/* eslint-disable jsx-a11y/no-static-element-interactions,no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { GroupsModel } from 'models/groups/types';
import { GroupsController } from 'controllers/groups';
import FormikContainer from 'components/form/containers';
import { KindOfFieldGroups } from 'common/constants/modal';
import FormikTextField from 'components/form/fields/inputs';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { toJS } from 'mobx';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  setIsAppendEmployeesModal: (isOpen: boolean) => void;
  isAppendEmployeesModal?: any;
  modalRefEl: any;
  groups: GroupsController;
  getGroupsModel: GroupsModel;
  editKindOfField: string;
}

const ModalEditGroupView = (props: ModalCreateGroupViewProps) => {
  const {
    groups,
    hideModal,
    modalRefEl,
    getGroupsModel,
    isAppendEmployeesModal,
    setIsAppendEmployeesModal,
    editKindOfField
  } = props;

  const [groupTitle, setGroupTitle] = useState(getGroupsModel.getGroupDetail.title);
  const [groupDescription, setGroupDescription] = useState(getGroupsModel.getGroupDetail.description);

  const validateSchema = Yup.object().shape({
    groupName: Yup.string().required()
  });

  const changeGroupCheck = (values: any) => {
    const data: any = {
      groupId: toJS(getGroupsModel.getGroupDetail).id,
      title: values.groupName || toJS(getGroupsModel.getGroupDetail).title,
      description: values.groupDescription,
      parentId: toJS(getGroupsModel.getGroupDetail).parent_id,
      employeesId: toJS(getGroupsModel.getGroupDetail).employees.map(
        (employee: any) => employee.id
      ),
      accounts: toJS(getGroupsModel.getGroupDetail).groupsAccounts
    };

    groups.updateGroupsAction(data);

    setIsAppendEmployeesModal(false);

    hideModal();
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupTitle(e.target.value);
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupDescription(e.target.value);
  };

  useEffect(() => () => setIsAppendEmployeesModal(false), [isAppendEmployeesModal]);

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            { editKindOfField === KindOfFieldGroups.GROUPNAME ? <h2 className={s.title}>Edit name</h2> : null }
            { editKindOfField === KindOfFieldGroups.GROUPDESCRIPTION ? <h2 className={s.title}>Edit description</h2> : null }
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <FormikContainer
          initialValues={{ groupName: getGroupsModel.getGroupDetail.title, groupDescription: getGroupsModel.getGroupDetail.description }}
          onSubmit={changeGroupCheck}
          // enableReinitialize
          validationSchema={validateSchema}
        >
          {({ errors, setFieldValue }) => (
            <div className={s.main}>
              <div className={s.operator}>
                { editKindOfField === KindOfFieldGroups.GROUPNAME ?

                  <div className={s.operator__create_id}>
                    <FormikTextField
                      name='groupName'
                      placeholder="Enter name"
                      value={groupTitle}
                      className={[ errors.groupName ? s.input_error : null ].join(' ')}
                      type="text"
                      onChange={(e) => handleChangeTitle(e)}
                      label={<p className={s.operator__create_label}>Group name</p>}
                      errorMessageDisable
                    />
                  </div>
                  :
                  null }

                { editKindOfField === KindOfFieldGroups.GROUPDESCRIPTION ?
                  <div className={s.operator__create_id}>
                    <FormikTextField
                      type="text"
                      value={groupDescription}
                      onChange={(e: any) => handleChangeDescription(e)}
                      placeholder="Enter description"
                      name="groupDescription"
                      label={<p className={s.operator__create_label}>Group description</p>}
                    />
                  </div>
                  :
                  null}


                <div className={s.employee}>
                  <div className={s.buttons}>
                    <motion.button
                      type='submit'
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                    >
                      <p>Save</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>Cancel</p>
                    </motion.button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </FormikContainer>
      </div>
    </ModalBackdrop>
  );
};

export const ModalEditGroup = observer(ModalEditGroupView);

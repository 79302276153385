/* eslint-disable react/void-dom-elements-no-children */
import React, { ChangeEvent } from 'react';
import s from './index.module.scss';

interface UniversalIconButtonProps {
  icon?: JSX.Element;
  text?: string;
  disabled?: boolean;
  onClick?: (event?: ChangeEvent<HTMLInputElement> | any) => void | string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  type: string;
  id: string;
  iconStyles?: React.CSSProperties;
}

export const UniversalIconButton = (props: UniversalIconButtonProps) => {
  const { icon, text, iconStyles, onClick, onChange, type, id, ...other } = props;
  return (
    <>
    <label htmlFor={id} className={s.label}>
      <div style={iconStyles} className={s.icon}>{icon}</div>
      <p className={s.text}>{text}</p>
    </label>
      <input id={id} {...other} onChange={onChange} onClick={onClick} type={type} className={s.input} />
    </>
  );
};

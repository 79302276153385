/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { Loader } from 'common/loader';
import ReactTooltip from 'react-tooltip';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { LinkSVG } from 'assets/icons/link';
import { BackSVG } from 'assets/icons/back';
import { CrossSVG } from 'assets/icons/cross';
import { LoupeSVG } from 'assets/icons/loupe';
import { useModal } from 'hooks/useModal.hook';
import { ReturnSVG } from 'assets/icons/return';
import { motion, Variants } from 'framer-motion';
import { Providers } from 'models/providers/types';
import { useDebounce } from 'hooks/useDebounce.hook';
import { EditLightSVG } from 'assets/icons/editLight';
import { EmployeeList } from 'models/employees/types';
import { GroupsMarkSVG } from 'assets/icons/groupsMark';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikContainer from 'components/form/containers';
import { DeleteLightSVG } from 'assets/icons/deleteLight';
import { AnimatedDivPage } from 'components/animatedPage';
import { RouterLink } from 'router/components/routerLink';
import { ColorsAccent, ColorsBorder } from 'common/colors';
import { KindOfFieldGroups } from 'common/constants/modal';
import { ProviderMeasure } from 'common/constants/payment';
import FormikCheckbox from 'components/form/fields/checkbox';
import FormikSelect from 'components/form/fields/select/index';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import FormikTextField from 'components/form/fields/inputs/index';
import { useLocation, useNavigate, useParams } from 'react-router';
import { operatorsTableGroupTitles } from 'common/constants/groups';
import { chooseAllEmployees } from 'common/constants/employeesList';
import { KindOfPage } from 'common/constants/additionalOperatorModal';
import FormikTextFieldNumber from 'components/form/fields/inputs/inputNumber/index';
import { selectSortByTitles } from 'common/constants/employeesDetail';
import { IOperatorsTableContent, IOperatorsTableTitles } from 'common/constants/employeesDetail/types';
import s from './index.module.scss';

const GroupsDetailView: FC = () => {
  const { state }: any = useLocation();
  const params: any = useParams();
  const { employees, auth, providers, accounts, groups, priceList } = useStores();
  const { getAuthModel } = auth;
  const { getProvidersModel } = providers;
  const { getPriceListModel } = priceList;
  const { showModal } = useModal();
  const { getAuthMe } = getAuthModel;
  const { isTablet } = useWindowDimensions();
  function removeDuplicates<T, K>(data: T[], key: (key: T) => K): T[] {
    return [...new Map(data?.map((item: T) => [key(item), item])).values()];
  }
  const { getPriceListDetailAsyncState } = priceList;
  const { getEmployeesModel, getEmployeesAsyncState } = employees;
  const { getGroupsModel, getUpdateGroupsAsyncState, getGroupsDetailAsyncState } = groups;
  const { getCreateAccountsAsyncState, getDeleteAccountsAsyncState } = accounts;
  const isDeletedLoading = getDeleteAccountsAsyncState.getLoadingState;
  const isDetailGroupLoading = getGroupsDetailAsyncState.getLoadingState;
  const [isOperatorsItemsSorted, setIsOperatorsItemsSorted] = useState<any>(false);
  const [allOperatorsItems, setAllOperatorsItems] = useState<any>(
    []?.sort((a: any, b: any) => {
      if (a?.provider?.title < b?.provider?.title) {
        return -1;
      }
      if (a?.provider?.title > b?.provider?.title) {
        return 1;
      }

      return 0;
    })
  );
  const [choosenItems, setChoosenItems] = useState<any>([]);
  const [productOperatorCheckList, setProductOperatorCheckList] = useState<any[]>([]);
  const [isFirstChoosenOperatorsCheck, setFirstChoosenOperatorsCheck] = useState<any[]>([]);
  const [isCheckGroupList, setIsCheckGroupList] = useState<any>(state?.isCheckGroupList);
  const [isCheck, setIsCheck] = useState<string[]>(removeDuplicates([], (key: any) => key));
  const [isCheckGroup, setIsCheckGroup] = useState<any>([]);
  const [isAllElementsChecked, setIsAllElementsChecked] = useState<boolean>(false);
  const [isAppendEmployeesModal, setIsAppendEmployeesModal] = useState<boolean>(false);
  const addEmployeeEl = useRef<any>();
  const addEmployeeButtonEl = useRef<any>();
  const modalRefEl = useRef<any>();
  const [createOperators, setCreateOperators] = useState<any>([]);
  const isAccountAddLoading = getCreateAccountsAsyncState.getLoadingState;
  const isGroupUpdateLoading = getUpdateGroupsAsyncState.getLoadingState;
  const isGetDetailPriceListSuccess = getPriceListDetailAsyncState.getLoadingState;
  const isGetEmployeesLoading = getEmployeesAsyncState.getLoadingState;
  const navigate = useNavigate();
  const [dynamicOperatorsItems, setDynamicOperatorsItems] = useState<any>([]);
  const [editInitialOperators, setEditInitialOperators] = useState<any>([]);
  const [editGroupName, setEditGroupName] = useState<any>(false);
  const [editGroupDescription, setEditGroupDescription] = useState<any>(false);
  const [isButtonClicked, setIsButtonClicked] = useState<any>(false);
  const [dynamicPage, setDynamicPage] = useState<any>(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [elementsCount, setElementsCount] = useState<any>(8);
  const itemsPerPage = elementsCount || 8;
  const [employeeSearch, setEmployeeSearch] = useState('');
  const employeeSearchDebounce = useDebounce(employeeSearch, 300);
  const [endOffset, setEndOffset] = useState<any>(itemOffset + itemsPerPage);

  const editInitialValues = editInitialOperators
    ?.reduce((acc: any, item: any) => {
      acc.push(
        item?.defaultPriceList
          ? {
            defaultPriceInitial: {
              [`${item.defaultPriceId}`]: {
                amount: +item?.amount,
                min: item?.minAmount,
                max: item?.maxAmount
              }
            },
            uniqueIdInitial: { [`${item.uniqueId}`]: item?.uniqueIdAccount },
            providerInitial: {
              [`${item.providerInitialId}`]: {
                ...item,
                label: item?.provider?.title,
                id: item.provider.id,
                value: item.provider?.id,
                singleValue: item?.provider?.title
              }
            },
            productInitial: {
              [`${item.productId}`]: item?.defaultPriceList
                ? {
                  ...item,
                  label: item?.defaultPriceList?.label,
                  id: item?.defaultPriceList?.id,
                  value: item.amount,
                  singleValue: item?.defaultPriceList?.label
                }
                : null
            }
          }
          : {
            defaultPriceInitial: {
              [`${item?.defaultPriceId}`]: {
                amount: +item?.amount,
                min: item?.minAmount,
                max: item?.maxAmount
              }
            },
            uniqueIdInitial: { [`${item.uniqueId}`]: item?.uniqueIdAccount },
            providerInitial: {
              [`${item?.providerInitialId}`]: {
                ...item,
                label: item?.provider?.title,
                id: item?.provider?.id,
                value: item?.provider?.id,
                singleValue: item?.provider?.title
              }
            }
          }
      );
      return acc;
    }, [])
    .reduce(
      (acc: any, item: any) =>
        Object.assign(
          acc,
          item.defaultPriceInitial,
          item.providerInitial,
          item.productInitial,
          item.uniqueIdInitial
        ),
      {}
    );

  const validateSchema = Yup.object().shape({
    ...(dynamicOperatorsItems?.groupsAccounts?.length
      ? [
        ...dynamicOperatorsItems?.groupsAccounts
          ?.filter((item: any) => item?.editItems)
          .map((item: any) => item?.editItems),
        ...createOperators
      ]
      : createOperators
    )
      ?.map((operator: any) => {
        if (
          !operator?.amount ||
          operator?.amount < operator.minAmount ||
          operator?.amount > operator.maxAmount
        ) {
          return {
            [`${operator.defaultPrice}`]: Yup.object().shape({
              amount: Yup.number()
                .required()
                .min(operator.minAmount, `Must be greater than or equal to ${operator.minAmount}`)
                .max(operator.maxAmount, `Must be less than or equal to ${operator.maxAmount}`)
            })
          };
        }
        return {};
      })
      .reduce((acc: any, item: any) => Object.assign(acc, item), {})
  });

  const onSubmit = (values: any) => {
    const resultData: any = [];
    const resultDataEmployees: any = [];

    Object.entries(values).map(([key, valuesData]: [string, any]): any => {
      resultData.push(
        ...createOperators.reduce((acc: any, operatorItem: any) => {
          choosenItems.map((choosenItem: any) => {
            if (operatorItem.providerId === choosenItem.id) {
              acc.push({
                defaultPrice: operatorItem.defaultPriceListId ? null : operatorItem.amount,
                priceListId: operatorItem.defaultPriceListId
                  ? operatorItem.defaultPriceListId
                  : null,
                providerId: operatorItem.providerId
              });
            }
          });
          return acc;
        }, [])
      );

      resultDataEmployees.push(
        ...createOperators.reduce((acc: any, operatorItem: any) => {
          choosenItems.map((choosenItem: any) => {
            if (operatorItem.providerId === choosenItem.id) {
              acc.push({
                providerId: operatorItem.providerId,
                defaultPrice: !operatorItem?.defaultPriceListId ? operatorItem.amount : null,
                defaultPriceListId: operatorItem?.defaultPriceListId || null,
                employeeId: null,
                uniqueId: null,
                note: null
              });
            }
          });
          return acc;
        }, [])
      );
    });

    const replacedAccountsGroups = toJS(getGroupsModel.getGroupDetail)?.groupsAccounts?.reduce(
      (acc: any, accountItem: any) => {
        acc.push({
          defaultPrice: accountItem.priceList ? null : accountItem.defaultPrice,
          priceListId: accountItem.priceList ? accountItem.priceList?.id : null,
          providerId: accountItem.provider?.id,
          defaultScenario: accountItem?.defaultScenario?.id || null
        });

        return acc;
      },
      []
    );

    setIsButtonClicked(true);

    const data: any = {
      groupId: toJS(getGroupsModel.getGroupDetail).id,
      title: values?.groupName || toJS(getGroupsModel.getGroupDetail).title,
      description: values?.groupDescription,
      parentId: toJS(getGroupsModel.getGroupDetail).parent_id,
      employeesId: toJS(getGroupsModel.getGroupDetail).employees.map(
        (employee: any) => employee.id
      ),
      accounts: replacedAccountsGroups
    };

    groups?.updateGroupsAction(data);

    ( isCheckGroup?.length ? isCheckGroup : toJS(getEmployeesModel.getEmployeesList)?.filter((employeeFilter: any) => toJS(getGroupsModel.getGroupDetail).employees.map(
      (employee: any) => employee.id
    ).includes(employeeFilter?.id)) )?.reduce((acc: any, employee: any, index: number) => {
      Promise.all([employees?.updateEmployeeAction({
        employeeId: employee?.id,
        name: employee?.name,
        phones: employee?.phones,
        surname: employee?.surname,
        groupId: getGroupsModel?.getGroupDetail?.id,
        accounts:
        toJS(getGroupsModel.getGroupDetail)?.groupsAccounts?.reduce(
          (accItem: any, accountItem: any) => {
            const initialEmployeeValues = employee?.accounts?.filter((itemUnique: any) => itemUnique?.provider?.id === accountItem?.provider?.id)[0];
            accItem.push( initialEmployeeValues?.defaultValueEmployee ? {
              defaultPrice: initialEmployeeValues?.defaultPrice || null,
              defaultPriceListId: initialEmployeeValues?.defaultPriceList?.id || null,
              defaultScenario: initialEmployeeValues?.defaultScenario,
              defaultValueEmployee: initialEmployeeValues?.defaultValueEmployee,
              providerId: accountItem?.provider?.id,
              employeeId: employee?.id,
              uniqueId: employee?.accounts?.filter((itemUnique: any) => itemUnique?.provider?.id === accountItem?.provider?.id).map((itemUnique: any) => itemUnique?.uniqueId)[0] || null,
              note: employee?.note || null
            }
              :
              {
                defaultPrice: !accountItem?.priceList?.id ? accountItem.defaultPrice : null,
                defaultPriceListId: accountItem?.priceList?.id || null,
                defaultScenario: accountItem?.defaultScenario,
                defaultValueEmployee: false,
                providerId: accountItem?.provider?.id,
                employeeId: employee?.id,
                uniqueId: employee?.accounts?.filter((itemUnique: any) => itemUnique?.provider?.id === accountItem?.provider?.id).map((itemUnique: any) => itemUnique?.uniqueId)[0] || null,
                note: employee?.note || null
              });

            return accItem;
          },
          []
        )
      }, ( isCheckGroup?.length ? isCheckGroup : toJS(getEmployeesModel.getEmployeesList)?.filter((employeeFilter: any) => toJS(getGroupsModel.getGroupDetail).employees.map(
        (employeeItem: any) => employeeItem.id
      ).includes(employeeFilter?.id)) ).length)]);
      return acc;
    }, []);
  };

  const handleChangeEdit = (e: any, eMeta: any, content: any, previousContent: any) => {
    setDynamicOperatorsItems({
      ...dynamicOperatorsItems,
      groupsAccounts: dynamicOperatorsItems?.groupsAccounts?.reduce((acc: any, item: any) => {
        if (content?.provider.id === item?.editItems?.provider?.id) {
          acc.push({
            ...item,
            defaultPriceList: null,
            uniqueId: '',
            editItems: {
              ...item?.editItems,
              uniqueId: '',
              amount: '',
              isDefaultOperatorValue: false,
              isDefaultIdValue: false,
              isDefaultProductValue: false,
              isDefaultAmountValue: false,
              defaultPriceList: null,
              defaultPrice: e?.defaultPrice,
              provider: {
                availability: e?.availability,
                id: e?.id,
                inputPatterns: e?.inputPatterns,
                title: e?.title,
                maxAmount: e?.maxAmount,
                minAmount: e?.minAmount,
                measure: e?.measure,
                validatePatterns: e?.validatePatterns
              }
            }
          });
        } else {
          acc.push({
            ...item
          });
        }
        return acc;
      }, [])
    });

    setEditInitialOperators(
      editInitialOperators?.reduce((acc: any, account: any) => {
        if (content?.provider.id === account?.provider?.id) {
          acc.push({
            ...account,
            uniqueIdAccount: '',
            defaultPriceList: '',
            providerInitialId: `provider${e?.id}`,
            provider: {
              availability: e?.availability,
              title: e?.title,
              id: e?.id,
              inputPatterns: e?.inputPatterns,
              maxAmount: e?.maxAmount,
              minAmount: e?.minAmount,
              measure: e?.measure,
              validatePatterns: e?.validatePatterns
            },
            providerId: e?.id,
            [`provider${account?.provider?.id}`]: {
              availability: e?.availability,
              title: e?.title,
              id: e?.id,
              inputPatterns: e?.inputPatterns,
              maxAmount: e?.maxAmount,
              minAmount: e?.minAmount,
              measure: e?.measure,
              validatePatterns: e?.validatePatterns
            }
          });
        } else {
          acc.push({
            ...account
          });
        }

        return acc;
      }, [])
    );

    setChoosenItems(removeDuplicates([...choosenItems, e], (key: any) => key.selectedFieldId));
    setFirstChoosenOperatorsCheck([...isFirstChoosenOperatorsCheck, content.id]);
  };

  const handleChangeProductEdit = (e: any, eMeta: any, content: any) => {
    setDynamicOperatorsItems({
      ...dynamicOperatorsItems,
      groupsAccounts: dynamicOperatorsItems?.groupsAccounts?.reduce((acc: any, item: any) => {
        if (content?.provider?.id === item?.editItems?.provider?.id) {
          acc?.push({
            ...item,
            editItems: {
              ...item?.editItems,
              amount: e?.amount,
              isDefaultProductValue: false,
              isDefaultAmountValue: false,
              defaultPriceListId: e?.id,
              defaultPriceList: {
                description: e?.description,
                label: e?.label,
                id: e?.id,
                price: e?.price,
                provider: e?.provider
              }
            }
          });
        } else {
          acc?.push({
            ...item
          });
        }
        return acc;
      }, [])
    });

    setProductOperatorCheckList([...productOperatorCheckList, content.id]);
  };

  const handleChangePackagesAndMoneyEdit = (e: any, content: any) => {
    setDynamicOperatorsItems({
      ...dynamicOperatorsItems,
      groupsAccounts: dynamicOperatorsItems?.groupsAccounts?.reduce((acc: any, item: any) => {
        if (item?.editItems?.provider?.id === content?.provider?.id) {
          acc?.push({
            ...item,
            editItems: {
              ...item?.editItems,
              amount: e,
              defaultPriceListId: null,
              defaultPriceList: null,
              isDefaultAmountValue: false
            }
          });
        } else {
          acc?.push({
            ...item
          });
        }

        return acc;
      }, [])
    });
  };

  const handleChangeOnlyMoneyEdit = (e: any, content: any) => {
    setDynamicOperatorsItems({
      ...dynamicOperatorsItems,
      groupsAccounts: dynamicOperatorsItems?.groupsAccounts?.reduce((acc: any, item: any) => {
        if (item?.editItems?.provider?.id === content?.provider?.id) {
          acc?.push({
            ...item,
            editItems: {
              ...item?.editItems,
              amount: e,
              defaultPriceListId: null,
              defaultPriceList: null,
              isDefaultAmountValue: false
            }
          });
        } else {
          acc?.push({
            ...item
          });
        }
        return acc;
      }, [])
    });
  };

  const handlePriceListSelect = (content: any) => {
    priceList.getPriceListDetailAction({ id: content?.provider?.id });
    setDynamicPage(1);
    setItemOffset(0);
    setEndOffset(8);
  };

  const handleProviderSelect = () => {
    providers.getProvidersAction();
    setDynamicPage(1);
    setItemOffset(0);
    setEndOffset(8);
  };

  const removeDynamicItem = (account: any) => {
    setDynamicOperatorsItems({
      ...dynamicOperatorsItems,
      groupsAccounts: dynamicOperatorsItems?.groupsAccounts?.reduce((acc: any, item: any) => {
        if (item?.id === account?.id) {
          acc.push({
            ...item,
            editItems: null
          });
        } else {
          acc.push({
            ...item
          });
        }

        return acc;
      }, [])
    });
  };

  const removeOperator = (operator: any) => {
    setCreateOperators(createOperators.filter((item: any) => item.id !== operator.id));
    setProductOperatorCheckList(
      productOperatorCheckList.filter((item: any) => item !== operator.id)
    );
    setFirstChoosenOperatorsCheck(
      isFirstChoosenOperatorsCheck.filter((item: any) => item !== operator.id)
    );

    setChoosenItems(
      choosenItems?.filter((item: any) => item?.selectedFieldId !== `provider${operator.id}`)
    );
  };

  const handleChangeProduct = (e: any, eMeta: any, content: any) => {
    setCreateOperators(
      createOperators.map((account: any) => {
        if (content?.operatorName === account?.operatorName) {
          account.amount = e?.price;
          account.defaultPriceListId = e?.id;
        }
        return account;
      })
    );

    setProductOperatorCheckList([...productOperatorCheckList, content.id]);
  };

  const selectUnique = () =>
    toJS(getProvidersModel?.getProvidersList)
      .filter(
        (allProviders: any) =>
          !getGroupsModel.getGroupDetail?.groupsAccounts
            ?.map((provider: any) => provider?.provider?.id)
            .includes(allProviders.id)
      )
      .sort((a: any, b: any) => {
        if (a?.title < b?.title) {
          return -1;
        }
        if (a?.title > b?.title) {
          return 1;
        }

        return 0;
      });

  const handleChange = (e: any, eMeta: any, content: any) => {
    createOperators?.map(async (account: any) => {
      if (content?.operatorName === account?.operatorName) {
        await priceList?.getPriceListDetailAction({ id: e?.id });
        account.priceList = toJS(getPriceListModel.getPriceListDetail);
        account.minAmount = e.minAmount;
        account.maxAmount = e.maxAmount;
        account.providerId = e.id;
      }
    });

    setChoosenItems(removeDuplicates([...choosenItems, e], (key: any) => key.selectedFieldId));
    setFirstChoosenOperatorsCheck([...isFirstChoosenOperatorsCheck, content.id]);
  };

  const addNewMember = () => {
    setIsAppendEmployeesModal(!isAppendEmployeesModal);
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>, employee: any) => {
    const { id, checked } = e.target;

    if (checked) {
      setIsCheck([...isCheck, id]);
      setIsCheckGroup([...isCheckGroup, employee]);
      setIsCheckGroupList([...isCheckGroup, employee]);
    }

    if (!checked) {
      setIsCheck(isCheck?.filter((item: any) => item !== id));
      setIsCheckGroup(isCheckGroup?.filter((item: any) => item.id !== id));
      setIsCheckGroupList(
        isCheckGroupList?.filter((employeeItem: any) => employeeItem.id !== employee.id)
      );
    }
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target;
    if (checked) {
      setIsCheck([...getEmployeesModel?.getEmployeesList?.map((li) => li.id), id]);
      setIsCheckGroup(getEmployeesModel?.getEmployeesList);
      setIsCheckGroupList(getEmployeesModel?.getEmployeesList);
    }

    if (!checked) {
      setIsCheck([]);
      setIsCheckGroup([]);
      setIsCheckGroupList([]);
    }
  };

  const animations: Variants = {
    initial: { opacity: 0, scale: 0.6, y: -10 },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.6, y: -10 }
  };

  const removeAccount = (accountItem: any) => {
    showModal(Modals.ModalRemoveOperatorFromGroup, {
      setDynamicOperatorsItems,
      dynamicOperatorsItems,
      operator: accountItem,
      groups,
      employees,
      getGroupsModel,
      getEmployeesModel
    });
  };

  const sortDynamicOperatorsItems = (a: any, b: any) => {
    if (a > b) {
      return isOperatorsItemsSorted ? 1 : -1;
    }

    if (a < b) {
      return !isOperatorsItemsSorted ? 1 : -1;
    }
  };

  const handleSortItems = (titles: any) => {
    setIsOperatorsItemsSorted(!isOperatorsItemsSorted);
    setAllOperatorsItems(
      toJS(
        getGroupsModel.getGroupDetail.groupsAccounts.sort((a: any, b: any): any => {
          if (titles.data === 'amount') {
            return sortDynamicOperatorsItems(a.defaultPrice, b.defaultPrice);
          }

          if (titles.data === 'title') {
            return sortDynamicOperatorsItems(a.provider.title, b.provider.title);
          }
        })
      )
    );
  };

  const handleSelectSort = (e: any) => {
    setIsOperatorsItemsSorted(!isOperatorsItemsSorted);
    setAllOperatorsItems(
      toJS(
        getGroupsModel.getGroupDetail.groupsAccounts.sort((a: any, b: any): any => {
          if (e.value === 'amount') {
            return sortDynamicOperatorsItems(a.defaultPrice, b.defaultPrice);
          }

          if (e.value === 'name') {
            return sortDynamicOperatorsItems(a.provider.title, b.provider.title);
          }
        })
      )
    );
  };

  const handleChangePackagesAndMoney = (e: any, content: any) => {
    setCreateOperators(
      createOperators.map((item: any) => {
        if (content?.operatorName === item?.operatorName) {
          item.amount = e;
          item.defaultPriceListId = null;
        }
        return item;
      })
    );
  };

  const handleChangeOnlyMoney = (e: any, content: any) => {
    setCreateOperators(
      createOperators.map((item: any) => {
        if (content?.operatorName === item?.operatorName) {
          item.amount = e;
          item.defaultPriceListId = null;
        }
        return item;
      })
    );
  };

  const removeCurrentEmployee = (e: any, employeeItem: any) => {
    e.stopPropagation();
    showModal(Modals.ModalRemoveEmployeeFromGroup, {
      isCheck,
      isCheckGroup,
      isCheckGroupList,
      setIsCheck,
      setIsCheckGroup,
      setIsCheckGroupList,
      employee: employeeItem,
      id: employeeItem.id,
      modalRefEl,
      setIsAppendEmployeesModal,
      isAppendEmployeesModal,
      groups,
      getGroupsModel
    });
  };

  const onHandleSearch = (setter: (val: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  const concatNewIsCheckGroupValues = () => {

    showModal(Modals.ModalAddEmployeesToGroup, {
      isCheck,
      isCheckGroup,
      isCheckGroupList,
      setIsCheckGroup,
      getAuthMe,
      setIsCheck,
      setIsCheckGroupList,
      modalRefEl,
      setIsAppendEmployeesModal,
      removeFromWindow: true,
      isAppendEmployeesModal,
      removeDuplicates,
      groups,
      employees,
      getGroupsModel,
      getEmployeesModel
    });
  };

  const handleGroupName = () => {
    showModal(Modals.ModalEditGroup, {
      modalRefEl,
      setIsAppendEmployeesModal,
      isAppendEmployeesModal,
      removeDuplicates,
      groups,
      getGroupsModel,
      editKindOfField: KindOfFieldGroups.GROUPNAME
    });
  };

  const handleGroupDescription = () => {
    showModal(Modals.ModalEditGroup, {
      modalRefEl,
      setIsAppendEmployeesModal,
      isAppendEmployeesModal,
      removeDuplicates,
      groups,
      getGroupsModel,
      editKindOfField: KindOfFieldGroups.GROUPDESCRIPTION
    });
  };

  const handleOperatorsEdit = (content?: any) => {
    toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
      if (provider.id === content?.provider?.id) {
        content.provider = provider;
      }
    });

    showModal(Modals.ModalAdditionOperatorFull, { uniqueProviders: selectUnique(), choosenItems, setChoosenItems, currentOperatorData: content ? { ...content, minAmount: content?.provider?.minAmount, maxAmount: content?.provider?.maxAmount, uniqueIdValue: content?.uniqueId, amount: content?.defaultPrice || content?.priceList?.price, defaultPriceList: content?.priceList, id: content?.provider?.id, initialProviderId: content?.provider?.id } : null, currentKindOfPage: KindOfPage.DETAIL_GROUP_PAGE, employees, groups });
  };

  useEffect(() => {

    if (isAccountAddLoading === Loading.success) {
      setCreateOperators([]);
    }

  }, [
    isDeletedLoading,
    isCheckGroupList,
    getPriceListModel.getPriceListDetail,
    getGroupsModel.getGroupDetail.employees.length,
    selectUnique().length,
    getPriceListModel.getPriceListDetail.length
  ]);

  useEffect(() => {
    const isOpenAddEmployeeMenu = (e: any) =>
      addEmployeeEl?.current?.contains(e.target) ||
      addEmployeeButtonEl?.current?.contains(e.target) ||
      modalRefEl?.current?.contains(e.target) ||
      setIsAppendEmployeesModal(false);

    document.addEventListener('click', isOpenAddEmployeeMenu);
    return () => document.removeEventListener('click', isOpenAddEmployeeMenu);
  }, [isAppendEmployeesModal]);

  useEffect(() => {
    if (isGroupUpdateLoading === Loading.success) {
      groups.getGroupsDetailAction({
        clientId: getAuthMe?.id,
        groupId: params?.id
      });

      groups.getGroupsAction({
        clientId: getAuthMe?.id,
        groupId: params?.id
      });

      setCreateOperators([]);
      setChoosenItems([]);
    }

  }, [isGroupUpdateLoading]);

  useEffect(() => {
    if (isGroupUpdateLoading === Loading.success) {
      setChoosenItems([]);
      setCreateOperators([]);
      setDynamicOperatorsItems([]);
      setProductOperatorCheckList([]);
      setFirstChoosenOperatorsCheck([]);
    }
  }, [isGroupUpdateLoading]);

  useEffect(() => {
    setDynamicOperatorsItems(toJS(getGroupsModel.getGroupDetail));

    return () => setDynamicOperatorsItems([]);
  }, []);

  useEffect(() => {
    groups.getGroupsDetailAction({
      clientId: getAuthMe?.id,
      groupId: params?.id
    });
  }, []);

  useEffect(() => {
    if (isGroupUpdateLoading === Loading.success) {
      setEditGroupName(false);
      setEditGroupDescription(false);
    }
  }, [isGroupUpdateLoading]);

  useEffect(() => {
    if (isButtonClicked) {
      setTimeout(() => {
        setIsButtonClicked(false);
      }, 500);
    }
  }, [isButtonClicked]);

  useEffect(() => {
    employees.getEmployeesAction(
      {
        id: getAuthMe.id,
        page: toJS(getEmployeesModel.getEmployeesMeta).page,
        search: employeeSearch,
        // quantity: (toJS(getEmployeesModel.getEmployeesMeta).page || employeeSearch) !== 1 ? 0 : 1000,
      }
    );
  }, [employeeSearchDebounce, employeeSearch]);

  useEffect(() => {
    if (isGetEmployeesLoading === Loading.success) {
      setIsAllElementsChecked(getEmployeesModel.getEmployeesList.length === [...new Set(isCheckGroup.map((item: EmployeeList) => item.id))].length);
    }
  }, [isGetEmployeesLoading, isCheckGroup]);

  useEffect(() => {

    if (isAppendEmployeesModal && !isTablet) {
      document.body.style.overflow = 'hidden';
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }

  }, [isAppendEmployeesModal]);

  useEffect(() => {
    providers.getProvidersAction();
  }, []);

  useEffect(() => {
    if (isGetEmployeesLoading === Loading.success) {
      setIsCheck([...toJS(getGroupsModel.getGroupDetail.employees).map(
        (employee: any) => employee.id
      ), ...isCheck]);
      setIsCheckGroup([...toJS(getEmployeesModel.getEmployeesList).filter(employee => toJS(getGroupsModel.getGroupDetail.employees).map(
        (groupEmployee: any) => groupEmployee.id
      ).includes(employee.id)), ...isCheckGroup]);
      setIsCheckGroupList([...toJS(getEmployeesModel.getEmployeesList).filter(employee => toJS(getGroupsModel.getGroupDetail.employees).map(
        (groupEmployee: any) => groupEmployee.id
      ).includes(employee.id)), ...isCheckGroup]);
    }
  }, [isGetEmployeesLoading]);

  useEffect(() => {
    if (isDetailGroupLoading === Loading.success || !isAppendEmployeesModal) {
      setIsCheck([...toJS(getGroupsModel.getGroupDetail.employees).map(
        (employee) => employee.id
      )]);
      setIsCheckGroup([...toJS(getEmployeesModel.getEmployeesList).filter(employee => toJS(getGroupsModel.getGroupDetail.employees).map(
        (groupEmployee) => groupEmployee.id
      ).includes(employee.id))]);
      setIsCheckGroupList([...toJS(getEmployeesModel.getEmployeesList).filter(employee => toJS(getGroupsModel.getGroupDetail.employees).map(
        (groupEmployee) => groupEmployee.id
      ).includes(employee.id))]);
    }
  }
  , [isDetailGroupLoading, isAppendEmployeesModal]);

  useEffect(() => {

    if (isGroupUpdateLoading === Loading.success) {
      employees.getEmployeesAction(
        {
          id: getAuthMe.id,
          page: toJS(getEmployeesModel.getEmployeesMeta).page,
          search: employeeSearch,
          // quantity: (toJS(getEmployeesModel.getEmployeesMeta).page || employeeSearch) !== 1 ? 0 : 1000,
        }
      );
    }

  }, [isGroupUpdateLoading]);

  return (
    <section className={s.wrapper}>
      <div className={s.wrapper__container}>
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: { opacity: 0, x: 70, y: 70 },
            animate: { opacity: 1, x: 0, y: 0 },
            exit: { opacity: 0, x: 70, y: 70 }
          }}
          transition={{ duration: 0.7, type: 'spring', damping: 22, stiffness: 155 }}
          className={s.back}
        >
          <ReactTooltip textColor="#334049" backgroundColor="#fff" border borderColor="#E5E8ED" />
          <motion.button
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className={s.back_icon}
            onClick={() => navigate(state?.previousPageWithQuery, { state: { group: state?.group, page: state?.page, sortId: state?.sortId } })}
          >
            <BackSVG />
          </motion.button>

          <div className={s.back_text}>
            <p>Back to groups</p>
          </div>
        </motion.div>

        <AnimatedDivPage
          transition={{ duration: 0.7, type: 'spring', damping: 15 }}
          animations={animations}
        >
          <FormikContainer
            enableReinitialize
            initialValues={{
              ...editInitialValues,
              groupName: toJS(getGroupsModel.getGroupDetail).title,
              groupDescription: toJS(getGroupsModel.getGroupDetail).description
            }}
            validationSchema={validateSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <>
                <div className={s.detail}>
                    <div className={s.detail__title_header}>
                      <h1 className={s.detail__title}>{getGroupsModel?.getGroupDetail?.title}</h1>
                      <div className={s.operators__items_icon_edit}>
                        <button type="button" onClick={handleGroupName}>
                          <EditLightSVG />
                        </button>
                      </div>
                    </div>
                  <div className={s.employee__group}>
                    <h4 className={s.employee__group_title}>Employees</h4>
                    <div className={s.employee__group__list}>
                      {
                        isDetailGroupLoading === Loading.success
                          ?
                          toJS(getGroupsModel.getGroupDetail.employees)?.map((employee: any) => (
                            <RouterLink key={employee.id} to={`/employees/employees-detail/${employee.id}`} state={{ previousPageWithQuery: '/employees' }}>
                              <div className={s.group__item}>
                                <div className={s.link}>
                                  <p>
                                    {employee.name} {employee.surname}
                                  </p>

                                  <div className={s.item__icon}>
                                    <LinkSVG />
                                  </div>
                                </div>

                                <button
                                  onClick={(e: ChangeEvent<any>) => removeCurrentEmployee(e, employee)}
                                  type="button"
                                  className={s.employee__remove_icon}
                                >
                                  <CrossSVG />
                                </button>
                              </div>
                            </RouterLink>
                          ))
                          :
                          <Loader
                            loaderStyles={{ width: '35px', height: '35px', borderWidth: '4px', borderTopWidth: '4px' }}
                            loaderWrapperStyles={{
                              width: '100%',
                              height: '100%'
                            }}/>
                      }
                      { !toJS(getGroupsModel.getGroupDetail.employees).length ?
                        <p>There are no employees</p>
                        :
                        null
                      }
                    </div>
                  </div>

                  <div className={s.members__button}>
                    <motion.button
                      ref={addEmployeeButtonEl}
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => addNewMember()}
                    >
                      <p>Add employee+</p>
                    </motion.button>
                  </div>

                  <div className={s.group__description}>
                    <h4 className={s.group__description_title}>Description</h4>
                  </div>

                    <div className={s.group__item}>
                      <p>
                        {toJS(getGroupsModel.getGroupDetail)?.description
                          ? toJS(getGroupsModel.getGroupDetail)?.description
                          : '----'}
                      </p>

                      <button
                        onClick={handleGroupDescription}
                        type="button"
                        className={s.employee__remove_icon}
                      >
                        <EditLightSVG />
                      </button>
                    </div>

                  <div className={s.operators}>
                    <div className={s.operators__header}>
                      <div className={s.operators__wrapper__title}>
                        <h4 className={s.operators__title}>Operators</h4>
                        <h4 className={s.operators__title}>
                        Employees: {getGroupsModel.getGroupDetail?.employees?.length}
                        </h4>
                      </div>

                      <div className={s.operators__sort}>
                        <FormikSelect
                          valueContainer={{ paddingLeft: '.5rem' }}
                          wrapperSelectStyles={{
                            width: '11rem',
                            maxWidth: '100%',
                            minWidth: '100%'
                          }}
                          options={selectSortByTitles}
                          name="sort"
                          placeholder="Sort by"
                          indicatorIcon={<DropSelectSVG />}
                          onChange={handleSelectSort}
                        />
                      </div>
                    </div>

                    <div className={s.operators__list}>
                      <div className={s.operators__list__header}>
                        {operatorsTableGroupTitles.map((titles: IOperatorsTableTitles) => (
                          <div key={titles.id} className={s.header__item}>
                            <button
                              onClick={() => handleSortItems(titles)}
                              type="button"
                              className={s.item}
                            >
                              <p>{titles.name}</p>
                              {titles.icon}
                            </button>
                          </div>
                        ))}
                      </div>

                      <div className={s.operators__main}>
                        { (allOperatorsItems.length
                          ? allOperatorsItems
                          : (dynamicOperatorsItems?.groupsAccounts?.length
                            ? dynamicOperatorsItems?.groupsAccounts
                            : toJS(getGroupsModel.getGroupDetail.groupsAccounts)
                          ).length ? (allOperatorsItems.length
                              ? allOperatorsItems
                              : (dynamicOperatorsItems?.groupsAccounts?.length
                                ? dynamicOperatorsItems?.groupsAccounts
                                : toJS(getGroupsModel.getGroupDetail.groupsAccounts)
                              )?.sort((a: any, b: any) => {
                                if (a?.provider?.title < b?.provider?.title) {
                                  return -1;
                                }
                                if (a?.provider?.title > b?.provider?.title) {
                                  return 1;
                                }

                                return 0;
                              })
                            )?.map((content: IOperatorsTableContent | any) => (
                          <motion.div
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            variants={{
                              initial: { opacity: 0, y: -10 },
                              animate: { opacity: 1, y: 0 },
                              exit: { opacity: 0, y: 10 }
                            }}
                            transition={{
                              duration: 0.5,
                              type: 'spring',
                              stiffness: 150,
                              damping: 18,
                              bounce: 0
                            }}
                            key={content.id}
                            className={s.operators__items}
                          >
                            {content?.editItems ? (
                              <motion.div
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                variants={{
                                  initial: { opacity: 0, y: -10 },
                                  animate: { opacity: 1, y: 0 },
                                  exit: { opacity: 0, y: 10 }
                                }}
                                transition={{
                                  duration: 0.5,
                                  type: 'spring',
                                  stiffness: 150,
                                  damping: 18,
                                  bounce: 0
                                }}
                                key={content?.editItems?.id}
                                className={`${s.operators__items} ${s.operators__items_edit} ${s.operators__items__create}`}
                              >
                                <div className={s.operators__item}>
                                  <FormikSelect
                                    onMenuOpen={handleProviderSelect}
                                    indicatorContainerStyles={{
                                      height: 'auto',
                                      width: '2.0625rem'
                                    }}
                                    wrapperSelectStyles={{ width: '100%' }}
                                    controlStyles={{
                                      minHeight: '2.0625rem',
                                      maxHeight: '2.0625rem',
                                      border: `1px solid ${ColorsBorder.borderPrimary}`
                                    }}
                                    // hideInputContainerWrapper={{
                                    //   width: 0,
                                    //   margin: 0,
                                    //   padding: 0,
                                    //   maxHeight: 0
                                    // }}
                                    valueContainer={{
                                      height: '2.0625rem',
                                      maxHeight: '2.0625rem',
                                      paddingTop: '0',
                                      paddingBottom: '.25rem',
                                      fontStyle: content?.editItems?.isDefaultOperatorValue
                                        ? 'italic'
                                        : 'normal'
                                    }}
                                    containerStyles={{
                                      width: '8.375rem',
                                      minWidth: !isTablet ? '100%' : 'auto',
                                      height: 'auto',
                                      fontSize: '1rem',
                                      border: 'none'
                                    }}
                                    name={`provider${content?.editItems?.provider?.id}`}
                                    options={
                                      // selectUnique()?.slice(itemOffset, endOffset)
                                      selectUnique()?.map((item: any) => ({
                                        ...item,
                                        value: item?.title,
                                        label: item?.title,
                                        singleValue: item?.title,
                                        id: item?.id,
                                        isDisabled: item?.availability === 'Disabled',
                                        genre: ['pop'],
                                        selectedFieldId: `provider${item.id}`,
                                        uniqueId: `uniqueId${item.id}`,
                                        defaultPrice: `${item.id}`,
                                        note: `note${item.id}`
                                      }))}
                                    // selectPagination={
                                    //   <>
                                    //     <div className={s.paginate__count__mobile}>
                                    //         <p>{dynamicPage}</p> <p className={s.count__divider}>of</p> <p>{pageCount || 1}</p>
                                    //     </div>
                                    //     <div className={s.select__pagination}>
                                    //       <SelectPagination
                                    //         dynamicPage={dynamicPage}
                                    //         handlePageClick={handlePageClickProviders}
                                    //         pageCount={pageCount}
                                    //       />
                                    //     </div>
                                    //   </>
                                    // }
                                    // isSearchable
                                    placeholder={'Operator'}
                                    indicatorIcon={<DropSelectSVG />}
                                    onChange={(e: any, eMeta: any) =>
                                      handleChangeEdit(
                                        e,
                                        eMeta,
                                        {
                                          ...content?.editItems,
                                          id: content?.editItems?.provider?.id
                                        },
                                        content
                                      )
                                    }
                                  />
                                </div>

                                {content?.editItems?.provider?.measure ===
                                  ProviderMeasure.PACKAGES ||
                                content?.editItems?.provider?.measure ===
                                  ProviderMeasure.PACKAGES_AND_MONEY ? (
                                  <div className={s.operators__item}>
                                    <FormikSelect
                                      indicatorContainerStyles={{
                                        height: 'auto',
                                        width: '2.0625rem'
                                      }}
                                      wrapperSelectStyles={{ width: '100%' }}
                                      controlStyles={{
                                        minHeight: '2.0625rem',
                                        maxHeight: '2.0625rem',
                                        border: `1px solid ${ColorsBorder.borderPrimary}`
                                      }}
                                      // hideInputContainerWrapper={{
                                      //   width: 0,
                                      //   margin: 0,
                                      //   padding: 0,
                                      //   maxHeight: 0
                                      // }}
                                      valueContainer={{
                                        height: '2.0625rem',
                                        maxHeight: '2.0625rem',
                                        paddingTop: '0',
                                        paddingBottom: '.25rem',
                                        fontStyle: content?.editItems?.isDefaultProductValue
                                          ? 'italic'
                                          : 'normal'
                                      }}
                                      containerStyles={{
                                        width: '8.375rem',
                                        minWidth: !isTablet ? '100%' : 'auto',
                                        height: 'auto',
                                        fontSize: '1rem',
                                        border: 'none'
                                      }}
                                      name={`product${content?.editItems?.provider?.id}`}
                                      loadingMessage={
                                        isGetDetailPriceListSuccess === Loading.success
                                      }
                                      onMenuOpen={() => handlePriceListSelect(content?.editItems)}
                                      options={
                                        // toJS(getPriceListModel.getPriceListDetail?.slice(itemOffset, endOffset))
                                        toJS(getPriceListModel.getPriceListDetail)?.map((item: any) => ({
                                          ...item,
                                          value: item?.description,
                                          label: item?.description,
                                          singleValue: item?.description,
                                          uniqueId: `uniqueId${content?.editItems?.provider?.id}`,
                                          amount: item?.price,
                                          id: item?.id,
                                          isDisabled: item?.availability === 'Disabled',
                                          genre: ['pop']
                                        }))}
                                      // selectPagination={
                                      //   <>
                                      //     <div className={s.paginate__count__mobile}>
                                      //         <p>{dynamicPage}</p> <p className={s.count__divider}>of</p> <p>{pageCount || 1}</p>
                                      //     </div>
                                      //     <div className={s.select__pagination}>
                                      //       <SelectPagination
                                      //         dynamicPage={dynamicPage}
                                      //         handlePageClick={handlePageClick}
                                      //         pageCount={pageCount}
                                      //       />
                                      //     </div>
                                      //   </>
                                      // }
                                      // isSearchable
                                      placeholder={'Product'}
                                      indicatorIcon={<DropSelectSVG />}
                                      onChange={(e: any, eMeta: any) =>
                                        handleChangeProductEdit(e, eMeta, {
                                          ...content?.editItems,
                                          id: content?.editItems?.provider?.id
                                        })
                                      }
                                    />
                                  </div>
                                  ) : (
                                  <div className={s.operators__placeholder} />
                                  )}

                                {content?.editItems?.provider?.measure ===
                                ProviderMeasure.PACKAGES ? (
                                  <div className={s.operators__item}>
                                    <FormikTextFieldNumber
                                      disabled={
                                        content?.editItems?.provider?.measure ===
                                        ProviderMeasure.PACKAGES
                                      }
                                      inputStyles={{
                                        width: '8.375rem',
                                        height: '2.0625rem',
                                        minWidth: !isTablet ? '100%' : 'auto',
                                        fontStyle: content?.editItems?.isDefaultAmountValue
                                          ? 'italic'
                                          : 'normal'
                                      }}
                                      placeholder="Amount"
                                      value={content?.editItems?.amount || ''}
                                      name={`defaultPrice${content?.editItems?.provider?.id}`}
                                      suppressHydrationWarning
                                      min={content?.editItems?.provider?.minAmount}
                                      max={content?.editItems?.provider?.maxAmount}
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                  ) : null}

                                {content?.editItems?.provider?.measure ===
                                ProviderMeasure.PACKAGES_AND_MONEY ? (
                                  <div className={s.operators__item}>
                                    <FormikTextFieldNumber
                                      inputStyles={{
                                        width: '8.375rem',
                                        height: '2.0625rem',
                                        minWidth: !isTablet ? '100%' : 'auto',
                                        fontStyle: content?.editItems?.isDefaultAmountValue
                                          ? 'italic'
                                          : 'normal',
                                        border:
                                          content?.editItems?.amount <
                                            content?.editItems?.provider?.minAmount ||
                                          content?.editItems?.amount >
                                            content?.editItems?.provider?.maxAmount ||
                                          !content?.editItems?.amount
                                            ? '1px solid red'
                                            : `1px solid ${ColorsBorder.borderPrimary}`
                                      }}
                                      placeholder="Amount"
                                      value={content?.editItems?.amount || ''}
                                      name={`defaultPrice${content?.editItems?.provider?.id}`}
                                      suppressHydrationWarning
                                      min={content?.editItems?.provider?.minAmount}
                                      max={content?.editItems?.provider?.maxAmount}
                                      onValueChange={(e: any) =>
                                        handleChangePackagesAndMoneyEdit(e, content?.editItems)
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                  ) : null}

                                {content?.editItems?.provider?.measure === ProviderMeasure.MONEY ? (
                                  <div className={s.operators__item}>
                                    <FormikTextFieldNumber
                                      inputStyles={{
                                        width: '8.375rem',
                                        height: '2.0625rem',
                                        minWidth: !isTablet ? '100%' : 'auto',
                                        fontStyle: content?.editItems?.isDefaultAmountValue
                                          ? 'italic'
                                          : 'normal',
                                        border:
                                          content?.editItems?.amount <
                                            content?.editItems?.provider?.minAmount ||
                                          content?.editItems?.amount >
                                            content?.editItems?.provider?.maxAmount ||
                                          !content?.editItems?.amount
                                            ? '1px solid red'
                                            : `1px solid ${ColorsBorder.borderPrimary}`
                                      }}
                                      placeholder="Amount"
                                      value={content?.editItems?.amount || ''}
                                      name={`defaultPrice${content?.editItems?.provider?.id}`}
                                      suppressHydrationWarning
                                      min={content?.editItems?.provider?.minAmount}
                                      max={content?.editItems?.provider?.maxAmount}
                                      onValueChange={(e: any) =>
                                        handleChangeOnlyMoneyEdit(e, content?.editItems)
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                ) : null}
                                <div className={s.operators__items_icon_edit}>
                                  <button type="button" onClick={() => removeDynamicItem(content)}>
                                    <ReturnSVG />
                                  </button>
                                </div>

                                <div className={s.operators__items_icon}>
                                  <button
                                    onClick={() => removeAccount(content)}
                                    type="button"
                                    className={s.remove__button}
                                  >
                                    <DeleteLightSVG />
                                  </button>
                                </div>
                              </motion.div>
                            ) : (
                              <>
                                {isTablet ? (
                                  operatorsTableGroupTitles.map(
                                    (titles: IOperatorsTableTitles | any) => (
                                      <div key={titles.id} className={s.operators__item}>
                                        {titles.data === 'title' ? (
                                          <p>{content.provider.title}</p>
                                        ) : null}
                                        {titles.data === 'amount' ? (
                                          <p>
                                            { content.priceList ? `${Number(content.priceList?.price)?.toFixed(2)?.toLocaleString()} UGX` : content.defaultPrice ? `${Number(content?.defaultPrice)?.toLocaleString()} UGX`
                                              :
                                              '----'}
                                          </p>
                                        ) : null}

                                        {titles.data === 'product' ? (
                                          <p>{content?.priceList?.label || '----'}</p>
                                        ) : null}
                                      </div>
                                    )
                                  )
                                ) : (
                                  <div key={content?.id} className={s.operators__item}>
                                    <div className={s.item__inner}>
                                      <h5>Name</h5>
                                      <p>{content?.provider?.title}</p>
                                    </div>

                                    <div className={s.item__inner}>
                                      <div className={s.item__inner}>
                                        <h5>Amount</h5>
                                        <p>
                                          { content.priceList ? `${Number(content.priceList?.price)?.toFixed(2)?.toLocaleString()} UGX` : content.defaultPrice ? `${Number(content?.defaultPrice)?.toLocaleString()} UGX`
                                            :
                                            '----'}
                                        </p>
                                      </div>
                                    </div>

                                    <div className={s.item__inner}>
                                      <h5>Product</h5>
                                      <p>{content?.priceList?.label || '----'}</p>
                                    </div>
                                  </div>
                                )}
                                <div className={s.operators__items_icon_edit}>
                                  <button type="button"
                                  // onClick={() => editAccount(content)}
                                  onClick={() => handleOperatorsEdit(content)}
                                  >
                                    <EditLightSVG />
                                  </button>
                                </div>
                                <div className={s.operators__items_icon}>
                                  <button type="button" onClick={() => removeAccount(content)}>
                                    <DeleteLightSVG />
                                  </button>
                                </div>
                              </>
                            )}
                          </motion.div>
                            ))
                            :
                              <div className={s.operators__empty}>
                                <p>There are no operators</p>
                              </div>
                        )}

                        {createOperators.map(
                          (content: IOperatorsTableContent | any, index: number) => (
                            <motion.div
                              initial="initial"
                              animate="animate"
                              exit="exit"
                              variants={{
                                initial: { opacity: 0, y: -10 },
                                animate: { opacity: 1, y: 0 },
                                exit: { opacity: 0, y: 10 }
                              }}
                              transition={{
                                duration: 0.5,
                                type: 'spring',
                                stiffness: 150,
                                damping: 18,
                                bounce: 0
                              }}
                              key={content.id}
                              className={`${s.operators__items} ${s.operators__items__create}`}
                            >
                              <div className={s.operators__item}>
                                <FormikSelect
                                  indicatorContainerStyles={{ height: 'auto', width: '2.0625rem' }}
                                  wrapperSelectStyles={{ width: '100%' }}
                                  controlStyles={{
                                    minHeight: '2.0625rem',
                                    maxHeight: '2.0625rem',
                                    border: `1px solid ${ColorsBorder.borderPrimary}`
                                  }}
                                  // hideInputContainerWrapper={{
                                  //   width: 0,
                                  //   margin: 0,
                                  //   padding: 0,
                                  //   maxHeight: 0
                                  // }}
                                  valueContainer={{
                                    height: '2.0625rem',
                                    maxHeight: '2.0625rem',
                                    paddingTop: '0',
                                    paddingBottom: '.25rem',
                                    flexDirection: 'column'
                                  }}
                                  containerStyles={{
                                    width: '8.375rem',
                                    minWidth: !isTablet ? '100%' : 'auto',
                                    height: 'auto',
                                    fontSize: '1rem',
                                    border: 'none'
                                  }}
                                  name={`provider${content.id}`}
                                  options={
                                    // selectUnique()?.slice(itemOffset, endOffset)
                                    selectUnique()?.map((item: any) => ({
                                      ...item,
                                      value: item?.title,
                                      label: item?.title,
                                      singleValue: item?.title,
                                      id: item?.id,
                                      isDisabled: item?.availability === 'Disabled',
                                      genre: ['pop'],
                                      selectedFieldId: `provider${content.id}`,
                                      uniqueId: `uniqueId${content.id}`,
                                      defaultPrice: `defaultPrice${content.id}`,
                                      note: `note${content.id}`
                                    }))}
                                  // isSearchable
                                  placeholder={'Operator'}
                                  indicatorIcon={<DropSelectSVG />}
                                  onChange={(e: any, eMeta: any) => handleChange(e, eMeta, content)}
                                />
                              </div>

                              {content?.priceList?.length > 0 ? (
                                <div className={s.operators__item}>
                                  <FormikSelect
                                    onMenuOpen={() => handlePriceListSelect({ ...content, provider: { id: content?.providerId } })}
                                    indicatorContainerStyles={{
                                      height: 'auto',
                                      width: '2.0625rem'
                                    }}
                                    wrapperSelectStyles={{ width: '100%' }}
                                    controlStyles={{
                                      minHeight: '2.0625rem',
                                      maxHeight: '2.0625rem',
                                      border: `1px solid ${ColorsBorder.borderPrimary}`
                                    }}
                                    // hideInputContainerWrapper={{
                                    //   width: 0,
                                    //   margin: 0,
                                    //   padding: 0,
                                    //   maxHeight: 0
                                    // }}
                                    valueContainer={{
                                      height: '2.0625rem',
                                      maxHeight: '2.0625rem',
                                      paddingTop: '0',
                                      paddingBottom: '.25rem',
                                      flexDirection: 'column'
                                    }}
                                    containerStyles={{
                                      width: '8.375rem',
                                      minWidth: !isTablet ? '100%' : 'auto',
                                      height: 'auto',
                                      fontSize: '1rem',
                                      border: 'none'
                                    }}
                                    name={`product${content.id}`}
                                    options={
                                      // content?.priceList?.slice(itemOffset, endOffset)
                                      content?.priceList?.map((item: any) => ({
                                        ...item,
                                        value: item?.description,
                                        label: item?.description,
                                        singleValue: item?.description,
                                        uniqueId: `uniqueId${content.id}`,
                                        amount: item?.price,
                                        id: item?.id,
                                        isDisabled: item?.availability === 'Disabled',
                                        genre: ['pop']
                                      }))}
                                    // selectPagination={
                                    //   <>
                                    //     <div className={s.paginate__count__mobile}>
                                    //         <p>{dynamicPage}</p> <p className={s.count__divider}>of</p> <p>{pageCount || 1}</p>
                                    //     </div>
                                    //     <div className={s.select__pagination}>
                                    //       <SelectPagination
                                    //         dynamicPage={dynamicPage}
                                    //         handlePageClick={handlePageClick}
                                    //         pageCount={pageCount}
                                    //       />
                                    //     </div>
                                    //   </>
                                    // }
                                    // isSearchable
                                    placeholder={'Product'}
                                    indicatorIcon={<DropSelectSVG />}
                                    onChange={(e: any, eMeta: any) =>
                                      handleChangeProduct(e, eMeta, content)
                                    }
                                  />
                                </div>
                              ) : (
                                <div className={s.operators__placeholder} />
                              )}

                              {isFirstChoosenOperatorsCheck.includes(content?.id) &&
                              !productOperatorCheckList.includes(content?.id) &&
                              content.priceList?.length === 0 &&
                              content?.priceList?.[0]?.provider?.measure !==
                                ProviderMeasure.PACKAGES_AND_MONEY ? (
                                <div className={s.operators__item}>
                                  <FormikTextFieldNumber
                                    inputStyles={{
                                      width: '8.375rem',
                                      height: '2.0625rem',
                                      minWidth: !isTablet ? '100%' : 'auto',
                                      border:
                                        content?.amount < content?.minAmount ||
                                        content?.amount > content?.maxAmount ||
                                        !content?.amount
                                          ? '1px solid red'
                                          : `1px solid ${ColorsBorder.borderPrimary}`
                                    }}
                                    placeholder="Amount"
                                    name={`defaultPrice${content.id}`}
                                    suppressHydrationWarning
                                    min={content.minAmount}
                                    max={content.maxAmount}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onValueChange={(e: any, name: any) =>
                                      handleChangeOnlyMoney(e, content)
                                    }
                                  />
                                </div>
                                ) : null}

                              {content?.priceList?.[0]?.provider?.measure ===
                              ProviderMeasure.PACKAGES_AND_MONEY ? (
                                <div className={s.operators__item}>
                                  <FormikTextFieldNumber
                                    inputStyles={{
                                      width: '8.375rem',
                                      height: '2.0625rem',
                                      minWidth: !isTablet ? '100%' : 'auto',
                                      border:
                                        content?.amount < content?.minAmount ||
                                        content?.amount > content?.maxAmount ||
                                        !content?.amount
                                          ? '1px solid red'
                                          : `1px solid ${ColorsBorder.borderPrimary}`
                                    }}
                                    placeholder="Amount"
                                    value={content.amount || ''}
                                    name={`defaultPrice${content.id}`}
                                    suppressHydrationWarning
                                    min={content.minAmount}
                                    max={content.maxAmount}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onValueChange={(e: any) =>
                                      handleChangePackagesAndMoney(e, content)
                                    }
                                  />
                                </div>
                                ) : null}

                              {isTablet ? (
                                !productOperatorCheckList.includes(content?.id) &&
                                (!isFirstChoosenOperatorsCheck.includes(content?.id) ||
                                  content?.priceList?.length > 1) &&
                                content?.priceList?.[0]?.provider?.measure !==
                                  ProviderMeasure.PACKAGES_AND_MONEY ? (
                                  <div className={s.operator_fill} />
                                  ) : null
                              ) : null}

                              {isFirstChoosenOperatorsCheck.includes(content?.id) &&
                              productOperatorCheckList.includes(content?.id) &&
                              content?.priceList?.[0]?.provider?.measure !==
                                ProviderMeasure.PACKAGES_AND_MONEY ? (
                                <div className={s.operators__item}>
                                  <FormikTextFieldNumber
                                    disabled={
                                      content?.priceList?.length > 0 ||
                                      content?.priceList === undefined
                                    }
                                    inputStyles={{
                                      width: '8.375rem',
                                      height: '2.0625rem',
                                      minWidth: !isTablet ? '100%' : 'auto'
                                    }}
                                    placeholder="Amount"
                                    value={content?.amount || ''}
                                    name={`defaultPrice${content.id}`}
                                    suppressHydrationWarning
                                    min={content.minAmount}
                                    max={content.maxAmount}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                                ) : null}

                              <div className={s.operators__items_icon}>
                                <button
                                  onClick={() => removeOperator(content)}
                                  type="button"
                                  className={s.remove__button}
                                >
                                  <DeleteLightSVG />
                                </button>
                              </div>
                            </motion.div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={s.operators__interactive}>
                    <div className={s.operators__button_add}>
                      <motion.button
                        // onClick={() => addNewOperator()}
                        onClick={() => handleOperatorsEdit()}
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        type="button"
                      >
                        <p>Add+</p>
                      </motion.button>
                    </div>

                    <motion.div
                      initial="hidden"
                      ref={addEmployeeEl}
                      animate={isAppendEmployeesModal ? 'open' : 'hidden'}
                      variants={{
                        hidden: { width: 0, opacity: 0 },
                        open: {
                          width: !isTablet ? '100%' : '23.8125rem',
                          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)',
                          opacity: 1
                        }
                      }}
                      transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                      className={s.employee__window}
                    >
                      <div className={s.employee__window__inner}>
                        <div className={s.header}>
                          <div className={s.header__title}>
                            <h2 className={s.title}>Add employee</h2>
                          </div>
                        </div>

                        <div className={s.employee__search}>
                        <FormikTextField
                        placeholder="Search"
                        name="addEmployeeSearch"
                        value={employeeSearch}
                        id={'addEmployeeSearch'}
                        onChange={onHandleSearch(setEmployeeSearch)}
                      >
                        <div className={s.search__icon}>
                          <LoupeSVG />
                        </div>
                      </FormikTextField>
                        </div>

                        <div className={s.employee__content}>
                          { getEmployeesModel?.getEmployeesList?.length ? [chooseAllEmployees, ...getEmployeesModel?.getEmployeesList]?.map((client: EmployeeList, i: number) => (
                            <motion.div
                              initial="hidden"
                              animate="visible"
                              variants={{
                                hidden: { opacity: 0, x: -50 },
                                visible: {
                                  pathLength: 1,
                                  opacity: 1,
                                  transition: {
                                    delay: i * 0.05,
                                    duration: 0.4,
                                    type: 'spring',
                                    damping: 10
                                  },
                                  x: 0
                                }
                              }}
                              transition={{ duration: 0.7 }}
                              key={client.id}
                              className={s.content__items}
                            >
                              <div className={[s.content__item, client.isCheckboxAll ? s.content__item_all : null].filter((item) => item).join(' ')}>
                                <FormikCheckbox
                                  labelStyles={{
                                    borderRadius: '8px',
                                    backgroundColor: ColorsAccent.accentYellow
                                  }}
                                  label={`${client.name} ${client.surname}`}
                                  id={toJS(client.id)}
                                  title={`${client.name} ${client.surname}`}
                                  name={`${toJS(client.id)}`}
                                  isChecked={ client.isCheckboxAll ?
                                    isAllElementsChecked :

                                    isCheck?.includes(toJS(client.id))
                                }
                                  groupCheckbox
                                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    client.isCheckboxAll ? handleCheckAll(e) : handleCheck(e, toJS(client))
                                  }
                                />

                                <div className={s.group__icons}>
                                  {toJS(client?.group?.title) ? (
                                    <div className={s.group__icon}>
                                      <GroupsMarkSVG />
                                    </div>
                                  ) : null}

                                  { client.isCheckboxAll ?
                                    null
                                    :
                                  <RouterLink to={`/employees/employees-detail/${client.id}`} state={{ previousPageWithQuery: '/employees' }}>
                                    <div className={s.item__icon}>
                                      <LinkSVG />
                                    </div>
                                  </RouterLink>}
                                </div>

                              </div>
                            </motion.div>
                          ))
                            :
                            <div className={s.empty_message}>
                              <p>There are no employees</p>
                            </div>
                        }
                        </div>

                        <div className={s.buttons}>
                          <motion.button
                            whileTap={{ scale: 0.95 }}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                            onClick={concatNewIsCheckGroupValues}
                            type="button"
                          >
                            {/* TODO: Make add flaxible, now work incorrectly */}
                            <p>Add/Remove</p>
                          </motion.button>
                          <motion.button
                            whileTap={{ scale: 0.95 }}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                            type="button"
                            onClick={() => setIsAppendEmployeesModal(false)}
                          >
                            <p>Cancel</p>
                          </motion.button>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </>
            )}
          </FormikContainer>
        </AnimatedDivPage>
      </div>
    </section>
  );
};

export const GroupsDetail = observer(GroupsDetailView);
import React from 'react';

export const RepeatSchedule = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.163 11.439L20.476 9.751L18.788 11.439" strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path
      d="M20.475 9.751V12.001C20.475 16.972 16.446 21 11.475 21C6.50398 21 2.47498 16.971 2.47498 12C2.47498 7.029 6.50398 3 11.475 3C14.315 3 16.845 4.319 18.494 6.375"
      strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.846 14.87L11.194 12.697V7.985" strokeWidth="1" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>

);

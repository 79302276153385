/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { useTranslation } from 'react-i18next';
import { GroupsController } from 'controllers/groups';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikContainer from 'components/form/containers';
import FormikSelect from 'components/form/fields/select';
import { DeleteLightSVG } from 'assets/icons/deleteLight';
import { ColorsAccent, ColorsBorder } from 'common/colors';
import { ProviderMeasure } from 'common/constants/payment';
import { EmployeesController } from 'controllers/employees';
import { Providers, Scenarios } from 'models/providers/types';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import FormikTextField from 'components/form/fields/inputs/index';
import { ModalBackdrop } from 'components/modal/modalBackdrop/index';
import { validateString } from 'common/constants/validate/operatorNumber';
import { IOperatorsTableContent } from 'common/constants/employeesDetail/types';
import FormikTextFieldNumber from 'components/form/fields/inputs/inputNumber/index';
import { KindOfPage, KindOfScenarios } from 'common/constants/additionalOperatorModal';
import { handleAppendNewOperators } from './functions';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  setChoosenItems: (items: any[]) => void;
  choosenItems: any[];
  hideModal: () => void;
  backdropEl: any;
  setCreateOperatorsGroup: (operators: any) => void;
  createOperatorsGroup: any[];
  currentOperatorData?: any;
  currentKindOfPage: string;
  uniqueProviders: any[];
  employees: EmployeesController;
  groups: GroupsController;
}

const ModalAdditionFullOperatorView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, setCreateOperatorsGroup, createOperatorsGroup, currentOperatorData, uniqueProviders, setChoosenItems, backdropEl, choosenItems, currentKindOfPage, employees, groups } = props;
  const { providers, priceList } = useStores();
  const { t } = useTranslation();
  const { getProvidersModel, getProvidersAsyncState } = providers;
  const { getPriceListModel, getPriceListDetailAsyncState } = priceList;
  const { isTablet } = useWindowDimensions();
  const [isDefaultAmountFromModal, setIsDefaultAmountFromModal] = useState(true);
  const containerEl = useRef<any>(null);
  const backdropWrapperEl = useRef<any>(null);
  const [scenarios, setScenarios] = useState<any[]>( currentOperatorData?.provider?.scenarios?.length ? currentOperatorData?.provider?.scenarios : []);

  const [defaultScenario, setDefaultScenario] = useState<any>(currentOperatorData?.defaultScenario || {});
  const [activateAdditionalFields, setActivateAdditionalFields] = useState<any>(false);
  const [operatorIdCount, setOperatorIdCount] = useState<number>(0);
  const [createNewOperator, setCreateNewOperator] = useState<any[]>( currentOperatorData ? [currentOperatorData] : [{
    operatorName: `operator${operatorIdCount}`,
    operatorUniqueIdName: `uniqueId${operatorIdCount}`,
    amountName: `amount${operatorIdCount}`,
    defaultPrice: `defaultPrice${operatorIdCount}`,
    noteName: `note${operatorIdCount}`,
    id: operatorIdCount
  }]);

  const validateSchema = Yup.object().shape({
    ...[ currentOperatorData, ...createNewOperator]?.map((operator: any) => {

      if ((!operator?.amount || operator?.amount < operator.minAmount || operator?.amount > operator.maxAmount) && operator?.amount) {
        return { [`defaultPrice${operator?.provider?.id}`]: Yup.object().shape({
          amount: Yup.number().min(
            operator.minAmount,
            `Must be greater than or equal to ${operator.minAmount}`
          )
            .max(
              operator.maxAmount,
              `Must be less than or equal to ${operator.maxAmount}`
            )
        }) };
      }

      if (operator?.provider?.isPhoneNumber) {
        return {
          [`uniqueId${operator?.provider?.id}`]: Yup.string().test(
            'validateString',
            'The customer number must match phone number (contain 10 numbers and start from 0).',
            // @ts-ignore
            (value) => validateString(value)
          ).notRequired()
        };
      }

      // if (operator?.scenarios?.length) {
      //   return { [`scenario${operator?.provider?.id}`]: Yup.object().shape({
      //     value: Yup.string().required("Scenario it's required field"),
      //   }) };
      // }

      return { provider0: Yup.object().shape({
        value: Yup.string().required("Provider it's required field")
      }) };
    }
    ).reduce((acc: any, item: any) => Object.assign(acc, item), {}),
  });

  const [productOperatorCheckList, setProductOperatorCheckList] = useState<any[]>(currentOperatorData?.provider?.id && currentOperatorData?.defaultScenario?.type !== KindOfScenarios.AMOUNT ? [currentOperatorData?.provider?.id?.split('.')?.join('')] : []);
  const [isFirstChoosenOperatorsCheck, setIsFirstChoosenOperatorsCheck] = useState<any[]>(currentOperatorData?.provider?.id ? [currentOperatorData?.provider?.id?.split('.')?.join('')] : []);
  const isGetDetailPriceListLoading = getPriceListDetailAsyncState.getLoadingState;
  const isGetProvidersLoading = getProvidersAsyncState.getLoadingState;
  const removeDuplicates = (data: any, key: any) => [
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];

  const onAddNewOperator = (values: any) => {
    handleAppendNewOperators[currentKindOfPage]({ createNewOperator, currentOperatorData, createOperatorsGroup, setCreateOperatorsGroup, employees, groups });
    hideModal();
  };

  const handleProviderSelect = () => {
    providers.getProvidersAction();
    // setItemOffset(0);
    // setEndOffset(8);
  };
  
  const selectUnique = () =>
    toJS(getProvidersModel?.getProvidersList)
      .filter(
        (allProviders: any) =>
          !createOperatorsGroup?.map((provider: any) => provider?.provider?.id).includes(allProviders.id)
      )
      .sort((a: any, b: any) => {
        if (a?.title < b?.title) {
          return -1;
        }
        if (a?.title > b?.title) {
          return 1;
        }

        return 0;
      }
      );

  const handleChange = (e: any, eMeta: any, content: any, setFieldValue: any) => {
    priceList?.getPriceListDetailAction({ id: e?.id, quantity: 9999 });
    // Clear price lists field after update providers
    const replaceObjectDep = `${e?.id}`?.split('.')?.join('');
    setFieldValue(`product${replaceObjectDep}`, '');
    setFieldValue(`scenario${replaceObjectDep}`, '');
    setFieldValue(`uniqueId${replaceObjectDep}`, '');
    setActivateAdditionalFields(true);
    setScenarios(e?.scenarios);
    setDefaultScenario({});
    // TODO: Работать с данными, сделать все необходимые данные для createOperatorsGroup, или создать аналог + сделать уникальные id для полей 
    setCreateNewOperator(
      createNewOperator?.map((account: any) => {
        account.amount = '';
        account.minAmount = e.minAmount;
        account.maxAmount = e.maxAmount;
        account.providerId = e?.id;
        account.defaultPriceList = null;
        account.defaultPriceListId = null;
        account.scenarios = e?.scenarios?.length ? e?.scenarios : null;
        account.operatorName = `operator${replaceObjectDep}`;
        account.scenarioName = `scenario${replaceObjectDep}`;
        account.operatorUniqueIdName = `id${replaceObjectDep}`;
        account.defaultPrice = `defaultPrice${replaceObjectDep}`;
        account.defaultPriceId = `defaultPrice${replaceObjectDep}`;
        account.providerInitialId = `provider${replaceObjectDep}`;
        account.selectedFieldId = `provider${replaceObjectDep}`;
        account.uniqueId = `uniqueId${replaceObjectDep}`;
        account.uniqueIdValue = '';
        account.productId = '';
        account.amountName = `amount${replaceObjectDep}`;
        account.noteName = `note${replaceObjectDep}`;
        account.isDisabled = e?.availability === 'Disabled';
        account.provider = {
          id: e?.id,
          availability: e?.availability,
          minAmount: e?.minAmount,
          maxAmount: e?.maxAmount,
          measure: e?.measure,
          title: e?.title,
          inputPatterns: e?.inputPatterns,
          validatePatterns: e?.validatePatterns,
          scenarios: e?.scenarios,
          isPhoneNumber: e?.isPhoneNumber
        };
        account[`provider${replaceObjectDep}`] = {
          id: e?.id,
          availability: e?.availability,
          minAmount: e?.minAmount,
          maxAmount: e?.maxAmount,
          measure: e?.measure,
          title: e?.title,
          inputPatterns: e?.inputPatterns,
          validatePatterns: e?.validatePatterns,
          scenarios: e?.scenarios
        };
        account.isDefaultOperatorValue = true;
        account.isDefaultIdValue = true;
        account.isDefaultProductValue = true;
        account.isDefaultAmountValue = true;
        account.defaultValueEmployee = true;
        return account;
      }));

    setChoosenItems(removeDuplicates([...choosenItems, e], (key: any) => key.selectedFieldId));
    setIsFirstChoosenOperatorsCheck([replaceObjectDep]);
  };
  const handleChangeId = (e: any, provider: any, setFieldValue: any) => {
    if (provider?.isPhoneNumber) {
      setFieldValue(`uniqueId${provider?.id}`?.split('.')?.join(''), `${e.target.value[0] !== '0' ? `${!e.target.value ? '' : '0'}${e.target.value}` : e.target.value}`);
    }

    setCreateNewOperator(
      createNewOperator.map((item: any) => {
        item.providersData = { providerId: e.target.value };
        item.uniqueIdValue = e.target.value;
        item.defaultValueEmployee = true;

        return item;
      })
    );
  };

  const handleCheckType = (e: Scenarios, eMeta: any, content: any, setFieldValue: any) => {
    setDefaultScenario(e);
    const replaceObjectDep = `${content?.provider?.id}`?.split('.')?.join('');
    setFieldValue(`product${replaceObjectDep}`, '');
    setFieldValue(`defaultPrice${replaceObjectDep}`, '');
    setCreateNewOperator(
      createNewOperator.map((account: any) => {
        account.defaultScenario = { 
          label: e?.label, 
          id: e?.id,
          createAt: e?.createAt,
          deletedAt: e?.deletedAt,
          type: e?.type
        };
        account.amount = '';
        account.productId = '';
        account.defaultPriceList = null;
        account.defaultPriceListId = null;
        account.defaultValueEmployee = true;

        return account;
      })
    );
  };

  const handlePriceListSelect = (content: any) => {
    priceList.getPriceListDetailAction({ id: content.provider.id, quantity: 9999 });
    // setItemOffset(0);
    // setEndOffset(8);
  };

  const handleChangeProduct = (e: any, eMeta: any, content: any) => {

    const replaceObjectDep = `${e.provider.id}`?.split('.')?.join('');
    setCreateNewOperator(
      createNewOperator.map((account: any) => {
        account.amount = +e?.price;
        account.defaultPriceListId = e?.id;
        account.productId = `product${replaceObjectDep}`;
        account.defaultPriceList = {
          id: e?.id,
          description: e?.description,
          label: e?.label,
          price: +e?.price,
          provider: toJS(e?.provider)
        };
        account[`defaultPrice${replaceObjectDep}`] = +e?.price;
        account[`provider${replaceObjectDep}`] = toJS(e?.provider);
        account.defaultValueEmployee = true;

        return account;
      })
    );

    setProductOperatorCheckList([content?.provider?.id?.split('.')?.join('')]);
    setIsDefaultAmountFromModal(false);
  };

  const handleChangePackagesAndMoney = (e: any, content: any) => {
    setCreateNewOperator(
      createNewOperator.map((item: any) => {
        item.amount = e || null;
        item.defaultPriceListId = null;
        item.isDefaultValue = false;
        item.defaultPriceList = null;
        item.defaultValueEmployee = true;

        return item;
      })
    );

    setIsDefaultAmountFromModal(false);
  };

  const handleChangeOnlyMoney = (e: any, content: any) => {
    setCreateNewOperator(
      createNewOperator.map((item: any) => {
        item.amount = e || null;
        item.defaultPriceListId = null;
        item.defaultPriceList = null;
        item.defaultValueEmployee = true;

        return item;
      })
    );
    setIsDefaultAmountFromModal(false);
  };
  // TODO: После первого добавления оператора через модальное окно, последующие операторы имеют ту же структуру что и предыдущий, посмотреть и исключить

  useEffect(() => {

    if (currentOperatorData?.provider?.id) {
      priceList?.getPriceListDetailAction({ id: currentOperatorData?.provider?.id, quantity: 9999 });
    }

    return () => {
      getProvidersAsyncState.reset();
      getPriceListDetailAsyncState.reset();
      getPriceListModel.resetPriceListDetail();
    };
  }
  , [currentOperatorData]);

  useEffect(() => {
    const isOpenInteractiveList = (e: any) => 
      !containerEl?.current?.contains(e.target) &&
      backdropWrapperEl?.current?.contains(e.target) &&
      hideModal();

    document.addEventListener('click', isOpenInteractiveList);
    return () => document.removeEventListener('click', isOpenInteractiveList);
  }, []);

  return (
    <div className={s.backdrop} ref={backdropWrapperEl}>
      <div className={s.wrapper__container} ref={containerEl}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>{ currentOperatorData ? t<string>('MAIN.MODALADDITIONALOPERATORS.EDITTITLE') : t<string>('MAIN.MODALADDITIONALOPERATORS.CREATETITLE') }</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <FormikContainer
            initialValues={ currentOperatorData?.provider?.id ? {
              provider0: { value: currentOperatorData?.provider?.id, singleValue: currentOperatorData?.provider?.title, label: currentOperatorData?.provider?.title },
              [`product${currentOperatorData?.provider?.id}`?.split('.')?.join('')]: currentOperatorData?.defaultPriceList ? { value: currentOperatorData?.defaultPriceList?.price, singleValue: currentOperatorData?.defaultPriceList?.label, label: currentOperatorData?.defaultPriceList?.label } : null,
              [`provider${currentOperatorData?.provider?.id}`?.split('.')?.join('')]: { value: currentOperatorData?.provider?.id, singleValue: currentOperatorData?.provider?.title, label: currentOperatorData?.provider?.title },
              [`scenario${currentOperatorData?.provider?.id}`?.split('.')?.join('')]: currentOperatorData?.defaultScenario?.id ? { value: currentOperatorData?.defaultScenario?.id, singleValue: currentOperatorData?.defaultScenario?.label, label: currentOperatorData?.defaultScenario?.label } : null,
            }
              :
              {}
        }
            onSubmit={onAddNewOperator}
            // enableReinitialize
            validationSchema={validateSchema}
          >
            {({ errors, setFieldValue }) => (

        <div className={s.main}>
          <div className={s.operator}>
            <>
              {/* Add new provider field */}
              {createNewOperator.map((content: IOperatorsTableContent | any, index: number) => (
                <div key={content?.id || index + 1}>
                  <div className={`${s.operator__create_id} ${s.operator__create_select}`}>
                    <FormikSelect
                      portalTargetDeactivate
                      loadingMessage={isGetProvidersLoading}
                      onMenuOpen={handleProviderSelect
                        // handlePriceListSelect({ id: currentOperatorData.provider?.id })
                      }
                      containerStyles={{
                        width: '100%',
                        maxWidth: '100%'
                      }}
                      valueContainer={{
                        paddingLeft: '.5rem'
                      }}
                      selectWrapperStyles={{
                        width: '100%'
                      }}
                      indicatorContainerStyles={{ height: 'auto', width: '2.5rem' }}
                      valueStyles={{
                        color: ColorsAccent.accentDark
                      }}
                      id="operator"
                      name={`provider${content?.id}`?.split('.')?.join('')}
                      options={
                        // selectUnique()?.slice(itemOffset, endOffset)
                        uniqueProviders?.map((item: any) => ({
                          ...item,
                          value: item?.title,
                          label: item?.title,
                          singleValue: item?.title,
                          id: item?.id,
                          isDisabled: item?.availability === 'Disabled',
                          genre: ['pop'],
                          selectedFieldId: `provider${item?.id}`,
                          uniqueId: `uniqueId${item?.id}`,
                          defaultPrice: `defaultPrice${item?.id}`,
                          note: `note${item?.id}`
                        }))
                      }
                      placeholder={t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.OPERATORPLACEHOLDER')}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.OPERATOR')}</p>}
                      indicatorIcon={<DropSelectSVG />}
                      // onChange={handleChooseAmount}
                      onChange={(e: any, eMeta: any) => handleChange(e, eMeta, content, setFieldValue)}
                    />
                  </div>

                  { currentKindOfPage !== KindOfPage.CREATE_GROUP_PAGE && currentKindOfPage !== KindOfPage.DETAIL_GROUP_PAGE ?
                  <div className={s.operator__create_id}>
                    <FormikTextField
                      name={`uniqueId${content?.provider?.id}`?.split('.')?.join('')}
                      placeholder={t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.IDPLACEHOLDER')}
                      value={content.uniqueIdValue}
                      maxLength={content?.provider?.isPhoneNumber ? 10 : undefined}
                      type="text"
                      // required
                      // onChange={checkUniqueIdField}
                      onChange={(e: any) => handleChangeId(e, content?.provider, setFieldValue)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.ID')}</p>}
                    />
                  </div>
                    :
                    null}

                    { scenarios?.length ?
                      <div className={`${s.operator__create_id} ${s.operator__create_select}`}>
                        <FormikSelect
                        portalTargetDeactivate
                        // loadingMessage={isGetProvidersLoading}
                        // onMenuOpen={
                          // handleProviderSelect
                          // handlePriceListSelect({ id: currentOperatorData.provider?.id })
                        // }
                        containerStyles={{
                          width: '100%',
                          maxWidth: '100%',
                        }}
                        valueContainer={{
                          paddingLeft: '.5rem'
                        }}
                        selectWrapperStyles={{
                          width: '100%'
                        }}
                        indicatorContainerStyles={{ height: 'auto', width: '2.5rem' }}
                        valueStyles={{
                          color: ColorsAccent.accentDark
                        }}
                        id="scenario"
                        name={`scenario${content?.provider?.id}`?.split('.')?.join('')}
                        options={
                          [{
                            value: '',
                            label: t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.SCENARIOPLACEHOLDER'),
                            createAt: '',
                            deletedAt: '',
                            singleValue: t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.SCENARIOPLACEHOLDER'),
                            id: null,
                            type: ''
                          },
                          ...scenarios]
                        }
                        placeholder={t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.SCENARIOPLACEHOLDER')}
                        label={<p className={s.operator__create_label}>{t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.SCENARIO')}</p>}
                        indicatorIcon={<DropSelectSVG />}
                        onChange={(e: any, eMeta: any) => handleCheckType(e, eMeta, content, setFieldValue)}
                      />
                    </div>
                      :
                      null
                    }

                  <div className={s.operator__create_id}>
                  {
                  (!scenarios?.length ? getPriceListModel.getPriceListDetail.length > 0 : defaultScenario?.type === KindOfScenarios.PRICELIST && getPriceListModel.getPriceListDetail.length > 0)
                  // getProvidersAsyncState?.getLoadingState === Loading.success
                    ? (
                    <div className={`${s.operator__create_id} ${s.operator__create_select}`}>
                      <FormikSelect
                      portalTargetDeactivate
                      loadingMessage={isGetDetailPriceListLoading}
                      onMenuOpen={() =>
                        handlePriceListSelect(content)
                      }
                      containerStyles={{
                        width: '100%',
                        maxWidth: '100%'
                      }}
                      valueContainer={{
                        paddingLeft: '.5rem'
                      }}
                      selectWrapperStyles={{
                        width: '100%'
                      }}
                      indicatorContainerStyles={{ height: 'auto', width: '2.5rem' }}
                      valueStyles={{
                        color: ColorsAccent.accentDark
                      }}
                      id="product"
                      name={`product${content?.provider?.id}`?.split('.')?.join('')}
                        options={
                          // content?.priceList?.slice(itemOffset, endOffset)
                          [ {
                            value: '',
                            label: t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.PRODUCTPLACEHOLDER'),
                            singleValue: t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.PRODUCTPLACEHOLDER'),
                            uniqueId: '',
                            amount: null,
                            id: t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.PRODUCTPLACEHOLDER'),
                            provider: content?.provider,
                            // isDisabled: false,
                            description: t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.PRODUCTPLACEHOLDER'),
                            genre: ['pop'],
                            price: null
                          },
                          ...getPriceListModel.getPriceListDetail?.map((item: any) => ({
                            ...item,
                            value: item?.description,
                            label: item?.description,
                            singleValue: item?.description,
                            uniqueId: `uniqueId${content?.id}`,
                            amount: item?.price,
                            id: item?.id,
                            isDisabled: item?.availability === 'Disabled',
                            genre: ['pop']
                          })) ]
                        }
                      placeholder={t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.PRODUCTPLACEHOLDER')}
                      indicatorIcon={<DropSelectSVG />}
                      onChange={(e: any, eMeta: any) => handleChangeProduct(e, eMeta, content)}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.PRODUCT')}</p>}
                    />
                    </div>
                    ) : (
                    <div className={s.operators__placeholder} />
                    )}
                  </div>

                  <div className={`${s.operator__create_id} ${s.operator__create_amount}`}>
                    { getPriceListModel.getPriceListDetail && getPriceListModel.getPriceListDetail.length > 0 && ((((!scenarios?.length ? (isFirstChoosenOperatorsCheck.includes(content?.provider?.id?.split('.')?.join('')) &&
                    productOperatorCheckList.includes(content?.provider?.id?.split('.')?.join('')) &&
                    getPriceListModel.getPriceListDetail?.[0]?.provider?.measure !==
                      ProviderMeasure.PACKAGES_AND_MONEY) : (defaultScenario?.type === KindOfScenarios.PRICELIST) &&
                      (isFirstChoosenOperatorsCheck.includes(content?.provider?.id?.split('.')?.join('')) &&
                      productOperatorCheckList.includes(content?.provider?.id?.split('.')?.join('')) &&
                      getPriceListModel.getPriceListDetail?.[0]?.provider?.measure !==
                      ProviderMeasure.PACKAGES_AND_MONEY)) && +content?.amount)) && content?.provider?.measure ===
                      ProviderMeasure.PACKAGES) ? (
                      <div className={s.operators__item}>
                        <FormikTextFieldNumber
                        name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                        disabled={getPriceListModel.getPriceListDetail?.length > 0 || getPriceListModel.getPriceListDetail === undefined}
                        placeholder={t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.AMOUNTPLACEHOLDER')}
                        value={+content?.amount || ''}
                        suppressHydrationWarning
                        min={+content.minAmount}
                        max={+content.maxAmount}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        label={<p className={s.operator__create_label}>{t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.AMOUNT')}</p>}
                        onValueChange={(e: any, name: any) => handleChangeOnlyMoney(e, content)}
                      />
                      </div>
                      ) : null}

                      {getPriceListModel.getPriceListDetail && getPriceListModel.getPriceListDetail.length > 0 && ((scenarios?.length && getPriceListModel.getPriceListDetail?.[0]?.provider?.measure ===
                      ProviderMeasure.PACKAGES_AND_MONEY && defaultScenario?.type === KindOfScenarios.PRICELIST && productOperatorCheckList.includes(content?.provider?.id?.split('.')?.join('')) && +content?.amount) && content?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY) ?
                      <div className={s.operators__item}>
                      <FormikTextFieldNumber
                      name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                      disabled={getPriceListModel.getPriceListDetail?.length > 0 || getPriceListModel.getPriceListDetail === undefined}
                      placeholder={t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.AMOUNTPLACEHOLDER')}
                      value={+content?.amount || ''}
                      suppressHydrationWarning
                      min={+content.minAmount}
                      max={+content.maxAmount}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.AMOUNT')}</p>}
                      onValueChange={(e: any, name: any) => handleChangeOnlyMoney(e, content)}
                    />
                    </div>
                        :
                        null}

                    { getPriceListModel.getPriceListDetail && getPriceListModel.getPriceListDetail.length > 0 && (((!scenarios?.length ? (getPriceListModel.getPriceListDetail?.[0]?.provider?.measure ===
                    ProviderMeasure.PACKAGES_AND_MONEY) : defaultScenario?.type === KindOfScenarios.AMOUNT) && (getPriceListModel.getPriceListDetail?.[0]?.provider?.measure ===
                      ProviderMeasure.PACKAGES_AND_MONEY)) && content?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY) ? (
                      <div className={s.operators__item}>
                        <FormikTextFieldNumber
                        inputStyles={{
                          border:
                            content?.amount < content?.minAmount ||
                            content?.amount > content?.maxAmount ||
                            !content?.amount
                              ? '1px solid red'
                              : `1px solid ${ColorsBorder.borderPrimary}`
                        }}
                        name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                        placeholder={t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.AMOUNTPLACEHOLDER')}
                        value={content.amount || ''}
                        // defaultValue={}
                        suppressHydrationWarning
                        min={+content.minAmount}
                        max={+content.maxAmount}
                        // onKeyPress={(event) => {
                        //   if (!/[0-9]/.test(event.key)) {
                        //     event.preventDefault();
                        //   }
                        // }}
                        label={<p className={s.operator__create_label}>{t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.AMOUNT')}</p>}
                        onValueChange={(e: any, name: any) =>
                          handleChangePackagesAndMoney(e, content)
                        }
                        />
                      </div>
                      ) : null}

                    {isTablet && getPriceListModel.getPriceListDetail && getPriceListModel.getPriceListDetail.length > 0 ? (
                      !productOperatorCheckList.includes(content?.provider?.id?.split('.')?.join('')) &&
                      (!isFirstChoosenOperatorsCheck.includes(content?.provider?.id?.split('.')?.join('')) ||
                      getPriceListModel.getPriceListDetail?.length > 1) &&
                      getPriceListModel.getPriceListDetail?.[0]?.provider?.measure !==
                        ProviderMeasure.PACKAGES_AND_MONEY ? (
                        <div className={s.operator_fill} />
                        ) : null
                    ) : null}

                    { (!scenarios?.length ? true : defaultScenario?.type === KindOfScenarios.AMOUNT) &&
                    getPriceListModel.getPriceListDetail?.length === 0
                    && content?.provider?.measure ===
                    ProviderMeasure.MONEY
                      ? (
                      <div className={s.operators__item}>
                        {/* TODO: Тест инпута с плавающей точкой */}
                        <FormikTextFieldNumber
                        disabled={getPriceListModel.getPriceListDetail?.length > 0 || getPriceListModel.getPriceListDetail === undefined}
                        value={content.amount || ''}
                        inputStyles={{
                          border:
                            content?.amount < content?.minAmount ||
                            content?.amount > content?.maxAmount ||
                            !content?.amount
                              ? '1px solid red'
                              : `1px solid ${ColorsBorder.borderPrimary}`
                        }}
                        placeholder={t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.AMOUNTPLACEHOLDER')}
                        // defaultValue={}
                        name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                              suppressHydrationWarning
                              min={+content.minAmount}
                              max={+content.maxAmount}

                        // onKeyPress={(event) => {
                        //   if (!/[0-9]/.test(event.key)) {
                        //     event.preventDefault();
                        //   }
                        // }}
                        label={<p className={s.operator__create_label}>{t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.AMOUNT')}</p>}
                        onValueChange={(e: any, name: any) => handleChangeOnlyMoney(e, content)}
                      />
                      </div>
                      ) : null}
                    </div>

                  </div>
              ))}
            </>

            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onSubmit={onAddNewOperator}
                value="Submit employee add"
                formAction="/submit/a"
                type="submit"
              >
                <p>{t<string>('MAIN.MODALADDITIONALOPERATORS.BUTTONS.SUBMIT')}</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>{t<string>('MAIN.MODALADDITIONALOPERATORS.BUTTONS.CANCEL')}</p>
              </motion.button>
            </div>
          </div>
        </div>
            )}
        </FormikContainer>
      </div>
    </div>
  );
};

export const ModalAdditionOperatorFull = observer(ModalAdditionFullOperatorView);

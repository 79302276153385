/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormikContext, useField } from 'formik';
import { MarkSVG } from 'assets/icons/mark';
import { IFormikMuiCheckBox } from './types';
import s from './index.module.scss';

const FormikCheckbox = (props: IFormikMuiCheckBox): ReactElement => {
  // eslint-disable-next-line no-unused-vars
  const {
    label = '',
    name = '',
    icon,
    onChange,
    type,
    title,
    labelStyles,
    wrapperStyles,
    labelTextStyles,
    checkboxInnerItem,
    isChecked,
    groupCheckbox,
    ...other
  } = props;
  const [field] = useField(name);
  const form = useFormikContext();
  const inputValue = groupCheckbox ? isChecked : field.value;
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue(name, e.target.checked);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <label style={{ ...wrapperStyles }} htmlFor={name} className={s.wrapper__checkbox}>

      { checkboxInnerItem || <label
          className={[s.label__input, inputValue ? s.checked : null ].join(' ')}
          style={{
            ...labelStyles,
            marginRight: !icon ? '0.75rem' : '0',
            backgroundColor: inputValue ? labelStyles?.backgroundColor : '',
            border: inputValue && 'none'
          }}
          htmlFor={name}
      >
        {inputValue ? <MarkSVG /> : null}
          <input
              type="checkbox"
              checked={inputValue}
              onChange={handleChange}
              id={name}
              name={name}
              {...other}
          />
      </label>}

      { icon &&
          <div className={s.icon}>
            { icon }
          </div>
      }


      {label && (
        <label style={{ ...labelTextStyles }} className={s.label__title} htmlFor={name}>
          <p>{title}</p>
        </label>
      )}
    </label>
  );
};

export default observer(FormikCheckbox);

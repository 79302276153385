/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { GroupsModel } from 'models/groups/types';
import { DeleteMemberSVG } from 'assets/icons/deleteMember';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { GroupsController } from 'controllers/groups';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  setIsCheck: (checkItems: any) => void;
  setIsCheckGroup: (CheckGroupItems: any) => void;
  setIsCheckGroupList: (CheckGroupItemsList: any) => void;
  setIsAppendEmployeesModal: (isOpen: boolean) => void;
  isAppendEmployeesModal?: any;
  isCheck: any[];
  isCheckGroup: any[];
  isCheckGroupList: any[];
  employee: any;
  id: any[];
  modalRefEl: any;
  groups: GroupsController;
  getGroupsModel: GroupsModel;
  removeFromWindow: boolean;
}

const ModalRemoveAllEmployeeFromGroupView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    isCheck,
    isCheckGroup,
    isCheckGroupList,
    setIsCheck,
    setIsCheckGroup,
    setIsCheckGroupList,
    employee,
    groups,
    id,
    modalRefEl,
    isAppendEmployeesModal,
    removeFromWindow,
    setIsAppendEmployeesModal,
    getGroupsModel
  } = props;

  const changeGroupCheck = () => {
    setIsCheck([]);
    setIsCheckGroup([]);
    setIsCheckGroupList([]);

    if (getGroupsModel) {
      const data: any = {
        groupId: toJS(getGroupsModel.getGroupDetail).id,
        title: toJS(getGroupsModel.getGroupDetail).title,
        description: toJS(getGroupsModel.getGroupDetail).description,
        parentId: toJS(getGroupsModel.getGroupDetail).parent_id,
        employeesId: [],
        accounts: toJS(getGroupsModel.getGroupDetail).groupsAccounts.reduce(
          (acc: any, item: any) => {
            acc.push({
              defaultPrice: item.defaultPrice,
              providerId: item.provider.id,
              priceListId: item.priceList?.id || null,
              defaultScenario: item?.defaultScenario?.id || null
            });
            return acc;
          },
          []
        )
      };

      groups.updateGroupsAction(data);
    }

    hideModal();
  };

  useEffect(() => () => isAppendEmployeesModal ? setIsAppendEmployeesModal(true) : setIsAppendEmployeesModal(false), [isAppendEmployeesModal]);

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.close} onClick={() => hideModal()}>
          <CrossSVG />
        </div>
        <div className={s.header}>
          <div className={s.header__title}>
            <div className={s.header__title_icon}>
              <DeleteMemberSVG />
            </div>
            <h2 className={s.title}>Delete employee</h2>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              Do you really want to delete all employees ?
            </p>
          </div>

          <div className={s.employee}>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={changeGroupCheck}
              >
                <p>Yes</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>No</p>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalRemoveAllEmployeeFromGroup = observer(ModalRemoveAllEmployeeFromGroupView);

import { ColorsText } from 'common/colors';
import React from 'react';

export const CalendarSVG = () =>
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 2.27121H12.75V0.77121H11.25V2.27121H3.75V0.77121H2.25V2.27121H1.5C0.675 2.27121 0 2.94621 0 3.77121V15.7712C0 16.5962 0.675 17.2712 1.5 17.2712H13.5C14.325 17.2712 15 16.5962 15 15.7712V3.77121C15 2.94621 14.325 2.27121 13.5 2.27121ZM13.5 15.7712H1.5V6.02121H13.5V15.7712Z"
      fill="#333333"/>
  </svg>
;

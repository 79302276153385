import React from 'react';

export const ImportSVG = () => (
  <svg
    version="1.1"
    id="Слой_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlSpace="preserve"
  >
    <path
      className="st0"
      d="M1,13v1c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3v-1 M9,13.4l-4-4 M13,9.4l-4,4 M9,1v12"
    />
  </svg>
);

import React from 'react';

export const GroupArrowSVG = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.993 2.049C12.993 1.5 12.549 1.053 11.999 1.05L4.00619 1H4.00019C3.4502 1 3.00421 1.444 3.00021 1.994C2.99621 2.546 3.4412 2.997 3.99419 3L9.55207 3.035L1.29324 11.293C0.902252 11.684 0.902252 12.317 1.29324 12.707C1.48824 12.903 1.74423 13 2.00023 13C2.25622 13 2.51222 12.903 2.70721 12.707L10.995 4.419L11 10.001C11 10.553 11.448 11 12 11H12.001C12.553 11 13 10.551 13 9.999L12.993 2.049Z"
      fill="#1a1a1a"
      stroke="#FFCA00"
    />
  </svg>
);

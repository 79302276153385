import React, { ReactElement, useEffect } from 'react';
import { FormErrorContainerType } from './types';

const FormErrorContainer = ({
  setErrors,
  children,
  serverErrors = {}
}: FormErrorContainerType): ReactElement => {
  useEffect(() => {
    setErrors(serverErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverErrors]);

  return <>{children}</>;
};

export default FormErrorContainer;

import { SortArrowSVG } from 'assets/icons/sortArrow';
import React from 'react';

import { IOperatorsTableTitles } from './types';

export const operatorsTableTitles = (t: any): IOperatorsTableTitles[] => [
  {
    name: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADER.NAME'),
    data: 'title',
    icon: <SortArrowSVG />,
    id: 0
  },
  {
    name: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADER.ID'),
    data: 'uniqueId',
    id: 1
  },
  {
    name: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADER.PRODUCT'),
    data: 'product',
    id: 2
  },
  {
    name: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADER.AMOUNT'),
    data: 'amount',
    icon: <SortArrowSVG />,
    id: 3
  }
];

export const operatorsTableEmployeeDetailTitles = (t: any): any[] => [
  {
    name: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADERMOBILE.NAME'),
    data: 'title',
    icon: <SortArrowSVG />,
    id: 0
  },
  {
    name: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADERMOBILE.ID'),
    data: 'uniqueId',
    id: 1
  },
  {
    name: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADERMOBILE.PRODUCT'),
    data: 'product',
    id: 2
  },
  {
    name: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADERMOBILE.AMOUNT'),
    data: 'amount',
    icon: <SortArrowSVG />,
    id: 3
  }
];

export const selectSortByTitles = (t: any) => [
  {
    label: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.SORTMOBILE.NAME'),
    singleValue: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.SORTMOBILE.NAME'),
    value: 'name',
    id: 1
  },
  {
    label: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.SORTMOBILE.AMOUNT'),
    singleValue: t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.SORTMOBILE.AMOUNT'),
    value: 'amount',
    id: 2
  }
];

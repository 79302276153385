/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useRef, useEffect, useState, FC, ChangeEvent, memo, useMemo } from 'react';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { Field } from 'formik';
import { useStores } from 'store';
import { Loading } from 'store/types';
import ReactTooltip from 'react-tooltip';
import MaskedInput from 'react-text-mask';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { BackSVG } from 'assets/icons/back';
import { GroupSVG } from 'assets/icons/group';
import { useModal } from 'hooks/useModal.hook';
import { useTranslation } from 'react-i18next';
import { motion, Variants } from 'framer-motion';
import FormikRadio from 'components/form/fields/radio';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikContainer from 'components/form/containers';
import { AnimatedDivPage } from 'components/animatedPage';
import { GroupUnset } from 'common/constants/groupModals';
import { DeleteLightSVG } from 'assets/icons/deleteLight';
import { ProviderMeasure } from 'common/constants/payment';
import { ColorsAccent, ColorsBorder } from 'common/colors';
import FormikTextField from 'components/form/fields/inputs';
import FormikSelect from 'components/form/fields/select/index';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import FormikSelectStatic from 'components/form/fields/selectStatic';
import { operatorsTableTitles } from 'common/constants/employeesDetail';
import { KindOfPage } from 'common/constants/additionalOperatorModal';
import FormikSelectCountries from 'components/form/fields/selectCountries';
import FormikTextFieldNumber from 'components/form/fields/inputs/inputNumber/index';
import {
  selectEmployeeSortByTitles
} from 'common/constants/employeesList';
import {
  IOperatorsTableTitles
} from 'common/constants/employeesDetail/types';
import s from './index.module.scss';

const CreateEmployeeView: FC = () => {
  const navigate = useNavigate();
  const removeDuplicates = (data: any, key: any) => [
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];
  const { showModal } = useModal();
  const { employees, providers, auth, accounts, priceList, groups } = useStores();
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const { t } = useTranslation();
  const { getPriceListModel, getPriceListDetailAsyncState } = priceList;
  const { getProvidersModel, getProvidersAsyncState } = providers;
  const { getGroupsModel } = groups;
  const { getCreateEmployeesAsyncState } =
  employees;
  const { getCreateAccountsAsyncState, getDeleteAccountsAsyncState } = accounts;
  const { isTablet } = useWindowDimensions();
  const [choosenItems, setChoosenItems] = useState<any>([]);
  const [initialFirstName, setInitialFirstName] = useState<any>('');
  const [initialSurname, setInitialSurname] = useState<any>('');
  const [isOperatorsItemsSorted, setIsOperatorsItemsSorted] = useState<any>(false);
  const [isAccountAddActive, setIsAccountAddActive] = useState(false);
  const isAccountAddLoading = getCreateAccountsAsyncState.getLoadingState;
  const isDeletedLoading = getDeleteAccountsAsyncState.getLoadingState;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [choosenGroup, setChoosenGroup] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [activeRadio, setActiveRadio] = useState<any>({ id: GroupUnset.NONE });
  const openModalButtonEl = useRef<any>(null);
  const [groupTitle, setGroupTitle] = useState<any>(GroupUnset.NONE);
  const [createOperators, setCreateOperators] = useState<any>([]);
  const [createOperatorsGroup, setCreateOperatorsGroup] = useState<any>([]);
  const [productOperatorCheckList, setProductOperatorCheckList] = useState<any[]>([]);
  const [isFirstChoosenOperatorsCheck, setFirstChoosenOperatorsCheck] = useState<any[]>([]);
  const [isButtonClicked, setIsButtonClicked] = useState<any>(false);
  const isCreatedEmployeeSuccess = getCreateEmployeesAsyncState.getLoadingState;
  const isGetDetailPriceListSuccess = getPriceListDetailAsyncState.getLoadingState;
  const [createPhoneField, setCreatePhoneField] = useState<any>([
    {
      phoneName: 'phoneNumber',
      favorite: true
    }
  ]);
  const [phoneIdCount, setPhoneIdCount] = useState<number>(0);
  const groupMenuEl = useRef<any>();
  const [itemOffset, setItemOffset] = useState(0);
  const [elementsCount, setElementsCount] = useState<any>(8);
  const itemsPerPage = elementsCount || 8;
  const pageCount = Math.ceil((getPriceListModel.getPriceListDetail || []).length / itemsPerPage);
  const groupInitialValues = createOperatorsGroup
    ?.reduce((acc: any, item: any) => {
      acc.push(
        item?.defaultPriceList
          ? {
            defaultPriceInitial: { [`${item.defaultPriceId}`]: { amount: +item?.amount, min: item?.minAmount, max: item?.maxAmount } },
            providerInitial: {
              [`${item.providerInitialId}`]: {
                ...item,
                label: item?.provider?.title,
                id: item.provider.id,
                value: item.provider?.id,
                singleValue: item?.provider?.title
              }
            },
            productInitial: {
              [`${item.productId}`]: item?.defaultPriceList
                ? {
                  ...item,
                  label: item?.defaultPriceList?.label,
                  id: item?.defaultPriceList?.id,
                  value: item.amount,
                  singleValue: item?.defaultPriceList?.label
                }
                : null
            }
          }
          : {
            defaultPriceInitial: { [`${item?.defaultPriceId}`]: { amount: +item?.amount, min: item?.minAmount, max: item?.maxAmount } },
            providerInitial: {
              [`${item?.providerInitialId}`]: {
                ...item,
                label: item?.provider?.title,
                id: item?.provider?.id,
                value: item?.provider?.id,
                singleValue: item?.provider?.title
              }
            }
          }
      );
      return acc;
    }, [])
    .reduce(
      (acc: any, item: any) =>
        Object.assign(acc, item.defaultPriceInitial, item.providerInitial, item.productInitial),
      {}
    );

  const validateSchema = Yup.object().shape({
    firstName: Yup.string().required('This is a required field'),
    surname: Yup.string().required('This is a required field'),
    phone: Yup.string(),
    phoneNumber: Yup.string()
      .transform((value: string) => value.replace(/[^\d]/g, ''))
      .min(10, 'Phone number should be valid')
      .required('This is a required field'),
    ...createPhoneField?.map((item: any) => ({
      [`${item?.phoneName}`]: Yup.string()
        .transform((value: string) => value.replace(/[^\d]/g, ''))
        .min(10, 'Phone number should be valid')
        .required('This is a required field')
        
    })).reduce((acc: any, item: any) => Object.assign(acc, item), {})
    
  });
  
  const onSubmit = (values: any, { resetForm }: any) => {
    const phones: any = createPhoneField.map((item: any) => ({ phone: item?.phone, favorite: item?.favorite })).flat(1);
    const resultData: any = [];
    setIsButtonClicked(true);

    Object.entries(values).map(([key, valuesData]: [string, any]): any => {
      resultData.push(
        [...createOperators, ...createOperatorsGroup]
          .reduce((acc: any, operatorItem: any) => {
            // choosenItems.map((choosenItem: any) => {
            // if (operatorItem.providerId === choosenItem.id) {
            acc.push({
              defaultPrice: operatorItem.defaultPriceListId ? null : +operatorItem.amount || null,
              defaultPriceListId: operatorItem.defaultPriceListId
                ? operatorItem.defaultPriceListId
                : null,
              // Добавить это поле когда прийдут изменения с бека
              defaultScenarioId: operatorItem?.defaultScenario?.id || null,
              note: null,
              providerId: operatorItem.providerId,
              uniqueId: operatorItem.uniqueIdValue,
              defaultValueEmployee: operatorItem?.defaultValueEmployee,
            });
            // }
            // });
            return acc;
          }, [])
          ?.map((item: any) => {
            if (key === item?.uniqueId) {
              item.uniqueId = valuesData;
            }
            return item;
          })
      );
    });
  
    const data: any = {
      clientId: getAuthMe.id,
      name: values.firstName,
      surname: values.surname,
      phones: phones.map((item: any) => (item?.phone?.length > 10 ? { phone: item?.phone.slice(0, item?.phone?.length - 1), favorite: item?.favorite } : item)), // TODO: Special for Uganda and for back, change this if need in future
      email: null,
      accounts: removeDuplicates(resultData.flat(1), (key: any) => key?.providerId),
      groupId: choosenGroup
    };

    employees.createEmployeesAction(data);
    
    if (isCreatedEmployeeSuccess === Loading.success) {
      resetForm();
    }
  };
  const animations: Variants = {
    initial: { opacity: 0, scale: 0.6, y: -10 },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.6, y: -10 }
  };

  const handleMoveGroup = (e: ChangeEvent<HTMLInputElement>) => {
  };

  const handleGroup = async (e: ChangeEvent<any>, group: any) => {
    const { group: groupItem } = group;
    // setGroupTitle(groupItem?.title);

    if (groupItem?.id === GroupUnset.NONE) {
      groupItem.id = null;
    }
    
    showModal(Modals.ModalEmployeeCreateOperatorsGroupChange, {
      e,
      group,
      groupItem,
      isModalOpen,
      choosenItems,
      setGroupTitle,
      setIsOpenModal,
      setIsModalOpen,
      setActiveRadio,
      setChoosenItems,
      createOperators,
      setChoosenGroup,
      removeDuplicates,
      getProvidersModel,
      setCreateOperators,
      setCreateOperatorsGroup,
    });

    setIsModalOpen(false);
  };

  const handleChangePackagesAndMoneyGroup = (e: any, content: any) => {
    setCreateOperatorsGroup(
      createOperatorsGroup.map((item: any) => {
        if (content?.operatorName === item?.operatorName) {
          item.amount = +e;
          item.defaultPriceListId = null;
          item.isDefaultAmountValue = false;
        }
        return item;
      })
    );
  };

  const handleChangeOnlyMoneyGroup = (e: any, content: any) => {
    setCreateOperatorsGroup(
      createOperatorsGroup.map((item: any) => {
        if (content?.operatorName === item?.operatorName) {
          item.amount = +e;
          item.defaultPriceListId = null;
          item.isDefaultAmountValue = false;
        }
        return item;
      })
    );
  };

  const addNewPhone = () => {
    setPhoneIdCount(phoneIdCount + 1);
    setCreatePhoneField((prevState: any): any => [
      ...prevState,
      {
        phoneName: `phone${phoneIdCount}`,
        id: phoneIdCount,
        additionalPhone: true,
        favorite: false
      }
    ]);
  };

  const handleSelectSort = (e: any, eMeta: any) => {
    setIsOperatorsItemsSorted(!isOperatorsItemsSorted);
  };
  const removeOperator = (operator: any) => {
    showModal(Modals.ModalRemoveOperatorFromCreatePage, {
      setCreateOperators,
      setCreateOperatorsGroup,
      setProductOperatorCheckList,
      setFirstChoosenOperatorsCheck,
      setChoosenItems,
      setActiveRadio,
      operator,
      createOperators,
      createOperatorsGroup,
      productOperatorCheckList,
      isFirstChoosenOperatorsCheck,
      choosenItems
    });
  };
  const selectUnique = () =>
    toJS(getProvidersModel?.getProvidersList).filter(
      (allProviders: any) =>
        !createOperatorsGroup?.map((provider: any) => provider?.provider?.id).includes(allProviders.id)
    ).sort((a: any, b: any) => {
      if (a?.title < b?.title) {
        return -1;
      }
      if (a?.title > b?.title) {
        return 1;
      }

      return 0;
    });
  const removeAdditionalPhone = (phoneRowId: number) => {
    setCreatePhoneField(createPhoneField.filter((item: any) => item.id !== phoneRowId));
  };

  const checkPhoneInputField = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.replace(/[^\d]/g, '')[0] !== '0') {
      e.target.value = `(0)${e.target.value}`;
    }

    if (e.target.value.replace(/[^\d]/g, '')[0] === '0' && e.target.value[3] === '_') {
      e.target.value = '';
    }

    setCreatePhoneField(createPhoneField?.reduce((acc: any, item: any) => {
      if (item.phoneName === e.target.id) {
        acc.push({
          ...item,
          phone: e.target.value.replace(/[^\d]/g, '').split(' ').join('').length > 10 ? e.target.value.replace(/[^\d]/g, '').split(' ').join('').slice(0, e.target.value.replace(/[^\d]/g, '').split(' ').join('').length - 1) : e.target.value.replace(/[^\d]/g, '').split(' ').join('')
        });
      } else {
        acc.push({
          ...item
        });
      }

      return acc;
    }, []));
  };

  const itemVariants: Variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 300, damping: 24 }
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
  };

  const handleChangeIdGroup = (e: any, index: number) => {
    setCreateOperatorsGroup(
      createOperatorsGroup.map((item: any) => {
        if (item.uniqueId === e.target.id) {
          item.uniqueIdValue = e.target.value;
        }
        return item;
      }));
  };
  
  const handleChangeGroup = (e: any, eMeta: any, content: any) => {
    createOperatorsGroup?.map(async (account: any) => {
      if (content?.operatorName === account?.operatorName) {
        await priceList?.getPriceListDetailAction({ id: e?.id });
        account.priceList = toJS(getPriceListModel.getPriceListDetail);
        account.minAmount = +e.minAmount;
        account.maxAmount = +e.maxAmount;
        account.providerId = e.id;
        account.provider = e;
        account.id = e?.id;
        account.isDefaultOperatorValue = false;
        account.isDefaultIdValue = false;
        account.isDefaultProductValue = false;
        account.isDefaultAmountValue = false;
      }
    });

    setChoosenItems(removeDuplicates([...choosenItems, e], (key: any) => key.selectedFieldId));
    setFirstChoosenOperatorsCheck([...isFirstChoosenOperatorsCheck, content.id]);
  };
  
  const handleChangeProductGroup = (e: any, eMeta: any, content: any) => {
    setCreateOperatorsGroup(
      createOperatorsGroup?.map((account: any) => {
        if (content?.operatorName === account?.operatorName) {
          account.amount = +e?.price;
          account.defaultPriceListId = e?.id;
          account.defaultPriceList = { description: e?.description, label: e?.label, id: e?.id, price: e?.price, provider: e?.provider };
          account.isDefaultProductValue = false;
          account.isDefaultAmountValue = false;
        }
        return account;
      })
    );

    setProductOperatorCheckList([...productOperatorCheckList, content.id]);
  };

  const handleOperatorsEdit = (content?: any) => {
    showModal(Modals.ModalAdditionOperatorFull, { uniqueProviders: selectUnique(), setCreateOperatorsGroup, createOperatorsGroup, currentOperatorData: content, setChoosenItems, choosenItems, currentKindOfPage: KindOfPage.CREATE_EMPLOYEE_PAGE });
  };
  
  useEffect(() => {

    if (isCreatedEmployeeSuccess === Loading.success) {
      navigate('/employees');
    }

    return () => getCreateEmployeesAsyncState.reset();
  }, [
    isAccountAddLoading,
    isDeletedLoading,
    isCreatedEmployeeSuccess,
    createOperatorsGroup,
    isGetDetailPriceListSuccess,
    getPriceListModel.getPriceListDetail,
    createOperators,
    selectUnique().length,
    getPriceListModel.getPriceListDetail.length
  ]);

  useEffect(() => {
    if (isButtonClicked) {
      setTimeout(() => {
        setIsButtonClicked(false);
      }, 500);
    }
  }, [isButtonClicked]);

  useEffect(() => {
    groups.getGroupsAction({ clientId: getAuthMe.id });
  }, []);

  useEffect(() => {
    providers.getProvidersAction();
  }, []);

  return (
    <section className={s.wrapper}>
      <div className={s.wrapper__container}>
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: { opacity: 0, x: 70, y: 70 },
            animate: { opacity: 1, x: 0, y: 0 },
            exit: { opacity: 0, x: 70, y: 70 }
          }}
          transition={{ duration: 0.7, type: 'spring', damping: 22, stiffness: 155 }}
          className={s.back}
        >
          <ReactTooltip textColor="#334049" backgroundColor="#fff" border borderColor="#E5E8ED" />
          <motion.button
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className={s.back_icon}
            onClick={() => navigate('/employees')}
          >
            <BackSVG />
          </motion.button>

          <div className={s.back_text}>
            <p>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.BACKBUTTON')}</p>
          </div>
        </motion.div>

        <AnimatedDivPage
          transition={{ duration: 0.7, type: 'spring', damping: 15 }}
          animations={animations}
        >
          <div className={s.wrapper__title}>
            <h1 className={s.title}>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.TITLE')}</h1>
          </div>
          <FormikContainer
            initialValues={{
              group: groupTitle || GroupUnset.NONE,
              surname: initialSurname,
              firstName: initialFirstName,
              ...createPhoneField?.reduce((phoneAcc: any, phoneItem: any) => Object.assign(phoneAcc, { [`${phoneItem?.phoneName}`]: phoneItem?.phone }), {}),
              ...groupInitialValues
            }}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validateSchema}
          >
            {({ errors }: any) => (
              <>
                <div className={s.detail}>
                  <div className={s.detail__title_header}>
                    <div className={s.employee}>
                      <div className={`${s.input} ${errors.firstName ? s.border_alert : null}`}>
                        <FormikTextField
                          type="text"
                          placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.FORM.NAMEPLACEHOLDER')}
                          errorMessageDisable
                          label={<p className={s.input__label}>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.FORM.NAME')}</p>}
                          name="firstName"
                          onChange={(e: any) => setInitialFirstName(e.target.value)}
                        />
                      </div>

                      <div className={`${s.input} ${errors.surname ? s.border_alert : null}`}>
                        <FormikTextField
                          type="text"
                          placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.FORM.SURNAMEPLACEHOLDER')}
                          errorMessageDisable
                          label={<p className={s.input__label}>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.FORM.SURNAME')}</p>}
                          name="surname"
                          onChange={(e: any) => setInitialSurname(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={s.group}>
                    <div className={s.group__choose}>
                      <FormikSelectStatic
                        groupMenuEl={groupMenuEl}
                        isOpenModal={isOpenModal}
                        setIsOpenModal={setIsOpenModal}
                        inputWrapperStyles={{
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                        inputStyles={{
                          cursor: 'pointer',
                          border: `1px solid ${ColorsBorder.borderPrimary}`,
                          borderRadius: '0.5rem',
                          height: '2.75rem',
                          width: '100%',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingLeft: '1.125rem'
                        }}
                        name="group"
                        type="text"
                        onChange={handleMoveGroup}
                        label={<p className={s.group__title}>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.FORM.GROUP')}</p>}
                        options={
                          <motion.ul
                            className={s.group__menu}
                            ref={groupMenuEl}
                            variants={{
                              open: {
                                height: '100%',
                                transition: {
                                  type: 'spring',
                                  bounce: 0,
                                  duration: 0.7,
                                  delayChildren: 0.3,
                                  staggerChildren: 0.05
                                }
                              },
                              closed: {
                                height: '0',
                                transition: {
                                  type: 'spring',
                                  bounce: 0,
                                  duration: 0.3
                                }
                              }
                            }}
                          >
                            {[
                              { id: GroupUnset.NONE, title: GroupUnset.NONE, parent_id: '', description: '', membersCount: 0, client: { id: '',
                                email: '',
                                name: '',
                                picture: '',
                                phone: '', }, employees: [], groupsAccounts: [] },
                              ...getGroupsModel.getGroupList ].map((group: any, index: number) => (
                              <FormikRadio
                                onClick={(e: ChangeEvent<any>) =>
                                  handleGroup(e, {
                                    id: group.id,
                                    group: toJS(group),
                                    createOperators
                                  })
                                }
                                name={'group'}
                                key={group.id}
                                id={group.id}
                                value={group?.id ? groupTitle : null}
                                type="radio"
                                label={
                                  <motion.li className={s.choose__option} variants={itemVariants}>
                                    <div
                                      className={`${s.radio} ${
                                        group.id === activeRadio?.id ? s.radio_active : ''
                                      }`}
                                    />
                                    <div className={s.choose__description}>
                                    { group?.id !== GroupUnset.NONE ?
                                      <div className={s.choose__icon}>
                                        <GroupSVG />
                                      </div>
                                      :
                                      null }
                                      <p className={s.choose__text}>{group.title}</p>
                                    </div>
                                  </motion.li>
                                }
                              />
                            ))}
                          </motion.ul>
                        }
                        placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.FORM.GROUPPLACEHOLDER')}
                      >
                        <motion.div
                          className={s.delimiter}
                          variants={{
                            open: {
                              height: '1px',
                              margin: '1rem 1.25rem',
                              transition: { type: 'spring', stiffness: 300, damping: 24 }
                            },
                            closed: {
                              transition: { type: 'spring', stiffness: 300, damping: 24 }
                            }
                          }}
                        />
                      </FormikSelectStatic>
                    </div>
                  </div>

                  <div className={s.phone}>
                    <h4 className={s.phone__title}>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.FORM.PHONE')}</h4>

                    <div className={s.phone__choose}>
                      <div
                        className={`${s.input__countries} ${
                          errors.phoneNumber ? s.border_alert : null
                        }`}
                      >
                        {createPhoneField.map((content: any, index: any) => (
                          <FormikSelectCountries
                            wrapperChildrenStyles={{ display: 'flex', alignItems: 'center' }}
                            key={content.phoneName}
                            name="phone"
                          >
                            <>
                              <div
                                className={`${s.countries__wrapper__input} ${
                                  errors[content.phoneName] ? s.border_alert : ''
                                }`}
                              >
                                <Field name={content.phoneName}>
                                  {({ field }: any) => (
                                    <MaskedInput
                                      {...field}
                                      onInput={checkPhoneInputField}
                                      mask={[
                                        '(',
                                        /\d/,
                                        ')',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/
                                      ]}
                                      placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.FORM.PHONEPLACEHOLDER')}
                                      guide
                                      name={content.phoneName}
                                      id={content.phoneName}
                                    />
                                  )}
                                </Field>
                              {content.additionalPhone ? (
                                <div className={s.phone__items_remove}>
                                  <button
                                    onClick={() => removeAdditionalPhone(content.id)}
                                    type="button"
                                    className={s.remove__button}
                                  >
                                    <DeleteLightSVG />
                                  </button>
                                </div>
                              ) : null}
                              </div>
                            </>
                          </FormikSelectCountries>
                        ))}
                      </div>
                    </div>

                    <div className={s.phone__button}>
                      <motion.button
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        type="button"
                        onClick={() => addNewPhone()}
                      >
                        <p>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.BUTTONS.ADDPHONE')}</p>
                      </motion.button>
                    </div>
                  </div>

                  <div className={s.operators}>
                    <div className={s.operators__header}>
                      <div className={s.operators__wrapper__title}>
                        <h4 className={s.operators__title}>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.LIST.TITLE')}</h4>
                      </div>

                      <div className={s.operators__sort}>
                        <FormikSelect
                          valueContainer={{ paddingLeft: '.5rem' }}
                          wrapperSelectStyles={{
                            width: '11rem',
                            maxWidth: '100%',
                            minWidth: '100%'
                          }}
                          options={selectEmployeeSortByTitles}
                          name="sort"
                          placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.SORTMOBILE.PLACEHOLDER')}
                          indicatorIcon={<DropSelectSVG />}
                          onChange={handleSelectSort}
                        />
                      </div>
                    </div>

                    <div className={s.operators__list}>
                      <div className={s.operators__list__header}>
                        {operatorsTableTitles(t).map((titles: IOperatorsTableTitles) => (
                          <div key={titles.id} className={s.header__item}>
                            <button
                              type="button"
                              className={s.item}
                            >
                              <p>{titles.name}</p>
                              {titles.icon}
                            </button>
                          </div>
                        ))}
                      </div>

                      <motion.div
                        className={s.operators__main}
                      >
                        {createOperatorsGroup.length
                          ? createOperatorsGroup.map((content: any, index: any) => (
                              <motion.div
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                variants={{
                                  initial: { opacity: 0, y: -10 },
                                  animate: { opacity: 1, y: 0 },
                                  exit: { opacity: 0, y: 10 }
                                }}
                                transition={{
                                  duration: 0.5,
                                  type: 'spring',
                                  stiffness: 150,
                                  damping: 18,
                                  bounce: 0
                                }}
                                key={content?.provider?.id}
                                className={`${s.operators__items} ${s.operators__items__create}`}
                              >
                                
                                  <div className={s.operators__item}>
                                    <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                      <FormikSelect
                                        // onMenuOpen={() =>
                                        // handleProviderSelect
                                        // handleOperatorsEdit(content)
                                        // }
                                        isDisabled
                                        valueStyles={{
                                          color: ColorsAccent.accentDark,
                                          opacity: 1
                                        }}
                                        indicatorContainerStyles={{
                                          height: 'auto',
                                          width: '2.0625rem',
                                          cursor: 'pointer'
                                        }}
                                        // wrapperSelectStyles={{ width: '100%' }}
                                        controlStyles={{
                                          minHeight: '2.0625rem',
                                          maxHeight: '2.0625rem',
                                          border: `1px solid ${ColorsBorder.borderPrimary}`,
                                        }}
                                        // hideInputContainerWrapper={{
                                        //   width: 0,
                                        //   margin: 0,
                                        //   padding: 0,
                                        //   maxHeight: 0
                                        // }}
                                        valueContainer={{
                                          height: '2.0625rem',
                                          maxHeight: '2.0625rem',
                                          paddingTop: '0',
                                          paddingBottom: '.25rem',
                                          fontStyle: content?.isDefaultOperatorValue ? 'italic' : 'normal'
                                        }}
                                        containerStyles={{
                                          width: '8.375rem',
                                          minWidth: !isTablet ? '100%' : 'auto',
                                          height: 'auto',
                                          fontSize: '1rem',
                                          border: 'none'
                                        }}
                                        name={`provider${content?.provider?.id}`?.split('.')?.join('')}
                                        options={
                                          // selectUnique()?.slice(itemOffset, endOffset)
                                          selectUnique()?.map((item: any) => ({
                                            ...item,
                                            value: item?.title,
                                            label: item?.title,
                                            singleValue: item?.title,
                                            id: item?.id,
                                            isDisabled: item?.availability === 'Disabled',
                                            genre: ['pop'],
                                            selectedFieldId: `provider${item?.id}`,
                                            uniqueId: `uniqueId${item?.id}`,
                                            defaultPrice: `defaultPrice${item?.id}`,
                                            note: `note${item?.id}`
                                          }))}
                                        // selectPagination={
                                        //   <>
                                        //     <div className={s.paginate__count__mobile}>
                                        //         <p>{dynamicPage}</p> <p className={s.count__divider}>of</p> <p>{pageCount || 1}</p>
                                        //     </div>
                                        //     <div className={s.select__pagination}>
                                        //       <SelectPagination
                                        //         dynamicPage={dynamicPage}
                                        //         handlePageClick={handlePageClickProviders}
                                        //         pageCount={pageCount}
                                        //       />
                                        //     </div>
                                        //   </>
                                        // }
                                        // isSearchable
                                        placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.LIST.HEADER.NAME')}
                                        indicatorIcon={<DropSelectSVG />}
                                        onChange={(e: any, eMeta: any) =>
                                          handleChangeGroup(e, eMeta, { ...content, id: content?.id })
                                        }
                                      />
                                    </div>
                                  </div>
                                  
                                <div className={s.operators__item}>
                                  <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                    <FormikTextField
                                      // required
                                      readOnly
                                      inputStyles={{
                                        width: '8.375rem',
                                        height: '2.0625rem',
                                        minWidth: !isTablet ? '100%' : 'auto'
                                      }}
                                      type="text"
                                      placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.LIST.HEADER.ID')}
                                      value={content.uniqueIdValue}
                                      name={`uniqueId${content?.provider?.id}`?.split('.')?.join('')}
                                      onChange={(e: any) => handleChangeIdGroup(e, index)}
                                    />
                                  </div>
                                </div>
                                {content?.provider?.measure === ProviderMeasure.PACKAGES ||
                                content?.provider?.measure ===
                                  ProviderMeasure.PACKAGES_AND_MONEY ? (

                                    <div className={s.operators__item} 
                                    // onClick={() => handleOperatorsEdit(content)}
                                    > 
                                      <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                        <FormikSelect
                                          // onMenuOpen={() => 
                                          // handlePriceListSelect({ ...content, provider: { id: content?.providerId } })
                                          //   handleOperatorsEdit(content)
                                          // }
                                          indicatorContainerStyles={{
                                            height: 'auto',
                                            width: '2.0625rem'
                                          }}
                                          isDisabled
                                          valueStyles={{
                                            color: ColorsAccent.accentDark,
                                            opacity: 1
                                          }}
                                          // wrapperSelectStyles={{ width: '100%' }}
                                          controlStyles={{
                                            minHeight: '2.0625rem',
                                            maxHeight: '2.0625rem',
                                            border: `1px solid ${ColorsBorder.borderPrimary}`
                                          }}
                                          // hideInputContainerWrapper={{
                                          //   width: 0,
                                          //   margin: 0,
                                          //   padding: 0,
                                          //   maxHeight: 0
                                          // }}
                                          valueContainer={{
                                            height: '2.0625rem',
                                            maxHeight: '2.0625rem',
                                            paddingTop: '0',
                                            paddingBottom: '.25rem',
                                            fontStyle: content?.isDefaultProductValue ? 'italic' : 'normal',
                                          }}
                                          containerStyles={{
                                            width: '8.375rem',
                                            minWidth: !isTablet ? '100%' : 'auto',
                                            height: 'auto',
                                            fontSize: '1rem',
                                            border: 'none'
                                          }}
                                          name={`product${content?.provider?.id}`?.split('.')?.join('')}
                                          loadingMessage={
                                            isGetDetailPriceListSuccess === Loading.success
                                          }
                                          options={
                                            // toJS(getPriceListModel.getPriceListDetail?.slice(itemOffset, endOffset))
                                            toJS(getPriceListModel.getPriceListDetail)?.map((item: any) => ({
                                              ...item,
                                              value: item?.description,
                                              label: item?.description,
                                              singleValue: item?.description,
                                              uniqueId: `uniqueId${content?.provider?.id}`?.split('.')?.join(''),
                                              amount: item?.price,
                                              id: item?.id,
                                              isDisabled: item?.availability === 'Disabled',
                                              genre: ['pop']
                                            }))}
                                          // selectPagination={
                                          //   <>
                                          //     <div className={s.paginate__count__mobile}>
                                          //         <p>{dynamicPage}</p> <p className={s.count__divider}>of</p> <p>{pageCount || 1}</p>
                                          //     </div>
                                          //     <div className={s.select__pagination}>
                                          //       <SelectPagination
                                          //         dynamicPage={dynamicPage}
                                          //         handlePageClick={handlePageClick}
                                          //         pageCount={pageCount}
                                          //       />
                                          //     </div>
                                          //   </>
                                          // }
                                          // isSearchable
                                          placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.LIST.HEADER.PRODUCT')}
                                          indicatorIcon={<DropSelectSVG />}
                                          onChange={(e: any, eMeta: any) =>
                                            handleChangeProductGroup(e, eMeta, {
                                              ...content,
                                              id: content?.provider?.id
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                  <div className={s.operators__placeholder} />
                                  )}

                                {content?.provider?.measure === ProviderMeasure.PACKAGES ? (
                                  <div className={s.operators__item}>
                                    <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                    <FormikTextFieldNumber
                                      readOnly={
                                        content?.provider?.measure === ProviderMeasure.PACKAGES
                                      }
                                      inputStyles={{
                                        width: '8.375rem',
                                        height: '2.0625rem',
                                        minWidth: !isTablet ? '100%' : 'auto',
                                        fontStyle: content?.isDefaultAmountValue ? 'italic' : 'normal'
                                      }}
                                      placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.LIST.HEADER.AMOUNT')}
                                      value={+content?.amount || ''}
                                      name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                                      suppressHydrationWarning
                                      min={+content?.minAmount}
                                      max={+content?.maxAmount}
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                    </div>
                                  </div>
                                ) : null}

                                {content?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY ? (
                                  <div className={s.operators__item}>
                                    <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                      <FormikTextFieldNumber
                                        readOnly
                                        inputStyles={{
                                          width: '8.375rem',
                                          height: '2.0625rem',
                                          minWidth: !isTablet ? '100%' : 'auto',
                                          fontStyle: content?.isDefaultAmountValue ? 'italic' : 'normal',
                                          border: content?.amount < content?.minAmount || content?.amount > content?.maxAmount || !content?.amount ? '1px solid red' : `1px solid ${ColorsBorder.borderPrimary}`
                                        }}
                                        placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.LIST.HEADER.AMOUNT')}
                                        value={+content?.amount || ''}
                                        name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                                        suppressHydrationWarning
                                        min={+content?.minAmount}
                                        max={+content?.maxAmount}
                                        onValueChange={(e: any, name: any) =>
                                          handleChangePackagesAndMoneyGroup(e, content)
                                        }
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : null}

                                {content?.provider?.measure === ProviderMeasure.MONEY ? (
                                  <div className={s.operators__item}>
                                    <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                      <FormikTextFieldNumber
                                        inputStyles={{
                                          width: '8.375rem',
                                          height: '2.0625rem',
                                          minWidth: !isTablet ? '100%' : 'auto',
                                          fontStyle: content?.isDefaultAmountValue ? 'italic' : 'normal',
                                          border: content?.amount < content?.minAmount || content?.amount > content?.maxAmount || !content?.amount ? '1px solid red' : `1px solid ${ColorsBorder.borderPrimary}`
                                        }}
                                        readOnly
                                        placeholder={t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.LIST.HEADER.AMOUNT')}
                                        value={+content?.amount || ''}
                                        name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                                        suppressHydrationWarning
                                        min={+content?.minAmount}
                                        max={+content?.maxAmount}
                                        onValueChange={(e: any, name: any) =>
                                          handleChangeOnlyMoneyGroup(e, content)
                                        }
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <div className={s.operators__items_icon}>
                                  <button
                                    onClick={() => removeOperator(content)}
                                    type="button"
                                    className={s.remove__button}
                                  >
                                    <DeleteLightSVG />
                                  </button>
                                </div>
                              </motion.div>
                          ))
                          :
                          <div className={s.operators__empty}>
                            <p>There are no operators</p>
                          </div>}

                      </motion.div>
                    </div>

                    <div className={s.create}>
                      <motion.button
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ scale: 1.05 }}
                        ref={openModalButtonEl}
                        // onClick={addNewOperator}
                        onClick={() => handleOperatorsEdit()}
                        className={s.create__button}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        disabled={isAccountAddActive}
                        type="button"
                      >
                        <p>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.BUTTONS.ADDOPERATOR')}</p>
                      </motion.button>
                    </div>
                  </div>
                </div>

                <div className={s.operators__buttons}>

                  <div className={s.operators__button}>
                      <motion.button
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        disabled={isButtonClicked}
                        type="submit"
                      >
                        <p>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.BUTTONS.SUBMIT')}</p>
                      </motion.button>
                    </div>
                  <div className={s.operators__button}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => navigate('/employees')}
                    >
                      <p>{t<string>('MAIN.EMPLOYEES.EMPLOYEECREATE.BUTTONS.CANCEL')}</p>
                    </motion.button>
                  </div>


                </div>
              </>
            )}
          </FormikContainer>
        </AnimatedDivPage>
      </div>
    </section>
  );
};

export const CreateEmployee = memo(observer(CreateEmployeeView));
import React from 'react';
import { routes } from 'router/paths';
import { AnimatePresence } from 'framer-motion';
import { generateAppRoutes } from 'router/utils';
import { Route, Routes, useLocation } from 'react-router';

export const RoutesContainer = () => {
  const appRoutes = generateAppRoutes(routes);
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        {appRoutes.map((route: any) => {
          const Component = () => route.component(route);
          return (
            <Route key={route.path} index={route.index} path={route.path} element={<Component />} />
          );
        })}
      </Routes>
    </AnimatePresence>
  );
};

import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';
import { ConfirmPayments, CreatePayments } from 'models/payments/types';

abstract class PaymentsAPI {
  public static createPayments = (data: CreatePayments): Promise<AxiosResponse> =>
    RestAPI.post('/api/payments/create', data);

  public static createConfirmPayments = (data: ConfirmPayments): Promise<AxiosResponse> => RestAPI.post('/api/payments/confirm', data);

  public static getPaymentsHistory = (data: any): Promise<AxiosResponse> => RestAPI.get(`/api/payments/history/${data.id}`,
    // data?.status ?
    { search: data?.search, page: data?.page, quantity: data?.quantity, sortId: data?.sortId, status: data?.active, wait: data?.wait });
  // : { search: data?.search, page: data?.page, quantity: data?.quantity, sortId: data?.sortId });
  
  public static getPaymentsHistoryStatus = (data: any): Promise<AxiosResponse> => RestAPI.get(`/api/payments/history/status/${data.id}`);

  public static createPaymentsHistoryTransferStatus = (data: any): Promise<AxiosResponse> => RestAPI.post('/api/payments/transfer-status', data);

  public static getPaymentsHistoryDetails = (data: any): Promise<AxiosResponse> => RestAPI.get(`/api/payments/history/details/${data.id}`);

  public static updatePaymentsHistoryDetails = (data: { multiTxId: string, notes: string }): Promise<AxiosResponse> => RestAPI.put(`/api/payments/history/details/${data.multiTxId}`, { notes: data.notes });

  public static getPaymentsExport = (data: { multiId: string, clientId: string }): Promise<AxiosResponse> =>
    RestAPI.get(`/api/payments/export/${data.clientId}?multiId=${data.multiId}`);

  public static createPaymentsExportSend = (data: { multiId: string, clientId: string, email: string }): Promise<AxiosResponse> =>
    RestAPI.post(`/api/payments/export-mail/${data.clientId}?multiId=${data.multiId}`, { mail: data.email });
}

export default PaymentsAPI;

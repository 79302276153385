import React from 'react';
import { SortArrowSVG } from 'assets/icons/sortArrow';
import { IGroupsSortMobile } from './types';

export const groupsListTitles: any = [
  {
    name: 'Group',
    data: 'name',
    icon: <SortArrowSVG />,
    id: 0,
    asc: 3,
    desc: 2
  },
  {
    name: 'Employees',
    data: 'members',
    icon: <SortArrowSVG />,
    id: 1,
    asc: 5,
    desc: 4
  },
  {
    name: 'Description',
    data: 'description',
    id: 2
  }
];

export const selectSortByGroupItems = [
  { label: 'Group name', singleValue: 'Group name', value: 'groupName', id: 1 },
  { label: 'Employees', singleValue: 'Employees', value: 'members', id: 2 }
];

export const operatorsTableGroupTitles: any[] = [
  {
    name: 'Operator',
    data: 'title',
    icon: <SortArrowSVG />,
    id: 0,
  },
  {
    name: 'Product',
    data: 'product',
    id: 1,
  },
  {
    name: 'Amount',
    data: 'amount',
    icon: <SortArrowSVG />,
    id: 2,
  },
];

export const selectGroupSortByTitles = [
  { label: 'Operator', singleValue: 'Operator', value: 'operator', id: 1 },
  { label: 'Amount', singleValue: 'Amount', value: 'amount', id: 2 },
];

export const groupsSortMobile: IGroupsSortMobile[] = [
  { value: 2, label: 'Group name by descending', singleValue: 'Group name by descending' },
  { value: 3, label: 'Group name by ascending', singleValue: 'Group name by ascending' },
  { value: 4, label: 'Employees by descending', singleValue: 'Employees by descending' },
  { value: 5, label: 'Employees by ascending', singleValue: 'Employees by ascending' }
];

import React, { ChangeEvent, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { UploadCsvSVG } from 'assets/icons/uploadCsv';
import { UniversalIconButton } from '../universalIconButton';
import { ImportSVG } from '../../assets/icons/import';
import s from './index.module.scss';

interface IUploadFileProps {
  getImportFile: (file: File) => void;
}

export const UploadFile = (props: IUploadFileProps) => {
  const { getImportFile } = props;
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setUploadedFile(file);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleImportFile = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    setUploadedFile(file);
  };

  useEffect(() => {
    if (uploadedFile) {
      getImportFile(uploadedFile);
    }
  }, [uploadedFile]);

  return (
        <motion.div
            className={s.wrapper}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            // drag
            // dragConstraints={{
            //   top: 0,
            //   left: 0,
            //   right: 0,
            //   bottom: 0,
            // }}
        >
            <div className={s.file__wrapper}>
              {uploadedFile ? (
                  <div className={s.uploaded__file}>
                    <p>Uploaded file:</p>
                    <p>{uploadedFile.name}</p>
                  </div>
              ) : (
                  <p>Drag file here to upload</p>
              )}
            </div>
          <div className={s.file__upload}>
              <UniversalIconButton
                  onChange={handleImportFile}
                  // eslint-disable-next-line no-return-assign
                  onClick={(e: ChangeEvent<HTMLInputElement>) => (e.target.value = '')}
                  type="file"
                  id={'IMPORT'}
                  icon={<UploadCsvSVG/>}
                  text={'Choose file'}
              />
          </div>
        </motion.div>
  );
};

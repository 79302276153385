/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, ReactChild, ReactChildren, useEffect, ChangeEvent } from 'react';
import { motion, useAnimation } from 'framer-motion';
import s from './index.module.scss';

export interface ModalBackdropProps {
  children: ReactChild | ReactChildren | ReactChild[];
  hideModal: () => void;
  modalWrapperStyles?: any;
  modalRefEl?: any;
  resetAfterImport?: () => void;
}

export const ModalBackdrop = (props: ModalBackdropProps) => {
  const { children, hideModal, resetAfterImport, modalWrapperStyles, modalRefEl } = props;
  async function handleHideModal(e: any) {
    if ((e.target as Element).id === 'ModalBackdrop') {
      if (resetAfterImport) {
        resetAfterImport();
      }
      hideModal();
    }
  }
  
  return (
    <motion.section
    ref={modalRefEl}
      variants={{
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
      }}
      initial="initial"
      animate="animate"
      exit="exit"
      style={modalWrapperStyles}
      transition={{ duration: 0.5, type: 'spring', damping: 17 }}
      className={s.wrapper}
      onClick={handleHideModal}
      id="ModalBackdrop"
    >
      {children}
    </motion.section>
  );
};

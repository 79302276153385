import React from 'react';

export const LogoSVG = () => 
<svg width="389" height="88" viewBox="0 0 389 88" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_202_19696)">
<path d="M299.175 37.4924C293.589 37.5374 290.791 34.9378 294.263 29.1358C296.944 24.6651 300.299 24.1254 303.241 28.641C306.974 34.371 305.193 37.4474 299.175 37.4924ZM332.431 50.1038L307.855 8.88734C304.905 3.9939 297.79 4.06586 294.947 9.01328L294.2 10.3086L272.072 50.2298C270.381 53.2702 272.585 57.0122 276.066 57.0122H283.064C285.439 57.0122 287.634 55.7169 288.776 53.63L290.494 50.5086C292.374 47.0904 295.964 44.9675 299.867 44.9675H301.622C305.31 44.9675 308.728 46.9105 310.617 50.0769L312.857 53.8369C314.035 55.8069 316.158 57.0122 318.452 57.0122H328.5C332.053 57.0032 334.239 53.1443 332.431 50.1038Z" fill="#FFC912"/>
<path d="M116.966 37.4924C111.38 37.5374 108.582 34.9378 112.055 29.1358C114.735 24.6651 118.09 24.1254 121.032 28.641C124.765 34.371 122.975 37.4474 116.966 37.4924ZM150.213 50.1038L125.646 8.88734C122.696 3.9939 115.59 4.06586 112.738 9.01328L111.992 10.3086L89.8631 50.2298C88.172 53.2702 90.3759 57.0122 93.8571 57.0122H100.855C103.23 57.0122 105.425 55.7169 106.567 53.63L108.286 50.5086C110.166 47.0904 113.755 44.9675 117.659 44.9675H119.413C123.101 44.9675 126.519 46.9105 128.408 50.0769L130.648 53.8369C131.826 55.8069 133.949 57.0122 136.252 57.0122H146.3C149.835 57.0032 152.03 53.1443 150.213 50.1038Z" fill="white"/>
<path d="M282.606 8.77941C282.606 9.58898 282.39 10.3716 281.967 11.1092L259.56 50.6076C254.36 58.4875 247.002 57.543 244.528 53.1982C242.531 49.7081 241.488 47.0994 238.996 42.3229C236.406 37.3665 232.133 36.8178 228.31 42.0351L219.98 52.4066C215.941 57.3451 204.643 58.9103 202.601 48.2238C201.846 43.0965 202.808 37.8612 205.327 33.3366L214.898 16.1735L215.681 20.6352C217.012 28.8479 218.883 30.5391 222.895 26.6891L232.916 17.3339C238.475 12.2966 243.692 12.7553 248.324 21.2019C250.672 25.4747 253.758 24.2603 257.32 19.9246L263.823 9.03127C267.269 3.31026 274.672 1.45723 280.393 4.87544C281.823 5.73899 282.606 7.23221 282.606 8.77941Z" fill="#FFC912"/>
<path d="M93.8301 9.42708C91.5273 5.48713 87.4434 3.15735 82.9098 3.15735H50.3557C44.6977 3.15735 39.4894 6.21575 36.7368 11.1542C33.6694 16.6413 38.338 23.028 44.6257 23.028C44.6257 23.028 61.4559 23.001 61.5909 23.001C67.3569 22.956 70.2444 25.6366 66.6552 31.6275C65.243 33.9843 63.6508 35.2706 62.0226 35.3516C61.7168 35.4235 61.348 35.4685 60.8712 35.4685H39.9841C32.3921 35.4685 25.3848 39.5973 21.6787 46.1999L4.31775 75.5786C3.65209 76.7659 3.34625 78.0433 3.34625 79.3296C3.34625 81.9922 4.74952 84.6009 7.24122 86.0041C11.559 88.4239 17.0191 86.9037 19.4209 82.5859L34.551 56.7424C35.3336 55.3391 36.8088 54.4846 38.374 54.4846H65.5578C71.7556 54.4846 77.4946 51.1383 80.535 45.7231L93.92 22.0115C96.1598 18.0716 96.1329 13.349 93.8301 9.42708Z" fill="white"/>
<path d="M385.674 4.8035C380.241 1.78107 373.377 3.73305 370.346 9.16622L358.616 29.9724C357.716 31.5825 355.414 31.6185 354.469 30.0263L341.912 8.93234C338.736 3.58013 331.819 1.82605 326.467 5.00139C323.507 6.75548 322.536 10.5785 324.299 13.5379L344.646 47.7471C345.951 49.9419 345.987 52.6585 344.745 54.8894L333.366 75.4616C331.288 79.1947 332.628 83.9082 336.362 85.9861C341.057 88.5948 346.976 86.8857 349.558 82.1721L388.085 13.2771C389.758 10.2636 388.678 6.47662 385.674 4.8035Z" fill="#FFC912"/>
<path d="M202.215 4.8035C196.781 1.78107 189.918 3.73305 186.886 9.16622L175.157 29.9724C174.257 31.5825 171.954 31.6185 171.01 30.0263L158.452 8.93234C155.277 3.58013 148.36 1.82605 143.007 5.00139C140.048 6.75548 139.076 10.5785 140.84 13.5379L161.187 47.7471C162.491 49.942 162.527 52.6585 161.286 54.8894L149.907 75.4706C147.829 79.2037 149.169 83.9172 152.902 85.9951C157.598 88.6038 163.517 86.8947 166.098 82.1811L204.625 13.2861C206.298 10.2636 205.219 6.47662 202.215 4.8035Z" fill="white"/>
<path d="M17.8921 23.217C11.6943 26.6712 6.38709 27.3728 3.20275 24.3594C2.20427 23.4419 1.43067 22.1556 0.872966 20.4824C0.207313 18.4225 -0.0985273 15.7869 0.0364023 12.5126C0.477172 1.32244 6.26116 -3.14823 16.4438 2.3479C29.3881 9.31926 29.1722 16.9473 17.8921 23.217Z" fill="#FFC912"/>
</g>
<defs>
<clipPath id="clip0_202_19696">
<rect width="388.867" height="87.2095" fill="white"/>
</clipPath>
</defs>
</svg>
;

export const LogoDarkSVG = () => 
<svg width="232" height="52" viewBox="0 0 232 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_202_19414)">
<path d="M178.408 22.3392C175.08 22.366 173.413 20.8171 175.482 17.3601C177.079 14.6963 179.078 14.3747 180.831 17.0653C183.055 20.4794 181.994 22.3124 178.408 22.3392ZM198.223 29.8535L183.58 5.29537C181.823 2.37969 177.583 2.42257 175.889 5.37041L175.444 6.14221L162.26 29.9286C161.252 31.7402 162.565 33.9698 164.639 33.9698H168.809C170.224 33.9698 171.532 33.198 172.213 31.9545L173.236 30.0947C174.356 28.058 176.495 26.7931 178.821 26.7931H179.866C182.064 26.7931 184.1 27.9508 185.226 29.8375L186.56 32.0778C187.263 33.2516 188.527 33.9698 189.894 33.9698H195.881C197.998 33.9644 199.301 31.6651 198.223 29.8535Z" fill="#FFC912"/>
<path d="M69.8422 22.3392C66.5138 22.366 64.847 20.8171 66.9158 17.3601C68.513 14.6963 70.5122 14.3747 72.2648 17.0653C74.4891 20.4794 73.4225 22.3124 69.8422 22.3392ZM89.6517 29.8535L75.0143 5.29537C73.2563 2.37969 69.0222 2.42257 67.3231 5.37041L66.8783 6.14221L53.6934 29.9286C52.6858 31.7402 53.9989 33.9698 56.0731 33.9698H60.243C61.6579 33.9698 62.9657 33.198 63.6464 31.9545L64.6701 30.0947C65.7903 28.058 67.9288 26.7931 70.2549 26.7931H71.3C73.4975 26.7931 75.5342 27.9508 76.6598 29.8375L77.9943 32.0778C78.6964 33.2516 79.9613 33.9698 81.3334 33.9698H87.3202C89.4266 33.9644 90.7343 31.6651 89.6517 29.8535Z" fill="#2F444E"/>
<path d="M168.536 5.23108C168.536 5.71346 168.407 6.17975 168.155 6.61925L154.804 30.1537C151.706 34.8488 147.322 34.286 145.848 31.6973C144.658 29.6177 144.037 28.0634 142.552 25.2174C141.008 22.2642 138.462 21.9373 136.185 25.0459L131.222 31.2256C128.815 34.1681 122.083 35.1007 120.867 28.7334C120.416 25.6784 120.99 22.559 122.491 19.8631L128.193 9.63676L128.66 12.2952C129.453 17.1886 130.568 18.1962 132.958 15.9023L138.929 10.3282C142.241 7.32673 145.35 7.60007 148.11 12.6328C149.509 15.1787 151.347 14.4551 153.47 11.8718L157.345 5.38116C159.398 1.97238 163.809 0.868284 167.217 2.90497C168.069 3.4195 168.536 4.30921 168.536 5.23108Z" fill="#FFC912"/>
<path d="M56.0571 5.61694C54.685 3.26939 52.2517 1.88123 49.5504 1.88123H30.1536C26.7824 1.88123 23.6791 3.70352 22.039 6.646C20.2114 9.91542 22.993 13.7208 26.7395 13.7208C26.7395 13.7208 36.7675 13.7047 36.8479 13.7047C40.2835 13.6779 42.0039 15.2751 39.8654 18.8447C39.0239 20.2489 38.0753 21.0154 37.1051 21.0636C36.9229 21.1065 36.7032 21.1333 36.4191 21.1333H23.9739C19.4503 21.1333 15.2751 23.5934 13.0669 27.5274L2.72265 45.0322C2.32603 45.7397 2.1438 46.5008 2.1438 47.2672C2.1438 48.8537 2.97991 50.408 4.46455 51.2441C7.03721 52.6859 10.2906 51.7801 11.7216 49.2074L20.7366 33.809C21.2029 32.9729 22.0819 32.4637 23.0145 32.4637H39.2115C42.9044 32.4637 46.3238 30.4699 48.1354 27.2433L56.1107 13.1152C57.4452 10.7676 57.4291 7.95377 56.0571 5.61694Z" fill="#2F444E"/>
<path d="M229.947 2.86206C226.71 1.0612 222.621 2.22425 220.814 5.46152L213.825 17.8585C213.289 18.8179 211.917 18.8393 211.355 17.8907L203.872 5.32216C201.98 2.13314 197.859 1.088 194.67 2.97997C192.906 4.02512 192.328 6.30299 193.378 8.06633L205.502 28.4493C206.279 29.7571 206.3 31.3757 205.561 32.7049L198.781 44.9625C197.543 47.1868 198.341 49.9953 200.565 51.2334C203.363 52.7877 206.89 51.7694 208.428 48.9609L231.384 7.9109C232.381 6.1154 231.737 3.85896 229.947 2.86206Z" fill="#FFC912"/>
<path d="M120.636 2.86206C117.399 1.0612 113.309 2.22425 111.503 5.46152L104.514 17.8585C103.978 18.8179 102.606 18.8393 102.043 17.8907L94.5612 5.32216C92.6692 2.13314 88.5476 1.088 85.3586 2.97997C83.5953 4.02512 83.0164 6.30299 84.0669 8.06633L96.1906 28.4493C96.9677 29.7571 96.9892 31.3757 96.2495 32.7049L89.4695 44.9679C88.2314 47.1922 89.03 50.0007 91.2543 51.2388C94.052 52.7931 97.5787 51.7747 99.117 48.9662L122.073 7.91626C123.069 6.1154 122.426 3.85896 120.636 2.86206Z" fill="#2F444E"/>
<path d="M10.8107 13.8334C7.11789 15.8916 3.95566 16.3096 2.05833 14.5141C1.4634 13.9674 1.00247 13.201 0.670164 12.2041C0.273545 10.9767 0.0913155 9.40633 0.171711 7.4554C0.434337 0.787928 3.88063 -1.87585 9.94781 1.39893C17.6604 5.5527 17.5318 10.0977 10.8107 13.8334Z" fill="#FFC912"/>
</g>
<defs>
<clipPath id="clip0_202_19414">
<rect width="231.7" height="51.9623" fill="white" transform="translate(0.150024)"/>
</clipPath>
</defs>
</svg>

;
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import SERVICE_API from 'api/payway';
import { remapFieldsError } from 'common/replaceErrorMessage';
import { makeAutoObservable } from 'mobx';
import { CreateGroup, GroupDetail, Groups, GroupsModel } from 'models/groups/types';
import { toast } from 'react-toastify';
import { IAsyncUtil, IConstructorAsyncUtils } from 'store/types';

interface GroupsRepository {
  new (): GroupsModel;
}

export class GroupsController {
  private groupsModel: GroupsModel;

  private createGroupsAsyncHelper: IAsyncUtil;

  private groupsAsyncHelper: IAsyncUtil;

  private groupsDetailAsyncHelper: IAsyncUtil;

  private updateGroupsAsyncHelper: IAsyncUtil;

  private deleteGroupsAsyncHelper: IAsyncUtil;

  get getGroupsModel(): GroupsModel {
    return this.groupsModel;
  }

  get getCreateGroupsAsyncState(): IAsyncUtil {
    return this.createGroupsAsyncHelper;
  }

  get getGroupsAsyncState(): IAsyncUtil {
    return this.groupsAsyncHelper;
  }
  
  get getUpdateGroupsAsyncState(): IAsyncUtil {
    return this.updateGroupsAsyncHelper;
  }

  get getGroupsDetailAsyncState(): IAsyncUtil {
    return this.groupsDetailAsyncHelper;
  }

  get getDeleteGroupsAsyncState(): IAsyncUtil {
    return this.deleteGroupsAsyncHelper;
  }

  createGroupsAction = async (group: CreateGroup): Promise<void> => {
    this.createGroupsAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.GroupsAPI.createGroup(group);
      await this.getCreateGroupsSuccess(data);
      await this.getGroupsDetailAction({ groupId: data?.id });
      
      this.createGroupsAsyncHelper.successLoadingAsync(false, 'Group created');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.createGroupsAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  getGroupsAction = async (group: any): Promise<void> => {
    this.groupsAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.GroupsAPI.getGroups(group);
      this.getGroupsSuccess(data);
      this.groupsAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.groupsAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  getGroupsDetailAction = async (group: any): Promise<void> => {
    this.groupsDetailAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.GroupsAPI.getGroupsDetail(group);
      this.getGroupsDetailSuccess(data);
      this.groupsDetailAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.groupsDetailAsyncHelper.errorAsync(errorMessage, fieldErrors);
      this.groupsAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  updateGroupsAction = async (group: any): Promise<void> => {
    this.updateGroupsAsyncHelper.startLoadingAsync();
    try {
      await SERVICE_API.GroupsAPI.updateGroup(group);
      this.updateGroupsAsyncHelper.successLoadingAsync(false, 'Group edited');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.updateGroupsAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  deleteGroupsAction = async (group: any): Promise<void> => {
    this.deleteGroupsAsyncHelper.startLoadingAsync();
    try {
      await SERVICE_API.GroupsAPI.deleteGroup(group);
      this.deleteGroupsAsyncHelper.successLoadingAsync(false, 'Group deleted successfully');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.deleteGroupsAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  private ormErrors = (error: any) => {
    const errorMessage = error?.response?.data?.error || error?.message;
    const fieldErrors = error?.response?.data?.message;
    return { errorMessage, fieldErrors };
  };

  private getGroupsSuccess = (data: Groups): void => {
    this.groupsModel.setGroupList(data?.groups);
    this.groupsModel.setGroupMeta(data?.meta);
  };

  private getGroupsDetailSuccess = (data: GroupDetail): void => {
    this.groupsModel.setGroupDetail(data);
  };

  private getCreateGroupsSuccess = (data: GroupDetail): void => {
    this.groupsModel.setGroupDetail(data);
  };

  constructor(GroupsEntity: GroupsRepository, AsyncHelper: IConstructorAsyncUtils) {
    this.groupsModel = new GroupsEntity();
    this.groupsAsyncHelper = new AsyncHelper();
    this.createGroupsAsyncHelper = new AsyncHelper();
    this.groupsDetailAsyncHelper = new AsyncHelper();
    this.updateGroupsAsyncHelper = new AsyncHelper();
    this.deleteGroupsAsyncHelper = new AsyncHelper();

    makeAutoObservable(this);
  }
}

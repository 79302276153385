export const combinePaths = (parent: string, child: string) =>
  `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`;

export const buildPaths = (routes: any, parentPath = '') =>
  routes.map((route: any) => {
    const path = combinePaths(parentPath, route.path);

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) })
    };
  });

export const setupParents = (routes: any, parentRoute?: unknown) =>
  routes.map((route: any) => {
    const withParent = {
      ...route,
      ...((parentRoute && { parent: parentRoute }) as object)
    };

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent)
      })
    };
  });

export const flattenRoutes = (routes: any) =>
  routes
    .map((route: any) => [route.routes ? flattenRoutes(route.routes) : [], route])
    .flat(Infinity);

export const generateAppRoutes = (routes: any) =>
  flattenRoutes(setupParents(buildPaths(routes)));

export const pathTo: any = (route: any) => {
  if (!route.parent) {
    return [route];
  }

  return [...pathTo(route.parent), route];
};
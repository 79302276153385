import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import { LinkSVG } from 'assets/icons/link';
import { CrossSVG } from 'assets/icons/cross';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { WarningSVG } from 'assets/icons/warning';
import { UploadFile } from 'components/UploadFile';
import { toast } from 'react-toastify';
import s from './index.module.scss';

export interface IModalImportEmployeesViewProps {
  hideModal: () => void;
}

const ModalImportEmployeesView = (props: IModalImportEmployeesViewProps) => {
  const { hideModal } = props;
  const navigate = useNavigate();
  const [importFile, setImportFile] = useState<File>();

  const onSubmit = () => {
    if (importFile) {
      navigate('employees',  { state: { importFile } });
      hideModal();
    } else {
      toast.warning('Please, choose .csv file');
    }
  };

  const importEmployee = (file: File) => {
    setImportFile(file);
  };

  return (
        <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
            <div className={s.wrapper__container}>
                <div className={s.header}>
                    <div className={s.header__title}>
                        <h2 className={s.title}>Import payment file</h2>
                        <div className={s.header__description}>
                          <p className={s.description}>At this window  can upload import file, which should be in .csv format, and have the necessary employees to pay.</p>
                        </div>

                    </div>

                    <button type='button' className={s.close} onClick={() => hideModal()}>
                        <CrossSVG/>
                    </button>
                </div>

                <div className={s.main}>
                    <div className={s.description}>
                        <div className={s.description__wrapper}>
                            <a className={s.description__text} href={`${process.env.PUBLIC_URL}/downloads/instruction.docx`} download={'instruction.docx'}>
                              <p>Instruction for filling in a .csv file</p>
                              <LinkSVG/>
                            </a>
                        </div>
                       <UploadFile getImportFile={importEmployee} />
                    </div>

                    <div className={s.employee}>
                        <FormikContainer initialValues={{}} onSubmit={onSubmit}>
                            {() => (
                                <>
                                    <div className={s.buttons}>
                                        <motion.button
                                            whileTap={{ scale: 0.95 }}
                                            whileHover={{ scale: 1.05 }}
                                            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                                            type="submit"
                                        >
                                            <p>Import</p>
                                        </motion.button>
                                        <motion.button
                                            whileTap={{ scale: 0.95 }}
                                            whileHover={{ scale: 1.05 }}
                                            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                                            type="button"
                                            onClick={() => hideModal()}
                                        >
                                            <p>Cancel</p>
                                        </motion.button>
                                    </div>
                                </>
                            )}
                        </FormikContainer>
                    </div>
                </div>
            </div>
        </ModalBackdrop>
  );
};

export const ModalImportEmployees = ModalImportEmployeesView;

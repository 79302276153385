import React, { ChangeEvent, ReactElement, InputHTMLAttributes } from 'react';
import { useFormikContext, useField } from 'formik';

interface IFormikMuiRadio extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactElement | string;
  hideGroup?: boolean;
  value: string;
  name: string;
  id?: string;
}

const FormikRadio = (props: IFormikMuiRadio): ReactElement => {
  const { label = '', name = '', id, onChange, hideGroup, value, ...other } = props;
  const [field] = useField(name);
  const form = useFormikContext();
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!hideGroup) {
      form.setFieldValue(name, e.target.value);
      if (onChange) {
        onChange(e);
      }
    }
  };
  const inputValue = field.value || 'tab0';
  return (
    <>
      <input
        {...other}
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={inputValue === value}
        onChange={handleChange}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </>
  );
};

export default FormikRadio;

import { createContext, useContext, ReactElement } from 'react';
import { hideModal, openModal } from 'common/constants/modal/index';

export interface ModalContextInterface {
  modalProps?: any;
  Modal: () => ReactElement<any, any> | null;
  showModal:(Modal: any, modalProps?: any) => void;
  hideModal?(): void;
}

export const ModalContext = createContext<ModalContextInterface>({
  Modal: () => null,
  modalProps: {},
  showModal: () => {},
  hideModal: () => {},
});

export const modalReducer = (state: any, { type, Modal, modalProps }: any) => {
  switch (type) {
    case openModal:
      return { ...state, Modal, modalProps };
    case hideModal:
      return { ...state, Modal: null, modalProps: {} };
    default:
      throw new Error('Unspecified reducer action');
  }
};

export const useModal = () => useContext(ModalContext);

/* eslint-disable consistent-return, @typescript-eslint/no-unused-expressions, class-methods-use-this, no-nested-ternary */
import SERVICE_API from 'api/payway';
import { remapFieldsError } from 'common/replaceErrorMessage';
import { makeAutoObservable } from 'mobx';
import {
  CreateCheckProviderEmployee,
  CreateEmployee,
  CreateEmployeeDetail,
  EmployeeList,
  EmployeesModel
} from 'models/employees/types';
import { IAsyncUtil, IConstructorAsyncUtils } from 'store/types';
import { FilterEmployees } from 'common/constants/employeesList';

interface EmployeesRepository {
  new (): EmployeesModel;
}

export class EmployeesController {
  private employeesModel: EmployeesModel;

  private createEmployeesAsyncHelper: IAsyncUtil;

  private updateEmployeeAsyncHelper: IAsyncUtil;

  private employeeFiltersAsyncHelper: IAsyncUtil;

  private employeeSortAsyncHelper: IAsyncUtil;

  private deleteEmployeeAsyncHelper: IAsyncUtil;

  private employeesAsyncHelper: IAsyncUtil;

  private employeesAllActiveAsyncHelper: IAsyncUtil;

  private employeesAllAsyncHelper: IAsyncUtil;

  private employeesDetailAsyncHelper: IAsyncUtil;

  private employeesExportAsyncHelper: IAsyncUtil;

  public fixedData: any[] = [];

  public fixedErrors: any[] = [];

  get getEmployeesModel(): EmployeesModel {
    return this.employeesModel;
  }

  get getCreateEmployeesAsyncState(): IAsyncUtil {
    return this.createEmployeesAsyncHelper;
  }

  get getUpdateEmployeeAsyncState(): IAsyncUtil {
    return this.updateEmployeeAsyncHelper;
  }

  get getDeleteEmployeeAsyncHelper(): IAsyncUtil {
    return this.deleteEmployeeAsyncHelper;
  }

  get getEmployeesAsyncState(): IAsyncUtil {
    return this.employeesAsyncHelper;
  }

  get getAllActiveEmployeesAsyncState(): IAsyncUtil {
    return this.employeesAllActiveAsyncHelper;
  }

  get getEmployeeFiltersAsyncState(): IAsyncUtil {
    return this.employeeFiltersAsyncHelper;
  }

  get getEmployeeSortAsyncState(): IAsyncUtil {
    return this.employeeSortAsyncHelper;
  }

  get getEmployeesDetailAsyncState(): IAsyncUtil {
    return this.employeesDetailAsyncHelper;
  }

  get getEmployeesExportAsyncState(): IAsyncUtil {
    return this.employeesExportAsyncHelper;
  }

  createEmployeesAction = async (employee: CreateEmployee, lengthOfIteration?: number): Promise<any> => {
    this.createEmployeesAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.EmployeesAPI.createEmployee(employee);
      this.getEmployeesDetailSuccess(data);

      await this.fixedData.push(data);

      if (this.fixedData.length === lengthOfIteration) {
        this.createEmployeesAsyncHelper.successLoadingAsync(false, 'Employee(s) created');
        this.fixedData = [];
      }

      if (!lengthOfIteration) {
        this.createEmployeesAsyncHelper.successLoadingAsync(false, 'Employee(s) created');
      }
      return data;

    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.createEmployeesAsyncHelper.errorLoadingAsync(
        false, remapFieldsError(fieldErrors)['0'] || errorMessage
      );
    }
  };

  updateEmployeeAction = async (employee: any, lengthOfIteration?: number): Promise<any> => {
    this.updateEmployeeAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.EmployeesAPI.updateEmployee(employee);
      this.getEmployeesDetailSuccess(data);

      // Поправить что бы было одно уведомление об изменения сотрудника(ов)
      await this.fixedData.push(data);

      if (this.fixedData.length === lengthOfIteration) {
        this.updateEmployeeAsyncHelper.successLoadingAsync(false, 'Employees data changed');
        this.fixedData = [];
      }

      if (!lengthOfIteration) {
        this.updateEmployeeAsyncHelper.successLoadingAsync(false, 'Employees data changed');
      }
      this.updateEmployeeAsyncHelper.reset();
      return data;

    } catch (error: any) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.fixedErrors.push(error.response);
      if (this.fixedErrors.length === 1) {
        this.updateEmployeeAsyncHelper.errorLoadingAsync(
          false, remapFieldsError(fieldErrors)['0'] || errorMessage
        );
      }
    }
  };

  createCheckProviderEmployeeAction = async (
    employee: CreateCheckProviderEmployee
  ): Promise<void> => {
    // this.createEmployeesAsyncHelper.startAsync();
    try {
      const { data } = await SERVICE_API.EmployeesAPI.createCheckProvidersEmployee(employee);
      // this.getCreateEmployeesSuccess(data);
      // await this.createEmployeesAsyncHelper.successAsync('Employee created');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      // this.createEmployeesAsyncHelper.errorAsync(errorMessage, fieldErrors);
    }
  };

  getEmployeesAction = async (employee: any): Promise<void> => {

    if (employee.currentStatus) {
      this.employeesAllActiveAsyncHelper.startLoadingAsync();
    }

    if (employee.currentStatus && employee.currentStatus === FilterEmployees.ALL_NO_PAGE.toString()) {
      this.employeesAllAsyncHelper.startLoadingAsync();
    }

    if (!employee.currentStatus) {
      this.employeesAsyncHelper.startLoadingAsync();
    }

    try {
      // @ts-ignore
      const { data } = await SERVICE_API.EmployeesAPI.getEmployees(employee?.isAllByPagesEmployees ? { ...employee, active: employee.active } : {  ...employee, active: employee.currentStatus === FilterEmployees.ACTIVE.toString() || !employee.currentStatus ? FilterEmployees.ACTIVE_NO_PAGE.toString() : employee.currentStatus === FilterEmployees.ALL_NO_PAGE.toString() ? FilterEmployees.ALL_NO_PAGE.toString() : FilterEmployees.INACTIVE_NO_PAGE.toString() });
      // TODO: Нужно попросить Сашу сделать флаг на получение только всех активных всех и неактивных сотрудников (потому что мне всегда летит только 8 сотрудников, я не могу их отфильтровать всех и получить что мне нужно)

      if (employee.currentStatus) {
        this.getAllActiveEmployeesSuccess(data);
        this.employeesAllActiveAsyncHelper.successLoadingAsync();
      }

      if (employee.currentStatus && employee.currentStatus === FilterEmployees.ALL_NO_PAGE.toString()) {
        this.getAllEmployeesSuccess(data);
        this.employeesAllAsyncHelper.successLoadingAsync();
      }

      if (!employee.currentStatus) {
        this.getEmployeesSuccess(data);
        this.employeesAsyncHelper.successLoadingAsync();
      }

    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);

      if (employee.currentStatus && employee.currentStatus === FilterEmployees.ACTIVE.toString()) {
        this.employeesAllActiveAsyncHelper.errorLoadingAsync(
          false, remapFieldsError(fieldErrors)['0'] || errorMessage
        );
      }

      if (employee.currentStatus && employee.currentStatus === FilterEmployees.ALL_NO_PAGE.toString()) {
        this.employeesAllAsyncHelper.errorLoadingAsync(
          false, remapFieldsError(fieldErrors)['0'] || errorMessage
        );
      }

      if (!employee.currentStatus) {
        this.employeesAsyncHelper.errorLoadingAsync(
          false, remapFieldsError(fieldErrors)['0'] || errorMessage
        );
      }
    }
  };

  getEmployeeFilters = async (params?: { name?: string }): Promise<void> => {
    // this.employeeFiltersAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.EmployeesAPI.getEmployeesFilters(params);
      this.getEmployeesFiltersSuccess(data);
      // this.employeeFiltersAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      // this.employeeFiltersAsyncHelper.errorLoadingAsync(remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  getEmployeeSort = async (sortItem: any): Promise<void> => {
    this.employeeSortAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.EmployeesAPI.getEmployeesSort(sortItem);
      // this.getEmployeesFiltersSuccess(data);
      this.employeeSortAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.employeeSortAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  getEmployeesDetailAction = async (employee: any): Promise<void> => {
    this.employeesDetailAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.EmployeesAPI.getEmployeeDetail(employee);
      this.getEmployeesDetailSuccess(data);
      this.employeesDetailAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.employeesDetailAsyncHelper.errorLoadingAsync(
        false, remapFieldsError(fieldErrors)['0'] || errorMessage
      );
    }
  };

  getEmployeesExportAction = async (employee: any): Promise<void> => {
    this.employeesExportAsyncHelper.startLoadingAsync(true, 'File exporting process...');
    try {
      const response = await SERVICE_API.EmployeesAPI.getEmployeeExport(employee);

      const link = document.createElement('a');

      if (response.config.url) {
        link.href = await response.config.url;
      }

      await document.body.appendChild(link);
      await link.click();
      await this.employeesExportAsyncHelper.successLoadingAsync(true, 'File exported successfully');

    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.employeesExportAsyncHelper.errorLoadingAsync(
        true, remapFieldsError(fieldErrors)['0'] || errorMessage,
      );
    }
  };

  deleteEmployeesAction = async (employee: any, lengthOfIteration?: number, singleEmployee?: boolean, activateMessage?: string): Promise<void> => {
    this.deleteEmployeeAsyncHelper.startLoadingAsync();
    try {
      const data = await SERVICE_API.EmployeesAPI.deleteEmployee(employee);
      if (singleEmployee && activateMessage) {
        this.deleteEmployeeAsyncHelper.successLoadingAsync(false, activateMessage);
        this.deleteEmployeeAsyncHelper.reset();
      } else {
        await this.fixedData.push(data);

        // if (this.fixedData.length === 1) {
        //   this.deleteEmployeeAsyncHelper.successLoadingAsync('Employees removed');
        // }

        if (this.fixedData.length === lengthOfIteration && activateMessage) {
          this.deleteEmployeeAsyncHelper.successLoadingAsync(false, activateMessage);
          this.fixedData = [];
        }

        if (this.fixedData.length === lengthOfIteration && !activateMessage) {
          this.deleteEmployeeAsyncHelper.successLoadingAsync(false, 'Employees deactivated');
          this.fixedData = [];
        }

        if (!lengthOfIteration) {
          this.deleteEmployeeAsyncHelper.successLoadingAsync(false, 'Employees deactivated');
        }
      }

      // this.fixedData = [];
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.deleteEmployeeAsyncHelper.errorLoadingAsync(
        false, remapFieldsError(fieldErrors)['0'] || errorMessage
      );
    }
  };

  private ormErrors = (error: any) => {
    const errorMessage = error?.response?.data?.error || error?.message;
    const fieldErrors = error?.response?.data?.message;
    return { errorMessage, fieldErrors };
  };

  private getEmployeesSuccess = (data: CreateEmployeeDetail): void => {
    this.employeesModel.setEmployeesList(data.list);
    this.employeesModel.setEmployeesMeta(data.meta);
  };

  private getAllActiveEmployeesSuccess = (data: CreateEmployeeDetail): void => {
    this.employeesModel.setAllActiveEmployeesList(data.list);
    this.employeesModel.setAllActiveEmployeesMeta(data.meta);
  };

  private getAllEmployeesSuccess = (data: CreateEmployeeDetail): void => {
    this.employeesModel.setAllEmployeesList(data.list);
    this.employeesModel.setAllEmployeesMeta(data.meta);
  };

  private getEmployeesFiltersSuccess = (data: CreateEmployeeDetail): void => {
    this.employeesModel.setEmployeeFilters(data.list);
    this.employeesModel.setEmployeesMeta(data.meta);
  };

  private getEmployeesDetailSuccess = (data: EmployeeList): void => {
    this.employeesModel.setEmployeesDetail(data);
  };

  constructor(EmployeesEntity: EmployeesRepository, AsyncHelper: IConstructorAsyncUtils) {
    this.employeesModel = new EmployeesEntity();
    this.employeesAsyncHelper = new AsyncHelper();
    this.employeeSortAsyncHelper = new AsyncHelper();
    this.employeesAllAsyncHelper = new AsyncHelper();
    this.updateEmployeeAsyncHelper = new AsyncHelper();
    this.deleteEmployeeAsyncHelper = new AsyncHelper();
    this.employeeFiltersAsyncHelper = new AsyncHelper();
    this.createEmployeesAsyncHelper = new AsyncHelper();
    this.employeesDetailAsyncHelper = new AsyncHelper();
    this.employeesExportAsyncHelper = new AsyncHelper();
    this.employeesAllActiveAsyncHelper = new AsyncHelper();

    makeAutoObservable(this);
  }
}

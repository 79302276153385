/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { BackSVG } from 'assets/icons/back';
import { LinkSVG } from 'assets/icons/link';
import { PhoneSVG } from 'assets/icons/phone';
import { GroupSVG } from 'assets/icons/group';
import { useTranslation } from 'react-i18next';
import { useModal } from 'hooks/useModal.hook';
import { ReturnSVG } from 'assets/icons/return';
import { motion, Variants } from 'framer-motion';
import { Pagination } from 'components/pagination';
import { Providers } from 'models/providers/types';
import { EditLightSVG } from 'assets/icons/editLight';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikContainer from 'components/form/containers';
import { AnimatedDivPage } from 'components/animatedPage';
import { DeleteLightSVG } from 'assets/icons/deleteLight';
import { RouterLink } from 'router/components/routerLink';
import { KindOfFieldEmployees } from 'common/constants/modal';
import { CreateEmployeePhones } from 'models/employees/types';
import FormikSelect from 'components/form/fields/select/index';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import { useLocation, useNavigate, useParams } from 'react-router';
import { KindOfPage } from 'common/constants/additionalOperatorModal';
import { NextArrowSVG, PrevArrowSVG } from 'assets/paginationArrows/paginationArrows';
import { operatorsTableEmployeeDetailTitles, selectSortByTitles } from 'common/constants/employeesDetail';
import { IOperatorsTableContent, IOperatorsTableTitles } from 'common/constants/employeesDetail/types';
import s from './index.module.scss';

const EmployeesDetailView: FC = () => {
  const { state }: any = useLocation();
  const params: any = useParams();
  const { showModal } = useModal();
  const { employees, auth, providers, accounts } = useStores();
  const { getAuthModel } = auth;
  const { getProvidersModel } = providers;
  const { getAuthMe } = getAuthModel;
  const { t } = useTranslation();
  const { isTablet } = useWindowDimensions();
  const { getEmployeesModel, getUpdateEmployeeAsyncState, getDeleteEmployeeAsyncHelper } =
    employees;
  const {  getDeleteAccountsAsyncState } = accounts;
  const [isModalOpen, setIsModalOpen] = useState(true);
  const isDeletedLoading = getDeleteAccountsAsyncState.getLoadingState;
  const [isOperatorsItemsSorted, setIsOperatorsItemsSorted] = useState<any>(false);
  const [isButtonClicked, setIsButtonClicked] = useState<any>(false);
  const [editedFavoritePhone, setEditedFavoritePhone] = useState<string>('');
  const [allOperatorsItems, setAllOperatorsItems] = useState<any>(
    []?.sort((a: any, b: any) => {
      if (a?.provider?.title < b?.provider?.title) {
        return -1;
      }
      if (a?.provider?.title > b?.provider?.title) {
        return 1;
      }

      return 0;
    })
  );

  const [dynamicOperatorsItems, setDynamicOperatorsItems] = useState<any>([]);
  const [currentGroupItem, setCurrentGroupItem] = useState<any>(
    state?.isCheckGroup?.filter((item: any) => String(item.id) === params.id)
  );
  const currentEmployeesDetail = {
    name: state?.client?.name || currentGroupItem?.[0]?.name || state?.name,
    phone: state?.client?.phone || currentGroupItem?.[0]?.phone || state?.phone,
    group: state?.client?.group || currentGroupItem?.[0]?.group || state?.group,
    id: params?.id
  };
  const [currentPage, setCurrentPage] = useState<number>(
    state?.isCheckGroup?.findIndex((item: any) => item?.id === currentEmployeesDetail?.id)
  );
  const [createOperators, setCreateOperators] = useState<any>([]);
  const [editInitialOperators, setEditInitialOperators] = useState<any>([]);
  const [choosenItems, setChoosenItems] = useState<any>([]);
  const [phoneIdCount, setPhoneIdCount] = useState<number>(0);
  const [createPhoneField, setCreatePhoneField] = useState([]);
  const [changePhonePriority, setChangePhonePriority] = useState<any>([]);
  const [ isEditFavoritePhone, setIsEditFavoritePhone ] = useState(false);
  const isEmployeeUpdatedLoading = getUpdateEmployeeAsyncState.getLoadingState;
  const isEmployeeDeletedLoading = getDeleteEmployeeAsyncHelper.getLoadingState;
  const validateSchema = Yup.object().shape({
    ...createPhoneField
      ?.map((item: any) => ({
        [`${item?.phoneName}`]: Yup.string()
          .transform((value: string) => value.replace(/[^\d]/g, ''))
          .min(10, 'Phone number should be valid')
          .required('This is a required field')
      }))
      .reduce((acc: any, item: any) => Object.assign(acc, item), {}),
    editPhone: Yup.string()
      .transform((value: string) => value.replace(/[^\d]/g, ''))
      .min(10, 'Phone number should be valid')
      .required('This is a required field'),
    ...(dynamicOperatorsItems?.accounts?.length
      ? [
        ...dynamicOperatorsItems?.accounts
          ?.filter((item: any) => item?.editItems)
          .map((item: any) => item?.editItems),
        ...createOperators
      ]
      : createOperators
    )
      ?.map((operator: any) => {
        if (
          !operator?.amount ||
          operator?.amount < operator.minAmount ||
          operator?.amount > operator.maxAmount
        ) {
          return {
            [`${operator.defaultPrice}`]: Yup.object().shape({
              amount: Yup.number()
                .required()
                .min(operator.minAmount, `Must be greater than or equal to ${operator.minAmount}`)
                .max(operator.maxAmount, `Must be less than or equal to ${operator.maxAmount}`)
            })
          };
        }
        return {};
      })
      .reduce((acc: any, item: any) => Object.assign(acc, item), {})
  });

  const editInitialValues = editInitialOperators
    ?.reduce((acc: any, item: any) => {
      acc.push(
        item?.defaultPriceList
          ? {
            defaultPriceInitial: {
              [`${item.defaultPriceId}`]: {
                amount: +item?.amount,
                min: item?.minAmount,
                max: item?.maxAmount
              }
            },
            uniqueIdInitial: { [`${item.uniqueId}`]: item?.uniqueIdAccount },
            providerInitial: {
              [`${item.providerInitialId}`]: {
                ...item,
                label: item?.provider?.title,
                id: item.provider.id,
                value: item.provider?.id,
                singleValue: item?.provider?.title
              }
            },
            productInitial: {
              [`${item.productId}`]: item?.defaultPriceList
                ? {
                  ...item,
                  label: item?.defaultPriceList?.label,
                  id: item?.defaultPriceList?.id,
                  value: item.amount,
                  singleValue: item?.defaultPriceList?.label
                }
                : null
            }
          }
          : {
            defaultPriceInitial: {
              [`${item?.defaultPriceId}`]: {
                amount: +item?.amount,
                min: item?.minAmount,
                max: item?.maxAmount
              }
            },
            uniqueIdInitial: { [`${item.uniqueId}`]: item?.uniqueIdAccount },
            providerInitial: {
              [`${item?.providerInitialId}`]: {
                ...item,
                label: item?.provider?.title,
                id: item?.provider?.id,
                value: item?.provider?.id,
                singleValue: item?.provider?.title
              }
            }
          }
      );
      return acc;
    }, [])
    .reduce(
      (acc: any, item: any) =>
        Object.assign(
          acc,
          item.defaultPriceInitial,
          item.providerInitial,
          item.productInitial,
          item.uniqueIdInitial
        ),
      {}
    );

  const navigate = useNavigate();

  const onSubmit = (values?: any) => {
    const resultData: any = [];
    setIsButtonClicked(true);
    const phones: any = createPhoneField
      .map((item: any) => ({ phone: item?.phone, favorite: item?.favorite }))
      .flat(1);

    if (values) {
      Object.entries(values).map(([key, valuesData]: [string, any]): any => {
        resultData.push(
          ...createOperators
            .reduce((acc: any, operatorItem: any) => {
              choosenItems.map((choosenItem: any) => {
                if (operatorItem.providerId === choosenItem.id) {
                  acc.push({
                    defaultPrice: operatorItem.defaultPriceListId ? null : operatorItem.amount,
                    defaultPriceListId: operatorItem.defaultPriceListId
                      ? operatorItem.defaultPriceListId
                      : null,
                    note: null,
                    providerId: operatorItem.providerId,
                    employeeId: params?.id,
                    uniqueId: choosenItem.uniqueId
                  });
                }
              });
              return acc;
            }, [])
            ?.map((item: any) => {
              if (key === item?.uniqueId) {
                item.uniqueId = valuesData;

                return item;
              }
            })
        );
      });
    }

    employees?.updateEmployeeAction({
      employeeId: toJS(getEmployeesModel.getEmployeesDetail)?.id,
      name: values?.editName || toJS(getEmployeesModel.getEmployeesDetail)?.name,
      phones:
        (changePhonePriority?.length
          ? changePhonePriority
          : [
            ...toJS(getEmployeesModel.getEmployeesDetail)?.phones.map((phone: any) => ({
              phone: phone.phone,
              favorite: phone?.favorite
            })),
            ...phones.map((item: any) =>
              item?.phone.length > 10
                ? {
                  phone: item?.phone.slice(0, item?.phone.length - 1),
                  favorite: item?.favorite
                }
                : item
            )
          ]).map((phone: CreateEmployeePhones) => {
          if (phone.favorite && editedFavoritePhone) {
            phone.phone = editedFavoritePhone;
          }

          return phone;
        } ),
      surname: values?.editSurname || toJS(getEmployeesModel.getEmployeesDetail)?.surname,
      groupId: toJS(getEmployeesModel.getEmployeesDetail)?.group?.id,
      accounts: toJS(getEmployeesModel.getEmployeesDetail)?.accounts?.reduce(
        (acc: any, accountItem: any) => {
          acc.push({
            defaultPrice: accountItem.defaultPriceList ? null : accountItem.defaultPrice,
            defaultPriceListId: accountItem.defaultPriceList
              ? accountItem.defaultPriceList?.id
              : null,
            defaultScenario: accountItem?.defaultScenario?.id ? {
              label: accountItem?.defaultScenario?.label,
              id: accountItem?.defaultScenario?.id,
              createAt: accountItem?.defaultScenario?.createAt,
              deletedAt: accountItem?.defaultScenario?.deletedAt,
              type: accountItem?.defaultScenario?.type
            } : null,
            defaultValueEmployee: accountItem?.defaultValueEmployee || false,
            note: null,
            providerId: accountItem.provider?.id,
            employeeId: params?.id,
            uniqueId: accountItem.uniqueId
          });

          return acc;
        },
        []
      )
    });
  };

  const selectUnique = () =>
    toJS(getProvidersModel?.getProvidersList)
      .filter(
        (allProviders: any) =>
          !toJS(getEmployeesModel.getEmployeesDetail).accounts?.map((item: any) => item?.provider?.id).includes(allProviders.id)
      )
      .sort((a: any, b: any) => {
        if (a?.title < b?.title) {
          return -1;
        }
        if (a?.title > b?.title) {
          return 1;
        }

        return 0;
      });

  const addNewPhone = () => {

    showModal(Modals.ModalEditEmployee, {
      editKindOfField: KindOfFieldEmployees.EMPLOYEEPHONEADD,
      getEmployeesModel,
      employees
    });

    setPhoneIdCount(phoneIdCount + 1);
    setCreatePhoneField((prevState: any): any => [
      ...prevState,
      {
        phoneName: `phone${phoneIdCount}`,
        id: phoneIdCount,
        additionalPhone: true,
        favorite: false
      }
    ]);
  };

  const handleChangePhonePriority = (e: any) => {
    if (toJS(getEmployeesModel.getEmployeesDetail).phones.filter(phone => phone.favorite)[0].phone !== e.value) {
      showModal(Modals.ModalEditPhonePriority, {
        choosenPhone: e,
        getEmployeesModel,
        employees
      });
    }
  };

  const handleOpenCreateMoveToGroup = () => {
    setIsModalOpen(true);
    showModal(Modals.ModalMoveToGroupDetail, {
      setIsModalOpen,
      isModalOpen,
      fakeEmployeesContent: getEmployeesModel.getEmployeesList,
      showModal,
      currentEmployee: getEmployeesModel.getEmployeesDetail
    });
  };

  const animations: Variants = {
    initial: { opacity: 0, scale: 0.6, y: -10 },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.6, y: -10 }
  };

  const removeAccount = (account: any) => {

    showModal(Modals.ModalRemoveOperatorFromEmployee, {
      operator: account,
      setDynamicOperatorsItems,
      dynamicOperatorsItems,
      accounts
    });


  };

  const removeAccountEdit = (account: any) => {
    accounts.deleteAccountsAction({ id: account.id });
    setDynamicOperatorsItems({
      ...dynamicOperatorsItems,
      accounts: dynamicOperatorsItems?.accounts?.filter((item: any) => item?.id !== account?.id)
    });
  };

  const removeDynamicItem = (account: any) => {
    setDynamicOperatorsItems({
      ...dynamicOperatorsItems,
      accounts: dynamicOperatorsItems?.accounts?.reduce((acc: any, item: any) => {
        if (item?.id === account?.id) {
          acc.push({
            ...item,
            editItems: null
          });
        } else {
          acc.push({
            ...item
          });
        }

        return acc;
      }, [])
    });
  };

  const editFavoritePhone = () => {
    showModal(Modals.ModalEditEmployee, {
      editKindOfField: KindOfFieldEmployees.EMPLOYEEPHONE,
      getEmployeesModel,
      employees
    });
  };

  const sortDynamicOperatorsItems = (a: any, b: any) => {
    if (a > b) {
      return isOperatorsItemsSorted ? 1 : -1;
    }

    if (a < b) {
      return !isOperatorsItemsSorted ? 1 : -1;
    }
  };
  const handleOperatorsEdit = (content?: any) => {
    toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
      if (provider.id === content?.provider?.id) {
        content.provider = provider;
      }
    });
    showModal(Modals.ModalAdditionOperatorFull, { uniqueProviders: selectUnique(), choosenItems, setChoosenItems, currentOperatorData: content ? { ...content, minAmount: content?.provider?.minAmount, maxAmount: content?.provider?.maxAmount, uniqueIdValue: content?.uniqueId, amount: content?.defaultPrice || content?.defaultPriceList?.price || null, id: content?.provider?.id, initialProviderId: content?.provider?.id } : null, currentKindOfPage: KindOfPage.DETAIL_EMPLOYEE_PAGE, employees });
  };

  const handleSortItems = (titles: any) => {
    setIsOperatorsItemsSorted(!isOperatorsItemsSorted);
    setAllOperatorsItems(
      toJS(
        getEmployeesModel.getEmployeesDetail.accounts.sort((a: any, b: any): any => {
          if (titles.data === 'amount') {
            return sortDynamicOperatorsItems(a.defaultPrice, b.defaultPrice);
          }

          if (titles.data === 'title') {
            return sortDynamicOperatorsItems(a.provider.title, b.provider.title);
          }
        })
      )
    );
  };

  const handleSelectSort = (e: any) => {
    setIsOperatorsItemsSorted(!isOperatorsItemsSorted);
    setAllOperatorsItems(
      toJS(
        getEmployeesModel.getEmployeesDetail.accounts.sort((a: any, b: any): any => {
          if (e.value === 'amount') {
            return sortDynamicOperatorsItems(a.defaultPrice, b.defaultPrice);
          }

          if (e.value === 'name') {
            return sortDynamicOperatorsItems(a.provider.title, b.provider.title);
          }
        })
      )
    );
  };

  const handleNextPage = () => {
    if (state?.isCheckGroup?.length - 1 > currentPage) {
      navigate(`/employees/employees-detail/${state?.isCheckGroup[currentPage + 1].id}`, {
        state: {
          isCheckGroup: state?.isCheckGroup,
          isCheckGroupCurrent: state?.isCheckGroup[currentPage + 1],
          page: state?.page,
          previousPageWithQuery: state.previousPageWithQuery
        }
      });
    }
  };
  const handlePrevPage = () => {
    if (currentPage !== 0) {
      navigate(`/employees/employees-detail/${state?.isCheckGroup[currentPage - 1].id}`, {
        state: {
          isCheckGroup: state?.isCheckGroup,
          isCheckGroupCurrent: state?.isCheckGroup[currentPage - 1],
          page: state?.page,
          previousPageWithQuery: state.previousPageWithQuery
        }
      });
    }
  };

  const removePhone = (e: ChangeEvent<HTMLButtonElement>, phone: any) => {
    e.stopPropagation();

    showModal(Modals.ModalPhoneRemoveConfirmation, {
      phone,
      params,
      employees,
      employeesDetail: toJS(getEmployeesModel.getEmployeesDetail),
      phones: toJS(getEmployeesModel.getEmployeesDetail)?.phones.filter((phoneItem: any) => phoneItem?.phone !== phone?.phone),
    });
  };

  const handleEmployeeFullName = () => {
    showModal(Modals.ModalEditEmployee, {
      editKindOfField: KindOfFieldEmployees.EMPLOYEEFULLNAME,
      getEmployeesModel,
      employees
    }
    );
  };

  useEffect(() => {
    employees.getEmployeesDetailAction({
      clientId: getAuthMe?.id,
      employeeId: params?.id
    });
  }, []);

  useEffect(() => {
    if (isEmployeeUpdatedLoading === Loading.success) {
      employees.getEmployeesDetailAction({
        clientId: getAuthMe?.id,
        employeeId: params?.id
      });

      setIsEditFavoritePhone(false);
    }
  }, [isEmployeeUpdatedLoading]);

  useEffect(() => {
    if (isDeletedLoading === Loading.success) {
      employees.getEmployeesDetailAction({
        clientId: getAuthMe?.id,
        employeeId: params?.id
      });
    }
  }, [isDeletedLoading]);

  useEffect(() => {
    providers.getProvidersAction();
  }, [
  ]);

  useEffect(() => {
    if (isEmployeeUpdatedLoading === Loading.success) {
      setChoosenItems([]);
      setCreateOperators([]);
      setCreatePhoneField([]);
      setDynamicOperatorsItems([]);
    }
  }, [isEmployeeUpdatedLoading]);

  useEffect(() => {
    setDynamicOperatorsItems([...dynamicOperatorsItems, toJS(getEmployeesModel?.getEmployeesDetail)]);

    return () => setDynamicOperatorsItems([]);
  }, []);

  useEffect(() => {
    if (isButtonClicked) {
      setTimeout(() => {
        setIsButtonClicked(false);
      }, 500);
    }
  }, [isButtonClicked]);

  const handleOperatorActive = () => {
    showModal(Modals.ModalChangeEmployeeStatus, { currentEmployee: toJS(getEmployeesModel.getEmployeesDetail), employees });
  };

  useEffect(() => {
    if (isEmployeeDeletedLoading === Loading.success) {
      employees.getEmployeesDetailAction({
        clientId: getAuthMe?.id,
        employeeId: params?.id
      });
    }
    return () => getDeleteAccountsAsyncState.reset();
  }, [isEmployeeDeletedLoading]);

  return (
    <section className={s.wrapper}>
      <div className={s.wrapper__container}>
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: { opacity: 0, x: 70, y: 70 },
            animate: { opacity: 1, x: 0, y: 0 },
            exit: { opacity: 0, x: 70, y: 70 }
          }}
          transition={{ duration: 0.7, type: 'spring', damping: 22, stiffness: 155 }}
          className={s.back}
        >
          <ReactTooltip textColor="#334049" backgroundColor="#fff" border borderColor="#E5E8ED" />
          <motion.button
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className={s.back_icon}
            onClick={() =>
              navigate(state.previousPageWithQuery, { state: { client: state?.isCheckGroup || currentEmployeesDetail, page: state.page, sortId: state.sortId, active: state.active, previousPageWithQuery: state.previousPageWithQuery } })
            }
          >
            <BackSVG />
          </motion.button>

          <div className={s.back_text}>
            <p>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.BACKBUTTON')}</p>
          </div>
        </motion.div>

        <AnimatedDivPage
          transition={{ duration: 0.7, type: 'spring', damping: 15 }}
          animations={animations}
        >
          <>
            { state?.isCheckGroup?.length > 1 || state?.length > 1 ?
            <div className={s.paginate__buttons__mobile}>
              <button onClick={() => handlePrevPage()} type="button">
                <PrevArrowSVG />
              </button>
              <button onClick={() => handleNextPage()} type="button">
                <NextArrowSVG />
              </button>
            </div>
              : null
            }

            <FormikContainer
              validationSchema={validateSchema}
              enableReinitialize
              initialValues={{
                phone:
                getEmployeesModel.getEmployeesDetail?.phones.filter((phone: any) =>
                  toJS(phone.favorite)
                )?.length
                  ? {
                    singleValue: `+256${
                      getEmployeesModel.getEmployeesDetail?.phones.filter((phone: any) =>
                        toJS(phone.favorite)
                      )[0].phone
                    }`,
                    label: `+256${
                      getEmployeesModel.getEmployeesDetail?.phones.filter((phone: any) =>
                        toJS(phone.favorite)
                      )[0].phone
                    }`,
                    value: `+256${
                      getEmployeesModel.getEmployeesDetail?.phones.filter((phone: any) =>
                        toJS(phone.favorite)
                      )[0].phone
                    }`
                  }
                  : null,
                editName: toJS(getEmployeesModel.getEmployeesDetail)?.name,
                editPhone: editedFavoritePhone || toJS(getEmployeesModel.getEmployeesDetail)?.phones.filter((phone: CreateEmployeePhones) => phone?.favorite)[0]?.phone,
                editSurname: toJS(getEmployeesModel.getEmployeesDetail)?.surname,
                ...createPhoneField?.reduce(
                  (phoneAcc: any, phoneItem: any) =>
                    Object.assign(phoneAcc, { [`${phoneItem?.phoneName}`]: phoneItem?.phone }),
                  {}
                ),
                ...editInitialValues
              }}
              onSubmit={onSubmit}
            >
              {() => (
                <>
                  <div className={s.detail}>
                    <div className={s.detail__inner}>
                        <div className={s.detail__title_header}>
                          <div className={s.detail__title_wrapper}>
                            <div className={s.wrapper__title}>
                              <h1 className={s.detail__title}>
                                {getEmployeesModel?.getEmployeesDetail?.name}{' '}
                                {getEmployeesModel?.getEmployeesDetail?.surname}
                              </h1>
                              <div className={s.operators__items_icon_edit}>
                                <button type="button"
                                        disabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt}
                                        onClick={handleEmployeeFullName}>
                                  <EditLightSVG />
                                </button>
                              </div>
                            </div>

                            <motion.button
                              whileTap={{ scale: 0.95 }}
                              whileHover={{ scale: 1.05 }}
                              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                              type="button"
                              onClick={handleOperatorActive}
                              className={[!toJS(getEmployeesModel.getEmployeesDetail).deletedAt ? s.operator_deactivated : s.operator_activated ].join(' ')}
                            >
                              <p>
                                { toJS(getEmployeesModel.getEmployeesDetail).deletedAt ?
                                  t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.BUTTONS.ACTIVATE')
                                  :
                                  t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.BUTTONS.DEACTIVATE')
                                }

                              </p>
                            </motion.button>
                          </div>
                        </div>
                    </div>

                    <div className={s.group}>
                      <h4 className={s.phone__title}>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.FORM.GROUPTITLE')}</h4>
                      {getEmployeesModel?.getEmployeesDetail.group?.title ? (
                          <div className={s.header__group__button}>
                            <RouterLink to={`/groups/groups-detail/${toJS(getEmployeesModel?.getEmployeesDetail.group?.id)}`} state={{ previousPageWithQuery: '/groups' }}>
                                <button disabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt} className={[s.header__group__button_btn, toJS(getEmployeesModel.getEmployeesDetail).deletedAt ? s.header__group__button_inactive : null ].join(' ')} type="button">
                                  <div className={s.group__left_item}>
                                    <div className={s.group__icon}>
                                      <GroupSVG />
                                    </div>
                                    <p>{getEmployeesModel?.getEmployeesDetail?.group?.title}</p>
                                  </div>

                                  <div className={s.group__right_item}>
                                      <LinkSVG />
                                  </div>
                                </button>
                            </RouterLink>

                            <div className={s.edit__icon}>
                              <button className={s.edit_btn} type="button"
                                      onClick={handleOpenCreateMoveToGroup}
                                      disabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt}
                              >
                                <EditLightSVG />
                              </button>
                            </div>
                          </div>
                      ) :
                          <div className={s.header__group__button}>
                            <button className={s.header__group__button_btn} disabled type="button">
                              <div className={s.group__left_item}>
                                <p>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.FORM.GROUPNONE')}</p>
                              </div>
                            </button>
                            <div className={s.edit__icon}>
                              <button className={s.edit_btn} type="button"
                                      disabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt}
                                      onClick={handleOpenCreateMoveToGroup}
                              >
                                <EditLightSVG />
                              </button>
                            </div>
                          </div>
                      }

                    </div>

                    <div className={s.phone}>
                      <h4 className={s.phone__title}>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.FORM.PHONE')}</h4>

                      <div className={s.phone__choose}>
                        <div className={s.phone__choose_inner} style={{ marginRight: isEditFavoritePhone ? '3rem' : '1rem' }}>

                        <>
                          <FormikSelect
                              optionIcon={<DeleteLightSVG />}
                              valueContainer={{ paddingLeft: '2.75rem' }}
                              childrenStyles={{ opacity: toJS(getEmployeesModel.getEmployeesDetail).deletedAt ? '0.5' : '1' }}
                              isDisabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt}
                              menuStyles={{ width: '100%' }}
                              containerStyles={{ width: '18.75rem', maxWidth: '100%', opacity: toJS(getEmployeesModel.getEmployeesDetail).deletedAt ? '0.5' : '1' }}
                              name="phone"
                              changePhonePriority
                              options={toJS(getEmployeesModel.getEmployeesDetail.phones).reduce(
                                (acc: any, phone) => {
                                  acc.push({
                                    value: phone.phone,
                                    label: `+256${phone.phone}`,
                                    singleValue: `+256${phone.phone}`,
                                    optionIcon: toJS(getEmployeesModel.getEmployeesDetail.phones)?.length > 1
                                      ? (
                                        <div className={s.select__mobile__icon}>
                                          <button
                                            onClick={(e: any) => removePhone(e, phone)}
                                            type="button"
                                            className={s.select__mobile__icon_button}
                                          >
                                            <DeleteLightSVG />
                                          </button>
                                        </div>
                                      ) : null,
                                    selectMobileIconStyles: { display: 'flex' },
                                    selectMobileStyles: {
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center'
                                    }
                                  });
                                  return acc;
                                },
                                []
                              )}
                              // isSearchable={false}
                              placeholder={'+256 000 000 000'}
                              indicatorIcon={<DropSelectSVG />}
                              onChange={handleChangePhonePriority}
                            >
                              <PhoneSVG />
                          </FormikSelect>
                          { toJS(getEmployeesModel.getEmployeesDetail.phones)?.length ?
                          <div className={s.edit__icon}>
                              <button className={s.edit_btn} type="button"
                                disabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt}
                                onClick={editFavoritePhone}
                              >
                                <EditLightSVG />
                              </button>
                          </div>
                            :
                            null
                          }
                        </>
                        </div>


                        {/* <div className={s.input__countries}> */}
                        {/*  {createPhoneField.map((content: any, index: any) => ( */}
                        {/*    <FormikSelectCountries */}
                        {/*      wrapperChildrenStyles={{ display: 'flex', alignItems: 'center' }} */}
                        {/*      key={content.phoneName} */}
                        {/*      name="phone" */}
                        {/*    > */}
                        {/*      <> */}
                        {/*        <div */}
                        {/*          className={` ${s.countries__wrapper__input} ${ */}
                        {/*            errors[content.phoneName] ? s.border_alert : '' */}
                        {/*          }`} */}
                        {/*        > */}
                        {/*          <Field name={content.phoneName}> */}
                        {/*            {({ field }: any) => ( */}
                        {/*              <MaskedInput */}
                        {/*                {...field} */}
                        {/*                onInput={checkPhoneInputField} */}
                        {/*                mask={[ */}
                        {/*                  '(', */}
                        {/*                  /\d/, */}
                        {/*                  ')', */}
                        {/*                  /\d/, */}
                        {/*                  /\d/, */}
                        {/*                  /\d/, */}
                        {/*                  '-', */}
                        {/*                  /\d/, */}
                        {/*                  /\d/, */}
                        {/*                  /\d/, */}
                        {/*                  '-', */}
                        {/*                  /\d/, */}
                        {/*                  /\d/, */}
                        {/*                  /\d/ */}
                        {/*                ]} */}
                        {/*                placeholder={t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.FORM.PHONEPLACEHOLDER')} */}
                        {/*                guide */}
                        {/*                name={content.phoneName} */}
                        {/*                id={content.phoneName} */}
                        {/*              /> */}
                        {/*            )} */}
                        {/*          </Field> */}
                        {/*        </div> */}
                        {/*        {content.additionalPhone ? ( */}
                        {/*          <div className={s.phone__items_remove}> */}
                        {/*            <button */}
                        {/*              onClick={() => removeAdditionalPhone(content.id)} */}
                        {/*              type="button" */}
                        {/*              className={s.remove__button} */}
                        {/*            > */}
                        {/*              <DeleteLightSVG /> */}
                        {/*            </button> */}
                        {/*          </div> */}
                        {/*        ) : null} */}
                        {/*      </> */}
                        {/*    </FormikSelectCountries> */}
                        {/*  ))} */}
                        {/* </div> */}
                      </div>



                      <div className={s.phone__button}>
                        <motion.button
                          disabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt}
                          whileTap={{ scale: 0.95 }}
                          whileHover={{ scale: 1.05 }}
                          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                          onClick={addNewPhone}
                          type="button"
                        >
                          <p>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.BUTTONS.ADDPHONE')}</p>
                        </motion.button>
                      </div>
                    </div>
                    <div className={s.operators}>
                      <div className={s.operators__header}>
                        <div className={s.operators__wrapper__title}>
                          <h4 className={s.operators__title}>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.TITLE')}</h4>
                        </div>

                        <div className={s.operators__sort}>
                          <FormikSelect
                            valueContainer={{ paddingLeft: '.5rem' }}
                            wrapperSelectStyles={{
                              width: '11rem',
                              maxWidth: '100%',
                              minWidth: '100%'
                            }}
                            options={selectSortByTitles(t)}
                            name="sort"
                            placeholder={t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.SORTMOBILE.PLACEHOLDER')}
                            indicatorIcon={<DropSelectSVG />}
                            onChange={handleSelectSort}
                          />
                        </div>
                      </div>

                      <div className={s.operators__list}>
                        <div className={s.operators__list__header}>
                          {operatorsTableEmployeeDetailTitles(t).map((titles: IOperatorsTableTitles) => (
                            <div key={titles.id} className={s.header__item}>
                              <button
                                onClick={() => handleSortItems(titles)}
                                type="button"
                                className={s.item}
                              >
                                <p>{titles.name}</p>
                                {titles.icon}
                              </button>
                            </div>
                          ))}
                        </div>

                        <div className={s.operators__main}>
                          {toJS(getEmployeesModel.getEmployeesDetail.accounts).length ? (allOperatorsItems.length
                            ? allOperatorsItems
                            : (dynamicOperatorsItems?.accounts?.length
                              ? dynamicOperatorsItems
                              : toJS(getEmployeesModel?.getEmployeesDetail)
                            )?.accounts?.sort((a: any, b: any) => {
                              if (a?.provider?.title < b?.provider?.title) {
                                return -1;
                              }
                              if (a?.provider?.title > b?.provider?.title) {
                                return 1;
                              }

                              return 0;
                            })
                          )?.map((content: IOperatorsTableContent | any) => (
                            <motion.div
                              initial="initial"
                              animate="animate"
                              exit="exit"
                              variants={{
                                initial: { opacity: 0, y: -10 },
                                animate: { opacity: 1, y: 0 },
                                exit: { opacity: 0, y: 10 }
                              }}
                              transition={{
                                duration: 0.5,
                                type: 'spring',
                                stiffness: 150,
                                damping: 18,
                                bounce: 0
                              }}
                              key={content.id}
                              className={s.operators__items}
                            >
                              {content?.editItems ? (
                                <motion.div
                                  initial="initial"
                                  animate="animate"
                                  exit="exit"
                                  variants={{
                                    initial: { opacity: 0, y: -10 },
                                    animate: { opacity: 1, y: 0 },
                                    exit: { opacity: 0, y: 10 }
                                  }}
                                  transition={{
                                    duration: 0.5,
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 18,
                                    bounce: 0
                                  }}
                                  key={content?.editItems?.id}
                                  className={`${s.operators__items} ${s.operators__items_edit} ${s.operators__items__create}`}
                                >
                                  <div className={s.operators__items_icon_edit}>
                                    <button type="button" onClick={() => removeDynamicItem(content)}>
                                      <ReturnSVG />
                                    </button>
                                  </div>

                                  <div className={s.operators__items_icon}>
                                    <button
                                      onClick={() => removeAccountEdit(content)}
                                      type="button"
                                      className={s.remove__button}
                                    >
                                      <DeleteLightSVG />
                                    </button>
                                  </div>
                                </motion.div>
                              ) : (
                                <>
                                  {isTablet ? (
                                    operatorsTableEmployeeDetailTitles(t).map(
                                      (titles: IOperatorsTableTitles | any) => (
                                        <div key={titles.id} className={s.operators__item}>
                                          {titles.data === 'title' ? (
                                            <p>{content?.provider?.title}</p>
                                          ) : null}

                                          {titles.data === 'uniqueId' ? (
                                            <p>{content.uniqueId || '----'}</p>
                                          ) : null}
                                          {titles.data === 'amount' ? (
                                            <p>
                                              { content.defaultPriceList ? `${Number(content.defaultPriceList?.price)?.toFixed(2)?.toLocaleString()} UGX` : content.defaultPrice ? `${Number(content?.defaultPrice)?.toLocaleString()} UGX`
                                                :
                                                '----'}
                                            </p>
                                          ) : null}
                                          {titles.data === 'product' ? (
                                            <p>
                                              {content?.defaultPriceList?.label || '----'}
                                            </p>
                                          ) : null}
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <div key={content?.id} className={s.operators__item}>
                                      <div className={s.item__inner}>
                                        <h5>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADERMOBILE.NAME')}</h5>
                                        <p>{content?.provider?.title}</p>
                                      </div>

                                      <div className={s.item__inner__wrapper}>
                                        <div className={s.item__inner}>
                                          <h5>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADERMOBILE.ID')}</h5>
                                          <p>{content.uniqueId || '----'}</p>
                                        </div>
                                        <div className={s.item__inner}>
                                          <h5>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADERMOBILE.AMOUNT')}</h5>
                                          <p>
                                            { content.defaultPriceList ? `${Number(content.defaultPriceList?.price)?.toFixed(2)?.toLocaleString()} UGX` : content.defaultPrice ? `${Number(content?.defaultPrice)?.toLocaleString()} UGX`
                                              :
                                              '----'}
                                          </p>
                                        </div>
                                      </div>
                                      <div className={s.item__inner}>
                                        <h5>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.LIST.HEADERMOBILE.PRODUCT')}</h5>
                                        <p>{content?.defaultPriceList?.label || '----'}</p>
                                      </div>
                                    </div>
                                  )}
                                  <div className={s.operators__items_icon_edit}>
                                    <button type="button"
                                    // onClick={() => editAccount(content)}
                                    disabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt}
                                    onClick={() => handleOperatorsEdit(content)}
                                    >
                                      <EditLightSVG />
                                    </button>
                                  </div>
                                  <div className={s.operators__items_icon}>
                                    <button type="button"
                                            disabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt}
                                            onClick={() => removeAccount(content)}>
                                      <DeleteLightSVG />
                                    </button>
                                  </div>
                                </>
                              )}
                            </motion.div>
                          ))
                            :
                          <div className={s.operators__empty}>
                            <p>There are no operators</p>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className={s.operators__interactive}>
                      <div className={s.operators__button_add}>
                        <motion.button
                          // onClick={() => addNewOperator()}
                          disabled={!!toJS(getEmployeesModel.getEmployeesDetail).deletedAt}
                          onClick={() => handleOperatorsEdit()}
                          whileTap={{ scale: 0.95 }}
                          whileHover={{ scale: 1.05 }}
                          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                          type="button"
                        >
                          <p>{t('MAIN.EMPLOYEES.EMPLOYEEDETAIL.BUTTONS.ADDOPERATOR')}</p>
                        </motion.button>
                      </div>

                    </div>
                  </div>

                  {state?.isCheckGroup?.length > 1 || state?.length > 1 ? (
                    <>
                      <div className={s.detail__back} />
                      <div
                        className={`${s.detail__back__small} ${
                          state?.isCheckGroup?.length === 2 ? s.detail__back__small_hide : ''
                        }`}
                      />
                    </>
                  ) : null}
                </>
              )}
            </FormikContainer>
          </>
        </AnimatedDivPage>
        {state?.isCheckGroup?.length > 1 || state?.length > 1 ? (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={{
              initial: { opacity: 0, y: -70 },
              animate: { opacity: 1, y: 0 },
              exit: { opacity: 0, y: -70 }
            }}
            transition={{ duration: 0.7, type: 'spring', damping: 15 }}
            className={s.detail__pagination}
          >
            <Pagination
              groupPageGuantity={state?.isCheckGroup?.length}
              totalItems={state?.isCheckGroup?.length}
              items={state?.isCheckGroup}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              currentListOfEmployeePage={state?.page}
              currentEmployeesDetailState={state}
              currentEmployeesDetail={currentEmployeesDetail}
              employeesDetailPage
            />
          </motion.div>
        ) : null}
      </div>
    </section>
  );
};

export const EmployeesDetail = observer(EmployeesDetailView);
import React from 'react';

export const GroupSVG = () => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2V12C0 13.1046 0.89543 14 2 14H16C17.1046 14 18 13.1046 18 12V4C18 2.89543 17.1046 2 16 2H10L8 0H2C0.895431 0 0 0.89543 0 2Z"
      fill="#FFCA00"
    />
  </svg>
);

import React from 'react';
import { ColorsText } from 'common/colors';

export const SortArrowSVG = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.76569 8.83466C8.0781 9.14708 8.0781 9.65361 7.76569 9.96603L4.56569 13.166C4.25327 13.4784 3.74673 13.4784 3.43431 13.166L0.234315 9.96603C-0.078105 9.65361 -0.078105 9.14708 0.234315 8.83466C0.546734 8.52224 1.05327 8.52224 1.36569 8.83466L3.2 10.669L3.2 1.40034C3.2 0.958514 3.55817 0.600342 4 0.600342C4.44183 0.600342 4.8 0.958514 4.8 1.40034L4.8 10.669L6.63432 8.83466C6.94673 8.52224 7.45327 8.52224 7.76569 8.83466Z"
      fill={ColorsText.textTitle}
    />
  </svg>
);

/* eslint-disable no-param-reassign */
import { makeAutoObservable } from 'mobx';
import { GroupDetail, GroupMeta, GroupsModel } from './types';

export class GroupsEntity implements GroupsModel {
  private groupList: GroupDetail[] = [];

  private groupMeta: GroupMeta = {
    total: 0,
    page: 1,
    quantity: 0,
    sort: 0
  };

  private groupListDetail: GroupDetail = {
    id: '',
    title: '',
    parent_id: '',
    description: '',
    membersCount: 0,
    client: {
      id: '',
      email: '',
      name: '',
      picture: '',
      phone: ''
    },
    employees: [
      {
        id: '',
        name: '',
        surname: ''
      }
    ],
    groupsAccounts: []
  };

  setGroupList(groupList: GroupDetail[]) {
    this.groupList = groupList.map(group => {
      if (group.groupsAccounts) {
        group.groupsAccounts = group.groupsAccounts.map(account => {
          if (account.priceList) {
            account.priceList.description = account.priceList.label;
            return account;
          }
          return account;
        });
      }
      return group;
    });
  }

  get getGroupList(): GroupDetail[] {
    return this.groupList;
  }

  setGroupDetail(groupListDetail: GroupDetail) {
    this.groupListDetail = { ...groupListDetail, groupsAccounts: groupListDetail.groupsAccounts.map(account => {
      if (account.priceList) {
        account.priceList.description = account.priceList.label;
        return account;
      }
      return account;
    }) };
  }

  get getGroupDetail(): GroupDetail {
    return this.groupListDetail;
  }

  setGroupMeta(meta: GroupMeta) {
    this.groupMeta = meta;
  }

  get getGroupMeta(): GroupMeta {
    return this.groupMeta;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

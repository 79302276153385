export enum ColorsGeneral {
  white = '#ffffff',
  greenLight = '#16A249',
  yellowLight = '#E7B008',
  black = '#000000',
}

export enum ColorsText {
  textPrimary = 'rgba(26, 26, 26, 0.5)',
  textPrimarySecondary = 'rgba(26, 26, 26, 0.7)',
  textTitle = '#1A1A1A'
}

export enum ColorsButton {
  buttonPrimary = '#FFCA00',
  buttonPrimaryText = '#1a1a1a'
}

export enum ColorsBorder {
  borderPrimary = 'rgba(26, 26, 26, 0.2)'
}

export enum ColorsBackground {
  backgroundPrimary = '#33434D',
  backgroundPrimarySecond = '#25323A',
  backgroundSecondary = '#F2F2F2',
  backgroundGrayLight = '#FDFDFD',
  backgroundGrayExtraLight = 'rgba(26, 26, 26, 0.1)',
  backgroundGrayAlmostWhite = 'rgba(26, 26, 26, 0.05)'
}

export enum ColorsStatus {
  statusSuccessful = '#4EDB75',
  statusFailed = '#FF6161',
  statusInProgress = '#ffca00',
}

// New colors from UI

// Accent
export enum ColorsAccent {
  accentYellow = '#ffca00',
  accentDark = '#1a1a1a'
}

// Gray
export enum ColorsGray {
  grayOne = '#ffca00',
  accentTwo = '#1a1a1a',
  grayThree = '#828282',
  grayFour = '#bdbdbd',
  grayFive = '#e0e0e0',
  graySix = '#f2f2f2',
  grayEight = '#E8ECF1'
}

export type Colors =
  | ColorsBackground
  | ColorsBorder
  | ColorsButton
  | ColorsText
  | ColorsGeneral
  | ColorsGray
  | ColorsAccent;

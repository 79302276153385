import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';


const MainView = (): ReactElement => {
  console.log('');

  return (
    <section>
      <h1>Main</h1>
      {/* Убираем стандартный роут на home */}
      <Navigate to="/signup" />
    </section>
  );
};

export const Main = observer(MainView);

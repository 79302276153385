/* eslint-disable no-param-reassign */
import { useStores } from 'store';
import ReactPaginate from 'react-paginate';
import React, { useEffect, useState } from 'react';
import { PrevArrowSVG, NextArrowSVG } from 'assets/paginationArrows/paginationArrows';
import { useNavigate, useParams } from 'react-router';
import s from './index.module.scss';

interface IPaginationProps {
  totalItems: number;
  items: any[];
  activeItems?: any[];
  isCheck?: any[];
  isStatus?: any;
  changePageWithSave?: boolean;
  changePageWithSavePath?: string;
  groupPageGuantity?: number;
  elementsPerPage?: number;
  employeesDetailPage?: boolean;
  currentEmployeesDetail?: any;
  currentPage?: any;
  currentSort?: any;
  activeLocation?: any;
  currentFilter?: any;
  searchParams?: any;
  activeParams?: any;
  setCurrentDynamicItems?: ((data: any) => void) | any;
  searchMoreFunctions?: string;
  setDynamicPage?: ((page: number) => void);
  setCurrentPage?: ((page: any) => void) | any;
  paginateMoreFunctions?: boolean;
  isMoreFunctionsElementsSorted?: boolean;
  currentListOfEmployeePage?: number;
  currentEmployeesDetailState?: any;
}

export const Pagination = (props: IPaginationProps) => {
  const {
    items,
    isCheck,
    isStatus,
    totalItems,
    currentSort,
    currentPage,
    activeItems,
    searchParams,
    activeParams,
    currentFilter,
    activeLocation,
    setDynamicPage,
    setCurrentPage,
    elementsPerPage,
    groupPageGuantity,
    employeesDetailPage,
    searchMoreFunctions,
    changePageWithSave,
    changePageWithSavePath,
    setCurrentDynamicItems,
    currentEmployeesDetail,
    paginateMoreFunctions,
    currentListOfEmployeePage,
    isMoreFunctionsElementsSorted,
    currentEmployeesDetailState
  } = props;

  const { auth, employees } = useStores();
  const { getAuthModel } = auth;
  const params = useParams();
  const { getAuthMe } = getAuthModel;
  const quantityPerPage = 8;
  const [itemOffset, setItemOffset] = useState(0);
  const pageQuantity = Math.ceil(totalItems / quantityPerPage);
  const itemsPerPage = elementsPerPage || 8;
  const [moreFunctionsPageQuantity, setMoreFunctionsPageQuantity] = useState(1);
  const pageQuantitySecond = Math.ceil(totalItems / itemsPerPage);
  const navigate = useNavigate();

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentDynamicItems?.(items?.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, items]);

  useEffect(() => {
    if (paginateMoreFunctions) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentDynamicItems?.(items?.slice(itemOffset, endOffset));
      setItemOffset(((currentPage - 1) * itemsPerPage) % items.length);
    }
  }, [searchMoreFunctions, items, itemsPerPage, itemOffset, isMoreFunctionsElementsSorted]);

  useEffect(() => {
    if (searchMoreFunctions) {
      setDynamicPage?.(1);
    }

  }, [searchMoreFunctions, items]);

  const handlePageClick = (page: any) => {
    const newOffset = (page.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    setDynamicPage?.(page?.selected + 1);

    if (changePageWithSave && changePageWithSavePath) {
      // if (activeParams.get('sortId')) {
      //   activeParams.set('sortId', currentSort);
      // }
      // if (activeParams.get('active')) {
      //   activeParams.set('active', currentFilter);
      // }
      //
      const currentParams = new URLSearchParams(window.location.search);
      currentParams.set('page', page?.selected + 1);
      // Создаем новый URL с обновленными параметрами
      const newUrl = `${activeLocation.pathname}?${currentParams.toString()}`;

      // Обновляем историю
      window.history.pushState({ path: newUrl }, '', newUrl);
      // window.history.pushState(null, '', `${(searchParams || `?page=${page?.selected + 1}`)}${+currentSort ? `&sortId=${currentSort}` : ''}${+currentFilter ? `&active=${currentFilter}` : ''}`);
      // TODO: Организовать правильную работу параметров поиска (фильтров/сортировки/текущей страницы), убрать возможно navigate отсюда, использовать какие-нибудь useSearchParams by react-router-dom.
      // navigate(`${changePageWithSavePath}?page=${page?.selected + 1}&sortId=${currentSort}&active=${currentFilter}`, { state: { isCheckGroup: activeItems, isCheck: activeItems?.map((item: any) => item.id) } });
      // navigate(`${activeLocation.pathname}?${activeParams.toString()}`, { state: { isCheckGroup: activeItems, isCheck: activeItems?.map((item: any) => item.id) } });
    }

    if (employeesDetailPage) {
      // employees.getEmployeesDetailAction({
      //   clientId: getAuthMe?.id,
      //   employeeId: params?.id
      // });
      setCurrentPage(page);
      navigate(`/employees/employees-detail/${items[page.selected].id}`, {
        state: { previousPageWithQuery: currentEmployeesDetailState?.previousPageWithQuery, active: currentEmployeesDetailState?.active, sortId: currentEmployeesDetailState?.sortId, isCheckGroup: items, client: items[page.selected], page: currentListOfEmployeePage }
      });
    }
  };

  return (
    <>
      {employeesDetailPage ? (
        <ReactPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={groupPageGuantity || pageQuantity}
          nextLabel={<NextArrowSVG />}
          previousLabel={<PrevArrowSVG />}
          containerClassName={s.pagination__container}
          activeClassName={s.page__current}
          forcePage={currentPage === -1 ? 0 : currentPage}
          pageClassName={s.page__item}
          nextClassName={s.page__next}
          previousClassName={s.page__prev}
          breakClassName={s.page__break}
        />
      ) : (
        <ReactPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={8}
          pageCount={pageQuantitySecond}
          forcePage={currentPage - 1}
          nextLabel={<NextArrowSVG />}
          previousLabel={<PrevArrowSVG />}
          containerClassName={s.pagination__container}
          activeClassName={s.page__current}
          pageClassName={s.page__item}
          nextClassName={s.page__next}
          previousClassName={s.page__prev}
          breakClassName={s.page__break}
        />
      )}
    </>
  );
};
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { AccountsController } from 'controllers/accounts';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  modalRefEl: any;
  setActiveRadio?: (active: boolean) => void;
  setCreateOperators: (operators: any[]) => void;
  setCreateOperatorsGroup: (operators: any[]) => void;
  setProductOperatorCheckList: (operators: any[]) => void;
  setFirstChoosenOperatorsCheck: (operators: any[]) => void;
  setChoosenItems: (operators: any[]) => void;
  createOperators: any[];
  createOperatorsGroup: any[];
  productOperatorCheckList: any[];
  isFirstChoosenOperatorsCheck: any[];
  choosenItems: any[];
  operator: any;
}

const ModalRemoveOperatorFromCreatePageView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    modalRefEl,
    setCreateOperators,
    operator,
    setActiveRadio,
    setCreateOperatorsGroup,
    setProductOperatorCheckList,
    setFirstChoosenOperatorsCheck,
    setChoosenItems,
    createOperators,
    createOperatorsGroup,
    productOperatorCheckList,
    isFirstChoosenOperatorsCheck,
    choosenItems
  } = props;

  const changeGroupCheck = () => {

    setCreateOperators(createOperators.filter((item: any) => item.id !== operator.id));
    setCreateOperatorsGroup(
      createOperatorsGroup.filter((item: any) => item.provider?.id !== operator?.provider?.id)
    );
    setProductOperatorCheckList(
      productOperatorCheckList.filter((item: any) => item !== operator.id)
    );
    setFirstChoosenOperatorsCheck(
      isFirstChoosenOperatorsCheck.filter((item: any) => item !== operator.id)
    );
    setChoosenItems(
      choosenItems?.filter((item: any) => item?.selectedFieldId !== `provider${operator?.provider?.id}`)
    );

    if (setActiveRadio) {
      setActiveRadio(false);
    }

    hideModal();

  };

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Delete operator</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              Do you really want to delete this provider: {operator?.provider?.title} ?
            </p>
          </div>

          <div className={s.employee}>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={changeGroupCheck}
              >
                <p>Yes</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>No</p>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalRemoveOperatorFromCreatePage = observer(ModalRemoveOperatorFromCreatePageView);

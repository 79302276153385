import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';

export default i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
    },
    fallbackLng: 'en',
    lng: 'en',
    supportedLngs: ['en'],
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain', 'sessionStorage'],
      caches: ['cookie', 'localStorage', 'sessionStorage']
    },
    react: {
      useSuspense: false
    }
  });

// Change logic language example in feature
// cookies.get('i18next')} onClick={() => i18next.changeLanguage('en')}


/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { ProvidersEntity } from 'models/providers';
import { Providers } from 'models/providers/types';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  setCreateOperatorsGroup: (operatorsGroup: any) => void;
  setCreateOperators: (operators: any) => void;
  setChoosenItems: (choosenItems: any) => void;
  removeDuplicates: (item: any, key: any) => void;
  getProvidersModel?: ProvidersEntity;
  setActiveRadio: (activeRadio: any) => void;
  setIsOpenModal: (open: boolean) => void;
  setGroupTitle: (title: string) => void;
  groupItem?: any;
  group?: any;
  e?: any;
  setChoosenGroup: (id: any) => void;
}

const ModalEmployeeCreateOperatorsGroupChangeView = (props: ModalCreateGroupViewProps) => {
  const {
    e,
    group,
    groupItem,
    hideModal,
    setGroupTitle,
    setActiveRadio,
    setIsOpenModal,
    setChoosenGroup,
    setChoosenItems,
    removeDuplicates,
    getProvidersModel,
    setCreateOperators,
    setCreateOperatorsGroup,
  } = props;

  const { providers } = useStores();

  const onSubmit = async (values: any) => {
    await setCreateOperatorsGroup(
      groupItem.groupsAccounts.reduce((acc: any, item: any, index: any) => {
        // TODO: Сделать уникальный index для всех, что бы не пересикались с createOperators

        // Hardcode
        getProvidersModel?.getProvidersList.map((provider: Providers) => {
          if (provider.id === item?.provider?.id) {
            item.provider = toJS(provider);
          }
        });

        acc.push({
          operatorName: `operator${item?.provider?.id}`?.split('.')?.join(''),
          operatorUniqueIdName: `id${item?.provider?.id}`?.split('.')?.join(''),
          amountName: `amount${item?.provider?.id}`?.split('.')?.join(''),
          noteName: `note${item?.provider?.id}`?.split('.')?.join(''),
          defaultPriceId: `defaultPrice${item?.provider?.id}`?.split('.')?.join(''),
          providerInitialId: `provider${item?.provider?.id}`?.split('.')?.join(''),
          productId: `product${item?.provider?.id}`?.split('.')?.join(''),
          defaultPriceList: item.priceList,
          amount: +item?.defaultPrice || +item?.priceList?.price,
          id: item.provider.id,
          minAmount: +item.provider.minAmount,
          maxAmount: +item.provider.maxAmount,
          // TODO: Тест currentScenario и defaultScenarioId
          defaultScenario: item?.defaultScenario || null,
          provider: item.provider,
          selectedFieldId: `provider${item?.provider?.id}`?.split('.')?.join(''),
          uniqueId: `uniqueId${item?.provider?.id}`?.split('.')?.join(''),
          defaultPrice: `defaultPrice${item?.provider?.id}`?.split('.')?.join(''),
          [`defaultPrice${item?.provider?.id}`?.split('.')?.join('')]: item.defaultPrice,
          [`provider${item?.provider?.id}`?.split('.')?.join('')]: item.provider,
          note: `note${item?.provider?.id}`?.split('.')?.join(''),
          isDisabled: item?.availability === 'Disabled',
          genre: ['pop'],
          providerId: item.provider.id,
          defaultPriceListId: item.priceList ? item.priceList.id : null,
          isDefaultOperatorValue: true,
          isDefaultIdValue: true,
          isDefaultProductValue: true,
          isDefaultAmountValue: true,
          defaultValueEmployee: false
        });
        return acc;
      }, [])
    );

    await setCreateOperators([]);

    const choosenGroupData = groupItem.groupsAccounts.reduce((acc: any, item: any, index: any) => {
      acc.push({
        ...item.provider,
        isDisabled: item?.availability === 'Disabled',
        genre: ['pop'],
        selectedFieldId: `provider${index + 9999}`,
        uniqueId: `uniqueId${index + 9999}`,
        defaultPrice: `defaultPrice${index + 9999}`,
        note: `note${index + 9999}`,
        groupChoosenField: true
      });

      return acc;
    }, []);
    
    setChoosenItems(removeDuplicates([...choosenGroupData], (key: any) => key.id));
    setChoosenGroup(group?.group?.id);
    if (e.target.id === group.id) {
      setActiveRadio(e.target);
    }

    hideModal();
    setIsOpenModal(false);
    setGroupTitle(groupItem?.title);
  };

  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Warning</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              { groupItem?.id ? "When adding a user to a group, all values that are currently selected will be reassigned to the group's default value, do you really want to change them ?" : 'Are you sure want to leave the employee from group and reset values ?' }
            </p>
          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalEmployeeCreateOperatorsGroupChange = observer(
  ModalEmployeeCreateOperatorsGroupChangeView
);

import React from 'react';

export const UploadCsvSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink"
         width="28" height="28" x="0" y="0" viewBox="0 0 64 64"
         xmlSpace="preserve" className="">
        <g transform="matrix(1.1099999999999994,0,0,1.1099999999999994,-3.5200002098083303,-3.519999999999982)">
            <path
                d="M52.12 27.56V20.5c0-.26-.1-.48-.26-.66-.01-.01-.01-.02-.01-.03L36.48 3.56h-.01a1 1 0 0 0-.72-.31H12.88c-.55 0-1 .45-1 1v23.31c-3.63.44-6.45 3.53-6.45 7.28v12.32c0 3.75 2.82 6.84 6.45 7.28v5.31c0 .55.45 1 1 1h38.24c.55 0 1-.45 1-1v-5.31c3.63-.44 6.45-3.53 6.45-7.28V34.84c0-3.75-2.82-6.84-6.45-7.28zM36.75 6.76 48.8 19.5H36.75zM13.88 5.25h20.87V20.5c0 .55.45 1 1 1h14.37v6H13.88zm36.24 53.5H13.88V54.5h36.24zm6.45-11.59c0 2.95-2.39 5.34-5.34 5.34H12.77c-2.95 0-5.34-2.39-5.34-5.34V34.84c0-2.95 2.39-5.34 5.34-5.34h38.46c2.95 0 5.34 2.39 5.34 5.34z"
                fill="#000000" data-original="#000000" className=""/>
            <path
                d="M26.88 44.04c-.33 1.22-.9 2.14-1.69 2.74-.8.6-1.81.899-3.01.899-1.49 0-2.73-.51-3.7-1.54-.95-1.01-1.44-2.42-1.44-4.159 0-1.851.49-3.311 1.45-4.341.97-1.029 2.26-1.56 3.82-1.56 1.38 0 2.51.42 3.38 1.229.52.49.91 1.19 1.16 2.091.02.069.01.14-.02.199-.04.061-.09.101-.16.11l-2.14.521c-.07.01-.14 0-.19-.03a.276.276 0 0 1-.12-.16c-.11-.5-.34-.89-.7-1.18s-.79-.431-1.32-.431c-.72 0-1.29.25-1.74.771-.46.53-.69 1.399-.69 2.62 0 1.29.23 2.21.68 2.729.44.53 1 .78 1.71.78.52 0 .95-.16 1.32-.49s.65-.859.82-1.59a.22.22 0 0 1 .12-.16c.06-.04.13-.05.2-.03l2.1.671c.13.041.2.181.16.311zM36.93 44.12c0 .66-.18 1.29-.55 1.87-.37.569-.89 1.01-1.56 1.29-.66.27-1.47.41-2.44.41-1.41 0-2.52-.341-3.29-1-.77-.671-1.24-1.641-1.38-2.9-.01-.07.01-.14.05-.19s.1-.09.17-.09l2.11-.21c.14-.01.25.08.27.21.11.641.35 1.12.69 1.41s.81.44 1.41.44c.63 0 1.11-.13 1.41-.391.3-.239.45-.52.45-.84 0-.2-.06-.35-.17-.49-.12-.14-.35-.27-.67-.39-.24-.08-.82-.24-1.71-.46-1.22-.3-2.05-.67-2.56-1.12-.71-.64-1.07-1.43-1.07-2.34 0-.6.17-1.16.51-1.68.33-.511.82-.91 1.45-1.181.61-.26 1.35-.39 2.21-.39 1.4 0 2.48.32 3.21.95s1.12 1.489 1.16 2.54c0 .14-.11.26-.24.26l-2.17.1a.26.26 0 0 1-.26-.21c-.08-.489-.25-.84-.5-1.04-.26-.199-.67-.31-1.22-.31-.57 0-1.02.12-1.33.34a.54.54 0 0 0-.25.47c0 .181.08.33.24.46.16.141.62.37 1.89.67 1.07.25 1.87.511 2.4.79.54.29.97.681 1.28 1.181.31.501.46 1.121.46 1.841zM47.64 36.6 43.8 47.33a.26.26 0 0 1-.24.17h-2.31a.26.26 0 0 1-.24-.17L37.17 36.6a.304.304 0 0 1 .03-.229.26.26 0 0 1 .21-.11h2.35c.11 0 .2.07.24.17l2.47 7.24 2.4-7.24c.03-.1.13-.17.24-.17h2.3c.08 0 .15.04.2.11.05.069.06.149.03.229z"
                fill="#000000" data-original="#000000" className=""/>
        </g>
    </svg>
);

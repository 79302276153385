/* eslint-disable no-nested-ternary, prefer-const */
import React, {
  ChangeEvent,
  ReactElement,
  InputHTMLAttributes,
  ReactChild,
  ReactChildren,
  useState,
  useEffect
} from 'react';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import { observer } from 'mobx-react-lite';
import { useFormikContext, useField } from 'formik';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { AnyObject, ObjectShape, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import s from './index.module.scss';

interface IFormikMuiInput extends InputHTMLAttributes<HTMLInputElement>, CurrencyInputProps {
  label?: string | ReactElement;
  children?: ReactChild | ReactChildren;
  initialStateForUpdate?: any;
  errorStyles?: any;
  validateYup?: OptionalObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>>,
  defaultValue?: any;
  step?: any;
  inputStyles?: any;
  toolTipTextStyles?: any;
  description?: string;
  accountId?: string;
  toolTipWrapperStyles?: any;
  isIntervalField?: boolean;
  isSeporatorDisabled?: boolean;
}

const FormikTextFieldNumber = (props: IFormikMuiInput): ReactElement => {
  const {
    children,
    onChange,
    onValueChange,
    name = '',
    label = '',
    min = 0 as any,
    max = 0 as any,
    errorStyles,
    inputStyles,
    isIntervalField,
    accountId,
    defaultValue,
    description,
    type = 'text',
    disabled = false,
    validateYup,
    toolTipTextStyles,
    isSeporatorDisabled,
    toolTipWrapperStyles,
    ...other
  } = props;

  const [ errorMessage, setErrorMessage ] = useState<any>('');
  const [ isValidateError, setIsValidateError ] = useState<any>(false);
  
  const checkValidateError = (checkedValue: any) => {
    if (Number(checkedValue) > max && max) {
      setErrorMessage(`Maximum amount: ${Number(max)?.toFixed(2)?.toLocaleString()}`);
      return true;
    } 
    
    if (Number(checkedValue) < min && min) {
      setErrorMessage(`Minimum amount: ${Number(min)?.toFixed(2)?.toLocaleString()}`);
      return true;
    } 

    return false;
  };
  
  const form = useFormikContext();

  const handleChange = (e: any, nameSecond: any): void => {
    setIsValidateError(checkValidateError(e));
    
    form.setFieldValue(name, { amount: +e || +defaultValue, min: min || '', max: max || '', accountId: accountId || '', description: description || '' });
    if (onValueChange) {
      onValueChange(e, name);
    }
  };

  const evalProps: any = {
    ...other,
    id: name,
    name,
    disabled,
    type,
    onValueChange: handleChange,
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isValidateError]);

  return (
    <>
      {label && (
        <label className={s.label} htmlFor={name}>
          <h4 className={s.label__title}>{label}</h4>
        </label>
      )}
      <div className={s.input__group}>
        <div data-tip={isIntervalField ? '' : errorMessage} className={s.wrapper__input}>
            <CurrencyInput decimalsLimit={2} allowDecimals disableGroupSeparators={isSeporatorDisabled || isIntervalField} style={{ ...inputStyles, border: isValidateError && !isIntervalField ? '1px solid red' : ( isIntervalField ? '1px solid #E8ECF1' : '1px solid rgba(26, 26, 26, 0.2)') }} suffix={ isSeporatorDisabled || isIntervalField ? '' : ' UGX' } defaultValue={defaultValue} {...evalProps}  />
          {children}
        </div>
      </div>
    </>
  );
};

export default observer(FormikTextFieldNumber);

import React from 'react';

export const WarningRepeatPaymentSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="24"
    height="24"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    className=""
  >
    <g transform="matrix(1.1399999999999992,0,0,1.1399999999999992,-2.240021595954886,-2.1700001335143924)">
      <path
        d="M4.581 28h22.838c.922 0 1.753-.485 2.221-1.297a2.685 2.685 0 0 0-.002-2.682L18.219 4.293C17.75 3.484 16.921 3 16 3s-1.75.484-2.219 1.293L2.362 24.021a2.687 2.687 0 0 0-.002 2.682A2.545 2.545 0 0 0 4.581 28zm-.488-2.977L15.512 5.295a.55.55 0 0 1 .976 0l11.419 19.728a.68.68 0 0 1 0 .682.556.556 0 0 1-.488.295H4.581a.556.556 0 0 1-.488-.295.68.68 0 0 1 0-.682z"
        fill="#000000"
        data-original="#000000"
        className=""
       />
      <path
        d="M15 20v-9a1 1 0 0 1 2 0v9a1 1 0 0 1-2 0zM15 23.75v-.5a1 1 0 0 1 2 0v.5a1 1 0 0 1-2 0z"
        fill="#000000"
        data-original="#000000"
        className=""
       />
    </g>
  </svg>
);

import AuthAPI from './auth';
import UsersAPI from './users';
import GroupsAPI from './groups';
import BalanceAPI from './balance';
import AccountsAPI from './accounts';
import PaymentsAPI from './payments';
import ProvidersAPI from './providers';
import EmployeesAPI from './employees';
import PriceListAPI from './priceList';
import TransactionsAPI from './transactions';

const SERVICE_API = {
  AuthAPI,
  UsersAPI,
  GroupsAPI,
  BalanceAPI,
  PaymentsAPI,
  AccountsAPI,
  ProvidersAPI,
  PriceListAPI,
  EmployeesAPI,
  TransactionsAPI
};

export default SERVICE_API;

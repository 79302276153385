import React from 'react';

export const PlusSVG = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 1V17M17 9L1 9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

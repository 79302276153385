import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';
import { ProviderCreate } from 'models/providers/types';

abstract class ProvidersAPI {
  public static createProvider = (data: ProviderCreate): Promise<AxiosResponse> =>
    RestAPI.post('/api/providers', data);

  public static getProviders = (): Promise<AxiosResponse> =>
    RestAPI.get('/api/providers', { page: 1, quantity: 58 });

  public static getCurrentProvider = (data: any): Promise<AxiosResponse> =>
    RestAPI.get(`/api/providers/${data.id}`);
}

export default ProvidersAPI;

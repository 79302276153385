import React from 'react';
import { makeAutoObservable } from 'mobx';
import { GetBalance, BalanceModel } from './types';

export class BalanceEntity implements BalanceModel {
  private balance: GetBalance = {
    account: '',
    amount: 0
  };

  setBalance(balance: GetBalance) {
    this.balance = balance;
  }

  get getBalance(): GetBalance {
    return this.balance;
  }


  constructor() {
    makeAutoObservable(this);
  }
}

import React from 'react';

export const AdditionLightSVG = () => (
  <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2L2.01 2M9 2L9.01 2M16 2L16.01 2M3 2C3 2.55228 2.55228 3 2 3C1.44772 3 1 2.55228 1 2C1 1.44771 1.44772 0.999999 2 0.999999C2.55228 0.999999 3 1.44771 3 2ZM10 2C10 2.55228 9.55228 3 9 3C8.44772 3 8 2.55228 8 2C8 1.44771 8.44772 1 9 1C9.55228 1 10 1.44772 10 2ZM17 2C17 2.55229 16.5523 3 16 3C15.4477 3 15 2.55228 15 2C15 1.44772 15.4477 1 16 1C16.5523 1 17 1.44772 17 2Z"
      stroke="#1a1a1a"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

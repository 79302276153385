/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { EmployeeList } from 'models/employees/types';
import FormikContainer from 'components/form/containers';
import { EmployeesController } from 'controllers/employees';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface IModalChangeEmployeeStatusViewProps {
  hideModal: () => void;
  currentEmployee: EmployeeList;
  employees: EmployeesController;
}

const ModalChangeEmployeeStatusView = (props: IModalChangeEmployeeStatusViewProps) => {
  const { hideModal, currentEmployee, employees } = props;
  const onSubmit = () => {
    employees.deleteEmployeesAction({ id: currentEmployee.id }, 0, true, `Employee ${ !currentEmployee.deletedAt ? 'deactivated' : 'activated' }`);
    hideModal();
  };

  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Warning</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              Do you really want to {currentEmployee.deletedAt ? 'activate' : 'deactivate'} this employee?
            </p>
          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalChangeEmployeeStatus = observer(ModalChangeEmployeeStatusView);

import React from 'react';
import { SortArrowSVG } from 'assets/icons/sortArrow';
import { IMoreFunctionsItems, IMoreFunctionsMobile } from './types';

export const moreFunctionsListTitles: any = [
  {
    name: 'Title',
    data: 'name',
    icon: <SortArrowSVG/>,
    id: 0,
    asc: 3,
    desc: 2
  }
];

export const moreFunctionsSortMobile: IMoreFunctionsMobile[] = [
  { value: 2, label: 'Function title by descending', singleValue: 'Function title by descending' },
  { value: 3, label: 'Function title by ascending', singleValue: 'Function title by ascending' },
];

export const moreFunctionsItems: IMoreFunctionsItems[] = [
  {
    id: 0,
    path: 'import',
    title: 'Import payment file',
    description: 'At this window can upload import file, which should be in .csv format, and have the necessary employees to pay.',
    submitTitleBtn: 'Import',
    cancelTitleBtn: null,
    identificator: 'IMPORT'
  }
];

export enum MoreFunctionsItemsIdentification {
  IMPORT = 'IMPORT'
}

import React from 'react';

export const EmployeeActiveSVG = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.469 2.02278C12.8327 3.38649 12.8327 5.5975 11.469 6.96121C10.1053 8.32492 7.8943 8.32492 6.53059 6.96121C5.16688 5.5975 5.16688 3.38649 6.53059 2.02278C7.8943 0.659072 10.1053 0.659072 11.469 2.02278"
      stroke="#4EDB75" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M13.363 11.796C12.064 11.277 10.54 10.991 9 10.991C4.952 10.991 1 12.958 1 15.983V16.983C1 17.535 1.448 17.983 2 17.983H11.413"
      stroke="#4EDB75" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M16 21C13.239 21 11 18.762 11 16C11 13.296 13.3 10.997 16.004 11C18.764 11.002 21 13.24 21 16C21 18.761 18.762 21 16 21"
      stroke="#4EDB75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.2218 14.8889L15.4438 17.6669L13.7778 15.9999" stroke="#4EDB75" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>


);

/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { motion } from 'framer-motion';
import MaskedInput from 'react-text-mask';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AuthContainer } from 'containers/auth';
import { LogoDarkSVG } from 'assets/icons/logo';
import { BackLongSVG } from 'assets/icons/backLong';
import FormikRadio from 'components/form/fields/radio';
import { useLocation, useNavigate } from 'react-router';
import FormikContainer from 'components/form/containers';
import { forgotPasswordTab } from 'common/constants/tabs';
import { UniversalTab } from 'components/tabs/universalTab';
import FormikTextField from 'components/form/fields/inputs';
import { menuTabsForgotPassword } from 'common/constants/auth';
import { IActiveContentState } from 'components/tabs/universalTab/types';
import FormikSelectCountries from 'components/form/fields/selectCountries';
import { useStores } from 'store';
import { CreateResetPassword } from 'models/auth/types';
import { Loading } from 'store/types';
import s from './index.module.scss';

export interface LocationState {
  tab: number;
}

const ForgotPasswordView = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { auth } = useStores();
  const state = location.state as LocationState;
  const tab = state?.tab ? menuTabsForgotPassword(t)[state.tab] : menuTabsForgotPassword(t)[0];
  const [activeContent, setActiveContent] = useState<IActiveContentState>(tab);
  const navigate = useNavigate();
  const isCreateResetPasswordLoading = auth.getResetPasswordAsyncState.getLoadingState === Loading.success;
  const isAuthMeLoadingSuccess = auth.getAuthMeAsyncState.getLoadingState;
  const validationEmailSchema = Yup.object().shape({
    email: Yup.string().required('This is a required field').email('The field must match email')
  });

  const validationPhoneSchema = Yup.object().shape({
    phone: Yup.string()
      .transform((value: string) => value.replace(/[^\d]/g, ''))
      .min(10, 'Phone number should be valid')
      .required('This is a required field')
  });

  const handleClick = async (elements: any, resetForm: any) => {
    await setActiveContent(elements);
    resetForm();
  };
  const onSubmit = (values: any) => {
    const requestData: CreateResetPassword = values.phone ? { phone: `+256${values.phone.replace(/[^\d]/g, '')}` } : { email: values.email };

    auth.createResetPasswordAction(requestData);

  };

  useEffect(() => {
    if (isCreateResetPasswordLoading) {
      navigate('/password-change', { state: { tab: 0 } });
    }

    return () => {
      auth.getResetPasswordAsyncState.reset();
    };
  }, [isCreateResetPasswordLoading]);

  const checkPhoneInputField = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.replace(/[^\d]/g, '')[0] !== '0') {
      e.target.value = `(0)${e.target.value}`;
    }

    if (e.target.value.replace(/[^\d]/g, '')[0] === '0' && e.target.value[3] === '_') {
      e.target.value = '';
    }
  };

  useEffect(() => {
    localStorage.removeItem('authMe');

    return () => localStorage.removeItem('authMe');
  }, [isAuthMeLoadingSuccess]);

  return (
    <AuthContainer>
      <div className={s.wrapper}>
        <motion.div
          variants={{
            initial: { opacity: 0, scale: 0.8 },
            animate: { opacity: 1, scale: 1 },
            exit: { opacity: 0, scale: 0.8 }
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5, type: 'spring', damping: 17 }}
          className={s.wrapper__inner}
        >
          <FormikContainer
            initialValues={activeContent.index === 0 ? { email: '' } : { phone: '' }}
            onSubmit={onSubmit}
            validationSchema={
              activeContent.index === 0 ? validationEmailSchema : validationPhoneSchema
            }
          >
            {({ resetForm, errors, isValid, dirty }) => (
              <div className={s.wrapper__inner__container}>
                <div className={s.wrapper__logo}>
                  <LogoDarkSVG />
                </div>
                <div className={s.wrapper__inner__title}>
                  <h1>{t('AUTH.FORGOTPASSWORD.TITLE')}</h1>
                  <UniversalTab index={activeContent.index} content={forgotPasswordTab(t)} />
                </div>
                <div
                  className={`${s.wrapper__inner__buttons} ${
                    activeContent.index === 0 ? s.first : s.second
                  }`}
                >
                  {menuTabsForgotPassword(t).map((menuTab: any, index: number) => (
                    <div key={menuTab.index} className={s.wrapper__inner__button}>
                      <FormikRadio
                        onClick={() =>
                          handleClick(
                            { index, titles: menuTab.title, icon: menuTab.icon },
                            resetForm
                          )
                        }
                        name={'tab'}
                        id={`tab${index}`}
                        value={`tab${index}`}
                        type="radio"
                        label={<p className={s.input__label}>{menuTab.title}</p>}
                      />
                    </div>
                  ))}
                </div>
                <div className={s.form__inputs}>
                  {activeContent.index === 0 ? (
                    <>
                      <motion.div
                        initial="closed"
                        animate="open"
                        exit="exit"
                        variants={{
                          closed: { opacity: 0 },
                          open: { opacity: 1 },
                          exit: { opacity: 0 }
                        }}
                        transition={{
                          duration: 0.1,
                          type: 'spring',
                          damping: 17,
                          stiffness: 150
                        }}
                        className={`${s.input} ${errors.email ? s.border_alert : null}`}
                      >
                        <FormikTextField placeholder={t('AUTH.FORGOTPASSWORD.FORM.EMAILPLACEHOLDER')} name="email" />
                      </motion.div>
                    </>
                  ) : (
                    <div
                      className={`${s.input__countries} ${
                        errors.phone ? s.border_alert : null
                      }`}
                    >
                      <motion.div
                        initial="closed"
                        animate="open"
                        exit="exit"
                        variants={{
                          closed: { opacity: 0 },
                          open: { opacity: 1 },
                          exit: { opacity: 0 }
                        }}
                        transition={{
                          duration: 0.1,
                          type: 'spring',
                          damping: 17,
                          stiffness: 150
                        }}
                      >
                        <FormikSelectCountries
                          name="phone"
                          label={<p className={s.input__label}>{t('AUTH.FORGOTPASSWORD.FORM.PHONE')}</p>}
                        >
                          <div className={` ${errors.phone ? s.border_alert : null}`}>
                            <Field name="phone">
                              {({ field }: any) => (
                                <MaskedInput
                                  {...field}
                                  onInput={checkPhoneInputField}
                                  mask={[
                                    '(',
                                    /\d/,
                                    ')',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/
                                  ]}
                                  placeholder={t('AUTH.FORGOTPASSWORD.FORM.PHONEPLACEHOLDER')}
                                  guide
                                  name="phone"
                                  id="phone"
                                />
                              )}
                            </Field>
                          </div>
                        </FormikSelectCountries>
                        {errors.phone || errors.tuched ? (
                          <div className={s.input__error}>
                            <p>{errors.phone}</p>
                          </div>
                        ) : null}
                      </motion.div>
                    </div>
                  )}

                  <div className={s.buttons}>
                    <motion.button
                       whileTap={
                        (isValid && dirty)
                          ? { scale: 0.99 }
                          : {}
                      }
                      whileHover={
                        (isValid && dirty)
                          ? { scale: 1.02 }
                          : {}
                      }
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      className={s.submit__button}
                      disabled={((!(isValid && dirty)))}
                      type="submit"
                    >
                      <p>{t('AUTH.FORGOTPASSWORD.FORM.SUBMIT')}</p>
                    </motion.button>

                    <motion.button
                      whileTap={{ scale: 0.99 }}
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      onClick={() => navigate('/signin')}
                      className={s.back__button}
                      type="button"
                    >
                      <BackLongSVG />
                      <p>{t('AUTH.FORGOTPASSWORD.FORM.BACKBUTTON')}</p>
                    </motion.button>
                  </div>
                </div>
              </div>
            )}
          </FormikContainer>
        </motion.div>
        <div className={s.circle} />
      </div>
    </AuthContainer>
  );
};

export const ForgotPassword = observer(ForgotPasswordView);
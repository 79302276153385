/* eslint-disable class-methods-use-this */
import SERVICE_API from 'api/payway';
import { remapFieldsError } from 'common/replaceErrorMessage';
import { makeAutoObservable } from 'mobx';
import { Balance, BalanceModel, GetBalance } from 'models/balance/types';
import { IAsyncUtil, IConstructorAsyncUtils } from 'store/types';

interface BalanceRepository {
  new (): BalanceModel;
}

export class BalanceController {
  private balanceModel: BalanceModel;

  private getBalanceAsyncHelper: IAsyncUtil;

  get getBalanceModel(): BalanceModel {
    return this.balanceModel;
  }

  get getBalanceAsyncState(): IAsyncUtil {
    return this.getBalanceAsyncHelper;
  }

  getBalanceAction = async (balance: Balance): Promise<void> => {
    this.getBalanceAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.BalanceAPI.getBalance(balance);
      this.getBalanceSuccess(data);
      this.getBalanceAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getBalanceAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  private ormErrors = (error: any) => {
    const errorMessage = error?.response?.data?.error || error?.message;
    const fieldErrors = error?.response?.data?.message;
    return { errorMessage, fieldErrors };
  };

  private getBalanceSuccess = (data: GetBalance): void => {
    this.balanceModel.setBalance(data);
  };

  constructor(BalanceEntity: BalanceRepository, AsyncHelper: IConstructorAsyncUtils) {
    this.balanceModel = new BalanceEntity();
    this.getBalanceAsyncHelper = new AsyncHelper();

    makeAutoObservable(this);
  }
}

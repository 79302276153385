import { ColorsAccent } from 'common/colors';
import React from 'react';

export const BackSVG = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25 12.25L1 7L6.25 1.75"
      stroke={`${ColorsAccent.accentDark}`}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

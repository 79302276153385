import React from 'react';

export const RepeatFailurePaymentSVG = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 12V7.5" stroke="#4F4F4F"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 12V7.5" stroke="black" strokeOpacity="0.2"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.999 15.5C11.861 15.5 11.749 15.612 11.75 15.75C11.75 15.888 11.862 16 12 16C12.138 16 12.25 15.888 12.25 15.75C12.25 15.612 12.138 15.5 11.999 15.5" stroke="#4F4F4F"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.999 15.5C11.861 15.5 11.749 15.612 11.75 15.75C11.75 15.888 11.862 16 12 16C12.138 16 12.25 15.888 12.25 15.75C12.25 15.612 12.138 15.5 11.999 15.5" stroke="black" strokeOpacity="0.2"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 14.381V9.61899C21 8.01699 20.148 6.53599 18.763 5.72999L14.263 3.11099C12.864 2.29699 11.135 2.29699 9.736 3.11099L5.236 5.72999C3.852 6.53599 3 8.01699 3 9.61899V14.381C3 15.983 3.852 17.464 5.237 18.27L9.737 20.889" stroke="#4F4F4F"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 14.381V9.61899C21 8.01699 20.148 6.53599 18.763 5.72999L14.263 3.11099C12.864 2.29699 11.135 2.29699 9.736 3.11099L5.236 5.72999C3.852 6.53599 3 8.01699 3 9.61899V14.381C3 15.983 3.852 17.464 5.237 18.27L9.737 20.889" stroke="black" strokeOpacity="0.2"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.9775 20.5L21.4775 19L19.9775 20.5" stroke="#4F4F4F"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.9775 20.5L21.4775 19L19.9775 20.5" stroke="black" strokeOpacity="0.2"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.4778 19V22.401C21.4778 23.281 20.7578 24 19.8788 24H17.4888" stroke="#4F4F4F"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.4778 19V22.401C21.4778 23.281 20.7578 24 19.8788 24H17.4888" stroke="black" strokeOpacity="0.2"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13 20.5L14.5 22L16 20.5" stroke="#4F4F4F"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13 20.5L14.5 22L16 20.5" stroke="black" strokeOpacity="0.2"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5 22V18.599C14.5 17.719 15.22 17 16.099 17H18.489" stroke="#4F4F4F"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5 22V18.599C14.5 17.719 15.22 17 16.099 17H18.489" stroke="black" strokeOpacity="0.2"  strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

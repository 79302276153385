/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { format } from 'date-fns';
import { Loading } from 'store/types';
import { Loader } from 'common/loader';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { DropSVG } from 'assets/icons/drop';
import { ColorsStatus } from 'common/colors';
import { LoupeSVG } from 'assets/icons/loupe';
import { useTranslation } from 'react-i18next';
import { FunellSVG } from 'assets/icons/funnel';
import { motion, Variants } from 'framer-motion';
import { Pagination } from 'components/pagination';
import { Providers } from 'models/providers/types';
import { useDebounce } from 'hooks/useDebounce.hook';
import { EditLightSVG } from 'assets/icons/editLight';
import { DropSelectSVG } from 'assets/icons/dropselect';
import { useLocation, useNavigate } from 'react-router';
import FormikContainer from 'components/form/containers';
import { AnimatedDivPage } from 'components/animatedPage';
import { RepeatPaymentSVG } from 'assets/icons/repeatPayment';
import { AdditionLightSVG } from 'assets/icons/additionLight';
import FormikSelect from 'components/form/fields/select/index';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import FormikTextField from 'components/form/fields/inputs/index';
import { GroupsSVG } from 'assets/icons/groups';
import { EmployeeList } from 'models/employees/types';
import { PaymentsTransferStatus } from 'common/constants/payment';
import { PaymentsHistoryTabs } from 'components/paymentHistory/tabs';
import { RepeatFailurePaymentSVG } from 'assets/icons/repeatFailurePayment';
import { selectFilterByStatusHistoryList } from 'common/constants/historyDetail';
import { TransactionsCount } from 'components/paymentHistory/transactionsCount';
import { MoreFunctionsList } from 'components/moreFunctionsList/MoreFunctionsList';
import { historyListTitles, historySortMobile, } from 'common/constants/paymentHistory';
import s from './index.module.scss';

export const PaymentHistoryView = () => {
  const { payments, auth, employees, providers } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isTabletAdditional, isTabletLongExclude } = useWindowDimensions();
  const [elementsCount, setElementsCount] = useState(8);
  const { getPaymentsModel, getPaymentsAsyncState, getPaymentsDetailsAsyncState, getPaymentsTransferStatusAsyncState } = payments;
  const { getAuthModel } = auth;
  const { getProvidersModel } = providers;
  const { getPaymentsHistory, getPaymentsHistoryDetails } = getPaymentsModel;
  const { getAuthMe } = getAuthModel;
  const { getEmployeesModel, getEmployeesAsyncState } = employees;
  const { getEmployeesList } = getEmployeesModel;
  const isPaymentsLoading = getPaymentsAsyncState?.getLoadingState;
  const isPaymentsTransferStatusLoading = getPaymentsTransferStatusAsyncState?.getLoadingState;
  const isPaymentsHistoryDetails = getPaymentsDetailsAsyncState.getLoadingState;
  const isEmployeesListLoading = getEmployeesAsyncState.getLoadingState;
  const [isOpenHistoryMenu, setIsOpenHistoryMenu] = useState(false);
  const [isCurrentHistoryId, setIsCurrentHistoryId] = useState<any[]>([]);
  const [repeatPaymentActivate, setRepeatPaymentActivate] = useState(false);
  const [repeatFailurePaymentActivate, setRepeatFailurePaymentActivate] = useState(false);
  const removeDuplicates = (data: any, key: any) => [
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];
  const [isStatus, setIsStatus] = useState<any>('');
  const [currentHistoryItems, setCurrentHistoryItems] = useState<any>([]);
  const [paymentsSearch, setPaymentsSearch] = useState('');
  const [paymentsMobileSearch, setPaymentsMobileSearch] = useState('');
  const paymentsSearchDebounce = useDebounce(paymentsSearch || paymentsMobileSearch, 300);

  const [isAdditionMenuOpen, setIsAdditionMenuOpen] = useState<any>(false);
  const [isCurrentAdditionMenuId, setIsCurrentAdditionMenuId] = useState<any>([]);
  const additionBtnEl = useRef(null);

  const { search } = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(search);
  const [dynamicPage, setDynamicPage] = useState<number | string>(params.get('page') || getPaymentsModel.getPaymentsHistoryMeta.page || 1);
  const [dynamicSort, setDynamicSort] = useState<number | string>(params.get('sortId') || (!isTabletAdditional ? getPaymentsModel.getPaymentsHistoryMeta.sort : 0));
  const [dynamicFilter, setDynamicFilter] = useState<number | string>(params.get('filter') || (!isTabletAdditional ? getPaymentsModel.getPaymentsHistoryMeta.status : 3));
  const [dynamicSchedule, setDynamicSchedule] = useState<boolean | string>(params.get('wait') || getPaymentsModel.getPaymentsHistoryMeta.wait);
  const [isHistoryItemsSorted, setIsHistoryItemsSorted] = useState<any>(params.get('sortId') || (!isTabletAdditional ? getPaymentsModel.getPaymentsHistoryMeta.sort : 0));
  const [isRepeatSchedulePayment, setIsRepeatSchedulePayment] = useState<boolean>(false);
  const onSubmit = () => {
  };

  const repeatPayment = (client: any, failurePayment?: boolean) => {
    payments.getPaymentsHistoryDetailsAction({ id: client?.id });
    setRepeatPaymentActivate(true);

    if (client?.isRepeatSchedulePayment) {
      setIsRepeatSchedulePayment(true);
    }

    if (failurePayment) {
      setRepeatFailurePaymentActivate(true);
    }
  };

  const animations: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };
  const handleSortItems = (e: any) => {
    // TODO: Подумать над сбросом сортировки
    const currentSortId = e?.value?.toString() || ( isHistoryItemsSorted?.toString() === e?.asc?.toString() ? e?.desc?.toString() : e?.asc?.toString() );

    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('sortId', currentSortId);
    const newUrl = `${location.pathname}?${currentParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    setIsHistoryItemsSorted(currentSortId);
    setDynamicSort(currentSortId);
    payments.getPaymentsHistoryAction({
      id: getAuthMe.id,
      search: paymentsSearch || paymentsMobileSearch,
      page: dynamicPage,
      active: dynamicFilter,
      sortId: currentSortId,
      wait: dynamicSchedule
    });
  };

  const handleOpenHistoryMenu = (e: ChangeEvent<HTMLDivElement>, client: any) => {
    setIsOpenHistoryMenu(!isOpenHistoryMenu);
    setIsCurrentHistoryId([...isCurrentHistoryId, client.id]);

    if (isCurrentHistoryId.includes(client.id)) {
      setIsCurrentHistoryId(
        isCurrentHistoryId.filter((historyId: any) => historyId !== client.id)
      );
    }
  };
  const handleAdditionMenuOpen = (e: ChangeEvent<HTMLDivElement>, client: any) => {
    setIsAdditionMenuOpen(!isAdditionMenuOpen);
    setIsCurrentAdditionMenuId(client.id);

    if (isCurrentAdditionMenuId !== client.id) {
      setIsAdditionMenuOpen(true);
      setIsCurrentAdditionMenuId(
        client.id
      );
    }
  };
  const callHistoryDetailPage = (client: any) => {
    navigate(`history-detail/${client.id}`, { state: { history: toJS(client), page: dynamicPage, sortId: dynamicSort, wait: dynamicSchedule, active: dynamicFilter, previousPageWithQuery: `${location.pathname}?${new URLSearchParams(window.location.search).toString()}` } });
  };

  const handleSelectFilter = (e: any) => {
    // setIsStatus(e.value);

    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('filter', e?.value);
    const newUrl = `${location.pathname}?${currentParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    setDynamicFilter(e?.value);
    payments.getPaymentsHistoryAction({
      id: getAuthMe.id,
      search: paymentsSearch || paymentsMobileSearch,
      page: dynamicPage,
      active: e?.value,
      sortId: dynamicSort,
      wait: dynamicSchedule,
    });
  };

  const onHandleSearch = (setter: (val: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  useEffect(() => {
    let statusInterval: any = '';
    if ((getPaymentsModel.getPaymentsHistoryTransferStatus.map(transfer => transfer.status).includes(PaymentsTransferStatus.inProgress) && sessionStorage.getItem('temporarySessionData')) && !getPaymentsModel.getPaymentsHistoryTransferStatus.map(transfer => transfer.status).includes(PaymentsTransferStatus.waiting)) {
      statusInterval = setInterval(async () => {
        payments.getPaymentsHistoryAction({
          id: getAuthMe?.id,
          page: toJS(getPaymentsModel.getPaymentsHistoryMeta)?.page,
          search: paymentsSearch || paymentsMobileSearch,
          // status: isStatusFilter || ''
          sortId: dynamicSort,
          active: dynamicFilter,
          wait: dynamicSchedule,
        });
      }, 1000);
    }

    return () => clearInterval(statusInterval);
  }, [isPaymentsTransferStatusLoading]);

  useEffect(() => {
    if (getPaymentsModel.getPaymentsHistoryTransferStatus.filter(transfer => transfer.status === PaymentsTransferStatus.completed)) {
      payments.getPaymentsHistoryAction({
        id: getAuthMe?.id,
        page: toJS(getPaymentsModel.getPaymentsHistoryMeta)?.page,
        search: paymentsSearch || paymentsMobileSearch,
        // status: isStatusFilter || ''
        sortId: dynamicSort,
        active: dynamicFilter,
        wait: dynamicSchedule,
      });

    }
  }, [isPaymentsTransferStatusLoading]);

  useEffect(() => {

    window.onscroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 5 >=
        document.scrollingElement!.scrollHeight &&
        !isTabletLongExclude
      ) {
        payments.getPaymentsHistoryAction({
          id: getAuthMe?.id,
          search: paymentsSearch || paymentsMobileSearch,
          active: dynamicFilter,
          sortId: dynamicSort,
          wait: dynamicSchedule,
          quantity: elementsCount + 5,
        });
        setElementsCount(elementsCount + 5);
      }
    };

  }, [elementsCount, dynamicFilter, dynamicSort, dynamicSchedule]);

  useEffect(() => {
    payments.getPaymentsHistoryAction(
      paymentsSearch || paymentsMobileSearch ?
        {
          id: getAuthMe?.id,
          search: paymentsSearch || paymentsMobileSearch,
          sortId: dynamicSort,
          active: dynamicFilter,
          wait: dynamicSchedule,
        }
        :
        {
          id: getAuthMe?.id,
          page: dynamicPage,
          sortId: dynamicSort,
          active: dynamicFilter,
          search: paymentsSearch || paymentsMobileSearch,
          wait: dynamicSchedule,
        }
    );
  }, [paymentsSearchDebounce, dynamicPage]);

  useEffect(() => {
    if (!paymentsSearch) {
      setCurrentHistoryItems(toJS(getPaymentsHistory));
    }
  }, [paymentsSearch]);

  useEffect(() => {
    employees.getEmployeesAction({ id: getAuthMe?.id,
      // quantity: 1000
    });
  }, []);

  useEffect(() => {

    if (isPaymentsHistoryDetails === Loading.success && repeatPaymentActivate) {
      const defineDeactivatedUsers = toJS(getPaymentsHistoryDetails?.payments)?.filter((providerItem: any) => !providerItem?.account?.employee?.deletedAt);
      const defineDeletedOperators = removeDuplicates(toJS(getPaymentsHistoryDetails?.payments)?.reduce((acc: any, historyItem: any): any => {
        if (historyItem?.account?.employee) {
          historyItem.account.provider.employeeId = historyItem?.account?.employee?.id;
          historyItem.account.employee.employeeId = historyItem?.account?.employee?.id;
          historyItem.account.employee.providerId = historyItem?.account?.provider?.id;
          historyItem.account.employee.amount = historyItem?.account?.defaultPrice || historyItem?.amount;
          historyItem.account.employee.description =
            historyItem?.priceList?.description ||
            `${historyItem?.account?.provider?.title} services`;
          acc.push({
            ...historyItem?.account?.employee,
          });
        }
        return acc;
      }, [])
        .reduce((acc: any, history: any): any => {
          acc.push({
            ...history,
            providers: removeDuplicates([
              ...toJS(getPaymentsHistoryDetails?.payments)?.filter(
                (insideHistoryItem: any): any => {
                  if (history?.id === insideHistoryItem?.account?.employee?.id) {
                    return {
                      ...insideHistoryItem.account?.provider,
                      priceList: insideHistoryItem?.priceList,
                    };
                  }
                }
              )
            ], (key: any) => key.id)
          });
          return acc;
        }, [])?.reduce((repalcedAcc: any[], replaced: any) => {
          toJS(getEmployeesList).map((employee: EmployeeList) => {
            if (employee.id === replaced.employeeId) {
              repalcedAcc.push({
                ...replaced,
                providers: replaced.providers.reduce((providerAcc: any[], provider: any) => {

                  if (employee.accounts.map((account) => account.provider.id).includes(provider.account.provider.id)) {
                    providerAcc.push({
                      ...provider,
                      isOperatorDeleted: false,
                    });
                  } else {
                    providerAcc.push({
                      ...provider,
                      isOperatorDeleted: true,
                    });
                  }

                  return providerAcc;
                }, [])
              });
            }

            if (replaced.deletedAt) {
              repalcedAcc.push({
                ...replaced
              });
            }

            return repalcedAcc;
          });
          return repalcedAcc;
        }, []).map((provider: any) => provider.providers).flat(Infinity).filter((newProvider: any) => newProvider?.isOperatorDeleted), (key: any) => key.id);

      const formatResultData = defineDeactivatedUsers
        ?.reduce((acc: any, historyItem: any): any => {
          if (historyItem?.account) {
            historyItem.account.provider.employeeId = historyItem?.account?.employee?.id;
            historyItem.account.employee.providerId = historyItem?.account?.provider?.id;
            historyItem.account.employee.amount = historyItem?.account?.defaultPrice || historyItem?.amount;
            historyItem.account.employee.description =
              historyItem?.priceList?.description ||
              `${historyItem?.account?.provider?.title} services`;
            acc.push({
              ...historyItem?.account?.employee,
            });
          }
          return acc;
        }, [])
        .reduce((acc: any, history: any): any => {
          acc.push({
            ...history,
            providers: [
              ...toJS(getPaymentsHistoryDetails?.payments)?.filter(
                (insideHistoryItem: any): any => {

                  // Hardcode
                  toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                    if (provider.id === insideHistoryItem?.account?.provider?.id) {
                      insideHistoryItem.account.provider = provider;
                    }
                  });

                  if (history?.id === insideHistoryItem?.account?.employee?.id) {
                    return {
                      ...insideHistoryItem.account?.provider,
                      priceList: insideHistoryItem?.priceList
                    };
                  }
                }
              )
            ]
          });
          return acc;
        }, [])?.reduce((accEmployee: any, employee: any) => {
          accEmployee.push({
            ...employee,
            employeeId: employee?.id,
            providers: employee?.providers?.reduce((accProviders: any, provider: any) => {

              // Замена accountId для платежа на актуальный, когда мы используем PUT на employee и меняем данные accoubt'a (operator'a)
              toJS(getEmployeesList)?.filter((employeeItem: any) => employeeItem?.id === employee?.id)?.map((account: any) => account?.accounts).flat(1)?.map((accountItem: any) => {
                if (accountItem?.provider?.id === provider?.account?.provider?.id) {
                  provider.account.id = accountItem?.id;
                }
              });

              accProviders.push({
                ...provider,
                ...provider?.account.provider,
                amount: provider.amount || provider.account.defaultPrice,
                description:
                  provider?.priceList?.description || `${provider?.account?.provider?.title} services`,
                employeeId: provider?.account?.employee?.id,
                providerId: provider?.account?.provider.id
              });

              return accProviders;
            }, [])
          });

          return accEmployee;
        }, [])?.reduce((repalcedAcc: any[], replaced: any) => {
          toJS(getEmployeesList).map((employee: EmployeeList) => {
            if (employee.id === replaced.employeeId) {
              repalcedAcc.push({
                ...replaced,
                providers: replaced.providers.reduce((providerAcc: any[], provider: any) => {

                  if (employee.accounts.map((account) => account.provider.id).includes(provider.account.provider.id)) {
                    providerAcc.push({
                      ...provider,
                    });
                  }

                  return providerAcc;
                }, [])
              });
            }

            if (replaced.deletedAt) {
              repalcedAcc.push({
                ...replaced
              });
            }

            return repalcedAcc;
          });
          return repalcedAcc;
        }, []).filter((resultItem: any) => resultItem?.providers.length);

      const formatResultDataVariants = repeatFailurePaymentActivate ?
        formatResultData.filter((item: any) => item.providers.map((provider: any) => provider.status).some((provider: any) => provider === 'Failure')).reduce((paymentAcc: any[], payment: any) => {

          paymentAcc.push({
            ...payment,
            providers: payment.providers.filter((provider: any) => provider.status === 'Failure')
          });

          return paymentAcc;
        }, [])
        :
        formatResultData;

      const resultProviders: any = removeDuplicates(
        toJS(formatResultDataVariants)
          .map((provider: any): any => provider?.providers)
          .flat(Infinity),
        (key: any) => key.id
      );

      const formatResultProviders = resultProviders?.map((item: any) => ({ ...item, operators: [] }));

      const formatResultDataReverse = formatResultProviders
        ?.map((provider: any) =>
          removeDuplicates(
            formatResultDataVariants?.map((operator: any): any => {
              if (provider?.id === operator?.providerId) {
                provider.operators = removeDuplicates(
                  [...provider?.operators, operator],
                  (key: any) => key?.employeeId
                );
              }
              return { ...provider };
            }),
            (key: any) => key.id
          )
        )
        .flat(Infinity);

      const formatPaymentData = {
        clientId: getAuthMe?.id,
        transfers: removeDuplicates(formatResultDataVariants, (key: any) => key.id)?.reduce(
          (acc: any, employee: any) => {
            employee?.providers?.map((provider: any) =>  {
              acc.push({
                accountId: provider.account.id,
                amount: provider.amount,
                priceListId: provider?.priceList?.id || null,
                scenarioId: provider?.scenario?.id || null
              });
            }
            );
            return acc;
          },
          []
        )
      };

      if (formatResultDataVariants.length) {
        navigate('/payment/payment-confirmation', {
          state: {
            totalAmount: getPaymentsHistoryDetails?.amount,
            formatResultData: removeDuplicates(formatResultDataVariants, (key: any) => key.id),
            formatResultDataReverse,
            formatPaymentData,
            goBack: '/history',
            isRepeatSchedulePayment: isRepeatSchedulePayment || false,
          }
        });
        getPaymentsDetailsAsyncState.reset();
      }

      if (!formatResultDataVariants.length) {
        toast.warning('Unable to make payment for deactivated employees and operators');
        getPaymentsDetailsAsyncState.reset();
      } else if (defineDeletedOperators.length && toJS(getPaymentsHistoryDetails?.payments)?.filter((providerItem: any) => providerItem?.account?.employee?.deletedAt).length) {
        toast.warning('Some employees and operators were (deactivated/deleted), unable to make payment for them');
        getPaymentsDetailsAsyncState.reset();
      } else if (toJS(getPaymentsHistoryDetails?.payments)?.filter((providerItem: any) => providerItem?.account?.employee?.deletedAt).length) {
        toJS(getPaymentsHistoryDetails?.payments)?.filter((providerItem: any) => providerItem?.account?.employee?.deletedAt);
        toast.warning('Some employees were deactivated, unable to make payment for them');
        getPaymentsDetailsAsyncState.reset();
      } else if (defineDeletedOperators.length) {
        toast.warning('Some operators were deleted, unable to make payment for them');
      }
    }
  }, [isPaymentsHistoryDetails, repeatPaymentActivate, isEmployeesListLoading]);

  useEffect(() => {
    providers.getProvidersAction();
  }, []);

  return (
    <section className={s.wrapper}>
      <AnimatedDivPage transition={{ duration: 0.5 }} animations={animations}>
        <div className={s.wrapper__container}>
          <FormikContainer onSubmit={onSubmit} initialValues={{
            sort: historySortMobile(t).filter(item => +dynamicSort === item.value).length ? historySortMobile(t).filter(item => +dynamicSort === item.value)[0] : historySortMobile(t)[0],
            filter: selectFilterByStatusHistoryList(t).filter(item => +dynamicFilter === item.value).length ? selectFilterByStatusHistoryList(t).filter(item => +dynamicFilter === item.value)[0] : selectFilterByStatusHistoryList(t)[0]
          }}>
            {() => (
              <>

                <div className={s.wrapper__title}>
                  <h1 className={s.title}>{t<string>('MAIN.PAYMENTHISTORY.TITLE')}</h1>

                  <div className={s.search__mobile}>
                    <FormikTextField
                      placeholder={t<string>('MAIN.PAYMENTHISTORY.SEARCH.PLACEHOLDER')}
                      name="search"
                      value={paymentsSearch}
                      id={'paymentsSearch'}
                      onChange={onHandleSearch(setPaymentsSearch)}
                    >
                      <div className={s.search__icon}>
                        <LoupeSVG />
                      </div>
                    </FormikTextField>
                  </div>

                  <div className={s.services__sort}>
                    {
                      getPaymentsModel.getPaymentsHistoryMeta.wait.toString() === 'false' ?
                        <FormikSelect
                          valueContainer={{ paddingLeft: '.5rem' }}
                          wrapperSelectStyles={{ width: '11rem' }}
                          options={selectFilterByStatusHistoryList(t)}
                          name="filter"
                          placeholder={t<string>('MAIN.PAYMENTHISTORY.FILTER.PLACEHOLDER')}
                          indicatorIcon={<DropSelectSVG />}
                          onChange={handleSelectFilter}
                        />
                        :
                        null
                    }

                  </div>

                  <div className={s.services__filter_mobile}>
                    <h2 className={s.filter__title}>{t<string>('MAIN.PAYMENTHISTORY.FILTER.MOBILETITLE')}</h2>
                    <FormikSelect
                      valueContainer={{ height: '1.7rem', padding: '0', paddingRight: '1.625rem' }}
                      selectWrapperStyles={{ width: 'min-content' }}
                      containerStyles={{ border: 'none' }}
                      controlStyles={{ backgroundColor: 'transparent' }}
                      wrapperSelectStyles={{ width: '2.7rem' }}
                      menuListStyles={{
                        width: '10rem',
                        right: '0',
                        transform: 'translate(-75%, 0%)'
                      }}
                      childrenStyles={{ left: '0', top: '0', transform: 'none' }}
                      indicatorContainerStyles={{ display: 'none' }}
                      singleValueStyles={{ opacity: 0 }}
                      valueStyles={{ opacity: 0 }}
                      options={selectFilterByStatusHistoryList(t)}
                      name="filter"
                      placeholder=""
                      indicatorIcon={<DropSelectSVG />}
                      onChange={handleSelectFilter}
                    >
                      <div className={s.filter__wrapper}>
                        <button type="button" name="filter" className={s.filter__icon}>
                          <FunellSVG />
                        </button>
                      </div>
                    </FormikSelect>
                  </div>
                  <div className={s.services__sort_mobile}>
                    <div className={s.operators__sort}>
                      <FormikSelect
                        valueContainer={{ paddingLeft: '.5rem' }}
                        wrapperSelectStyles={{ width: '11rem', maxWidth: '100%', minWidth: '100%' }}
                        selectWrapperStyles={{ width: '100%' }}
                        options={historySortMobile(t)}
                        name="sort"
                        placeholder={t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.PLACEHOLDER')}
                        indicatorIcon={<DropSelectSVG />}
                        onChange={handleSortItems}
                      />
                    </div>
                  </div>
                </div>

                <div className={s.history__list}>
                  <div className={s.search}>
                    <FormikTextField
                      placeholder={t<string>('MAIN.PAYMENTHISTORY.SEARCH.PLACEHOLDER')}
                      name="search"
                      value={paymentsMobileSearch}
                      id={'paymentsMobileSearch'}
                      onChange={onHandleSearch(setPaymentsMobileSearch)}
                    >
                      <div className={s.search__icon}>
                        <LoupeSVG />
                      </div>
                    </FormikTextField>
                  </div>

                  {/* TODO: Сделать плавную смену списков по переключению таба, вернуть что бы не было страниц ошибок на проде */}
                  <PaymentsHistoryTabs payments={payments} paymentsSearch={paymentsSearch} paymentsMobileSearch={paymentsMobileSearch} dynamicPage={dynamicPage} dynamicSort={dynamicSort} dynamicFilter={dynamicFilter} getAuthMe={getAuthMe} waitMeta={getPaymentsModel.getPaymentsHistoryMeta.wait} location={location} setDynamicSchedule={setDynamicSchedule} />

                  <div className={s.content}>
                    {
                      (currentHistoryItems?.length ? currentHistoryItems : toJS(getPaymentsHistory)).length >= 1 ?
                        <div className={s.content__header}>
                          {historyListTitles(t).map((titles) => (
                            <div key={titles.id} className={s.content__header_item}>
                              <button
                                style={{ marginLeft: titles.data === 'transactions' ? '-6rem' : titles.data === 'amount' ? '-2rem' : titles.data === 'notes' ? '-5rem' : titles.data === 'type' ? '-3rem' : 0 }}
                                disabled={!titles?.icon}
                                onClick={() => handleSortItems(titles)}
                                type="button"
                                className={s.item}
                              >
                                <p>{titles.name}</p>
                                {titles.icon}
                              </button>

                            </div>
                          ))}
                        </div>
                        :
                        null
                    }

                    <div className={s.content__main}>
                      {isTabletLongExclude ? (
                      // isPaymentsLoading === Loading.success ? (
                        (currentHistoryItems?.length ? currentHistoryItems : toJS(getPaymentsHistory))
                          ?.length >= 1 ? (
                            (currentHistoryItems?.length ? currentHistoryItems : toJS(getPaymentsHistory))
                              ?.map((client: any, i: number) => (
                                <motion.div
                                  initial="hidden"
                                  animate="visible"
                                  variants={{
                                    hidden: { opacity: 0, x: -50 },
                                    visible: {
                                      pathLength: 1,
                                      opacity: 1,
                                      transition: {
                                        delay: i * 0.05,
                                        duration: 0.4,
                                        type: 'spring',
                                        damping: 10
                                      },
                                      x: 0
                                    }
                                  }}
                                  transition={{ duration: 0.7 }}
                                  key={client.id}
                                  className={s.content__items}
                                >
                                  {historyListTitles(t).map((title: any) => (
                                    <div key={title.id} className={s.content__item}>
                                      {title.data === 'amount' ? (
                                        <div className={s.amount__wrapper}>
                                          <p>{Number(client?.[title.data])?.toFixed(2)?.toLocaleString()} UGX</p>
                                        </div>
                                      ) : null}
                                      {title.data === 'transactions' ? (
                                        <div className={s.transactions__wrapper}>

                                          <div className={s.members__count}>
                                            <GroupsSVG />
                                            <p>{client.countEmploees}</p>
                                          </div>

                                          <TransactionsCount
                                            wrapperStyles={{ width: '9rem' }}
                                            allTransactions={client.payments.length}
                                            successfulTransactions={
                                              client.payments.filter(
                                                (payment: any) => payment === 'Success'
                                              ).length
                                            }
                                            failureTransactions={
                                              client.payments.filter(
                                                (payment: any) => payment === 'Failure'
                                              ).length
                                            }
                                            inProgressTransactions={client.payments.filter(
                                              (payment: any) => payment === 'Accepted'
                                            ).length}
                                            trackSuccessStyles={{
                                              backgroundColor: ColorsStatus.statusSuccessful
                                            }}
                                            trackFailureStyles={{
                                              backgroundColor: ColorsStatus.statusFailed
                                            }}
                                            trackInProgressStyles={{
                                              backgroundColor: ColorsStatus.statusInProgress
                                            }}
                                          />
                                        </div>
                                      ) : null}

                                      {title.data === 'notes' ? (
                                        <div className={s.notes__wrapper}>
                                          <p>{client?.notes || '----'}</p>
                                        </div>
                                      ) : null}

                                      {title.data === 'date' ? (
                                        <p>
                                          {client?.startTx
                                            ? format(
                                              Date.parse(client?.startTx),
                                              'dd.MM.yyyy HH:mm'
                                            )
                                            : '----'}
                                        </p>
                                      ) : null}

                                      {/* {title.data === 'type' ? ( */}
                                      {/*  <div className={s.group__payment}> */}
                                      {/*      {client?.groupPayment?.id */}
                                      {/*        ? */}
                                      {/*        <button type='button' onClick={() => goToGroup(client.groupPayment)}> */}
                                      {/*          <GroupsMarkSVG /> */}
                                      {/*          <p>{client.groupPayment.title}</p> */}
                                      {/*        </button> */}
                                      {/*        : <EmployeeMarkSVG />} */}
                                      {/*  </div> */}
                                      {/* ) : null} */}
                                    </div>
                                  ))}

                                  <div className={s.content__items_icons}>
                                    <button type='button' onClick={(e: any) => {
                                      e.stopPropagation();
                                      callHistoryDetailPage(client);
                                    }} className={s.content__items_icon}>
                                      <EditLightSVG />
                                    </button>

                                    <button type='button'
                                            onClick={(e: any) => {
                                              e.stopPropagation();
                                              repeatPayment(client, true);
                                            }}
                                            className={`${s.content__items_icon} ${s.content__items_stroke}`}
                                    >
                                      <RepeatFailurePaymentSVG />
                                    </button>

                                    <button type='button'
                                            onClick={(e: any)  => {
                                              e.stopPropagation();
                                              repeatPayment(client);
                                            } }
                                            className={`${s.content__items_icon} ${s.content__items_stroke}`}
                                    >
                                      <RepeatPaymentSVG />
                                    </button>

                                    <button ref={additionBtnEl} onClick={(e: any) => {
                                      handleAdditionMenuOpen(e, client);
                                      e.stopPropagation();
                                    }} type='button' className={[s.content__items_icon, s.content__items_icon_more].join(' ')}>
                                      <AdditionLightSVG />
                                    </button>
                                  </div>

                                  {/* TODO: Сделать открытие окна для каждого элемента, для каждой кнопки AdditionLight, перестилизировать окно, сровнять в правым краем */}
                                  { isCurrentAdditionMenuId === client.id ?
                                    <MoreFunctionsList funcItem={client} repeatSchedulePayment={repeatPayment} isSchedulePayment={dynamicSchedule} payments={payments} getAuthMe={getAuthMe} historyItem={client} styles={{ right: 0, left: 'auto', transform: 'none' }} isOpen={isAdditionMenuOpen} setIsOpen={setIsAdditionMenuOpen} btnEl={additionBtnEl} />
                                    : null }
                                </motion.div>
                              ))
                          ) : (
                            <h2 className={s.item__title_empty}>{t<string>('MAIN.PAYMENTHISTORY.LIST.EMPTY')}</h2>
                          )
                          // ) : (
                          //   <div className={s.main__loader}>
                          //     <Loader
                          //       loaderStyles={{
                          //         width: '2.5rem',
                          //         height: '2.5rem',
                          //         borderTopWidth: '4px',
                          //         borderWidth: '4px'
                          //       }}
                          //     />
                          //   </div>
                          // )
                      )
                        // : isPaymentsLoading === Loading.success ? (
                        :
                        currentHistoryItems
                          .length >= 1 ? (
                            (currentHistoryItems)
                              .map((client: any, i: number) => (
                              <motion.div
                                initial="hidden"
                                animate="visible"
                                variants={{
                                  hidden: { opacity: 0, x: -50 },
                                  visible: {
                                    pathLength: 1,
                                    opacity: 1,
                                    borderRadius: isCurrentHistoryId.includes(client.id)
                                      ? '1.25rem'
                                      : '.5rem',
                                    transition: {
                                      delay: ( i > 9 ? 0 : i ) * 0.05,
                                      duration: 0.4,
                                      type: 'spring',
                                      damping: 10
                                    },
                                    x: 0
                                  }
                                }}
                                transition={{ duration: 0.7 }}
                                key={client.id}
                                className={s.content__items_mobile}
                                onClick={(e: any) => handleOpenHistoryMenu(e, client)}
                              >
                                <div className={s.content__header}>
                                  <div className={s.content__item}>
                                    {isCurrentHistoryId.includes(client.id) ? (
                                      <motion.h5
                                        initial="hidden"
                                        animate={
                                          isCurrentHistoryId.includes(client.id)
                                            ? 'open'
                                            : 'hidden'
                                        }
                                        variants={{
                                          hidden: { height: '0' },
                                          open: { height: 'fit-content' }
                                        }}
                                        transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                                        className={s.name}
                                      >
                                        {t<string>('MAIN.PAYMENTHISTORY.LIST.HEADERMOBILE.AMOUNT')}
                                      </motion.h5>
                                    ) : null}

                                    <div className={s.content__item_info}>
                                      <div
                                        className={`${s.info__status} ${
                                          client?.payments?.includes('Accepted')
                                            ? s.status__in_process
                                            : ''
                                        } ${
                                          client?.payments?.every(
                                            (currentValue: any) => currentValue === 'Success'
                                          ) && !client?.payments?.includes('Accepted')
                                            ? s.status__successful
                                            : ''
                                        } ${
                                          client?.payments?.includes('Failure') &&
                                          !client?.payments?.includes('Accepted')
                                            ? s.status__error
                                            : ''
                                        }
                                        ${
                                          isCurrentHistoryId.includes(client.id)
                                            ? s.info__status_hide
                                            : ''
                                        }`}
                                      />

                                      <div className={s.content__item_info__inner}>
                                        <p
                                          className={
                                            isCurrentHistoryId.includes(client.id)
                                              ? s.info__status_hide
                                              : ''
                                          }
                                        >
                                          {client?.startTx
                                            ? format(
                                              Date.parse(client?.startTx),
                                              'dd.MM.yyyy HH:mm'
                                            )
                                            : '----'}
                                        </p>

                                        <p>{Number(client?.amount)?.toFixed(2)?.toLocaleString()} UGX</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className={s.icons}>
                                    {isCurrentHistoryId.includes(client.id) ? (
                                      <>
                                        <button onClick={e => {
                                          callHistoryDetailPage(client);
                                          e.stopPropagation();
                                        }}  type='button' className={s.content__items_icon}>
                                          <EditLightSVG />
                                        </button>

                                        <button type='button'
                                                onClick={(e: any) => {
                                                  repeatPayment(client, true);
                                                  e.stopPropagation();
                                                }}
                                                className={`${s.content__items_icon} ${s.content__items_stroke}`}
                                        >
                                          <RepeatFailurePaymentSVG />
                                        </button>

                                        <button type='button' onClick={(e: any) => {
                                          repeatPayment(client);
                                          e.stopPropagation();
                                        }}
                                                className={`${s.content__items_icon} ${s.content__items_stroke}`}
                                        >
                                          <RepeatPaymentSVG />
                                        </button>

                                        <button ref={additionBtnEl} onClick={(e: any) => {
                                          handleAdditionMenuOpen(e, client);
                                          e.stopPropagation();
                                        }}  type='button' className={[s.content__items_icon, s.content__items_icon_more].join(' ')}>
                                          <AdditionLightSVG />
                                        </button>
                                      </>
                                    ) : null}

                                    <div
                                      style={
                                        isCurrentHistoryId.includes(client.id)
                                          ? { transform: 'rotate(180deg)' }
                                          : {}
                                      }
                                      className={s.icon_drop}
                                    >
                                      <DropSVG />
                                    </div>
                                  </div>
                                  { isCurrentAdditionMenuId === client.id ?
                                    <MoreFunctionsList funcItem={client} repeatSchedulePayment={repeatPayment} isSchedulePayment={dynamicSchedule} payments={payments} getAuthMe={getAuthMe} historyItem={client} styles={{ right: 0, left: 'auto', transform: 'none', top: '40%' }} isOpen={isAdditionMenuOpen} setIsOpen={setIsAdditionMenuOpen} btnEl={additionBtnEl} />
                                    : null }
                                </div>

                                <motion.div
                                  initial="hidden"
                                  animate={
                                    isCurrentHistoryId.includes(client.id) ? 'open' : 'hidden'
                                  }
                                  variants={{
                                    hidden: { height: '0' },
                                    open: { height: '100%' }
                                  }}
                                  transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                                  className={s.employee__list_mobile}
                                >
                                  <div className={s.employee__list_mobile_inner}>
                                    <div className={s.group__list}>
                                      <div className={s.group__wrapper}>
                                        <h5>{t<string>('MAIN.PAYMENTHISTORY.LIST.HEADERMOBILE.DATE')}</h5>
                                        <p>
                                          {client?.startTx
                                            ? format(
                                              Date.parse(client?.startTx),
                                              'dd.MM.yyyy HH:mm'
                                            )
                                            : '----'}
                                        </p>
                                      </div>

                                      <div className={[s.group__wrapper, s.status__item].join(' ')}>
                                        <h5 className={s.group__wrapper_status}>
                                          {t<string>('MAIN.PAYMENTHISTORY.LIST.HEADERMOBILE.STATUS')}
                                          <div
                                            className={`${s.info__status} ${
                                              client?.payments?.includes('Accepted')
                                                ? s.status__in_process
                                                : ''
                                            } ${
                                              client?.payments?.every(
                                                (currentValue: any) => currentValue === 'Success'
                                              ) && !client?.payments?.includes('Accepted')
                                                ? s.status__successful
                                                : ''
                                            } ${
                                              client?.payments?.includes('Failure') &&
                                              !client?.payments?.includes('Accepted')
                                                ? s.status__error
                                                : ''
                                            }`}
                                          />
                                        </h5>

                                        <p>
                                          {client?.payments?.includes('Failure') &&
                                          !client?.payments?.includes('Accepted')
                                            ? 'Failure'
                                            : null}
                                          {client?.payments?.every(
                                            (currentValue: any) => currentValue === 'Success'
                                          ) && !client?.payments?.includes('Accepted')
                                            ? 'Success'
                                            : null}
                                          {client?.payments?.includes('Accepted')
                                            ? 'In progress'
                                            : null}
                                        </p>

                                        {client?.payments?.includes('Accepted')
                                          ?
                                          <p className={s.status__progress_count}>
                                            {
                                              client?.payments?.filter((item: any) => item === 'Failure' || item === 'Success').length
                                            }{' '}
                                            {t<string>('MAIN.PAYMENTHISTORY.LIST.HEADERMOBILE.OF')} {client.payments.length}
                                          </p>
                                          :
                                          <p className={s.status__progress_count}>
                                            {
                                              client.payments.filter(
                                                (payment: any) => payment === 'Success'
                                              ).length
                                            }{' '}
                                            {t<string>('MAIN.PAYMENTHISTORY.LIST.HEADERMOBILE.OF')} {client.payments.length}
                                          </p>
                                        }

                                      </div>
                                    </div>

                                    <div className={s.employees__count}>

                                      <div className={s.count}>
                                        <h5>{t<string>('MAIN.PAYMENTHISTORY.LIST.HEADERMOBILE.EMPLOYEES')}</h5>
                                        <p>{client?.countEmploees}</p>
                                      </div>

                                        {/* <div className={s.type}> */}
                                        {/*  <h5>{t<string>('MAIN.PAYMENTHISTORY.LIST.HEADERMOBILE.TYPE')}</h5> */}
                                        {/*    {client?.groupPayment?.id */}
                                        {/*      ? */}
                                        {/*        <button type='button' onClick={(e: any) => { */}
                                        {/*          e.stopPropagation(); */}
                                        {/*          goToGroup(client.groupPayment); */}
                                        {/*        }}> */}
                                        {/*          <GroupsMarkSVG /> */}
                                        {/*          <p>{client.groupPayment.title}</p> */}
                                        {/*        </button> */}
                                        {/*      : <EmployeeMarkSVG />} */}
                                        {/* </div> */}

                                    </div>

                                    <div className={s.description__wrapper}>
                                      <h5>{t<string>('MAIN.PAYMENTHISTORY.LIST.HEADERMOBILE.DESCRIPTION')}</h5>
                                      <p>{client?.notes || '----'}</p>
                                    </div>
                                  </div>
                                </motion.div>
                              </motion.div>
                              ))
                          ) : (
                          <h2 className={s.item__title_empty}>{t<string>('MAIN.PAYMENTHISTORY.LIST.EMPTY')}</h2>
                          )
                        // ) : (
                        //   <div className={s.mobile__loader}>
                        //     <Loader
                        //       loaderStyles={{
                        //         width: '2.5rem',
                        //         height: '2.5rem',
                        //         borderTopWidth: '4px',
                        //         borderWidth: '4px'
                        //       }}
                        //     />
                        //   </div>
                        // )
                      }
                      { isPaymentsLoading !== Loading.success ?
                        <div className={s.mobile__loader}>
                          <Loader
                            loaderStyles={{
                              width: '2.5rem',
                              height: '2.5rem',
                              borderTopWidth: '4px',
                              borderWidth: '4px'
                            }}
                          />
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                </div>

                {
                  (currentHistoryItems?.length ? currentHistoryItems : toJS(getPaymentsHistory))
                    ?.length ?
                    <div className={s.paginate__wrapper}>
                      <Pagination
                        elementsPerPage={elementsCount}
                        setDynamicPage={setDynamicPage}
                        currentPage={dynamicPage || 1}
                        currentSort={dynamicSort}
                        currentFilter={dynamicFilter}
                        activeParams={params}
                        activeLocation={location}
                        totalItems={getPaymentsModel.getPaymentsHistoryMeta?.total}
                        items={getPaymentsHistory}
                        setCurrentDynamicItems={setCurrentHistoryItems}
                        isStatus={isStatus}
                        changePageWithSave
                        changePageWithSavePath={'/history'}
                      />
                    </div>
                    :
                    null
                }
              </>
            )}
          </FormikContainer>
        </div>
      </AnimatedDivPage>
    </section>
  );
};

export const PaymentHistory = observer(PaymentHistoryView);
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { AccountsController } from 'controllers/accounts';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  modalRefEl: any;
  setDynamicOperatorsItems: (dynamicItems: any) => void,
  dynamicOperatorsItems: any,
  operator: any;
  accounts: AccountsController;
}

const ModalRemoveOperatorFromEmployeeView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    accounts,
    modalRefEl,
    setDynamicOperatorsItems,
    dynamicOperatorsItems,
    operator,
  } = props;

  const changeGroupCheck = () => {
    accounts.deleteAccountsAction({ id: operator.id });

    setDynamicOperatorsItems({
      ...dynamicOperatorsItems,
      accounts: dynamicOperatorsItems?.accounts?.filter((item: any) => item?.id !== operator?.id)
    });

    hideModal();

  };

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Delete operator</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              Do you really want to delete this provider: {operator?.provider?.title} ?
            </p>
          </div>

          <div className={s.employee}>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={changeGroupCheck}
              >
                <p>Yes</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>No</p>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalRemoveOperatorFromEmployee = observer(ModalRemoveOperatorFromEmployeeView);

/* eslint-disable prefer-destructuring */
/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { makeAutoObservable, toJS } from 'mobx';
import { Id, toast } from 'react-toastify';
import { IAsyncUtil, TFieldErrors, Loading } from './types';

export class AsyncUtil implements IAsyncUtil {
  private loading: Loading = Loading.stable;

  public startLoading: any = '';

  public updateLoading: any = '';

  private error: string = '';

  private message: string = '';

  private fieldErrors: { [key: string]: string } | null = null;

  private toastLoader: any = '';

  private toastLoaderArr: any[] = [];

  get getLoadingState(): Loading {
    return this.loading;
  }

  get getErrorState(): string {
    return this.error;
  }

  get getFieldsErrorState(): { [key: string]: string } | null {
    return this.fieldErrors;
  }

  get getMessage(): string {
    return this.message;
  }

  startLoadingAsync = async (loading: any, message?: string) => {
    this.loading = Loading.load;
    this.error = '';
    this.fieldErrors = null;
    this.message = '';

    // TODO: *** Нужно разобраться с уведомлениями, сейчас если их больше 3, то они становятся вечной загрузкой. ***

    if (loading) {
      if (this.toastLoaderArr.length >= 2) {
        const dismissToast = await this.toastLoaderArr.shift();
        await toast.dismiss(dismissToast);
        await toast.clearWaitingQueue();
        // const dismissEl = this.toastLoaderArr.shift();
        // toast.dismiss(dismissEl);
      }

      const toastLoader = await toast.loading(message, { isLoading: true, draggable: true, autoClose: 12000, closeOnClick: true });
      await this.toastLoaderArr.push(toastLoader);


    } else {
      toast.loading(message);
    }
  };

  successLoadingAsync = async (loading: any, message?: string) => {
    this.loading = Loading.success;

    if (loading) {
      if (this.toastLoaderArr.length >= 2) {
        const dismissToast = await this.toastLoaderArr.shift();
        await toast.dismiss(dismissToast);
        await toast.clearWaitingQueue();
      }
      this.toastLoaderArr.map((item) => toast.update(item, { render: message, type: 'success', isLoading: false, draggable: true, autoClose: 12000, closeOnClick: true }));
      // toast.update(this.toastLoader, { render: message, type: 'success', isLoading: false, draggable: true, autoClose: 12000, closeOnClick: true });

    } else {
      toast.success(message);
    }
  };

  errorLoadingAsync = async (loading: any, message?: string) => {
    this.loading = Loading.error;

    if (loading) {
      if (this.toastLoaderArr.length >= 2) {
        const dismissToast = await this.toastLoaderArr.shift();
        await toast.dismiss(dismissToast);
        await toast.clearWaitingQueue();
      }
      // toast.update(this.toastLoader, { render: message, type: 'error', isLoading: false, draggable: true, autoClose: 12000, closeOnClick: true });
      this.toastLoaderArr.map((item) => toast.update(item, { render: message, type: 'error', isLoading: false, draggable: true, autoClose: 12000, closeOnClick: true }));
    } else {
      toast.error(message);
    }
  };

  startAsync = () => {
    this.loading = Loading.load;
    this.error = '';
    this.fieldErrors = null;
    this.message = '';
    this.startLoading = toast.loading('Waiting...', {
      isLoading: true,
      draggable: true,
    });
  };

  successAsync = (message?: string) => {
    this.loading = Loading.success;
    this.error = '';
    this.fieldErrors = null;
    this.message = message || '';
    this.updateLoading = toast.update(this.startLoading, {
      render: message || '',
      type: 'success',
      isLoading: false,
      autoClose: 12000,
      draggable: true
    });
  };

  errorAsync = (message?: string, fields?: TFieldErrors) => {
    this.loading = Loading.error;
    this.error = message || '';
    this.fieldErrors = fields ? this.remapFieldsError(fields) : null;
    this.message = '';
    this.updateLoading = toast.update(this.startLoading, {
      render: this.remapFieldsError(fields)['0'] || message,
      type: 'error',
      isLoading: false,
      autoClose: 12000,
      draggable: true
    });
  };

  reset = () => {
    this.loading = Loading.stable;
    this.error = '';
    this.fieldErrors = null;
    this.message = '';
    this.updateLoading = '';
  };

  private remapFieldsError = (fields?: TFieldErrors): { [key: string]: string } =>
    Object.entries(fields || {}).reduce((acc: any, [key, value]: any): any => {
      acc[key] = value[0].toUpperCase() + value.slice(1);
      return acc;
    }, {});

  constructor() {
    makeAutoObservable(this);
  }
}

import React, { useEffect, useState } from 'react';
import { Loader } from 'common/loader';
import { observer } from 'mobx-react-lite';
import { AuthMe } from 'models/auth/types';
import { Location } from 'react-router-dom';
import { PaymentsController } from 'controllers/payments';
import { paymentsHistoryTabsList } from 'common/constants/paymentHistory';
import { IPaymentsHistoryTabs } from 'common/constants/paymentHistory/types';
import s from './index.module.scss';

interface IPaymentsHistoryTabsView {
  setDynamicSchedule: (wait: boolean | string) => void;
  location: Location;
  payments: PaymentsController
  getAuthMe: AuthMe
  dynamicFilter: string | number;
  dynamicSort: string | number;
  dynamicPage: string | number;
  paymentsMobileSearch: string;
  paymentsSearch: string;
  waitMeta: boolean;

}

const PaymentsHistoryTabsView = (props: IPaymentsHistoryTabsView) => {
  const { setDynamicSchedule, dynamicSort, dynamicPage, dynamicFilter, location, payments, paymentsSearch, paymentsMobileSearch, getAuthMe, waitMeta } = props;
  const [activeContent, setActiveContent] = useState<IPaymentsHistoryTabs | null>(null);

  const handleClick = (tab: IPaymentsHistoryTabs) => {
    setActiveContent(tab);
    setDynamicSchedule(tab.wait);
    // resetForm();

    const currentParams = new URLSearchParams();
    currentParams.set('wait', tab.wait.toString());

    const newUrl = `${location.pathname}?${currentParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    setDynamicSchedule(tab.wait);
    payments.getPaymentsHistoryAction({
      id: getAuthMe.id,
      search: paymentsSearch || paymentsMobileSearch,
      page: dynamicPage,
      wait: tab.wait
    });
  };

  useEffect(() => {
    setActiveContent(paymentsHistoryTabsList.filter(tab => tab.wait === waitMeta)[0]);
  }, [waitMeta]);

  return (
    <div className={s.wrapper}>
      <div className={s.buttons}>
        {activeContent ? paymentsHistoryTabsList.map((tab: IPaymentsHistoryTabs) =>
          <div key={tab.id} className={s.button}>
            <button
              className={[tab.id === activeContent.id ? s.button_active : s.button_default].join('')}
              onClick={() =>
                handleClick(
                  tab,
                  // resetForm
                )
              }
              type="submit"
            >
              <p>{tab.title}</p>
            </button>
          </div>
        )
          :
        <Loader />}
      </div>
    </div>
  );
};

export const PaymentsHistoryTabs = observer(PaymentsHistoryTabsView);
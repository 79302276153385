/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useStores } from 'store';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  setIsCheck: (checkItems: any) => void;
  setIsCheckGroup: (CheckGroupItems: any) => void;
  setIsCheckGroupList: (groupItems: any) => void;
  setIsAppendEmployeesModal: (isOpen: boolean) => void;
  isAppendEmployeesModal?: any;
  isCheck: any[];
  isCheckGroup: any[];
  employee: any;
  id: any[];
  modalRefEl: any;
}

const ModalAdditionEmployeeToGroupView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    isCheck,
    isCheckGroup,
    setIsCheck,
    setIsCheckGroup,
    setIsCheckGroupList,
    employee,
    id,
    modalRefEl,
    isAppendEmployeesModal,
    setIsAppendEmployeesModal
  } = props;

  const changeGroupCheck = () => {
    setIsCheck([...isCheck, id]);
    setIsCheckGroup([...isCheckGroup, employee]);
    setIsCheckGroupList([...isCheckGroup, employee]);
    hideModal();
  };

  useEffect(() => () => setIsAppendEmployeesModal(true), [isAppendEmployeesModal]);
  
  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Change group</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            { employee.group ?
            <><p className={s.description__text}>
                {employee?.name} already has a group, do you want to change this group:{' '}
                {employee.group?.title} ?
              </p><br /><p className={s.description__text}>
                  When adding a user to a group, all values that are currently selected will be reassigned to the group's default value, do you really want to change them ?
                </p></>
              :
            <p className={s.description__text}>
              When adding a user to a group, all values that are currently selected will be reassigned to the group's default value, do you really want to change them ?
            </p>
          }
          </div>

          <div className={s.employee}>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={changeGroupCheck}
              >
                <p>Yes</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>Cancel</p>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalAdditionEmployeeToGroup = observer(ModalAdditionEmployeeToGroupView);

/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import React, {
  ChangeEvent,
  ReactElement,
  InputHTMLAttributes,
  ReactChild,
  ReactChildren
} from 'react';
import { observer } from 'mobx-react-lite';
import { useFormikContext, useField } from 'formik';
import s from './index.module.scss';

interface IFormikMuiInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactElement;
  children?: ReactChild | ReactChildren;
  initialStateForUpdate?: any;
  errorStyles?: any;
  defaultValue?: any;
  inputStyles?: any;
  errorMessageDisable?: any;
}

const FormikTextField = (props: IFormikMuiInput): ReactElement => {
  const {
    children,
    onChange,
    name = '',
    value = '',
    label = '',
    errorStyles,
    inputStyles,
    defaultValue,
    type = 'text',
    disabled = false,
    errorMessageDisable,
    ...other
  } = props;

  const [field, meta] = useField(name);
  const inputValue = field.value || value || '';
  
  const regex = new RegExp('^[0-9]+$');
 
  const form = useFormikContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // TODO: Raplace in future
    form.setFieldValue(name, defaultValue || ( name.includes('uniqueId') ? e.target.value.trim() : e.target.value ));
      
    if (onChange) {
      onChange(e);
    }
  };

  const evalProps = {
    ...field,
    ...other,
    value: inputValue,
    id: name,
    disabled,
    style: inputStyles,
    type,
    onChange: handleChange
  };
  const isError = !!(meta.error && meta.touched);

  return (
    <>
      {label && (
        <label className={s.label} htmlFor={name}>
          <h4 className={s.label__title}>{label}</h4>
        </label>
      )}
      <div className={s.wrapper__input}>
        <input placeholder={defaultValue} {...evalProps} />
        {children}
      </div>
      {( isError && !errorMessageDisable ) && (
        <div className={s.input__error} style={errorStyles}>
          <p>{meta.error}</p>
        </div>
      )}
    </>
  );
};

export default observer(FormikTextField);

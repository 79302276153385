/* eslint-disable */

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useStores } from 'store';
import { Outlet } from 'react-router-dom';
import { Header } from 'containers/header';
import { toast } from 'react-toastify';
import { Loader } from 'common/loader';
import { observer } from 'mobx-react-lite';
import { PaymentsTransferStatus } from 'common/constants/payment';
import s from './index.module.scss';
import { Loading } from '../../../store/types';
import { authPaths } from '../../../router/paths/appPaths';


interface ILayoutProps {
  children?: ReactElement | null;
}

const MainLayoutView = ({ children }: ILayoutProps): ReactElement => {
  const { auth, payments, balance } = useStores();
  const { getPaymentsModel, getPaymentsTransferStatusAsyncState } = payments;
  const navigate = useNavigate();
  const location = useLocation();
  const { getAuthModel, getAuthMeAsyncState } = auth;
  const { getBalanceModel } = balance;
  const [completedCount, setCompletedCount] = useState(0);
  const isTransferStatusLoading = getPaymentsTransferStatusAsyncState.getLoadingState;
  const isAuthMeLoadingSuccess = getAuthMeAsyncState.getLoadingState === Loading.success;
  const timeoutIdRef = useRef<number | null>(null);

  const logout = () => {
    auth.logoutAction();
    navigate('/signin', { state: { safetyLogoutMessage: 'For safety the system auto logged out after 5 minutes inactivity. Please sign in again.' } });
  };

  const resetTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    const newTimeoutId = window.setTimeout(logout, 1200000);
    timeoutIdRef.current = newTimeoutId;
  };

  useEffect(() => {
    resetTimeout();
    const handleActivity = () => {
      resetTimeout();
    };

    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('scroll', handleActivity);
    document.addEventListener('click', handleActivity);
    document.addEventListener('touchstart', handleActivity);
    document.addEventListener('touchmove', handleActivity);
    document.addEventListener('touchend', handleActivity);
    document.addEventListener('touchcancel', handleActivity);

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('scroll', handleActivity);
      document.removeEventListener('click', handleActivity);
      document.removeEventListener('touchstart', handleActivity);
      document.removeEventListener('touchmove', handleActivity);
      document.removeEventListener('touchend', handleActivity);
      document.removeEventListener('touchcancel', handleActivity);
    };
  }, []);

  const fetchBalance = () =>
    auth.authMeAction();

  const handleStatusInterval = () => {
    let statusInterval: NodeJS.Timeout | null = null;

    if ((getPaymentsModel.getPaymentsHistoryTransferStatus.map(transfer => transfer.status).includes(PaymentsTransferStatus.inProgress) && sessionStorage.getItem('temporarySessionData')) && !getPaymentsModel.getPaymentsHistoryTransferStatus.map(transfer => transfer.status).includes(PaymentsTransferStatus.waiting)) {
      statusInterval = setInterval(() => {
        // @ts-ignore
        payments.createPaymentsHistoryTransferStatusAction(sessionStorage.getItem('temporarySessionData'));
      }, 6000);
    }

    return () => {
      // @ts-ignore
      clearInterval(statusInterval);
    };
  };

  const handleCompletedStatus = async () => {
    if (getPaymentsModel.getPaymentsHistoryTransferStatus.map(transfer => transfer.status).every(status => status === PaymentsTransferStatus.completed)) {
      getPaymentsTransferStatusAsyncState.reset();
      getPaymentsModel.setPaymentsHistoryTransferStatus([{ status: 'InProgress', multiIxId: '' }]);
      sessionStorage.removeItem('temporarySessionData');
      sessionStorage.removeItem('temporaryMultiTxId');
      sessionStorage.removeItem('temporaryBalance');
      getPaymentsModel.setPaymentsTemporaryBalance({ temporaryBalance: null, active: true });
      toast.success('All payments are completed, balance will be updated soon');

      // Ожидаем 2 секунды перед отправкой запросов
      await new Promise(resolve => setTimeout(resolve, 2000));

      let count = 0;
      const interval = setInterval(async () => {
        if (count < 3) {
          await fetchBalance();
          setCompletedCount((prevCount) => prevCount + 1);
          // eslint-disable-next-line no-plusplus
          count++;
        } else {
          clearInterval(interval); // Останавливаем интервал после 3 запросов
        }
      }, 2000); // Интервал в 2 секунды между запросами
    }
  };

  const handleSessionDataUpdate = () => {
    if (getPaymentsModel.getPaymentsHistoryTransferStatus.map(transfer => transfer.status).some(status => status === PaymentsTransferStatus.completed)) {
      sessionStorage.setItem('temporarySessionData', JSON.stringify({ transfers: getPaymentsModel.getPaymentsHistoryTransferStatus.filter(transfer => transfer.status === PaymentsTransferStatus.inProgress).map(transfer => ({ multiIxId: transfer.multiIxId })) }));
    }
  };

  // @ts-ignore
  useEffect(() => {
    if (!authPaths.includes(location.pathname)) {
      fetchBalance();
    }
  }, [location.pathname]);

  useEffect(handleStatusInterval, [getPaymentsModel.getPaymentsHistoryTransferStatus, isTransferStatusLoading, sessionStorage.getItem('temporarySessionData')]);
  // @ts-ignore
  useEffect(handleCompletedStatus, [getPaymentsModel.getPaymentsHistoryTransferStatus, JSON.parse(sessionStorage.getItem('temporarySessionData'))?.transfers, completedCount, isTransferStatusLoading]);
  useEffect(handleSessionDataUpdate, [getPaymentsModel.getPaymentsHistoryTransferStatus, isTransferStatusLoading]);

  return (
    <>
      <section className={s.wrapper}>
                   {isAuthMeLoadingSuccess || JSON.parse(localStorage.getItem('authMe')!)?.id ? (
            <>
              <Header/>
              <Outlet/>
              {children || null}
            </>
                   ) :
                   <Loader loaderWrapperStyles={{ height: '100vh', flexDirection: 'column' }} loaderStyles={{
                     width: '5rem',
                     height: '5rem',
                     animationDuration: '1s',
                     border: 'none',
                     borderTop: ' 4px solid #ffca00',
                     borderBottom: '4px solid rgba(26, 26, 26, 0.2)',
                     marginBottom: '0.4rem'
                   }}>
                   <p style={{ fontSize: '1rem' }}>Loading...</p>
                   </Loader>}
      </section>
    </>
  );
};

export const MainLayout = observer(MainLayoutView);
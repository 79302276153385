import React from 'react';

export const EditLightSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2677 1.73079L18.6209 1.37689L18.6209 1.37689L18.2677 1.73079ZM4.5 19V19.5C4.63244 19.5 4.75947 19.4475 4.85321 19.3539L4.5 19ZM1 19H0.5C0.5 19.2761 0.723858 19.5 1 19.5V19ZM1 15.436L0.646795 15.0821C0.552814 15.1759 0.5 15.3032 0.5 15.436H1ZM15.0854 2.08469C15.8665 1.3051 17.1334 1.3051 17.9145 2.08469L18.6209 1.37689C17.4494 0.207703 15.5505 0.207703 14.379 1.37689L15.0854 2.08469ZM17.9145 2.08469C18.6951 2.86374 18.6951 4.12642 17.9145 4.90547L18.6209 5.61327C19.793 4.44354 19.793 2.54662 18.6209 1.37689L17.9145 2.08469ZM17.9145 4.90547L4.14679 18.6461L4.85321 19.3539L18.6209 5.61327L17.9145 4.90547ZM4.5 18.5H1V19.5H4.5V18.5ZM14.379 1.37689L0.646795 15.0821L1.35321 15.7899L15.0854 2.08469L14.379 1.37689ZM0.5 15.436V19H1.5V15.436H0.5ZM12.879 3.58174L16.4145 7.11032L17.1209 6.40252L13.5854 2.87394L12.879 3.58174Z"
      fill="#1a1a1a"
    />
    <path
      d="M18.2677 1.73079L18.6209 1.37689L18.6209 1.37689L18.2677 1.73079ZM4.5 19V19.5C4.63244 19.5 4.75947 19.4475 4.85321 19.3539L4.5 19ZM1 19H0.5C0.5 19.2761 0.723858 19.5 1 19.5V19ZM1 15.436L0.646795 15.0821C0.552814 15.1759 0.5 15.3032 0.5 15.436H1ZM15.0854 2.08469C15.8665 1.3051 17.1334 1.3051 17.9145 2.08469L18.6209 1.37689C17.4494 0.207703 15.5505 0.207703 14.379 1.37689L15.0854 2.08469ZM17.9145 2.08469C18.6951 2.86374 18.6951 4.12642 17.9145 4.90547L18.6209 5.61327C19.793 4.44354 19.793 2.54662 18.6209 1.37689L17.9145 2.08469ZM17.9145 4.90547L4.14679 18.6461L4.85321 19.3539L18.6209 5.61327L17.9145 4.90547ZM4.5 18.5H1V19.5H4.5V18.5ZM14.379 1.37689L0.646795 15.0821L1.35321 15.7899L15.0854 2.08469L14.379 1.37689ZM0.5 15.436V19H1.5V15.436H0.5ZM12.879 3.58174L16.4145 7.11032L17.1209 6.40252L13.5854 2.87394L12.879 3.58174Z"
      fill="#1a1a1a"
      fillOpacity="0.2"
    />
  </svg>
);

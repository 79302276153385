import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';
import { Balance } from 'models/balance/types';

abstract class BalanceAPI {
  public static getBalance = (data: Balance): Promise<AxiosResponse> =>
    RestAPI.get(`/api/balance/${data.id}`);
}

export default BalanceAPI;

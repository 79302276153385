import React from 'react';

export const RepeatPaymentSVG = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   version="1.1"
  //   xmlnsXlink="http://www.w3.org/1999/xlink"
  //   width="20"
  //   height="20"
  //   x="0"
  //   y="0"
  //   viewBox="0 0 612 612"
  //   xmlSpace="preserve"
  // >
  //   <g>
  //     <path
  //       d="M592.875 382.5h-191.25c-10.557 0-19.125 8.568-19.125 19.125s8.568 19.125 19.125 19.125h146.02c-43.013 90.365-134.889 153-241.645 153-134.851 0-246.101-99.794-264.709-229.5H2.639C21.497 495.146 149.978 612 306 612c115.285 0 215.577-63.801 267.75-157.973v138.848c0 10.557 8.568 19.125 19.125 19.125S612 603.432 612 592.875v-191.25c0-20.311-19.125-19.125-19.125-19.125zm-573.75-153h191.25c10.557 0 19.125-8.568 19.125-19.125s-8.568-19.125-19.125-19.125H64.241c42.974-90.423 134.984-153 241.759-153 134.851 0 246.101 99.794 264.709 229.5h38.651C590.504 116.834 462.021 0 306 0 190.714 0 90.423 63.801 38.25 157.973V19.125C38.25 8.568 29.682 0 19.125 0S0 8.568 0 19.125v191.25C0 228.907 19.125 229.5 19.125 229.5z"
  //       fill="#1a1a1a"
  //       data-original="#1a1a1a"
  //     />
  //   </g>
  // </svg>
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.58984 5L11.5898 3L9.58984 1" stroke="#1a1a1a" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.41016 17L6.41016 19L8.41016 21" stroke="#1a1a1a" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.6743 5.37097C16.1093 6.81697 17.0003 8.80197 17.0003 11C17.0003 15.418 13.4183 19 9.00026 19C8.21426 19 7.45726 18.882 6.73926 18.671" stroke="#1a1a1a" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.254 16.562C1.86 15.122 1 13.162 1 11C1 6.582 4.582 3 9 3C9.786 3 10.543 3.118 11.261 3.329" stroke="#1a1a1a" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

/* eslint-disable jsx-a11y/no-static-element-interactions,no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useStores } from 'store';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { GroupsModel } from 'models/groups/types';
import { GroupsController } from 'controllers/groups';
import { EmployeeList, EmployeesModel } from 'models/employees/types';
import { EmployeesController } from 'controllers/employees';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { toJS } from 'mobx';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  isCheckGroup: EmployeeList[];
  isCheckGroupList: EmployeeList[];
  setIsCheckGroupList: (groupItems: EmployeeList[]) => void;
  setIsAppendEmployeesModal: (isOpen: boolean) => void;
  isAppendEmployeesModal?: any;
  modalRefEl: any;
}

const ModalAddEmployeesToCreateGroupView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    modalRefEl,
    isCheckGroup,
    isCheckGroupList,
    setIsCheckGroupList,
    isAppendEmployeesModal,
    setIsAppendEmployeesModal
  } = props;

  const isRemovedEmployees = isCheckGroupList.filter(groupEmployee => isCheckGroup.map(employee => employee.id).includes(groupEmployee.id)).length < isCheckGroupList.length;
  const removedListEmployees = isCheckGroupList.filter(groupEmployee => !isCheckGroup.map(employee => employee.id).includes(groupEmployee.id)).map(employee => `${employee.name} ${employee.surname}`);
  const addedListEmployees = isCheckGroup.filter(groupEmployee => !isCheckGroupList.map(employee => employee.id).includes(groupEmployee.id)).map(employee => `${employee.name} ${employee.surname}`);
  const isAddedEmployees = isCheckGroup.filter(groupEmployee => !isCheckGroupList.map(employee => employee.id).includes(groupEmployee.id)).length;

  // После закрытия окна добавления сотрудников, нужно делать isCheckGroup равным setIsCheckGroupList
  const changeGroupCheck = () => {
    setIsCheckGroupList(isCheckGroup);
    setIsAppendEmployeesModal(false);

    hideModal();
  };

  useEffect(() => () => setIsAppendEmployeesModal(false), [isAppendEmployeesModal]);

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Edit employees</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>

            { isRemovedEmployees ?
              <>
                <p className={[s.description__text, s.description__text_scroll].join(' ')}>
                  Do you want to remove employees ({removedListEmployees.map(employee => employee).join(', ')}) from this group ?
                </p><br/>
              </>
              :
              null
            }

            { isAddedEmployees ?
              <>
                <p className={[s.description__text, s.description__text_scroll].join(' ')}>
                  Do you want to add employees ({addedListEmployees.map(employee => employee).join(', ')}) to this group ?
                </p><br/>
              </>
              :
              null
            }

            {
              isCheckGroup.filter((employee) => employee.group).length
                ?
              <>
                <p className={s.description__text}>
                  Some employees already have a group, do you want to change this group ?
                </p><br/>
              </>
                :
                null
            }
            <p className={s.description__text}>
              When adding a user to a group, all values that are currently selected will be reassigned to the group's
              default value, do you really want to change them ?
            </p>
          </div>

          <div className={s.employee}>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={changeGroupCheck}
              >
                <p>Yes</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>No</p>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalAddEmployeesToCreateGroup = observer(ModalAddEmployeesToCreateGroupView);

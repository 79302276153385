import { makeAutoObservable } from 'mobx';
import {
  GetPayments,
  GetPaymentsDescription,
  GetPaymentsHistoryDetails,
  GetPaymentsHistoryInner, IGetPaymentsTransferStatus, ITemporaryBalance,
  PaymentsMeta,
  PaymentsModel
} from './types';

export class PaymentsEntity implements PaymentsModel {
  private payments: GetPayments = {
    id: '',
    amount: 0,
    confirm: false,
    payments: [],
    meta: {
      total: 0,
      page: 1,
      status: 3,
      sort: 0,
      wait: false
    }
  };

  private paymentsHistoryTransferStatus: IGetPaymentsTransferStatus[] = [{
    status: 'InProgress',
    multiIxId: ''
  }];

  private paymentsHistory: GetPaymentsHistoryInner[] = [];

  private paymentsDescription: GetPaymentsDescription = {
    notes: ''
  };

  private paymentsTemporaryBalance: ITemporaryBalance = {
    temporaryBalance: null,
    active: false,
  };

  private paymentsHistoryMeta: PaymentsMeta = {
    total: 0,
    page: 1,
    status: 3,
    sort: 0,
    wait: false
  };

  private paymentsHistoryDetails: GetPaymentsHistoryDetails = {
    id: '',
    amount: 0,
    status: '',
    confirm: false,
    notes: '',
    startTx: '',
    finishTx: '',
    payments: []
  };

  private paymentsHistoryStatus: GetPaymentsHistoryInner = {
    id: '',
    amount: 0,
    confirm: false,
    countEmploees: 0,
    groupPayment: {},
    status: '',
    payments: []
  };

  setPayments(payments: GetPayments) {
    this.payments = payments;
  }

  setPaymentsHistoryDetails(paymentsHistoryDetails: GetPaymentsHistoryDetails) {
    this.paymentsHistoryDetails = { ...paymentsHistoryDetails, amount: Number(paymentsHistoryDetails.amount) };
  }
  
  setPaymentsHistory(paymentsHistory: GetPaymentsHistoryInner[]) {
    this.paymentsHistory = paymentsHistory.map(payment => ({ ...payment, amount: Number(payment.amount) }));
  }
  
  setPaymentsHistoryStatus(paymentsHistoryStatus: GetPaymentsHistoryInner) {
    this.paymentsHistoryStatus = paymentsHistoryStatus;
  }
  
  setPaymentsHistoryMeta(meta: PaymentsMeta) {
    this.paymentsHistoryMeta = meta;
  }

  setPaymentsDescription(paymentsDescription: GetPaymentsDescription) {
    this.paymentsDescription = paymentsDescription;
  }

  setPaymentsHistoryTransferStatus(paymentsHistoryTransferStatus: IGetPaymentsTransferStatus[]) {
    this.paymentsHistoryTransferStatus = paymentsHistoryTransferStatus;
  }

  setPaymentsTemporaryBalance(paymentsTemporaryBalance: ITemporaryBalance) {
    this.paymentsTemporaryBalance = paymentsTemporaryBalance;
  }

  get getPayments() {
    return this.payments;
  }

  get getPaymentsHistory() {
    return this.paymentsHistory?.filter((payment: GetPaymentsHistoryInner) => payment?.payments?.length) || [];
  }

  get getPaymentsHistoryDetails() {
    return this.paymentsHistoryDetails;
  }

  get getPaymentsHistoryStatus() {
    return this.paymentsHistoryStatus;
  }

  get getPaymentsHistoryMeta() {
    return this.paymentsHistoryMeta;
  }

  get getPaymentsDescription() {
    return this.paymentsDescription;
  }

  get getPaymentsHistoryTransferStatus() {
    return this.paymentsHistoryTransferStatus;
  }

  get getPaymentsTemporaryBalance() {
    return this.paymentsTemporaryBalance;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CalendarSVG } from 'assets/icons/calendar';
import { ColorsAccent, ColorsGray } from 'common/colors';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikSelect from 'components/form/fields/select';
import FormikContainer from 'components/form/containers';
import { ChoosenSchedulePayment, ScheduleSelectField } from 'common/constants/schedulePayment/types';
import {
  KindOfSchedule,
  listKindsOfSchedule,
  regularPaymentInterval,
  ScheduleCalendarTitle
} from 'common/constants/schedulePayment';
import { useModal } from 'hooks/useModal.hook';
import { Modals } from 'components/modal';
import { ScheduleCalendarButton } from '../scheduleCalendarButton';
import { ScheduleActiveToggle } from '../scheduleActiveToggle';
import { ScheduleCalendarButtonRegularInterval } from '../scheduleCalendarButtonRegularInterval';
import s from './index.module.scss';

interface ISchedulePaymentView {
  handleSchedulePayment: (choosenScedulePayment: ChoosenSchedulePayment & { isChedulePaymentActive: boolean, isShcedulePaymentReadyToPayment: boolean }) => void;
  isRepeatSchedulePayment: boolean;
}

const SchedulePaymentView = (props: ISchedulePaymentView) => {
  const { handleSchedulePayment, isRepeatSchedulePayment } = props;
  const { showModal } = useModal();
  const [isOpenStartsCalendar, setIsOpenStartsCalendar] = useState<boolean>(false);
  const [isOpenPlannedPaymentCalendar, setIsOpenPlannedPaymentCalendar] = useState<boolean>(false);
  const [isOpenEndsCalendar, setIsOpenEndsCalendar] = useState<boolean>(false);
  const [formattedStartsDate, setFormattedStartsDate] = useState<string>('Choose date');
  const [formattedPlannedPaymentDate, setFormattedPlannedPaymentDate] = useState<string>('Choose date');
  const [formattedEndsDate, setFormattedEndsDate] = useState<string>('Choose date');
  const [switchToggle, setSwitchToggle] = useState<boolean>(isRepeatSchedulePayment || false);
  const [scheduleValidate, setScheduleValidate] = useState<boolean>(false);
  const [kindOfSceduled, setKindOfSceduled] = useState<ScheduleSelectField>(listKindsOfSchedule[0]);
  const [listKindOfSceduled, setListKindOfSceduled] = useState<ScheduleSelectField[]>(listKindsOfSchedule);
  const [choosenScedulePayment, setChoosenSchedulePayment] = useState<ChoosenSchedulePayment>({});
  const openBtnEl: any = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isOverflowVisibleGeneral, setIsOverflowVisibleGeneral] = useState(false);
  const [isOverflowVisibleKindOfSchedule, setIsOverflowVisibleKindOfSchedule] = useState(false);
  const [startAnimateForSwitch, setStartAnimateForSwitch] = useState<boolean>(false);


  // Функция для запуска анимации
  const startAnimation = (kind: ScheduleSelectField) => {
    setIsAnimating(true);

    setTimeout(() => {
      setKindOfSceduled(kind);
      setFormattedStartsDate('Choose date');
      setFormattedEndsDate('Choose date');
      setFormattedPlannedPaymentDate('Choose date');
      // setIsAnimating(false);
    }, 0);
  };

  const handleChange = (kind: ScheduleSelectField, setFieldValue: any) => {
    if (kind.value !== kindOfSceduled.value) {
      setChoosenSchedulePayment({});
      startAnimation(kind);
      setFieldValue('intervalDate', regularPaymentInterval[0]);
      setFieldValue('intervalValue', 0);
    }
  };

  const handleStartsDateSelect = (selectedDate: Date, kindOfChoosen?: number) => {
    const offsetMinutes = selectedDate.getTimezoneOffset();
    if (choosenScedulePayment.regularStart && kindOfChoosen !== 0) {
      const utcDate = new Date(choosenScedulePayment.regularStart);
      const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);

      const hours = localDate.getHours();
      const minutes = localDate.getMinutes();
      const seconds = localDate.getSeconds();

      selectedDate.setHours(hours);
      selectedDate.setMinutes(minutes);
      selectedDate.setSeconds(seconds);
    }

    const isoString = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const updatedSchedulePayment = { ...choosenScedulePayment, regularStart: isoString, };
    setChoosenSchedulePayment(updatedSchedulePayment);
  };

  const handlePlannedPaymentDateSelect = (selectedDate: Date, kindOfChoosen?: number) => {
    const offsetMinutes = selectedDate.getTimezoneOffset();

    if (choosenScedulePayment.pending && kindOfChoosen !== 0) {
      const utcDate = new Date(choosenScedulePayment.pending);
      const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);

      const hours = localDate.getHours();
      const minutes = localDate.getMinutes();
      const seconds = localDate.getSeconds();

      selectedDate.setHours(hours);
      selectedDate.setMinutes(minutes);
      selectedDate.setSeconds(seconds);
    }

    // const utcDate = dayjs(selectedDate).local().toDate();

    const isoString = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const updatedSchedulePayment = { ...choosenScedulePayment, pending: isoString, };
    setChoosenSchedulePayment(updatedSchedulePayment);
  };

  const handleRegularIntervalSelect = (time: number) => {
    const updatedSchedulePayment = { ...choosenScedulePayment, interval: time };
    setChoosenSchedulePayment(updatedSchedulePayment);
  };

  const handleEndsDateSelect = (selectedDate: Date, kindOfChoosen?: number) => {

    if (choosenScedulePayment.regularEnd && kindOfChoosen !== 0) {
      const utcDate = new Date(choosenScedulePayment.regularEnd);
      const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);

      const hours = localDate.getHours();
      const minutes = localDate.getMinutes();
      const seconds = localDate.getSeconds();

      selectedDate.setHours(hours);
      selectedDate.setMinutes(minutes);
      selectedDate.setSeconds(seconds);
    }

    const isoString = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const updatedSchedulePayment = { ...choosenScedulePayment, regularEnd: isoString };
    setChoosenSchedulePayment(updatedSchedulePayment);
  };

  const handleStartsCalendarOpen = () => {
    setIsOpenStartsCalendar(true);
    setIsOpenEndsCalendar(false); // Закрываем календарь окончания, если он открыт
  };

  const handlePlannedPaymentCalendarOpen = () => {
    setIsOpenPlannedPaymentCalendar(true);
    setIsOpenStartsCalendar(false);
    setIsOpenEndsCalendar(false);
  };

  const handleEndsCalendarOpen = () => {
    setIsOpenEndsCalendar(true);
    setIsOpenStartsCalendar(false); // Закрываем календарь начала, если он открыт
  };

  const toggleOpenCalculator = (e: any, setIsOpenCalendar: (open: boolean) => void, isOpenCalendar: boolean, choosenScedulePaymentItem: ChoosenSchedulePayment, wrapperStyles: CSSProperties | undefined, setFormattedDate: (formattedDate: string) => void, onDateSelect: (date: Date, kindOfChoosen?: number) => void, minDate: boolean | undefined, currentDate: Date | null, calendarTitle: string ) => {
    e.stopPropagation();
    setIsOpenCalendar(!isOpenCalendar);
    showModal(Modals.ModalCalendarWindowChoose, {
      setIsOpenCalendar,
      isOpenCalendar,
      choosenScedulePayment: choosenScedulePaymentItem,
      wrapperStyles,
      setFormattedDate,
      onDateSelect,
      currentDate,
      minDate,
      calendarTitle

    });
  };

  useEffect(() => {
    setChoosenSchedulePayment(kindOfSceduled.value === KindOfSchedule.PLANNED ? { pending: null } : {
      regularStart: null,
      regularEnd: null,
      interval: null
    });
  }, [kindOfSceduled]);

  useEffect(() => {
    if (choosenScedulePayment.regularStart && choosenScedulePayment.regularEnd && choosenScedulePayment.interval) {
      setScheduleValidate(true);
    }

    if (choosenScedulePayment.pending) {
      setScheduleValidate(true);
    }

    return () => setScheduleValidate(false);
  }, [choosenScedulePayment]);

  useEffect(() => {
    handleSchedulePayment({ ...choosenScedulePayment, isChedulePaymentActive: switchToggle, isShcedulePaymentReadyToPayment: choosenScedulePayment.interval && choosenScedulePayment.regularEnd && choosenScedulePayment.regularStart ? true : !!choosenScedulePayment.pending });
  }, [choosenScedulePayment, switchToggle]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <h3 className={s.title}>Schedule payment</h3>
        <ScheduleActiveToggle disabled={!startAnimateForSwitch} setChoosenSchedulePayment={setChoosenSchedulePayment} setKindOfSceduled={setKindOfSceduled} setFormattedEndsDate={setFormattedEndsDate} setFormattedStartsDate={setFormattedStartsDate} setFormattedPlannedPaymentDate={setFormattedPlannedPaymentDate} stylesWrapper={{ height: '20px', width: '44.44px', backgroundColor: ColorsGray.grayFour }}
                              stylesCircle={{ height: '13.33px', width: '20px' }} switchToggle={switchToggle}
                              setSwitchToggle={setSwitchToggle}/>
      </div>

      <FormikContainer
        initialValues={{
          kind: listKindsOfSchedule[0],
          intervalDate: regularPaymentInterval[0]
        }}
        onSubmit={() => {
        }}
      >
        {({ errors, setFieldValue }) => (
          <motion.div
            initial={'hidden'}
            animate={switchToggle ? 'open' : 'hidden'}
            variants={{
              open: { height: '100%' },
              hidden: { height: '0' }
            }}
            style={{
              overflow: isOverflowVisibleGeneral ? 'visible' : 'hidden'
            }}
            onAnimationStart={() => {
              setStartAnimateForSwitch(false);
              if (!switchToggle) {
                setIsOverflowVisibleGeneral(false);
              }
            }}

            onAnimationComplete={() => {
              setStartAnimateForSwitch(true);
              if (switchToggle) {
                setIsOverflowVisibleGeneral(true);
              }

              if (!switchToggle) {
                setFieldValue('kind', listKindsOfSchedule[0]);
                setFieldValue('intervalDate', regularPaymentInterval[0]);
                setFieldValue('intervalValue', 0);
                setKindOfSceduled(listKindsOfSchedule[0]);
              }
            }}

            transition={{ duration: 0.3, type: 'spring', damping: 18 }}
            className={s.kind__wrapper}>
            <div className={s.kind}>
              <FormikSelect
                portalTargetDeactivate
                isSearchable={false}
                controlStyles={{ minHeight: '2.125rem', backgroundColor: '#F8FAFC' }}


                // loadingMessage={isGetProvidersLoading}
                // onMenuOpen={handleProviderSelect
                //   // handlePriceListSelect({ id: currentOperatorData.provider?.id })
                // }

                titleStyles={{
                  marginBottom: '0.3rem',
                  color: ColorsAccent.accentDark,
                  fontSize: '14px',
                  fontWeight: '500'
                }}

                containerStyles={{
                  width: '100%',
                  maxWidth: '100%',
                  borderColor: ColorsGray.grayEight
                }}
                valueContainer={{
                  paddingLeft: '.5rem'
                }}
                selectWrapperStyles={{
                  width: '100%'
                }}
                indicatorContainerStyles={{ height: 'auto', width: '2.5rem' }}
                valueStyles={{
                  color: ColorsAccent.accentDark,
                  fontWeight: 500
                }}
                id="kind"
                name="kind"
                options={listKindOfSceduled}
                placeholder={'Choose variant'}
                label={'Variant of schedule payment'}
                indicatorIcon={<DropSelectSVG/>}
                onChange={(kind: ScheduleSelectField) => handleChange(kind, setFieldValue)}
              />
            </div>
            <motion.div
              initial={'hidden'}
              style={{ overflow: isOverflowVisibleKindOfSchedule ? 'visible' : 'hidden' }}
              animate={isAnimating ? 'hidden' : 'open'}

              onAnimationStart={() => {
                if (isAnimating) {
                  setIsOverflowVisibleKindOfSchedule(false);
                }
              }}

              onAnimationComplete={() => {
                if (!isAnimating) {
                  setIsOverflowVisibleKindOfSchedule(true);
                }

                if (isAnimating) {
                  setIsAnimating(false);
                }
              }}

              variants={{
                open: { height: '100%' },
                hidden: { height: '0' }
              }}
              transition={{ duration: 0.3, type: 'spring', damping: 18 }}
              className={s.date}>
              {kindOfSceduled.value === KindOfSchedule.REGULAR ?
                <>
                  <div className={[s.date__item, s.calendar__starts_btn].join('')}>
                    <ScheduleCalendarButton openBtnEl={openBtnEl} date={formattedStartsDate}
                                            title={ScheduleCalendarTitle.STARTS} icon={<CalendarSVG/>}
                                            isOpenCalendar={isOpenStartsCalendar}
                                            setIsOpenCalendar={handleStartsCalendarOpen}
                                            handleIsOpenCalendar={toggleOpenCalculator}
                                            onDateSelect={handleStartsDateSelect}
                                            setFormattedDate={setFormattedStartsDate}
                                            choosenScedulePayment={choosenScedulePayment}
                                            currentDate={choosenScedulePayment.regularStart ? new Date(new Date(choosenScedulePayment.regularStart).getTime() + new Date(choosenScedulePayment.regularStart).getTimezoneOffset() * 60000) : null}
                                            calendarTitle={'Starts'}/>
                  </div>

                  <div className={[s.date__item, s.repeat].join('')}>
                    <ScheduleCalendarButtonRegularInterval clearIntervalValues
                                                           choosenScedulePayment={choosenScedulePayment}
                                                           onIntervalSelect={handleRegularIntervalSelect}
                                                           title={'Repeat every'}/>
                  </div>

                  <div className={[s.date__item, s.calendar__ends_btn].join('')}>
                    <ScheduleCalendarButton openBtnEl={openBtnEl}
                                            date={formattedEndsDate} title={ScheduleCalendarTitle.ENDS}
                                            icon={<CalendarSVG/>} isOpenCalendar={isOpenEndsCalendar}
                                            setIsOpenCalendar={handleEndsCalendarOpen}
                                            handleIsOpenCalendar={toggleOpenCalculator}
                                            currentDate={choosenScedulePayment.regularEnd ? new Date(new Date(choosenScedulePayment.regularEnd).getTime() + new Date(choosenScedulePayment.regularEnd).getTimezoneOffset() * 60000) : null}
                                            minDate
                                            choosenScedulePayment={choosenScedulePayment}
                                            setFormattedDate={setFormattedEndsDate}
                                            onDateSelect={handleEndsDateSelect}
                                            calendarTitle={'Ends'}
                    />
                  </div>
                </>
                :
                <div className={[s.date__item].join('')}>
                  <ScheduleCalendarButton openBtnEl={openBtnEl} date={formattedPlannedPaymentDate}
                                          title={ScheduleCalendarTitle.PLANNEDPAYMENT} icon={<CalendarSVG/>}
                                          isOpenCalendar={isOpenPlannedPaymentCalendar}
                                          setIsOpenCalendar={handlePlannedPaymentCalendarOpen}
                                          handleIsOpenCalendar={toggleOpenCalculator}
                                          currentDate={choosenScedulePayment.pending ? new Date(new Date(choosenScedulePayment.pending).getTime() + new Date(choosenScedulePayment.pending).getTimezoneOffset() * 60000) : null}
                                          choosenScedulePayment={choosenScedulePayment}
                                          setFormattedDate={setFormattedPlannedPaymentDate}
                                          onDateSelect={handlePlannedPaymentDateSelect}
                                          calendarTitle={'Payment date'}/>
                </div>
              }


            </motion.div>
          </motion.div>
        )}
      </FormikContainer>

    </div>
  );
};

export const SchedulePayment = observer(SchedulePaymentView);

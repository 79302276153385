/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikSelect from 'components/form/fields/select';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import FormikTextField from 'components/form/fields/inputs/index';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  state?: any;
}

const ModalCreateOperatorIdView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, state } = props;
  const [choosedAmount, setChoosedAmount] = useState();

  const onSubmit = (values: any) => {

    state?.isCheckGroup?.map((item: any) => {
      if (item.id === state?.payment.id) {
        item.providers.push(state?.uniqueItem);

        const uniqueKeyName: any = `choose${state?.uniqueItem?.id}${state?.payment?.id}`;
        state?.setChooseFromModal(
          state?.uniqueItem?.priceList?.map((price: any) => ({
            uniqueName: uniqueKeyName,
            value: values?.operator?.value,
            description: price?.description,
            label: `${values?.operator?.singleValue} ${values?.operator?.value} UGX`,
            singleValue: `${values?.operator?.singleValue}`,
            providerId: state?.uniqueItem?.id,
            currentOperator: {
              ...state?.uniqueItem,
              employeeId: state?.payment.id,
              icon: state?.uniqueItem?.icon,
              description: price?.description,
              operators: []
            },
            name: state?.payment?.name,
            providers: [],
            employeeId: state?.payment?.id
          }))[0]
        );
      }
    });

    hideModal();
  };

  const handleChooseAmount = (e: any, eMeta: any) => {
    setChoosedAmount(e.value);
  };

  return (
    <ModalBackdrop hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Add operator</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.operator}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.operator__create_id}>
                    <FormikTextField
                      name="id"
                      placeholder="Enter number"
                      type="text"
                      label={<p className={s.operator__create_label}>Id</p>}
                    />
                  </div>

                  {state?.uniqueItem?.priceList?.length > 1 ? (
                    <>
                      <div className={s.operator__create_id}>
                        <FormikSelect
                          containerStyles={{
                            width: '100%'
                          }}
                          valueContainer={{
                            paddingLeft: '.5rem'
                          }}
                          indicatorContainerStyles={{ height: 'auto', width: '2.5rem' }}
                          hideInputContainerWrapper={{
                            width: 0,
                            margin: 0,
                            padding: 0,
                            maxHeight: 0
                          }}
                          valueStyles={{
                            color: 'hsl(0, 0%, 50%)',
                            fontSize: '1rem'
                          }}
                          name={`choose${state?.uniqueItem?.id}${state?.payment?.id}`}
                          id="operator"
                          options={
                            state?.uniqueItem?.priceList.map((price: any) => ({
                              value: price?.price,
                              label: `${price?.position} ${price?.price} UGX`,
                              singleValue: price?.position,
                              id: price?.provider?.id
                            }))
                          }
                          isSearchable={false}
                          placeholder="None"
                          label={<p className={s.operator__create_label}>Product</p>}
                          indicatorIcon={<DropSelectSVG />}
                          onChange={handleChooseAmount}
                        />
                      </div>
                      <div className={s.operator__create_id}>
                        <FormikTextField
                          name="amount"
                          disabled
                          placeholder="Amount"
                          type="text"
                          value={choosedAmount && `${choosedAmount} UGX`}
                          label={<p className={s.operator__create_label}>Amount</p>}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={s.operator__create_id}>
                      <FormikTextField
                        name="amount"
                        placeholder="Enter amount"
                        type="text"
                        label={<p className={s.operator__create_label}>Amount</p>}
                      />
                    </div>
                  )}

                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Save</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>Cancel</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalCreateOperatorId = observer(ModalCreateOperatorIdView);

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { CreateEmployeePhones, EmployeeList } from 'models/employees/types';
import FormikContainer from 'components/form/containers';
import { EmployeesController } from 'controllers/employees';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  phone: any;
  params: any;
  phones: any;
  employees: EmployeesController;
  employeesDetail: EmployeeList;
}

const ModalPhoneRemoveConfirmationView = (props: ModalCreateGroupViewProps) => {
  const { phone, phones, params, employeesDetail, employees, hideModal } = props;

  if (phone?.favorite) {
    phones[0].favorite = true;
  }
  
  const onSubmit = () => {

    employees?.updateEmployeeAction({
      phones,
      name: employeesDetail?.name,
      employeeId: employeesDetail?.id,
      surname: employeesDetail?.surname,
      accounts: employeesDetail?.accounts?.reduce(
        (acc: any, accountItem: any) => {
          acc.push({
            defaultPrice: accountItem.defaultPriceList ? null : accountItem.defaultPrice,
            defaultPriceListId: accountItem.defaultPriceList
              ? accountItem.defaultPriceList?.id
              : null,
            defaultScenario: accountItem?.defaultScenario,
            defaultValueEmployee: accountItem?.defaultValueEmployee || false,
            note: null,
            providerId: accountItem.provider?.id,
            employeeId: params?.id,
            uniqueId: accountItem.uniqueId
          });

          return acc;
        },
        []
      ),
      groupId: employeesDetail?.group?.id,
    });

    hideModal();
  };

  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem', zIndex: 15 }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Delete phone ?</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
            Are you sure to delete phone: {phone?.phone}?
            </p>
          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalPhoneRemoveConfirmation = observer(ModalPhoneRemoveConfirmationView);

import { TFunction } from 'react-i18next';

export const selectFilterByStatus = [
  { label: 'Filter by all', singleValue: 'All', value: 'All', id: 1 },
  { label: 'Filter by success', singleValue: 'Success', value: 'Success', id: 2 },
  { label: 'Filter by in process', singleValue: 'In process', value: 'Accepted', id: 3 },
  { label: 'Filter by failure', singleValue: 'Failure', value: 'Failure', id: 4 }
];

export const selectFilterByStatusHistoryList = (t: TFunction<'translation', undefined>) => [
  { label: t<string>('MAIN.PAYMENTHISTORY.FILTER.SUCCESS'), singleValue: t<string>('MAIN.PAYMENTHISTORY.FILTER.SUCCESS'), value: 0, id: 0 },
  { label: t<string>('MAIN.PAYMENTHISTORY.FILTER.FAILURE'), singleValue: t<string>('MAIN.PAYMENTHISTORY.FILTER.FAILURE'), value: 1, id: 1 },
  { label: t<string>('MAIN.PAYMENTHISTORY.FILTER.INPROCESS'), singleValue: t<string>('MAIN.PAYMENTHISTORY.FILTER.INPROCESS'), value: 2, id: 2 },
  { label: t<string>('MAIN.PAYMENTHISTORY.FILTER.ALL'), singleValue: t<string>('MAIN.PAYMENTHISTORY.FILTER.ALL'), value: 3, id: 3 },
];

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import MaskedInput from 'react-text-mask';
import { EyeSVG } from 'assets/icons/eye';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { MarkSVG } from 'assets/icons/mark';
import { ColorsAccent } from 'common/colors';
import { useTranslation } from 'react-i18next';
import { GoogleSVG } from 'assets/icons/google';
import { AuthContainer } from 'containers/auth';
import { StrengthBar } from 'components/strengthBar';
import { LogoDarkSVG, LogoSVG } from 'assets/icons/logo';
import FormikContainer from 'components/form/containers';
import { RouterLink } from 'router/components/routerLink';
import FormikTextField from 'components/form/fields/inputs';
import FormikCheckbox from 'components/form/fields/checkbox';
import FormikSelectCountries from 'components/form/fields/selectCountries';
import s from './index.module.scss';

export interface LocationState {
  tab: number;
}

const SignUpView = () => {
  const [isHiddenPassword, setIsHiddenPassword] = useState<boolean>(false);
  const [checkEmail, setCheckEmail] = useState<boolean>(false);
  const [resultDialCode, setResultDialCode] = useState<string>('+256');
  const navigate = useNavigate();
  const { auth } = useStores();
  const { getSignUpAsyncState } = auth;
  const isLoading = getSignUpAsyncState.getLoadingState;
  const { t } = useTranslation();
  const isAuthMeLoadingSuccess = auth.getAuthMeAsyncState.getLoadingState;

  const [initialGoogleValues, setInitialGoogleValues] = useState<any>({
    email: '',
    name: '',
    picture: ''
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('This is a required field')
      .min(4, 'Minimum number of characters 4'),
    email: Yup.string().required('This is a required field').email('The field must match email'),
    password: Yup.string()
      .required('This is a required field')
      .matches(/^[A-Za-z0-9!@#$%^&*()_+"№;:?()~ `-]+$/, 'Only latin characters')
      .min(6, 'Password must be longer than or equal to 6 characters'),
    phone: Yup.string(),
    phoneNumber: Yup.string()
      .transform((value: string) => value.replace(/[^\d]/g, ''))
      .min(10, 'Phone number should be valid')
      .required('This is a required field')
  });

  const onSubmit = (values: any) => {
    const data = {
      name: values.name,
      phone: (resultDialCode + values.phoneNumber.replace(/[^\d]/g, '')).split(' ').join(''),
      password: values.password,
      email: values.email,
      picture: '',
      remember: values.remember
    };
    auth.signUpAction(data);
  };

  const handleChange = (value: any, newMeta: any) => {
    setResultDialCode(value);
  };

  const handleCheckEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckEmail(false);
    if (e.target.value) {
      setCheckEmail(true);
    }
  };

  const checkPhoneInputField = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.replace(/[^\d]/g, '')[0] !== '0') {
      e.target.value = `(0)${e.target.value}`;
    }

    if (e.target.value.replace(/[^\d]/g, '')[0] === '0' && e.target.value[3] === '_') {
      e.target.value = '';
    }
  };

  useEffect(() => {
    if (isLoading === Loading.success) {
      navigate('/signup-success');
    }
  }, [isLoading]);

  useEffect(() => {
    const messageFromGoogle = (message: any) => {
      if (message?.data?.user) {
        setInitialGoogleValues(message?.data?.user);
        setCheckEmail(true);
      }
    };
    window.addEventListener('message', messageFromGoogle);
    return () => window.removeEventListener('message', messageFromGoogle);
  }, [initialGoogleValues]);

  const signUpWithGoogle = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/api/auth/google`,
      'Google',
      'location=1, stats=1, scrollbars=1, width: 400, height: 400'
    );
  };

  useEffect(() => {
    localStorage.removeItem('authMe');

    return () => localStorage.removeItem('authMe');
  }, [isAuthMeLoadingSuccess]);

  return (
    <AuthContainer>
      <div className={s.wrapper}>
        <motion.div
          variants={{
            initial: { opacity: 0, scale: 0.8 },
            animate: { opacity: 1, scale: 1 },
            exit: { opacity: 0, scale: 0.8 }
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5, type: 'spring', damping: 17 }}
          className={s.wrapper__inner}
        >
          <div className={s.wrapper__inner__container}>
            <div className={s.wrapper__dark_logo}>
              <LogoDarkSVG />
            </div>
            <FormikContainer
              initialValues={{
                ...initialGoogleValues,
                name: initialGoogleValues?.name.replace(' undefined' || 'undefined', ''),
                password: '',
                email: initialGoogleValues?.email.replace(' undefined' || 'undefined', ''),
                phone: '',
                remember: false
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {({ values, errors, isValid, dirty }) => (
                <>
                  <div className={s.wrapper__inner__title}>
                    <h1>{t<string>('AUTH.SIGNUP.TITLE')}</h1>
                  </div>

                  <div className={s.title__description}>
                    <p className={s.subtitle}>{t<string>('AUTH.SIGNUP.DESCRIPTION')}</p>
                  </div>
                  <div className={s.form__inputs}>
                    <>
                      <div className={`${s.input} ${errors.name ? s.border_alert : null}`}>
                        <FormikTextField
                          placeholder={t<string>('AUTH.SIGNUP.FORM.NAMEPLACEHOLDER')}
                          label={<p className={s.input__label}>{t<string>('AUTH.SIGNUP.FORM.NAME')}</p>}
                          name="name"
                        />
                      </div>
                      <div
                        className={`${s.input__countries} ${
                          errors.phoneNumber ? s.border_alert : null
                        }`}
                      >
                        <FormikSelectCountries
                          name="phone"
                          label={<p className={s.input__label}>{t<string>('AUTH.SIGNUP.FORM.PHONE')}</p>}
                          onChange={handleChange}
                        >
                          <div className={` ${errors.phoneNumber ? s.border_alert : null}`}>
                            <Field name="phoneNumber">
                              {({ field }: any) => (
                                <MaskedInput
                                  {...field}
                                  onInput={checkPhoneInputField}
                                  mask={[
                                    '(',
                                    /\d/,
                                    ')',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/
                                  ]}
                                  placeholder={t<string>('AUTH.SIGNUP.FORM.PHONEPLACEHOLDER')}
                                  guide
                                  name="phoneNumber"
                                  id="phoneNumber"
                                />
                              )}
                            </Field>
                          </div>
                        </FormikSelectCountries>
                        {errors.phoneNumber || errors.tuched ? (
                          <div className={s.input__error}>
                            <p>{errors.phoneNumber}</p>
                          </div>
                        ) : null}
                      </div>

                      <div
                        className={`${s.input} ${s.input__password} ${
                          errors.password ? s.border_alert : null
                        }`}
                      >
                        <FormikTextField
                          type={isHiddenPassword ? 'text' : 'password'}
                          placeholder={t<string>('AUTH.SIGNUP.FORM.PASSWORDPLACEHOLDER')}
                          label={<p className={s.input__label}>{t<string>('AUTH.SIGNUP.FORM.PASSWORD')}</p>}
                          name="password"
                        >
                          <div
                            onClick={() => setIsHiddenPassword(!isHiddenPassword)}
                            className={s.input__icon}
                          >
                            <EyeSVG />
                          </div>
                        </FormikTextField>
                      </div>

                      <StrengthBar password={values.password} />

                      <div className={`${s.input} ${errors.email ? s.border_alert : null}`}>
                        <FormikTextField
                          placeholder={t<string>('AUTH.SIGNUP.FORM.EMAILPLACEHOLDER')}
                          label={<p className={s.input__label}>{t<string>('AUTH.SIGNUP.FORM.EMAIL')}</p>}
                          name="email"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckEmail(e)}
                        >
                          <div
                            className={`${s.input__icon} ${s.input__icon__mark} ${
                              !errors.email && checkEmail ? s.email__fill : ''
                            }`}
                          >
                            <MarkSVG />
                          </div>
                        </FormikTextField>
                      </div>
                    </>

                    <div className={s.form__helper}>
                      <div className={s.checkbox}>
                        <FormikCheckbox
                          label={t<string>('AUTH.SIGNUP.FORM.REMEMBER')}
                          title={t<string>('AUTH.SIGNUP.FORM.REMEMBER')}
                          name="remember"
                          labelStyles={{
                            borderRadius: '4px',
                            backgroundColor: ColorsAccent.accentYellow
                          }}
                        />
                      </div>

                      {/* <div className={s.link}> */}
                      {/*  <RouterLink to={'/password-forgot'}> */}
                      {/*    <p>{t<string>('AUTH.SIGNUP.FORM.FORGOTPASSWORD')}</p> */}
                      {/*  </RouterLink> */}
                      {/* </div> */}
                    </div>

                    <div className={s.buttons}>
                      <motion.button
                      whileTap={
                        (isValid && dirty)
                          ? { scale: 0.99 }
                          : {}
                      }
                      whileHover={
                        (isValid && dirty)
                          ? { scale: 1.02 }
                          : {}
                      }
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        className={s.submit__button}
                        disabled={((!(isValid && dirty)))}
                        type="submit"
                      >
                        <p>{t<string>('AUTH.SIGNUP.FORM.SUBMIT')}</p>
                      </motion.button>

                      <motion.button
                        whileTap={{ scale: 0.99 }}
                        whileHover={{ scale: 1.02 }}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        className={s.google__button}
                        type="button"
                        onClick={signUpWithGoogle}
                      >
                        <GoogleSVG />
                        <p>{t<string>('AUTH.SIGNUP.FORM.SUBMITWITHGOOGLE')}</p>
                      </motion.button>
                    </div>
                  </div>
                  <div className={s.signup}>
                    <p>{t<string>('AUTH.SIGNUP.THEREISACCOUNT')}</p>
                    <RouterLink to={'/signin'}>
                      <p>{t<string>('AUTH.SIGNUP.LINKSIGNINTEXT')}</p>
                    </RouterLink>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </motion.div>

        <motion.div
          variants={{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 }
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5 }}
          className={s.wrapper__logo}
        >
          <div className={s.wrapper__logo_inner}>
            <LogoSVG />
          </div>
        </motion.div>
        <div className={s.circle} />
      </div>
    </AuthContainer>
  );
};

export const SignUp = observer(SignUpView);
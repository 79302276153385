/* eslint-disable no-param-reassign */

import React from 'react';
import { configure, makeAutoObservable } from 'mobx';
import {
  CreateEmployeeDetail,
  EmployeeList,
  EmployeeMeta,
  EmployeesList,
  EmployeesModel
} from './types';

export class EmployeesEntity implements EmployeesModel {
  private employeesList: EmployeeList[] = [];

  private employeesAllActiveList: EmployeeList[] = [];

  private allEmployeesList: EmployeeList[] = [];

  private employeeFilters: EmployeeList[] = [];

  private employeesMeta: EmployeeMeta = {
    total: 0,
    page: 1,
    sortId: '0',
    filter: 0
  };

  private allEmployeesMeta: Omit<EmployeeMeta, 'sortId' | 'filter'> = {
    total: 0,
    page: 1
  };

  private employeesAllActiveMeta: Omit<EmployeeMeta, 'sortId' | 'filter'> = {
    total: 0,
    page: 1
  };

  private updateEmployee: EmployeeList = {
    id: '',
    name: '',
    email: '',
    surname: '',
    deletedAt: '',
    phones: [
      {
        phone: '',
        favorite: false,
      }
    ],
    accounts: [],
    client: {
      id: '',
      email: '',
      name: '',
      picture: '',
      phone: ''
    },
    group: {
      id: '',
      title: '',
      parent_id: '',
      client: {
        id: '',
        email: '',
        name: '',
        picture: '',
        phone: ''
      },
      employees: [
        {
          id: '',
          name: '',
          email: '',
          phones: [],
          accounts: []
        }
      ],
      groupsAccounts: [
        {
          id: '',
          provider: {
            id: '',
            title: '',
            minAmount: 0,
            maxAmount: 0,
            validatePatterns: [],
            inputPatterns: [],
            availability: '',
            isPhoneNumber: false
          },
          priceList: {
            id: '',
            description: '',
            label: '',
            price: 0,
            provider: {
              id: '',
              title: '',
              minAmount: 0,
              maxAmount: 0,
              validatePatterns: [
                {
                  id: '',
                  pattern: ''
                }
              ],
              inputPatterns: [],
              availability: '',
              isPhoneNumber: false
            }
          },
          defaultPrice: 0
        }
      ]
    }
  };

  private createEmployeesDetail: EmployeeList = {
    id: '',
    name: '',
    email: '',
    surname: '',
    deletedAt: '',
    phones: [
      {
        phone: '',
        favorite: false
      }
    ],
    accounts: [],
    client: {
      id: '',
      email: '',
      name: '',
      picture: '',
      phone: ''
    },
    group: {
      id: '',
      title: '',
      parent_id: '',
      client: {
        id: '',
        email: '',
        name: '',
        picture: '',
        phone: ''
      },
      employees: [
        {
          id: '',
          name: '',
          email: '',
          phones: [],
          accounts: []
        }
      ],
      groupsAccounts: [
        {
          id: '',
          provider: {
            id: '',
            title: '',
            minAmount: 0,
            maxAmount: 0,
            validatePatterns: [],
            inputPatterns: [],
            availability: '',
            isPhoneNumber: false
          },
          priceList: {
            id: '',
            description: '',
            label: '',
            price: 0,
            provider: {
              id: '',
              title: '',
              minAmount: 0,
              maxAmount: 0,
              validatePatterns: [
                {
                  id: '',
                  pattern: ''
                }
              ],
              inputPatterns: [],
              availability: '',
              isPhoneNumber: false
            }
          },
          defaultPrice: 0
        }
      ]
    }
  };

  setEmployeesList(employeesList: EmployeeList[]) {
    this.employeesList = employeesList.map(item => {
      if (item.accounts.length) {
        item.accounts = item.accounts.map(account => {
          if (account?.defaultPriceList) {
            account.defaultPriceList.description = account?.defaultPriceList?.label;
          }
          return account;
        });
      }
      return item;
    });
  }

  setAllActiveEmployeesList(employeesAllActiveList: EmployeeList[]) {
    this.employeesAllActiveList = employeesAllActiveList.map(item => {
      if (item.accounts.length) {
        item.accounts = item.accounts.map(account => {
          if (account?.defaultPriceList) {
            account.defaultPriceList.description = account?.defaultPriceList?.label;
          }
          return account;
        });
      }
      return item;
    });
  }

  setAllEmployeesList(allEmployeesList: EmployeeList[]) {
    this.allEmployeesList = allEmployeesList.map(item => {
      if (item.accounts.length) {
        item.accounts = item.accounts.map(account => {
          if (account?.defaultPriceList) {
            account.defaultPriceList.description = account?.defaultPriceList?.label;
          }
          return account;
        });
      }
      return item;
    });
  }

  setEmployeesDetail(createEmployeeDetail: EmployeeList) {
    this.createEmployeesDetail = { ...createEmployeeDetail, accounts: createEmployeeDetail.accounts.map(account => {
      if (account.defaultPriceList) {
        account.defaultPriceList.description = account.defaultPriceList.label;
        return account;
      }
      return account;
    }) };
  }

  setUpdateEmployee(updateEmployee: EmployeeList) {
    this.updateEmployee = updateEmployee;
  }

  setEmployeeFilters(employeeFilters: EmployeeList[]) {
    this.employeeFilters = employeeFilters;
  }

  setEmployeesMeta(employeesMeta: EmployeeMeta) {
    this.employeesMeta = employeesMeta;
  }

  setAllEmployeesMeta(allEmployeesMeta: Omit<EmployeeMeta, 'sortId' | 'filter'>) {
    this.allEmployeesMeta = allEmployeesMeta;
  }

  setAllActiveEmployeesMeta(employeesAllActiveMeta: Omit<EmployeeMeta, 'sortId' | 'filter'>) {
    this.employeesAllActiveMeta = employeesAllActiveMeta;
  }

  get getEmployeesList(): EmployeeList[] {
    return this.employeesList;
  }

  get getAllActiveEmployeesList(): EmployeeList[] {
    return this.employeesAllActiveList;
  }

  get getAllEmployeesList(): EmployeeList[] {
    return this.allEmployeesList;
  }

  get getEmployeesDetail(): EmployeeList {
    return this.createEmployeesDetail;
  }

  get getUpdateEmployee(): EmployeeList {
    return this.updateEmployee;
  }

  get getEmployeesMeta(): EmployeeMeta {
    return this.employeesMeta;
  }

  get getAllActiveEmployeesMeta(): Omit<EmployeeMeta, 'sortId' | 'filter'> {
    return this.employeesAllActiveMeta;
  }

  get getAllEmployeesMeta(): Omit<EmployeeMeta, 'sortId' | 'filter'> {
    return this.allEmployeesMeta;
  }

  get getEmployeeFilters(): EmployeeList[] {
    return this.employeeFilters;
  }

  constructor() {
    makeAutoObservable(this);
    configure({
      // useProxies: 'never',
    });
  }
}

import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import FormikContainer from 'components/form/containers';
import { GetPaymentsHistoryInner } from 'models/payments/types';
import FormikTextField from 'components/form/fields/inputs/index';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  sendItem: GetPaymentsHistoryInner;
  moreFuncBtnEl: any | React.MutableRefObject<any>;
  clientId: string;
}

const ModalSendReportView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, sendItem, moreFuncBtnEl, clientId } = props;
  const { payments } = useStores();
  const containerEl = useRef<any>(null);
  const backdropWrapperEl = useRef<any>(null);
  const { getPaymentsExportSendAsyncState } = payments;
  const isPaymentsExportSendSuccess = getPaymentsExportSendAsyncState.getLoadingState === Loading.success;

  const onSubmit = (values: { email: string }) => {
    const { email } = values;

    payments.createPaymentsExportSendAction({ multiId: sendItem.id, clientId, email });
  };
  const validateSchema = Yup.object().shape({
    email: Yup.string().required('This is a required field').email('The field must match email')
  });

  useEffect(() => {
    const isOpenInteractiveList = (e: any) =>
      !containerEl?.current?.contains(e.target) &&
      !moreFuncBtnEl?.current?.contains(e.target) &&
      backdropWrapperEl?.current?.contains(e.target)
      && hideModal();

    document.addEventListener('click', isOpenInteractiveList);
    return () => document.removeEventListener('click', isOpenInteractiveList);
  }, [moreFuncBtnEl]);

  useEffect(() => {
    if (isPaymentsExportSendSuccess) {
      hideModal();
    }

    return () => getPaymentsExportSendAsyncState.reset();
  }, [isPaymentsExportSendSuccess]);

  return (
    <div className={s.backdrop} ref={backdropWrapperEl}>
      <div className={s.wrapper} ref={containerEl}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Send report</h2>
          </div>

          <button type='button' className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </button>
        </div>

        <FormikContainer
          initialValues={{ email: '' }}
          onSubmit={onSubmit}
          validationSchema={validateSchema}
        >
          {({ errors }) => (
            <>
              <div
                className={`${s.input} ${errors.email ? s.border_alert : null}`}
              >
                <FormikTextField
                  placeholder={'Enter email'}
                  label={<p className={s.input__label}>Email</p>}
                  name="email"
                  // errorStyles={{
                  //   position: 'absolute'
                  // }}
                />
              </div>

              <div className={s.buttons}>
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                  value="Submit send email"
                  type="submit"
                >
                  <p>Send</p>
                </motion.button>
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                  type="button"
                  onClick={() => hideModal()}
                >
                  <p>Cancel</p>
                </motion.button>
              </div>
            </>
          )}


        </FormikContainer>
      </div>
    </div>
  );
};

export const ModalSendReport = observer(ModalSendReportView);

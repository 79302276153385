import { useState, useEffect } from 'react';

interface IWindowDimensions {
  isDesktop?: boolean;
  isNotebook?: boolean;
  isTablet?: boolean;
  isTabletAdditional?: boolean;
  isTabletLong?: boolean;
  isTabletLongExclude?: boolean;
  isMobile?: boolean;
}

export default function useWindowDimensions(): IWindowDimensions {
  function getWindowDimensions() {
    const windowWidth = window.innerWidth;

    return windowWidth;
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  const currentWindowDimensions: IWindowDimensions = {
    isDesktop: windowDimensions >= 1200,
    isNotebook: windowDimensions >= 1024,
    isTabletLong: windowDimensions >= 830,
    isTabletLongExclude: windowDimensions > 830,
    isTablet: windowDimensions >= 768,
    isTabletAdditional: windowDimensions > 768,
    isMobile: windowDimensions >= 480,
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...currentWindowDimensions };
}

import { TFunction } from 'react-i18next';

export enum ProviderMeasure {
  PACKAGES = 'PACKAGES',
  MONEY = 'MONEY',
  PACKAGES_AND_MONEY = 'PACKAGES_AND_MONEY'
}

export enum CheckFieldsType {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI'
}

export const paymentListTitles = (t: TFunction<'translation', undefined>): any[] => [
  {
    name: t('MAIN.NEWPAYMENT.LIST.TITLE'),
    data: 'name',
    surname: 'surname',
    id: 0
  }
];

export enum PaymentsTransferStatus {
  completed = 'Completed',
  inProgress = 'InProgress',
  waiting = 'Waiting'
}

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { Modals } from 'components/modal';
import { useNavigate } from 'react-router';
import { LinkSVG } from 'assets/icons/link';
import { useModal } from 'hooks/useModal.hook';
import { UploadFile } from 'components/UploadFile';
import FormikContainer from 'components/form/containers';
import s from './index.module.scss';

export const ImportPaymentFileFunc = () => {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const [importFile, setImportFile] = useState<File>();

  const onSubmit = () => {
    if (importFile) {
      showModal(Modals.ModalImportWindowConfirm, {
        importFile,
        navigate
      });
    } else {
      toast.warning('Please, choose .csv file');
    }
  };

  const importEmployee = (file: File) => {
    setImportFile(file);
  };

  return (
    <div className={s.wrapper__container}>
      <div className={s.main}>
        <div className={s.description}>
          <div className={s.description__wrapper}>
            <a className={s.description__text} href={`${process.env.PUBLIC_URL}/downloads/instruction.docx`}
               download={'instruction.docx'}>
              <p>Instruction for filling in a .csv file</p>
              <LinkSVG/>
            </a>
          </div>
          <UploadFile getImportFile={importEmployee}/>
        </div>

        <div className={s.employee}>
          <FormikContainer initialValues={{}} onSubmit={onSubmit}>
            {() => (
              <>
                <div className={s.buttons}>
                  <motion.button
                    whileTap={{ scale: 0.98 }}
                    whileHover={{ scale: 1.01 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                    type="submit"
                  >
                    <p>Import</p>
                  </motion.button>
                </div>
              </>
            )}
          </FormikContainer>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable class-methods-use-this */
import SERVICE_API from 'api/payway';
import { makeAutoObservable } from 'mobx';
import { IAsyncUtil, IConstructorAsyncUtils } from 'store/types';
import {
  ConfirmPayments,
  GetPayments,
  GetPaymentsHistory,
  GetPaymentsHistoryDetails,
  GetPaymentsHistoryInner, ICreatePaymentsTransferStatus,
  IGetPaymentsTransferStatus,
  PaymentsModel
} from 'models/payments/types';
import { remapFieldsError } from 'common/replaceErrorMessage';
import PaymentsAPI from '../../api/payway/payments';

interface PaymentsRepository {
  new (): PaymentsModel;
}

export class PaymentsController {
  private paymentsModel: PaymentsModel;

  private createPaymentsAsyncHelper: IAsyncUtil;

  private confirmPaymentsAsyncHelper: IAsyncUtil;

  private paymentsAsyncHelper: IAsyncUtil;

  private paymentsDetailsAsyncHelper: IAsyncUtil;

  private updatePaymentsDetailsAsyncHelper: IAsyncUtil;

  private paymentsStatusAsyncHelper: IAsyncUtil;

  private paymentsTransferStatusAsyncHelper: IAsyncUtil;

  private paymentsExportAsyncHelper: IAsyncUtil;

  private paymentsExportSendAsyncHelper: IAsyncUtil;

  get getPaymentsModel(): PaymentsModel {
    return this.paymentsModel;
  }

  get getCreatePaymentsAsyncState(): IAsyncUtil {
    return this.createPaymentsAsyncHelper;
  }

  get getConfirmPaymentsAsyncHelper(): IAsyncUtil {
    return this.confirmPaymentsAsyncHelper;
  }

  get getPaymentsAsyncState(): IAsyncUtil {
    return this.paymentsAsyncHelper;
  }

  get getPaymentsDetailsAsyncState(): IAsyncUtil {
    return this.paymentsDetailsAsyncHelper;
  }

  get getUpdatePaymentsDetailsAsyncState(): IAsyncUtil {
    return this.updatePaymentsDetailsAsyncHelper;
  }

  get getPaymentsStatusAsyncState(): IAsyncUtil {
    return this.paymentsStatusAsyncHelper;
  }

  get getPaymentsTransferStatusAsyncState(): IAsyncUtil {
    return this.paymentsTransferStatusAsyncHelper;
  }

  get getPaymentsExportAsyncState(): IAsyncUtil {
    return this.paymentsExportAsyncHelper;
  }

  get getPaymentsExportSendAsyncState(): IAsyncUtil {
    return this.paymentsExportSendAsyncHelper;
  }

  createPaymentsAction = async (payment: any): Promise<void> => {
    this.createPaymentsAsyncHelper.startLoadingAsync(true, 'Making a payment...');
    try {
      const { data } = await SERVICE_API.PaymentsAPI.createPayments(payment);
      await this.createPaymentsAsyncHelper.successLoadingAsync(
        // 'Payment successfully completed'
        true, '',
      );
      this.createConfirmPaymentsAction({ multiTxId: data.id });
      this.getPaymentsSuccess(data);
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.createPaymentsAsyncHelper.errorLoadingAsync(true, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  createConfirmPaymentsAction = async (multiTx: ConfirmPayments): Promise<void> => {
    this.confirmPaymentsAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.PaymentsAPI.createConfirmPayments(multiTx);
      this.getPaymentsSuccess(data);

      // Получите текущее значение из сессии
      const sessionData = await JSON.parse(sessionStorage.getItem('temporarySessionData') || '{"transfers": []}');

      // Добавьте новый объект в массив transfers
      await sessionData.transfers.push({ multiIxId: multiTx?.multiTxId });

      // Сохраните обновленное значение в сессии
      await sessionStorage.setItem('temporarySessionData', JSON.stringify(sessionData));

      sessionStorage.setItem('temporaryBalance', data?.balance);
      sessionStorage.setItem('temporaryMultiTxId', multiTx?.multiTxId);
      this.getPaymentsHistoryStatusAction({ id: data?.multi?.id });
      this.createPaymentsHistoryTransferStatusAction(sessionData);
      await this.confirmPaymentsAsyncHelper.successLoadingAsync(false, 'Payment process started');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.confirmPaymentsAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  getPaymentsHistoryAction = async (client: any): Promise<void> => {
    this.getPaymentsAsyncState.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.PaymentsAPI.getPaymentsHistory(client);
      this.getPaymentsHistorySuccess(data);
      await this.getPaymentsAsyncState.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getPaymentsAsyncState.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  getPaymentsHistoryDetailsAction = async (client: any): Promise<void> => {
    try {
      const { data } = await SERVICE_API.PaymentsAPI.getPaymentsHistoryDetails(client);
      this.getPaymentsHistoryDetailsSuccess(data);
      await this.getPaymentsDetailsAsyncState.successAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getPaymentsDetailsAsyncState.errorAsync(errorMessage, fieldErrors);
    }
  };

  updatePaymentsHistoryDetailsAction = async (dataItem: { multiTxId: string, notes: string }): Promise<void> => {
    this.getUpdatePaymentsDetailsAsyncState.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.PaymentsAPI.updatePaymentsHistoryDetails(dataItem);
      this.getUpdatePaymentDescriptionSuccess({ notes: data.notes });
      await this.getUpdatePaymentsDetailsAsyncState.successLoadingAsync(false, 'Payment description was changed');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getUpdatePaymentsDetailsAsyncState.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  getPaymentsHistoryStatusAction = async (client: any): Promise<void> => {
    this.paymentsStatusAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.PaymentsAPI.getPaymentsHistoryStatus(client);
      this.getPaymentsHistoryStatusSuccess(data);
      await this.paymentsStatusAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.paymentsStatusAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  createPaymentsHistoryTransferStatusAction = async (transferData: ICreatePaymentsTransferStatus): Promise<void> => {
    this.paymentsTransferStatusAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.PaymentsAPI.createPaymentsHistoryTransferStatus(transferData);
      this.getPaymentsHistoryTransferStatusSuccess(data);
      await this.paymentsTransferStatusAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.paymentsTransferStatusAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
      sessionStorage.removeItem('temporaryBalance');
      sessionStorage.removeItem('temporarySessionData');
      sessionStorage.removeItem('temporaryMultiTxId');
    }
  };

  getPaymentsExportAction = async (payment: { multiId: string, clientId: string }): Promise<void> => {
    this.paymentsExportAsyncHelper.startLoadingAsync( true, 'File exporting process...');
    try {

      const response = await SERVICE_API.PaymentsAPI.getPaymentsExport(payment);
      const link = document.createElement('a');
      if (response.config.url) {
        link.href = await response.config.url;
      }

      await document.body.appendChild(link);
      await link.click();
      await this.paymentsExportAsyncHelper.successLoadingAsync( true, 'File exported successfully');


      // await this.paymentsExportAsyncHelper.successLoadingAsync('File exported successfully');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.paymentsExportAsyncHelper.errorLoadingAsync(true, remapFieldsError(fieldErrors)['0'] || errorMessage
      );
    }
  };

  createPaymentsExportSendAction = async (payment: { multiId: string, clientId: string, email: string }): Promise<void> => {
    this.getPaymentsExportSendAsyncState.startLoadingAsync(true, 'File sending to email process...');
    try {
      await SERVICE_API.PaymentsAPI.createPaymentsExportSend(payment);

      await this.getPaymentsExportSendAsyncState.successLoadingAsync(true, 'Report sent to email successfully',);

      // await this.paymentsExportAsyncHelper.successLoadingAsync('File exported successfully');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getPaymentsExportSendAsyncState.errorLoadingAsync(true, remapFieldsError(fieldErrors)['0'] || errorMessage,
      );
    }
  };

  private ormErrors = (error: any) => {
    const errorMessage = error?.response?.data?.error || error?.message;
    const fieldErrors = error?.response?.data?.message;
    return { errorMessage, fieldErrors };
  };

  
  private getPaymentsSuccess = (data: GetPayments): void => {
    this.paymentsModel.setPayments(data);
  };

  private getPaymentsHistorySuccess = (data: GetPaymentsHistory): void => {
    this.paymentsModel.setPaymentsHistory(data?.multiPayments);
    this.paymentsModel.setPaymentsHistoryMeta(data?.meta);
  };

  private getPaymentsHistoryDetailsSuccess = (data: GetPaymentsHistoryDetails): void => {
    this.paymentsModel.setPaymentsHistoryDetails(data);
  };

  private getUpdatePaymentDescriptionSuccess = (data: { notes: string }): void => {
    this.paymentsModel.setPaymentsDescription(data);
  };

  private getPaymentsHistoryStatusSuccess = (data: GetPaymentsHistoryInner): void => {
    this.paymentsModel.setPaymentsHistoryStatus(data);
  };

  private getPaymentsHistoryTransferStatusSuccess = (data: IGetPaymentsTransferStatus[]): void => {
    this.paymentsModel.setPaymentsHistoryTransferStatus(data);
  };

  constructor(PaymentsEntity: PaymentsRepository, AsyncHelper: IConstructorAsyncUtils) {
    this.paymentsModel = new PaymentsEntity();
    this.paymentsAsyncHelper = new AsyncHelper();
    this.paymentsExportAsyncHelper = new AsyncHelper();
    this.paymentsStatusAsyncHelper = new AsyncHelper();
    this.createPaymentsAsyncHelper = new AsyncHelper();
    this.confirmPaymentsAsyncHelper = new AsyncHelper();
    this.paymentsDetailsAsyncHelper = new AsyncHelper();
    this.paymentsExportSendAsyncHelper = new AsyncHelper();
    this.updatePaymentsDetailsAsyncHelper = new AsyncHelper();
    this.paymentsTransferStatusAsyncHelper = new AsyncHelper();

    makeAutoObservable(this);
  }
}

import { toast } from 'react-toastify';

// eslint-disable-next-line consistent-return
export const validateImportString = (input: string): any => {

  const pattern = /^0\d{9}$/;

  if (pattern.test(input)) {
    return input;
  }

  if (/^\d{9}$/.test(input) && input[0] !== '0') {
    // toast.warning('For some numbers, a 0 was added to the start, this is required by the operator number format');
    return `0${input}`;
  }

  if ((input.length <= 9 && input[0] === '0' || input.length < 9) || input.length >= 10) {
    return 'reject';
  }

  if (!/^\d+$/.test(input)) {
    return 'reject';
  }

};

export const validateString = (input: string | undefined) => {
  // Если поле пусто, возвращаем true (валидное)
  if (!input) {
    return true;
  }

  const pattern = /^0\d{9}$/;
  if (pattern.test(input)) {
    return input; // Возвращает введенную строку, возможно с добавленным ведущим нулем
  }
  if (/^\d{9}$/.test(input) && input[0] !== '0') {
    // Если введена строка из 9 цифр без ведущего нуля, добавьте ведущий ноль
    // toast.warning('Подставлен 0, 9 цифр было');
    // return `0${input}`;
    return false;
  }

  if ((input.length <= 9 && input[0] === '0' || input.length < 9) || input.length >= 10) {
    // toast.warning('The customer number must contain 10 characters and start from 0.');
    return false;
  }

  return false;
  // Вывести сообщение об ошибке, если введено менее 10 цифр
};

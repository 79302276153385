import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';
import {
  AuthMe,
  AuthVerification,
  CreateResetPassword, ICreateNewPassword, ICreateNewPasswordResponse,
  ICreateResetPasswordCode,
  SignIn,
  SignInMobile,
  SignUp
} from 'models/auth/types';

abstract class AuthAPI {
  public static createSignUp = (data: SignUp): Promise<AxiosResponse> =>
    RestAPI.post('/api/auth/registration', data);

  public static createSignIn = (data: SignIn): Promise<AxiosResponse> =>
    RestAPI.post('/api/auth/authorization', data);

  public static createSignInMobile = (data: SignInMobile): Promise<AxiosResponse> =>
    RestAPI.post('/api/auth/authorization-mobile', data);

  public static createSignInGoogle = (data: any): Promise<AxiosResponse> =>
    RestAPI.post('/api/auth/authorization-google', data);

  public static createVerifiction = (data: AuthVerification): Promise<AxiosResponse> =>
    RestAPI.post('/api/auth/verification', data);

  public static getLogout = (): Promise<AxiosResponse> =>
    RestAPI.get('/api/auth/logout');

  public static getAuthMe = (): Promise<AxiosResponse> =>
    RestAPI.get('/api/auth/me');

  public static getSignUpGoogle = (): Promise<AxiosResponse> =>
    RestAPI.get('/api/auth/google');

  public static getGoogleRedirect = (data: any): Promise<AxiosResponse> =>
    RestAPI.get('/api/auth/google/redirect');

  public static createResetPassword = (data: CreateResetPassword ): Promise<AxiosResponse> =>
    RestAPI.post('/api/auth/resrt-password', data);

  public static createResetPasswordCode = (data: ICreateResetPasswordCode): Promise<AxiosResponse> =>
    RestAPI.post('/api/auth/reset-password-code', data);

  public static createNewPassword = (data: ICreateNewPasswordResponse): Promise<AxiosResponse> =>
    RestAPI.post('/api/auth/new-password', data);
}

export default AuthAPI;

import React from 'react';
import { Form, Formik } from 'formik';
import { IFormContainer } from './types';
import FormErrorContainer from './errorContainer';

const FormikContainer = (props: IFormContainer) => {
  const { serverErrors = {}, className, children, ...other } = props;
  return (
    <Formik {...other}>
      {(formik) => {
        const { setErrors } = formik;
        return (
          <FormErrorContainer serverErrors={serverErrors} setErrors={setErrors}>
            <Form className={className}>{children(formik)}</Form>
          </FormErrorContainer>
        );
      }}
    </Formik>
  );
};

export default FormikContainer;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { GroupSVG } from 'assets/icons/group';
import { CrossSVG } from 'assets/icons/cross';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import FormikTextField from 'components/form/fields/inputs/index';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  setIsCheck: (arr: any) => void;
  setIsCheckGroup: (arr: any) => void;
  isCheckGroup?: any;
  choosenGroup?: any;
  isSaveValues?: boolean;
}

const ModalMoveToGroupWarningView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, isCheckGroup, setIsCheckGroup, setIsCheck, choosenGroup, isSaveValues } = props;
  const { auth, employees, groups } = useStores();
  const { getUpdateEmployeeAsyncState } = employees;
  const isUpdateEmployeeLoadingSuccess = getUpdateEmployeeAsyncState.getLoadingState;
  const { state }: any = useLocation();
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const removeDuplicates = (data: any, key: any) => [
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];
  const onSubmit = async (values: any) => {
    // TODO: Нужно протестировать поле isSaveEmployeeValues, нужно сделать перемещение сотрудника в группу с сохранением его значений. (сделать чекбокс, сейчас тест - isSaveEmployeeValues )
    // TODO: Нужно протестировать обновление запроса за перемещение сотрудников, когда иной раз нажимаю на перемещение, страница не перезагружается, возможно поменять getAsyncState на корневой asyncState и делать ему reset()
    isCheckGroup?.reduce((acc: any, employee: any, index: number) => {
      Promise.all([employees?.updateEmployeeAction({
        employeeId: employee?.id,
        name: employee?.name,
        phones: employee?.phones,
        surname: employee?.surname,
        groupId: choosenGroup?.id,
        accounts: 
        // choosenGroup?.id ?
          isSaveValues
            ?
            employee?.accounts?.map((item: any) => ({
              defaultPrice: item?.defaultPrice,
              defaultPriceListId: item?.defaultPriceList?.id,
              defaultScenario: item?.defaultScenario,
              defaultValueEmployee: false,
              providerId: item?.provider?.id,
              employeeId: employee?.id,
              uniqueId: item?.uniqueId,
              note: item?.note || null
            }))
            :
            employee?.accounts?.length ?
              [ ...removeDuplicates(employee?.accounts?.map((employeeItem: any) => choosenGroup?.groupsAccounts?.reduce((accEmployee: any, group: any) => {

                if (employeeItem?.provider?.id === group?.provider?.id) {
                  accEmployee.push(
                    {
                      defaultPrice: employeeItem?.provider?.id === group?.provider?.id ? !group?.priceList?.id ? group.defaultPrice : null : !employeeItem?.defaultPriceList?.id ? employeeItem?.defaultPrice : null,
                      defaultPriceListId: employeeItem?.provider?.id === group?.provider?.id ? group?.priceList?.id ? group.priceList?.id : null : employeeItem?.defaultPriceList?.id || null,
                      defaultScenario: employeeItem?.defaultScenario,
                      defaultValueEmployee: false,
                      providerId: employeeItem?.provider?.id,
                      employeeId: employee?.id,
                      uniqueId: employeeItem?.uniqueId,
                      note: employeeItem?.note || null
                    }
                  );
                }

                return accEmployee;
              }, [])).flat(Infinity), (key: any) => key.providerId),

              ...choosenGroup?.groupsAccounts?.filter((groupAccount: any) =>
                !removeDuplicates(employee?.accounts?.map((employeeItem: any) => choosenGroup?.groupsAccounts?.reduce((accEmployee: any, group: any) => {

                  if (employeeItem?.provider?.id === group?.provider?.id) {
                    accEmployee.push(
                      {
                        defaultPrice: employeeItem?.provider?.id === group?.provider?.id ? !group?.priceList?.id ? group.defaultPrice : null : !employeeItem?.defaultPriceList?.id ? employeeItem?.defaultPrice : null,
                        defaultPriceListId: employeeItem?.provider?.id === group?.provider?.id ? group?.priceList?.id ? group.priceList?.id : null : employeeItem?.defaultPriceList?.id || null,
                        providerId: employeeItem?.provider?.id,
                        employeeId: employee?.id,
                        uniqueId: employeeItem?.uniqueId,
                        note: employeeItem?.note || null
                      }
                    );
                  }
                  return accEmployee;
                }, [])).flat(Infinity), (key: any) => key.providerId).map((convertEmployee: any) => convertEmployee?.providerId).includes(groupAccount?.provider?.id)).reduce((groupAcc: any, groupItem: any) => {
                groupAcc?.push({
                  defaultPrice: !groupItem?.priceList?.id ? groupItem.defaultPrice : null,
                  defaultPriceListId: groupItem?.priceList?.id ? groupItem.priceList?.id : null,
                  providerId: groupItem?.provider?.id,
                  employeeId: employee?.id,
                  uniqueId: null,
                  note: null
                });
                return groupAcc;
              }, []),
              ]
              :
              choosenGroup?.groupsAccounts?.reduce((accGroup: any, group: any) => {
                accGroup.push({
                  defaultPrice: !group?.priceList?.id ? group.defaultPrice : null,
                  defaultPriceListId: group?.priceList?.id || null,
                  providerId: group?.provider?.id,
                  employeeId: employee?.id,
                  uniqueId: null,
                  note: null
                });
                return accGroup;
              }, []).flat(Infinity)
        // : []
      })]
      );
      return acc;
    }, []);
    
    hideModal();
    setIsCheck([]);
    setIsCheckGroup([]);
  };

  // useEffect(() => {
  //   if (isUpdateEmployeeLoadingSuccess === Loading.success) {
  //     employees.getEmployeesAction({ id: getAuthMe?.id || temporaryClientId });
  //     hideModal();
  //   }
  //
  //   return () => {
  //     getUpdateEmployeeAsyncState.reset();
  //   };
  // }, [isUpdateEmployeeLoadingSuccess]);

  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Warning</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              { choosenGroup?.id ? isSaveValues ? 'When adding a employee(s) to a group, all values will be saved.' : "When adding a user to a group, all values that are currently selected will be reassigned to the group's default value, do you really want to change them ?" : 'Are you sure want to leave the employee from group and reset values ?' }
            </p>
          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalMoveToGroupWarning = observer(ModalMoveToGroupWarningView);
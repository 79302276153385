/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { CSSProperties, useEffect } from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { ModalCalendarWindowChooseViewProps } from './types';
import s from './index.module.scss';
import { CalendarWindow } from '../../calendar';



const ModalCalendarWindowChooseView = (props: ModalCalendarWindowChooseViewProps) => {
  const {
    isOpenCalendar,
    wrapperStyles,
    setFormattedDate,
    onDateSelect,
    setIsOpenCalendar,
    choosenScedulePayment,
    minDate,
    currentDate,
    hideModal,
    calendarTitle
  } = props;

  const onSubmit = () => {

    hideModal();
  };

  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>{calendarTitle}</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <div className={s.main}>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {({ isSubmitting }) => (
                <>
                  <div className={s.calendar}>
                    <CalendarWindow
                      currentDate={currentDate}
                      minDate={minDate}
                      choosenScedulePayment={choosenScedulePayment}
                      setFormattedDate={setFormattedDate}
                      onDateSelect={onDateSelect}
                      wrapperStyles={wrapperStyles}
                      isOpenCalendar={isOpenCalendar}
                      setIsOpenCalendar={setIsOpenCalendar}
                      isSubmitting={isSubmitting}
                    />
                  </div>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Save</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>Cancel</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalCalendarWindowChoose = observer(ModalCalendarWindowChooseView);

/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toJS } from 'mobx';
import Papa from 'papaparse';
import { useStores } from 'store';
import { toast } from 'react-toastify';
import { Loading } from 'store/types';
import { Loader } from 'common/loader';
import { motion } from 'framer-motion';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { DropSVG } from 'assets/icons/drop';
import { ColorsAccent } from 'common/colors';
import { LoupeSVG } from 'assets/icons/loupe';
import { useModal } from 'hooks/useModal.hook';
import { useTranslation } from 'react-i18next';
import { ImportSVG } from 'assets/icons/import';
import { ExportSVG } from 'assets/icons/export';
import { FiltersSVG } from 'assets/icons/filters';
import { PriceListsDetail } from 'models/priceList/types';
import { Pagination } from 'components/pagination';
import { useDebounce } from 'hooks/useDebounce.hook';
import { EditLightSVG } from 'assets/icons/editLight';
import {
  CreateEmployee,
  CreateEmployeeExpand,
  CreateEmployeePhones,
  EmployeeList,
  EmployeeProviders,
  EmployeesAccountsWithEmployee,
  FormattedEmployee,
  ICreateEmployeeParsedData,
} from 'models/employees/types';
import { MakeGroupSVG } from 'assets/icons/makeGroup';
import { GetAccountData } from 'models/accounts/types';
import { DropSelectSVG } from 'assets/icons/dropselect';
import { useLocation, useNavigate } from 'react-router';
import FormikContainer from 'components/form/containers';
import FormikSelect from 'components/form/fields/select';
import { DeleteLightSVG } from 'assets/icons/deleteLight';
import { MoveToGroupSVG } from 'assets/icons/moveToGroup';
import { AnimatedDivPage } from 'components/animatedPage';
import FormikTextField from 'components/form/fields/inputs';
import FormikCheckbox from 'components/form/fields/checkbox';
import { Providers, Scenarios } from 'models/providers/types';
import { EmployeeActiveSVG } from 'assets/icons/employeeActive';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import { EmployeeInactiveSVG } from 'assets/icons/employeeInactive';
import { UniversalIconButton } from 'components/universalIconButton';
import { SwitchStatus } from 'components/employeesList/switchStatus';
import { validateImportString, validateString } from 'common/constants/validate/operatorNumber';
import { ModalImportEmployees } from 'components/modal/importEmployeesModal';
import { moreFunctionsItems, MoreFunctionsItemsIdentification } from 'common/constants/moreFunctions';
import {
  checkAccountChanges,
  csvErrors,
  EmployeesHeaderButtons,
  employeesListTitles,
  employeesSortMobile, FilterEmployees,
  HandlingPaymentFields,
  selectFilterByEmployeesStatus,
  validationHeaders
} from 'common/constants/employeesList';
import s from './index.module.scss';

export const EmployeesListView = () => {
  let { state }: any = useLocation();
  const { showModal } = useModal();
  const { auth, employees, providers, groups, accounts, priceList } = useStores();
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const { getProvidersModel } = providers;
  const { getPriceListModel } = priceList;
  const { getCreateGroupsAsyncState } = groups;
  const { getCreateAccountsAsyncState } = accounts;
  const { t } = useTranslation();
  const {
    getEmployeesModel,
    getEmployeesAsyncState,
    getAllActiveEmployeesAsyncState,
    getUpdateEmployeeAsyncState,
    getDeleteEmployeeAsyncHelper
  } = employees;
  let isEmployeesLoading = getEmployeesAsyncState?.getLoadingState;
  const isEmployeeDeletedLoading = getDeleteEmployeeAsyncHelper.getLoadingState;
  const interactiveWrapperEl = useRef<HTMLDivElement>(null);
  const [currentEmployeesItems, setCurrentEmployeesItems] = useState<EmployeeList[]>(
    [].sort((a: any, b: any) => {
      if (a?.name < b?.name) {
        return -1;
      }
      if (a?.name > b?.name) {
        return 1;
      }

      return 0;
    })
  );

  const { isTablet, isTabletAdditional } = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isCheckAll, setIsCheckAll] = useState(JSON.parse(sessionStorage.getItem('isCheckAll') || 'false') || false);

  function removeDuplicates<T, K>(data: T[], key: (key: T) => K): T[] {
    return [...new Map(data?.map((item: T) => [key(item), item])).values()];
  }

  const [isOpenIntaractiveMenu, setIsOpenIntaractiveMenu] = useState(false);
  const [isOpenEmployeeMenu, setIsOpenEmployeeMenu] = useState(false);
  const [elementsCount, setElementsCount] = useState(8);
  const isUpdateEmployeeLoadingSuccess = getUpdateEmployeeAsyncState.getLoadingState;
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [isCurrentEmployeeId, setIsCurrentEmployeeId] = useState<any[]>([]);
  const [isCheck, setIsCheck] = useState<any[]>(removeDuplicates(state?.isCheck || JSON.parse(sessionStorage.getItem('isCheck') || '[]') || [], (key: string) => key));
  const [isCheckGroup, setIsCheckGroup] = useState<any[]>(toJS(state?.isCheckGroup || JSON.parse(sessionStorage.getItem('isCheckGroup') || '[]') || []));
  const [employeeSearch, setEmployeeSearch] = useState('');
  const [employeeMobileSearch, setEmployeeMobileSearch] = useState('');
  const employeeSearchDebounce = useDebounce(employeeSearch || employeeMobileSearch, 300);
  const isCreatedGroupSuccess = getCreateGroupsAsyncState.getLoadingState;
  const [createEmployeeData, setCreateEmployeeData] = useState<CreateEmployee[]>([]);
  const [completedGroupDataCount, setCompletedGroupDataCount] = useState<number>(0);
  const [createAccountPromiseCompleted, setCreateAccountPromiseCompleted] = useState<boolean>(false);
  const [editAccountPromiseCompleted, setEditAccountPromiseCompleted] = useState<boolean>(false);
  const [isAllPromisesCompleted, setIsAllPromisesCompleted] = useState<boolean>(false);
  const [isDeletePromisesCompleted, setIsDeletePromisesCompleted] = useState<boolean>(false);
  const [isImportActive, setIsImportActive] = useState<boolean>(false);
  const [isModalButtonSubmited, setIsModalButtonSubmited] = useState<boolean>(false);
  const [existDeactivatedEmployes, setExistDeactivatedEmployes] = useState<boolean>(false);
  // const [isEmployeesActivateCompleted, setIsEmployeesActivateCompleted] = useState<boolean>(false);
  const [editEmployees, setEditEmployees] = useState<EmployeeList[]>([]);
  const [createEmployeesPromiseCompleted, setCreateEmployeesPromiseCompleted] = useState<boolean>(false);
  const { EDIT_EMPLOYEES, MAKE_GROUP, MOVE_TO_GROUP, IMPORT, EXPORT, DEACTIVATE } =
        EmployeesHeaderButtons;
  const navigate = useNavigate();
  const [isStatusFilter, setIsStatusFilter] = useState<any>('');
  const importMoreFunc = moreFunctionsItems.filter(item => item.identificator === MoreFunctionsItemsIdentification.IMPORT)[0];
  const { search } = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(search);
  const [dynamicPage, setDynamicPage] = useState<number | string>(params.get('page') || (!isTabletAdditional ? getEmployeesModel.getEmployeesMeta.page : 1));
  const [dynamicSort, setDynamicSort] = useState<number | string>(params.get('sortId') || (!isTabletAdditional ? getEmployeesModel.getEmployeesMeta.sortId : 0));
  const [dynamicFilter, setDynamicFilter] = useState<number | string>(params.get('filter') || (!isTabletAdditional ? getEmployeesModel.getEmployeesMeta.filter : 0));
  const [isEmployeeItemsSorted, setIsEmployeeItemsSorted] = useState<any>(params.get('sortId') || getEmployeesModel.getEmployeesMeta.sortId || 0);
  const [currentEmployeeStatus, setCurrentEmployeeStatus] = useState<string>('');
  const onSubmit = () => {
    const dynamicIsCheckGroup = ( editEmployees.length ? isCheckGroup.map((item: any) => {
      editEmployees.map((dataItem: EmployeeList) => {
        if (item.id === dataItem.id) {
          item.accounts = dataItem.accounts;
        }
      });
      return item;
    }) : isCheckGroup );

    navigate('/payment', {
      state: {
        isCheckGroup: removeDuplicates(dynamicIsCheckGroup?.reduce((employeeAcc: any, employeeItem: any) => {
          employeeAcc.push({
            ...employeeItem,
            accounts: employeeItem?.accounts?.reduce((accountAcc: any, accountItem: any) => {
              // Hardcode
              toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                if (provider.id === accountItem?.provider?.id) {
                  accountItem.provider = provider;
                }
              });

              accountAcc.push({
                ...accountItem,
                isDefaultValue: true,
                defaultPriceListEdit: accountItem?.defaultPriceList
                  ? accountItem?.defaultPriceList
                  : null
              });
              return accountAcc;
            }, [])
          });
          return employeeAcc;
        }, []), (key: EmployeeList) => key.id),
        isCheck,
        enableReinitializate: true,
        // providers: toJS(
        //   dynamicIsCheckGroup.reduce((acc: any[], accountsItem: any) => {
        //     if (accountsItem?.accounts?.length >= 1) {
        //       // Original
        //
        //       // acc.push(
        //       //   ...accountsItem?.accounts?.map((provider: any) => ({
        //       //     ...provider?.provider,
        //       //     employeeId: accountsItem?.id,
        //       //     uniqueId: provider?.uniqueId
        //       //   }))
        //       // );
        //
        //       // Hardcode
        //       acc.push(
        //         ...toJS(getProvidersModel.getProvidersList)?.map((provider: any) => ({
        //           ...provider,
        //           employeeId: accountsItem?.id,
        //           uniqueId: provider?.uniqueId
        //         }))
        //       );
        //     }
        //     return acc;
        //   }, [])
        // ),
        goBack: `/employees?${new URLSearchParams(window.location.search).toString()}`
      }
    });

    window.sessionStorage.setItem(
      'isCheckGroup',
      JSON.stringify(removeDuplicates(dynamicIsCheckGroup?.reduce((employeeAcc: any, employeeItem: any) => {
        employeeAcc.push({
          ...employeeItem,
          accounts: employeeItem?.accounts?.reduce((accountAcc: any, accountItem: any) => {
            accountAcc.push({
              ...accountItem,
              isDefaultValue: true,
              defaultPriceListEdit: accountItem?.defaultPriceList
                ? accountItem?.defaultPriceList
                : null
            });
            return accountAcc;
          }, [])
        });
        return employeeAcc;
      }, []), (key: EmployeeList) => key.id)
      )
    );
    window.sessionStorage.setItem('isCheck', JSON.stringify(removeDuplicates(isCheck, (key: string) => key)));
  };

  const handleSelectAll = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await setIsCheckAll(!isCheckAll);
    await employees.getEmployeesAction({
      id: getAuthMe.id,
      currentStatus: dynamicFilter.toString()
    });
    setIsCheck(toJS(getEmployeesModel.getAllActiveEmployeesList).map((li) => li.id));
    setIsCheckGroup(toJS(getEmployeesModel.getAllActiveEmployeesList));
    sessionStorage.setItem('isCheckAll', JSON.stringify(!isCheckAll));
    sessionStorage.setItem('isCheck', JSON.stringify(toJS(getEmployeesModel.getAllActiveEmployeesList).map((li) => li.id)));
    sessionStorage.setItem('isCheckGroup', JSON.stringify(toJS(getEmployeesModel.getAllActiveEmployeesList)));
    // if (!sessionStorage.getItem('isCheckAll')) {
    //   sessionStorage.setItem('isCheckAll', 'true');
    // } else {
    //   sessionStorage.setItem('isCheckAll', JSON.stringify(sessionStorage.getItem('isCheckAll')));
    // }

    // setIsCheck(toJS(getEmployeesModel.getAllActiveEmployeesList).map((li) => li.id));
    // setIsCheckGroup(toJS(getEmployeesModel.getAllActiveEmployeesList));
    //
    window.history.replaceState({}, '/employees');
    if (isCheckAll) {
      setIsCheck([]);
      setIsCheckGroup([]);
      setIsCheckAll(false);
      sessionStorage.setItem('isCheck', JSON.stringify([]));
      sessionStorage.setItem('isCheckAll', JSON.stringify(false));
      sessionStorage.setItem('isCheckGroup', JSON.stringify([]));
    }
  };

  // useEffect(() => {
  //   setIsCheckAll(JSON.parse(sessionStorage.getItem('isCheckAll') || ''));
  // }, [sessionStorage, isCheckAll]);


  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, client: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    // setIsCurrentEmployeeId(isCurrentEmployeeId.filter((item: any) => item !== id));
    setIsCheckGroup((prevState: any) => [...prevState, client]);
    sessionStorage.setItem('isCheck', JSON.stringify([...isCheck, id]));
    sessionStorage.setItem('isCheckGroup', JSON.stringify([...isCheckGroup, client]));
    // sessionStorage.setItem('isCheckAll', JSON.stringify(isCheckAll));

    window.history.replaceState({}, '/employees');
    if (!checked) {
      setIsCheckAll(false);
      setIsCheck(isCheck.filter((item) => item !== id));
      setIsCheckGroup(isCheckGroup.filter((item) => item.id !== id));
      sessionStorage.setItem('isCheck', JSON.stringify(isCheck.filter((item) => item !== id)));
      sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroup.filter((item) => item.id !== id)));
      sessionStorage.setItem('isCheckAll', JSON.stringify(false));
    }
  };

  useEffect(() => {
    // Retrieve the selection from sessionStorage
    const storedIsCheck = sessionStorage.getItem('isCheck');
    const storedIsCheckGroup = sessionStorage.getItem('isCheckGroup');
    const storedIsCheckAll = sessionStorage.getItem('isCheckAll');

    // Parse the values from sessionStorage
    const parsedIsCheck = storedIsCheck ? JSON.parse(storedIsCheck) : [];
    const parsedIsCheckGroup = storedIsCheckGroup ? JSON.parse(storedIsCheckGroup) : [];
    const parsedIsCheckAll = storedIsCheckAll ? JSON.parse(storedIsCheckAll) : false;

    // Update the component state with the retrieved values
    setIsCheck(parsedIsCheck);
    setIsCheckGroup(parsedIsCheckGroup);
    setIsCheckAll(parsedIsCheckAll);

    // ... rest of your code ...
  }, []);

  // TODO: Доделать логику с checkAll элементами и тому прочее, что бы при изменении страницы у меня оставались актуальные выбранные сотрудники. Нужно сделать через sessionStorage.

  const handleEditPages = () => {
    navigate(
      `employees-detail/${
        isCheckGroup.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          }

          if (a.id < b.id) {
            return -1;
          }

          return 0;
        })[0].id
      }`,
      {
        state: {
          isCheckGroup,
          client: toJS(getEmployeesModel.getEmployeesDetail),
          page: dynamicPage,
          sortId: dynamicSort,
          active: dynamicFilter,
          previousPageWithQuery: `${location.pathname}?${new URLSearchParams(window.location.search).toString()}`
        }
      }
    );
  };

  const handleSortItems = (e: any) => {
    // TODO: Подумать над сбросом сортировки
    // const currentSortId = e?.singleValue ? e?.value : +isEmployeeItemsSorted === 0 ? 2 : +isEmployeeItemsSorted >= 3 ? 0 : +isEmployeeItemsSorted + 1;
    const currentSortId = e?.value?.toString() || ( isEmployeeItemsSorted?.toString() === e?.asc?.toString() ? e?.desc?.toString() : e?.asc?.toString() );
    setIsEmployeeItemsSorted(currentSortId);
    setDynamicSort(currentSortId);

    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('sortId', currentSortId);
    const newUrl = `${location.pathname}?${currentParams.toString()}`;

    // Обновляем историю
    window.history.pushState({ path: newUrl }, '', newUrl);


    employees.getEmployeesAction({
      id: getAuthMe.id,
      search: employeeSearch || employeeMobileSearch,
      isAllByPagesEmployees: true,
      page: dynamicPage,
      active: dynamicFilter,
      // quantity: (toJS(getEmployeesModel.getEmployeesMeta).page || employeeSearch) !== 1 ? 0 : 1000,
      sortId: currentSortId
    });

  };

  useEffect(() => {
    window.onscroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.scrollingElement!.scrollHeight &&
        !isTabletAdditional
      ) {
        employees.getEmployeesAction({
          id: getAuthMe.id,
          search: employeeSearch || employeeMobileSearch,
          active: dynamicFilter,
          sortId: dynamicSort,
          quantity: elementsCount + 5,
          isAllByPagesEmployees: true
        }
        );
        setElementsCount(elementsCount + 5);

      }
    };
  }, [elementsCount, dynamicSort, dynamicFilter]);

  const callEmployeeDetailPage = (client: any) => {
    employees.getEmployeesDetailAction({
      clientId: getAuthMe?.id,
      employeeId: client?.id
    });
    navigate(`employees-detail/${client.id}`, { state: { client: toJS(client), page: dynamicPage, sortId: dynamicSort, active: dynamicFilter, previousPageWithQuery: `${location.pathname}?${new URLSearchParams(window.location.search).toString()}` } });
  };
  const goToGroup = (group: any) => {
    groups.getGroupsDetailAction({
      clientId: getAuthMe?.id,
      groupId: group.id
    });
    navigate(`/groups/groups-detail/${toJS(group.id)}`, { state: { previousPageWithQuery: '/employees' } });
  };

  function formatDataForCreateEmployee<T extends ICreateEmployeeParsedData>(
    data: T[],
    validPriceLists: string[]
  ): CreateEmployee[] {
    const resultMapped = new Map();
    const { getEmptyFieldsError, getOperatorIdError, getScenarioLabelError, getAllFilledFieldsError, getAmountValidateError, getScenarioFieldError, getProductIdFieldError, getCustomerNumberValidateError } = csvErrors;
    const getValidatePnoneNumber = (phone: string) => {
      if (phone.charAt(0) !== '0') {
        return `0${phone}`;
      }
      return phone;
    };

    const formattedData = data?.map((item: T, index: number): FormattedEmployee => {

      let newTrimedItem: T = item;
      let { id, operatorId, firstName, lastName } = newTrimedItem;

      // Проходим по каждому свойству объекта item
      for (const [key, value] of Object.entries(item)) {
        // Проверяем, что значение является строкой и имеет метод trim
        if (typeof value === 'string' && typeof value.trim === 'function') {
          // Применяем метод trim к значению и сохраняем обратно в новом объекте
          // @ts-ignore
          newTrimedItem[key] = value.trim();
        } else {
          // Если значение не является строкой или не имеет метода trim, сохраняем без изменений
          // @ts-ignore
          newTrimedItem[key] = value;
        }
      }

      // Validate all empty fields (amount and productId)
      if (newTrimedItem.amount === 'null' && newTrimedItem.productId === 'null' || !newTrimedItem.amount && !newTrimedItem.productId) {
        getEmptyFieldsError(`Some column has the wrong amount or productId fields, both of these fields cannot be empty. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`, moreFunctionsItems[0], navigate);
      }

      // Validate all filled fields (amount and productId)
      if ((newTrimedItem.amount !== 'null' && newTrimedItem.productId !== 'null') && (newTrimedItem.amount && newTrimedItem.productId) && newTrimedItem.amount !== '' && newTrimedItem.productId !== '') {
        getAllFilledFieldsError(`Some column has the wrong amount or productId fields, both of these fields cannot be filled. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`, moreFunctionsItems[0], navigate);
      }

      // Validate operatorId field
      if (!toJS(getProvidersModel.getProvidersList).filter((provider: Providers) => provider.id === newTrimedItem.operatorId).length) {
        getOperatorIdError(`Some column has the wrong operatorId: ( ${newTrimedItem.operatorId} ) field. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`, moreFunctionsItems[0], navigate);
      }

      // Validate scenarioLabel field
      if (!toJS(getProvidersModel.getProvidersList).filter((provider: Providers) => provider.id === newTrimedItem.operatorId)[0].scenarios.length && (newTrimedItem.scenarioLabel && newTrimedItem.scenarioLabel !== 'null' && newTrimedItem.scenarioLabel !== '')) {
        getScenarioFieldError(`Some column has the wrong scenarioLabel: ( ${newTrimedItem.scenarioLabel} ) field. This operator: ( ${newTrimedItem.operatorId} ) has no scenarios. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`, moreFunctionsItems[0], navigate);
      }

      // Validate productId field
      if (!validPriceLists.includes(newTrimedItem.productId) && newTrimedItem.productId && newTrimedItem.productId !== 'null') {
        getProductIdFieldError(`Some column has the wrong productId: ( ${newTrimedItem.productId} ) field. This operator: ( ${newTrimedItem.operatorId} ) has invalid productId field. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`, moreFunctionsItems[0], navigate);
      }

      // Formatted scenarioLabel to scenarioId + validate scenarioLabelField
      if (toJS(getProvidersModel.getProvidersList).filter((provider: Providers) => provider.scenarios.length && newTrimedItem.operatorId === provider.id).length) {
        toJS(getProvidersModel.getProvidersList).filter((provider: Providers) => provider.scenarios.length && newTrimedItem.operatorId === provider.id)?.map((provider: Providers) => {

          if (!provider.scenarios.filter((scenario: Scenarios) => scenario.label === newTrimedItem.scenarioLabel).length) {
            getScenarioLabelError(`Some column has the wrong scenarioLabel: ( ${newTrimedItem.scenarioLabel} ) field. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`, moreFunctionsItems[0], navigate);
          } else {
            newTrimedItem.scenarioLabel = provider.scenarios.filter((scenario: Scenarios) => scenario.label === newTrimedItem.scenarioLabel)[0].id;
          }
        });
      }

      const scenarioType = toJS(getProvidersModel.getProvidersList).filter((provider: Providers) => provider.id === newTrimedItem.operatorId)[0].scenarios.length ? toJS(getProvidersModel.getProvidersList).filter((provider: Providers) => provider.id === newTrimedItem.operatorId)[0].scenarios.filter((scenario: Scenarios) => scenario.id === newTrimedItem.scenarioLabel)?.[0]?.type : null;

      new HandlingPaymentFields(toJS(getProvidersModel.getProvidersList).filter((provider: Providers) => provider.id === newTrimedItem.operatorId)[0].measure, scenarioType, newTrimedItem, moreFunctionsItems[0], navigate);

      const providerItem = toJS(getProvidersModel.getProvidersList).filter((provider: Providers) => provider.id === newTrimedItem.operatorId)[0];

      // Validate amount field
      if (newTrimedItem.amount && (newTrimedItem.amount !== 'null' && newTrimedItem.amount !== '') && (newTrimedItem.amount < providerItem.minAmount || newTrimedItem.amount > providerItem.maxAmount)) {
        getAmountValidateError(`Some column has the wrong amount field, amount: ( ${Number(newTrimedItem.amount)?.toFixed(2)?.toLocaleString()} UGX ) must be greater than ${providerItem.minAmount} and less than ${providerItem.maxAmount}. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`, moreFunctionsItems[0], navigate);
      }


      const providersList = toJS(getProvidersModel.getProvidersList);
      const phoneNumberCheck = providersList.filter((provider) => provider.id === operatorId)[0]?.isPhoneNumber;

      if (id === 'null' && phoneNumberCheck) {
        id = '';
      }

      if ((id && (id !== 'null' && id !== '')) && validateImportString(id) !== 'reject' && /^\d+$/.test(id) && phoneNumberCheck) {
        id = validateImportString(id);
      }
      // Validate customer number field

      if (id && id !== '' && phoneNumberCheck) {
        if (validateImportString(id) === 'reject' || !/^\d+$/.test(id)) {
          const errorMessage = `Some column has the wrong customer number field(id), field: (${id}) the customer number must contain 10 numbers and start from 0. Name for search in .csv: ${firstName} ${lastName}`;
          getCustomerNumberValidateError(errorMessage, moreFunctionsItems[0], navigate);
        }
      }

      const defineKindOfAmount = newTrimedItem.amount !== 'null' && newTrimedItem.amount !== '' ? +newTrimedItem.amount : null;
      const defineKindOfPachage = newTrimedItem.productId !== 'null' && newTrimedItem.productId !== '' ? newTrimedItem.productId : null;
      const defineScenario = newTrimedItem.scenarioLabel !== 'null' && newTrimedItem.scenarioLabel !== '' ? newTrimedItem.scenarioLabel : null;
      const newItem: FormattedEmployee = {
        clientId: getAuthMe?.id,
        name: newTrimedItem.firstName,
        surname: newTrimedItem.lastName,
        groupId: null,
        email: null,
        phones: [
          {
            phone: newTrimedItem.phoneNumber.length === 10 ? newTrimedItem.phoneNumber : getValidatePnoneNumber(newTrimedItem.phoneNumber),
            favorite: true
          }
        ],
        accounts: [
          {
            providerId: newTrimedItem.operatorId,
            uniqueId: id,
            defaultPrice: defineKindOfAmount,
            defaultPriceListId: defineKindOfPachage,
            defaultScenarioId: defineScenario,
            note: null,
            defaultValueEmployee: true,
            phoneNumber: newTrimedItem.phoneNumber.length === 10 ? newTrimedItem.phoneNumber : getValidatePnoneNumber(newTrimedItem.phoneNumber)
          }
        ]
      };

      resultMapped.set(newItem.phones[0].phone, newItem);

      return newItem;
    });

    formattedData
      .flatMap((item: any) => item.accounts)
      .forEach((item: EmployeeProviders & { phoneNumber: string }) => {
        const checkMappedAccountsForDuplicates = !resultMapped
          .get(item.phoneNumber)
          .accounts?.map((mappedItem: any) => mappedItem.providerId)
          .includes(item.providerId);
        const isUniqueItem =
                    resultMapped.get(item.phoneNumber).phones[0].phone === item.phoneNumber &&
                    checkMappedAccountsForDuplicates;
        if (isUniqueItem) {
          resultMapped.set(resultMapped.get(item.phoneNumber).phones[0].phone, {
            ...resultMapped.get(item.phoneNumber),
            accounts: [...resultMapped.get(item.phoneNumber).accounts, item]
          });
        }
      });

    const resultCreateEmployeeData = [...resultMapped]
      .map((item: [string, FormattedEmployee]): FormattedEmployee => ({ ...item[1] }))
      .flatMap((item: FormattedEmployee): CreateEmployee[] => {
        item.accounts?.map(
          (accountItem: EmployeeProviders & { phoneNumber?: string }): EmployeeProviders => {
            delete accountItem.phoneNumber;

            return accountItem;
          }
        );
        return [{ ...item }];
      });

    return resultCreateEmployeeData;
  }

  const handleImportModal = () => {
    showModal(ModalImportEmployees);
  };

  useEffect(() => {
    priceList.getPriceListAction();
  }, []);

  const importEmployee = (file: File | null) => {
    if (file) {
      if (file.type === 'text/csv') {

        Papa.parse(file, {
          skipEmptyLines: true,
          header: true,
          async complete(results: Papa.ParseResult<ICreateEmployeeParsedData>) {
            // const parsedData = results.data.slice(0, -1);
            const parsedData = results.data;

            if (!validationHeaders(Object.keys(parsedData[0])).length) {
              if (parsedData.every(obj => Object.keys(obj).length === 8)) {
                const uniquePriceListIds = [...new Set(parsedData?.flatMap(createItem => createItem.operatorId))];
                const validPriceLists = await removeDuplicates(toJS(getPriceListModel.getPriceList)?.filter(priceListItem => uniquePriceListIds.includes(priceListItem.provider.id)), (key) => key.id)?.map(item => item.id);
                setCreateEmployeeData(formatDataForCreateEmployee<ICreateEmployeeParsedData>(parsedData, validPriceLists));
              } else {
                toast.error('Some employees have missing fields, please correct them and try importing again');
                navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
              }

            } else {
              toast.error(`Some headers have invalid titles: (${validationHeaders(Object.keys(parsedData[0])).join(', ')}), please correct them and try importing again`);
              navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
            }
          }
        });

      } else {
        toast.error('Only allowed to use files with .csv extension');

        navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
      }
    }
  };

  useEffect(() => {
  // @ts-ignore
    if (getEmployeesModel.getAllActiveEmployeesMeta?.total === isCheck.length && isCheck.length && getEmployeesModel.getAllActiveEmployeesMeta?.total) {
      setIsCheckAll(true);
      sessionStorage.setItem('isCheckAll', JSON.stringify(true));
    } else {
      setIsCheckAll(false);
      sessionStorage.setItem('isCheckAll', JSON.stringify(false));
    }
  // @ts-ignore
  }, [JSON.parse(sessionStorage.getItem('isCheck'))?.length, isCheck, getEmployeesModel.getAllActiveEmployeesMeta?.total, getAllActiveEmployeesAsyncState.getLoadingState]);
  useEffect(() => {
    if (isUpdateEmployeeLoadingSuccess === Loading.success) {
      employees.getEmployeesAction({
        id: getAuthMe.id,
        isAllByPagesEmployees: true,
        search: employeeSearch || employeeMobileSearch,
        active: dynamicFilter,
        sort: dynamicSort,
        page: dynamicPage
        // quantity: 1000
      });
    }
    return () => {
      getUpdateEmployeeAsyncState.reset();
    };
  }, [isUpdateEmployeeLoadingSuccess]);

  useEffect(() => {
    if (isCreatedGroupSuccess === Loading.success) {
      employees.getEmployeesAction({
        id: getAuthMe.id,
        isAllByPagesEmployees: true,
        search: employeeSearch || employeeMobileSearch,
        active: dynamicFilter,
        sort: dynamicSort,
        page: dynamicPage
        // quantity: 1000
      });
    }

    return () => getCreateGroupsAsyncState.reset();
  }, [isCreatedGroupSuccess]);

  const animations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  const handleOpenCreateGroup = () => {
    setIsModalOpen(true);
    showModal(Modals.ModalCreateGroup, {
      setIsModalOpen,
      isModalOpen,
      setIsCheckGroup,
      setIsCheck,
      isCheckGroup,
      isCheck
    });
  };

  const handleOpenIntaractiveMenu = (e: any) => {
    setIsOpenIntaractiveMenu(!isOpenIntaractiveMenu);
  };

  const handleOpenEmployeeMenu = (e: any, client: any) => {
    setIsOpenEmployeeMenu(!isOpenEmployeeMenu);
    setIsCurrentEmployeeId([...isCurrentEmployeeId, client.id]);

    if (isCurrentEmployeeId.includes(client.id) && !isCheckBox) {
      setIsCurrentEmployeeId(
        isCurrentEmployeeId.filter((employeeId: any) => employeeId !== client.id)
      );
    }
  };

  const handleOpenCreateMoveToGroup = () => {
    setIsModalOpen(true);
    showModal(Modals.ModalMoveToGroup, {
      setIsModalOpen,
      isModalOpen,
      fakeEmployeesContent: getEmployeesModel.getAllActiveEmployeesList,
      showModal,
      isCheckGroup,
      isCheck,
      setIsCheck,
      setIsCheckGroup
    });
  };

  const removeEmployee = () => {
    showModal(Modals.ModalEmployeeRemoveConfirmation, {
      isCheck,
      setIsCheck,
      setIsCheckAll,
      setIsCheckGroup
    });
  };

  const exportEmployee = () => {
    employees.getEmployeesExportAction({
      clientId: getAuthMe?.id,
      employeesId: isCheck.join(',')
    });
  };

  const onHandleSearch = (setter: (val: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  useEffect(() => {
    employees.getEmployeesAction(
      employeeSearch || employeeMobileSearch
        ?
        {
          id: getAuthMe.id,
          search: employeeSearch || employeeMobileSearch,
          sortId: dynamicSort,
          active: dynamicFilter,
          isAllByPagesEmployees: true,
        }
        :
        {
          id: getAuthMe.id,
          page: dynamicPage,
          sortId: dynamicSort,
          active: dynamicFilter,
          search: employeeSearch || employeeMobileSearch,
          isAllByPagesEmployees: true,
        }
    );
    employees.getEmployeesAction({
      id: getAuthMe.id,
      currentStatus: dynamicFilter.toString(),
    }
    );
    providers.getProvidersAction();
  }, [employeeSearchDebounce, dynamicPage]);

  useEffect(() => {
    if (!employeeSearch) {
      setCurrentEmployeesItems(toJS(getEmployeesModel.getEmployeesList));
    }
  }, [employeeSearch]);

  useEffect(() => {
    const isOpenInteractiveList = (e: any) =>
      interactiveWrapperEl?.current?.contains(e.target) ||
            !interactiveWrapperEl?.current?.contains(e.target) ||
            setIsOpenIntaractiveMenu(false);

    document.addEventListener('click', isOpenInteractiveList);
    return () => document.removeEventListener('click', isOpenInteractiveList);
  }, []);

  useEffect(() => {
    if (isEmployeeDeletedLoading === Loading.success) {
      employees.getEmployeesAction(
        employeeSearch || employeeMobileSearch
          ?
          {
            id: getAuthMe.id,
            search: employeeSearch || employeeMobileSearch,
            sortId: dynamicSort,
            active: dynamicFilter,
            isAllByPagesEmployees: true
            // quantity: (toJS(getEmployeesModel.getEmployeesMeta).page || employeeSearch) !== 1 ? 0 : 1000
          }
          :
          {
            id: getAuthMe.id,
            page: dynamicPage,
            sortId: dynamicSort,
            active: dynamicFilter,
            search: employeeSearch || employeeMobileSearch,
            isAllByPagesEmployees: true
            // quantity: (toJS(getEmployeesModel.getEmployeesMeta).page || employeeSearch) !== 1 ? 0 : 1000
          }
      );
    }
  }, [isEmployeeDeletedLoading]);

  // useEffect(() => {
  //   if (createEmployeeData.length) {
  //     const priceListsEditedPromiseAll = async () => {
  //       const promiseAllPriceLists: Promise<PriceListsDetail>[] = createEmployeeData.flatMap(item => item.accounts).map(item => priceList.getPriceListDetailAction({ id: item.providerId, quantity: 9999 }));
  //       const data = await Promise.all(promiseAllPriceLists);
  //       const incomingPriceLists = [...new Set(createEmployeeData.flatMap(item => item.accounts).map(item => item.defaultPriceListId))];
  //       const validPriceLists = removeDuplicates(data.flatMap(item => item.priceLists), (key) => key.id);
  //
  //       const resultValid
  //
  //     };
  //
  //     priceListsEditedPromiseAll();
  //
  //   }
  // }, [createEmployeeData]);

  useEffect(() => {
    if (createEmployeeData.length) {

      const importCalculate = () => {
        let filteredNonExistentEmployees: CreateEmployee[] = createEmployeeData.filter((item: CreateEmployee) => !toJS(getEmployeesModel.getAllEmployeesList)?.flatMap((employeItem: EmployeeList) => employeItem.phones).map((phone: any): string => phone.phone).includes(item.phones[0].phone));

        let filteredExistentEmployees: EmployeeList[] = removeDuplicates(toJS(getEmployeesModel.getAllEmployeesList)?.flatMap((item: EmployeeList) => createEmployeeData.map((employeeItem: CreateEmployeeExpand | CreateEmployee): any => {
          if (item.phones?.map((phone: CreateEmployeePhones) => phone.phone).includes(employeeItem.phones[0].phone)) {
            employeeItem.accounts = employeeItem.accounts?.map((accountItem: any): EmployeeProviders => {
              accountItem.employee = { id: item.id, name: item.name, surname: item.surname };
              return accountItem;
            });

            return item;
          }
        })).filter((itemFilter: CreateEmployee) => itemFilter !== undefined), (key: EmployeeList) => key.id)
          .flatMap((item: EmployeeList) => {
            item.accounts = item.accounts.reduce((accountAcc: EmployeesAccountsWithEmployee[], itemAccount: GetAccountData ): EmployeesAccountsWithEmployee[] => {
              accountAcc.push({
                ...itemAccount,
                employee: { id: item.id, name: item.name, surname: item.surname }
              });

              return accountAcc;
            }, []);

            return item;
          });

        const accountsEditedPromiseAll = async () => {
          const groupEditDataFunc = async () => {
            const groupEditData: any[] = removeDuplicates(filteredExistentEmployees.flatMap((employee: EmployeeList) =>
              createEmployeeData.flatMap((employeeCreateItem) =>
                employee.accounts.flatMap((accountItem: GetAccountData) =>
                  removeDuplicates(employeeCreateItem.accounts.reduce((accountCreateAcc: any[], accountCreateItem) => {
                    if (employee.phones?.map((phone: CreateEmployeePhones) => phone.phone).includes(employeeCreateItem.phones[0].phone)) {
                      if (checkAccountChanges(accountItem, accountCreateItem)) {
                        accountItem.defaultPrice = accountCreateItem.defaultPrice;
                        accountItem.uniqueId = accountCreateItem.uniqueId;

                        if (accountItem.defaultPriceList) {
                          accountItem.defaultPriceList.id = accountCreateItem.defaultPriceListId;
                        }

                        if (accountItem.defaultScenario) {
                          accountItem.defaultScenario.id = accountCreateItem.defaultScenarioId;
                        }

                        accountCreateAcc.push({
                          employeeId: employee.id,
                          name: employee.name,
                          surname: employee.surname,
                          groupId: employee.group ? employee.group.id : null,
                          phones: employee.phones,
                          accounts: employee.accounts.reduce((editAccountAcc: any[], editAccount) => {
                            editAccountAcc.push({
                              employeeId: employee.id,
                              providerId: editAccount.provider.id,
                              uniqueId: editAccount.uniqueId,
                              defaultPrice: editAccount.defaultPrice,
                              defaultPriceListId: editAccount.defaultPriceList ? editAccount.defaultPriceList.id : null,
                              defaultScenarioId: editAccount.defaultScenario ? editAccount.defaultScenario.id : null,
                              node: editAccount.note,
                              defaultValueEmployee: editAccount.defaultValueEmployee
                            });
                            return editAccountAcc;
                          }, []),
                        });
                      }
                    }
                    return accountCreateAcc;
                  }, []), (key: EmployeeProviders) => key.providerId)))), (key: any) => key.employeeId).flat(Infinity);

            const promise: Promise<EmployeeList>[] = await groupEditData?.map((editItem: EmployeeList) => employees.updateEmployeeAction(editItem, groupEditData.length));
            const data: EmployeeList[] = await Promise.all(promise);

            const onlyImportOperators = removeDuplicates(data?.flatMap(employee => createEmployeeData?.map((employeeCreateItem): EmployeeList => {
              if (employee.phones?.map((phone: CreateEmployeePhones) => phone.phone).includes(employeeCreateItem.phones[0].phone)) {
                employee = { ...employee, accounts: employee.accounts.filter((accountItem) => employeeCreateItem.accounts?.map((createAccountItem) => createAccountItem.providerId).includes(accountItem.provider.id)) };
              }
              return employee;
            }
            )), (key: EmployeeList) => key.id);

            setEditEmployees(onlyImportOperators);
            setEditAccountPromiseCompleted(true);
          };

          groupEditDataFunc();

        };

        const filteredExistentEmployeesOnlyImport = removeDuplicates(filteredExistentEmployees?.flatMap(employee => createEmployeeData?.map((employeeCreateItem): EmployeeList => {
          if (employee.phones?.map((phone: CreateEmployeePhones) => phone.phone).includes(employeeCreateItem.phones[0].phone)) {
            employee = { ...employee, accounts: employee.accounts.filter((accountItem) => employeeCreateItem.accounts?.map((createAccountItem) => createAccountItem.providerId).includes(accountItem.provider.id)) };
          }
          return employee;
        }
        )), (key: EmployeeList) => key.id);

        const accountsCreatedPromiseAll = async () => {
          const promise: Promise<GetAccountData>[] = createEmployeeData.filter((createEmployeeFiltered: any) => createEmployeeFiltered?.accounts[0]?.employee?.id)?.flatMap((createEmployee: CreateEmployeeExpand | CreateEmployee) => createEmployee.accounts).reduce((createAccountAcc: any[], createAccount: EmployeeProviders | any ) => {
            if (!filteredExistentEmployeesOnlyImport.filter((employeeInner) => employeeInner?.id === createAccount?.employee?.id)?.flatMap((employee) => employee.accounts)?.map((accountInner) => accountInner.provider.id).includes(createAccount.providerId)) {
              createAccountAcc.push(accounts.createAccountsAction({
                employeeId: createAccount.employee.id,
                providerId: createAccount.providerId,
                uniqueId: createAccount.uniqueId,
                defaultPrice: createAccount.defaultPrice,
                defaultPriceListId: createAccount.defaultPriceListId,
                defaultScenarioId: createAccount.defaultScenarioId,
                note: createAccount.note,
                defaultValueEmployee: createAccount.defaultValueEmployee
              }));
            }
            return createAccountAcc;
          }, []);

          const data: GetAccountData[] = await Promise.all(promise);

          filteredExistentEmployeesOnlyImport?.map((employee: any) => {
            data?.map((response: GetAccountData) => {
              if (response.employee.id === employee.id) {
                employee.accounts.push(response);
              }
            });
          });

          await setIsCheckGroup(removeDuplicates([...isCheckGroup, ...filteredExistentEmployeesOnlyImport], (key: EmployeeList) => key.id));
          await setIsCheck(removeDuplicates([...isCheck, ...filteredExistentEmployeesOnlyImport?.map((item: EmployeeList) => item.id)], (key: string) => key));
          await setCompletedGroupDataCount(removeDuplicates([...isCheck, ...filteredExistentEmployeesOnlyImport?.map((item: EmployeeList) => item.id)], (key: string) => key).length);
          setCreateAccountPromiseCompleted(true);
        };

        const promises = [];

        if (filteredExistentEmployeesOnlyImport.length) {
          promises.push(accountsCreatedPromiseAll());
          promises.push(accountsEditedPromiseAll());
          // accountsCreatedPromiseAll();
          // accountsEditedPromiseAll();
        }

        let incorrectProvidersIdWarning: boolean = false;
        const checkProviderIdCorrect = (itemOfEmployee: CreateEmployee): boolean => {
          const checkItems = itemOfEmployee.accounts?.map((account: EmployeeProviders) => toJS(getProvidersModel.getProvidersList)?.map((item: Providers) => item.id).includes(account.providerId)).every((item: boolean) => item === true);

          if (!checkItems) {
            incorrectProvidersIdWarning = true;
          }
          return checkItems;
        };

        if (filteredNonExistentEmployees.length) {
          const allPromisesCompleted = async (): Promise<void> => {
            const promise: Promise<EmployeeList>[] = filteredNonExistentEmployees.filter((employeeItem: CreateEmployee) => checkProviderIdCorrect(employeeItem))?.map(
              (itemOfEmployee: CreateEmployee): Promise<EmployeeList> => employees.createEmployeesAction(
                itemOfEmployee,
                filteredNonExistentEmployees.length
              )
            );

            if (incorrectProvidersIdWarning) {
              toast.warning('Some employees have invalid operatorName fields at .csv file, they will not be created and will not get into the payment table');
            }

            const data: EmployeeList[] = await Promise.all(promise);
            await setIsCheckGroup(removeDuplicates([...isCheckGroup, ...filteredExistentEmployeesOnlyImport, ...data], (key: EmployeeList) => key.id));
            await setIsCheck(removeDuplicates([...isCheck, ...filteredExistentEmployeesOnlyImport?.map((item: EmployeeList) => item.id), ...data?.map((item: EmployeeList) => item.id)], (key: string) => key));
            await setCompletedGroupDataCount(removeDuplicates([...isCheck, ...filteredExistentEmployeesOnlyImport?.map((item: EmployeeList) => item.id), ...data?.map((item: EmployeeList) => item.id)], (key: string) => key).length);
            setCreateEmployeesPromiseCompleted(true);
          };
          promises.push(allPromisesCompleted());
          // allPromisesCompleted();
        }

        Promise.all(promises).then(() => {
          setIsAllPromisesCompleted(true);
        }).catch((err) => {
          setIsImportActive(false);
        });
      };

      let filteredExistentDeactivatedEmployees: EmployeeList[] = removeDuplicates(toJS(getEmployeesModel.getAllEmployeesList).filter((item: EmployeeList) => {
        if (item.deletedAt && createEmployeeData.some((employeeItem: CreateEmployeeExpand | CreateEmployee) => employeeItem.phones.some((phone: CreateEmployeePhones) => phone?.phone === item.phones?.[0]?.phone))) {
          return true;
        }
        return false;
      }), (key: EmployeeList) => key.id);

      const importActivateEmployes = () => {
        setExistDeactivatedEmployes(true);
        showModal(Modals.ModalEmployeeActivateConfirmation, {
          isCheck,
          setIsCheck,
          setIsCheckAll,
          setIsCheckGroup,
          removeDuplicates,
          filteredExistentDeactivatedEmployees,
          setIsModalButtonSubmited,
          employees,
          employeeSearch,
          employeeMobileSearch,
          dynamicSort,
          dynamicFilter,
          dynamicPage,
          setIsImportActive,
          setIsDeletePromisesCompleted,
          importCalculate
        });
      };

      if (filteredExistentDeactivatedEmployees?.length) {
        importActivateEmployes();
      } else {
        importCalculate();
      }
    }

    return () => {
      setCreateEmployeeData([]);
      setCompletedGroupDataCount(0);
      getCreateAccountsAsyncState.reset();
    };
  }, [createEmployeeData.length, isDeletePromisesCompleted]);

  useEffect(() => {
    const deactivatedEmployeesExists = existDeactivatedEmployes ? isModalButtonSubmited : true;
    if (
      // (isCreateEmployeesCompleted && isCreateAccountCompleted) ||
      // (isCreateEmployeesCompleted && isEditAccountCompleted) ||
      // (isCreateAccountCompleted && isEditAccountCompleted) ||
      // (!isCreateEmployeesCompleted && isEditAccountCompleted) ||
      // (!isCreateAccountCompleted && isEditAccountCompleted) ||
      // (!isCreateAccountCompleted && !isCreateEmployeesCompleted && isEditAccountCompleted)
      isAllPromisesCompleted && deactivatedEmployeesExists
    ) {
      onSubmit();
      // setIsImportActive(false);
    }

    return () => {
      getCreateAccountsAsyncState.reset();
      setCreateEmployeesPromiseCompleted(false);
      setCreateAccountPromiseCompleted(false);
      setEditAccountPromiseCompleted(false);
    };
  }, [createEmployeesPromiseCompleted, createAccountPromiseCompleted, editAccountPromiseCompleted, isModalButtonSubmited, isAllPromisesCompleted, existDeactivatedEmployes]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      setIsCheck([]);
      setIsCheckGroup([]);
      setIsCheckAll(false);
      setCompletedGroupDataCount(0);
      setCreateEmployeeData([]);
      window.sessionStorage.removeItem('isCheckGroup');
      window.sessionStorage.removeItem('isCheck');
      window.sessionStorage.removeItem('isCheckAll');

      // TODO: Протестировать обновление состояния
      const currentParams = new URLSearchParams(window.location.search);
      const newUrl = `${location.pathname}${currentParams.toString() ? '?' : ''}${currentParams.toString()}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
      // navigate(`${location.pathname}?${params.toString()}`, {
      //   state: {}
      // });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (state?.importFile) {
      setIsImportActive(true);
      const fetchDataAndImport = async () => {
        // Сначала выполняем запрос
        await employees.getEmployeesAction({
          id: getAuthMe.id,
          currentStatus: FilterEmployees.ALL_NO_PAGE.toString(),
        });

        // Затем вызываем функцию importEmployee
        importEmployee(state.importFile);
      };
      fetchDataAndImport(); // Вызываем асинхронную функцию
    }

  }, [state]);

  useEffect(() => {
    if (!location.pathname.includes('employees') && !location.pathname.includes('employees-detail') && !location.pathname.includes('payment')) {
      setIsCheck([]);
      setIsCheckGroup?.([]);
      setIsCheckAll(false);
      window.sessionStorage.removeItem('isCheckGroup');
      window.sessionStorage.removeItem('isCheck');
      window.sessionStorage.removeItem('isCheckAll');
    }
  }, [location.pathname]);

  const handleSelectFilter = (e: any) => {
    setDynamicFilter(e?.value);
    params.set('filter', e?.value);
    navigate(`${location.pathname}?${params.toString()}`);
    // window.history.pushState(null, '', `${search ? `${search}&filter=${e?.value}` : `?filter=${e?.value}` }`);
    employees.getEmployeesAction({
      id: getAuthMe.id,
      search: employeeSearch || employeeMobileSearch,
      isAllByPagesEmployees: true,
      page: dynamicPage,
      active: e?.value,
      sortId: dynamicSort
    });
  };

  useEffect(() => {
    const checkStatuses = {
      0: t<string>('MAIN.EMPLOYEES.CONTROLPANEL.ACTIVATE'),
      1: t<string>('MAIN.EMPLOYEES.CONTROLPANEL.DEACTIVATE'),
      2: t<string>('MAIN.EMPLOYEES.CONTROLPANEL.BOTHSTATUSES'),
    };

    setCurrentEmployeeStatus(checkStatuses[isCheckGroup.filter((item: any) => item.deletedAt).length && isCheckGroup.filter((item: any) => !item.deletedAt).length ? 2 : isCheckGroup.filter((item: any) => item.deletedAt).length ? 0 : isCheckGroup.filter((item: any) => !item.deletedAt).length ? 1 : 1 ]);
  }, [isCheckGroup]);

  return (
        <section className={s.wrapper}>
          {
            isImportActive ?
              <Loader loaderWrapperStyles={{ height: '100%', flexDirection: 'column', position: 'absolute', zIndex: '10', backdropFilter: 'blur(2px)' }} loaderStyles={{
                width: '5rem',
                height: '5rem',
                animationDuration: '1s',
                border: 'none',
                borderTop: ' 4px solid #ffca00',
                borderBottom: '4px solid rgba(26, 26, 26, 0.2)',
                marginBottom: '0.4rem'
              }}>
                <p style={{ fontSize: '1rem' }}>Loading...</p>
              </Loader>
              :
              null
            }

            <AnimatedDivPage transition={{ duration: 0.5 }} animations={animations}>
                <div className={s.wrapper__container}>
                    <FormikContainer onSubmit={onSubmit} initialValues={{ sort: employeesSortMobile(t).filter(item => +dynamicSort === item.value).length ? employeesSortMobile(t).filter(item => +dynamicSort === item.value)[0] : employeesSortMobile(t)[0], filter: selectFilterByEmployeesStatus(t).filter(item => +dynamicFilter === item.value).length ? selectFilterByEmployeesStatus(t).filter(item => +dynamicFilter === item.value)[0] : selectFilterByEmployeesStatus(t)[0] }}>
                        {() => (
                            <>
                                <div className={s.wrapper__title}>
                                    <div className={s.wrapper__title_main}>
                                        <h1 className={s.title}>{t<string>('MAIN.EMPLOYEES.TITLE')}</h1>

                                        <div className={s.list__main__buttons}>
                                            {/* <EmployeesListFilters t={t} handleSelectFilter={handleSelectFilter} /> */}
                                          { isTabletAdditional && <SwitchStatus setIsCheck={setIsCheck} setIsCheckGroup={setIsCheckGroup} setCompletedGroupDataCount={setCompletedGroupDataCount} setCreateEmployeeData={setCreateEmployeeData} setIsCheckAll={setIsCheckAll} setDynamicPage={setDynamicPage} navigate={navigate} params={params} location={location} setDynamicFilter={setDynamicFilter} employees={employees} id={getAuthMe.id} search={employeeSearch || employeeMobileSearch} dynamicPage={dynamicPage.toString()} dynamicSort={dynamicSort.toString()} dynamicFilter={dynamicFilter.toString()} />}
                                            <div className={s.create}>
                                                <motion.button
                                                    whileTap={{ scale: 0.95 }}
                                                    whileHover={{ scale: 1.05 }}
                                                    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                                                    onClick={() => navigate('create-employee')}
                                                    className={s.create__button}
                                                    type="button"
                                                >
                                                    <p>{t<string>('MAIN.EMPLOYEES.BUTTONS.CREATE')}</p>
                                                </motion.button>
                                            </div>

                                            <div className={s.payment}>
                                                <motion.button
                                                    whileTap={isCheck?.length && !isCheckGroup.filter((item: any) => item.deletedAt).length ? { scale: 0.95 } : {}}
                                                    whileHover={isCheck?.length && !isCheckGroup.filter((item: any) => item.deletedAt).length ? { scale: 1.05 } : {}}
                                                    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                                                    className={`${s.payment__button} ${
                                                      isCheck?.length && !isCheckGroup.filter((item: any) => item.deletedAt).length ? s.payment__button_active : ''
                                                    }`}
                                                    disabled={isCheck?.length === 0 || !!isCheckGroup.filter((item: any) => item.deletedAt).length}
                                                    type="submit"
                                                >
                                                    <p>{t<string>('MAIN.EMPLOYEES.BUTTONS.PAYMENT')}</p>
                                                </motion.button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* { */}
                                    {/*  !isTabletAdditional ? */}
                                    {/*    <EmployeesListFilters t={t} handleSelectFilter={handleSelectFilter} /> */}
                                    {/*    : */}
                                    {/*    null */}
                                    {/* } */}

                                    <div className={s.search__mobile}>
                                        <FormikTextField
                                            placeholder={t<string>('MAIN.EMPLOYEES.SEARCH.PLACEHOLDER')}
                                            name="search"
                                            value={employeeSearch}
                                            id={'employeeSearch'}
                                            onChange={onHandleSearch(setEmployeeMobileSearch)}
                                        >
                                            <div className={s.search__icon}>
                                                <LoupeSVG/>
                                            </div>
                                        </FormikTextField>
                                    </div>

                                    <div className={s.services__sort_mobile}>
                                        <div className={s.operators__sort}>
                                            <FormikSelect
                                                valueContainer={{ paddingLeft: '.5rem' }}
                                                wrapperSelectStyles={{
                                                  width: '11rem',
                                                  maxWidth: '100%',
                                                  minWidth: '100%'
                                                }}
                                                selectWrapperStyles={{ width: '100%' }}
                                                options={employeesSortMobile(t)}
                                                name="sort"
                                                placeholder={t<string>('MAIN.EMPLOYEES.MOBILESORT.PLACEHOLDER')}
                                                indicatorIcon={<DropSelectSVG/>}
                                                onChange={handleSortItems}
                                            />
                                        </div>
                                      {!isTabletAdditional &&
                                          <div className={s.switch___mobile}>
                                              <SwitchStatus setIsCheck={setIsCheck} setIsCheckGroup={setIsCheckGroup} setCompletedGroupDataCount={setCompletedGroupDataCount} setCreateEmployeeData={setCreateEmployeeData} setIsCheckAll={setIsCheckAll} stylesWrapper={{ marginLeft: '1rem', marginRight: '0', height: '46px' }} stylesCircle={{ height: '36px', width: '36px' }} navigate={navigate} params={params} location={location} setDynamicFilter={setDynamicFilter} employees={employees} id={getAuthMe.id} search={employeeSearch || employeeMobileSearch} dynamicPage={dynamicPage?.toString()} dynamicSort={dynamicSort?.toString()} dynamicFilter={dynamicFilter?.toString()} />
                                          </div>
                                      }
                                    </div>
                                </div>

                                <div className={s.search}>
                                    <FormikTextField
                                        placeholder={t<string>('MAIN.EMPLOYEES.SEARCH.PLACEHOLDER')}
                                        name="search"
                                        value={employeeSearch}
                                        id={'employeeSearch'}
                                        onChange={onHandleSearch(setEmployeeSearch)}
                                    >
                                        <div className={s.search__icon}>
                                            <LoupeSVG/>
                                        </div>
                                    </FormikTextField>
                                </div>

                                <div className={s.list}>
                                    <div className={s.list__header}>
                                        <FormikCheckbox
                                            labelStyles={{
                                              borderRadius: '4px',
                                              backgroundColor: ColorsAccent.accentYellow,
                                              marginRight: '0.3rem'
                                            }}
                                            label={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.CHECKALL')}
                                            title={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.CHECKALL')}
                                            name="checkAll"
                                            id="checkAll"
                                            checkboxInnerItem={getAllActiveEmployeesAsyncState.getLoadingState === Loading.load ? <Loader loaderWrapperStyles={{ width: 'fit-content', height: 'fit-content', marginRight: '0.75rem' }} loaderStyles={{ width: '24px', height: '24px', borderWidth: '4px', borderTopWidth: '4px' }} /> : null}
                                            onChange={handleSelectAll}
                                            isChecked={isCheckAll}
                                            groupCheckbox
                                        />

                                        <div className={s.list__delimetr}/>

                                        <div
                                            className={[s.list__tabs, isCheck?.length && !isCheckGroup.filter((item: any) => item.deletedAt).length ? s.list__tab_active : s.list__tab_passive, isCheck?.length && isCheckGroup.filter((item: any) => item.deletedAt).length ? s.list__tab_status : null ].join(' ')}
                                        >
                                            <div className={`${s.list__tab} ${s.list__tab_deactivate_fill}`}>
                                                <UniversalIconButton
                                                    iconStyles={{ marginRight: '0.3rem' }}
                                                    onClick={handleEditPages}
                                                    type="button"
                                                    id={EDIT_EMPLOYEES}
                                                    icon={<EditLightSVG/>}
                                                    text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.EDIT')}
                                                />
                                            </div>
                                            <div className={s.list__tab}>
                                                <UniversalIconButton
                                                    iconStyles={{ marginRight: '0.3rem' }}
                                                    onClick={handleOpenCreateGroup}
                                                    type="button"
                                                    id={MAKE_GROUP}
                                                    icon={<MakeGroupSVG/>}
                                                    text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.CREATEGROUP')}
                                                />
                                            </div>
                                            <div className={s.list__tab}>
                                                <UniversalIconButton
                                                    iconStyles={{ marginRight: '0.3rem' }}
                                                    onClick={handleOpenCreateMoveToGroup}
                                                    type="button"
                                                    id={MOVE_TO_GROUP}
                                                    icon={<MoveToGroupSVG/>}
                                                    text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.MOVEGROUP')}
                                                />
                                            </div>
                                            <div className={`${s.list__tab} ${s.list__tab_import}`}>
                                                <UniversalIconButton
                                                    iconStyles={{ marginRight: '0.3rem' }}
                                                    onClick={() => navigate(`/functions/functions-detail/${importMoreFunc.path}`, { state: { moreFunc: importMoreFunc } }) }
                                                    type="button"
                                                    id={IMPORT}
                                                    icon={<ImportSVG/>}
                                                    text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.IMPORT')}
                                                />
                                            </div>
                                            <div className={`${s.list__tab} ${s.list__tab_deactivate}`}>
                                                <UniversalIconButton
                                                    iconStyles={{ marginRight: '0.3rem' }}
                                                    onClick={exportEmployee}
                                                    type="button"
                                                    id={EXPORT}
                                                    icon={<ExportSVG/>}
                                                    text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.EXPORT')}
                                                />
                                            </div>
                                            <div className={`${s.list__tab} ${s.list__tab_deactivate}`}>
                                                <UniversalIconButton
                                                    iconStyles={{ marginRight: '0.3rem' }}
                                                    onClick={removeEmployee}
                                                    type="button"
                                                    id={DEACTIVATE}
                                                    icon={<DeleteLightSVG/>}
                                                    text={currentEmployeeStatus}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.list__header_dropdown}>
                                        <div className={s.dropdown__header} onClick={handleOpenIntaractiveMenu}>
                                            <div className={s.header__left}>
                                                <div className={s.header__icon}>
                                                    <FiltersSVG/>
                                                </div>

                                                <div className={s.header__title}>
                                                    <p>
                                                        {t<string>('MAIN.EMPLOYEES.CONTROLPANEL.MOBILEDROPDOWNHEADER')} (
                                                        {isCheck.length})
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                style={isOpenIntaractiveMenu ? { transform: 'rotate(180deg)' } : {}}
                                                className={s.header__icon_drop}
                                            >
                                                <DropSVG/>
                                            </div>
                                        </div>

                                        <motion.div
                                            initial="hidden"
                                            animate={isOpenIntaractiveMenu ? 'open' : 'hidden'}
                                            variants={{
                                              hidden: { height: '0' },
                                              open: { height: '100%' }
                                            }}
                                            transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                                            className={s.interactive__list}
                                        >
                                            <div className={s.interactive__inner}>
                                                <FormikCheckbox
                                                    labelStyles={{
                                                      borderRadius: '4px',
                                                      backgroundColor: ColorsAccent.accentYellow
                                                    }}
                                                    label={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.CHECKALL')}
                                                    title={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.CHECKALL')}
                                                    name="checkAll"
                                                    id="checkAll"
                                                    checkboxInnerItem={getAllActiveEmployeesAsyncState.getLoadingState === Loading.load ? <Loader loaderWrapperStyles={{ width: 'fit-content', height: 'fit-content', marginRight: '0.75rem' }} loaderStyles={{ width: '24px', height: '24px', borderWidth: '4px', borderTopWidth: '4px' }} /> : null}
                                                    onChange={handleSelectAll}
                                                    isChecked={isCheckAll}
                                                    groupCheckbox
                                                />

                                                <div
                                                  className={[s.list__tabs, isCheck?.length && !isCheckGroup.filter((item: any) => item.deletedAt).length ? s.list__tab_active : s.list__tab_passive, isCheck?.length && isCheckGroup.filter((item: any) => item.deletedAt).length ? s.list__tab_status : null ].join(' ')}
                                                >
                                                    <div className={s.list__tab}>
                                                        <UniversalIconButton
                                                            iconStyles={{ marginRight: '0.3rem' }}
                                                            onClick={handleEditPages}
                                                            type="button"
                                                            id={EDIT_EMPLOYEES}
                                                            icon={<EditLightSVG/>}
                                                            text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.EDIT')}
                                                        />
                                                    </div>
                                                    <div className={s.list__tab}>
                                                        <UniversalIconButton
                                                            iconStyles={{ marginRight: '0.3rem' }}
                                                            onClick={handleOpenCreateGroup}
                                                            type="button"
                                                            id={MAKE_GROUP}
                                                            icon={<MakeGroupSVG/>}
                                                            text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.CREATEGROUP')}
                                                        />
                                                    </div>
                                                    <div className={s.list__tab}>
                                                        <UniversalIconButton
                                                            iconStyles={{ marginRight: '0.3rem' }}
                                                            onClick={handleOpenCreateMoveToGroup}
                                                            type="button"
                                                            id={MOVE_TO_GROUP}
                                                            icon={<MoveToGroupSVG/>}
                                                            text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.MOVEGROUP')}
                                                        />
                                                    </div>
                                                    <div className={`${s.list__tab} ${s.list__tab_import}`}>
                                                        <UniversalIconButton
                                                            iconStyles={{ marginRight: '0.3rem' }}
                                                            onClick={() => navigate(`/functions/functions-detail/${importMoreFunc.path}`, { state: { moreFunc: importMoreFunc } }) }
                                                            type="button"
                                                            id={IMPORT}
                                                            icon={<ImportSVG/>}
                                                            text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.IMPORT')}
                                                        />
                                                    </div>
                                                    <div className={s.list__tab}>
                                                        <UniversalIconButton
                                                            iconStyles={{ marginRight: '0.3rem' }}
                                                            onClick={exportEmployee}
                                                            type="button"
                                                            id={EXPORT}
                                                            icon={<ExportSVG/>}
                                                            text={t<string>('MAIN.EMPLOYEES.CONTROLPANEL.EXPORT')}
                                                        />
                                                    </div>
                                                    <div className={`${s.list__tab} ${s.list__tab_deactivate}`}>
                                                        <UniversalIconButton
                                                            iconStyles={{ marginRight: '0.3rem' }}
                                                            onClick={removeEmployee}
                                                            type="button"
                                                            id={DEACTIVATE}
                                                            icon={<DeleteLightSVG/>}
                                                            text={currentEmployeeStatus}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </motion.div>
                                    </div>

                                    <div className={s.content}>
                                        {(currentEmployeesItems?.length
                                          ? currentEmployeesItems
                                          : toJS(getEmployeesModel.getEmployeesList)
                                        ).length >= 1 ? (
                                            <div className={s.content__header}>
                                                {employeesListTitles(t).map((titles) => (
                                                    <div key={titles.id} className={s.content__header_item}>
                                                        <button
                                                            disabled={!titles?.icon}
                                                            onClick={() => handleSortItems(titles)}
                                                            type="button"
                                                            style={{ marginLeft: titles.data === 'name' ? '-3rem' : titles.data === 'surname' ? '-3rem' : titles.data === 'group' ? '-3rem' : titles.data === 'phone' ? '-3rem' : 0 }}
                                                            className={[s.item].join(' ')}
                                                        >
                                                            <p>{titles.name}</p>
                                                            {titles.icon}
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                          ) : null}

                                        <div className={s.content__main}>
                                            {isTabletAdditional ? (
                                              isEmployeesLoading === Loading.success ? (
                                                (currentEmployeesItems?.length
                                                  ? currentEmployeesItems
                                                  : toJS(getEmployeesModel.getEmployeesList)
                                                ).length >= 1 ? (
                                                    (currentEmployeesItems?.length
                                                      ? currentEmployeesItems
                                                      : toJS(getEmployeesModel.getEmployeesList)
                                                    )?.map((client: any, i: number) => (
                                                            <motion.div
                                                                initial="hidden"
                                                                animate="visible"
                                                                variants={{
                                                                  hidden: { opacity: 0, x: -50 },
                                                                  visible: {
                                                                    pathLength: 1,
                                                                    opacity: 1,
                                                                    transition: {
                                                                      delay: i * 0.05,
                                                                      duration: 0.4,
                                                                      type: 'spring',
                                                                      damping: 10
                                                                    },
                                                                    x: 0
                                                                  }
                                                                }}
                                                                transition={{ duration: 0.7 }}
                                                                key={client.id}
                                                                className={[s.content__items, client.deletedAt ? s.deactivated : null ].join(' ')}
                                                            >
                                                                {employeesListTitles(t).map((title: any) => (
                                                                    <div
                                                                        className={s.content__items__inner}
                                                                        key={`${client.id}${title.id}`}
                                                                    >
                                                                        <div className={s.content__item}>
                                                                          {/* <div className={[s.item__status, client.deletedAt ? s.item__deactive : s.item__active].join(' ')} /> */}
                                                                          {title.data === 'status' ? (
                                                                                <FormikCheckbox
                                                                                    wrapperStyles={{ width: 'fit-content' }}
                                                                                    labelStyles={{
                                                                                      borderRadius: '4px',
                                                                                      backgroundColor: ColorsAccent.accentYellow,
                                                                                      minWidth: '1.5rem',
                                                                                      maxWidth: '1.5rem'
                                                                                      // cursor: client.deletedAt ? 'default' : 'pointer'
                                                                                    }}
                                                                                    icon={client?.deletedAt ? <EmployeeInactiveSVG /> : <EmployeeActiveSVG />}
                                                                                    // disabled={client.deletedAt}
                                                                                    label={client.name}
                                                                                    id={client.id}
                                                                                    checkboxInnerItem={getAllActiveEmployeesAsyncState.getLoadingState === Loading.load ? <Loader loaderWrapperStyles={{ width: 'fit-content', height: 'fit-content' }} loaderStyles={{ width: '24px', height: '24px', borderWidth: '4px', borderTopWidth: '4px' }} /> : null}
                                                                                    // title={client.name}
                                                                                    name={`${client.id}`}
                                                                                    isChecked={isCheck.includes(client.id)}
                                                                                    groupCheckbox
                                                                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                                      handleCheck(e, toJS(client))
                                                                                    }
                                                                                />

                                                                          ) : null}

                                                                          {title.data === 'name' ? (
                                                                            <div className={s.name__wrapper}>
                                                                              <p>{client?.name || '----'}</p>
                                                                            </div>
                                                                          ) : null}

                                                                          {title.data === 'surname' ? (
                                                                            <div className={s.surname__wrapper}>
                                                                              <p>{client?.surname || '----'}</p>
                                                                            </div>
                                                                          ) : null}

                                                                            {title.data === 'group' ? (
                                                                                <button
                                                                                    disabled={client.deletedAt}
                                                                                    className={s.redirect__button}
                                                                                    onClick={() => client?.group?.title ? goToGroup(client?.group) : null}
                                                                                    type="button"
                                                                                >
                                                                                    <p>{client?.group?.title || '----'}</p>
                                                                                </button>
                                                                            ) : null}

                                                                            {title.data === 'phone' ? (
                                                                              client.phones?.filter((item: any) => item?.favorite)
                                                                                ?.length ? (
                                                                                    <div className={s.phone__wrapper}>
                                                                                      <p>
                                                                                        +256
                                                                                        {
                                                                                            client.phones?.filter(
                                                                                              (item: any) => item?.favorite
                                                                                            )[0]?.phone
                                                                                        }
                                                                                      </p>
                                                                                    </div>
                                                                                ) : (
                                                                                    <p>----</p>
                                                                                )
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <button
                                                                    type={'button'}
                                                                    // disabled={client.deletedAt}
                                                                    onClick={() => callEmployeeDetailPage(client)}
                                                                    className={s.content__items_icon}
                                                                >
                                                                    <EditLightSVG/>
                                                                </button>
                                                            </motion.div>
                                                    ))
                                                  ) : (
                                                        <h2 className={s.item__title_empty}>There are no employees</h2>
                                                  )
                                              ) : (
                                                    <div className={s.main__loader}>
                                                        <Loader
                                                            loaderStyles={{
                                                              width: '2.5rem',
                                                              height: '2.5rem',
                                                              borderTopWidth: '4px',
                                                              borderWidth: '4px'
                                                            }}
                                                        />
                                                    </div>
                                              )
                                            ) :
                                              // isEmployeesLoading === Loading.success ? (
                                              currentEmployeesItems.length >= 1 ? (
                                                currentEmployeesItems?.map((client: any, i: number) => (
                                                        <motion.div
                                                            initial="hidden"
                                                            animate="visible"
                                                            variants={{
                                                              hidden: { opacity: 0, x: -50 },
                                                              visible: {
                                                                pathLength: 1,
                                                                opacity: 1,
                                                                borderRadius: isCurrentEmployeeId.includes(client.id)
                                                                  ? '1.25rem'
                                                                  : '.5rem',
                                                                transition: {
                                                                  delay: ( i > 9 ? 0 : i ) * 0.05,
                                                                  duration: 0.4,
                                                                  type: 'spring',
                                                                  damping: 10
                                                                },
                                                                x: 0
                                                              }
                                                            }}
                                                            transition={{ duration: 0.7 }}
                                                            key={client.id}
                                                            className={s.content__items_mobile}
                                                            onClick={(e: any) => handleOpenEmployeeMenu(e, client)}
                                                        >
                                                            <div className={s.content__header}>
                                                                <div className={s.content__item}>
                                                                  {/* <div className={[s.item__status, client.deletedAt ? s.item__deactive : s.item__active].join(' ')} /> */}
                                                                    {isCurrentEmployeeId.includes(client.id) ? (
                                                                        <motion.h5
                                                                            initial="initial"
                                                                            variants={{
                                                                              initial: { height: 'fit-content' }
                                                                            }}
                                                                            transition={{
                                                                              duration: 0.3,
                                                                              type: 'spring',
                                                                              damping: 18
                                                                            }}
                                                                            className={s.name}
                                                                        >
                                                                            {t<string>('MAIN.EMPLOYEES.LIST.HEADERMOBILE.NAME')}
                                                                        </motion.h5>
                                                                    ) : null}
                                                                    <FormikCheckbox
                                                                        wrapperStyles={{ width: 'fit-content' }}
                                                                        labelStyles={{
                                                                          borderRadius: '4px',
                                                                          backgroundColor: ColorsAccent.accentYellow,
                                                                          minWidth: '1.5rem',
                                                                          maxWidth: '1.5rem'
                                                                        }}
                                                                        icon={client?.deletedAt ? <EmployeeInactiveSVG /> : <EmployeeActiveSVG />}
                                                                        label={client.name}
                                                                        id={client.id}
                                                                        title={client.name}
                                                                        name={`${client.id}`}
                                                                        isChecked={isCheck.includes(client.id)}
                                                                        groupCheckbox
                                                                        checkboxInnerItem={getAllActiveEmployeesAsyncState.getLoadingState === Loading.load ? <Loader loaderWrapperStyles={{ width: 'fit-content', height: 'fit-content' }} loaderStyles={{ width: '24px', height: '24px', borderWidth: '4px', borderTopWidth: '4px' }} /> : null}
                                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                          e.stopPropagation();
                                                                          handleCheck(e, toJS(client));
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className={s.icons}>
                                                                    <div
                                                                        onClick={(e: any) => {
                                                                          e.stopPropagation();
                                                                          callEmployeeDetailPage(client);
                                                                        }}
                                                                        className={s.content__items_icon}
                                                                    >
                                                                        <EditLightSVG/>
                                                                    </div>

                                                                    <div
                                                                        style={
                                                                            isCurrentEmployeeId.includes(client.id)
                                                                              ? { transform: 'rotate(180deg)' }
                                                                              : {}
                                                                        }
                                                                        className={s.icon_drop}
                                                                    >
                                                                        <DropSVG/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <motion.div
                                                                initial="hidden"
                                                                animate={
                                                                    isCurrentEmployeeId.includes(client.id) ? 'open' : 'hidden'
                                                                }
                                                                variants={{
                                                                  hidden: { height: '0' },
                                                                  open: { height: '100%' }
                                                                }}
                                                                transition={{
                                                                  duration: 0.3,
                                                                  type: 'spring',
                                                                  damping: 18
                                                                }}
                                                                className={s.employee__list_mobile}
                                                            >
                                                                <div className={s.employee__list_mobile_inner}>
                                                                    <h5>{t<string>('MAIN.EMPLOYEES.LIST.HEADERMOBILE.SURNAME')}</h5>
                                                                    <p>{client?.surname}</p>
                                                                </div>
                                                                <div className={s.employee__list_mobile_inner}>
                                                                    <div className={s.phone__wrapper}>
                                                                        <h5>{t<string>('MAIN.EMPLOYEES.LIST.HEADERMOBILE.PHONE')}</h5>
                                                                        {client?.phones?.filter((item: any) => item?.favorite)
                                                                          ?.length ? (
                                                                            <p>
                                                                                +256
                                                                                {
                                                                                    client.phones?.filter((item: any) => item?.favorite)[0]
                                                                                      ?.phone
                                                                                }
                                                                            </p>
                                                                          ) : (
                                                                            <p>----</p>
                                                                          )}
                                                                    </div>
                                                                    <div className={s.group__wrapper}>
                                                                        <h5>{t<string>('MAIN.EMPLOYEES.LIST.HEADERMOBILE.GROUP')}</h5>
                                                                      <button
                                                                        disabled={client.deletedAt}
                                                                        className={s.redirect__button}
                                                                        onClick={() => client?.group?.title ? goToGroup(client?.group) : null}
                                                                        type="button"
                                                                      >
                                                                        <p>{client?.group?.title || '----'}</p>
                                                                      </button>
                                                                    </div>
                                                                </div>
                                                            </motion.div>
                                                        </motion.div>
                                                ))
                                              ) : (
                                                    <h2 className={s.item__title_empty}>
                                                        {t<string>('MAIN.EMPLOYEES.LIST.EMPTY')}
                                                    </h2>
                                              )
                                            // ) : (
                                            // )
                                            }
                                          { isEmployeesLoading !== Loading.success ?
                                            <div className={s.mobile__loader}>
                                              <Loader
                                                loaderStyles={{
                                                  width: '2.5rem',
                                                  height: '2.5rem',
                                                  borderTopWidth: '4px',
                                                  borderWidth: '4px'
                                                }}
                                              />
                                            </div>
                                            :
                                            null
                                          }
                                        </div>
                                    </div>
                                </div>
                              { (currentEmployeesItems?.length
                                ? currentEmployeesItems
                                : toJS(getEmployeesModel.getEmployeesList)
                              ).length
                                ?
                                <div className={s.paginate__wrapper}>
                                  <Pagination
                                    elementsPerPage={elementsCount}
                                    currentPage={dynamicPage || 1}
                                    currentSort={dynamicSort}
                                    currentFilter={dynamicFilter}
                                    setDynamicPage={setDynamicPage}
                                    activeParams={params}
                                    activeLocation={location}
                                    isCheck={isCheck.sort((a, b) => a - b)}
                                    totalItems={getEmployeesModel.getEmployeesMeta?.total}
                                    items={getEmployeesModel.getEmployeesList || []}
                                    activeItems={toJS(getEmployeesModel.getAllActiveEmployeesList) || []}
                                    setCurrentDynamicItems={setCurrentEmployeesItems}
                                    changePageWithSave
                                    changePageWithSavePath={'/employees'}
                                  />
                                </div>
                                :
                                null
                              }


                                <div className={s.payment__mobile}>
                                    <motion.button
                                        whileTap={isCheck?.length && !isCheckGroup.filter((item: any) => item.deletedAt).length ? { scale: 0.95 } : {}}
                                        whileHover={isCheck?.length && !isCheckGroup.filter((item: any) => item.deletedAt).length ? { scale: 1.05 } : {}}
                                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                                        className={`${s.payment__mobile__button} ${
                                          isCheck?.length && !isCheckGroup.filter((item: any) => item.deletedAt).length ? s.payment__mobile__button_active : ''
                                        }`}
                                        disabled={isCheck?.length === 0 || !!isCheckGroup.filter((item: any) => item.deletedAt).length}
                                        type="submit"
                                    >
                                        <p>{t<string>('MAIN.EMPLOYEES.BUTTONS.PAYMENT')}</p>
                                    </motion.button>
                                </div>
                            </>
                        )}
                    </FormikContainer>
                </div>
            </AnimatedDivPage>
        </section>
  );
};

export const EmployeesList = observer(EmployeesListView);
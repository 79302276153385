/* eslint-disable jsx-a11y/no-static-element-interactions,no-nested-ternary,array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  state: any;
  isCheckGroupRechange: any[];
  isDeletedOperators: any;
  inputValidateFields: any,
  selectValidateFields: any,
  isCheckRechangeConcat: any;
  isCheckGroupRechangeConcat: any;
  removeDuplicates: <T, K>(data: T[], key: (key: T) => K) => T[];
  setIsCheckRecnahge: (checkRechange: any) => void;
  setUniqueProviders: (uniqueProviders: any) => void;
  setIsCheckGroupRechange: (checkGroupRechange: any) => void;
  submitValues?: any;
  modalRefEl: any;
}

const ModalAddEmployeesToPaymentView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    modalRefEl,
    inputValidateFields,
    selectValidateFields,
    state,
    submitValues,
    isDeletedOperators,
    setIsCheckGroupRechange,
    isCheckGroupRechangeConcat,
    setIsCheckRecnahge,
    setUniqueProviders,
    isCheckGroupRechange,
    removeDuplicates,
    isCheckRechangeConcat,
  } = props;

  const isRemovedEmployees = isCheckGroupRechange.filter(groupEmployee => isCheckGroupRechangeConcat.map((employee: any) => employee.id).includes(groupEmployee.id)).length < isCheckGroupRechange.length;
  const removedListEmployees = isCheckGroupRechange.filter(groupEmployee => !isCheckGroupRechangeConcat.map((employee: any) => employee.id).includes(groupEmployee.id)).map(employee => `${employee.name} ${employee.surname}`);
  const addedListEmployees = isCheckGroupRechangeConcat.filter((groupEmployee: any) => !isCheckGroupRechange.map((employee: any) => employee.id).includes(groupEmployee.id)).map((employee: any) => `${employee.name} ${employee.surname}`);
  const isAddedEmployees = isCheckGroupRechangeConcat.filter((groupEmployee: any) => !isCheckGroupRechange.map((employee: any) => employee.id).includes(groupEmployee.id)).length;

  // После закрытия окна добавления сотрудников, нужно делать isCheckGroup равным setIsCheckGroupList
  const changeGroupCheck = () => {
    // setIsCheckGroupList(isCheckGroup);
    // setIsAppendEmployeesModal(false);

    Object.entries(submitValues || [...inputValidateFields, ...selectValidateFields]?.reduce(
      (acc: any, item: any) => Object.assign(acc, item.initialValue),
      {}
    ) || state?.forInitialValues).map(
      ([key]: [string, any]): any => {
        isDeletedOperators.map((operator: any) => {
          if (key.includes(operator)) {
            delete submitValues[key];
          }
        });
      }
    );

    setIsCheckGroupRechange(isCheckGroupRechangeConcat.sort((a: any, b: any) => {
      if (`${a.name.toLowerCase()}${a.surname.toLowerCase()}` > `${b.name.toLowerCase()}${b.surname.toLowerCase()}`) {
        return 1;
      }

      if (`${a.name.toLowerCase()}${a.surname.toLowerCase()}` < `${b.name.toLowerCase()}${b.surname.toLowerCase()}`) {
        return -1;
      }

      return 0;

    }));
    setIsCheckRecnahge(isCheckRechangeConcat);
    setUniqueProviders(
      removeDuplicates(
        [
          ...isCheckGroupRechangeConcat.reduce((acc: any[], accountsItem: any) => {
            if (accountsItem?.accounts?.length >= 1) {
              acc.push(...accountsItem?.accounts?.map((provider: any) => provider?.provider));
            }
            return acc;
          }, [])
        ].sort((a: any, b: any) => {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }

          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }

          return 0;
        }),
        (key: any) => key.id
      )
    );

    window.sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroupRechangeConcat.sort((a: any, b: any) => {
      if (`${a.name.toLowerCase()}${a.surname.toLowerCase()}` > `${b.name.toLowerCase()}${b.surname.toLowerCase()}`) {
        return 1;
      }

      if (`${a.name.toLowerCase()}${a.surname.toLowerCase()}` < `${b.name.toLowerCase()}${b.surname.toLowerCase()}`) {
        return -1;
      }

      return 0;

    })));
    window.sessionStorage.setItem('isCheck', JSON.stringify(isCheckRechangeConcat));
    // window.sessionStorage.setItem('providers', JSON.stringify(removeDuplicates(toJS(
    //   isCheckGroupRechangeConcat.reduce((acc: any[], accountsItem: any) => {
    //     if (accountsItem?.accounts?.length >= 1) {
    //       acc.push(
    //         ...accountsItem?.accounts?.map((provider: any) => ({
    //           ...provider?.provider,
    //           employeeId: accountsItem?.id,
    //           uniqueId: provider?.uniqueId
    //         }))
    //       );
    //     }
    //     return acc;
    //   }, [])
    // ), (key: any) => key.id)));

    hideModal();
  };

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Add employees</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>

            { isRemovedEmployees ?
              <>
                <p className={[s.description__text, s.description__text_scroll].join(' ')}>
                  Do you want to remove employees ({removedListEmployees.map((employee: any) => employee).join(', ')}) from this payment ?
                </p><br/>
              </>
              :
              null
            }

            { isAddedEmployees ?
              <>
                <p className={[s.description__text, s.description__text_scroll].join(' ')}>
                  Do you want to add employees ({addedListEmployees.map((employee: any) => employee).join(', ')}) to this payment ?
                </p><br/>
              </>
              :
              null
            }

            <>
              <p className={[s.description__text, s.description__text_scroll].join(' ')}>
                Do you confirm your choice to this payment ?
              </p><br/>
            </>
          </div>

          <div className={s.employee}>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={changeGroupCheck}
              >
                <p>Yes</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>No</p>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalAddEmployeesToPayment = observer(ModalAddEmployeesToPaymentView);

/* eslint-disable no-param-reassign */
export const formatDuration = (duration: number): string | number => {
  const milliseconds = duration % 1000;
  duration = (duration - milliseconds) / 1000;
  const seconds = duration % 60;
  duration = (duration - seconds) / 60;
  const minutes = duration % 60;
  duration = (duration - minutes) / 60;
  const hours = duration % 24;
  const days = (duration - hours) / 24;

  const parts = [];
  if (days) {
    parts.push(`${days} day${days > 1 ? 's' : ''}`);
  }
  if (hours) {
    parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }
  if (minutes) {
    parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  }
  if (seconds) {
    parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
  }
  if (milliseconds) {
    parts.push(`${milliseconds} millisecond${milliseconds > 1 ? 's' : ''}`);
  }

  return parts.join(', ');
};

/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { Loader } from 'common/loader';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { DropSVG } from 'assets/icons/drop';
import { LoupeSVG } from 'assets/icons/loupe';
import { useModal } from 'hooks/useModal.hook';
import { motion, Variants } from 'framer-motion';
import { Providers } from 'models/providers/types';
import { Pagination } from 'components/pagination';
import { useDebounce } from 'hooks/useDebounce.hook';
import { EditLightSVG } from 'assets/icons/editLight';
import { DropSelectSVG } from 'assets/icons/dropselect';
import { GroupArrowSVG } from 'assets/icons/groupArrow';
import FormikContainer from 'components/form/containers';
import { AnimatedDivPage } from 'components/animatedPage';
import { DeleteLightSVG } from 'assets/icons/deleteLight';
import { RouterLink } from 'router/components/routerLink';
import FormikSelect from 'components/form/fields/select/index';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import FormikTextField from 'components/form/fields/inputs/index';
import { useLocation, useNavigate } from 'react-router';
import { groupsListTitles, groupsSortMobile } from 'common/constants/groups';
import s from './index.module.scss';

export const GroupsView = () => {
  const { auth, groups, employees, providers } = useStores();
  const { getGroupsModel } = groups;
  const { getProvidersModel } = providers;
  const { showModal } = useModal();
  const { getEmployeesModel } = employees;
  const navigate = useNavigate();
  const [elementsCount, setElementsCount] = useState(8);
  const { isTablet, isTabletAdditional } = useWindowDimensions();
  const { getGroupsAsyncState, getDeleteGroupsAsyncState } = groups;
  const isGroupsLoading = getGroupsAsyncState?.getLoadingState;
  const isDeletedGroupsLoading = getDeleteGroupsAsyncState.getLoadingState;
  const [isCurrentGroupId, setIsCurrentGroupId] = useState<any[]>([]);
  const [isOpenGroupMenu, setIsOpenGroupMenu] = useState(false);
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const [currentGroupItems, setCurrentGroupItems] = useState<any>([]);
  const [groupsSearch, setGroupsSearch] = useState('');
  const [groupsMobileSearch, setGroupsMobileSearch] = useState('');
  const groupsSearchDebounce = useDebounce(groupsSearch || groupsMobileSearch, 300);

  const { search } = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(search);
  const [dynamicPage, setDynamicPage] = useState<number | string>(params.get('page') || getGroupsModel.getGroupMeta.page || 1);
  const [dynamicSort, setDynamicSort] = useState<number | string>(params.get('sortId') || (!isTabletAdditional ? getGroupsModel.getGroupMeta.sort : 0));
  const [isGroupItemsSorted, setIsGroupItemsSorted] = useState<any>(params.get('sortId') || (!isTabletAdditional ? getGroupsModel.getGroupMeta.sort : 0));

  const removeDuplicates = (data: any, key: any) => [
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];
  const onSubmit = (values: any) => {
  };

  const animations: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  const handleOpenEmployeeMenu = (e: any, group: any) => {
    setIsOpenGroupMenu(!isOpenGroupMenu);
    setIsCurrentGroupId([...isCurrentGroupId, group.id]);

    if (isCurrentGroupId.includes(group.id)) {
      setIsCurrentGroupId(isCurrentGroupId.filter((employeeId: any) => employeeId !== group.id));
    }
  };

  const deleteGroup = (group: any) => {
    showModal(Modals.ModalGroupRemoveConfirmation, { group });
  };

  const groupPayment = (group: any) => {
    navigate('/payment', {
      state: {
        enableReinitializate: true,
        group: toJS(group),
        isCheck: toJS(group).employees.map((employee: any) => employee.id),
        isCheckGroup: toJS(getEmployeesModel.getEmployeesList)
          .filter((employee: any) =>
            toJS(group)
              .employees.map((groupEmployee: any) => groupEmployee.id)
              .includes(employee.id)
          )
          .reduce((acc: any, item: any) => {
            acc.push({
              ...item,
              accounts: (removeDuplicates(
                [
                  ...toJS(group.groupsAccounts).reduce((groupAcc: any, groupItem: any) => {
                    groupAcc.push({
                      ...groupItem,
                      defaultPrice: null,
                      priceList: null,
                      isDefaultValue: true,
                    });
                    return groupAcc;
                  }, []),

                  ...item?.accounts
                    .filter((excludeItem: any) =>
                      toJS(group.groupsAccounts)
                        .map((groupExcludeItem: any) => groupExcludeItem.provider.id)
                        .includes(excludeItem.provider.id)
                    )
                    .map((accountItem: any) =>
                      toJS(group.groupsAccounts).length ? toJS(group.groupsAccounts).map((groupItem: any) => {
                        if ((accountItem?.provider?.id === groupItem?.provider?.id) && !accountItem?.defaultValueEmployee) {
                          accountItem.defaultPrice = groupItem.defaultPrice;
                          accountItem.defaultPriceList = groupItem?.priceList;
                          accountItem.isDefaultValue = true;
                        }
                        return accountItem;
                      })
                        :
                        accountItem
                    )
                ].flat(Infinity),

                (key: any) => key.provider?.id
              )).reduce((accountAcc: any, accountItem: any) => {

                // Hardcode
                toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                  if (provider.id === accountItem?.provider?.id) {
                    accountItem.provider = provider;
                  }
                });

                accountAcc.push(accountItem);

                return accountAcc;
              }, [])
            });
            return acc;
          }, []),
        goBack: '/groups'
      }
    });
    window.sessionStorage.setItem('isCheck', JSON.stringify(toJS(group).employees.map((employee: any) => employee.id)));
    window.sessionStorage.setItem('isCheckGroup', JSON.stringify(toJS(getEmployeesModel.getEmployeesList)
      .filter((employee: any) =>
        toJS(group)
          .employees.map((groupEmployee: any) => groupEmployee.id)
          .includes(employee.id)
      )
      .reduce((acc: any, item: any) => {
        acc.push({
          ...item,
          accounts: (removeDuplicates(
            [
              ...toJS(group.groupsAccounts).reduce((groupAcc: any, groupItem: any) => {
                groupAcc.push({
                  ...groupItem,
                  defaultPrice: null,
                  priceList: null,
                  isDefaultValue: true,
                });
                return groupAcc;
              }, []),

              ...item?.accounts
                .filter((excludeItem: any) =>
                  toJS(group.groupsAccounts)
                    .map((groupExcludeItem: any) => groupExcludeItem.provider.id)
                    .includes(excludeItem.provider.id)
                )
                .map((accountItem: any) =>
                  toJS(group.groupsAccounts).length ? toJS(group.groupsAccounts).map((groupItem: any) => {
                    if ((accountItem?.provider?.id === groupItem?.provider?.id) && !accountItem?.defaultValueEmployee) {
                      accountItem.defaultPrice = groupItem.defaultPrice;
                      accountItem.defaultPriceList = groupItem?.priceList;
                    }
                    return accountItem;
                  })
                    :
                    accountItem
                )
            ].flat(Infinity),

            (key: any) => key.provider?.id
          )).reduce((accountAcc: any, accountItem: any) => {

            // Hardcode
            toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
              if (provider.id === accountItem?.provider?.id) {
                accountItem.provider = provider;
              }
            });

            accountAcc.push(accountItem);

            return accountAcc;
          }, [])
        });
        return acc;
      }, [])));
  };

  const goToDetailGroup = (group: any) => {
    groups.getGroupsDetailAction({
      clientId: getAuthMe?.id,
      groupId: group?.id
    });
    navigate(`groups-detail/${group.id}`, { state: { page: dynamicPage, sortId: dynamicSort, previousPageWithQuery: `${location.pathname}?${new URLSearchParams(window.location.search).toString()}` } });
  };

  const callGroupsDetailPage = (group: any) => {
    navigate(`groups-detail/${group.id}`, { state: { group: toJS(group), page: dynamicPage, sortId: dynamicSort, previousPageWithQuery: `${location.pathname}?${new URLSearchParams(window.location.search).toString()}` } });

  };

  const handleSortItems = (e: any) => {
    const currentSortId = e?.value?.toString() || ( isGroupItemsSorted?.toString() === e?.asc?.toString() ? e?.desc?.toString() : e?.asc?.toString() );
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('sortId', currentSortId);
    const newUrl = `${location.pathname}?${currentParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    setIsGroupItemsSorted(currentSortId);
    setDynamicSort(currentSortId);

    groups.getGroupsAction({
      clientId: getAuthMe.id,
      search: groupsSearch || groupsMobileSearch,
      page: dynamicPage,
      sortId: currentSortId
    });
  };

  const onHandleSearch = (setter: (val: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  useEffect(() => {
    window.onscroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 5 >=
          document.scrollingElement!.scrollHeight &&
        !isTabletAdditional
      ) {
        setElementsCount(elementsCount + 5);
        groups.getGroupsAction({
          clientId: getAuthMe?.id,
          search: groupsSearch || groupsMobileSearch,
          sortId: dynamicSort,
          quantity: elementsCount + 5,
        });
      }
    };
  }, [elementsCount, dynamicSort]);

  useEffect(() => {
    if (isDeletedGroupsLoading === Loading.success) {
      groups.getGroupsAction({ clientId: getAuthMe?.id });
    }
  }, [isDeletedGroupsLoading, toJS(getEmployeesModel.getEmployeesList).length]);

  useEffect(() => {
    providers.getProvidersAction();
    employees.getEmployeesAction({
      id: getAuthMe.id
      // quantity: 1000
    });
  }, []);

  useEffect(() => {
    groups.getGroupsAction(
      groupsSearch || groupsMobileSearch ?
        {
          clientId: getAuthMe?.id,
          search: groupsSearch || groupsMobileSearch,
          sortId: dynamicSort,
        }
        :
        {
          clientId: getAuthMe?.id,
          page: dynamicPage,
          sortId: dynamicSort,
          search: groupsSearch || groupsMobileSearch,
        });
  }, [groupsSearchDebounce, dynamicPage]);

  useEffect(() => {
    if (!groupsSearch) {
      setCurrentGroupItems(toJS(getGroupsModel.getGroupList));
    }
  }, [groupsSearch]);

  return (
    <section className={s.wrapper}>
      <AnimatedDivPage transition={{ duration: 0.5 }} animations={animations}>
        <div className={s.wrapper__container}>
          <FormikContainer onSubmit={onSubmit} initialValues={{
            sort: groupsSortMobile.filter(item => +dynamicSort === item.value).length ? groupsSortMobile.filter(item => +dynamicSort === item.value)[0] : groupsSortMobile[0],
          }}>
            {() => (
              <>
                <div className={s.wrapper__title}>
                  <div className={s.wrapper__title_main}>
                    <h1 className={s.title}>Groups</h1>
                    <div className={s.group__button}>
                      <motion.button
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        className={s.create__group_button}
                        type="button"
                        onClick={() => navigate('create-group')}
                      >
                        <p>Create group +</p>
                      </motion.button>
                    </div>
                  </div>

                  <div className={s.search__mobile}>
                  <FormikTextField
                    placeholder="Search"
                    name="search"
                    value={groupsSearch}
                    id={'groupsSearch'}
                    onChange={onHandleSearch(setGroupsSearch)}
                  >
                    <div className={s.search__icon}>
                      <LoupeSVG />
                    </div>
                  </FormikTextField>
                  </div>

                  <div className={s.services__sort_mobile}>
                    <div className={s.operators__sort}>
                      <FormikSelect
                        valueContainer={{ paddingLeft: '.5rem' }}
                        wrapperSelectStyles={{ width: '11rem', maxWidth: '100%', minWidth: '100%' }}
                        selectWrapperStyles={{ width: '100%' }}
                        options={groupsSortMobile}
                        name="sort"
                        placeholder="Sort by"
                        indicatorIcon={<DropSelectSVG />}
                        onChange={handleSortItems}
                      />
                    </div>
                  </div>
                </div>

                <div className={s.groups__list}>
                  <div className={s.search}>
                  <FormikTextField
                    placeholder="Search"
                    name="search"
                    value={groupsMobileSearch}
                    id={'groupsMobileSearch'}
                    onChange={onHandleSearch(setGroupsMobileSearch)}
                  >
                    <div className={s.search__icon}>
                      <LoupeSVG />
                    </div>
                  </FormikTextField>
                  </div>
                  <div className={s.content}>
                    <div className={s.content__header}>
                      {groupsListTitles.map((titles: any) => (
                        <div key={titles.id} className={s.content__header_item}>
                          <button
                           style={{ marginLeft: titles.data === 'description' ? '-3rem' : 0 }}
                            disabled={!titles?.icon}
                            onClick={() => handleSortItems(titles)}
                            type="button"
                            className={s.item}
                          >
                            <p>{titles.name}</p>
                            {titles.icon}
                          </button>
                        </div>
                      ))}
                    </div>

                    <div className={s.content__main}>
                      {isTabletAdditional ? (
                        isGroupsLoading === Loading.success ? (
                          (currentGroupItems?.length ? currentGroupItems : toJS(getGroupsModel.getGroupList)
                          ).length >= 1 ? (
                              (currentGroupItems?.length ? currentGroupItems : toJS(getGroupsModel.getGroupList)
                              )?.map((group: any, i: number) => (
                            <motion.div
                              initial="hidden"
                              animate="visible"
                              variants={{
                                hidden: { opacity: 0, x: -50 },
                                visible: {
                                  pathLength: 1,
                                  opacity: 1,
                                  transition: {
                                    delay: i * 0.05,
                                    duration: 0.4,
                                    type: 'spring',
                                    damping: 10
                                  },
                                  x: 0
                                }
                              }}
                              transition={{ duration: 0.7 }}
                              key={group.id}
                              className={s.content__items}
                            >
                              {groupsListTitles.map((title: any) => (
                                <div key={title.id} className={s.content__item}>

                                    {title.data === 'name' ?
                                    <div className={s.title__wrapper}>
                                      <RouterLink to={`/groups/groups-detail/${group.id}`} state={{ page: dynamicPage, sortId: dynamicSort, previousPageWithQuery: `${location.pathname}?${new URLSearchParams(window.location.search).toString()}` }}>
                                        <p className={s.content__item_title}>{group.title || '----'}</p>
                                      </RouterLink>
                                    </div>
                                      : null}

                                  {title.data === 'members' ? (
                                    <p>{group.employees.length || 0}</p>
                                  ) : null}

                                  {title.data === 'description' ? (
                                    <div className={s.description__wrapper}>
                                      <p>{group.description || '----'}</p>
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                              <div className={s.content__items_icons}>
                                <button
                                  onClick={() => goToDetailGroup(group)}
                                  type="button"
                                  className={s.content__items_icon}
                                >
                                  <EditLightSVG />
                                </button>
                                <button
                                  onClick={() => deleteGroup(group)}
                                  type="button"
                                  className={`${s.content__items_icon} ${s.content__items_stroke}`}
                                >
                                  <DeleteLightSVG />
                                </button>
                                <button
                                  type="button"
                                  disabled={!group.groupsAccounts.length || !toJS(group)?.employees?.length}
                                  className={`${s.content__items_icon} ${s.content__items_icon_arrow}`}
                                  onClick={() => groupPayment(group)}
                                >
                                  <GroupArrowSVG />
                                </button>
                              </div>
                            </motion.div>
                              ))
                            ) :
                            <h2 className={s.item__title_empty}>There are no groups</h2>
                        ) : (
                          <Loader
                            loaderStyles={{
                              width: '2.5rem',
                              height: '2.5rem',
                              borderTopWidth: '4px',
                              borderWidth: '4px'
                            }}
                          />
                        )
                      ) :
                        (currentGroupItems
                        ).length >= 1 ? (
                            currentGroupItems.map((group: any, i: number) => (
                            <motion.div
                              initial="hidden"
                              animate="visible"
                              variants={{
                                hidden: { opacity: 0, x: -50 },
                                visible: {
                                  pathLength: 1,
                                  opacity: 1,
                                  borderRadius: isCurrentGroupId.includes(group.id)
                                    ? '1.25rem'
                                    : '.5rem',
                                  transition: {
                                    delay: ( i > 9 ? 0 : i ) * 0.05,
                                    duration: 0.4,
                                    type: 'spring',
                                    damping: 10
                                  },
                                  x: 0
                                }
                              }}
                              transition={{ duration: 0.7 }}
                              key={group.id}
                              className={s.content__items_mobile}
                              onClick={(e: any) => handleOpenEmployeeMenu(e, group)}
                            >
                              <div className={s.content__header}>
                                <div className={s.content__item}>
                                  {isCurrentGroupId.includes(group.id) ? (
                                    <motion.h5
                                      initial="hidden"
                                      animate={
                                        isCurrentGroupId.includes(group.id) ? 'open' : 'hidden'
                                      }
                                      variants={{
                                        hidden: { height: '0' },
                                        open: { height: 'fit-content' }
                                      }}
                                      transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                                      className={s.name}
                                    >
                                      Group
                                    </motion.h5>
                                  ) : null}

                                  <div className={s.content__item_info}>
                                    <div className={s.content__item_info__inner}>
                                      <p>{group.title || '----'}</p>
                                      <p
                                        className={
                                          isCurrentGroupId.includes(group.id)
                                            ? s.info__status_hide
                                            : ''
                                        }
                                      >
                                        {group?.employees?.length}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className={s.icons}>
                                  {isCurrentGroupId.includes(group.id) ? (
                                    <>
                                      <button type='button' onClick={(e: any) => {
                                        e.stopPropagation();
                                        callGroupsDetailPage(group);
                                      }} className={s.content__items_icon}>
                                        <EditLightSVG />
                                      </button>
                                      <button
                                        onClick={(e: any) => {
                                          e.stopPropagation();
                                          deleteGroup(group);
                                        }}
                                        type="button"
                                        className={`${s.content__items_icon} ${s.content__items_stroke}`}
                                      >
                                        <DeleteLightSVG />
                                      </button>
                                      <button
                                        type="button"
                                        disabled={!group.groupsAccounts.length || !toJS(group)?.employees?.length}
                                        className={`${s.content__items_icon} ${s.content__items_icon_arrow}`}
                                        onClick={(e: any) => {
                                          e.stopPropagation();
                                          groupPayment(group);
                                        }}
                                      >
                                        <GroupArrowSVG />
                                      </button>
                                    </>
                                  ) : null}

                                  <div
                                    style={
                                      isCurrentGroupId.includes(group.id)
                                        ? { transform: 'rotate(180deg)' }
                                        : {}
                                    }
                                    className={s.icon_drop}
                                  >
                                    <DropSVG />
                                  </div>
                                </div>
                              </div>

                              <motion.div
                                initial="hidden"
                                animate={isCurrentGroupId.includes(group.id) ? 'open' : 'hidden'}
                                variants={{
                                  hidden: { height: '0' },
                                  open: { height: '100%' }
                                }}
                                transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                                className={s.employee__list_mobile}
                              >
                                <div className={s.employee__list_mobile_inner}>
                                  <div className={s.group__list}>
                                    <div className={s.group__wrapper}>
                                      <h5>Employees:</h5>
                                      <p>{group?.employees?.length}</p>
                                    </div>
                                  </div>

                                  <div className={s.description__wrapper}>
                                    <h5>Description:</h5>
                                    <p>{group?.description || '----'}</p>
                                  </div>
                                </div>
                              </motion.div>
                            </motion.div>
                            ))
                          ) : (
                          <h2 className={s.item__title_empty}>There are no groups</h2>
                          )}
                      { isGroupsLoading !== Loading.success ?
                        <div className={s.mobile__loader}>
                          <Loader
                            loaderStyles={{
                              width: '2.5rem',
                              height: '2.5rem',
                              borderTopWidth: '4px',
                              borderWidth: '4px'
                            }}
                          />
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                </div>

                {
                  (currentGroupItems?.length ? currentGroupItems : toJS(getGroupsModel.getGroupList)
                  ).length
                    ?
                    <div className={s.paginate__wrapper}>
                      <Pagination
                        setDynamicPage={setDynamicPage}
                        currentPage={dynamicPage || 1}
                        currentSort={dynamicSort}
                        searchParams={search}
                        activeParams={params}
                        activeLocation={location}
                        elementsPerPage={elementsCount}
                        totalItems={getGroupsModel.getGroupMeta.total}
                        items={getGroupsModel.getGroupList || []}
                        setCurrentDynamicItems={setCurrentGroupItems}
                        changePageWithSave
                        changePageWithSavePath={'/groups'}
                      />
                    </div>
                    :
                    null
                }

              </>
            )}
          </FormikContainer>
        </div>
      </AnimatedDivPage>
    </section>
  );
};

export const Groups = observer(GroupsView);
import { useTranslation } from 'react-i18next';


export const checkPasswordStrong = (password: string) => {
  const { t } = useTranslation();
  const atLeastOneNumber = /\d/;
  const atLeastOneUpperCase = /(?=.*[A-Z])/;
  const atLeastOneSpecialChar = /(?=.*[-+_!@#$%^&*., ?])/;
  if (
    (password?.length >= 6 &&
      password?.match(atLeastOneNumber) &&
      password?.match(atLeastOneUpperCase) &&
      password?.match(atLeastOneSpecialChar)) ||
    password?.length >= 15
  ) {
    return { score: 4, strength: t<string>('AUTH.SIGNUP.FORM.PASSWORDHARD'), width: '100%', backgroundColor: '#16a249' };
  }
  if (
    (password?.length >= 6 &&
      password?.match(atLeastOneNumber) &&
      password?.match(atLeastOneUpperCase)) ||
    password?.length >= 10
  ) {
    return { score: 3, strength: t<string>('AUTH.SIGNUP.FORM.PASSWORDGOOD'), width: '75%', backgroundColor: '#fceb00' };
  }
  if (
    (password?.length >= 6 && password?.match(atLeastOneNumber)) ||
    password?.match(atLeastOneUpperCase) ||
    password?.length >= 8
  ) {
    return { score: 2, strength: t<string>('AUTH.SIGNUP.FORM.PASSWORDEASY'), width: '50%', backgroundColor: '#ff9204' };
  }
  if (password?.length >= 6) {
    return { score: 1, strength: t<string>('AUTH.SIGNUP.FORM.PASSWORDEASY'), width: '25%', backgroundColor: 'red' };
  }
  return { score: 0, strength: t<string>('AUTH.SIGNUP.FORM.PASSWORDEASY'), width: '0', backgroundColor: 'red' };
};

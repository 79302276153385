export enum KindOfPage {
  DETAIL_GROUP_PAGE = 'DETAIL_GROUP_PAGE',
  CREATE_GROUP_PAGE = 'CREATE_GROUP_PAGE',
  DETAIL_EMPLOYEE_PAGE = 'DETAIL_EMPLOYEE_PAGE',
  CREATE_EMPLOYEE_PAGE = 'CREATE_EMPLOYEE_PAGE',
}

export enum KindOfScenarios {
  AMOUNT = 'Amount',
  PRICELIST = 'PriceList'
}

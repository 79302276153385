/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useStores } from 'store';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { CrossSVG } from 'assets/icons/cross';
import { EmployeeList } from 'models/employees/types';
import FormikContainer from 'components/form/containers';
import { EmployeesController } from 'controllers/employees';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  dynamicPage: string | number;
  dynamicSort: string | number;
  dynamicFilter: string | number;
  employeeSearch: string;
  employeeMobileSearch: string;
  setIsCheck: (checkItems: any) => void;
  setIsCheckGroup: (CheckGroupItems: any) => void;
  importCalculate: () => void;
  setIsModalButtonSubmited: (submited: boolean) => void;
  setIsImportActive: (active: boolean) => void;
  setIsDeletePromisesCompleted: (active: boolean) => void;
  setIsCheckAll: (checkAll: boolean) => void;
  removeDuplicates: <T, K>(items: T[], key: (key: T) => K) => T[];
  isCheck: string[];
  employees: EmployeesController;
  filteredExistentDeactivatedEmployees: EmployeeList[];
}

const ModalEmployeeActivateConfirmationView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, filteredExistentDeactivatedEmployees, setIsImportActive, importCalculate, setIsDeletePromisesCompleted, setIsCheckAll, employeeSearch, employeeMobileSearch, dynamicSort, dynamicPage, dynamicFilter, employees, setIsModalButtonSubmited, removeDuplicates, isCheck, setIsCheck, setIsCheckGroup } = props;
  const { state }: any = useLocation();
  const { auth, groups } = useStores();
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const deactivatedListEmployees = filteredExistentDeactivatedEmployees?.map(employee => `${employee.name} ${employee.surname}`);

  const onSubmit = async (values: any) => {
    setIsModalButtonSubmited(true);
    await toast.warning('Some employees have been activated to continue payment');
    const groupEditData = removeDuplicates(
      filteredExistentDeactivatedEmployees.flatMap((employee) => employee.id),
      (key: string) => key
    ).flat(Infinity);

    const deletePromises = groupEditData?.map((id: string) =>
      employees.deleteEmployeesAction({ id }, groupEditData.length, false, 'Employees activated')
    );

    await Promise.all(deletePromises).then(() => {
      setIsDeletePromisesCompleted(true);
    });

    importCalculate();

    // groupEditDataFunc();

    // setIsCheck([]);
    // setIsCheckGroup?.([]);
    // setIsCheckAll(false);
    // window.sessionStorage.removeItem('isCheckGroup');
    // window.sessionStorage.removeItem('isCheck');
    // window.sessionStorage.removeItem('isCheckAll');
    hideModal();
  };

  useEffect(() => () => {
    employees.getEmployeesAction(
      employeeSearch || employeeMobileSearch
        ?
        {
          id: getAuthMe.id,
          search: employeeSearch || employeeMobileSearch,
          sortId: dynamicSort,
          active: dynamicFilter,
          isAllByPagesEmployees: true,
        }
        :
        {
          id: getAuthMe.id,
          page: dynamicPage,
          sortId: dynamicSort,
          active: dynamicFilter,
          search: employeeSearch || employeeMobileSearch,
          isAllByPagesEmployees: true,
        }
    );
  }, []);

  return (
    <ModalBackdrop resetAfterImport={() => {
      setIsCheck([]);
      setIsCheckGroup?.([]);
      setIsCheckAll(false);
      window.sessionStorage.removeItem('isCheckGroup');
      window.sessionStorage.removeItem('isCheck');
      window.sessionStorage.removeItem('isCheckAll');
      setIsImportActive(false);
    }} modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Warning</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>

            <p className={s.description__text}>
              Some employees are deactivated, you need to activate them to pay ({filteredExistentDeactivatedEmployees?.length}) { filteredExistentDeactivatedEmployees?.length > 1 ? 'employees' : 'employee' }.
            </p><br/>

            <p className={s.description__text}>
              If you do not confirm employees activation, no further payment will be made.
            </p><br/>

            <p className={s.description__text}>Are you sure you want to activate these employees for payment ?</p><br/>
            <p className={[s.description__text, s.description__text_scroll].join(' ')}>
              ({deactivatedListEmployees?.map(employee => employee).join(', ')})
            </p>

          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => {
                        setIsCheck([]);
                        setIsCheckGroup?.([]);
                        setIsCheckAll(false);
                        window.sessionStorage.removeItem('isCheckGroup');
                        window.sessionStorage.removeItem('isCheck');
                        window.sessionStorage.removeItem('isCheckAll');
                        setIsImportActive(false);
                        hideModal();
                      }}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalEmployeeActivateConfirmation = observer(ModalEmployeeActivateConfirmationView);
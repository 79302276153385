/* eslint-disable  */

import React, { CSSProperties, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ColorsAccent, ColorsGray } from 'common/colors';
import {
  listKindsOfSchedule,
  regularPaymentInterval,
  RegularPaymentTitles,
  RegularPaymentTitlesIds
} from 'common/constants/schedulePayment';
import FormikTextFieldNumber from 'components/form/fields/inputs/inputNumber';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikSelect from 'components/form/fields/select';
import {
  ChoosenSchedulePayment,
  ScheduleSelectField,
  ScheduleSelectFieldInterval
} from 'common/constants/schedulePayment/types';
import s from './index.module.scss';

interface IScheduleCalendarButtonRegularInterval {
  stylesWrapper?: CSSProperties;
  onIntervalSelect: (time: number) => void;
  title: string;
  choosenScedulePayment: ChoosenSchedulePayment;
  clearIntervalValues?: boolean;
}

export const ScheduleCalendarButtonRegularInterval = (props: IScheduleCalendarButtonRegularInterval) => {
  const { stylesWrapper, title, onIntervalSelect, choosenScedulePayment, clearIntervalValues } = props;
  const [currentIntervalTime, setCurrentIntervalTime] = useState<number>(0);
  const [currentOption, setCurrentOption] = useState<ScheduleSelectFieldInterval>(regularPaymentInterval[0]);

  const onChangeDate = (option: ScheduleSelectFieldInterval) => {
    setCurrentOption(option);
  };

  const onChangeValue = (e: any) => {
    setCurrentIntervalTime(+e || 0);
  };

  useEffect(() => {
    const selectedDateUTC = choosenScedulePayment.regularStart ? new Date(choosenScedulePayment.regularStart) : new Date();
    const selectedDateMillis = selectedDateUTC.getTime();
    const startDate = new Date(selectedDateMillis);
    const endDate = new Date(startDate);

    switch (currentOption.id) {
      case RegularPaymentTitlesIds.DAY:
        endDate.setDate(endDate.getDate() + currentIntervalTime);
        break;
      case RegularPaymentTitlesIds.WEEK:
        endDate.setDate(endDate.getDate() + currentIntervalTime * 7);
        break;
      case RegularPaymentTitlesIds.MONTH:
        endDate.setMonth(endDate.getMonth() + currentIntervalTime);
        const lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
        endDate.setDate(Math.min(endDate.getDate(), lastDay));
        break;
      case RegularPaymentTitlesIds.YEAR:
        endDate.setFullYear(endDate.getFullYear() + currentIntervalTime);
        break;
      default:
        break;
    }

    // endDate.setHours(0);
    // endDate.setMinutes(0);
    // endDate.setSeconds(0);

    const endDateMillis = new Date(new Date(endDate).getTime() + new Date(endDate).getTimezoneOffset() * 60000).getTime();

    onIntervalSelect(endDateMillis);

  }, [currentIntervalTime, currentOption]);

  useEffect(() => {
    if (clearIntervalValues) {
      onIntervalSelect(0);
    }
  }, [clearIntervalValues]);

  return (
    <div className={s.wrapper}>

      <div className={s.fields}>
        {/* TODO: Сделать title для всех элементов, потом начинать собирать данные с регулярного платежа */}
        <div className={s.field}>
          <FormikTextFieldNumber
            isIntervalField
            name='intervalValue'
            disabled={!choosenScedulePayment.regularStart}
            placeholder={'1'}
            inputStyles={{
              height: '2.25rem',
              borderColor: '#E8ECF1',
              backgroundColor: '#F8FAFC',
              paddingLeft: '0',
              textAlign: 'center'
            }}
            value={currentIntervalTime}
            // suppressHydrationWarning
            // min={1}
            // max={4}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            // label={<p className={s.operator__create_label}>{t<string>('MAIN.MODALADDITIONALOPERATORS.FORM.AMOUNT')}</p>}
            onValueChange={(e: any) => onChangeValue(e)}
          />
        </div>

        <div className={s.field}>
          <FormikSelect
            portalTargetDeactivate
            isSearchable={false}
            isDisabled={!choosenScedulePayment.regularStart}
            // loadingMessage={isGetProvidersLoading}
            // onMenuOpen={handleProviderSelect
            //   // handlePriceListSelect({ id: currentOperatorData.provider?.id })
            // }
            controlStyles={{ minHeight: '2.125rem', backgroundColor: '#F8FAFC' }}
            titleStyles={{
              marginBottom: '0.3rem',
              color: ColorsAccent.accentDark,
              fontSize: '14px',
              fontWeight: '500'
            }}

            containerStyles={{
              width: '100%',
              maxWidth: '100%',
              borderColor: ColorsGray.grayEight
            }}
            valueContainer={{
              paddingLeft: '.5rem'
            }}
            selectWrapperStyles={{
              width: '100%'
            }}
            indicatorContainerStyles={{ height: 'auto', width: '2.5rem' }}
            valueStyles={{
              color: ColorsAccent.accentDark,
              fontWeight: 500
            }}
            id="intervalDate"
            name="intervalDate"
            options={regularPaymentInterval}
            placeholder={'Choose date'}
            // label={title}
            indicatorIcon={<DropSelectSVG />}
            onChange={(option: ScheduleSelectFieldInterval) => onChangeDate(option)}
          />
        </div>
      </div>
    </div>
  );
};

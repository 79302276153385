/* eslint-disable no-restricted-globals,react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState, useEffect, ChangeEvent, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { PaySVG } from 'assets/icons/pay';
import { observer } from 'mobx-react-lite';
import { BackSVG } from 'assets/icons/back';
import { LinkSVG } from 'assets/icons/link';
import { PlusSVG } from 'assets/icons/plus';
import { ColorsAccent } from 'common/colors';
import { LoupeSVG } from 'assets/icons/loupe';
import { GroupSVG } from 'assets/icons/group';
import { useTranslation } from 'react-i18next';
import { AdditionSVG } from 'assets/icons/addition';
import { useDebounce } from 'hooks/useDebounce.hook';
import { useLocation, useNavigate } from 'react-router';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikContainer from 'components/form/containers';
import FormikSelect from 'components/form/fields/select';
import { AnimatedDivPage } from 'components/animatedPage';
import { DeleteLightSVG } from 'assets/icons/deleteLight';
import { RouterLink } from 'router/components/routerLink';
import FormikTextField from 'components/form/fields/inputs';
import { SelectPagination } from 'components/selectPagination';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import { NextArrowSVG, PreviousArrowSVG } from 'assets/icons/tableArrows';
import { ProviderMeasure, paymentListTitles } from 'common/constants/payment';
import FormikTextFieldNumber from 'components/form/fields/inputs/inputNumber/index';
import { ModalAdditionOperatorNewPayment } from 'components/modal/additionOperatorModalNewPayment';
import { PaymentAddEmployee } from 'pages/main/pages/payment/pages/paymentAddEmployees';
import s from './index.module.scss';

export const PaymentView = () => {
  const navigate = useNavigate();
  const payEl = useRef<any>(null);
  const cellRef = useRef<any>(null);
  const { t } = useTranslation();
  const { state }: any = useLocation();
  function removeDuplicates<T, K>(data: T[], key: (key: T) => K): T[] {
    return [...new Map(data?.map((item: T) => [key(item), item])).values()];
  }
  const { auth, employees, providers, priceList, accounts } = useStores();
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const { getCreateAccountsAsyncState } =
    accounts;
  const { getEmployeesModel, getUpdateEmployeeAsyncState } = employees;
  const { getProvidersModel } = providers;
  const { getPriceListModel, getPriceListDetailAsyncState } = priceList;
  let isAccountsLoading = getCreateAccountsAsyncState?.getLoadingState;
  const { isMobile, isTablet } = useWindowDimensions();
  const [menuIsOpen, setMenuIsOpen] = useState<any>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const addEmployeeButtonEl = useRef<any>();
  const groupButtonEl = useRef<any>();
  const additionalGroupEl = useRef<any>();
  const [chooseFromModal, setChooseFromModal] = useState<any>({});
  const [isSelectCreated, setIsSelectCreated] = useState<boolean>(false);
  const [whichSubmitButtonClicked, setWhichSubmitButtonClicked] = useState('');
  const [isAppendEmployeesModal, setIsAppendEmployeesModal] = useState<boolean>(false);
  const [openGroupPaymentDetail, setOpenGroupPaymentDetail] = useState<boolean>(false);
  const [isDefaultFieldValues, setIsDefaultFieldValues] = useState<boolean>(true);
  const [elementsCount, setElementsCount] = useState<any>(8);
  const isPriceListDetailLoading = getPriceListDetailAsyncState.getLoadingState;
  const [currentPriceListItems, setCurrentPriceListItems] = useState<any>([]);
  const [dynamicPage, setDynamicPage] = useState<any>(1);
  const [employeeSearch, setEmployeeSearch] = useState('');
  const employeeSearchDebounce = useDebounce(employeeSearch, 300);
  // TODO: enableReinitializate Пока отключен, потестировать без него
  const [enableRenitializate, setEnableRenitializate] = useState<boolean>(
    state?.enableReinitializate
  );
  const [inputValidateFields, setInputValidateFields] = useState<any>(
    state?.inputValidateFields || []
  );
  const [selectValidateFields, setSelectValidateFields] = useState<any>(
    state?.selectValidateFields || []
  );
  const [isCheckGroupRechangeConcat, setIsCheckGroupRechangeConcat] = useState<any>(
    ( JSON.parse(window.sessionStorage.getItem('isCheckGroup')!) ? JSON.parse(window.sessionStorage.getItem('isCheckGroup')!) : null ) || state?.isCheckGroup || []
  );
  const [isCheckRechangeConcat, setIsCheckRechangeConcat] = useState<any>(
    ( JSON.parse(window.sessionStorage.getItem('isCheck')!) ? JSON.parse(window.sessionStorage.getItem('isCheck')!) : null ) || state?.isCheck || []
  );
  const [isCheckGroupRechange, setIsCheckGroupRechange] = useState(
    (( JSON.parse(window.sessionStorage.getItem('isCheckGroup')!) ? JSON.parse(window.sessionStorage.getItem('isCheckGroup')!) : null ) || state?.isCheckGroup || []).sort((a: any, b: any) => {
      if (`${a.name.toLowerCase()}${a.surname.toLowerCase()}` > `${b.name.toLowerCase()}${b.surname.toLowerCase()}`) {
        return 1;
      }

      if (`${a.name.toLowerCase()}${a.surname.toLowerCase()}` < `${b.name.toLowerCase()}${b.surname.toLowerCase()}`) {
        return -1;
      }

      return 0;
      
    })
  );
  
  const [isCheckRechange, setIsCheckRecnahge] = useState<any>(
    ( JSON.parse(window.sessionStorage.getItem('isCheck')!) ? JSON.parse(window.sessionStorage.getItem('isCheck')!) : null ) || state?.isCheck || []
  );
  const [isDeletedOperators, setIsDeletedOperators] = useState<any>([]);

  const [removedProviders, setRemovedProviders] = useState<any>(
    state?.removedProviders || []
  );
    
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = elementsCount || 8;
  const [endOffset, setEndOffset] = useState<any>(itemOffset + itemsPerPage);
  const pageCount = Math.ceil((getPriceListModel.getPriceListDetail || []).length / itemsPerPage);
  // TODO: Test memo in future
  const isCheckGroupRechangeMemo = useMemo(() => isCheckGroupRechange, [isCheckGroupRechange]);
  // Edit data
  const [ currentOperatorData, setCurrentOperatorData ] = useState<any>({});
  const [initialReplacedDatasAtEditFields, setInitialReplacedDatasAtEditFields] = useState({});
  const [initialReplacedDatasAtFields, setInitialReplacedDatasAtFields] = useState({});

  const isGetDetailPriceListLoading = getPriceListDetailAsyncState.getLoadingState;
  const isEmployeeUpdatedLoading = getUpdateEmployeeAsyncState.getLoadingState;

  const validationSchema = Yup.object().shape(
    Object.entries(
      [...inputValidateFields, ...selectValidateFields]?.reduce(
        (acc: any, item: any) => Object.assign(acc, item.initialValue),
        {}
      )
    )
      .map(([key, valuesData]: [string, any]): any => ({
        [`${key}`]: Yup.object().shape({
          amount: Yup.number()
            .min(
              valuesData.currentOperator.minAmount,
              `Must be greater than or equal to ${valuesData.currentOperator.minAmount}`
            )
            .max(
              valuesData.currentOperator.maxAmount,
              `Must be less than or equal to ${valuesData.currentOperator.maxAmount}`
            )
        }),
      }))
      .reduce((acc: any, item: any) => Object.assign(acc, item), {})
  );

  const [uniqueProviders, setUniqueProviders] = useState<any>(
    removeDuplicates(
      isCheckGroupRechange.reduce((acc: any[], accountsItem: any) => {
        if (accountsItem?.accounts?.length >= 1) {
          acc.push(...accountsItem?.accounts?.map((provider: any) => provider?.provider));
        }
        return acc;
      }, []),
      (item: any) => item.id
    ).sort((a: any, b: any) => {
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }

      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }

      return 0;
    })
  );
  const onSubmit = (values: any) => {

    if (whichSubmitButtonClicked === '1') {
      const resultData: any = [];
      delete values.operator;

      Object.entries(values
        // TODO: Потестировать пока без initialValues
        //  || state?.forInitialValues
      ).map(
        ([key, valuesData]: [string, any]): any => {
          removedProviders.map((provider: any) => {
            if (key.includes(provider.replace('.', ''))) {
              delete values[key];
            }
          });

          if (key.includes('choose')) {
            selectValidateFields.map((item: any) => {
              if (item?.accountId === valuesData?.accountId) {
                resultData.push(valuesData);
              }
            });
          }

          // if (key.includes('singleId')) {
          //   inputValidateFields.map((item: any) => {
          //     if (item?.accountId === valuesData?.accountId) {
          //       item.amount = valuesData?.amount;
          //       item.value = valuesData?.amount;
          //       item.label = `${valuesData?.amount} UGX`;
          //       item.singleValue = `${valuesData?.amount} UGX`;
          //       item.description = valuesData?.description;
          //       item.currentOperator.description = valuesData?.description;
          //       resultData.push(item);
          //     }
          //     // if (item?.amount === valuesData || valuesData) {
          //     // }
          //   });
          // }

          if (key.includes('singleId')) {
            inputValidateFields.map((item: any) => {
              if (item?.amount === valuesData || valuesData) {
                resultData.push(item);
              }
            });
          }

        }
      );
      const replacedResultData: any = removeDuplicates(
        resultData,
        (key: any) => `${key?.currentOperator?.id}${key.employeeId}`
      //   TODO: Подкорректировать корректность isOperatorActive. Что бы не было реверса значения.
      ).filter((operatorFilter: any) => isCheckRechange.includes(operatorFilter.employeeId)).filter(( checkAmount: any ) => checkAmount?.amount).filter((checkOnRemoved: any) => !removedProviders.includes(checkOnRemoved?.providerId)).filter((item: any) => item?.uniqueId).filter((item: any) => !item?.isOperatorActive);
      const resultProviders: any = removeDuplicates(
        [
          ...replacedResultData.reduce((acc: any, item: any) => {
            if (item.employeeId === item?.currentOperator?.employeeId) {
              acc.push({ ...item?.currentOperator, amount: item.amount });
            }
            return acc;
          }, [])
        ],
        (key: any) => key.id
      );
      
      const resultProvidersAll = [
        ...replacedResultData?.reduce((acc: any, item: any) => {
          acc.push({ ...item?.currentOperator, amount: item.amount, accountId: item.accountId });
          return acc;
        }, [])
      ];

      const formatResultData = removeDuplicates(replacedResultData, (key: any) => key.employeeId)
        .map((operator: any) =>
          removeDuplicates(
            resultProvidersAll.map((provider: any) => {
              if (operator.employeeId === provider.employeeId) {
                operator.providers = [...operator.providers, provider];
              }
              return {
                ...operator,
                providers: removeDuplicates(
                  operator?.providers?.filter(
                    (providerItem: any) => !removedProviders.includes(providerItem?.id)
                  ),
                  (key: any) => key.id
                )
              };
            }),
            (key: any) => key.id
          )
        )
        .flat(Infinity);

      const formatResultDataReverse = removeDuplicates(resultProviders, (key: any) => key.id)
        .map((provider: any) =>
          removeDuplicates(
            replacedResultData.map((operator: any) => {
              if (provider?.id === operator?.providerId) {
                provider.operators = removeDuplicates(
                  [...provider?.operators, operator],
                  (key: any) => key.employeeId
                );
              }
              return { ...provider };
            }),
            (key: any) => key.id
          )
        )
        .flat(Infinity);
        
      const formatPaymentData = {
        clientId: getAuthMe?.id,
        transfers: formatResultData?.reduce((acc: any, employee: any) => {
          employee?.providers?.map((provider: any) => {
            acc.push({
              accountId: provider.accountId,
              amount: !provider?.priceListId ? +provider?.amount : null,
              priceListId: provider?.priceListId || null,
              // TODO: Когда будет лететь с бека defaultScenario, поменять - provider?.defaultScenario?.id на актуальные данные
              scenarioId: provider?.defaultScenario?.id || null
            });
          }
          
          );
          return acc;
        }, [])
      };

      navigate('payment-confirmation', {
        state: {
          replacedResultData,
          formatResultData,
          formatPaymentData,
          removedProviders,
          formatResultDataReverse,
          totalAmount: formatResultData
            ?.map((employee: any) => employee.providers)
            .map((provider: any) => provider)
            .flat(Infinity)
            .reduce((acc: any, count: any) => (acc += +count.amount), 0),
          // TODO: Потестировать пока без initialValues
          // forInitialValues: values,
          isCheck: isCheckRechange,
          isCheckGroup: isCheckGroupRechange,
          // providers:
          // ( JSON.parse(window.sessionStorage.getItem('providers')!) ? JSON.parse(window.sessionStorage.getItem('providers')!) : null )
          // ,
          inputValidateFields,
          selectValidateFields,
          group: state?.group,
          uniqueProviders,
          enableRenitializate,
          goBack: state?.goBack,
          fromTable: true
        }
      });
    }

    toast.dismiss();
  };
  
  const animations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  const handleHorizantalScroll = (element: any, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const handlerAddNewProvider = () => {
    setIsSelectCreated(true);
    handleHorizantalScroll(payEl.current, 1, 9999, -150);
    setMenuIsOpen(false);
  };
  const handlerReplaceProviderSelect = async (e: any, eMeta: any) => {
    // priceList?.getPriceListDetailAction({ id: e?.id });
    
    // setIsCheckGroupRechange(removeDuplicates(isCheckGroupRechange?.reduce((acc: any, employee: any) => toJS(getEmployeesModel.getEmployeesList).filter((filterEmployee: any) => filterEmployee?.id === employee?.id).map((newEmployee: any) => {
    //   acc.push({
    //     ...employee,
    //     accounts: !state?.group ? removeDuplicates([ ...employee?.accounts, ...newEmployee?.accounts?.filter((newEmployeeAccounts: any) => newEmployeeAccounts?.provider?.id === e?.id) ], (key: any) => key?.id) :
    //       removeDuplicates([ ...employee?.accounts, ...newEmployee?.accounts?.filter((newEmployeeAccounts: any) => newEmployeeAccounts?.provider?.id === e?.id) ]
    //         .map((employeeForGroup: any) => toJS(state?.group)?.groupsAccounts.map((groupItem: any) => {
    //           if (employeeForGroup?.provider?.id === e?.id && groupItem?.provider?.id === employeeForGroup?.provider?.id) {
    //             employeeForGroup.defaultPrice = !groupItem?.priceList ? groupItem?.defaultPrice : null;
    //             employeeForGroup.defaultPriceList = groupItem?.priceList ? groupItem?.priceList : null;
    //             employeeForGroup.priceList = groupItem?.priceList ? groupItem?.priceList : null;
    //           }
    //           return employeeForGroup;
    //         })).flat(Infinity), (key: any) => key?.id) 
    //   });
      
      
    //   return acc;
    // }), []).flat(Infinity), (key: any) => key?.id));


    // window.sessionStorage.setItem('isCheckGroup', JSON.stringify(removeDuplicates(isCheckGroupRechange?.reduce((acc: any, employee: any) => toJS(getEmployeesModel.getEmployeesList).filter((filterEmployee: any) => filterEmployee?.id === employee?.id).map((newEmployee: any) => {
    //   acc.push({
    //     ...employee,
    //     accounts: !state?.group ? removeDuplicates([ ...employee?.accounts, ...newEmployee?.accounts?.filter((newEmployeeAccounts: any) => newEmployeeAccounts?.provider?.id === e?.id) ], (key: any) => key?.id) :
    //       removeDuplicates([ ...employee?.accounts, ...newEmployee?.accounts?.filter((newEmployeeAccounts: any) => newEmployeeAccounts?.provider?.id === e?.id) ]
    //         .map((employeeForGroup: any) => toJS(state?.group)?.groupsAccounts.map((groupItem: any) => {
    //           if (employeeForGroup?.provider?.id === e?.id && groupItem?.provider?.id === employeeForGroup?.provider?.id) {
    //             employeeForGroup.defaultPrice = !groupItem?.priceList ? groupItem?.defaultPrice : null;
    //             employeeForGroup.defaultPriceList = groupItem?.priceList ? groupItem?.priceList : null;
    //             employeeForGroup.priceList = groupItem?.priceList ? groupItem?.priceList : null;
    //           }
    //           return employeeForGroup;
    //         })).flat(Infinity), (key: any) => key?.id) 
    //   });
      
    //   return acc;
    // }), []).flat(Infinity), (key: any) => key?.id)));

    // window.sessionStorage.setItem('providers', JSON.stringify(
    //   removeDuplicates(
    //     removeDuplicates(
    //       ( JSON.parse(window.sessionStorage.getItem('providers')!) ? JSON.parse(window.sessionStorage.getItem('providers')!) : null )
    //         ?.filter(
    //           (allProviders: any) => allProviders.id === e.id
    //         ),
    //       (key: any) => key.id
    //     ).length > 0
    //       ? [
    //         ...removeDuplicates(
    //           ( JSON.parse(window.sessionStorage.getItem('providers')!) ? JSON.parse(window.sessionStorage.getItem('providers')!) : null )
    //             ?.filter((allProviders: any) => allProviders.id === e.id),
    //           (key: any) => key.id
    //         ),
    //         ...uniqueProviders
    //       ]
    //       : [
    //         ...removeDuplicates(
    //           toJS(getProvidersModel?.getProvidersList)?.filter(
    //             (provider: any) => provider?.id === e.id
    //           ),
    //           (key: any) => key.id
    //         ),
    //         ...uniqueProviders
    //       ],
    //     (item: any) => item.id
    //   )
    // ));
  
    setIsSelectCreated(false);

    await setUniqueProviders((prevState: any) =>
      removeDuplicates(
        removeDuplicates(
          removeDuplicates(
            isCheckGroupRechange.reduce((acc: any[], accountsItem: any) => {
              if (accountsItem?.accounts?.length >= 1) {
                acc.push(...accountsItem?.accounts?.map((provider: any) => provider?.provider));
              }
              return acc;
            }, []),
            (item: any) => item.id
          ).sort((a: any, b: any) => {
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }

            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }

            return 0;
          })?.filter(
            (allProviders: any) => allProviders.id === e.id
          ),
          (key: any) => key.id
        ).length > 0
          ? [
            ...removeDuplicates(
              removeDuplicates(
                isCheckGroupRechange.reduce((acc: any[], accountsItem: any) => {
                  if (accountsItem?.accounts?.length >= 1) {
                    acc.push(...accountsItem?.accounts?.map((provider: any) => provider?.provider));
                  }
                  return acc;
                }, []),
                (item: any) => item.id
              ).sort((a: any, b: any) => {
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                  return 1;
                }

                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                  return -1;
                }

                return 0;
              })?.filter((allProviders: any) => allProviders.id === e.id),
              (key: any) => key.id
            ),
            ...prevState
          ]
          : [
            ...removeDuplicates(
              toJS(getProvidersModel?.getProvidersList)?.filter(
                (provider: any) => provider?.id === e.id
              ),
              (key: any) => key.id
            ),
            ...prevState
          ],
        (item: any) => item.id
      )
    );

    e.singleValue = e.value;
  };

  // Используем коллекцию для определения уникальных провайдеров
  const selectUnique = (allProviders: any) =>
    ![...new Set(uniqueProviders?.map((item: any) => item.id))].includes(allProviders.id);

  const removeProvider = (provider: any) => {
    setIsCheckGroupRechange(isCheckGroupRechange?.reduce((employeeAcc: any, employee: any) => {
      employeeAcc.push({
        ...employee,
        accounts: employee?.accounts?.filter((employeeAccounts: any) => employeeAccounts?.provider?.id !== provider?.id)
      });
      return employeeAcc;
    }, []));

    window.sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroupRechange?.reduce((employeeAcc: any, employee: any) => {
      employeeAcc.push({
        ...employee,
        accounts: employee?.accounts?.filter((employeeAccounts: any) => employeeAccounts?.provider?.id !== provider?.id)
      });
      return employeeAcc;
    }, [])));

    // window.sessionStorage.setItem('providers', JSON.stringify(uniqueProviders?.filter((uniqueProvider: any) => provider.id !== uniqueProvider.id)));

    setUniqueProviders(
      uniqueProviders?.filter((uniqueProvider: any) => provider.id !== uniqueProvider.id)
    );

    setInputValidateFields(
      inputValidateFields?.filter((item: any) => item.providerId !== provider.id)
    );

    setSelectValidateFields(selectValidateFields.filter((item: any) => item?.id !== provider?.id));
  };
  const checkLengthOfEmployees = () =>
    isCheckGroupRechange?.length > 5
      ? { minWidth: '11.5625rem', maxWidth: '11.5625rem' }
      : { minWidth: '11.726rem', maxWidth: '11.726rem' };
  const checkSelectLength = () =>
    getProvidersModel?.getProvidersList
      .filter((allProviders: any) => selectUnique(allProviders))
      .map((item: any) => !!item?.name);

  const handleOperatorIdModal = (e: any, uniqueItem: any, payment: any) => {
    const defaultValues = payment?.accounts?.length ? payment?.accounts?.filter((account: any) => account?.provider?.id === uniqueItem?.id)?.[0] : '';
    const defaultPrice = defaultValues?.defaultPrice || defaultValues?.defaultPriceList?.price || defaultValues?.defaultPriceListEdit?.price;
    const defaultPriceListId = defaultValues?.defaultPriceList?.id || defaultValues?.defaultPriceListEdit?.id;
    const defaultLabel = defaultValues?.defaultPriceList?.label || defaultValues?.defaultPriceListEdit?.label;
    const defaultDescription = defaultValues?.defaultPriceList?.description || defaultValues?.defaultPriceListEdit?.description;

    setChooseFromModal({
      uniqueName: '',
      value: defaultPrice,
      amount: defaultPrice,
      description: defaultDescription || `${uniqueItem.title} services`,
      label: defaultLabel,
      singleValue: defaultPrice,
      accountId: '',
      providerId: uniqueItem?.id,
      defaultScenario: defaultValues?.defaultScenario || null,
      currentOperator: {
        ...uniqueItem,
        priceListId: defaultPriceListId,
        employeeId: payment.id,
        icon: '',
        description: defaultDescription || `${uniqueItem.title} services`,
        operators: [],
        defaultScenario: defaultValues?.defaultScenario || null,
      },
      name: payment?.name,
      surname: payment?.surname,
      providers: [],
      employeeId: payment?.id
    });
    setIsModalOpen(true);
    setWhichSubmitButtonClicked('2');
  };

  const onHandleSearch = (setter: (val: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  const handleInputValue = (e: any, selectedItem: any, employeeInfo: any, mainProvider?: any) => {
    mainProvider.isDefaultValue = false;
    setInputValidateFields([...inputValidateFields, employeeInfo]);
    setEnableRenitializate(false);
    window.sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroupRechange?.reduce((employeeAcc: any, employee: any) => {
      employeeAcc.push({
        ...employee,
        accounts: employee?.accounts?.map((employeeAccounts: any) => {
          if (employeeAccounts?.provider?.id === employeeInfo?.providerId && employeeInfo?.employeeId === employee.id) {
            employeeAccounts.defaultPrice = employeeInfo?.amount;
            employeeAccounts.defaultPriceList = null;
            employeeAccounts.priceList = null;
          }
          return employeeAccounts;
        })
      });
      return employeeAcc;
    }, [])));

    if (!e) {
      setInputValidateFields(
        inputValidateFields.filter(
          (item: any) =>
            item.employeeId !== employeeInfo.employeeId ||
            item.providerId !== employeeInfo.providerId
        )
      );
    }
  };

  const handleCheckSelectFields = (e: any, eMeta: any) => {
    setSelectValidateFields([...selectValidateFields, e?.currentOperator]);
    window.sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroupRechange?.reduce((employeeAcc: any, employee: any) => {
      employeeAcc.push({
        ...employee,
        accounts: employee?.accounts?.map((employeeAccounts: any) => {
          if (employeeAccounts?.provider?.id === e?.providerId && e?.employeeId === employee.id) {
            employeeAccounts.defaultPriceList = { description: e?.description, id: e?.providerId, label: e?.label, prive: e?.amount, provider: e?.currentOperator };
            employeeAccounts.priceList = { description: e?.description, id: e?.providerId, label: e?.label, prive: e?.amount, provider: e?.currentOperator };
          }
          return employeeAccounts;
        })
      });
      return employeeAcc;
    }, [])));
  };

  const handleChooseEmployees = () => {
    setIsAppendEmployeesModal(!isAppendEmployeesModal);
  };

  const removeMobileSelected = (e: any, provider: any) => {
    e.stopPropagation();

    setIsCheckGroupRechange(isCheckGroupRechange?.reduce((employeeAcc: any, employee: any) => {
      employeeAcc.push({
        ...employee,
        accounts: employee?.accounts?.filter((employeeAccounts: any) => employeeAccounts?.provider?.id !== provider?.id)
      });
      return employeeAcc;
    }, []));

    window.sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroupRechange?.reduce((employeeAcc: any, employee: any) => {
      employeeAcc.push({
        ...employee,
        accounts: employee?.accounts?.filter((employeeAccounts: any) => employeeAccounts?.provider?.id !== provider?.id)
      });
      return employeeAcc;
    }, [])));

    setUniqueProviders(
      uniqueProviders?.filter((uniqueProvider: any) => provider.id !== uniqueProvider.id)
    );
 
    setInputValidateFields(
      inputValidateFields?.filter((item: any) => item.providerId !== provider.id)
    );
    setSelectValidateFields(selectValidateFields.filter((item: any) => item?.id !== provider?.id));
  };

  const handlePriceListSelect = (mainProviders: any, employee: any) => {
    mainProviders.isDefaultValue = false;
    priceList?.getPriceListDetailAction(mainProviders?.provider);
    setDynamicPage(1);
    setItemOffset(0);
    setEndOffset(8);
    setCurrentOperatorData({ ...mainProviders, employee });
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (
      // !window.sessionStorage.getItem('previousNav')! ||
      !JSON.parse(window.sessionStorage.getItem('isCheckGroup')!)?.length || !JSON.parse(window.sessionStorage.getItem('isCheck')!)?.length
    // TODO: Нужно потестировать без providers
    // || !JSON.parse(window.sessionStorage.getItem('providers')!)?.length
    ) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    setInitialReplacedDatasAtEditFields({
      [`uniqueId${currentOperatorData?.provider?.id}${currentOperatorData?.employee?.id}`?.split('.').join('')]: currentOperatorData?.uniqueId,
      // TODO: Сделать дефолтное значение выбранного сценария когда будет лететь с бека
      [`scenario${currentOperatorData?.provider?.id}${currentOperatorData?.employee?.id}`?.split('.').join('')]: currentOperatorData?.defaultScenario?.id ? { value: currentOperatorData?.defaultScenario?.id, label: currentOperatorData?.defaultScenario?.label, singleValue: currentOperatorData?.defaultScenario?.label } : null,
      [`singleId${currentOperatorData?.provider?.id}${currentOperatorData?.employee?.id}`?.split('.').join('')]: { 
        accountId: currentOperatorData?.id,
        amount: currentOperatorData?.defaultPrice,
        currentOperator: {
          ...currentOperatorData?.provider,
          icon: null,
          priceListId: null,
          operators: [],
          employeeId: currentOperatorData?.employee?.id,
          description: `${currentOperatorData?.provider?.title} services`,
        },
        description: `${currentOperatorData?.provider?.title} services`,
        employeeId: currentOperatorData?.employee?.id,
        label: null,
        singleValue: null,
        name: currentOperatorData?.employee?.name,
        surname: currentOperatorData?.employee?.surname,
        providerId: currentOperatorData?.provider?.id,
        providers: [],
        value: currentOperatorData?.defaultPrice
      },
      [`choose${currentOperatorData?.provider?.id}${currentOperatorData?.employee?.id}`?.split('.').join('')]: currentOperatorData?.defaultPriceList?.id || currentOperatorData?.defaultPriceListEdit?.id ? { 
        accountId: currentOperatorData?.id,
        amount: currentOperatorData?.defaultPrice,
        currentOperator: {
          ...currentOperatorData?.provider,
          icon: null,
          priceListId: currentOperatorData?.defaultPriceList?.id || currentOperatorData?.defaultPriceListEdit?.id,
          operators: [],
          employeeId: currentOperatorData?.employee?.id,
          description: currentOperatorData?.defaultPriceList?.description || currentOperatorData?.defaultPriceListEdit?.description,
        },
        description: currentOperatorData?.defaultPriceList?.description || currentOperatorData?.defaultPriceListEdit?.description,
        employeeId: currentOperatorData?.employee?.id,
        label: currentOperatorData?.defaultPriceList?.description || currentOperatorData?.defaultPriceListEdit?.description,
        singleValue: currentOperatorData?.defaultPriceList?.description || currentOperatorData?.defaultPriceListEdit?.description,
        name: currentOperatorData?.employee?.name,
        surname: currentOperatorData?.employee?.surname,
        providerId: currentOperatorData?.provider?.id,
        providers: [],
        value: currentOperatorData?.defaultPrice
      }
        :
        null,
    });
  }, [currentOperatorData]);

  useEffect(() => {
    setInitialReplacedDatasAtFields({
      [`uniqueId${chooseFromModal?.currentOperator?.id}${chooseFromModal?.employeeId}`?.split('.').join('')]: chooseFromModal?.uniqueId,
      // TODO: Сделать дефолтное значение выбранного сценария когда будет лететь с бека
      [`scenario${chooseFromModal?.currentOperator?.id}${chooseFromModal?.employeeId}`?.split('.').join('')]: chooseFromModal?.defaultScenario?.id ? { value: chooseFromModal?.defaultScenario?.id, label: chooseFromModal?.defaultScenario?.label, singleValue: chooseFromModal?.defaultScenario?.label } : null,
      [`singleId${chooseFromModal?.currentOperator?.id}${chooseFromModal?.employeeId}`?.split('.').join('')]: { 
        accountId: chooseFromModal?.accountId,
        amount: chooseFromModal?.amount,
        currentOperator: {
          ...chooseFromModal?.currentOperator,
          icon: null,
          priceListId: null,
          operators: [],
          employeeId: chooseFromModal?.employeeId,
          description: `${chooseFromModal?.currentOperator?.title} services`,
        },
        description: `${chooseFromModal?.currentOperator?.title} services`,
        employeeId: chooseFromModal?.employeeId,
        label: null,
        singleValue: null,
        name: chooseFromModal?.name,
        surname: chooseFromModal?.surname,
        providerId: chooseFromModal?.currentOperator?.id,
        providers: [],
        value: chooseFromModal?.amount
      },
      [`choose${chooseFromModal?.currentOperator?.id}${chooseFromModal?.employeeId}`?.split('.').join('')]: chooseFromModal?.currentOperator?.priceListId ? { 
        accountId: chooseFromModal?.accountId,
        amount: chooseFromModal?.amount,
        currentOperator: {
          ...chooseFromModal?.currentOperator,
          icon: null,
          priceListId: chooseFromModal?.currentOperator?.priceListId,
          operators: [],
          employeeId: chooseFromModal?.employeeId,
          description: chooseFromModal?.currentOperator?.description,
        },
        description: chooseFromModal?.currentOperator?.description,
        employeeId: chooseFromModal?.employeeId,
        label: chooseFromModal?.currentOperator?.description,
        singleValue: chooseFromModal?.currentOperator?.description,
        name: chooseFromModal?.name,
        surname: chooseFromModal?.surname,
        providerId: chooseFromModal?.currentOperator?.id,
        providers: [],
        value: chooseFromModal?.amount
      }
        :
        null,
    });
  }, [chooseFromModal]);

  useEffect(() => {
    providers.getProvidersAction();
  }, []);

  useEffect(() => {
    setInputValidateFields(
      isCheckGroupRechange
        ?.map((item: any): any =>
          item.accounts.reduce((acc: any, itemInner: any): any => {
            if (
              itemInner?.provider?.measure === ProviderMeasure.MONEY ||
          (itemInner?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY &&
            itemInner.defaultPrice || itemInner.defaultPriceList || itemInner.priceList)
            ) {
              acc.push({
                value: itemInner.defaultPrice || itemInner.defaultPriceList?.price || itemInner?.priceList?.price,
                amount: itemInner.defaultPrice || itemInner.defaultPriceList?.price || itemInner?.priceList?.price,
                accountId: itemInner.id,
                isOperatorActive: itemInner?.isOperatorActive || false,
                description: itemInner?.defaultPriceList?.id || itemInner?.priceList?.id ? itemInner?.defaultPriceList?.description || itemInner?.priceList?.description : `${itemInner?.provider.title} services`,
                label: `${itemInner.defaultPrice || itemInner.defaultPriceList?.price || itemInner?.priceList?.price} UGX`,
                singleValue: `${itemInner.defaultPrice || itemInner.defaultPriceList?.price || itemInner?.priceList?.price} UGX`,
                uniqueId: itemInner?.uniqueId,
                defaultScenario: itemInner?.defaultScenario || null,
                initialValue: {
                  [`singleId${itemInner.provider.id}${item.id}`?.split('.').join('')]: {
                    accountId: itemInner.id,
                    amount: itemInner.defaultPrice || itemInner.defaultPriceList?.price || itemInner?.priceList?.price,
                    currentOperator: {
                      ...itemInner.provider,
                      priceListId: itemInner.defaultPriceList?.id || itemInner?.priceList?.id,
                      employeeId: item.id,
                      icon: itemInner?.icon,
                      description: itemInner?.defaultPriceList?.id || itemInner?.priceList?.id ? itemInner?.defaultPriceList?.description || itemInner?.priceList?.description : `${itemInner?.provider.title} services`,
                      operators: [],
                      defaultScenario: itemInner?.defaultScenario || null,
                    },
                    description: itemInner?.defaultPriceList?.id || itemInner?.priceList?.id ? itemInner?.defaultPriceList?.description || itemInner?.priceList?.description : `${itemInner?.provider.title} services`,
                    employeeId: item.id,
                    label: itemInner.defaultPriceList?.label || itemInner?.priceList?.label,
                    singleValue: itemInner.defaultPriceList?.label || itemInner?.priceList?.label,
                    name: item?.name,
                    surname: item?.surname,
                    providerId: itemInner?.provider.id,
                    value: itemInner.defaultPrice || itemInner.defaultPriceList?.price || itemInner?.priceList?.price,
                    providers: [],
                    defaultScenario: itemInner?.defaultScenario || null,
                  }
                },
                providerId: itemInner?.provider.id,
                currentOperator: {
                  ...itemInner.provider,
                  priceListId: itemInner.defaultPriceList?.id || itemInner?.priceList?.id,
                  employeeId: item.id,
                  icon: itemInner?.icon,
                  description: itemInner?.defaultPriceList?.id || itemInner?.priceList?.id ? itemInner?.defaultPriceList?.description || itemInner?.priceList?.description : `${itemInner?.provider.title} services`,
                  operators: [],
                  defaultScenario: itemInner?.defaultScenario || null,
                },
                name: item?.name,
                surname: item?.surname,
                providers: [],
                employeeId: item?.id
              });
            }
            return acc;
          }, [])
        )
        .flat(Infinity)
    );

    setSelectValidateFields(
      isCheckGroupRechange
        ?.map((item: any): any =>
          item.accounts.reduce((acc: any, itemInner: any): any => {
            if (
              itemInner?.provider?.measure === ProviderMeasure.PACKAGES &&
                ( itemInner.defaultPriceList || itemInner.priceList )
            ) {
              acc.push({
                value: itemInner.defaultPriceList?.price || itemInner?.priceList?.price,
                amount: itemInner.defaultPriceList?.price || itemInner?.priceList?.price,
                accountId: itemInner.id,
                isOperatorActive: itemInner?.isOperatorActive || false,
                description: itemInner.defaultPriceList?.description || itemInner?.priceList?.description,
                label: itemInner.defaultPriceList?.label || itemInner?.priceList?.label,
                singleValue: itemInner.defaultPriceList?.label || itemInner?.priceList?.label,
                defaultScenario: itemInner?.defaultScenario || null,
                initialValue: {
                  [`choose${itemInner.provider.id}${item.id}`?.split('.').join('')]: {
                    accountId: itemInner.id,
                    amount: itemInner.defaultPriceList?.price || itemInner?.priceList?.price,
                    currentOperator: {
                      ...itemInner.provider,
                      priceListId: itemInner.defaultPriceList?.id || itemInner?.priceList?.id,
                      employeeId: item.id,
                      icon: itemInner?.icon,
                      description: itemInner.defaultPriceList?.description || itemInner?.priceList?.description,
                      operators: [],
                      defaultScenario: itemInner?.defaultScenario || null,
                    },
                    description: itemInner.defaultPriceList?.description || itemInner?.priceList?.description,
                    employeeId: item.id,
                    label: itemInner.defaultPriceList?.label || itemInner?.priceList?.label,
                    singleValue: itemInner.defaultPriceList?.label || itemInner?.priceList?.label,
                    name: item?.name,
                    uniqueId: itemInner?.uniqueId,
                    surname: item?.surname,
                    providerId: itemInner?.provider.id,
                    value: itemInner.defaultPriceList?.price || itemInner?.priceList?.price,
                    providers: [],
                    defaultScenario: itemInner?.defaultScenario || null,
                  }
                },
                providerId: itemInner?.provider.id,
                currentOperator: {
                  ...itemInner.provider,
                  priceListId: itemInner.defaultPriceList?.id || itemInner?.priceList?.id,
                  employeeId: item.id,
                  icon: itemInner?.icon,
                  description: itemInner.defaultPriceList?.description || itemInner?.priceList?.description,
                  operators: [],
                  defaultScenario: itemInner?.defaultScenario || null,
                },
                name: item?.name,
                surname: item?.surname,
                providers: [],
                employeeId: item?.id
              });
            }
            return acc;
          }, [])
        )
        .flat(Infinity)
    );
  }, [
    isCheckGroupRechange,
    isGetDetailPriceListLoading,
    inputValidateFields.length,
    selectValidateFields.length,
    window.sessionStorage,
    isAccountsLoading
  ]);

  useEffect(() => {
    if (isAccountsLoading === Loading.success) {
      window.sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroupRechange));
    }

  }, [isAccountsLoading, isCheckGroupRechange]);

  useEffect(() => {
    const isOpenInteractiveList = (e: any) => {
      groupButtonEl?.current?.contains(e.target) ||
      setOpenGroupPaymentDetail(false);
    };

    document.addEventListener('click', isOpenInteractiveList);
    return () => document.removeEventListener('click', isOpenInteractiveList);
  }, []);

  useEffect(() => {
    if (isPriceListDetailLoading) {
      setCurrentPriceListItems(toJS(getPriceListModel.getPriceListDetail));
    }
  }, [isPriceListDetailLoading]);

  const handlePageClick = (page: any) => {
    const newOffset = (page.selected * itemsPerPage) % (getPriceListModel.getPriceListDetail || []).length;
    setItemOffset(newOffset);
    setEndOffset(newOffset + itemsPerPage);
    setDynamicPage(page?.selected + 1);
  };

  const handleRepalceNumberField = (mainProviders: any, employee: any) => {
    setCurrentOperatorData({ ...mainProviders, employee });
    setIsModalOpen(true);
  };

  useEffect(() => {

    if (isAppendEmployeesModal && !isTablet) {
      document.body.style.overflow = 'hidden';
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }

  }, [isAppendEmployeesModal]);

  useEffect(() => {
    if (location.pathname.includes('employees')) {
      window.sessionStorage.removeItem('isCheckGroup');
      window.sessionStorage.removeItem('isCheck');
      window.sessionStorage.removeItem('isCheckAll');
    }
  }, [location.pathname]);

  return (
    <section className={s.wrapper}>
      <AnimatedDivPage transition={{ duration: 0.5 }} animations={animations}>
        <div className={s.wrapper__container}>
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={{
              initial: { opacity: 0 },
              animate: { opacity: 1 },
              exit: { opacity: 0 }
            }}
            transition={{ duration: 0.5, type: 'spring', damping: 22, stiffness: 155 }}
            className={s.back}
          >
            <ReactTooltip textColor="#334049" backgroundColor="#fff" border borderColor="#E5E8ED" />
            <motion.button
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
              className={s.back_icon}
              onClick={() => {
                window.sessionStorage.removeItem('isCheckGroup');
                window.sessionStorage.removeItem('isCheck');
                window.sessionStorage.removeItem('isCheckAll');
                navigate(state?.goBack || -1,
                  {
                    state: {
                      // TODO: Последить на state которые передается в пред. страницу
                      // isCheckGroup: isCheckGroupRechange?.filter((employee: any) => state?.isCheck.includes(employee.id)),
                      // isCheck: isCheckRechange,
                      // isCheckGroup: ( JSON.parse(window.sessionStorage.getItem('isCheckGroup')!) ? JSON.parse(window.sessionStorage.getItem('isCheckGroup')!) : null ),
                      // isCheck: ( JSON.parse(window.sessionStorage.getItem('isCheck')!) ? JSON.parse(window.sessionStorage.getItem('isCheck')!) : null ),
                      // isCheckAll: false
                      // providers: ( JSON.parse(window.sessionStorage.getItem('providers')!) ? JSON.parse(window.sessionStorage.getItem('providers')!) : null )
                    }
                  }
                );
              }
              }
            >
              <BackSVG />
            </motion.button>

            <div className={s.back_text}>
              <p>Back</p>
            </div>
          </motion.div>
          <FormikContainer
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={
              { 
                // TODO: Потестировать пока без initialValues
                // ...state?.forInitialValues ||
                ...[...inputValidateFields, ...selectValidateFields]?.reduce(
                  (acc: any, item: any) => Object.assign(acc, item.initialValue),
                  {}
                ), ...initialReplacedDatasAtEditFields, ...initialReplacedDatasAtFields }
            }
            validationSchema={validationSchema}
          >
            {({ values, setValues, errors, setFieldValue }: any) => {
              const resultData: any = [];
              Object.entries(values 
                // TODO: Потестировать пока без initialValues
                // || state?.forInitialValues
              ).map(
                ([key, valuesData]: [string, any]): any => {
                  removedProviders.map((provider: any) => {
                    if (key.includes(provider.replace('.', ''))) {
                      delete values[key];
                    }
                  });
                  
                  if (key.includes('choose')) {
                    selectValidateFields.map((item: any) => {
                      if (item?.accountId === valuesData?.accountId) {
                        resultData.push(valuesData);
                      }
                    });
                  }
                  
                  if (key.includes('singleId')) {
                    inputValidateFields.map((item: any) => {
                      if (item?.amount === valuesData || valuesData) {
                        resultData.push(item);
                      }
                    });
                  }
                  // if (key.includes('singleId')) {
                  //   inputValidateFields.map((item: any) => {
                  //     if (item?.accountId === valuesData?.accountId) {
                  //       item.amount = valuesData?.amount;
                  //       item.value = valuesData?.amount;
                  //       item.label = `${valuesData?.amount} UGX`;
                  //       item.singleValue = `${valuesData?.amount} UGX`;
                  //       item.description = valuesData?.description;
                  //       item.currentOperator.description = valuesData?.description;
                  //       resultData.push(item);
                  //     }
                  //   });
                  // }
                }
              );

              const replacedResultData: any = removeDuplicates(
                resultData,
                (key: any) => `${key?.currentOperator?.id}${key.employeeId}`
              ).filter((operatorFilter: any) => isCheckRechange.includes(operatorFilter.employeeId)).filter(( checkAmount: any ) => checkAmount?.amount).filter((checkOnRemoved: any) => !removedProviders.includes(checkOnRemoved?.providerId)).filter((item: any) => item?.uniqueId);
              return ( <>
                <div className={s.wrapper__title}>
                  <h1 className={s.title}>{t<string>('MAIN.NEWPAYMENT.TITLE')}</h1>

                  {state?.group?.title ? (
                    <div className={s.header__group__button}>
                      <div className={s.group__info}>
                        <button ref={groupButtonEl} className={`${ openGroupPaymentDetail ? s.group__button_border_change : '' }`} onClick={() => setOpenGroupPaymentDetail(!openGroupPaymentDetail)} type="button">
                          <div className={s.group__icon}>
                            <GroupSVG />
                          </div>
                          <p>{state?.group?.title}</p>
                          <div className={`${s.group__icon_drop} ${ openGroupPaymentDetail ? s.group__icon_drop_open : '' }`}>
                            <DropSelectSVG />
                          </div>
                        </button>

                        <div ref={additionalGroupEl} className={`${s.group__additional_info} ${ openGroupPaymentDetail ? s.group__additional_info_show : ''}`}>
                          <p><strong>{t<string>('MAIN.NEWPAYMENT.GROUP.TOTAL')}</strong> {Number(isCheckGroupRechange?.map((employee: any) => employee?.accounts).flat(Infinity)?.filter((accountItem: any) => accountItem?.uniqueId)?.reduce((accountAcc: any, accountItem: any) => accountAcc += (+accountItem?.defaultPrice || +accountItem?.defaultPriceList?.price) || 0, 0))?.toFixed(2)?.toLocaleString()} UGX</p>
                          <p><strong>{t<string>('MAIN.NEWPAYMENT.GROUP.EMPLOYEES')}</strong> {isCheckRechange?.length}</p>
                        </div>
                      </div>

                      <RouterLink key={state?.group?.id} to={`/groups/groups-detail/${state?.group?.id}`} state={{ previousPageWithQuery: '/employees' }}>
                        <div className={s.link__icon}>
                          <LinkSVG />
                        </div>
                      </RouterLink>
                    </div>
                  ) : null}

                  <div className={s.wrapper__button}>
                    <motion.button
                      disabled={
                        // !replacedResultData?.length
                        // !inputValidateFields.length
                        // !isCheckGroupRechange?.map((item: any) => item?.accounts).flat(1).map((accountItem: any) => accountItem?.uniqueId)?.filter((uniqueId: any) => uniqueId).length
                        !isCheckGroupRechange?.map((item: any) => item?.accounts).flat(1).filter((accountItem: any) => accountItem?.uniqueId).reduce((resultAcc: any[], result: any): boolean[] => {

                          if ((result.defaultPrice || result.defaultPriceList || result.defaultPriceListEdit) && !result.isOperatorActive) {
                            resultAcc.push(true);
                          } else {
                            resultAcc.push(false);
                          }
                        
                          return resultAcc;
                      
                        }, []).some((el: any) => el)
                      }
                      whileTap={
                        selectValidateFields?.length || inputValidateFields?.length
                          ? { scale: 0.95 }
                          : {}
                      }
                      whileHover={
                        selectValidateFields?.length || inputValidateFields?.length
                          ? { scale: 1.05 }
                          : {}
                      }
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                      onClick={() => setWhichSubmitButtonClicked('1')}
                      value="Submit main"
                      formAction="/submit/b"
                    >
                      <p>{t<string>('MAIN.NEWPAYMENT.BUTTONS.SUBMIT')}</p>
                      <div className={s.button__icon}>
                        <PaySVG />
                      </div>
                    </motion.button>
                  </div>
                </div>
                <div className={s.search}>
                <FormikTextField
                  placeholder={t<string>('MAIN.NEWPAYMENT.SEARCH.PLACEHOLDER')}
                  name="addEmployeeSearch"
                  value={employeeSearch}
                  id={'addEmployeeSearch'}
                  onChange={onHandleSearch(setEmployeeSearch)}
                >
                  <div className={s.search__icon}>
                    <LoupeSVG />
                  </div>
                </FormikTextField>
                </div>
                <div
                  // onClick={() => setMenuIsOpen(!menuIsOpen)}
                  className={s.select__provider__mobile}
                >
                  <div
                    // onClick={() => setMenuIsOpen(!menuIsOpen)}
                    className={s.select__provider__wrapper}
                  >
                    <FormikSelect
                      optionIcon={<DeleteLightSVG />}
                      onMenuOpen={() => setMenuIsOpen(true)}
                      onMenuClose={() => setMenuIsOpen(false)}
                      selectWrapperStyles={{ width: '100%' }}
                      selectMobileIconStyles={{ display: 'flex' }}
                      selectMobileStyles={{ display: 'flex', justifyContent: 'space-between' }}
                      containerStyles={{
                        width: '100%',
                        height: 'auto'
                      }}
                      wrapperSelectStyles={{ width: '100%' }}
                      valueContainer={{
                        paddingLeft: '.5rem',
                        height: '3rem',
                        maxHeight: '3rem'
                      }}
                      indicatorContainerStyles={{ height: 'auto', width: '3rem' }}
                      controlStyles={{
                        minHeight: '3rem',
                        maxHeight: '3rem'
                      }}
                      // hideInputContainerWrapper={{
                      //   width: 0,
                      //   margin: 0,
                      //   padding: 0,
                      //   maxHeight: 0
                      // }}
                      valueStyles={{ color: ColorsAccent.accentDark }}
                      name="operator"
                      id="operator"
                      isOptionDisabledProvider={toJS(getProvidersModel?.getProvidersList)}
                      options={uniqueProviders.map((provider: any) => ({
                        value: provider?.title,
                        label: provider?.title,
                        singleValue: provider?.title,
                        id: provider?.id,
                        isDisabled: provider?.availability === 'Disabled',
                        genre: ['pop'],
                        optionIcon: (
                          <div className={s.select__mobile__icon}>
                            <button
                              onClick={(e: any) => removeMobileSelected(e, provider)}
                              type="button"
                              className={s.select__mobile__icon_button}
                            >
                              <DeleteLightSVG />
                            </button>
                          </div>
                        ),
                        selectMobileIconStyles: { display: 'flex' },
                        selectMobileStyles: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }
                      }))}
                      // isSearchable={false}
                      placeholder={t<string>('MAIN.NEWPAYMENT.MOBILEFORM.APPENDOPERATORPLACEHOLDER')}
                      handleMenuIsOpen={menuIsOpen}
                      onBlur={ (e: any) => e.preventDefault()}
                      indicatorIcon={<DropSelectSVG />}
                      addNewProvider={
                        <div className={s.select__mobile_service}>
                          <motion.button
                            type="button"
                            className={s.select__mobile_service_button}
                            onClick={handlerAddNewProvider}
                            // disabled={checkSelectLength()?.length < 1}
                          >
                              <p>{t<string>('MAIN.NEWPAYMENT.MOBILEFORM.APPENDOPERATORBUTTON')}</p>
                          </motion.button>
                        </div>
                      }
                      onChange={handlerReplaceProviderSelect}
                    />
                  </div>
                </div>

                <div className={s.pay__wrapper}>
                  <div
                    className={`${s.pay} ${
                      isCheckGroupRechange?.length >= 6 ? s.pay__scrollbar_modified : ''
                    }`}
                    ref={payEl}
                  >
                    <div className={s.pay__header}>
                      {paymentListTitles(t).map((titles) => (
                        <div
                          key={titles.id}
                          className={`${s.pay__header_item} ${
                            isCheckGroupRechange?.length > 5 ? s.small_width : s.large_width
                          }`}
                        >
                          <div className={s.item}>
                            <p>{titles.name}</p>
                            <div className={s.item__icons}>
                              {titles.removeIcon}
                              {titles.optionsIcon}
                            </div>
                          </div>
                        </div>
                      ))}
                      {isSelectCreated && (
                        <div
                          className={`${s.select__provider__wrapper} ${
                            isCheckGroupRechange?.length > 5 ? s.small_width : s.large_width
                          }`}
                          // style={checkLengthOfEmployees()}
                        >
                          <FormikSelect
                            containerStyles={{
                              width: '9.5625rem',
                              height: 'auto'
                            }}
                            valueContainer={{
                              paddingLeft: '.5rem',
                              height: '2.0625rem',
                              maxHeight: '2.0625rem'
                            }}
                            indicatorContainerStyles={{ height: 'auto', width: '2.5rem' }}
                            controlStyles={{
                              minHeight: '2.0625rem',
                              maxHeight: '2.0625rem'
                            }}
                            // hideInputContainerWrapper={{
                            //   width: 0,
                            //   margin: 0,
                            //   padding: 0,
                            //   maxHeight: 0
                            // }}
                            valueStyles={{
                              color: 'hsl(0, 0%, 50%)'
                            }}
                            name="operator"
                            id="operator"
                            isOptionDisabledProvider={toJS(getProvidersModel?.getProvidersList)}
                            // searchField={
                            //   <div className={s.select__search}>
                            //     <FormikTextField
                            //       placeholder="Search"
                            //       name="searchProviders"
                            //       value={employeeSearch}
                            //       id='searchProviders'
                            //       onChange={onHandleSearch(setEmployeeSearch)}
                            //     >
                            //       <div className={s.search__icon}>
                            //         <LoupeSVG />
                            //       </div>
                            //     </FormikTextField>
                            //   </div>
                            // }
                            options={getProvidersModel?.getProvidersList
                              .filter((allProviders: any) => selectUnique(allProviders))
                              .map((item: any) => ({
                                value: item?.title,
                                label: item?.title,
                                singleValue: item?.title,
                                id: item?.id,
                                isDisabled: item?.availability === 'Disabled',
                                genre: ['pop']
                              }))}
                            isSearchable
                            placeholder={t<string>('MAIN.NEWPAYMENT.FORM.SELECTPLACEHOLDER')}
                            indicatorIcon={<DropSelectSVG />}
                            onChange={handlerReplaceProviderSelect}
                          />
                        </div>
                      )}

                      {uniqueProviders?.map((provider: any, index: any) => (
                        <div
                          key={provider?.id}
                          className={`${s.pay__header_item} ${
                            isCheckGroupRechange?.length > 5 ? s.small_width : s.large_width
                          }`}
                        >
                          <div className={s.item}>
                            <p>{provider?.title}</p>
                            <div className={s.item__buttons}>
                              <button
                                id={provider?.id}
                                onClick={() => removeProvider(provider)}
                                type="button"
                                className={s.item__button}
                              >
                                <DeleteLightSVG />
                              </button>

                              <button type="button" className={s.item__button}>
                                <AdditionSVG />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className={s.pay__main}>
                      {isCheckGroupRechange?.filter((item: any) => item?.name?.includes(employeeSearchDebounce))?.map((payment: any, i: number) => (
                        <motion.div
                          initial="hidden"
                          animate="visible"
                          variants={{
                            hidden: { opacity: 0 },
                            visible: {
                              pathLength: 1,
                              opacity: 1,
                              transition: {
                                delay: i * 0.05,
                                duration: 0.4,
                                type: 'spring',
                                damping: 10
                              }
                            }
                          }}
                          transition={{ duration: 0.7 }}
                          key={payment.id}
                          className={`${s.pay__items} ${
                            uniqueProviders?.length >= 1 && isSelectCreated ? s.pay__rotation : ''
                          }`}
                        >
                          {paymentListTitles(t)?.map((title: any) => (
                            <div
                              className={`${s.pay__item_scroll} ${
                                isCheckGroupRechange?.length > 5 ? s.small_width : s.large_width
                              } `}
                              key={title.id}
                            >
                              {payment[title.data] && (
                                <RouterLink to={`/employees/employees-detail/${payment.id}`} state={{ previousPageWithQuery: '/employees' }}>
                                  <div key={title.id} className={s.pay__item}>

                                    <div className={s.link}>
                                      <div className={s.fullname}>
                                        <p>{payment[title.data]}</p>
                                        
                                        <div className={s.item__icon}>
                                        <p>{payment[title.surname]}</p>
                                          <LinkSVG />
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </RouterLink>
                              )}
                            </div>
                          ))}
                          {isSelectCreated && (
                            <div
                              className={`${s.pay__create} ${
                                isCheckGroupRechange?.length > 5 ? s.small_width : s.large_width
                              } `}
                            />
                          )}
                          {uniqueProviders?.map((item: any, index: number) => (
                            <div
                              key={`${index}${payment.id}`}
                              ref={cellRef}
                              className={`${s.pay__create} ${
                                isCheckGroupRechange?.length > 5 ? s.small_width : s.large_width
                              } `}
                            >
                              {payment?.accounts?.map((mainProviders: any, accountsIndex: any) => (
                                <React.Fragment
                                  key={`${payment.id}${mainProviders?.provider?.id}${accountsIndex}`}
                                >
                                  {item?.id === mainProviders?.provider?.id &&
                                  mainProviders?.provider?.measure === ProviderMeasure.PACKAGES &&
                                  mainProviders.uniqueId && !mainProviders?.isOperatorActive ? (
                                    <div className={`${s.select__provider__wrapper} ${s.select__provider_edit}`} onClick={() => handlePriceListSelect(mainProviders, payment)}>
                                      <FormikSelect
                                        loadingMessage={
                                          isGetDetailPriceListLoading === Loading.success
                                        }
                                        isDisabled
                                        onMenuOpen={() => handlePriceListSelect(mainProviders, payment)}
                                        containerStyles={{
                                          width: '100%',
                                          height: 'auto',
                                          maxWidth: '100%',
                                        }}
                                        valueContainer={{
                                          paddingLeft: '.5rem',
                                          height: '2.75rem',
                                          maxHeight: '2.75rem',
                                          backgroundColor: '#fdfdfd',
                                          fontStyle: mainProviders?.isDefaultValue ? 'italic' : 'normal'
                                        }}
                                        indicatorContainerStyles={{
                                          height: '2.75rem',
                                          width: '2.75rem'
                                        }}
                                        controlStyles={{
                                          minHeight: '2.75rem',
                                          maxHeight: '2.75rem'
                                        }}
                                        // hideInputContainerWrapper={{
                                        //   width: 0,
                                        //   margin: 0,
                                        //   padding: 0,
                                        //   maxHeight: 0
                                        // }}
                                        valueStyles={{
                                          color: ColorsAccent.accentDark,
                                          opacity: 1
                                        }}
                                        name={`choose${mainProviders?.provider?.id
                                          ?.split('.')
                                          ?.join('')}${payment.id}`}
                                        id="operator"
                                        options={
                                          // currentPriceListItems?.slice(itemOffset, endOffset)
                                          currentPriceListItems?.map(
                                            (price: any) => ({
                                              value: price?.id,
                                              amount: price.price,
                                              description: price?.description,
                                              label: price?.label,
                                              singleValue: `${price?.label}`,
                                              providerId: price?.provider?.id,
                                              accountId: mainProviders.id,
                                              uniqueId: mainProviders?.uniqueId,
                                              currentOperator: {
                                                ...item,
                                                priceListId: price.id,
                                                employeeId: payment.id,
                                                providerId: price?.provider?.id,
                                                icon: mainProviders?.icon || '',
                                                description: price?.description,
                                                uniqueId: mainProviders?.uniqueId,
                                                operators: []
                                              },
                                              name: payment?.name,
                                              providers: [],
                                              employeeId: payment?.id
                                            })
                                          )}
                                        // isSearchable={false}
                                        placeholder={t<string>('MAIN.NEWPAYMENT.FORM.SELECTPLACEHOLDER')}
                                        indicatorIcon={<DropSelectSVG />}
                                        onChange={handleCheckSelectFields}
                                        // TODO: Переделать внутренности селекта на поиск, убрать пагинацию, сделать обычный скролл
                                        // selectPagination={
                                        //   <>
                                        //     <div className={s.paginate__count__mobile}>
                                        //         <p>{dynamicPage}</p> <p className={s.count__divider}>of</p> <p>{pageCount || 1}</p>
                                        //     </div>
                                        //     <div className={s.select__pagination}>
                                        //       <SelectPagination
                                        //         dynamicPage={dynamicPage}
                                        //         handlePageClick={handlePageClick}
                                        //         pageCount={pageCount}
                                        //       />
                                        //     </div>
                                        //   </>
                                        // }
                                      />
                                    </div>
                                    ) : null}

                                  {item?.id === mainProviders?.provider?.id &&
                                  mainProviders?.provider?.measure ===
                                    ProviderMeasure.PACKAGES_AND_MONEY &&
                                  mainProviders.uniqueId && !mainProviders?.isOperatorActive ? (
                                    <div className={s.pay__edit} onClick={(e: ChangeEvent<HTMLInputElement | any>) => handleRepalceNumberField(mainProviders, payment)}>
                                      <FormikTextFieldNumber
                                        inputStyles={{
                                          height: '2.75rem',
                                          width: '100%',
                                          paddingLeft: '.5rem',
                                          fontStyle: mainProviders?.isDefaultValue ? 'italic' : 'normal',
                                          opacity: 1,
                                          cursor: 'pointer'
                                        }}
                                        readOnly
                                        validateYup={validationSchema}
                                        name={`singleId${mainProviders?.provider?.id}${payment.id}`?.split('.')?.join('')}
                                        placeholder={t<string>('MAIN.NEWPAYMENT.FORM.INPUTAMOUNTPLACEHOLDER')}
                                        value={mainProviders?.defaultPrice || mainProviders?.defaultPriceList?.price || mainProviders?.priceList?.price || ''}
                                        defaultValue={mainProviders?.defaultPrice || mainProviders?.defaultPriceList?.price || mainProviders?.priceList?.price || ''}
                                        min={mainProviders.provider.minAmount}
                                        max={mainProviders.provider.maxAmount}
                                        description={`${mainProviders.provider.title} services`}
                                        accountId={mainProviders?.id}
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onValueChange={(e: any, name: any) =>
                                          handleInputValue(e, item, {
                                            value: e,
                                            amount: e,
                                            description: `${item?.title} services`,
                                            label: `${e} UGX`,
                                            singleValue: `${e} UGX`,
                                            providerId: item?.id,
                                            accountId: mainProviders.id,
                                            currentOperator: {
                                              ...item,
                                              priceListId: null,
                                              employeeId: payment.id,
                                              icon: mainProviders?.icon,
                                              description: `${item?.title} services`,
                                              operators: []
                                            },
                                            name: payment?.name,
                                            providers: [],
                                            employeeId: payment?.id
                                          }, mainProviders)
                                        }
                                      />
                                    </div>
                                    ) : null}
                                    
                                  {item?.id === mainProviders?.provider?.id &&
                                  mainProviders?.provider?.measure === ProviderMeasure.MONEY &&
                                  mainProviders.uniqueId && !mainProviders?.isOperatorActive ? (
                                    <div className={s.pay__edit} onClick={(e: ChangeEvent<HTMLInputElement | any>) => handleRepalceNumberField(mainProviders, payment)}>
                                      <FormikTextFieldNumber
                                        inputStyles={{
                                          height: '2.75rem',
                                          width: '100%',
                                          paddingLeft: '.5rem',
                                          fontStyle: mainProviders?.isDefaultValue ? 'italic' : 'normal',
                                          opacity: 1,
                                          cursor: 'pointer'
                                        }}
                                        readOnly
                                        validateYup={validationSchema}
                                        name={`singleId${mainProviders?.provider?.id}${payment.id}`?.split('.')?.join('')}
                                        placeholder={t<string>('MAIN.NEWPAYMENT.FORM.INPUTAMOUNTPLACEHOLDER')}
                                        defaultValue={mainProviders.defaultPrice || ''}
                                        value={mainProviders.defaultPrice || ''}
                                        min={mainProviders.provider.minAmount}
                                        max={mainProviders.provider.maxAmount}
                                        accountId={mainProviders?.id}
                                        description={`${mainProviders.provider.title} services`}
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onValueChange={(e: any, name: any) =>
                                          handleInputValue(e, item, {
                                            value: e,
                                            amount: e,
                                            description: `${item?.title} services`,
                                            label: `${e} UGX`,
                                            singleValue: `${e} UGX`,
                                            providerId: item?.id,
                                            accountId: mainProviders.id,
                                            currentOperator: {
                                              ...item,
                                              priceListId: null,
                                              employeeId: payment.id,
                                              icon: mainProviders?.icon,
                                              description: `${item?.title} services`,
                                              operators: []
                                            },
                                            name: payment?.name,
                                            providers: [],
                                            employeeId: payment?.id
                                          }, mainProviders)
                                        }
                                      />
                                    </div>
                                    ) : null}

                                  {

                                    item?.id === mainProviders?.provider?.id &&
                                    (mainProviders?.provider?.measure === ProviderMeasure.MONEY ||
                                      mainProviders?.provider?.measure === ProviderMeasure.PACKAGES ||
                                      mainProviders?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY) &&
                                    mainProviders?.isOperatorActive ?
                                      <button
                                        className={s.operator__disabled}
                                        id={item.id}
                                        data-tip={'This operator disabled'}
                                        type="button"
                                        onClick={(e: ChangeEvent<HTMLInputElement | any>) => handleRepalceNumberField(mainProviders, payment)}
                                      >
                                        <p>Deactivated</p>
                                      </button>
                                      :
                                      null}
                                </React.Fragment>
                              ))}

                                <button
                                id={item.id}
                                data-tip={t<string>('MAIN.NEWPAYMENT.TOOLTIP.ADDID')}
                                type="button"
                                onClick={(e: any) =>
                                  handleOperatorIdModal(
                                    e,
                                    {
                                      ...item,
                                      priceList: toJS(
                                        getPriceListModel.getPriceListDetail
                                      )
                                    },
                                    payment
                                  )
                                }
                              >
                                <p>{t<string>('MAIN.NEWPAYMENT.BUTTONS.APPENDID')}</p>
                                <PlusSVG />
                              </button>
                            </div>
                          ))}
                        </motion.div>
                      ))}
                    </div>
                    {isModalOpen ? (
                      <ModalAdditionOperatorNewPayment
                        pageCount={pageCount}
                        endOffset={endOffset}
                        providers={providers}
                        itemOffset={itemOffset}
                        dynamicPage={dynamicPage}
                        hideModal={setIsModalOpen}
                        setEndOffset={setEndOffset}
                        setItemOffset={setItemOffset}
                        setFieldValue={setFieldValue}
                        setDynamicPage={setDynamicPage}
                        handlePageClick={handlePageClick}
                        chooseFromModal={chooseFromModal}
                        setChooseFromModal={setChooseFromModal}
                        currentOperatorData={currentOperatorData}
                        inputValidateFields={inputValidateFields}
                        isCheckGroupRechange={isCheckGroupRechange}
                        selectValidateFields={selectValidateFields}
                        setCurrentOperatorData={setCurrentOperatorData}
                        setInputValidateFields={setInputValidateFields}
                        setIsCheckGroupRechange={setIsCheckGroupRechange}
                        setSelectValidateFields={setSelectValidateFields}
                        whichSubmitButtonClicked={whichSubmitButtonClicked}
                        setInitialReplacedDatasAtEditFields={setInitialReplacedDatasAtEditFields}
                        isGetDetailPriceListLoading={
                          isGetDetailPriceListLoading === Loading.success
                        }
                      />
                    ) : null}

                    <div
                      className={`${s.pay__create_operator} ${
                        isCheckGroupRechange?.length > 5 ? s.pay__create_rotate : ''
                      }`}
                    >
                      <button
                        type="button"
                        onClick={handlerAddNewProvider}
                        // disabled={checkSelectLength()?.length < 1}
                      >
                          <div className={s.pay__create_icon}>
                            <PlusSVG />
                          </div>
                      </button>
                    </div>
                    <div className={s.pay__create_employee}>
                      <button
                        ref={addEmployeeButtonEl}
                        type="button"
                        onClick={handleChooseEmployees}
                        className={`${
                          isCheckGroupRechange?.length > 5 ? s.small_width : s.large_width
                        } `}
                      >
                        <div className={s.pay__create_icon}>
                          <PlusSVG />
                        </div>
                      </button>
                    </div>
                  </div>

                  <div className={s.pay__arrows}>
                    <div
                      className={s.previous__wrapper}
                      onClick={() =>
                        isMobile
                          ? handleHorizantalScroll(
                            payEl.current,
                            1,
                            isCheckGroupRechange?.length >= 6 ? 180.1 : 185,
                            isCheckGroupRechange?.length >= 6 ? -5 : -4
                          )
                          : handleHorizantalScroll(
                            payEl.current,
                            1,
                            isCheckGroupRechange?.length >= 6 ? 150.61 : 155.61,
                            isCheckGroupRechange?.length >= 6 ? -5 : -4
                          )
                      }
                    >
                      <PreviousArrowSVG />
                    </div>

                    <div
                      className={s.next__wrapper}
                      onClick={() =>
                        isMobile
                          ? handleHorizantalScroll(
                            payEl.current,
                            1,
                            isCheckGroupRechange?.length >= 6 ? 180.1 : 185,
                            isCheckGroupRechange?.length >= 6 ? 5 : 4
                          )
                          : handleHorizantalScroll(
                            payEl.current,
                            1,
                            isCheckGroupRechange?.length >= 6 ? 150.61 : 155.61,
                            isCheckGroupRechange?.length >= 6 ? 5 : 4
                          )
                      }
                    >
                      <NextArrowSVG />
                    </div>
                  </div>
                </div>

                <PaymentAddEmployee
                  state={state}
                  values={values}
                  getAuthMe={getAuthMe}
                  employees={employees}
                  isCheckRechange={isCheckRechange}
                  removeDuplicates={removeDuplicates}
                  getProvidersModel={getProvidersModel}
                  getEmployeesModel={getEmployeesModel}
                  isDeletedOperators={isDeletedOperators}
                  setIsCheckRecnahge={setIsCheckRecnahge}
                  setUniqueProviders={setUniqueProviders}
                  addEmployeeButtonEl={addEmployeeButtonEl}
                  inputValidateFields={inputValidateFields}
                  selectValidateFields={selectValidateFields}
                  isCheckGroupRechange={isCheckGroupRechange}
                  setIsDeletedOperators={setIsDeletedOperators}
                  isCheckRechangeConcat={isCheckRechangeConcat}
                  isAppendEmployeesModal={isAppendEmployeesModal}
                  setIsCheckGroupRechange={setIsCheckGroupRechange}
                  setIsCheckRechangeConcat={setIsCheckRechangeConcat}
                  setIsAppendEmployeesModal={setIsAppendEmployeesModal}
                  isCheckGroupRechangeConcat={isCheckGroupRechangeConcat}
                  setIsCheckGroupRechangeConcat={setIsCheckGroupRechangeConcat}
                />
                <div className={s.wrapper__button_mobile}>
                  <div className={s.wrapper__button}>
                    <motion.button
                      disabled={
                        // !replacedResultData?.length
                        // !inputValidateFields.length
                        // !isCheckGroupRechange?.map((item: any) => item?.accounts).flat(1).map((accountItem: any) => accountItem?.uniqueId)?.filter((uniqueId: any) => uniqueId).length
                        !isCheckGroupRechange?.map((item: any) => item?.accounts).flat(1).filter((accountItem: any) => accountItem?.uniqueId).reduce((resultAcc: any[], result: any): boolean[] => {
    
                          if ((result.defaultPrice || result.defaultPriceList || result.defaultPriceListEdit) && !result.isOperatorActive) {
                            resultAcc.push(true);
                          } else {
                            resultAcc.push(false);
                          }
                        
                          return resultAcc;
                      
                        }, []).some((el: any) => el)
                      }
                      whileTap={
                        selectValidateFields?.length || inputValidateFields?.length
                          ? { scale: 0.95 }
                          : {}
                      }
                      whileHover={
                        selectValidateFields?.length || inputValidateFields?.length
                          ? { scale: 1.05 }
                          : {}
                      }
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                      onClick={() => setWhichSubmitButtonClicked('1')}
                      value="Submit main"
                      formAction="/submit/b"
                    >
                      <p>{t<string>('MAIN.NEWPAYMENT.BUTTONS.SUBMIT')}</p>
                      <div className={s.button__icon}>
                        <PaySVG />
                      </div>
                    </motion.button>
                  </div>
                </div>
              </>
              );
            }}
          </FormikContainer>
        </div>
      </AnimatedDivPage>
    </section>
  );
};

export const Payment = observer(PaymentView);
import React from 'react';
import MainRouter from 'router';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';

function App() {

  const { isMobile } = useWindowDimensions();

  return (
    <>
      <MainRouter />
      <ToastContainer style={{ height: '20px' }} autoClose={12000}
                      limit={!isMobile ? 1 : 3}
      />
    </>
  );
}

export default App;

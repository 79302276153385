/* eslint-disable class-methods-use-this */
import SERVICE_API from 'api/payway';
import { remapFieldsError } from 'common/replaceErrorMessage';
import { makeAutoObservable, toJS } from 'mobx';
import {
  AccountsModel, CheckAccount, CreateAccount, GetAccountData
} from 'models/accounts/types';
import { useStores } from 'store';
import { IAsyncUtil, IConstructorAsyncUtils } from 'store/types';

interface AccountsRepository {
  new (): AccountsModel;
}

export class AccountsController {
  private accountsModel: AccountsModel;

  private createAccountsAsyncHelper: IAsyncUtil;

  private checkAccountsAsyncHelper: IAsyncUtil;

  private accountsAsyncHelper: IAsyncUtil;
  
  private deleteAccountsAsyncHelper: IAsyncUtil;

  private accountsByEmployeeAsyncHelper: IAsyncUtil;

  public fixedData: any[] = [];

  get getAccountsModel(): AccountsModel {
    return this.accountsModel;
  }

  get getCreateAccountsAsyncState(): IAsyncUtil {
    return this.createAccountsAsyncHelper;
  }

  get getCheckEmployeeAsyncHelper(): IAsyncUtil {
    return this.checkAccountsAsyncHelper;
  }

  get getAccountsByEmployeeAsyncState(): IAsyncUtil {
    return this.accountsByEmployeeAsyncHelper;
  }

  get getAccountsAsyncState(): IAsyncUtil {
    return this.accountsAsyncHelper;
  }

  get getDeleteAccountsAsyncState(): IAsyncUtil {
    return this.deleteAccountsAsyncHelper;
  }

  // eslint-disable-next-line consistent-return
  createAccountsAction = async (account: CreateAccount): Promise<any> => {
    this.createAccountsAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.AccountsAPI.createAccount(account);
      SERVICE_API.PriceListAPI.getPriceListDetail({ id: data.provider.id });
      this.getCheckEmployeeAccountsAction({ id: account?.employeeId });
      // SERVICE_API.EmployeesAPI.getEmployees({ id: data.employeeId });
      this.getCreateAccountsSuccess(data);

      await this.fixedData.push(data);

      if (this.fixedData.length === 1) {
        this.createAccountsAsyncHelper.successLoadingAsync(false, 'Providers added');
      }

      return data;
      // this.fixedData = [];
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.createAccountsAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  createCheckAccountsAction = async (account: CheckAccount): Promise<void> => {
    // this.createAccountsAsyncHelper.startAsync();
    try {
      const { data } = await SERVICE_API.AccountsAPI.createCheckAccount(account);
      this.getCreateAccountsSuccess(data);
    //   await this.createAccountsAsyncHelper.successAsync('Employee created');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.checkAccountsAsyncHelper.errorAsync(errorMessage, fieldErrors);
    }
  };

  createCheckProviderEmployeeAction = async (provider: any): Promise<void> => {
    // this.createAccountsAsyncHelper.startAsync();
    try {
      const { data } = await SERVICE_API.AccountsAPI.getCheckProviderAccount(provider);
      // this.getCreateAccountsSuccess(data);
      // await this.createAccountsAsyncHelper.successAsync('Employee created');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      // this.createAccountsAsyncHelper.errorAsync(errorMessage, fieldErrors);
    }
  };

  getCheckEmployeeAccountsAction = async (employee: any): Promise<void> => {
    try {
      const { data } = await SERVICE_API.AccountsAPI.getCheckEmployeeAccount(employee);
      this.getCheckAccountsSuccess(data);
      this.accountsByEmployeeAsyncHelper.successAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.accountsByEmployeeAsyncHelper.errorAsync(errorMessage, fieldErrors);
    }
  };

  getCheckProviderAccountsAction = async (provider: any): Promise<void> => {
    try {
      const { data } = await SERVICE_API.AccountsAPI.getCheckProviderAccount(provider);
      this.getCheckAccountsSuccess(data);
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.accountsAsyncHelper.errorAsync(errorMessage, fieldErrors);
    }
  };

  deleteAccountsAction = async (account: any): Promise<void> => {
    this.deleteAccountsAsyncHelper.startLoadingAsync();
    try {
      await SERVICE_API.AccountsAPI.deleteAccount(account);
      if (account?.messageDisable) {
        this.deleteAccountsAsyncHelper.successLoadingAsync();
      } else {
        this.deleteAccountsAsyncHelper.successLoadingAsync(false, 'Provider removed');
      }
      
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.deleteAccountsAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  private ormErrors = (error: any) => {
    const errorMessage = error?.response?.data?.error || error?.message;
    const fieldErrors = error?.response?.data?.message;
    return { errorMessage, fieldErrors };
  };

  private getCheckAccountsSuccess = (data: GetAccountData[]): void => {
    this.accountsModel.setCreateCheckAccounts(data);
  };

  private getCreateAccountsSuccess = (data: GetAccountData): void => {
    this.accountsModel.setCreateAccounts(data);
  };

  constructor(AccountsEntity: AccountsRepository, AsyncHelper: IConstructorAsyncUtils) {
    this.accountsModel = new AccountsEntity();
    this.accountsAsyncHelper = new AsyncHelper();
    this.checkAccountsAsyncHelper = new AsyncHelper();
    this.deleteAccountsAsyncHelper = new AsyncHelper();
    this.createAccountsAsyncHelper = new AsyncHelper();
    this.accountsByEmployeeAsyncHelper = new AsyncHelper();

    makeAutoObservable(this);
  }
}

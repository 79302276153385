/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { toJS } from 'mobx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { EmployeesModel } from 'models/employees/types';
import { EmployeesController } from 'controllers/employees';
import { DeleteMemberSVG } from 'assets/icons/deleteMember';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  modalRefEl: any;
  choosenPhone: any;
  getEmployeesModel: EmployeesModel;
  employees: EmployeesController;
}

const ModalEditPhonePriorityView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    modalRefEl,
    employees,
    getEmployeesModel,
    choosenPhone
  } = props;
  const changeGroupCheck = () => {
    const data = {
      employeeId: toJS(getEmployeesModel.getEmployeesDetail)?.id,
      name: toJS(getEmployeesModel.getEmployeesDetail)?.name,
      phones:
      [
        ...toJS(getEmployeesModel.getEmployeesDetail)?.phones?.reduce((acc: any, itemPhone: any) => {
          if (itemPhone?.phone === choosenPhone?.value) {
            acc.push({
              ...itemPhone,
              favorite: true
            });
          } else {
            acc.push({
              ...itemPhone,
              favorite: false
            });
          }
          return acc;
        }, [])
      ],
      surname: toJS(getEmployeesModel.getEmployeesDetail)?.surname,
      groupId: toJS(getEmployeesModel.getEmployeesDetail)?.group?.id,
      accounts: toJS(getEmployeesModel.getEmployeesDetail)?.accounts?.reduce(
        (acc: any, accountItem: any) => {
          acc.push({
            defaultPrice: accountItem.defaultPriceList ? null : accountItem.defaultPrice,
            defaultPriceListId: accountItem.defaultPriceList
              ? accountItem.defaultPriceList?.id
              : null,
            defaultScenario: accountItem?.defaultScenario?.id ? {
              label: accountItem?.defaultScenario?.label,
              id: accountItem?.defaultScenario?.id,
              createAt: accountItem?.defaultScenario?.createAt,
              deletedAt: accountItem?.defaultScenario?.deletedAt,
              type: accountItem?.defaultScenario?.type
            } : null,
            defaultValueEmployee: accountItem?.defaultValueEmployee || false,
            note: null,
            providerId: accountItem.provider?.id,
            employeeId: toJS(getEmployeesModel.getEmployeesDetail).id,
            uniqueId: accountItem.uniqueId
          });

          return acc;
        },
        []
      )
    };

    employees?.updateEmployeeAction(data);

    hideModal();
  };

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Change primary phone</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              Are you sure you want to make this phone number your primary phone number: +256{choosenPhone?.value} ?
            </p>
          </div>

          <div className={s.employee}>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={changeGroupCheck}
              >
                <p>Yes</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>No</p>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalEditPhonePriority = observer(ModalEditPhonePriorityView);

import React from 'react';

export const AdditionSVG = () => (
  <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2V2.01M2 9V9.01M2 16V16.01M2 3C1.44772 3 1 2.55228 1 2C1 1.44772 1.44772 1 2 1C2.55229 1 3 1.44772 3 2C3 2.55228 2.55229 3 2 3ZM2 10C1.44772 10 1 9.55228 1 9C1 8.44772 1.44772 8 2 8C2.55229 8 3 8.44772 3 9C3 9.55228 2.55229 10 2 10ZM2 17C1.44772 17 1 16.5523 1 16C1 15.4477 1.44772 15 2 15C2.55228 15 3 15.4477 3 16C3 16.5523 2.55228 17 2 17Z"
      stroke="#1a1a1a"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';

export const MarkSVG = () => (
  <svg
    width="1rem"
    height="0.8125rem"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3851 0.996566C13.9392 0.380801 14.8877 0.330883 15.5034 0.885072C16.1192 1.43926 16.1691 2.38769 15.6149 3.00346L6.61494 13.0035C6.04003 13.6422 5.04703 13.6684 4.43934 13.0607L0.43934 9.06067C-0.146447 8.47489 -0.146447 7.52514 0.43934 6.93935C1.02513 6.35357 1.97487 6.35357 2.56066 6.93935L5.44271 9.8214L13.3851 0.996566Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_335_782"
        x="0"
        y="0.5"
        width="16"
        height="13.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_335_782" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_335_782" result="shape" />
      </filter>
    </defs>
  </svg>
);

import React, { ReactChild, ReactChildren } from 'react';
import ReactTooltip from 'react-tooltip';
import { observer } from 'mobx-react-lite';
import { BackSVG } from 'assets/icons/back';
import { useModal } from 'hooks/useModal.hook';
import { motion, Variants } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router';
import { AnimatedDivPage } from 'components/animatedPage';
import s from './index.module.scss';

interface IMoreFunctionsDetailViewProps {
  children: ReactChild | ReactChildren;
}

const MoreFunctionsDetailView = ({ children }: IMoreFunctionsDetailViewProps) => {
  const { state }: any = useLocation();
  const { showModal } = useModal();

  function removeDuplicates<T, K>(data: T[], key: (key: T) => K): T[] {
    return [...new Map(data?.map((item: T) => [key(item), item])).values()];
  }

  const navigate = useNavigate();

  const animations: Variants = {
    initial: { opacity: 0, scale: 0.6, y: -10 },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.6, y: -10 }
  };

  return (
    <section className={s.wrapper}>
      <div className={s.wrapper__container}>
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: { opacity: 0, x: 70, y: 70 },
            animate: { opacity: 1, x: 0, y: 0 },
            exit: { opacity: 0, x: 70, y: 70 }
          }}
          transition={{ duration: 0.7, type: 'spring', damping: 22, stiffness: 155 }}
          className={s.back}
        >
          <ReactTooltip textColor="#334049" backgroundColor="#fff" border borderColor="#E5E8ED"/>
          <motion.button
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className={s.back_icon}
            onClick={() => navigate('/functions')}
          >
            <BackSVG/>
          </motion.button>

          <div className={s.back_text}>
            <p>Back to functions</p>
          </div>
        </motion.div>

        <AnimatedDivPage
          transition={{ duration: 0.7, type: 'spring', damping: 15 }}
          animations={animations}
        >
          <>
            <div className={s.detail}>
              <div className={s.detail__title_header}>
                <h1 className={s.detail__title}>{state?.moreFunc?.title}</h1>
              </div>

              <div className={s.employee__group}>
                <h4 className={s.employee__group_title}>Description</h4>
                <div className={s.functions__description_wrapper}>
                  <p>{state?.moreFunc?.description}</p>
                </div>
              </div>

              <div className={s.operators}>
                <div className={s.operators__header}>
                  <div className={s.operators__wrapper__title}>
                    <h4 className={s.operators__title}>Interaction</h4>
                  </div>
                </div>

                <div className={s.operators__list}>
                  {children}
                </div>
              </div>
            </div>
          </>
        </AnimatedDivPage>
      </div>
    </section>
  );
};

export const MoreFunctionsDetail = observer(MoreFunctionsDetailView);

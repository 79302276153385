import React from 'react';
import { observer } from 'mobx-react-lite';
import { AuthContainer } from 'containers/auth';
import s from './index.module.scss';

const ProfileView = () => {
  console.log('');

  return (
    <AuthContainer>
      <h1>Profile</h1>
    </AuthContainer>
  );
};

export const Profile = observer(ProfileView);

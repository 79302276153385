/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { KindOfPage } from 'common/constants/additionalOperatorModal';
import { EmployeesController } from 'controllers/employees';
import { GroupsController } from 'controllers/groups';
import { toJS } from 'mobx';
import { EmployeeList } from 'models/employees/types';

/* eslint-disable no-param-reassign */
type KindOfPageType = {
  [KindOfPage.CREATE_EMPLOYEE_PAGE]?: (createNewOperator?: any, currentOperatorData?: any, createOperatorsGroup?: any[], setCreateOperatorsGroup?: (groupItems: any[]) => void) => void;
  [KindOfPage.CREATE_GROUP_PAGE]?: (createNewOperator?: any, currentOperatorData?: any, createOperatorsGroup?: any[], setCreateOperatorsGroup?: (groupItems: any[]) => void) => void;
  [KindOfPage.DETAIL_EMPLOYEE_PAGE]?: (employees?: EmployeesController, createNewOperator?: any, currentOperatorData?: any) => void;
  [KindOfPage.DETAIL_GROUP_PAGE]?: (groups?: GroupsController, createNewOperator?: any, currentOperatorData?: any) => void;
};

const createOperatorLogic = ({
  createNewOperator,
  currentOperatorData,
  createOperatorsGroup,
  setCreateOperatorsGroup 
}: any) => {
  if (!currentOperatorData) {
    setCreateOperatorsGroup([...createOperatorsGroup, ...createNewOperator]);
  } else {
    setCreateOperatorsGroup(
      createOperatorsGroup?.map((operator: any) => {
        if (operator?.provider?.id === currentOperatorData?.provider?.id) {
          operator = currentOperatorData;
        }
        return operator;
      })
    );
  }
};

export const handleAppendNewOperators: KindOfPageType | any = {
  [KindOfPage.CREATE_EMPLOYEE_PAGE]: (props: { createNewOperator: any, currentOperatorData: any, createOperatorsGroup: any, setCreateOperatorsGroup: any }) => {
    const { createNewOperator, currentOperatorData, createOperatorsGroup, setCreateOperatorsGroup } = props;
    createOperatorLogic({ createNewOperator, currentOperatorData, createOperatorsGroup, setCreateOperatorsGroup });
  },
  
  [KindOfPage.CREATE_GROUP_PAGE]: (props: { createNewOperator: any, currentOperatorData: any, createOperatorsGroup: any, setCreateOperatorsGroup: any }) => {
    const { createNewOperator, currentOperatorData, createOperatorsGroup, setCreateOperatorsGroup } = props;
    createOperatorLogic({ createNewOperator, currentOperatorData, createOperatorsGroup, setCreateOperatorsGroup });
  },
  
  [KindOfPage.DETAIL_EMPLOYEE_PAGE]: (props: { employees: EmployeesController, createNewOperator: any, currentOperatorData: any }) => {
    const { employees, createNewOperator, currentOperatorData } = props;
    const { getEmployeesModel } = employees;
    const { uniqueIdValue: uniqueId, provider: { id: providerId } } = createNewOperator[0];
    
    employees.updateEmployeeAction({
      employeeId: toJS(getEmployeesModel.getEmployeesDetail)?.id,
      name: toJS(getEmployeesModel.getEmployeesDetail)?.name,
      surname: toJS(getEmployeesModel.getEmployeesDetail)?.surname,
      phones: toJS(getEmployeesModel.getEmployeesDetail)?.phones,
      groupId: toJS(getEmployeesModel.getEmployeesDetail)?.group?.id,
      accounts: 
      currentOperatorData ? 
        toJS(getEmployeesModel.getEmployeesDetail)?.accounts?.reduce(
          (acc: any, accountItem: any) => {
      
            acc.push(
              {
                defaultPrice: accountItem.defaultPriceList ? null : accountItem.defaultPrice || null,
                defaultPriceListId: accountItem.defaultPriceList
                  ? accountItem.defaultPriceList?.id
                  : null,
                note: null,
                providerId: accountItem.provider?.id,
                employeeId: toJS(getEmployeesModel.getEmployeesDetail)?.id,
                uniqueId: accountItem.uniqueId,
                defaultScenario: accountItem?.defaultScenario?.id ? accountItem?.defaultScenario : null,
                defaultValueEmployee: accountItem?.defaultValueEmployee,
                // TODO: Тест defaultScenario и defaultScenarioId
                // defaultScenarioId: accountItem?.defaultScenario?.id
              });
          
            return acc;
          },
          []
        )?.map(
          (accountItem: any) => {
    
            if (accountItem?.providerId === currentOperatorData?.initialProviderId) {
              accountItem.defaultPrice = !currentOperatorData.defaultPriceList ? createNewOperator[0]?.amount || null : null;
              accountItem.defaultPriceListId = currentOperatorData.defaultPriceList
                ? currentOperatorData?.defaultPriceList?.id
                : null;
              accountItem.defaultScenario = currentOperatorData?.defaultScenario?.id ? currentOperatorData?.defaultScenario : null;
              accountItem.note = null;
              accountItem.providerId = currentOperatorData?.provider?.id;
              accountItem.employeeId = toJS(getEmployeesModel.getEmployeesDetail)?.id;
              accountItem.uniqueId = currentOperatorData?.uniqueIdValue;
              accountItem.defaultValueEmployee = currentOperatorData?.defaultValueEmployee;

              // TODO: Тест defaultScenario и defaultScenarioId (Когда будут лететь нам defaultScenario в defaultScenario с бэка)
              // accountItem.defaultScenarioId = currentOperatorData?.defaultScenario?.id;
            }
    
            return accountItem;
          }
        )
        :
        [ ...toJS(getEmployeesModel.getEmployeesDetail)?.accounts?.reduce(
          (acc: any, accountItem: any) => {
  
            acc.push(
              {
                defaultPrice: accountItem.defaultPriceList ? null : accountItem.defaultPrice || null,
                defaultPriceListId: accountItem.defaultPriceList
                  ? accountItem.defaultPriceList?.id
                  : null,
                defaultScenario: accountItem?.defaultScenario?.id ? accountItem?.defaultScenario : null,
                note: null,
                providerId: accountItem.provider?.id,
                employeeId: toJS(getEmployeesModel.getEmployeesDetail)?.id,
                uniqueId: accountItem.uniqueId,
                defaultValueEmployee: accountItem?.defaultValueEmployee,
                // TODO: Тест defaultScenario и defaultScenarioId
                // defaultScenarioId: accountItem?.defaultScenario?.id
              });
      
            return acc;
          },
          []
        ),
        {
          defaultPrice: !createNewOperator[0]?.defaultPriceList ? createNewOperator[0]?.amount || null : null,
          defaultPriceListId: createNewOperator[0]?.defaultPriceList
            ? createNewOperator[0]?.defaultPriceList?.id
            : null,
          // TODO: Тест defaultScenario и defaultScenarioId
          defaultScenario: createNewOperator?.[0]?.defaultScenario?.id ? createNewOperator?.[0]?.defaultScenario : null,
          defaultValueEmployee: createNewOperator?.[0]?.defaultValueEmployee,
          // defaultScenarioId: createNewOperator?.[0]?.defaultScenario?.id || null,
          note: null,
          providerId,
          employeeId: toJS(getEmployeesModel.getEmployeesDetail)?.id,
          uniqueId
        } 
        ]
    });
  },

  [KindOfPage.DETAIL_GROUP_PAGE]: (props: { groups: GroupsController, employees: EmployeesController, createNewOperator: any, currentOperatorData: any }) => {
    const { groups, employees, createNewOperator, currentOperatorData } = props;
    const { uniqueIdValue: uniqueId, provider: { id: providerId } } = createNewOperator[0];
    const { getGroupsModel } = groups;
    const { getEmployeesModel } = employees;

    const data: any = {
      groupId: toJS(getGroupsModel.getGroupDetail).id,
      title: toJS(getGroupsModel.getGroupDetail).title,
      description: toJS(getGroupsModel.getGroupDetail)?.description,
      parentId: toJS(getGroupsModel.getGroupDetail).parent_id,
      employeesId: toJS(getGroupsModel.getGroupDetail).employees.map(
        (employee: any) => employee.id
      ),
      accounts: currentOperatorData ?
        toJS(getGroupsModel.getGroupDetail)?.groupsAccounts?.reduce(
          (acc: any, accountItem: any) => {
            acc.push({
              defaultPrice: accountItem.priceList ? null : accountItem.defaultPrice || null,
              priceListId: accountItem.priceList ? accountItem.priceList?.id : null,
              providerId: accountItem.provider?.id,
              defaultScenario: accountItem?.defaultScenario?.id || null
            });

            return acc;
          },
          []
        )?.map(
          (accountItem: any) => {
  
            if (accountItem?.providerId === currentOperatorData?.initialProviderId) {
              accountItem.defaultPrice = !currentOperatorData.defaultPriceList ? createNewOperator[0]?.amount || null : null;
              accountItem.priceListId = currentOperatorData.defaultPriceList
                ? currentOperatorData?.defaultPriceList?.id
                : null;
              accountItem.defaultScenario = currentOperatorData?.defaultScenario?.id || null;
              accountItem.providerId = currentOperatorData?.provider?.id;
            }
  
            return accountItem;
          }
        )
        :
        [ ...toJS(getGroupsModel.getGroupDetail)?.groupsAccounts?.reduce(
          (acc: any, accountItem: any) => {
            acc.push({
              defaultPrice: accountItem.priceList ? null : accountItem.defaultPrice || null,
              priceListId: accountItem.priceList ? accountItem.priceList?.id : null,
              providerId: accountItem.provider?.id,
              defaultScenario: accountItem?.defaultScenario?.id || null
            });

            return acc;
          },
          []
        ), 
        {
          defaultPrice: !createNewOperator[0]?.defaultPriceList ? createNewOperator[0]?.amount || null : null,
          priceListId: createNewOperator[0]?.defaultPriceList
            ? createNewOperator[0]?.defaultPriceList?.id
            : null,
          defaultScenario: createNewOperator?.[0]?.defaultScenario?.id || null,
          providerId
        } ]
    };
    
    groups?.updateGroupsAction(data);
    // const { uniqueIdValue: uniqueId, provider: { id: providerId } } = createNewOperator[0];
    toJS(getEmployeesModel.getEmployeesList)?.filter((employee: EmployeeList) => toJS(getGroupsModel.getGroupDetail).employees?.map((employeeItem: any) => employeeItem.id).includes(employee?.id))?.map((employee: EmployeeList) => {
      Promise.all([employees?.updateEmployeeAction({
        employeeId: employee?.id,
        name: employee?.name,
        surname: employee?.surname,
        phones: employee?.phones,
        groupId: employee?.group?.id,
        accounts: currentOperatorData ? 
          employee?.accounts?.reduce(
            (acc: any, accountItem: any) => {
      
              acc.push(
                {
                  defaultPrice: accountItem.defaultPriceList ? null : accountItem.defaultPrice || null,
                  defaultPriceListId: accountItem.defaultPriceList
                    ? accountItem.defaultPriceList?.id
                    : null,
                  note: null,
                  providerId: accountItem.provider?.id,
                  employeeId: employee?.id,
                  uniqueId: accountItem.uniqueId,
                  defaultScenario: accountItem?.defaultScenario?.id ? accountItem?.defaultScenario : null,
                  defaultValueEmployee: accountItem?.defaultValueEmployee,
                // TODO: Тест defaultScenario и defaultScenarioId
                // defaultScenarioId: accountItem?.defaultScenario?.id
                });
          
              return acc;
            },
            []
          )?.map(
            (accountItem: any) => {
    
              if ((accountItem?.providerId === currentOperatorData?.initialProviderId) && !accountItem?.defaultValueEmployee) {
                accountItem.defaultPrice = !currentOperatorData.defaultPriceList ? createNewOperator[0]?.amount || null : null;
                accountItem.defaultPriceListId = currentOperatorData.defaultPriceList
                  ? currentOperatorData?.defaultPriceList?.id
                  : null;
                accountItem.defaultScenario = currentOperatorData?.defaultScenario?.id ? currentOperatorData?.defaultScenario : null;
                accountItem.note = null;
                accountItem.providerId = currentOperatorData?.provider?.id;
                accountItem.employeeId = employee?.id;
                accountItem.uniqueId = currentOperatorData?.uniqueIdValue;
                accountItem.defaultValueEmployee = currentOperatorData?.defaultValueEmployee;
  
              // TODO: Тест defaultScenario и defaultScenarioId (Когда будут лететь нам defaultScenario в defaultScenario с бэка)
              // accountItem.defaultScenarioId = currentOperatorData?.defaultScenario?.id;
              }
    
              return accountItem;
            }
          )
          :
          [ ...employee?.accounts?.reduce(
            (acc: any, accountItem: any) => {
  
              acc.push(
                {
                  defaultPrice: accountItem.defaultPriceList ? null : accountItem.defaultPrice || null,
                  defaultPriceListId: accountItem.defaultPriceList
                    ? accountItem.defaultPriceList?.id
                    : null,
                  defaultScenario: accountItem?.defaultScenario?.id ? accountItem?.defaultScenario : null,
                  note: null,
                  providerId: accountItem.provider?.id,
                  employeeId: employee?.id,
                  uniqueId: accountItem.uniqueId,
                  defaultValueEmployee: accountItem?.defaultValueEmployee,
                // TODO: Тест defaultScenario и defaultScenarioId
                // defaultScenarioId: accountItem?.defaultScenario?.id
                });
      
              return acc;
            },
            []
          ),
          {
            defaultPrice: !createNewOperator[0]?.defaultPriceList ? createNewOperator[0]?.amount || null : null,
            defaultPriceListId: createNewOperator[0]?.defaultPriceList
              ? createNewOperator[0]?.defaultPriceList?.id
              : null,
            // TODO: Тест defaultScenario и defaultScenarioId
            defaultScenario: createNewOperator?.[0]?.defaultScenario?.id ? createNewOperator?.[0]?.defaultScenario : null,
            defaultValueEmployee: createNewOperator?.[0]?.defaultValueEmployee,
            // defaultScenarioId: createNewOperator?.[0]?.defaultScenario?.id || null,
            note: null,
            providerId,
            employeeId: employee?.id,
            uniqueId
          } 
          ]
      }, toJS(getEmployeesModel.getEmployeesList)?.filter((employeeList: EmployeeList) => toJS(getGroupsModel.getGroupDetail).employees?.map((employeeItem: any) => employeeItem.id).includes(employeeList?.id)).length)]);

    });
  }

};
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TimePicker } from 'antd';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import 'react-calendar/dist/Calendar.css';
import { ChoosenSchedulePayment } from 'common/constants/schedulePayment/types';
import s from './index.module.scss';
import './index.css';

type ValuePiece = Date | null;

type DateValue = ValuePiece | [ValuePiece, ValuePiece];

interface ICalendarWindowView {
  isOpenCalendar: boolean;
  wrapperStyles?: CSSProperties;
  onDateSelect: (date: Date, kindOfChoosen?: number) => void;
  setFormattedDate: (formattedDate: string) => void;
  setIsOpenCalendar: (isOpen: boolean) => void;
  choosenScedulePayment: ChoosenSchedulePayment;
  minDate?: boolean;
  currentDate: Date | null;
  isSubmitting: boolean;
}

const CalendarWindowView = (props: ICalendarWindowView) => {
  const { isOpenCalendar, wrapperStyles, setFormattedDate, onDateSelect, setIsOpenCalendar, choosenScedulePayment, minDate, currentDate, isSubmitting } = props;
  const containerEl: any = useRef(null);

  dayjs.extend(customParseFormat);
  dayjs.extend(utc);

  const [timePicker, setTimePicker] = useState<Dayjs>(currentDate ? dayjs(currentDate).local() : dayjs('00:00:00', 'HH:mm:ss').local());
  const [datePicker, setDatePicker] = useState<Date>(currentDate || new Date());

  const [finalyDate, setFinalyDate] = useState<Date>(currentDate || new Date());


  // TODO: При клине на кнопку ok или now в окне выбора времени у меня закрываются все окна, а нужно что бы закрывалось только окно выбора времени, а окно выбора даты оставалось открытым
  const handleSetTime = (time: Dayjs | null) => {

    if (time) {
      time.utc(false);
      setTimePicker(time);
      const newTime = time.toDate();
      if (finalyDate instanceof Date) {
        const updatedDateTime = new Date(finalyDate);
        updatedDateTime.setHours(newTime.getHours());
        updatedDateTime.setMinutes(newTime.getMinutes());
        updatedDateTime.setSeconds(newTime.getSeconds());
        setFinalyDate(updatedDateTime);
      }
    }
  };

  const handleSetDate = (date: DateValue) => {

    if (date instanceof Date) {
      setDatePicker(date);
      date.setHours(finalyDate.getHours());
      date.setMinutes(finalyDate.getMinutes());
      date.setSeconds(finalyDate.getSeconds());
      setFinalyDate(date);
    }
  };

  const appendFinalyDateToChoosenDate = () => {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      year: 'numeric'
    };

    onDateSelect(finalyDate, 0);
    setFormattedDate(new Date(finalyDate).toLocaleString('en-US', options));
  };

  useEffect(() => {
    if (isSubmitting) {
      appendFinalyDateToChoosenDate();
    }
  }, [isSubmitting]);
  return (
    <motion.div
      ref={containerEl}
      style={{
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        zIndex: 10,
        ...wrapperStyles
      }}
      initial='hidden'
      animate={isOpenCalendar ? 'open' : 'hidden'}

      transition={{
        type: 'spring',
        bounce: 0,
        duration: 0.5
      }}

      className={s.wrapper}>
      <Calendar minDate={choosenScedulePayment.regularStart && minDate ? new Date(choosenScedulePayment.regularStart) : new Date()} className={s.calendar} value={datePicker} onChange={(date: DateValue) => handleSetDate(date)} />
      <TimePicker
        className={s.time}
        style={{ width: '100%' }}
        onChange={handleSetTime} value={timePicker} defaultValue={timePicker} />
    </motion.div>
  );
};

export const CalendarWindow = observer(CalendarWindowView);

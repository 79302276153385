import React from 'react';

export const InfoSVG = () => (
  <svg fill="none" height="20" viewBox="0 0 26 26" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
      stroke="#1a1a1a"
      strokeMiterlimit="10"
      strokeWidth="1"
    />
    <path
      d="M13 9.00003C13.718 9.00003 14.3 8.41801 14.3 7.70004C14.3 6.98207 13.718 6.40002 13 6.40002C12.282 6.40002 11.7 6.98207 11.7 7.70004C11.7 8.41801 12.282 9.00003 13 9.00003Z"
      fill="#1a1a1a"
    />
    <path
      d="M13 19.7C12.2 19.7 11.6 19.1 11.6 18.4V11.6C11.6 10.9 12.2 10.3 12.9 10.3H13C13.7 10.3 14.3 10.9 14.3 11.6V18.4C14.3 19.1 13.8 19.7 13 19.7Z"
      fill="#1a1a1a"
    />
  </svg>
);

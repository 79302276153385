/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import SERVICE_API from 'api/payway';
import { remapFieldsError } from 'common/replaceErrorMessage';
import { makeAutoObservable } from 'mobx';
import { PriceList, PriceListCreate, PriceListModel, PriceListsDetail } from 'models/priceList/types';
import { IAsyncUtil, IConstructorAsyncUtils } from 'store/types';

interface PriceListRepository {
  new (): PriceListModel;
}

export class PriceListController {
  private priceListModel: PriceListModel;

  private createPriceListAsyncHelper: IAsyncUtil;

  private priceListAsyncHelper: IAsyncUtil;

  private priceListDetailAsyncHelper: IAsyncUtil;

  get getPriceListModel(): PriceListModel {
    return this.priceListModel;
  }

  get getCreatePriceListAsyncState(): IAsyncUtil {
    return this.createPriceListAsyncHelper;
  }

  get getPriceListAsyncState(): IAsyncUtil {
    return this.priceListAsyncHelper;
  }

  get getPriceListDetailAsyncState(): IAsyncUtil {
    return this.priceListDetailAsyncHelper;
  }

  createPriceListAction = async (price: PriceListCreate): Promise<void> => {
    this.createPriceListAsyncHelper.startAsync();
    try {
      const { data } = await SERVICE_API.PriceListAPI.createPriceList(price);
      this.getPriceListDetailSuccess(data);
      await this.createPriceListAsyncHelper.successAsync('Successfuly created');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.createPriceListAsyncHelper.errorAsync(errorMessage, fieldErrors);
    }
  };

  getPriceListAction = async (): Promise<void> => {
    this.priceListAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.PriceListAPI.getPriceList();
      this.getPriceListSuccess(data);
      await this.priceListAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.priceListAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);

    }
  };

  // eslint-disable-next-line consistent-return
  getPriceListDetailAction = async (provider: any): Promise<any> => {
    this.priceListDetailAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.PriceListAPI.getPriceListDetail(provider);
      this.getPriceListDetailSuccess(data);
      this.priceListDetailAsyncHelper.successLoadingAsync();

      return data;
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.priceListDetailAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  private ormErrors = (error: any) => {
    const errorMessage = error?.response?.data?.error || error?.message;
    const fieldErrors = error?.response?.data?.message;
    return { errorMessage, fieldErrors };
  };

  private getPriceListSuccess = (data: PriceList[]): void => {
    this.priceListModel.setPriceList(data);
  };

  private getPriceListDetailSuccess = (data: PriceListsDetail): void => {
    this.priceListModel.setPriceListDetail(data?.priceLists);
    this.priceListModel.setPriceListDetailMeta(data?.meta);
  };

  constructor(PriceListEntity: PriceListRepository, AsyncHelper: IConstructorAsyncUtils) {
    this.priceListModel = new PriceListEntity();
    this.priceListAsyncHelper = new AsyncHelper();
    this.priceListDetailAsyncHelper = new AsyncHelper();
    this.createPriceListAsyncHelper = new AsyncHelper();

    makeAutoObservable(this);
  }
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import { EyeSVG } from 'assets/icons/eye';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AuthContainer } from 'containers/auth';
import { LogoDarkSVG } from 'assets/icons/logo';
import { BackLongSVG } from 'assets/icons/backLong';
import { StrengthBar } from 'components/strengthBar';
import FormikContainer from 'components/form/containers';
import FormikTextField from 'components/form/fields/inputs';
import s from './index.module.scss';
import { useStores } from '../../../../store';
import { Loading } from '../../../../store/types';

export interface LocationState {
  tab: number;
}

const validationSchema = Yup.object().shape({
  firstPass: Yup.string()
    .required('This is a required field')
    .matches(/^[A-Za-z0-9!@#$%^&*()_+"№;:?()~ `-]+$/, 'Only latin characters')
    .min(6, 'Password must be longer than or equal to 6 characters'),
  secondPass: Yup.string()
    .required('This is a required field')
    .oneOf([Yup.ref('firstPass'), null], "Passwords don't match")
});

const ChangePasswordSuccessView = (props: any) => {
  const [isHiddenFirstPass, setIsHiddenFirstPass] = useState<boolean>(false);
  const [isHiddenSecondPass, setIsHiddenSecondPass] = useState<boolean>(false);
  const [key, setKey] = useState('');
  const { auth } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isNewPasswordLoading = auth.getNewPasswordAsyncState.getLoadingState === Loading.success;
  const isAuthMeLoadingSuccess = auth.getAuthMeAsyncState.getLoadingState;

  const onSubmit = (values: { firstPass: string, secondPass: string }) => {
    const { firstPass, secondPass } = values;

    auth.createNewPasswordAction({
      key,
      password: firstPass,
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem('key')) {
      setKey(sessionStorage.getItem('key') || '');
    } else {
      navigate('/signin');
    }

    return () => {
      sessionStorage.removeItem('key');
    };
  }, [sessionStorage.getItem('key')]);

  useEffect(() => {
    if (isNewPasswordLoading) {
      navigate('/signin');
      sessionStorage.removeItem('key');
    }
  }, [isNewPasswordLoading]);

  useEffect(() => {
    localStorage.removeItem('authMe');

    return () => localStorage.removeItem('authMe');
  }, [isAuthMeLoadingSuccess]);

  return (
    <AuthContainer>
      <div className={s.wrapper}>
        <motion.div
          variants={{
            initial: { opacity: 0, scale: 0.8 },
            animate: { opacity: 1, scale: 1 },
            exit: { opacity: 0, scale: 0.8 }
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5, type: 'spring', damping: 17 }}
          className={s.wrapper__inner}
        >
          <FormikContainer
            initialValues={{ firstPass: '', secondPass: '' }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ resetForm, values, errors, isValid, dirty }) => (
              <div className={s.wrapper__inner__container}>
                <div className={s.wrapper__logo}>
                  <LogoDarkSVG />
                </div>
                <div className={s.wrapper__inner__title}>
                  <h1>{t<string>('AUTH.CHANGEPASSWORDSUCCESS.TITLE')}</h1>
                </div>
                <div className={s.wrapper__inner__description}>
                  <p>
                  {t<string>('AUTH.CHANGEPASSWORDSUCCESS.DESCRIPTION')} <br /> {t<string>('AUTH.CHANGEPASSWORDSUCCESS.DESCRIPTIONLASTWORDS')}
                  </p>
                </div>
                <div className={s.form__inputs}>
                  {/* TODO: Пока два одинаковых поля по условию, потом если будут разные поля, поменять. */}
                  <div
                    className={`${s.input} ${s.input__password} ${
                      errors.firstPass ? s.border_alert : null
                    }`}
                  >
                    <FormikTextField
                      type={isHiddenFirstPass ? 'text' : 'password'}
                      placeholder={t<string>('AUTH.CHANGEPASSWORDSUCCESS.FORM.PASSWORDPLACEHOLDER')}
                      label={<p className={s.input__label}>{t<string>('AUTH.CHANGEPASSWORDSUCCESS.FORM.PASSWORD')}</p>}
                      name="firstPass"
                      errorStyles={{ position: 'static' }}
                    >
                      <div
                        onClick={() => setIsHiddenFirstPass(!isHiddenFirstPass)}
                        className={s.input__icon}
                      >
                        <EyeSVG />
                      </div>
                    </FormikTextField>
                  </div>

                  <div
                    className={`${s.input} ${s.input__password} ${
                      errors.secondPass ? s.border_alert : null
                    }`}
                  >
                    <FormikTextField
                      type={isHiddenSecondPass ? 'text' : 'password'}
                      placeholder={t<string>('AUTH.CHANGEPASSWORDSUCCESS.FORM.PASSWORDPLACEHOLDER')}
                      label={<p className={s.input__label}>{t<string>('AUTH.CHANGEPASSWORDSUCCESS.FORM.REPEATPASSWORD')}</p>}
                      name="secondPass"
                    >
                      <div
                        onClick={() => setIsHiddenSecondPass(!isHiddenSecondPass)}
                        className={s.input__icon}
                      >
                        <EyeSVG />
                      </div>
                    </FormikTextField>
                  </div>

                  <StrengthBar password={values.firstPass} />

                  <div className={s.buttons}>
                    <motion.button
                       whileTap={
                        (isValid && dirty)
                          ? { scale: 0.99 }
                          : {}
                      }
                      whileHover={
                        (isValid && dirty)
                          ? { scale: 1.02 }
                          : {}
                      }
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      className={s.submit__button}
                      disabled={((!(isValid && dirty)))}
                      type="submit"
                    >
                      <p>{t<string>('AUTH.CHANGEPASSWORDSUCCESS.FORM.SUBMIT')}</p>
                    </motion.button>

                    <motion.button
                      whileTap={{ scale: 0.99 }}
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      onClick={() => navigate(-1)}
                      className={s.back__button}
                      type={'button'}
                    >
                      <BackLongSVG />
                      <p>{t<string>('AUTH.CHANGEPASSWORDSUCCESS.FORM.BACKBUTTON')}</p>
                    </motion.button>
                  </div>
                </div>
              </div>
            )}
          </FormikContainer>
        </motion.div>
        <div className={s.circle} />
      </div>
    </AuthContainer>
  );
};

export const ChangePasswordSuccess = observer(ChangePasswordSuccessView);
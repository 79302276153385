/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { GroupSVG } from 'assets/icons/group';
import { CrossSVG } from 'assets/icons/cross';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import FormikTextField from 'components/form/fields/inputs/index';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  isCheck: any[];
  isCheckGroup: any[];
  hideModal: () => void;
  setIsCheck: (employeIds: any) => void;
  showModal: (Modal: any, modalProps?: any) => void;
  setIsCheckGroup: (employeeItems: any) => void;
}

const ModalCreateGroupView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, showModal, setIsCheckGroup, setIsCheck, isCheckGroup, isCheck } = props;
  const { auth, groups, employees } = useStores();
  const { getAuthModel } = auth;
  const { getGroupsModel, getCreateGroupsAsyncState } = groups;
  const { getEmployeesModel } = employees;
  const { getAuthMe } = getAuthModel;
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This is a required field')
  });
  const isCreatedGroupSuccess = getCreateGroupsAsyncState.getLoadingState;

  const onSubmit = (values: any) => {

    showModal(Modals.ModalCreateGroupWarning, {
      isCheck,
      setIsCheck,
      isCheckGroup,
      setIsCheckGroup,
      title: values.title
    });
  };

  useEffect(() => {
    if (isCreatedGroupSuccess === Loading.success) {
      hideModal();
      employees.getEmployeesAction({ id: getAuthMe.id });
    }

    return () => getCreateGroupsAsyncState.reset();
  }, [isCreatedGroupSuccess]);
  
  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <div className={s.header__icon}>
              <GroupSVG />
            </div>
            <h2 className={s.title}>Create group</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
            You want to create a group of <span className={s.text_weight}>{isCheckGroup?.length} contacts</span>, give it a name so that it can be easily found in the list later
            </p>
          </div>

          <div className={s.group}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit} validationSchema={validationSchema}>
              {({ errors }) => (
                <>
                  <div className={`${s.group__create} ${errors.title ? s.border_alert : null}`}>
                    <FormikTextField
                      name="title"
                      placeholder="Enter name group"
                      type="text"
                      label={<p className={s.group__create_label}>Name group</p>}
                    />
                  </div>

                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Create and save</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>Cancel</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalCreateGroup = observer(ModalCreateGroupView);
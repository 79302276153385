/* eslint-disable no-debugger */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { FC, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { format } from 'date-fns';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { Loader } from 'common/loader';
import ReactTooltip from 'react-tooltip';
import { observer } from 'mobx-react-lite';
import { BackSVG } from 'assets/icons/back';
import { Modals } from 'components/modal';
import { LinkSVG } from 'assets/icons/link';
import { ColorsStatus } from 'common/colors';
import { FunellSVG } from 'assets/icons/funnel';
import { useModal } from 'hooks/useModal.hook';
import { motion, Variants } from 'framer-motion';
import { WarningSVG } from 'assets/icons/warning';
import { Providers } from 'models/providers/types';
import { EmployeeList } from 'models/employees/types';
import { TotalInfoSVG } from 'assets/icons/totalInfo';
import { EditLightSVG } from 'assets/icons/editLight';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikContainer from 'components/form/containers';
import { RouterLink } from 'router/components/routerLink';
import { AnimatedDivPage } from 'components/animatedPage';
import { RepeatPaymentSVG } from 'assets/icons/repeatPayment';
import FormikSelect from 'components/form/fields/select/index';
import { useLocation, useNavigate, useParams } from 'react-router';
import { selectFilterByStatus } from 'common/constants/historyDetail';
import { PaymentsTransferStatus } from 'common/constants/payment';
import { GetPaymentsHistoryDetails, Payments } from 'models/payments/types';
import { WarningRepeatPaymentSVG } from 'assets/icons/repeatPaymentWarning';
import { RepeatFailurePaymentSVG } from 'assets/icons/repeatFailurePayment';
import { TransactionsCount } from 'components/paymentHistory/transactionsCount';
import s from './index.module.scss';

const HistoryDetailView: FC = () => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const { payments, auth, employees, providers } = useStores();
  const params = useParams();
  const {
    getPaymentsModel,
    getPaymentsDetailsAsyncState,
    getUpdatePaymentsDetailsAsyncState,
    getPaymentsStatusAsyncState,
    getPaymentsTransferStatusAsyncState
  } = payments;
  const { getEmployeesModel, getEmployeesAsyncState } = employees;
  const { getProvidersModel } = providers;
  const { getEmployeesList } = getEmployeesModel;
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const { getPaymentsHistoryDetails } = getPaymentsModel;
  const isPaymentsHistoryDetailsLoading = getPaymentsDetailsAsyncState.getLoadingState;
  const isPaymentsStatusLoadingSuccess = getPaymentsStatusAsyncState?.getLoadingState;
  const isEmployeesListLoading = getEmployeesAsyncState.getLoadingState;
  const isPaymentsDescriptionUpdateLoading = getUpdatePaymentsDetailsAsyncState.getLoadingState;
  const isPaymentsTransferStatusLoading = getPaymentsTransferStatusAsyncState?.getLoadingState;

  const [editPaymentDescription, setEditPaymentDescription] = useState<any>(false);
  const removeDuplicates = (data: any, key: any) => [
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];
  const [replacedProvidersItems, setReplacedProvdersItems] = useState<any>([]);
  const [isStatus, setIsStatus] = useState<any>('');
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
  const [ bothStatuses, setBothStatuses ] = useState<any[]>([]);
  const [ allStatusesSuccess, setAllStatusesSuccess ] = useState<any[]>([]);
  const [ allStatusesFailure, setAllStatusesFailure ] = useState<any[]>([]);
  const [ allStatuses, setAllStatuses ] = useState<any[]>([]);
  const { showModal } = useModal();
  const animations: Variants = {
    initial: { opacity: 0, y: '10rem' },
    animate: { opacity: 1, y: '0rem' },
    exit: { opacity: 0, y: '10rem' }
  };

  interface IOnSubmit {
    failurePayment?: boolean;
  }

  const onSubmit = ({ failurePayment }: IOnSubmit ) => {

    const formatResultData = replacedProvidersItems
      .filter((payment: any) => !payment.deletedAt)
      .reduce((replacedAcc: any[], replaced: any) => {
        replacedAcc.push({
          ...replaced,
          providers: replaced.providers.reduce((paymentAcc: any[], payment: any) => {
            toJS(getEmployeesList).map((employee: EmployeeList) => {
              const excludeUselessOperators = employee.accounts
                .filter(
                  (employeeAccount) =>
                    employeeAccount.provider.id === payment.account.provider.id
                )
                .map((employeeReplaced) => employeeReplaced.provider.id)
                .includes(payment.account.provider.id);
              if (
                employee.id === payment.account.employee.id &&
                excludeUselessOperators &&
                !payment?.isOperatorDeleted
              ) {
                return paymentAcc.push(payment);
              }
            });

            return paymentAcc;
          }, [])
        });

        return replacedAcc;
      }, [])
      .filter((payment: any) => {
        if (
          !payment.deletedAt &&
          payment.providers
            .map((paymentElements: any) => paymentElements.account.provider.id)
            .includes(payment.providerId)
        ) {
          return payment;
        }
      })
      ?.reduce((accEmployee: any, employee: any) => {
        accEmployee.push({
          ...employee,
          employeeId: employee?.id,
          providers: employee?.providers?.reduce((accProviders: any, provider: any) => {
            // Hardcode
            toJS(getProvidersModel.getProvidersList).map((providerItem: Providers) => {
              if (providerItem.id === provider?.account?.provider?.id) {
                provider.account.provider = providerItem;
              }
            });

            // Замена accountId для платежа на актуальный, когда мы используем PUT на employee и меняем данные accoubt'a (operator'a)
            toJS(getEmployeesList)
              ?.filter((employeeItem: any) => employeeItem?.id === employee?.id)
              ?.map((account: any) => account?.accounts)
              .flat(1)
              ?.map((accountItem: any) => {
                if (accountItem?.provider?.id === provider?.account?.provider?.id) {
                  provider.account.id = accountItem?.id;
                }
              });

            accProviders.push({
              ...provider,
              ...provider?.account.provider,
              amount: provider.amount || provider.account.defaultPrice,
              description:
                provider?.priceList?.description ||
                `${provider?.account?.provider?.title} services`,
              employeeId: provider?.account?.employee?.id,
              providerId: provider?.account?.provider.id
            });

            return accProviders;
          }, [])
        });

        return accEmployee;
      }, []);

    const formatResultDataVariants = failurePayment ?
      formatResultData.filter((item: any) => item.providers.map((provider: any) => provider.status).some((provider: any) => provider === 'Failure')).reduce((paymentAcc: any[], payment: any) => {

        paymentAcc.push({
          ...payment,
          providers: payment.providers.filter((provider: any) => provider.status === 'Failure')
        });

        return paymentAcc;
      }, [])
      :
      formatResultData;

    const resultProviders: any = removeDuplicates(
      toJS(formatResultDataVariants)
        .map((provider: any): any => provider?.providers)
        .flat(Infinity),
      (key: any) => key.id
    );

    const formatResultProviders = resultProviders?.map((item: any) => ({ ...item, operators: [] }));

    const formatResultDataReverse = formatResultProviders
      ?.map((provider: any) =>
        removeDuplicates(
          formatResultDataVariants?.map((operator: any): any => {
            if (provider?.id === operator?.providerId) {
              provider.operators = removeDuplicates(
                [...provider?.operators, operator],
                (key: any) => key?.employeeId
              );
            }
            return { ...provider };
          }),
          (key: any) => key.id
        )
      )
      .flat(Infinity);

    const formatPaymentData = {
      clientId: getAuthMe?.id,
      transfers: removeDuplicates(formatResultDataVariants, (key: any) => key.id)?.reduce(
        (acc: any, employee: any) => {
          employee?.providers?.map((provider: any) =>
            acc.push({
              accountId: provider.account.id,
              amount: provider.amount,
              priceListId: provider?.priceList?.id || null,
              // TODO: Когда будет лететь с бека currentScenario, поменять - provider?.currentScenario?.id на актуальные данные
              scenarioId: provider?.scenario?.id || null
            })
          );
          return acc;
        },
        []
      )
    };

    navigate('/payment/payment-confirmation', {
      state: {
        totalAmount: getPaymentsHistoryDetails?.amount,
        formatResultData: removeDuplicates(formatResultDataVariants, (key: any) => key.id),
        formatResultDataReverse,
        formatPaymentData,
        goBack: `/history/history-detail/${params.id}`
      }
    });
  };

  const moveToEditErrorDatas = () => {
    const itemsExcludeUselessOperators = replacedProvidersItems
      .filter((payment: any) => !payment.deletedAt)
      .reduce((replacedAcc: any[], replaced: any) => {
        replacedAcc.push({
          ...replaced,
          providers: replaced.providers.reduce((paymentAcc: any[], payment: any) => {
            toJS(getEmployeesList).map((employee: EmployeeList) => {
              const excludeUselessOperators = employee.accounts
                .filter(
                  (employeeAccount) =>
                    employeeAccount.provider.id === payment.account.provider.id
                )
                .map((employeeReplaced) => employeeReplaced.provider.id)
                .includes(payment.account.provider.id);

              if (
                employee.id === payment.account.employee.id &&
                excludeUselessOperators &&
                !payment?.isOperatorDeleted
              ) {
                return paymentAcc.push(payment);
              }
            });

            return paymentAcc;
          }, [])
        });

        return replacedAcc;
      }, [])
      .filter((payment: any) => {
        if (
          !payment.deletedAt &&
          payment.providers
            .map((paymentElements: any) => paymentElements.account.provider.id)
            .includes(payment.providerId)
        ) {
          return payment;
        }
      });

    const isCheck = removeDuplicates(
      removeDuplicates(itemsExcludeUselessOperators, (key: any) => key.id)
        .map((employee: any) => employee?.providers)
        .flat(Infinity)
        .map((employee: any) => employee?.account.employee?.id),
      (key: any) => key
    );

    const isCheckGroup = removeDuplicates(
      itemsExcludeUselessOperators
        .reduce((replacedAcc: any[], replaced: any) => {
          replacedAcc.push({
            group: replaced?.group,
            id: replaced?.employeeId,
            name: replaced?.name,
            surname: replaced?.surname,
            phones: [],
            accounts: replaced?.providers?.map((providerItem: any) => {
              toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                if (provider.id === providerItem?.account?.provider?.id) {
                  providerItem.account.provider = provider;
                }
              });
              return providerItem.account;
            })
          });

          return replacedAcc;
        }, [])
        .reduce((acc: any, item: any) => {
          acc.push({
            ...item,
            accounts: toJS(getPaymentsHistoryDetails)
              .payments.filter((payment: Payments) => !payment.account.employee.deletedAt)
              .reduce((paymentAcc: any[], payment: Payments) => {
                toJS(getEmployeesList).map((employee: EmployeeList) => {
                  if (
                    employee.id === payment.account.employee.id &&
                    employee.accounts
                      .filter(
                        (employeeAccount) =>
                          employeeAccount.provider.id === payment.account.provider.id
                      )
                      .map((employeeReplaced) => employeeReplaced.provider.id)
                      .includes(payment.account.provider.id)
                  ) {
                    paymentAcc.push(payment);
                  }
                });

                return paymentAcc;
              }, [])
              .reduce((paymentAcc: any, paymentItem: any) => {
                if (item?.id === paymentItem?.account?.employee?.id) {
                  // Hardcode
                  toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                    if (provider.id === paymentItem?.account?.provider?.id) {
                      paymentItem.account.provider = provider;
                    }
                  });

                  toJS(getEmployeesList)
                    ?.filter((employeeItem: any) => employeeItem?.id === item?.id)
                    ?.map((account: any) => account?.accounts)
                    .flat(1)
                    ?.map((accountItem: any) => {
                      if (accountItem?.provider?.id === paymentItem?.account?.provider?.id) {
                        paymentItem.account.id = accountItem?.id;
                      }
                    });

                  paymentAcc.push({
                    ...paymentItem?.account,
                    defaultPriceList: paymentItem?.priceList ? paymentItem?.priceList : null,
                    defaultPrice: !paymentItem?.priceList ? paymentItem?.amount : null,
                    defaultScenario: paymentItem?.scenario || null
                  });
                }
                return paymentAcc;
              }, [])
          });
          return acc;
        }, []),
      (key: any) => key?.id
    );

    navigate('/payment', {
      state: {
        enableReinitializate: true,
        isCheck,
        isCheckGroup,
        goBack: `/history/history-detail/${params?.id}`
      }
    });

    window.sessionStorage.setItem('isCheck', JSON.stringify(isCheck));
    window.sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroup));
  };

  const moveToSingleEditErrorDatas = (detail: any) => {
    const isCheck = [detail?.account?.employee?.id];

    const isCheckGroup = toJS(getEmployeesList)?.filter((employee) =>
      isCheck?.includes(employee.id)
    ).length
      ? toJS(getEmployeesList)
        ?.filter((employee) => isCheck?.includes(employee.id))
        .reduce((acc: any, item: any) => {
          acc.push({
            ...item,
            accounts: toJS(getPaymentsHistoryDetails)
              .payments.filter((payment: Payments) => !payment.account.employee.deletedAt)
              .reduce((paymentAcc: any[], payment: Payments) => {
                toJS(getEmployeesList).map((employee: EmployeeList) => {
                  if (
                    employee.id === payment.account.employee.id &&
                      employee.accounts
                        .filter(
                          (employeeAccount) =>
                            employeeAccount.provider.id === payment.account.provider.id
                        )
                        .map((employeeReplaced) => employeeReplaced.provider.id)
                        .includes(payment.account.provider.id)
                  ) {
                    paymentAcc.push(payment);
                  }
                });

                return paymentAcc;
              }, [])
              .reduce((paymentAcc: any, paymentItem: any) => {
                // Hardcode
                toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                  if (provider.id === paymentItem?.account?.provider?.id) {
                    paymentItem.account.provider = provider;
                  }
                });

                if (
                  detail?.account?.provider?.id === paymentItem?.account?.provider?.id &&
                    detail?.account?.employee?.id === paymentItem?.account?.employee?.id
                ) {
                  toJS(getEmployeesList)
                    ?.filter((employeeItem: any) => employeeItem?.id === item?.id)
                    ?.map((account: any) => account?.accounts)
                    .flat(1)
                    ?.map((accountItem: any) => {
                      if (accountItem?.provider?.id === paymentItem?.account?.provider?.id) {
                        paymentItem.account.id = accountItem?.id;
                      }
                    });

                  paymentAcc.push({
                    ...paymentItem?.account,
                    defaultPriceList: paymentItem?.priceList ? paymentItem?.priceList : null,
                    defaultPrice: !paymentItem?.priceList ? paymentItem?.amount : null,
                    defaultScenario: paymentItem?.scenario || null
                  });
                }
                return paymentAcc;
              }, [])
          });
          return acc;
        }, [])
      : null;

    const isCheckGroupDeactivated = !toJS(getEmployeesList)?.filter((employee) =>
      isCheck?.includes(employee.id)
    ).length
      ? [
        {
          group: detail?.account?.employee?.group,
          phones: [],
          id: detail?.account?.employee?.id,
          name: detail?.account?.employee?.name,
          surname: detail?.account?.employee?.surname,
          accounts: toJS(getPaymentsHistoryDetails)
            .payments.filter((payment: Payments) => !payment.account.employee.deletedAt)
            .reduce((paymentAcc: any[], payment: Payments) => {
              toJS(getEmployeesList).map((employee: EmployeeList) => {
                if (
                  employee.id === payment.account.employee.id &&
                    employee.accounts
                      .filter(
                        (employeeAccount) =>
                          employeeAccount.provider.id === payment.account.provider.id
                      )
                      .map((employeeReplaced) => employeeReplaced.provider.id)
                      .includes(payment.account.provider.id)
                ) {
                  paymentAcc.push(payment);
                }
              });

              return paymentAcc;
            }, [])
            .reduce((paymentAcc: any, paymentItem: any) => {
              // Hardcode
              toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                if (provider.id === paymentItem?.account?.provider?.id) {
                  paymentItem.account.provider = provider;
                }
              });

              if (
                detail?.account?.provider?.id === paymentItem?.account?.provider?.id &&
                  detail?.account?.employee?.id === paymentItem?.account?.employee?.id
              ) {
                toJS(getEmployeesList)
                  ?.filter(
                    (employeeItem: any) => employeeItem?.id === detail?.account?.employee?.id
                  )
                  ?.map((account: any) => account?.accounts)
                  .flat(1)
                  ?.map((accountItem: any) => {
                    if (accountItem?.provider?.id === paymentItem?.account?.provider?.id) {
                      paymentItem.account.id = accountItem?.id;
                    }
                  });

                paymentAcc.push({
                  ...paymentItem?.account,
                  defaultPriceList: paymentItem?.priceList ? paymentItem?.priceList : null,
                  defaultPrice: !paymentItem?.priceList ? paymentItem?.amount : null,
                  defaultScenario: paymentItem?.scenario || null
                });
              }
              return paymentAcc;
            }, [])
        }
      ]
      : null;

    navigate('/payment', {
      state: {
        enableReinitializate: true,
        isCheck,
        isCheckGroup: isCheckGroup || isCheckGroupDeactivated,
        // providers: providersItems,
        changedAccounts:
          isCheckGroup?.map((employee: any) => employee.accounts).flat(Infinity) ||
          isCheckGroupDeactivated?.map((employee: any) => employee.accounts).flat(Infinity),
        goBack: `/history/history-detail/${params.id}`
      }
    });

    window.sessionStorage.setItem('isCheck', JSON.stringify(isCheck));
    window.sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroup));
  };

  const handleSelectSort = (e: any) => {
    setIsStatus(e.value);
  };

  const handlePaymentDescription = () => {
    // setEditPaymentDescription(!editPaymentDescription);
    showModal(Modals.ModalEditHistory, { payments, currentPayment: toJS(getPaymentsHistoryDetails) });
  };

  useEffect(() => {
    payments.getPaymentsHistoryDetailsAction({ id: params?.id });

    return () => getPaymentsDetailsAsyncState.reset();
  }, []);

  useEffect(() => {
    employees.getEmployeesAction({ id: getAuthMe?.id,
      // quantity: 1000
    });
  }, []);

  const acceptDetailElementsToState = (paymentsItems: GetPaymentsHistoryDetails | any) =>
    paymentsItems?.payments
      ?.reduce((acc: any, historyItem: any): any => {
        if (historyItem?.account?.employee) {
          historyItem.account.provider.employeeId = historyItem?.account?.employee?.id;
          historyItem.account.employee.employeeId = historyItem?.account?.employee?.id;
          historyItem.account.employee.providerId = historyItem?.account?.provider?.id;
          historyItem.account.employee.amount =
            historyItem?.account?.defaultPrice || historyItem?.amount;
          historyItem.account.employee.description =
            historyItem?.priceList?.description ||
            `${historyItem?.account?.provider?.title} services`;
          acc.push({
            ...historyItem?.account?.employee
          });
        }
        return acc;
      }, [])
      .reduce((acc: any, history: any): any => {
        acc.push({
          ...history,
          providers: removeDuplicates(
            [
              ...paymentsItems?.payments?.filter((insideHistoryItem: any): any => {
                if (history?.id === insideHistoryItem?.account?.employee?.id) {
                  return {
                    ...insideHistoryItem.account?.provider,
                    priceList: insideHistoryItem?.priceList
                  };
                }
              })
            ],
            (key: any) => key.id
          )
        });
        return acc;
      }, [])
      ?.reduce((repalcedAcc: any[], replaced: any) => {
        toJS(getEmployeesList).map((employee: EmployeeList) => {
          if (employee.id === replaced.employeeId) {
            repalcedAcc.push({
              ...replaced,
              providers: replaced.providers.reduce((providerAcc: any[], provider: any) => {
                if (
                  employee.accounts
                    .map((account) => account.provider.id)
                    .includes(provider.account.provider.id)
                ) {
                  providerAcc.push({
                    ...provider,
                    isOperatorDeleted: false
                  });
                } else {
                  providerAcc.push({
                    ...provider,
                    isOperatorDeleted: true
                  });
                }

                return providerAcc;
              }, [])
            });
          }

          if (replaced.deletedAt) {
            repalcedAcc.push({
              ...replaced
            });
          }

          return repalcedAcc;
        });
        return repalcedAcc;
      }, []);

  useEffect(() => {
    setReplacedProvdersItems(acceptDetailElementsToState(toJS(getPaymentsHistoryDetails)));
  }, [
    params,
    isPaymentsHistoryDetailsLoading,
    isEmployeesListLoading,
    isPaymentsStatusLoadingSuccess
  ]);

  useEffect(() => {
    providers.getProvidersAction();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [replacedProvidersItems]);

  useEffect(() => {
    if (isPaymentsDescriptionUpdateLoading === Loading.success) {
      payments.getPaymentsHistoryDetailsAction({ id: params?.id });
      setEditPaymentDescription(false);
    }
  }, [isPaymentsDescriptionUpdateLoading]);

  // useEffect(() => {
  //   let statusInterval: any = '';
  //
  //   if (
  //     toJS(getPaymentsHistoryDetails).payments.filter(
  //       (payment: Payments) => payment.status === 'Accepted'
  //     ).length
  //   ) {
  //     statusInterval = setInterval(async () => {
  //       await payments.getPaymentsHistoryStatusAction({ id: params?.id });
  //       await payments.getPaymentsHistoryDetailsAction({ id: params?.id });
  //     }, 1000);
  //   } else {
  //     clearInterval(statusInterval);
  //   }
  //
  //   return () => clearInterval(statusInterval);
  // }, [isPaymentsHistoryDetailsLoading, isPaymentsStatusLoadingSuccess]);
  // TODO: Протестировать изменение статусов, была удалена предыдущая проверка статусов, добавлена одна единственная на все включая буфер, протестировать в деталке истории платежей и в списке истории платежей
  // TODO: Когда появится баланс, нужно доделать и протестировать логику повторения только неуспешных, завершенных платежей (кнопка уже есть, и некая логика тоже с параметром в onSubmit)
  useEffect(() => {
    let statusInterval: any = '';
    if ((getPaymentsModel.getPaymentsHistoryTransferStatus.map(transfer => transfer.status).includes(PaymentsTransferStatus.inProgress) && sessionStorage.getItem('temporarySessionData')) && !getPaymentsModel.getPaymentsHistoryTransferStatus.map(transfer => transfer.status).includes(PaymentsTransferStatus.waiting)) {
      statusInterval = setInterval(async () => {
        payments.getPaymentsHistoryDetailsAction({ id: params?.id });
      }, 1000);
    }

    return () => clearInterval(statusInterval);
  }, [isPaymentsTransferStatusLoading]);

  useEffect(() => {
    if (getPaymentsModel.getPaymentsHistoryTransferStatus.filter(transfer => transfer.status === PaymentsTransferStatus.completed)) {
      payments.getPaymentsHistoryDetailsAction({ id: params?.id });
    }
  }, [isPaymentsTransferStatusLoading]);

  useEffect(() => {
    if (replacedProvidersItems.length) {
      const returnStatus = (status: string) => removeDuplicates(replacedProvidersItems, (key: any) => key.id).flatMap((historyItem: any) => historyItem.providers).map((item: any) => item.status).some((item: any) => item === status);

      const amountForDefineStatus = (status?: string) => removeDuplicates(replacedProvidersItems, (key: any) => key.id).flatMap((historyItem: any) => historyItem.providers).filter((provider: any) => status ? provider.status === status : provider);

      if (returnStatus('Failure') && returnStatus('Success')) {
        setBothStatuses(removeDuplicates(replacedProvidersItems, (key: any) => key.id).flatMap((historyItem: any) => historyItem.providers).filter((provider: any) => provider.status === 'Success'));
      }

      setAllStatuses(amountForDefineStatus());
      setAllStatusesSuccess(amountForDefineStatus('Success'));
      setAllStatusesFailure(amountForDefineStatus('Failure'));
    }

    return () => {
      setBothStatuses([]);
      setAllStatuses([]);
      setAllStatusesSuccess([]);
      setAllStatusesFailure([]);
    };
  }, [replacedProvidersItems, isPaymentsStatusLoadingSuccess]);

  const repeatFailurePayment = () => {
    onSubmit({ failurePayment: true });
  };

  return (
    <section className={s.wrapper}>
      {isPaymentsHistoryDetailsLoading === Loading.success ? (
        <div className={s.wrapper__container}>
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={{
              initial: { opacity: 0, x: 70, y: 70 },
              animate: { opacity: 1, x: 0, y: 0 },
              exit: { opacity: 0, x: 70, y: 70 }
            }}
            transition={{ duration: 0.7, type: 'spring', damping: 30, stiffness: 155 }}
            className={s.back}
          >
            <ReactTooltip textColor="#334049" backgroundColor="#fff" border borderColor="#E5E8ED" />
            <motion.button
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
              className={s.back_icon}
              onClick={() => navigate(state.previousPageWithQuery, { state: { history: state?.history, page: state.page, sortId: state.sortId, active: state.active, wait: state?.wait, previousPageWithQuery: state.previousPageWithQuery } })
            }
            >
              <BackSVG />
            </motion.button>

            <div className={s.back_text}>
              <p>Back to history</p>
            </div>
          </motion.div>

          <AnimatedDivPage
            transition={{ duration: 0.7, type: 'spring', damping: 20 }}
            animations={animations}
          >
            <div className={s.wrapper__title}>
              <h1 className={s.title}>Payment details</h1>
            </div>
            <div className={s.wrapper__detail}>
              <div className={s.detail}>
                <div className={s.progress}>
                  <TransactionsCount
                    wrapperStyles={{ width: '100%' }}
                    allTransactions={getPaymentsHistoryDetails?.payments?.length}
                    successfulTransactions={
                      getPaymentsHistoryDetails.payments.filter(
                        (payment: any) => payment.status === 'Success'
                      ).length
                    }
                    failureTransactions={
                      getPaymentsHistoryDetails.payments.filter(
                        (payment: any) => payment.status === 'Failure'
                      ).length
                    }
                    inProgressTransactions={
                      getPaymentsHistoryDetails.payments.filter(
                        (payment: any) => payment.status === 'Accepted'
                      ).length
                    }
                    trackSuccessStyles={{ backgroundColor: ColorsStatus.statusSuccessful }}
                    trackFailureStyles={{ backgroundColor: ColorsStatus.statusFailed }}
                    trackInProgressStyles={{ backgroundColor: ColorsStatus.statusInProgress }}
                  />
                </div>

                <div className={s.creator}>
                  <h4>Creator</h4>
                  <p>{getAuthMe?.name ? getAuthMe?.name : 'Name'}</p>
                </div>

                <div className={s.payment__description}>
                  <h2>Description</h2>
                    <div className={s.payment__description_edit}>
                      <div className={s.description}>
                        <p>{toJS(getPaymentsHistoryDetails)?.notes || '----'}</p>
                      </div>

                      <button
                        onClick={handlePaymentDescription}
                        type="button"
                        className={s.employee__remove_icon}
                      >
                        <EditLightSVG />
                      </button>
                    </div>
                </div>

                <div className={s.date}>
                  <div className={s.date__item}>
                    <h5>Date start</h5>
                    <p>
                      {getPaymentsHistoryDetails?.startTx
                        ? format(Date.parse(getPaymentsHistoryDetails?.startTx), 'dd.MM.yyyy HH:mm')
                        : '----'}
                    </p>
                  </div>
                  <div className={s.date__item}>
                    <h5>Date end</h5>
                    <p>
                      {getPaymentsHistoryDetails?.finishTx
                        ? format(
                          Date.parse(getPaymentsHistoryDetails?.finishTx),
                          'dd.MM.yyyy HH:mm'
                        )
                        : '----'}
                    </p>
                  </div>
                </div>

                <div className={s.transactions}>
                  <h4>Transactions</h4>

                  <div className={s.transactions__items}>
                    <div className={s.item}>
                      <h5>Successful</h5>
                      <p>
                        {
                          getPaymentsHistoryDetails?.payments?.filter(
                            (item) => item?.status === 'Success'
                          ).length
                        }
                      </p>

                        <div className={s.both__statuses}>
                          <p>
                            {`${Number(allStatusesSuccess.reduce((itemAcc: number, item: any) => {
                              itemAcc += +item.amount;
                              
                              return itemAcc;
                            }, 0)).toFixed(2).toLocaleString()  } UGX`}
                          </p>
                        </div>

                    </div>
                    <div className={s.item}>
                      <h5>Not successful</h5>
                      <p>
                        {
                          getPaymentsHistoryDetails?.payments?.filter(
                            (item) => item?.status === 'Failure'
                          ).length
                        }
                      </p>

                      <div className={s.both__statuses}>
                        <p>
                          {`${Number(allStatusesFailure.reduce((itemAcc: number, item: any) => {
                            itemAcc += +item.amount;

                            return itemAcc;
                          }, 0)).toFixed(2).toLocaleString()  } UGX`}
                        </p>
                      </div>
                    </div>
                    <div className={s.item}>
                      <h5>All</h5>
                      <p>{getPaymentsHistoryDetails?.payments?.length}</p>

                      <div className={s.both__statuses}>
                        <p>
                          {`${Number(allStatuses.reduce((itemAcc: number, item: any) => {
                            itemAcc += +item.amount;

                            return itemAcc;
                          }, 0)).toFixed(2).toLocaleString()  } UGX`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={s.services}>
                  <FormikContainer initialValues={() => {}} onSubmit={onSubmit}>
                    {() => (
                      <>
                        <div className={s.services__sort}>
                          <FormikSelect
                            valueContainer={{ paddingLeft: '.5rem' }}
                            wrapperSelectStyles={{ width: '11rem' }}
                            options={selectFilterByStatus}
                            name="filter"
                            placeholder="Filter by"
                            indicatorIcon={<DropSelectSVG />}
                            onChange={handleSelectSort}
                          />
                        </div>

                        <div className={s.services__sort_mobile}>
                          <h2 className={s.filter__title}>Filters</h2>
                          <FormikSelect
                            valueContainer={{
                              height: '1.7rem',
                              padding: '0',
                              paddingRight: '1.625rem'
                            }}
                            containerStyles={{ border: 'none' }}
                            selectWrapperStyles={{ width: 'min-content' }}
                            controlStyles={{ backgroundColor: 'transparent' }}
                            wrapperSelectStyles={{ width: '2.7rem' }}
                            menuListStyles={{
                              width: '10rem',
                              right: '0',
                              transform: 'translate(-75%, 0%)'
                            }}
                            childrenStyles={{ left: '0', top: '0', transform: 'none' }}
                            indicatorContainerStyles={{ display: 'none' }}
                            singleValueStyles={{ opacity: 0 }}
                            valueStyles={{ opacity: 0 }}
                            options={selectFilterByStatus}
                            name="filter"
                            placeholder=""
                            indicatorIcon={<DropSelectSVG />}
                            onChange={handleSelectSort}
                          >
                            <div className={s.filter__wrapper}>
                              <button type="button" name="filter" className={s.filter__icon}>
                                <FunellSVG />
                              </button>
                            </div>
                          </FormikSelect>
                        </div>
                      </>
                    )}
                  </FormikContainer>
                  <div className={s.services__list}>
                    {removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                      ?.map((employee: any) =>
                        isStatus === 'All' || !isStatus
                          ? employee?.providers
                          : employee?.providers?.filter((item: any) => item?.status === isStatus)
                      )
                      .flat(Infinity).length >= 1 ? (
                        removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                          .reduce((paymentItemsAcc: any[], paymentItems: any) => {
                            paymentItemsAcc.push({
                              ...paymentItems,
                              providers: paymentItems.providers?.sort((a: any, b: any) => {
                                if (a?.account?.provider?.title < b?.account?.provider?.title) {
                                  return -1;
                                }
                                if (a?.account?.provider?.title > b?.account?.provider?.title) {
                                  return 1;
                                }

                                return 0;
                              })
                            });

                            return paymentItemsAcc;
                          }, [])
                          ?.sort((a: any, b: any) => {
                            if (a?.name + a?.surname < b?.name + a?.surname) {
                              return -1;
                            }
                            if (a?.name + a?.surname > b?.name + a?.surname) {
                              return 1;
                            }

                            return 0;
                          })
                          ?.map((payment: any, index: number) =>
                            !payment?.deletedAt ? (
                            <div key={payment?.id} className={s.item}>
                              {(isStatus === 'All' || !isStatus
                                ? payment?.providers
                                : payment?.providers
                                  ?.sort((a: any, b: any) => {
                                    if (
                                      a?.account?.provider?.title < b?.account?.provider?.title
                                    ) {
                                      return -1;
                                    }
                                    if (
                                      a?.account?.provider?.title > b?.account?.provider?.title
                                    ) {
                                      return 1;
                                    }

                                    return 0;
                                  })
                                  ?.filter((item: any) => item?.status === isStatus)
                              ).length ? (
                                <div className={s.item__title_wrapper}>
                                  <RouterLink to={`/employees/employees-detail/${payment.id}`} state={{ previousPageWithQuery: '/employees' }}>
                                    <div className={s.item__link}>
                                      <h2 className={s.item__title}>
                                        {index + 1}. {payment?.name} {payment?.surname}
                                      </h2>
                                      <div className={s.item__icon}>
                                        <LinkSVG />
                                      </div>
                                    </div>
                                  </RouterLink>
                                </div>
                                ) : null}

                              {(isStatus === 'All' || !isStatus
                                ? payment?.providers
                                : payment?.providers
                                  ?.sort((a: any, b: any) => {
                                    if (
                                      a?.account?.provider?.title < b?.account?.provider?.title
                                    ) {
                                      return -1;
                                    }
                                    if (
                                      a?.account?.provider?.title > b?.account?.provider?.title
                                    ) {
                                      return 1;
                                    }

                                    return 0;
                                  })
                                  ?.filter((item: any) => item?.status === isStatus)
                              )?.map((detail: any) =>
                                !detail?.isOperatorDeleted ? (
                                  <div key={detail?.id} className={s.item__description}>
                                    <div className={s.item__description__inner}>
                                      <h5 className={s.description__title}>
                                        {detail?.account?.provider?.title}
                                      </h5>
                                      <p className={s.description__text}>
                                        {`${Number((
                                          detail?.amount || detail.account.defaultPrice
                                        ))?.toFixed(2)?.toLocaleString()} UGX`}
                                      </p>
                                    </div>

                                    <div className={s.item__description_product}>
                                      <h5 className={s.description__title}>Product:</h5>
                                      {detail?.priceList?.description ? (
                                        <p className={s.description__text}>
                                          {detail?.priceList?.description}
                                        </p>
                                      ) : (
                                        <p className={s.description__text}>Deposit</p>
                                      )}
                                    </div>

                                    <div className={s.transaction__id}>
                                      <h5 className={s.description__title}>Transaction id:</h5>
                                      {' '}
                                      <p className={s.description__text}>{detail.id}</p>
                                    </div>

                                    <div
                                      className={`${s.description__error}
                                    ${detail?.status === 'Accepted' ? s.status__in_process : ''}
                                    ${detail?.status === 'Success' ? s.status__successful : ''}
                                    ${detail?.status === 'Failure' ? s.status__error : ''}
                                    
                                    `}
                                    >
                                      {detail?.status === 'Success' ? (
                                        <p className={s.description__status}>{detail.messege}</p>
                                      ) : null}

                                      {detail?.status === 'Accepted' ? (
                                        <p className={s.description__status}>
                                          {detail.messege || 'In progress'}
                                        </p>
                                      ) : null}

                                      {detail?.status === 'Failure' ? (
                                        <div className={s.wrapper__fail}>
                                          <p className={s.description__status}>{detail.messege}</p>
                                        </div>
                                      ) : null}

                                      <button
                                        // disabled={!toJS(getEmployeesList)?.map((employee: any) => employee?.id).filter((employee: any) => detail?.account?.employee?.id === employee).flat(Infinity)?.length}
                                        type="button"
                                        onClick={() => moveToSingleEditErrorDatas(detail)}
                                      >
                                        <EditLightSVG />
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    key={detail?.id}
                                    className={`${s.item__description} ${s.item__description_deactivated}`}
                                  >
                                    <div className={s.item__description__inner}>
                                      <div
                                        className={s.item__title_wrapper}
                                        data-tip={
                                          'This operator has been deleted, the payment will be made without his participation'
                                        }
                                      >
                                        <h5 className={s.description__title}>
                                          {detail?.account?.provider?.title}{' '}
                                          <span className={s.title__deactivated}>
                                            {' - Deleted '}
                                          </span>
                                        </h5>

                                        <div className={s.item__icon}>
                                          <WarningSVG />
                                        </div>
                                      </div>

                                      <p className={s.description__text}>
                                        {`${Number((
                                          detail?.amount || detail.account.defaultPrice
                                        ))?.toFixed(2)?.toLocaleString()} UGX`}
                                      </p>
                                    </div>

                                    <div className={s.item__description_product}>
                                      <h5 className={s.description__title}>Product:</h5>
                                      {detail?.priceList?.description ? (
                                        <p className={s.description__text}>
                                          {detail?.priceList?.description}
                                        </p>
                                      ) : (
                                        <p className={s.description__text}>Deposit</p>
                                      )}
                                    </div>

                                    <div className={s.description__error}>
                                      {detail?.status === 'Success' ? (
                                        <p className={s.description__status}>{detail.messege}</p>
                                      ) : null}

                                      {detail?.status === 'Accepted' ? (
                                        <p className={s.description__status}>
                                          {detail.messege || 'In progress'}
                                        </p>
                                      ) : null}

                                      {detail?.status === 'Failure' ? (
                                        <div className={s.wrapper__fail}>
                                          <p className={s.description__status}>{detail.messege}</p>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            ) : (
                            <div key={payment?.id} className={`${s.item} ${s.item__deactivated}`}>
                              <div className={s.item__title_wrapper}>
                                <div
                                  className={s.item__title_message}
                                  data-tip={
                                    'This employee has been deactivated, the payment will be made without his participation'
                                  }
                                >
                                  <h2 className={s.item__title}>
                                    {index + 1}. {payment?.name} {payment?.surname}{' '}
                                    <span className={s.title__deactivated}>
                                      {' - Deactivated '}
                                    </span>
                                  </h2>

                                  <div className={s.item__icon}>
                                    <WarningSVG />
                                  </div>
                                </div>
                              </div>

                              {(isStatus === 'All' || !isStatus
                                ? payment?.providers
                                : payment?.providers?.filter(
                                  (item: any) => item?.status === isStatus
                                )
                              )?.map((detail: any) => (
                                <div key={detail?.id} className={s.item__description}>
                                  <div className={s.item__description__inner}>
                                    <div
                                      className={s.item__title_wrapper}
                                      data-tip={
                                        'This operator has been deleted, the payment will be made without his participation'
                                      }
                                    >
                                      <h5 className={s.description__title}>
                                        {detail?.account?.provider?.title}{' '}
                                        <span className={s.title__deactivated}>
                                          {' - Deleted '}
                                        </span>
                                      </h5>

                                      <div className={s.item__icon}>
                                        <WarningSVG />
                                      </div>
                                    </div>

                                    <p className={s.description__text}>
                                      {`${Number((
                                        detail?.amount || detail.account.defaultPrice
                                      ))?.toFixed(2)?.toLocaleString()} UGX`}
                                    </p>
                                  </div>

                                  <div className={s.item__description_product}>
                                    <h5 className={s.description__title}>Product:</h5>
                                    {detail?.priceList?.description ? (
                                      <p className={s.description__text}>
                                        {detail?.priceList?.description}
                                      </p>
                                    ) : (
                                      <p className={s.description__text}>Deposit</p>
                                    )}
                                  </div>

                                  <div className={s.transaction__id}>
                                    <h5 className={s.description__title}>Transaction id:</h5>
                                    {' '}
                                    <p className={s.description__text}>{detail.id}</p>
                                  </div>

                                  <div className={s.description__error}>
                                    {detail?.status === 'Success' ? (
                                      <p className={s.description__status}>{detail.messege}</p>
                                    ) : null}

                                    {detail?.status === 'Accepted' ? (
                                      <p className={s.description__status}>
                                        {detail.messege || 'In progress'}
                                      </p>
                                    ) : null}

                                    {detail?.status === 'Failure' ? (
                                      <div className={s.wrapper__fail}>
                                        <p className={s.description__status}>{detail.messege}</p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                            </div>
                            )
                          )
                      ) : (
                      <h2 className={s.item__title_empty}>There are no payments</h2>
                      )}
                  </div>
                </div>
              </div>
              <div className={s.total__wrapper}>
                <div className={s.total}>
                  <div className={s.total__inner}>
                    <div className={s.total__item}>
                      <p>Employees</p>
                      <p>
                        {[...new Set(replacedProvidersItems.map((item: any) => item.id))]?.length}
                      </p>
                    </div>
                    <div className={s.total__item}>
                      <p>Total amount</p>
                      <p>{Number(getPaymentsHistoryDetails?.amount)?.toFixed(2)?.toLocaleString()} UGX</p>
                    </div>
                    <div className={s.total__item}>
                      <p>Charge</p>
                      <p>{0?.toLocaleString()} UGX</p>
                    </div>

                    <div className={s.delimiter} />

                    <div className={[s.total__item__sum, s.total__item__sum_weight].join(' ')}>
                      <h3>Total</h3>
                      <p>{Number(getPaymentsHistoryDetails?.amount)?.toFixed(2)?.toLocaleString()} UGX</p>
                    </div>
                        {
                          <div className={ [s.total__item__sum, s.total__item__sum_success].join(' ') }>
                            <h3>Total successful</h3>
                            <p>{Number(allStatusesSuccess.reduce((itemAcc: number, item: any) => {
                              itemAcc += +item.amount;
                              
                              return itemAcc;
                            }, 0)).toFixed(2).toLocaleString()} UGX</p>
                          </div>}

                  </div>
                  <FormikContainer initialValues={() => {}} onSubmit={onSubmit} enableReinitialize>
                    {() => (
                      <>
                        {!replacedProvidersItems?.filter(
                          (providerItem: any) => !providerItem?.deletedAt
                        ).length ||
                        removeDuplicates(
                          replacedProvidersItems
                            .map((replacedItem: any) => replacedItem.providers)
                            .flat(Infinity),
                          (key: any) => key.id
                        )
                          .map((provider: any) => provider.isOperatorDeleted)
                          .filter((operatorStatus: any) => operatorStatus !== undefined)
                          .every((element: any) => element === true) ? (
                          <div
                            className={s.total__warning}
                            data-tip={
                              'Unable to (make/edit) payment for deactivated employees and operators'
                            }
                          >
                            <div className={s.icon}>
                              <WarningRepeatPaymentSVG />
                            </div>

                            <p>
                              Unable to (make/edit) payment for deactivated employees and operators
                            </p>
                          </div>
                          ) : null}
                        <div className={s.total__buttons}>
                          <motion.button
                            className={
                              // !removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                              //   .map((employee: any) => employee?.providers)
                              //   .flat(Infinity)
                              //   .map((item: any) => item.status)
                              //   .some((item: any) => item !== 'Success')
                              //   ?
                              s.payment__button_active
                              // : ''
                            }
                            whileTap={{ scale: 0.95 }}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                            type="submit"
                            // disabled={
                            // removeDuplicates(toJS(getPaymentsModel.getPaymentsHistoryDetails)?.payments?.map((payment: any) =>
                            //   toJS(getEmployeesList)?.map((employee: any) => employee?.id).filter((employee: any) => payment?.account?.employee?.id === employee)).flat(Infinity), (key: any) => key)?.length < 1
                            // }
                            disabled={
                              !replacedProvidersItems?.filter(
                                (providerItem: any) => !providerItem?.deletedAt
                              ).length ||
                              removeDuplicates(
                                replacedProvidersItems
                                  .map((replacedItem: any) => replacedItem.providers)
                                  .flat(Infinity),
                                (key: any) => key.id
                              )
                                .map((provider: any) => provider.isOperatorDeleted)
                                .filter((operatorStatus: any) => operatorStatus !== undefined)
                                .every((element: any) => element === true)
                            }
                          >
                            <div className={s.total__icon}>
                              <RepeatPaymentSVG />
                            </div>
                            <p>Repeat payment</p>
                          </motion.button>

                           <motion.button
                            className={
                              // !removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                              //   .map((employee: any) => employee?.providers)
                              //   .flat(Infinity)
                              //   .map((item: any) => item.status)
                              //   .some((item: any) => item !== 'Success')
                              //   ?
                              [s.payment__button_active, s.payment__button_repeat].join(' ')
                              // : ''
                            }
                            whileTap={{ scale: 0.95 }}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                            type="button"
                            onClick={repeatFailurePayment}
                            disabled={
                              (!replacedProvidersItems?.filter(
                                (providerItem: any) => !providerItem?.deletedAt
                              ).length ||
                              removeDuplicates(
                                replacedProvidersItems
                                  .map((replacedItem: any) => replacedItem.providers)
                                  .flat(Infinity),
                                (key: any) => key.id
                              )
                                .map((provider: any) => provider.isOperatorDeleted)
                                .filter((operatorStatus: any) => operatorStatus !== undefined)
                                .every((element: any) => element === true)) || (toJS(getPaymentsModel.getPaymentsHistoryDetails).payments.map(payment => payment.status).some(status => status === 'Accepted')
                              && !!toJS(getPaymentsModel.getPaymentsHistoryDetails).payments.filter(payment => payment.status === 'Failure').length)

                            }
                           >
                            <div className={s.total__icon}>
                              <RepeatFailurePaymentSVG />
                            </div>
                            <p>Repeat failure payment</p>
                           </motion.button>

                          <motion.button
                            className={
                              // removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                              //   .map((employee: any) => employee?.providers)
                              //   .flat(Infinity)
                              //   .map((item: any) => item.status)
                              //   .some((item: any) => item === 'Failure')
                              //   ?
                              s.payment__button_active
                              // : ''
                            }
                            whileTap={
                              // removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                              //   .map((employee: any) => employee?.providers)
                              //   .flat(Infinity)
                              //   .map((item: any) => item.status)
                              //   .some((item: any) => item === 'Failure')
                              // ?
                              { scale: 0.95 }
                              // : {}
                            }
                            whileHover={
                              // removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                              //   .map((employee: any) => employee?.providers)
                              //   .flat(Infinity)
                              //   .map((item: any) => item.status)
                              //   .some((item: any) => item === 'Failure')
                              //   ?
                              { scale: 1.05 }
                              // : {}
                            }
                            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                            type="button"
                            // disabled={
                            //   removeDuplicates(toJS(getPaymentsModel.getPaymentsHistoryDetails)?.payments?.map((payment: any) =>
                            //     toJS(getEmployeesList)?.map((employee: any) => employee?.id).filter((employee: any) => payment?.account?.employee?.id === employee)).flat(Infinity), (key: any) => key)?.length < 1
                            // }
                            disabled={
                              !replacedProvidersItems?.filter(
                                (providerItem: any) => !providerItem?.deletedAt
                              ).length ||
                              removeDuplicates(
                                replacedProvidersItems
                                  .map((replacedItem: any) => replacedItem.providers)
                                  .flat(Infinity),
                                (key: any) => key.id
                              )
                                .map((provider: any) => provider.isOperatorDeleted)
                                .filter((operatorStatus: any) => operatorStatus !== undefined)
                                .every((element: any) => element === true)
                            }
                            onClick={moveToEditErrorDatas}
                          >
                            <p>Edit payment</p>
                          </motion.button>
                        </div>
                      </>
                    )}
                  </FormikContainer>
                </div>
              </div>
            </div>
          </AnimatedDivPage>
          <div className={s.total__wrapper_mobile}>
            <div className={s.total}>
              <div className={s.total__inner}>
                <div className={s.total__info_icon}>
                  <button
                    onClick={() => setIsMoreInfoOpen(!isMoreInfoOpen)}
                    type="button"
                    className={`${s.total__info_button} ${isMoreInfoOpen ? s.arrow_rotate : ''}`}
                  >
                    <TotalInfoSVG />
                  </button>
                </div>

                <motion.div
                  animate={isMoreInfoOpen ? 'open' : 'hidden'}
                  variants={{
                    animate: { height: '100%' },
                    hidden: { height: '0' }
                  }}
                  transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                  className={s.total__main_info}
                >
                  <div className={s.total__item}>
                    <p>Employees</p>
                    <p>
                      {[...new Set(replacedProvidersItems.map((item: any) => item.id))]?.length}
                    </p>
                  </div>
                  <div className={s.total__item}>
                    <p>Total amount</p>
                    <p>{Number(getPaymentsHistoryDetails?.amount)?.toFixed(2)?.toLocaleString()} UGX</p>
                  </div>
                  <div className={s.total__item}>
                    <p>Charge</p>
                    <p>{0?.toLocaleString()} UGX</p>
                  </div>

                  <div className={s.delimiter} />
                </motion.div>

                <div className={[s.total__item__sum, s.total__item__sum_weight].join(' ')}>
                  <h3>Total</h3>
                  <p>{Number(getPaymentsHistoryDetails?.amount)?.toFixed(2)?.toLocaleString()} UGX</p>
                </div>

                {
                  <div className={ [s.total__item__sum, s.total__item__sum_success].join(' ') }>
                    <h3>Total successful</h3>
                    <p>{Number(allStatusesSuccess.reduce((itemAcc: number, item: any) => {
                      itemAcc += +item.amount;
                      
                      return itemAcc;
                    }, 0))?.toFixed(2)?.toLocaleString()} UGX</p>
                  </div>}
              </div>
              <FormikContainer initialValues={() => {}} onSubmit={onSubmit} enableReinitialize>
                {() => (
                  <>
                    {!replacedProvidersItems?.filter(
                      (providerItem: any) => !providerItem?.deletedAt
                    ).length ||
                    removeDuplicates(
                      replacedProvidersItems
                        .map((replacedItem: any) => replacedItem.providers)
                        .flat(Infinity),
                      (key: any) => key.id
                    )
                      .map((provider: any) => provider.isOperatorDeleted)
                      .filter((operatorStatus: any) => operatorStatus !== undefined)
                      .every((element: any) => element === true) ? (
                      <div
                        className={s.total__warning}
                        data-tip={
                          'Unable to (make/edit) payment for deactivated employees and operators'
                        }
                      >
                        <div className={s.icon}>
                          <WarningRepeatPaymentSVG />
                        </div>

                        <p>Unable to (make/edit) payment for deactivated employees and operators</p>
                      </div>
                      ) : null}
                    <div className={s.total__buttons}>
                      <motion.button
                        className={
                          // !removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                          //   .map((employee: any) => employee?.providers)
                          //   .flat(Infinity)
                          //   .map((item: any) => item.status)
                          //   .some((item: any) => item !== 'Success')
                          //   ?
                          s.payment__button_active
                          // : ''
                        }
                        whileTap={
                          // !removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                          //   .map((employee: any) => employee?.providers)
                          //   .flat(Infinity)
                          //   .map((item: any) => item.status)
                          //   .some((item: any) => item !== 'Success')
                          //   ?
                          { scale: 0.95 }
                          // : {}
                        }
                        whileHover={
                          // !removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                          //   .map((employee: any) => employee?.providers)
                          //   .flat(Infinity)
                          //   .map((item: any) => item.status)
                          //   .some((item: any) => item !== 'Success')
                          //   ?
                          { scale: 1.05 }
                          // : {}
                        }
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        type="submit"
                        // disabled={
                        // removeDuplicates(toJS(getPaymentsModel.getPaymentsHistoryDetails)?.payments?.map((payment: any) =>
                        //   toJS(getEmployeesList)?.map((employee: any) => employee?.id).filter((employee: any) => payment?.account?.employee?.id === employee)).flat(Infinity), (key: any) => key)?.length < 1
                        // }
                        disabled={
                          !replacedProvidersItems?.filter(
                            (providerItem: any) => !providerItem?.deletedAt
                          ).length ||
                          removeDuplicates(
                            replacedProvidersItems
                              .map((replacedItem: any) => replacedItem.providers)
                              .flat(Infinity),
                            (key: any) => key.id
                          )
                            .map((provider: any) => provider.isOperatorDeleted)
                            .filter((operatorStatus: any) => operatorStatus !== undefined)
                            .every((element: any) => element === true)
                        }
                      >
                        <div className={s.total__icon}>
                          <RepeatPaymentSVG />
                        </div>
                        <p>Repeat payment</p>
                      </motion.button>

                       <motion.button
                        className={
                          // !removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                          //   .map((employee: any) => employee?.providers)
                          //   .flat(Infinity)
                          //   .map((item: any) => item.status)
                          //   .some((item: any) => item !== 'Success')
                          //   ?
                          [s.payment__button_active, s.payment__button_repeat].join(' ')
                          // : ''
                        }
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        type="button"
                        onClick={repeatFailurePayment}
                        disabled={
                          (!replacedProvidersItems?.filter(
                            (providerItem: any) => !providerItem?.deletedAt
                          ).length ||
                            removeDuplicates(
                              replacedProvidersItems
                                .map((replacedItem: any) => replacedItem.providers)
                                .flat(Infinity),
                              (key: any) => key.id
                            )
                              .map((provider: any) => provider.isOperatorDeleted)
                              .filter((operatorStatus: any) => operatorStatus !== undefined)
                              .every((element: any) => element === true)) || (toJS(getPaymentsModel.getPaymentsHistoryDetails).payments.map(payment => payment.status).some(status => status === 'Accepted')
                            && !!toJS(getPaymentsModel.getPaymentsHistoryDetails).payments.filter(payment => payment.status === 'Failure').length)
                      
                        }
                       >
                        <div className={s.total__icon}>
                          <RepeatFailurePaymentSVG />
                        </div>
                        <p>Repeat failure payment</p>
                       </motion.button>

                      <motion.button
                        className={
                          // removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                          //   .map((employee: any) => employee?.providers)
                          //   .flat(Infinity)
                          //   .map((item: any) => item.status)
                          //   .some((item: any) => item === 'Failure')
                          //   ?
                          s.payment__button_active
                          // : ''
                        }
                        whileTap={
                          // removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                          //   .map((employee: any) => employee?.providers)
                          //   .flat(Infinity)
                          //   .map((item: any) => item.status)
                          //   .some((item: any) => item === 'Failure')
                          //   ?
                          { scale: 0.95 }
                          // : {}
                        }
                        whileHover={
                          // removeDuplicates(replacedProvidersItems, (key: any) => key.id)
                          //   .map((employee: any) => employee?.providers)
                          //   .flat(Infinity)
                          //   .map((item: any) => item.status)
                          //   .some((item: any) => item === 'Failure')
                          //   ?
                          { scale: 1.05 }
                          // : {}
                        }
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        type="button"
                        // disabled={
                        //   removeDuplicates(toJS(getPaymentsModel.getPaymentsHistoryDetails)?.payments?.map((payment: any) =>
                        //     toJS(getEmployeesList)?.map((employee: any) => employee?.id).filter((employee: any) => payment?.account?.employee?.id === employee)).flat(Infinity), (key: any) => key)?.length < 1
                        // }
                        disabled={
                          !replacedProvidersItems?.filter(
                            (providerItem: any) => !providerItem?.deletedAt
                          ).length ||
                          removeDuplicates(
                            replacedProvidersItems
                              .map((replacedItem: any) => replacedItem.providers)
                              .flat(Infinity),
                            (key: any) => key.id
                          )
                            .map((provider: any) => provider.isOperatorDeleted)
                            .filter((operatorStatus: any) => operatorStatus !== undefined)
                            .every((element: any) => element === true)
                        }
                        onClick={moveToEditErrorDatas}
                      >
                        <p>Edit payment</p>
                      </motion.button>
                    </div>
                  </>
                )}
              </FormikContainer>
            </div>
          </div>
        </div>
      ) : (
        <Loader
          loaderStyles={{ width: '50px', height: '50px', borderWidth: '5px', borderTopWidth: '5px' }}
          loaderWrapperStyles={{
            width: '100%',
            height: '100%'
          }}
        />
      )}
    </section>
  );
};

export const HistoryDetail = observer(HistoryDetailView);
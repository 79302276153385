/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import { ColorsAccent, ColorsBackground, ColorsBorder, ColorsGeneral } from 'common/colors';
import React, { ReactElement, useState, useEffect } from 'react';
import Select, {
  components,
  ControlProps,
  DropdownIndicatorProps,
  Props,
  SingleValueProps
} from 'react-select';
import { Loader } from 'common/loader';
import { SelectFieldProps } from './types';
import s from './index.module.scss';

const { Option, Input } = components;

const SelectOption = ({ data: { label, selectMobileStyles, optionIcon }, ...other }: any) => (
  <Option {...other}>
    <div style={selectMobileStyles}>
      <p>{label}</p>

      <div>{optionIcon}</div>
    </div>
  </Option>
);

const SingleValueOption = ({ data: { singleValue }, ...other }: any) => (
  <components.SingleValue {...other}>{singleValue}</components.SingleValue>
);

const SelectMenu = ({ children, selectProps, ...other }: any) => (
    selectProps.loadingMessage ?
  <components.MenuList {...other}>
    {selectProps?.searchField}
    {children}
    {selectProps?.addNewProvider}
    {selectProps?.selectPagination}
  </components.MenuList>
  :
  <Loader />
);

const SelectField = (props: Props | SelectFieldProps | any): ReactElement => {
  const {
    hideInputContainerWrapper,
    indicatorContainerStyles,
    isOptionDisabledProvider,
    selectMobileIconStyles,
    portalTargetDeactivate,
    selectWrapperStyles,
    wrapperSelectStyles,
    menuPortalTargetEl,
    selectMobileStyles,
    defaultInputValue,
    isMenuListLoading,
    handleMenuIsOpen,
    menuPortalTarget,
    containerStyles,
    childrenStyles,
    valueContainer,
    getOptionLabel,
    menuListStyles,
    indicatorIcon,
    controlStyles,
    customStyles,
    optionStyles,
    inputStyles,
    valueStyles,
    iconStyles,
    menuStyles,
    optionIcon,
    children,
    isError,
    onClick,
    ...other
  } = props;

  const portalTarget = portalTargetDeactivate ? null : document.body;
  
  const DropdownIndicator = (indicatorProps: any) => (
    <components.DropdownIndicator {...indicatorProps}>
      {indicatorIcon || 'DropDown icon '}
    </components.DropdownIndicator>
  );

  const stl: Props = {
    container: (provided: { [key: string]: string }) => ({
      ...provided,
      width: '100%',
      border: `1px solid ${ColorsBorder.borderPrimary}`,
      borderRadius: '8px',
      ...containerStyles,
      // TODO: Сделал возможным искать в поле select
      // input: {
      //   width: 0,
      //   height: 0
      // }
    }),
    control: (provided: { [key: string]: string }, state: ControlProps<{}>) => ({
      ...provided,
      borderColor: `${ColorsBorder.borderPrimary}`,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '8px',
      backgroundColor: `${ColorsBackground.backgroundGrayLight}`,
      minHeight: '44px',
      overflow: 'hidden',
      cursor: 'pointer',

      ':hover': {
        borderColor: ColorsBorder.borderPrimary
      },

      ':focus': {
        borderColor: ColorsBorder.borderPrimary
      },
      ...controlStyles
    }),
    indicatorSeparator: (provided: { [key: string]: string }) => ({
      ...provided,
      background: 'inherit',
      display: 'none'
    }),
    placeholder: (provided: { [key: string]: string }) => ({
      ...provided,
      whiteSpace: 'nowrap',
      margin: '0'
    }),

    indicatorsContainer: (provided: { [key: string]: string }) => ({
      ...provided,
      backgroundColor: `${ColorsBackground.backgroundGrayAlmostWhite}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.75rem',
      // height: '2.75rem',
      // position: 'absolute',
      position: 'absolute',
      right: '0',
      ...indicatorContainerStyles,
      height: '100%',
    }),
    dropdownIndicator: (
      provided: { [key: string]: string },
      state: DropdownIndicatorProps<{}>
    ) => ({
      ...provided,
      ...iconStyles
    }),
    menuPortal: (provided: { [key: string]: string }) => ({
      ...provided,
      zIndex: 10
    }),
    menuList: (provided: { [key: string]: string }) => ({
      ...provided,
      border: `1px solid ${ColorsBorder.borderPrimary}`,
      borderRadius: '10px',
      padding: 0,
      boxShadow: 'none',
      backgroundColor: `${ColorsBackground.backgroundGrayLight}`,
      ...menuListStyles
    }),
    menu: (provided: { [key: string]: string }) => ({
      ...provided,
      width: 'max-content',
      borderRadius: '10px',
      boxShadow: 'none',
      zIndex: 10,
      ...menuStyles,
      '@media (max-width: 1024px)': {
        width: '20rem'
    },

      '@media (max-width: 768px)': {
        width: '100%'
    }
    }),
    valueContainer: (provided: { [key: string]: string }) => ({
      ...provided,
      // TODO: Убрать смещение значений после фокуса
      // display: 'flex',
      // flexDirection: 'column',
      // alignItems: 'unset',
      // justifyContent: 'center',
      // height: '2.75rem',
      zIndex: 2,
      paddingRight: '40px',
      ...valueContainer,

      'div:last-child': {
        ...hideInputContainerWrapper
      }
    }),
    singleValue: (provided: { [key: string]: string }, state: SingleValueProps<{}>) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = `${ColorsAccent.accentDark}`;

      return { ...provided, opacity, color, transition, ...valueStyles };
    },
    option: (provided: { [key: string]: string }, { isFocused, isSelected, isDisabled }: any) => ({
      ...provided,
      ...optionStyles,
      padding: '10px 15px',
      transition: 'color, background-color .2s ease',
      cursor: isDisabled ? 'default' : 'pointer',
      color:
        isSelected && !isDisabled
          ? `${ColorsGeneral.white}`
          : isDisabled
          ? `${ColorsBackground.backgroundGrayExtraLight}`
          : `${ColorsAccent.accentDark}`,
      backgroundColor:
        isSelected && !isDisabled
          ? `${ColorsBackground.backgroundPrimary}`
          : isDisabled
          ? `${ColorsBackground.backgroundGrayAlmostWhite}`
          : `${ColorsGeneral.white}`,
      ':hover': {
        backgroundColor:
          isFocused && !isDisabled
            ? `${ColorsBackground.backgroundPrimary}`
            : `${ColorsBackground.backgroundGrayAlmostWhite}`,
        color:
          isFocused && !isDisabled
            ? `${ColorsGeneral.white}`
            : isDisabled
            ? ColorsBackground.backgroundGrayAlmostWhite
            : `${ColorsBackground.backgroundPrimary}`
      },

      'svg path': {
        stroke: isSelected ? ColorsGeneral.white : ColorsAccent.accentDark
      },
      
      ':hover svg path': {
        stroke: ColorsGeneral.white
      },
    }),
    ...customStyles
  };

  const [defaultInputValueState, setDefaultInputValueState] = useState(defaultInputValue);

  useEffect(() => setDefaultInputValueState(defaultInputValue), [defaultInputValue]);

  return (
    <div style={selectWrapperStyles} className={s.select} >
      <Select
      // Потестить эти пункты на mobile varsion
      menuPlacement='auto'
      // menuPosition="fixed"

        styles={stl}
        getOptionLabel={(e: any) => (
          <div>
            {e.value}
            <span style={{ marginLeft: 5 }}>{e.label}</span>
          </div>
        )}
        
        defaultInputValue={defaultInputValueState}
        menuPortalTarget={portalTarget}
        isOptionDisabled={(option: any) => option?.isDisabled}
        noOptionsMessage={() => 'Not found'}
        menuIsOpen={handleMenuIsOpen}
        components={{
          Option: SelectOption,
          SingleValue: SingleValueOption,
          DropdownIndicator,
          MenuList: SelectMenu
        }}
        {...other}
      />
      <div style={childrenStyles} className={s.children__container}>{children}</div>
    </div>
  );
};

export default SelectField;

import React from 'react';
import { TFunction } from 'react-i18next';
import { SortArrowSVG } from 'assets/icons/sortArrow';
import { IPatmentsSortMobile, IPaymentsHistoryTabs } from './types';

export const historyListTitles = (t: TFunction<'translation', undefined>) => [
  {
    name: t<string>('MAIN.PAYMENTHISTORY.LIST.HEADER.DATE'),
    data: 'date',
    icon: <SortArrowSVG />,
    id: 0,
    asc: 1,
    desc: 0
  },
  {
    name: t<string>('MAIN.PAYMENTHISTORY.LIST.HEADER.AMOUNT'),
    data: 'amount',
    icon: <SortArrowSVG />,
    id: 1,
    asc: 7,
    desc: 6
  },
  // {
  //   name: t<string>('MAIN.PAYMENTHISTORY.LIST.HEADER.TYPE'),
  //   data: 'type',
  //   id: 2,
  // },
  {
    name: t<string>('MAIN.PAYMENTHISTORY.LIST.HEADER.TRANSACTIONS'),
    data: 'transactions',
    icon: <SortArrowSVG />,
    id: 3,
    asc: 3,
    desc: 2
  },
  {
    name: t<string>('MAIN.PAYMENTHISTORY.LIST.HEADER.DESCRIPTION'),
    data: 'notes',
    id: 4
  },
];

export const selectHistoryPaymentsSortByTitles = [
  { label: 'Date', singleValue: 'Date', value: 'date', id: 1 },
  { label: 'Amount', singleValue: 'Amount', value: 'amount', id: 2 },
  { label: 'Transactions', singleValue: 'Transactions', value: 'transactions', id: 3 },
];

export const historySortMobile = (t: TFunction<'translation', undefined>): IPatmentsSortMobile[] => [
  { value: 0, label: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.DATEDESC'), singleValue: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.DATEDESC') },
  { value: 1, label: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.DATEASC'), singleValue: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.DATEASC') },
  { value: 2, label: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.TRANSACTIONSDESC'), singleValue: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.TRANSACTIONSDESC') },
  { value: 3, label: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.TRANSACTIONSASC'), singleValue: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.TRANSACTIONSASC') },
  { value: 6, label: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.AMOUNTDESC'), singleValue: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.AMOUNTDESC') },
  { value: 7, label: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.AMOUNTASC'), singleValue: t<string>('MAIN.PAYMENTHISTORY.MOBILESORT.OPTIONS.AMOUNTASC') },
];

export const paymentsHistoryTabsList: IPaymentsHistoryTabs[] = [
  { id: 0, title: 'All payments', wait: false },
  { id: 1, title: 'Scheduled payments', wait: true }
];

import { ColorsButton } from 'common/colors';
import React from 'react';

export const GoogleSVG = () => 
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_335_12091)">
<path d="M23.766 12.7764C23.766 11.9607 23.6999 11.1406 23.5588 10.3381H12.24V14.9591H18.7217C18.4528 16.4494 17.5885 17.7678 16.323 18.6056V21.6039H20.19C22.4608 19.5139 23.766 16.4274 23.766 12.7764Z" fill={ColorsButton.buttonPrimaryText}/>
<path d="M12.24 24.5008C15.4764 24.5008 18.2058 23.4382 20.1944 21.6039L16.3274 18.6055C15.2516 19.3375 13.8626 19.752 12.2444 19.752C9.11376 19.752 6.45934 17.6399 5.50693 14.8003H1.51648V17.8912C3.55359 21.9434 7.70278 24.5008 12.24 24.5008Z" fill="#34A853"/>
<path d="M5.50253 14.8003C4.99987 13.3099 4.99987 11.6961 5.50253 10.2057V7.11481H1.51649C-0.18551 10.5056 -0.18551 14.5004 1.51649 17.8912L5.50253 14.8003Z" fill="#FBBC04"/>
<path d="M12.24 5.24966C13.9508 5.2232 15.6043 5.86697 16.8433 7.04867L20.2694 3.62262C18.1 1.5855 15.2207 0.465534 12.24 0.500809C7.70277 0.500809 3.55359 3.05822 1.51648 7.11481L5.50252 10.2058C6.45052 7.36173 9.10935 5.24966 12.24 5.24966Z" fill="#EA4335"/>
</g>
<defs>
<clipPath id="clip0_335_12091">
<rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
;
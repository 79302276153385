import React from 'react';
import { useNavigate } from 'react-router-dom';

interface RouterLinkProps {
  to: any;
  nav?: boolean;
  state?: any;
  children?: any;
}

export const RouterLink = (props: RouterLinkProps) => {
  const { to = '', nav = true, state = null, children } = props;
  const navigate = useNavigate();

  const onClick = () => {
    if (nav) {
      navigate(to, { state });
    }
    if (!nav && typeof to === 'string') {
      window.location.href = to;
    }
  };
  const elem = React.createElement(
    children.type,
    { onClick, ...children.props },
    children.props.children
  );
  return elem;
};

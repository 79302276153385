/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { GroupSVG } from 'assets/icons/group';
import { CrossSVG } from 'assets/icons/cross';
import { EmployeeList } from 'models/employees/types';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import FormikTextField from 'components/form/fields/inputs/index';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  showModal: (Modal: any, modalProps?: any) => void;
  currentEmployee?: EmployeeList;
}

const ModalCreateGroupDetailView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, showModal, currentEmployee } = props;
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This is a required field')
  });

  const onSubmit = (values: any) => {

    showModal(Modals.ModalCreateGroupDetailWarning, {
      title: values.title,
      currentEmployee
    });
  };
  
  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <div className={s.header__icon}>
              <GroupSVG />
            </div>
            <h2 className={s.title}>Create group</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
            You want to create a group for <span className={s.text_weight}>{`${currentEmployee?.name} ${currentEmployee?.surname}`}</span>, give it a name so that it can be easily found in the list later
            </p>
          </div>

          <div className={s.group}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit} validationSchema={validationSchema}>
              {({ errors }) => (
                <>
                  <div className={`${s.group__create} ${errors.title ? s.border_alert : null}`}>
                    <FormikTextField
                      name="title"
                      placeholder="Enter name group"
                      type="text"
                      label={<p className={s.group__create_label}>Name group</p>}
                    />
                  </div>

                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Create and save</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>Cancel</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalCreateGroupDetail = observer(ModalCreateGroupDetailView);
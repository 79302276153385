import React from 'react';

export const HistorySVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4628 7.55349H11.5401C11.4556 7.55349 11.3866 7.62047 11.3866 7.70233V12.8242C11.3866 12.8726 11.4096 12.9172 11.4499 12.9451L14.6211 15.1907C14.6902 15.2391 14.7861 15.226 14.836 15.1591L15.3847 14.4335C15.4365 14.3647 15.4211 14.2716 15.3521 14.2251L12.6163 12.307V7.70233C12.6163 7.62047 12.5473 7.55349 12.4628 7.55349ZM16.6797 8.95256L19.6878 9.66512C19.7838 9.68744 19.8778 9.61675 19.8778 9.52186L19.8931 6.51721C19.8931 6.39256 19.7454 6.32186 19.6456 6.4L16.6221 8.69023C16.5993 8.70738 16.5819 8.73043 16.5719 8.75673C16.562 8.78304 16.5599 8.81153 16.5659 8.83893C16.5719 8.86634 16.5858 8.89154 16.6059 8.91164C16.626 8.93174 16.6516 8.94592 16.6797 8.95256V8.95256ZM19.897 14.5544L18.8092 14.1916C18.7712 14.179 18.7297 14.1813 18.6935 14.198C18.6573 14.2147 18.6293 14.2445 18.6154 14.2809C18.579 14.3758 18.5406 14.4688 18.5003 14.5619C18.1588 15.3451 17.6696 16.0502 17.0442 16.6549C16.4257 17.2565 15.6927 17.7361 14.8859 18.067C14.0501 18.4097 13.1516 18.5856 12.2441 18.5842C11.3271 18.5842 10.4388 18.4112 9.60239 18.067C8.79555 17.7361 8.06258 17.2565 7.4441 16.6549C6.82059 16.0502 6.33138 15.3451 5.98797 14.5619C5.63654 13.7509 5.45648 12.8797 5.45847 12C5.45847 11.1107 5.63689 10.2474 5.99181 9.43628C6.3333 8.65303 6.82251 7.94791 7.44794 7.34326C8.06642 6.74162 8.79939 6.26206 9.60623 5.93116C10.4388 5.58698 11.329 5.41396 12.2461 5.41396C13.1631 5.41396 14.0513 5.58698 14.8878 5.93116C15.6946 6.26206 16.4276 6.74162 17.0461 7.34326C17.2418 7.53489 17.426 7.73396 17.5948 7.94419L18.742 7.07349C17.2322 5.20186 14.882 3.99814 12.2422 4C7.64554 4.00186 3.95438 7.62233 4.00043 12.0819C4.04647 16.4633 7.72036 20 12.2461 20C15.8048 20 18.836 17.8121 19.991 14.7479C20.0197 14.6698 19.9775 14.5823 19.897 14.5544V14.5544Z"
      fill="#BDBDBD"
    />
  </svg>
);

import React, { CSSProperties } from 'react';
import { motion } from 'framer-motion';
import { ChoosenSchedulePayment } from 'common/constants/schedulePayment/types';
import { WindowType } from 'common/constants/schedulePayment';
import { ColorsAccent, ColorsGray } from 'common/colors';
import s from './index.module.scss';

interface IScheduleCalendarButton {
  stylesWrapper?: CSSProperties;
  date?: Date | any;
  icon?: JSX.Element;
  isOpenCalendar: boolean;
  setIsOpenCalendar: (isOpenCalendar: boolean) => void;
  title: string;
  choosenScedulePayment: ChoosenSchedulePayment;
  openBtnEl?: any;
  calendarTitle: string;
  handleIsOpenCalendar: (e: any, setIsOpenCalendar: (open: boolean) => void, isOpenCalendar: boolean, choosenScedulePayment: ChoosenSchedulePayment, wrapperStylesCalendar: CSSProperties | undefined, setFormattedDate: (formattedDate: string) => void, onDateSelect: (date: Date, kindOfChoosen?: number) => void, minDate: boolean | undefined, currentDate: Date | null, calendarTitle: string ) => void;
  onDateSelect: (date: Date, kindOfChoosen?: number) => void;
  setFormattedDate: (formattedDate: string) => void;
  minDate?: boolean;
  currentDate: Date | null;
  wrapperStylesCalendar?: CSSProperties | undefined;
}

export const ScheduleCalendarButton = (props: IScheduleCalendarButton) => {
  const {
    date,
    stylesWrapper,
    icon,
    isOpenCalendar,
    setIsOpenCalendar,
    title,
    choosenScedulePayment,
    openBtnEl,
    handleIsOpenCalendar,
    wrapperStylesCalendar,
    setFormattedDate,
    onDateSelect,
    minDate,
    calendarTitle,
    currentDate
  } = props;
  const toggleOpenCalculator = (e: any) => {
    // e.stopPropagation();
    // setIsOpenCalendar(!isOpenCalendar);
    handleIsOpenCalendar(e, setIsOpenCalendar, isOpenCalendar, choosenScedulePayment, wrapperStylesCalendar, setFormattedDate, onDateSelect, minDate, currentDate, calendarTitle);
  };

  return (
    <div className={s.wrapper}>
      <h4 className={s.title}>{title}</h4>
      <motion.button
        ref={openBtnEl}
        type='button'
        disabled={choosenScedulePayment && calendarTitle === WindowType.ENDS ? !choosenScedulePayment?.regularStart : false}
        className={s.btn}
        onClick={toggleOpenCalculator}
        style={{ ...stylesWrapper }}
        transition={{
          type: 'spring',
          stiffness: 700,
          damping: 30,
        }}
      >
        <p>{date}</p>
        <div className={s.icon}>
          {icon}
        </div>
      </motion.button>
    </div>
  );
};

import React from 'react';

export const menuTabsConfirmation = [
  {
    index: 0,
    title: 'Employee',
    tab: 'employee'
  },
  {
    index: 1,
    title: 'Operator',
    tab: 'operator'
  }
];

/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, no-param-reassign, no-nested-ternary */
import React, { useEffect } from 'react';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { CreatePayments } from 'models/payments/types';
import FormikContainer from 'components/form/containers';
import { PaymentsController } from 'controllers/payments';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { formatDuration } from 'common/constants/paymentConfirmation';
import { ChoosenSchedulePayment } from 'common/constants/schedulePayment/types';
import s from './index.module.scss';

export interface ModalPaymentConsentViewProps {
  hideModal: () => void;
  payments: PaymentsController;
  formatPaymentsData: CreatePayments & ChoosenSchedulePayment & {
    pending: string,
    regularStart: string,
    regularEnd: string,
    interval: number,
    isChedulePaymentActive: boolean
  };
}

const ModalPaymentWindowConsentView = (props: ModalPaymentConsentViewProps) => {
  const { payments, formatPaymentsData, hideModal } = props;
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };

  const pendingExcludeUTCFormatted = new Intl.DateTimeFormat('en-US', options).format(new Date(new Date(formatPaymentsData.pending).getTime() + new Date(formatPaymentsData.pending).getTimezoneOffset() * 60000));
  const startsExcludeUTCFormatted = new Intl.DateTimeFormat('en-US', options).format(new Date(new Date(formatPaymentsData.regularStart).getTime() + new Date(formatPaymentsData.regularStart).getTimezoneOffset() * 60000));
  const endsExcludeUTCFormatted = new Intl.DateTimeFormat('en-US', options).format(new Date(new Date(formatPaymentsData.regularEnd).getTime() + new Date(formatPaymentsData.regularEnd).getTimezoneOffset() * 60000));
  const intervalUserFriendly: string | number = formatDuration(new Date(formatPaymentsData.interval).getTime() - new Date(new Date(formatPaymentsData.regularStart).getTime() + new Date(formatPaymentsData.regularStart).getTimezoneOffset() * 60000).getTime());
  const intervalInMilliseconds: number | string = new Date(formatPaymentsData.interval).getTime() - new Date(new Date(formatPaymentsData.regularStart).getTime() + new Date(formatPaymentsData.regularStart).getTimezoneOffset() * 60000).getTime();
  const onSubmit = () => {

    const paymentRegularStart = new Date(formatPaymentsData.regularStart);
    const paymentRegularEnd = new Date(formatPaymentsData.regularEnd);
    const paymentRegularPending = new Date(formatPaymentsData.pending);

    const getTimeZoneOffsetString = (date: Date) => {
      const offsetMinutes = date.getTimezoneOffset();
      const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
      const offsetMinutesPart = Math.abs(offsetMinutes % 60);
      const sign = offsetMinutes <= 0 ? '+' : '-';
      return `GMT${sign}${offsetHours.toString().padStart(2, '0')}${offsetMinutesPart.toString().padStart(2, '0')}`;
    };

    const adjustedStart = new Date(paymentRegularStart.getTime() + paymentRegularStart.getTimezoneOffset() * 60 * 1000);
    const adjustedEnd = new Date(paymentRegularEnd.getTime() + paymentRegularEnd.getTimezoneOffset() * 60 * 1000);
    const adjustedPending = new Date(paymentRegularPending.getTime() + paymentRegularPending.getTimezoneOffset() * 60 * 1000);

    const dateAsStringStart = `${adjustedStart.toDateString()} ${adjustedStart.toLocaleTimeString('en-US', { hour12: false })} ${getTimeZoneOffsetString(adjustedStart)}`;
    const dateAsStringEnd = `${adjustedEnd.toDateString()} ${adjustedEnd.toLocaleTimeString('en-US', { hour12: false })} ${getTimeZoneOffsetString(adjustedEnd)}`;
    const dateAsStringPending = `${adjustedPending.toDateString()} ${adjustedPending.toLocaleTimeString('en-US', { hour12: false })} ${getTimeZoneOffsetString(adjustedPending)}`;

    const data: CreatePayments & ChoosenSchedulePayment = formatPaymentsData.isChedulePaymentActive ? ( formatPaymentsData.pending ? {
      transfers: formatPaymentsData.transfers,
      clientId: formatPaymentsData.clientId,
      pending: dateAsStringPending,
      regularStart: null,
      regularEnd: null,
      interval: null,
    }
      :
      {
        transfers: formatPaymentsData.transfers,
        clientId: formatPaymentsData.clientId,
        regularStart: dateAsStringStart,
        regularEnd: dateAsStringEnd,
        interval: intervalInMilliseconds,
        pending: null
      })
      :
      {
        transfers: formatPaymentsData.transfers,
        clientId: formatPaymentsData.clientId,
        pending: null,
        regularStart: null,
        regularEnd: null,
        interval: null,
      };

    payments.createPaymentsAction(data);
    hideModal();
  };

  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Warning</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>

            {
              formatPaymentsData.isChedulePaymentActive ?
                (formatPaymentsData.pending ? (
                <p className={s.description__text}>
                  Planned payment selected, payment will be processed: {<strong>{pendingExcludeUTCFormatted}</strong>}
                </p>
                ) : (
                <p className={s.description__text}>
                  Regular payment selected, payment will be processed: from {
                  <strong>{startsExcludeUTCFormatted}</strong>} to {<strong>{endsExcludeUTCFormatted}</strong>}.
                  Interval: {<strong>{intervalUserFriendly}</strong>}
                </p>
                ))
                :
                null
            }
            { formatPaymentsData.isChedulePaymentActive && <br/> }
            <p className={s.description__text}>
              Do you confirm the payment?
            </p>
          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalPaymentWindowConsent = observer(ModalPaymentWindowConsentView);

import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';
import { CreateEmployee } from 'models/employees/types';

abstract class EmployeesAPI {
  public static createEmployee = (data: CreateEmployee): Promise<AxiosResponse> =>
    RestAPI.post('/api/employees', data);

  public static createCheckProvidersEmployee = (data: any): Promise<AxiosResponse> =>
    RestAPI.post('/api/employees/check-provider', data);

  public static updateEmployee = (data: any): Promise<AxiosResponse> =>
    RestAPI.put(`/api/employees/${data.employeeId}`, data);

  public static getEmployees = (data: any): Promise<AxiosResponse> =>
    RestAPI.get(`/api/employees/${data.id}`, { search: data?.search, page: data?.page, quantity: data?.quantity, sortId: data?.sortId,
      active: data?.active || ''
    });

  public static getEmployeesFilters = (params?: { name?: string }): Promise<AxiosResponse> =>
    RestAPI.get('/api/employees', params);

  public static getEmployeesSort = (data: any): Promise<AxiosResponse> =>
    RestAPI.get('/api/employees/sort-options', data);

  public static getEmployeeDetail = (data: any): Promise<AxiosResponse> =>
    RestAPI.get(`/api/employees/${data.clientId}/${data.employeeId}`);

  public static getEmployeeExport = (data: { clientId: string, employeesId: string[] }): Promise<AxiosResponse> =>
    RestAPI.get(`/api/employees/export/${data.clientId}?employeesId=${data?.employeesId}`);

  public static deleteEmployee = (data: any): Promise<AxiosResponse> =>
    RestAPI.delete(`/api/employees/${data.id}`, data);
}

export default EmployeesAPI;

import * as Yup from 'yup';

export const openModal = 'openModal';
export const hideModal = 'hideModal';

export enum KindOfFieldGroups {
  GROUPNAME = 'groupName',
  GROUPDESCRIPTION = 'groupDescription',
}

export enum KindOfFieldEmployees {
  EMPLOYEEFULLNAME = 'editName',
  EMPLOYEEPHONE = 'editPhone',
  EMPLOYEEPHONEADD = 'addPhone',
}

export const checkValidateFieldsValiants = (editKindOfField: string, createPhoneField?: any) => {

  switch (editKindOfField) {
    case KindOfFieldEmployees.EMPLOYEEFULLNAME:
      return {
        name: Yup.string().required(),
        surname: Yup.string().required()
      };
      break;

    case KindOfFieldEmployees.EMPLOYEEPHONE:
      return { editPhone: Yup.string()
        .transform((value: string) => value.replace(/[^\d]/g, ''))
        .min(10, 'Phone number should be valid')
        .required('This is a required field') };
      break;

    case KindOfFieldEmployees.EMPLOYEEPHONEADD:
      return { ...createPhoneField
        ?.map((item: any) => ({
          [`${item?.phoneName}`]: Yup.string()
            .transform((value: string) => value.replace(/[^\d]/g, ''))
            .min(10, 'Phone number should be valid')
            .required('This is a required field')
        }))
        .reduce((acc: any, item: any) => Object.assign(acc, item), {}) };
      break;

    default:
      return {};
  }
};

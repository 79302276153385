/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { EmployeeList } from 'models/employees/types';
import { GetAccountData } from 'models/accounts/types';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  choosenGroup?: any;
  currentEmployee: EmployeeList;
  isSaveValues?: boolean;
}

const ModalMoveToGroupDetailWarningView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, choosenGroup, currentEmployee, isSaveValues } = props;
  const { auth, employees } = useStores();
  const { getUpdateEmployeeAsyncState } = employees;
  const isUpdateEmployeeLoadingSuccess = getUpdateEmployeeAsyncState.getLoadingState;
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;

  function removeDuplicates<T, K>(data: T[], key: (key: T) => K): T[] {
    return [...new Map(data?.map((item: T) => [key(item), item])).values()];
  }

  const onSubmit = (values: any) => {
    // TODO: Нужно протестировать поле isSaveEmployeeValues, нужно сделать перемещение сотрудника в группу с сохранением его значений. (сделать чекбокс, сейчас тест - isSaveEmployeeValues )
    // TODO: Нужно протестировать обновление запроса за перемещение сотрудников, когда иной раз нажимаю на перемещение, страница не перезагружается, возможно поменять getAsyncState на корневой asyncState и делать ему reset()

    employees.updateEmployeeAction({
      employeeId: currentEmployee?.id,
      name: currentEmployee?.name,
      surname: currentEmployee?.surname,
      phones: currentEmployee?.phones,
      groupId: choosenGroup?.id,
      
      accounts:
      isSaveValues
        ?
        currentEmployee?.accounts?.map(item => ({
          defaultPrice: item?.defaultPrice,
          defaultPriceListId: item?.defaultPriceList?.id,
          defaultScenario: item?.defaultScenario,
          defaultValueEmployee: false,
          providerId: item?.provider?.id,
          employeeId: currentEmployee?.id,
          uniqueId: item?.uniqueId,
          note: item?.note || null
        }))
        :
        currentEmployee?.accounts?.length ?
          [ ...removeDuplicates(currentEmployee?.accounts?.map((employeeItem: any) => choosenGroup?.groupsAccounts?.reduce((accEmployee: any, group: any) => {

            if (employeeItem?.provider?.id === group?.provider?.id) {
              accEmployee.push({
                defaultPrice: employeeItem?.provider?.id === group?.provider?.id ? !group?.priceList?.id ? group.defaultPrice : null : !employeeItem?.defaultPriceList?.id ? employeeItem?.defaultPrice : null,
                defaultPriceListId: employeeItem?.provider?.id === group?.provider?.id ? group?.priceList?.id ? group.priceList?.id : null : employeeItem?.defaultPriceList?.id || null,
                defaultScenario: employeeItem?.defaultScenario,
                defaultValueEmployee: false,
                providerId: employeeItem?.provider?.id,
                employeeId: currentEmployee?.id,
                uniqueId: employeeItem?.uniqueId,
                note: employeeItem?.note || null
              }
              );
            }

            return accEmployee;
          }, [])).flat(Infinity), (key: any) => key.providerId),

          ...choosenGroup?.groupsAccounts?.filter((groupAccount: any) =>
            !removeDuplicates(currentEmployee?.accounts?.map((employeeItem: any) => choosenGroup?.groupsAccounts?.reduce((accEmployee: any, group: any) => {

              if (employeeItem?.provider?.id === group?.provider?.id) {
                accEmployee.push(
                  {
                    defaultPrice: employeeItem?.provider?.id === group?.provider?.id ? !group?.priceList?.id ? group.defaultPrice : null : !employeeItem?.defaultPriceList?.id ? employeeItem?.defaultPrice : null,
                    defaultPriceListId: employeeItem?.provider?.id === group?.provider?.id ? group?.priceList?.id ? group.priceList?.id : null : employeeItem?.defaultPriceList?.id || null,
                    providerId: employeeItem?.provider?.id,
                    employeeId: currentEmployee?.id,
                    uniqueId: employeeItem?.uniqueId,
                    note: employeeItem?.note || null
                  }
                );
              }
              return accEmployee;
            }, [])).flat(Infinity), (key: any) => key.providerId).map((convertEmployee: any) => convertEmployee?.providerId).includes(groupAccount?.provider?.id)).reduce((groupAcc: any, groupItem: any) => {
            groupAcc?.push({
              defaultPrice: !groupItem?.priceList?.id ? groupItem.defaultPrice : null,
              defaultPriceListId: groupItem?.priceList?.id ? groupItem.priceList?.id : null,
              providerId: groupItem?.provider?.id,
              employeeId: currentEmployee?.id,
              uniqueId: null,
              note: null
            });
            return groupAcc;
          }, []),
          ]
          :
          choosenGroup?.groupsAccounts?.reduce((accGroup: any, group: any) => {
            accGroup.push({
              defaultPrice: !group?.priceList?.id ? group.defaultPrice : null,
              defaultPriceListId: group?.priceList?.id || null,
              providerId: group?.provider?.id,
              employeeId: currentEmployee?.id,
              uniqueId: null,
              note: null
            });
            return accGroup;
          }, []).flat(Infinity)
    });
    hideModal();
  };

  // useEffect(() => {
  //   if (isUpdateEmployeeLoadingSuccess === Loading.success) {
  //     employees.getEmployeesAction({ id: getAuthMe?.id || temporaryClientId });
  //     hideModal();
  //   }
  //
  //   return () => {
  //     getUpdateEmployeeAsyncState.reset();
  //   };
  // }, [isUpdateEmployeeLoadingSuccess]);

  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Warning</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              { choosenGroup?.id ? isSaveValues ? 'When adding a employee(s) to a group, all values will be saved.' : "When adding a user to a group, all values that are currently selected will be reassigned to the group's default value, do you really want to change them ?" : 'Are you sure want to leave the employee from group and reset values ?' }
            </p>
          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalMoveToGroupDetailWarning = observer(ModalMoveToGroupDetailWarningView);
import React from 'react';

export const ExportSVG = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 13V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V13M13 5L9 1M9 1L5 5M9 1L9 13"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  title?: any;
  isCheck?: any;
  hideModal: () => void;
  setIsCheck: (employeIds: any) => void;
  setIsCheckGroup: (employeeItems: any) => void;
}

const ModalCreateGroupWarningView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, setIsCheckGroup, setIsCheck, isCheck, title } = props;
  const { auth, employees, groups } = useStores();
  const { getGroupsModel, getCreateGroupsAsyncState } = groups;
  const isCreatedGroupSuccess = getCreateGroupsAsyncState.getLoadingState;
  const { getEmployeesModel, getUpdateEmployeeAsyncState } = employees;
  const isUpdateEmployeeLoadingSuccess = getUpdateEmployeeAsyncState.getLoadingState;
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const onSubmit = async (values: any) => {

    const data: any = {
      clientId: getAuthMe.id,
      title,
      description: '',
      parentId: null,
      employeesId: isCheck,
      accounts: []
    };

    await groups.createGroupsAction(data);
  };
  useEffect(() => {
    if (isCreatedGroupSuccess === Loading.success) {
      employees.getEmployeesAction({ id: getAuthMe.id });
      hideModal();
    }
    return () => getCreateGroupsAsyncState.reset();
  }, [isCreatedGroupSuccess]);



  useEffect(() => {
    if (isUpdateEmployeeLoadingSuccess === Loading.success) {
      setIsCheckGroup([]);
      setIsCheck([]);
    }
  }, [isUpdateEmployeeLoadingSuccess]);

  useEffect(() => {
    if (isCreatedGroupSuccess === Loading.success) {
      toJS(getEmployeesModel.getEmployeesList)?.filter((item: any) => isCheck.includes(item?.id))?.reduce((acc: any, employee: any, index: number) => {
        Promise.all([employees?.updateEmployeeAction({
          employeeId: employee?.id,
          name: employee?.name,
          // phones: employee?.phones.length ? employee?.phones.map((item: any) => (item?.phone?.length > 10 ? { phone: item?.phone.slice(0, item?.phone?.length - 1), favorite: item?.favorite } : item)) : [],
          phones: employee?.phones,
          surname: employee?.surname,
          groupId: toJS(getGroupsModel.getGroupDetail).id,
          accounts: []
        }, toJS(getEmployeesModel.getEmployeesList)?.filter((item: any) => isCheck.includes(item?.id)).length)]);
        return acc;
      }, []);
    }
  }, [isCreatedGroupSuccess]);
  
  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Warning</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              When adding a user to a group, all values that are currently selected will be reassigned to the group's default value, do you really want to change them ?
            </p>
          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalCreateGroupWarning = observer(ModalCreateGroupWarningView);
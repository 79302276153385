export enum KindOfSchedule {
  PLANNED,
  REGULAR
}

export enum RegularPaymentTitles {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year'
}

export enum RegularPaymentTitlesIds {
  DAY = 0,
  WEEK = 1,
  MONTH = 2,
  YEAR = 3
}

export enum WindowType {
  STARTS = 'isStarts',
  ENDS = 'Ends',
  PLANNEDPAYMENT = 'plannedPayment'
}

export enum ScheduleCalendarTitle {
  STARTS = 'Starts',
  PLANNEDPAYMENT = 'Payment date',
  ENDS = 'Ends'
}

export const listKindsOfSchedule = [{
  value: KindOfSchedule.PLANNED,
  label: 'Planned payment',
  singleValue: 'Planned payment'
},
{
  value: KindOfSchedule.REGULAR,
  label: 'Regular payment',
  singleValue: 'Regular payment'
}];

export const regularPaymentInterval = [{
  value: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(),
  label: RegularPaymentTitles.DAY,
  singleValue: RegularPaymentTitles.DAY,
  id: 0
},
{
  value: 52,
  label: RegularPaymentTitles.WEEK,
  singleValue: RegularPaymentTitles.WEEK,
  id: 1
},
{
  value: 12,
  label: RegularPaymentTitles.MONTH,
  singleValue: RegularPaymentTitles.MONTH,
  id: 2
},
{
  value: 1,
  label: RegularPaymentTitles.YEAR,
  singleValue: RegularPaymentTitles.YEAR,
  id: 3
}];

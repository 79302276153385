import React from 'react';

export const MoreFunctionsSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink"
        width="27" height="27" x="0" y="0" viewBox="0 0 64 64"
       xmlSpace="preserve" className="">
      <g transform="matrix(1.04,0,0,1.04,-1.2799999380111728,-1.2800000000000011)">
          <path
              d="M32 23.318A8.682 8.682 0 1 0 40.682 32 8.691 8.691 0 0 0 32 23.318Zm0 15.364A6.682 6.682 0 1 1 38.682 32 6.69 6.69 0 0 1 32 38.682ZM54.043 23.318A8.682 8.682 0 1 0 62.725 32a8.691 8.691 0 0 0-8.682-8.682Zm0 15.364A6.682 6.682 0 1 1 60.725 32a6.69 6.69 0 0 1-6.682 6.682ZM9.957 40.682A8.682 8.682 0 1 0 1.275 32a8.691 8.691 0 0 0 8.682 8.682Zm0-15.364A6.682 6.682 0 1 1 3.275 32a6.69 6.69 0 0 1 6.682-6.682Z"
              fill="#000000" data-original="#000000" className=""/>
      </g>
  </svg>
  // Filled dots
  // <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink"
  //       width="32" height="32" x="0" y="0" viewBox="0 0 512 512"
  //      xmlSpace="preserve" className="">
  //     <g>
  //         <path
  //             d="M447.5 319.5c-35.375 0-64-28.625-64-64s28.625-64 64-64c35.313 0 64 28.625 64 64s-28.687 64-64 64zm-192 0c-35.375 0-64-28.625-64-64s28.625-64 64-64 64 28.625 64 64-28.625 64-64 64zm-192 0c-35.375 0-64-28.625-64-64s28.625-64 64-64 64 28.625 64 64-28.625 64-64 64z"
  //             fill="#000000" data-original="#000000" className=""/>
  //     </g>
  // </svg>
);

import { makeAutoObservable } from 'mobx';
import { AuthMe, AuthModel, ICreateNewPassword, IGoogleSignUp } from './types';

export class AuthEntity implements AuthModel {
  private authMe: AuthMe = {
    id: '',
    email: '',
    name: '',
    picture: '',
    phone: '',
    balance: {
      account: '',
      amount: 0
    }
  };

  private authSignUp: any = {};

  private authSignIn: any = {};

  private authSignInMobile: any = {};

  private authSignInGoogle: any = {};

  private authSignUpGoogle: IGoogleSignUp = {
    name: '',
    email: '',
    picture: ''
  };

  private authVerification: any = {};

  private resetPasswordCodeData: ICreateNewPassword = {
    key: ''
  };

  setAuthMe(authMe: AuthMe) {
    this.authMe = authMe;
  }

  setAuthSignUp(authSignUp: any) {
    this.authSignUp = authSignUp;
  }

  setAuthSignIn(authSignIn: any) {
    this.authSignIn = authSignIn;
  }

  setAuthSignInMobile(authSignInMobile: any) {
    this.authSignInMobile = authSignInMobile;
  }

  setAuthSignInGoogle(authSignInGoogle: any) {
    this.authSignInGoogle = authSignInGoogle;
  }

  setAuthSignUpGoogle(authSignUpGoogle: IGoogleSignUp) {
    this.authSignUpGoogle = authSignUpGoogle;
  }

  setAuthVerification(authVerification: any) {
    this.authVerification = authVerification;
  }

  setAuthResetPasswordCode(resetPasswordCodeData: ICreateNewPassword) {
    this.resetPasswordCodeData = resetPasswordCodeData;
  }

  get getAuthResetPasswordCode() {
    return this.resetPasswordCodeData;
  }

  get getAuthMe(): AuthMe {
    return JSON.parse(localStorage.getItem('authMe')!)?.id ? { ...this.authMe, ...JSON.parse(localStorage.getItem('authMe')!) } : this.authMe;
  }

  get getAuthSignUpGoogle(): IGoogleSignUp {
    return this.authSignUpGoogle;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
import React from 'react';

export const PaySVG = () => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 4V14C22 15.1 21.1 16 20 16H4C3.45 16 3 15.55 3 15C3 14.45 3.45 14 4 14H20V4C20 3.45 20.45 3 21 3C21.55 3 22 3.45 22 4ZM3 12C1.34 12 0 10.66 0 9V3C0 1.34 1.34 0 3 0H15C16.66 0 18 1.34 18 3V10C18 11.1 17.1 12 16 12H3ZM6 6C6 7.66 7.34 9 9 9C10.66 9 12 7.66 12 6C12 4.34 10.66 3 9 3C7.34 3 6 4.34 6 6Z"
      fill="#1a1a1a"
    />
  </svg>
);

import React from 'react';
import { ColorsText } from 'common/colors';
import s from './index.module.scss';

export const PrevArrowSVG = () => (
  <div className={s.prev}>
    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36549 0.234315C5.67791 0.546734 5.67791 1.05327 5.36549 1.36569L2.73118 4L5.36549 6.63432C5.67791 6.94673 5.67791 7.45327 5.36549 7.76569C5.05307 8.0781 4.54654 8.0781 4.23412 7.76569L1.03412 4.56569C0.7217 4.25327 0.7217 3.74673 1.03412 3.43431L4.23412 0.234315C4.54654 -0.0781049 5.05307 -0.0781049 5.36549 0.234315Z"
        fill={ColorsText.textTitle}
      />
    </svg>
  </div>
);

export const NextArrowSVG = () => (
  <div className={s.next}>
    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.634706 7.76569C0.322286 7.45327 0.322286 6.94673 0.634706 6.63432L3.26902 4L0.634705 1.36569C0.322286 1.05327 0.322286 0.546734 0.634705 0.234315C0.947125 -0.078105 1.45366 -0.078105 1.76608 0.234315L4.96608 3.43431C5.2785 3.74673 5.2785 4.25327 4.96608 4.56569L1.76608 7.76569C1.45366 8.0781 0.947125 8.0781 0.634706 7.76569Z"
        fill={ColorsText.textTitle}
      />
    </svg>
  </div>
);

import React, { CSSProperties, ReactChild } from 'react';
import { observer } from 'mobx-react-lite';
import s from './index.module.scss';

interface ILoaderProps {
  loaderStyles?: CSSProperties;
  loaderWrapperStyles?: CSSProperties;
  children?: ReactChild;
}

const LoaderView = (props: ILoaderProps) => {
  const { loaderStyles, loaderWrapperStyles, children } = props;
  return (
    <div style={loaderWrapperStyles} className={s.loader__wrapper}>
      <div style={loaderStyles} className={s.loader} />
      {children}
    </div>
  );
};

export const Loader = observer(LoaderView);

import { AnimatedDivPage } from 'components/animatedPage';
import { observer } from 'mobx-react-lite';
import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import s from './index.module.scss';

interface ISecondLayoutProps {
  children?: ReactElement | null;
}

const AuthLayoutView = ({ children }: ISecondLayoutProps): ReactElement => (
  <section className={s.wrapper}>
    <Outlet />
    {children || null}
  </section>
);

export const AuthLayout = observer(AuthLayoutView);
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'containers/modal/modalProvider';
import { RoutesContainer } from './routesContainer';

const MainRouter = () => (
  <BrowserRouter>
    <ModalProvider>
      <RoutesContainer />
    </ModalProvider>
  </BrowserRouter>
);

export default React.memo(MainRouter);

// eslint-disable-next-line max-classes-per-file
import React from 'react';
import { toast } from 'react-toastify';
import { NavigateFunction } from 'react-router';
import { SortArrowSVG } from 'assets/icons/sortArrow';
import { GetAccountData } from 'models/accounts/types';
import {
  EmployeeList,
  EmployeeListCheckboxAll,
  EmployeeProviders,
  ICreateEmployeeParsedData
} from 'models/employees/types';
import { TFunction } from 'react-i18next';
import {
  IEmployeesListTitles,
  IEmployeesSort,
  IEmployeesSortMobile,
} from './types';
import { ProviderMeasure } from '../payment';
import { IMoreFunctionsItems } from '../moreFunctions/types';
import { moreFunctionsItems } from '../moreFunctions';

export const employeesListTitles = (t: any): IEmployeesListTitles[] => [
  {
    name: t('MAIN.EMPLOYEES.LIST.HEADER.STATUS'),
    data: 'status',
    // icon: <SortArrowSVG/>,
    // asc: 5,
    // desc: 4,
    id: 0
  },
  {
    name: t('MAIN.EMPLOYEES.LIST.HEADER.NAME'),
    data: 'name',
    id: 1
  },
  {
    name: t('MAIN.EMPLOYEES.LIST.HEADER.SURNAME'),
    data: 'surname',
    id: 2
  },
  {
    name: t('MAIN.EMPLOYEES.LIST.HEADER.GROUP'),
    data: 'group',
    icon: <SortArrowSVG/>,
    id: 3,
    asc: 2,
    desc: 3
  },
  {
    name: t('MAIN.EMPLOYEES.LIST.HEADER.PHONE'),
    data: 'phone',
    id: 4
  }
];

export const selectEmployeeSortByTitles = [
  { label: 'Operator', singleValue: 'Operator', value: 'operator', id: 1 },
  { label: 'Amount', singleValue: 'Amount', value: 'amount', id: 2 }
];

export const selectSortByEmployeesGroupItems = [{ label: 'Group name', value: 'groupName', id: 1 }];

export const employeesSortMobile = (t: any): IEmployeesSortMobile[] => [
  {
    value: 0,
    label: t('MAIN.EMPLOYEES.MOBILESORT.ALL'),
    singleValue: t('MAIN.EMPLOYEES.MOBILESORT.ALL')
  },
  // {
  //   value: 4,
  //   label: t('MAIN.EMPLOYEES.MOBILESORT.ACTIVE'),
  //   singleValue: t('MAIN.EMPLOYEES.MOBILESORT.ACTIVE')
  // },
  // {
  //   value: 5,
  //   label: t('MAIN.EMPLOYEES.MOBILESORT.INACTIVE'),
  //   singleValue: t('MAIN.EMPLOYEES.MOBILESORT.INACTIVE')
  // },
  {
    value: 2,
    label: t('MAIN.EMPLOYEES.MOBILESORT.ASC'),
    singleValue: t('MAIN.EMPLOYEES.MOBILESORT.ASC')
  },
  {
    value: 3,
    label: t('MAIN.EMPLOYEES.MOBILESORT.DESC'),
    singleValue: t('MAIN.EMPLOYEES.MOBILESORT.DESC')
  }
];

export const selectFilterByEmployeesStatus = (t: TFunction<'translation', undefined>) => [
  { label: 'Filter by all', singleValue: 'Filter by all', value: 0, id: 0 },
  { label: 'Filter by active', singleValue: 'Filter by active', value: 2, id: 1 },
  { label: 'Filter by inactive', singleValue: 'Filter by inactive', value: 3, id: 2 },
];

export enum EmployeesHeaderButtons {
  EDIT_EMPLOYEES = 'Edit',
  MAKE_GROUP = 'MakeGroup',
  MOVE_TO_GROUP = 'MoveToGroup',
  IMPORT = 'Import',
  EXPORT = 'Export',
  DEACTIVATE = 'Deactivate'
}

export const chooseAllEmployees: EmployeeList = {
  group: null,
  id: 'allEmployees',
  name: 'All',
  phones: [],
  surname: '',
  accounts: [],
  isCheckboxAll: true
};

export const checkAccountChanges = (existEmployee: GetAccountData, createEmployee: EmployeeProviders) => (existEmployee.provider.id === createEmployee.providerId) && (existEmployee.defaultPrice !== createEmployee.defaultPrice || (existEmployee.defaultPriceList ? existEmployee.defaultPriceList?.id : null) !== createEmployee.defaultPriceListId || (existEmployee.defaultScenario ? existEmployee.defaultScenario?.id : null) !== createEmployee?.defaultScenarioId || existEmployee.uniqueId !== createEmployee.uniqueId);

class CsvFieldsErrors {
  // eslint-disable-next-line class-methods-use-this
  getEmptyFieldsError(message: string, moreFunc: IMoreFunctionsItems, navigate: NavigateFunction) {
    toast.error(message);
    navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
    throw new Error(message);
  }

  // eslint-disable-next-line class-methods-use-this
  getAllFilledFieldsError(message: string, moreFunc: IMoreFunctionsItems, navigate: NavigateFunction) {
    toast.error(message);
    navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
    throw new Error(message);
  }

  // eslint-disable-next-line class-methods-use-this
  getScenarioLabelError(message: string, moreFunc: IMoreFunctionsItems, navigate: NavigateFunction) {
    toast.error(message);
    navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
    throw new Error(message);
  }

  // eslint-disable-next-line class-methods-use-this
  getOperatorIdError(message: string, moreFunc: IMoreFunctionsItems, navigate: NavigateFunction) {
    toast.error(message);
    navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
    throw new Error(message);
  }

  // eslint-disable-next-line class-methods-use-this
  getAmountValidateError(message: string, moreFunc: IMoreFunctionsItems, navigate: NavigateFunction) {
    toast.error(message);
    navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
    throw new Error(message);
  }

  // eslint-disable-next-line class-methods-use-this
  getCustomerNumberValidateError(message: string, moreFunc: IMoreFunctionsItems, navigate: NavigateFunction) {
    toast.error(message);
    navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
    throw new Error(message);
  }

  // eslint-disable-next-line class-methods-use-this
  getScenarioFieldError(message: string, moreFunc: IMoreFunctionsItems, navigate: NavigateFunction) {
    toast.error(message);
    navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
    throw new Error(message);
  }

  // eslint-disable-next-line class-methods-use-this
  getProductIdFieldError(message: string, moreFunc: IMoreFunctionsItems, navigate: NavigateFunction) {
    toast.error(message);
    navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
    throw new Error(message);
  }
}

export class HandlingPaymentFields {
  private navigate: NavigateFunction;

  private moreFunc: IMoreFunctionsItems;

  constructor(measure: string, scenarioType: string | null, newTrimedItem: ICreateEmployeeParsedData, moreFunc: IMoreFunctionsItems, navigate: NavigateFunction) {
    this.navigate = navigate;
    this.moreFunc = moreFunc;
    if (measure === ProviderMeasure.MONEY) {
      this.handleMoneyMeasure(measure, scenarioType, newTrimedItem);
    } else if (measure === ProviderMeasure.PACKAGES) {
      this.handlePackagesMeasure(measure, scenarioType, newTrimedItem);
    } else if (measure === ProviderMeasure.PACKAGES_AND_MONEY) {
      this.handlePackagesAndMoneyMeasure(measure, scenarioType, newTrimedItem);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleMoneyMeasure(measure: string, scenarioType: string | null, newTrimedItem: ICreateEmployeeParsedData, ) {
    const message: string = `Some column has the wrong amount field, this operator: ${newTrimedItem.operatorId} cannot work with the productId type, it needs to specify an amount and the productId must be empty. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`;
    const messageScenarioType: string = `Some column has the wrong amount field, this operator: ${newTrimedItem.operatorId} has scenario with the type: ${scenarioType}, this type can't work with the productId field, it needs to specify an amount and the productId must be empty. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`;
    if ((newTrimedItem.amount === 'null' || newTrimedItem.amount === '') && (newTrimedItem.productId && newTrimedItem.productId !== 'null' && newTrimedItem.productId !== '')) {
      toast.error(message);
      this.navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
      throw new Error(message);
    }

    if (scenarioType === 'Amount' && (newTrimedItem.amount === 'null' || newTrimedItem.amount === '' || !newTrimedItem.amount)) {
      toast.error(messageScenarioType);
      this.navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
      throw new Error(messageScenarioType);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handlePackagesMeasure(measure: string, scenarioType: string | null, newTrimedItem: ICreateEmployeeParsedData) {
    const message: string = `Some column has the wrong productId field, this operator: ${newTrimedItem.operatorId} can't work with the amount type, it needs to specify an productId and the amount must be empty. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`;
    if ((newTrimedItem.productId === 'null' || newTrimedItem.productId === '') && (newTrimedItem.amount && newTrimedItem.amount !== 'null' && newTrimedItem.amount !== '')) {
      toast.error(message);
      this.navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
      throw new Error(message);
    }
  }
  
  // eslint-disable-next-line class-methods-use-this
  handlePackagesAndMoneyMeasure(measure: string, scenarioType: string | null, newTrimedItem: ICreateEmployeeParsedData) {
    const messageScenarioAmountType: string = `Some column has the wrong amount field, this operator: ${newTrimedItem.operatorId} has scenario with the type: ${scenarioType}, this type can't work with the productId field, it needs to specify an amount and the productId must be empty. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`;
    const messageScenarioPriceListType: string = `Some column has the wrong productId field, this operator: ${newTrimedItem.operatorId} has scenario with the type: ${scenarioType}, this type can't work with the amount field, it needs to specify an productId and the amount must be empty. Name for search in .csv: ${newTrimedItem.firstName} ${newTrimedItem.lastName}`;
    if (scenarioType === 'PriceList' && (!newTrimedItem.productId || newTrimedItem.productId === 'null' || newTrimedItem.productId === '')) {
      toast.error(messageScenarioPriceListType);
      this.navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
      throw new Error(messageScenarioPriceListType);
    }

    if (scenarioType === 'Amount' && (!newTrimedItem.amount || newTrimedItem.amount === 'null' || newTrimedItem.amount === '')) {
      toast.error(messageScenarioAmountType);
      this.navigate('/functions/functions-detail/import', { state: { moreFunc: moreFunctionsItems[0] } });
      throw new Error(messageScenarioAmountType);
    }
  }
}

const correctFields: string[] = [
  'firstName', 'lastName', 'operatorId', 'id', 'amount', 'productId', 'scenarioLabel', 'phoneNumber'
];

export const validationHeaders = (fields: string[]) => fields.filter(header => !correctFields.includes(header));

export enum FilterEmployees {
  ACTIVE = 0,
  ACTIVE_NO_PAGE = 1,
  INACTIVE_NO_PAGE = 2,
  DELETED = 3,
  ALL_NO_PAGE = 4
}

export const csvErrors = new CsvFieldsErrors();

import React from 'react';

export const PreviousArrowSVG = () => (
  <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.86578 0.234314C5.1782 0.546734 5.1782 1.05327 4.86578 1.36568L2.23147 4L4.86578 6.63431C5.1782 6.94673 5.1782 7.45327 4.86578 7.76569C4.55336 8.0781 4.04683 8.0781 3.73441 7.76568L0.534412 4.56569C0.221992 4.25327 0.221992 3.74673 0.534412 3.43431L3.73441 0.234314C4.04683 -0.0781051 4.55336 -0.078105 4.86578 0.234314Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const NextArrowSVG = () => (
  <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.13422 7.76569C0.821798 7.45327 0.821798 6.94673 1.13422 6.63431L3.76853 4L1.13422 1.36569C0.821797 1.05327 0.821797 0.546734 1.13422 0.234315C1.44664 -0.078105 1.95317 -0.078105 2.26559 0.234315L5.46559 3.43431C5.77801 3.74673 5.77801 4.25327 5.46559 4.56569L2.26559 7.76569C1.95317 8.0781 1.44664 8.0781 1.13422 7.76569Z"
      fill="#1A1A1A"
    />
  </svg>
);

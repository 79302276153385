import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';

abstract class TransactionsAPI {
  public static createTransactions = (data: any): Promise<AxiosResponse> =>
    RestAPI.post('/api/transactions', data);

  public static getTransactions = (data: any): Promise<AxiosResponse> =>
    RestAPI.get('/api/transactions', data);

  public static getDetailTransactions = (data: any): Promise<AxiosResponse> =>
    RestAPI.get(`/api/transactions/${data.id}`);

  public static updateDetailTransactions = (data: any): Promise<AxiosResponse> =>
    RestAPI.patch(`/api/transactions/${data.id}`, data);

  public static deleteDetailTransactions = (data: any): Promise<AxiosResponse> =>
    RestAPI.delete(`/api/transactions/${data.id}`, data);
}

export default TransactionsAPI;

import React from 'react';
import { motion, Variants } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { checkPasswordStrong } from 'components/progressBar';
import s from './index.module.scss';

interface StrengthBarViewProps {
  password: string;
}

const StrengthBarView = (props: StrengthBarViewProps) => {
  const { password } = props;
  const { t } = useTranslation();
  const itemVariants: Variants = {
    open: {
      width: checkPasswordStrong(password)?.width,
      transition: {
        type: 'spring',
        bounce: 0,
        duration: 0.7,
      }
    },
    closed: {
      width: '0',
      transition: {
        type: 'spring',
        bounce: 0,
        duration: 0.7
      }
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.progress__title}>
        <p>{checkPasswordStrong(password)?.strength || t<string>('AUTH.SIGNUP.FORM.PASSWORDEASY')}</p>
      </div>
      <div className={s.wrapper__progress_bar}>
        <div
          className={`${s.progress_bar}
          
          `}
        >
          <motion.div
            animate={password ? 'open' : 'closed'}
            variants={itemVariants}
            style={{ background: checkPasswordStrong(password)?.backgroundColor }}
            className={`${s.progress_bar_strength}
          `}
          />
        </div>
      </div>
    </div>
  );
};

export const StrengthBar = observer(StrengthBarView);

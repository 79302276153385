/* eslint-disable no-param-reassign */
import React, { ReactElement, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Props } from 'react-select';
import { FromikSelectProps } from './types';
import s from './index.module.scss';
import './phone.css';

const FormikSelectCountries = (props: Props & FromikSelectProps): ReactElement => {
  const { label = '', name = '', children, wrapperChildrenStyles } = props;
  const refEl = useRef<any>();

  return (
    <>
      {label && (
        <label className={s.label} htmlFor={name}>
          <h4 className={s.label__title}>{label}</h4>
        </label>
      )}
      <div className={s.wrapper} ref={refEl}>
        <PhoneInput
          country={'ug'}
          onlyCountries={['ug']}
          specialLabel={''}
          value={''}
          masks={{ ug: '.... ... ... .. ..' }}
          placeholder={'Phone number'}
        />
        <div style={wrapperChildrenStyles} className={s.wrapper__input__phone}>{children}</div>
      </div>
    </>
  );
};

export default FormikSelectCountries;

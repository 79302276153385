import React, { CSSProperties } from 'react';
import { motion } from 'framer-motion';
import { Location } from 'react-router-dom';
import { NavigateFunction } from 'react-router';
import { EmployeesController } from 'controllers/employees';
import { EmployeeActiveSVG } from 'assets/icons/employeeActive';
import { FilterEmployees } from 'common/constants/employeesList';
import { EmployeeInactiveSVG } from 'assets/icons/employeeInactive';
import s from './index.module.scss';

interface ISwitchStatus {
  id: string;
  dynamicPage: string;
  dynamicSort: string;
  dynamicFilter: string;
  setDynamicFilter: (filter: string) => void;
  setDynamicPage?: (page: string) => void;
  setIsCheckAll: (isCheck: boolean) => void;
  setIsCheck: (isCheck: string[]) => void;
  setIsCheckGroup: (isCheckGroup: any[]) => void;
  setCompletedGroupDataCount: (groupDataCount: number) => void;
  setCreateEmployeeData: (createEmployeeData: any[]) => void;
  search: string;
  navigate: NavigateFunction;
  params: URLSearchParams;
  location: Location;
  employees: EmployeesController;
  stylesWrapper?: CSSProperties;
  stylesCircle?: CSSProperties;
}

export const SwitchStatus = (props: ISwitchStatus) => {
  const { id, setDynamicPage, setIsCheck, setIsCheckGroup, setCompletedGroupDataCount, setCreateEmployeeData, dynamicSort, setIsCheckAll, stylesWrapper, stylesCircle, location, params, navigate, dynamicFilter, setDynamicFilter, dynamicPage, search, employees } = props;

  const toggleSwitch = () => {
    const currentDynamicFilter =
      dynamicFilter === FilterEmployees.ACTIVE.toString()
        ? FilterEmployees.DELETED.toString()
        : FilterEmployees.ACTIVE.toString();

    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('filter', currentDynamicFilter);
    currentParams.set('page', '1');
    setIsCheck([]);
    setIsCheckGroup([]);
    setIsCheckAll(false);
    setCompletedGroupDataCount(0);
    setCreateEmployeeData([]);
    window.sessionStorage.removeItem('isCheckGroup');
    window.sessionStorage.removeItem('isCheck');
    window.sessionStorage.removeItem('isCheckAll');

    if (setDynamicPage) {
      setDynamicPage('1');
    }
    setIsCheckAll(false);

    const newUrl = `${location.pathname}?${currentParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    setDynamicFilter(currentDynamicFilter);

    employees.getEmployeesAction({
      id,
      search,
      isAllByPagesEmployees: true,
      page: '1',
      active: currentDynamicFilter,
      sortId: dynamicSort,
    });
  };

  return (
      <button
        type={'button'}
        className={[s.wrapper, dynamicFilter === FilterEmployees.DELETED.toString() ? s.active : null].join(' ')}
        onClick={toggleSwitch}
        style={{ ...stylesWrapper }}
      >
        <motion.div
          className={s.handle}
          style={{ ...stylesCircle }}
          layout
          transition={{
            type: 'spring',
            stiffness: 700,
            damping: 30,
          }}
        >
          {dynamicFilter === FilterEmployees.DELETED.toString() ? <EmployeeInactiveSVG /> : <EmployeeActiveSVG /> }
        </motion.div>
      </button>
  );
};

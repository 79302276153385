import React, { memo, useEffect, useRef } from 'react';
import { AuthMe } from 'models/auth/types';
import { useModal } from 'hooks/useModal.hook';
import { ImportSVG } from 'assets/icons/import';
import { Modals } from 'components/modal/index';
import { motion, MotionStyle } from 'framer-motion';
import { SendArrowSVG } from 'assets/icons/sendArrow';
import { PaymentsController } from 'controllers/payments';
import { RepeatSchedule } from 'assets/icons/repeatSchedule';
import { GetPaymentsHistoryInner } from 'models/payments/types';
import { MoreFunctionsBtn } from './components/MoreFunctionsBtn';
import { ModalImportEmployees } from '../modal/importEmployeesModal';
import s from './index.module.scss';

interface IPropsMoreFunctionsList {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  btnEl: React.MutableRefObject<any>;
  styles?: MotionStyle;
  historyItem: GetPaymentsHistoryInner;
  getAuthMe: AuthMe;
  payments: PaymentsController;
  isSchedulePayment: boolean | string;
  repeatSchedulePayment: (funcItem: any) => void;
  funcItem: any;
}

export const MoreFunctionsList = memo((props: IPropsMoreFunctionsList) => {
  const { isOpen, setIsOpen, btnEl, styles, historyItem, getAuthMe, payments, isSchedulePayment, repeatSchedulePayment, funcItem } = props;
  const listEl = useRef<any>();
  const moreFuncBtnEl = useRef<any>();
  const { showModal } = useModal();

  const handleImport = () => {
    showModal(ModalImportEmployees);
  };

  const moreElements = [
    { id: 1,
      item: <MoreFunctionsBtn icon={<ImportSVG/>} title={'Download report'} handleFunc={() => {
        payments.getPaymentsExportAction({ clientId: getAuthMe.id, multiId: historyItem.id });
        setIsOpen(false);
      }}/> },
    { id: 2,
      item: <MoreFunctionsBtn icon={<RepeatSchedule />} title={'Schedule payment'} handleFunc={() => {
        repeatSchedulePayment({ ...funcItem, isRepeatSchedulePayment: true });
        setIsOpen(false);
      }}/> },
    { id: 3,
      item: <MoreFunctionsBtn icon={<SendArrowSVG/>} title={'Send report'} handleFunc={() => {
        showModal(Modals.ModalSendReport, { sendItem: historyItem, moreFuncBtnEl, clientId: getAuthMe.id });
        setIsOpen(false);
      }}/> }
  ];

  useEffect(() => {
    const isOpenAddEmployeeMenu = (e: MouseEvent) =>
      !listEl.current?.contains(e.target) && !btnEl.current?.contains(e.target) && setIsOpen(false);

    document.addEventListener('click', isOpenAddEmployeeMenu);
    return () => document.removeEventListener('click', isOpenAddEmployeeMenu);
  }, [isOpen]);

  return (
        <motion.div ref={listEl} className={s.wrapper}
                    style={{
                      pointerEvents: isOpen ? 'auto' : 'none',
                      width: isOpen ? 'fit-content' : '0px',
                      height: isOpen ? 'fit-content' : '0px',
                      ...styles
                    }}
                    initial='hidden'
                    animate={isOpen ? 'open' : 'hidden'}

                    variants={{
                      hidden: { opacity: 0, width: 'fit-content', height: 'fit-content', scale: 0 },
                      open: { opacity: 1, width: 'fit-content', height: 'fit-content',  scale: 1 },
                    }}

                    transition={{
                      type: 'spring',
                      bounce: 0,
                      duration: 0.5
                    }}
        >
            <ul>
                {moreElements.map((item: { id: number, item: JSX.Element }, index: number) =>
                    <motion.li
                       ref={moreFuncBtnEl}
                       key={item.id}
                       initial='hidden'
                       animate={isOpen ? 'open' : 'hidden'}
                       variants={{
                         hidden: { opacity: 0, filter: 'blur(10px)', scale: 0.3 },
                         open: { opacity: 1, filter: 'blur(0px)', scale: 1 },
                       }}

                       transition={{
                         duration: 0.4,
                         delay: isOpen ? 0.15 * index : 0,
                       }}
                     >{item.item}</motion.li>
                )}
            </ul>
        </motion.div>);

});
import React from 'react';
import { LogoSVG } from 'assets/icons/logo';
import { LinkSVG } from 'assets/icons/link';
import { AnimatedDivPage } from 'components/animatedPage';
import { RouterLink } from 'router/components/routerLink';
import s from './index.module.scss';
import { ErrorPageProps } from './types';

export const ErrorPage = (props: ErrorPageProps) => {
  const { status, text } = props;
  const encodedData = window.location.pathname.split('/')[2] || null;

  let decodedData = null;
  if (encodedData) {
    try {
      decodedData = JSON.parse(decodeURIComponent(encodedData));
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }


  const animations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  return (
    <AnimatedDivPage className={s.error} transition={{ duration: 0.5 }} animations={animations}>
      <div className={s.error__logo}>
        <LogoSVG />
      </div>

      <h1 className={s.error__title}>
        {' '}
        {Number(status) ? `Error page ${status}` : `Page ${status}`}
      </h1>

      <div className={s.error__description}>
        <p>{text}</p>
      </div>

      {decodedData && <div className={s.error__message}>
        <h1>Error message:</h1>
        <p>{decodedData}</p>
      </div>}

      <div className={s.error__back}>
        <div className={s.error__back_item}>
          <RouterLink to={-1}>
            <div className={s.error__back_item_inner}>
              <p>Back to previous page</p>
              <div className={s.icon}>
                <LinkSVG />
              </div>
            </div>
          </RouterLink>
        </div>
        <div className={s.error__back_item}>
          <RouterLink to={'/signin'}>
            <div className={s.error__back_item_inner}>
              <p>Go to login page</p>
              <div className={s.icon}>
                <LinkSVG />
              </div>
            </div>
          </RouterLink>
        </div>
      </div>
    </AnimatedDivPage>
  );
};

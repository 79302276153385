import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';
import { CreateGroup, DeleteGroupQuery, GetCurrentGroupQuery, GetGroupsQuery, UpdateGroupQuery } from 'models/groups/types';

abstract class GroupsAPI {
  public static createGroup = (data: CreateGroup): Promise<AxiosResponse> =>
    RestAPI.post('/api/groups', data);

  public static getGroups = (data: any): Promise<AxiosResponse> =>
    RestAPI.get(`/api/groups/${data.clientId}`, { search: data?.search, page: data?.page, quantity: data?.quantity, sortId: data?.sortId });

  public static getGroupsDetail = (data: GetCurrentGroupQuery): Promise<AxiosResponse> =>
    RestAPI.get(`/api/groups/${data.clientId}/${data.groupId}`);

  public static updateGroup = (data: any): Promise<AxiosResponse> =>
    RestAPI.put(`/api/groups/${data.groupId}`, { title: data.title, description: data.description, parentId: data.parentId, employeesId: data.employeesId, accounts: data.accounts });

  public static deleteGroup = (data: any): Promise<AxiosResponse> =>
    RestAPI.delete(`/api/groups/${data.id}`, data);
}

export default GroupsAPI;

/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { ChangeEvent, ReactElement, useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useField, useFormikContext } from 'formik';
import { DropSelectSVG } from 'assets/icons/dropselect';
import { FromikSelectStaticProps } from './types';
import s from './index.module.scss';

const FormikSelectStatic = (props: FromikSelectStaticProps): ReactElement => {
  const {
    label = '',
    name = '',
    onChange,
    children,
    inputWrapperStyles,
    inputStyles,
    groupMenuEl,
    isOpenModal,
    setIsOpenModal,
    options,
    value = '',
    ...other
  } = props;
  const [field] = useField(name);
  const wrapperEl = useRef<any>();
  const wrapperInnerEl = useRef<any>();
  
  const form = useFormikContext();
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue(name, e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const inputValue = field.value || '';
  const evalProps = {
    ...field,
    ...other,
    value: inputValue,
    id: name,
    onChange: handleChange
  };

  useEffect(() => {
    const isOpenInteractiveList = (e: any) => {
      groupMenuEl?.current?.contains(e.target) ||
      wrapperInnerEl?.current?.contains(e.target) ||
        setIsOpenModal(false);
    };
      

    document.addEventListener('click', isOpenInteractiveList);
    return () => document.removeEventListener('click', isOpenInteractiveList);
  }, []);
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <motion.div ref={wrapperEl} animate={isOpenModal ? 'open' : 'closed'} className={s.wrapper}>
        <motion.div
          ref={wrapperInnerEl}
          whileTap={{ scale: 0.97 }}
          className={s.wrapper__input}
          style={inputWrapperStyles}
          onClick={() => setIsOpenModal(!isOpenModal)}
        >
          <motion.input style={{ ...inputStyles, pointerEvents: 'none' }} {...evalProps} />

          <motion.div className={s.wrapper__icon}>
            <motion.div
              variants={{
                open: { rotate: 180 },
                closed: { rotate: 0 }
              }}
              transition={{ duration: 0.2 }}
              style={{ originY: 0.55 }}
            >
              <DropSelectSVG />
            </motion.div>
          </motion.div>
        </motion.div>

        {children}
        {options}
      </motion.div>
    </>
  );
};

export default FormikSelectStatic;

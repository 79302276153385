import { makeAutoObservable } from 'mobx';
import { ProviderCreate, Providers, ProvidersMeta, ProvidersModel, Scenarios } from './types';

export class ProvidersEntity implements ProvidersModel {
  private providersList: Providers[] = [];

  private providersMeta: ProvidersMeta = {
    total: 0
  };

  private currentProvider: Providers = {
    id: '',
    title: '',
    measure: '',
    minAmount: 0,
    maxAmount: 0,
    validatePatterns: [],
    inputPatterns: [],
    availability: '',
    isPhoneNumber: false,
    scenarios: [],
  };

  setProvidersList(providersList: Providers[]) {
    this.providersList = providersList?.reduce((providerAcc: Providers[], provider: Providers): Providers[] => {
      
      providerAcc.push({
        ...provider,
        scenarios: provider?.scenarios?.reduce((scenarioAcc: Scenarios[], scenario: Scenarios) => {

          scenarioAcc.push({
            ...scenario,
            singleValue: scenario.label,
            value: scenario.label
          });

          return scenarioAcc;
        }, [])
      });

      return providerAcc;
    }, []);
  }

  get getProvidersList() {
    return this.providersList;
  }

  setProvidersMeta(providersMeta: ProvidersMeta) {
    this.providersMeta = providersMeta;
  }

  setProviderCreate(currentProvider: Providers) {
    this.currentProvider = currentProvider;
  }

  get getProvidersMeta() {
    return this.providersMeta;
  }

  get getProviderCreate() {
    return this.currentProvider;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

import React from 'react';
import { EmailSVG } from 'assets/icons/email';
import { PhoneSVG } from 'assets/icons/phone';

export const tokenFieldName = 'token';

export const menuTabs = (t: any) => [
  {
    index: 0,
    icon: <EmailSVG />,
    title: t('AUTH.SIGNIN.TABS.EMAIL'),
    tab: 'authEmail'
  },
  {
    index: 1,
    icon: <PhoneSVG />,
    title: t('AUTH.SIGNIN.TABS.PHONE'),
    tab: 'authPhone'
  }
];

export const menuTabsForgotPassword = (t: any) => [
  {
    index: 0,
    title: t('AUTH.FORGOTPASSWORD.FORM.EMAIL'),
    tab: 'resetEmail',
    description: t('AUTH.FORGOTPASSWORD.FORM.SUBMIEMAILDESCRIPTIONT')
  },
  {
    index: 1,
    title: t('AUTH.FORGOTPASSWORD.FORM.PHONE'),
    tab: 'resetPhone',
    description: t('AUTH.FORGOTPASSWORD.FORM.PHONEDESCRIPTION')
  }
];

export const menuTabsClientsList = (t: any) => [
  {
    index: 0,
    title: 'Email',
    tab: 'resetEmail'
  },
  {
    index: 1,
    title: 'Phone number',
    tab: 'resetPhone'
  }
];

export const menuTabsChangePassword = (t: any) => [
  // {
  //   index: 0,
  //   title: 'Check your Email',
  //   tab: 'changeEmail',
  //   description: (
  //     <p>
  //       We sent a code to reset your password <br /> to hello@jdin.com
  //     </p>
  //   )
  // },
  {
    index: 0,
    title: t('AUTH.CHANGEPASSWORD.TITLE'),
    tab: 'changePhone',
    description: (
      <div>
        <p>{t('AUTH.CHANGEPASSWORD.DESCRIPTION')}</p>
        <p>{t('AUTH.CHANGEPASSWORD.DESCRIPTIONLASTWORDS')}</p>
      </div>
    )
  }
];

export const menuTabsConfirmation = (t: any) => [
  {
    index: 0,
    title: 'Employee',
    tab: 'employee'
  },
  {
    index: 1,
    title: 'Operator',
    tab: 'operator'
  }
];
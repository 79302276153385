/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import { EyeSVG } from 'assets/icons/eye';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { GoogleSVG } from 'assets/icons/google';
import { AuthContainer } from 'containers/auth';
import { menuTabs } from 'common/constants/auth';
import { useLocation, useNavigate } from 'react-router';
import { loginContentTab } from 'common/constants/tabs';
import { LogoDarkSVG, LogoSVG } from 'assets/icons/logo';
import FormikContainer from 'components/form/containers';
import { ColorsAccent, ColorsText } from 'common/colors';
import { RouterLink } from 'router/components/routerLink';
import { UniversalTab } from 'components/tabs/universalTab';
import FormikTextField from 'components/form/fields/inputs';
import FormikCheckbox from 'components/form/fields/checkbox';
import { IActiveContentState } from 'components/tabs/universalTab/types';
import FormikSelectCountries from 'components/form/fields/selectCountries';
import s from './index.module.scss';

export interface LocationState {
  tab: number;
  safetyLogoutMessage?: string;
}

const SignInView = () => {
  const { auth } = useStores();
  const location = useLocation();
  const state = location.state as LocationState;
  const { t } = useTranslation();
  const tab = state?.tab ? menuTabs(t)[state.tab] : menuTabs(t)[0];
  const [isHiddenPassword, setIsHiddenPassword] = useState<boolean>(false);
  const [activeContent, setActiveContent] = useState<IActiveContentState>(tab);
  const [resultDialCode, setResultDialCode] = useState<string>('+256');
  const { getSignInAsyncState, getSignInMobileAsyncState, getSignInGoogleAsyncState } = auth;
  const isAuthMeLoadingSuccess = auth.getAuthMeAsyncState.getLoadingState;
  let isLoadingSignIn = getSignInAsyncState?.getLoadingState;
  let isLoadingSignInMobile = getSignInMobileAsyncState?.getLoadingState;
  let isLoadingSignInGoogle = getSignInGoogleAsyncState?.getLoadingState;
  const navigate = useNavigate();

  const validationEmailSchema = Yup.object().shape({
    email: Yup.string().required('This is a required field').email('The field must match email'),
    password: Yup.string()
      .required('This is a required field')
      .matches(/^[A-Za-z0-9!@#$%^&*()_+"№;:?()~ `-]+$/, 'Only latin characters')
      .min(6, 'Password must be longer than or equal to 6 characters')
  });

  const validationPhoneSchema = Yup.object().shape({
    phone: Yup.string(),
    phoneNumber: Yup.string()
      .transform((value: string) => value.replace(/[^\d]/g, ''))
      .min(10, 'Phone number should be valid')
      .required('This is a required field')
  });

  const handleClick = (elements: any, resetForm: any) => {
    setActiveContent(elements);
    resetForm();
  };

  const onSubmit = (values: any) => {
    const signInData = {
      password: values.password,
      email: values.email,
      remember: values.remember
    };
    const signInMobileData = {
      phone: (resultDialCode + values.phoneNumber?.replace(/[^\d]/g, '')).split(' ').join(''),
      remember: values.remember
    };

    if (values.email) {
      auth.signInAction(signInData);
    } else {
      auth.signInMobileAction(signInMobileData);
    }
  };

  const handleChange = (value: any, newMeta: any) => {
    setResultDialCode(value);
  };

  const signInWithGoogle = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/api/auth/google`,
      'Google',
      'location=1, stats=1, scrollbars=1, width: 400, height: 400'
    );
  };
 
  // useEffect(() => {
  //   setActiveContent(tab);

  // }, [tab]);

  useEffect((): any => {
    if (
      isLoadingSignIn === Loading.success ||
      isLoadingSignInMobile === Loading.success ||
      isLoadingSignInGoogle === Loading.success
    ) {
      navigate('/signin-success');
    }
  }, [isLoadingSignIn, isLoadingSignInMobile, isLoadingSignInGoogle]);

  useEffect(() => {
    const messageFromGoogle = (message: any) => {
      if (message?.data?.user) {
        auth.signInGoogleAction({ email: message?.data?.user?.email });
      }
    };
    window.addEventListener('message', messageFromGoogle);
    return () => window.removeEventListener('message', messageFromGoogle);
  }, []);

  const checkPhoneInputField = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.replace(/[^\d]/g, '')[0] !== '0') {
      e.target.value = `(0)${e.target.value}`;
    }

    if (e.target.value.replace(/[^\d]/g, '')[0] === '0' && e.target.value[3] === '_') {
      e.target.value = '';
    }
  };

  useEffect(() => {
    if (state?.safetyLogoutMessage) {
      toast.warning(state?.safetyLogoutMessage);
    }

  }, [state?.safetyLogoutMessage]);

  useEffect(() => {
    localStorage.removeItem('authMe');

    return () => localStorage.removeItem('authMe');
  }, [isAuthMeLoadingSuccess]);

  return (
    <AuthContainer>
      <div className={s.wrapper}>
        <motion.div
          variants={{
            initial: { opacity: 0, scale: 0.8 },
            animate: { opacity: 1, scale: 1 },
            exit: { opacity: 0, scale: 0.8 }
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5, type: 'spring', damping: 17 }}
          className={s.wrapper__inner}
        >
          <FormikContainer
            initialValues={{ email: '', password: '', remember: false }}
            onSubmit={onSubmit}
            validationSchema={
              activeContent.index === 0 ? validationEmailSchema : validationPhoneSchema
            }
            enableReinitialize
          >
            {({ resetForm, errors, isValid, dirty }) => (
              <div className={s.wrapper__inner__container}>
                <div className={s.wrapper__dark_logo}>
                  <LogoDarkSVG />
                </div>

                <div className={s.wrapper__inner__title}>
                  <h1>{t<string>('AUTH.SIGNIN.TITLE')}</h1>
                </div>

                <div className={s.title__description}>
                  <UniversalTab
                    index={activeContent.index}
                    content={loginContentTab(t)}
                    styles={{
                      color: ColorsText.textPrimarySecondary,
                      fontSize: '1.125rem',
                      lineHeight: '1.875rem,'
                    }}
                  />
                </div>
                <div className={s.wrapper__inner__buttons}>
                  {menuTabs(t).map((menuTab: any, index: number) =>
                    index === activeContent.index ? (
                      <div key={menuTab.index} className={s.wrapper__inner__button}>
                        <button className={s.wrapper__inner__button_active} type="submit">
                          {menuTab.icon}
                          <p>{menuTab.title}</p>
                        </button>
                      </div>
                    ) : (
                      <div key={menuTab.index} className={s.wrapper__inner__button}>
                        <button
                          className={s.wrapper__inner__button_default}
                          onClick={() =>
                            handleClick(
                              { index, titles: menuTab.title, icon: menuTab.icon },
                              resetForm
                            )
                          }
                          type="submit"
                        >
                          {menuTab.icon}
                          <p>{menuTab.title}</p>
                        </button>
                      </div>
                    )
                  )}
                </div>
                <div className={s.form__inputs}>
                  {activeContent.index === 0 ? (
                    <>
                      <motion.div
                        initial="closed"
                        animate="open"
                        exit="exit"
                        variants={{
                          closed: { opacity: 0, height: '0' },
                          open: { opacity: 1, height: 'fit-content' },
                          exit: { opacity: 0, height: '0' }
                        }}
                        transition={{
                          duration: 0.1,
                          type: 'spring',
                          damping: 17,
                          stiffness: 150
                        }}
                        className={`${s.input} ${errors.email ? s.border_alert : null}`}
                      >
                        <FormikTextField
                          placeholder={t<string>('AUTH.SIGNIN.FORM.EMAILPLACEHOLDER')}
                          label={<p className={s.input__label}>{t<string>('AUTH.SIGNIN.FORM.EMAIL')}</p>}
                          name="email"
                        />
                      </motion.div>

                      <div className={`${s.input} ${errors.password ? s.border_alert : null}`}>
                        <FormikTextField
                          type={isHiddenPassword ? 'text' : 'password'}
                          placeholder={t<string>('AUTH.SIGNIN.FORM.PASSWORDPLACEHOLDER')}
                          label={<p className={s.input__label}>{t<string>('AUTH.SIGNIN.FORM.PASSWORD')}</p>}
                          name="password"
                        >
                          <div
                            onClick={() => setIsHiddenPassword(!isHiddenPassword)}
                            className={s.input__icon}
                          >
                            <EyeSVG />
                          </div>
                        </FormikTextField>
                      </div>
                    </>
                  ) : (
                    <div
                      className={`${s.input__countries} ${
                        errors.phoneNumber ? s.border_alert : null
                      }`}
                    >
                      <motion.div
                        initial="closed"
                        animate={
                          activeContent.index === loginContentTab(t)[activeContent.index].id
                            ? 'open'
                            : 'closed'
                        }
                        exit="exit"
                        variants={{
                          closed: { opacity: 0, height: '11.625rem' },
                          open: { opacity: 1, height: '100%' },
                          exit: {
                            opacity: 0,
                            height: '100%'
                          }
                        }}
                        transition={{
                          duration: 0.1,
                          type: 'spring',
                          damping: 22,
                          stiffness: 150
                        }}
                      >
                        <FormikSelectCountries
                          name="phone"
                          label={<p className={s.input__label}>{t<string>('AUTH.SIGNIN.FORM.PHONE')}</p>}
                          onChange={handleChange}
                        >
                          <div className={` ${errors.phoneNumber ? s.border_alert : null}`}>
                            <Field name="phoneNumber">
                              {({ field }: any) => (
                                <MaskedInput
                                  {...field}
                                  onInput={checkPhoneInputField}
                                  mask={[
                                    '(',
                                    /\d/,
                                    ')',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/
                                  ]}
                                  placeholder={t<string>('AUTH.SIGNIN.FORM.PHONEPLACEHOLDER')}
                                  guide
                                  name="phoneNumber"
                                  id="phoneNumber"
                                />
                              )}
                            </Field>
                          </div>
                        </FormikSelectCountries>
                        {errors.phoneNumber || errors.tuched ? (
                          <div className={s.input__error}>
                            <p>{errors.phoneNumber}</p>
                          </div>
                        ) : null}
                      </motion.div>
                    </div>
                  )}

                  <div className={s.form__helper}>
                    <div className={s.checkbox}>
                      <FormikCheckbox
                        label={t<string>('AUTH.SIGNIN.FORM.REMEMBER')}
                        title={t<string>('AUTH.SIGNIN.FORM.REMEMBER')}
                        name="remember"
                        labelStyles={{
                          borderRadius: '4px',
                          backgroundColor: ColorsAccent.accentYellow
                        }}
                      />
                    </div>

                    <div className={s.link}>
                      <RouterLink to={'/password-forgot'}>
                        <p>{t<string>('AUTH.SIGNIN.FORM.FORGOTPASSWORD')}</p>
                      </RouterLink>
                    </div>
                  </div>

                  <div className={s.buttons}>
                    <motion.button
                      whileTap={
                        (isValid && dirty)
                          ? { scale: 0.99 }
                          : {}
                      }
                      whileHover={
                        (isValid && dirty)
                          ? { scale: 1.02 }
                          : {}
                      }
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      className={s.submit__button}
                      type="submit"
                      disabled={((!(isValid && dirty)))}
                    >
                      <p>{t<string>('AUTH.SIGNIN.FORM.SUBMIT')}</p>
                    </motion.button>

                    <motion.button
                      whileTap={{ scale: 0.99 }}
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      className={s.google__button}
                      type={'button'}
                      onClick={signInWithGoogle}
                    >
                      <GoogleSVG />
                      <p>{t<string>('AUTH.SIGNIN.FORM.SUBMITWITHGOOGLE')}</p>
                    </motion.button>
                  </div>
                </div>
                <div className={s.signup}>
                  <p>{t<string>('AUTH.SIGNIN.WITHOUTACCOUNT')}</p>
                  <RouterLink to={'/signup'}>
                    <p>{t<string>('AUTH.SIGNIN.LINKSIGNINTEXT')}</p>
                  </RouterLink>
                </div>
              </div>
            )}
          </FormikContainer>
        </motion.div>

        <motion.div
          variants={{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 }
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5 }}
          className={s.wrapper__logo}
        >
          <div className={s.wrapper__logo_inner}>
            <LogoSVG />
          </div>
        </motion.div>
        <div className={s.circle} />
      </div>
    </AuthContainer>
  );
};

export const SignIn = observer(SignInView);
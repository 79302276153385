import { ColorsText } from 'common/colors';
import React from 'react';

export const BackLongSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 454.4"
  >
    <g>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="arrow_back"
        transform="translate(-85.333 -104.533)"
        data-name="arrow back"
      >
        <g id="Group_10" transform="translate(85.333 104.533)">
          <path
            id="Path_31"
            d="m231.2 4.9 44.8 44.8-150.4 150.4h390.4v64h-390.4l150.4 150.4-44.8 44.8-227.2-227.2z"
            transform="translate(-4 -4.9)"
            fill={ColorsText.textTitle}
            className=""
          />
        </g>
      </g>
    </g>
  </svg>
);

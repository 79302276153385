/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import SERVICE_API from 'api/payway';
import { makeAutoObservable } from 'mobx';
import { IAsyncUtil, IConstructorAsyncUtils } from 'store/types';
import { ProviderCreate, ProvidersList, ProvidersModel } from 'models/providers/types';
import { remapFieldsError } from 'common/replaceErrorMessage';

interface ProvidersRepository {
  new (): ProvidersModel;
}

export class ProvidersController {
  private providersModel: ProvidersModel;

  private providersAsyncHelper: IAsyncUtil;

  private createProvidersAsyncHelper: IAsyncUtil;

  private getProvidersAsyncHelper: IAsyncUtil;

  get getProvidersModel(): ProvidersModel {
    return this.providersModel;
  }

  get getProvidersAsyncState(): IAsyncUtil {
    return this.getProvidersAsyncHelper;
  }

  get getCreateProvidersAsyncState(): IAsyncUtil {
    return this.createProvidersAsyncHelper;
  }

  createProvidersAction = async (provider: ProviderCreate): Promise<void> => {
    this.createProvidersAsyncHelper.startAsync();
    try {
      const { data } = await SERVICE_API.ProvidersAPI.createProvider(provider);
      this.getProvidersSuccess(data);
      await this.createProvidersAsyncHelper.successAsync('Provider created');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.createProvidersAsyncHelper.errorAsync(errorMessage, fieldErrors);
    }
  };

  getProvidersAction = async (): Promise<void> => {
    this.getProvidersAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.ProvidersAPI.getProviders();
      this.getProvidersSuccess(data);
      await this.getProvidersAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getProvidersAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  private ormErrors = (error: any) => {
    const errorMessage = error?.response?.data?.error || error?.message;
    const fieldErrors = error?.response?.data?.message;
    return { errorMessage, fieldErrors };
  };

  private getProvidersSuccess = (data: ProvidersList): void => {
    this.providersModel.setProvidersList(data.providers);
    this.providersModel.setProvidersMeta(data.meta);
  };

  constructor(ProvidersEntity: ProvidersRepository, AsyncHelper: IConstructorAsyncUtils) {
    this.providersModel = new ProvidersEntity();
    this.providersAsyncHelper = new AsyncHelper();
    this.getProvidersAsyncHelper = new AsyncHelper();
    this.createProvidersAsyncHelper = new AsyncHelper();

    makeAutoObservable(this);
  }
}

import React, { memo, RefObject } from 'react';
import s from '../../index.module.scss';

interface ISideMenuButtonProps {
  menuTab?: any;
  handleClick: (props?: any) => void;
  index?: number;
  activeContent?: any;
  buttonEl: React.RefObject<any>;
}

export const SideMenuButton = memo((props: ISideMenuButtonProps) => {
  const { menuTab, index, handleClick, activeContent, buttonEl } = props;

  return (
        <>
            {menuTab.path  ?
                <button
                    ref={buttonEl}
                    className={`${s.button} ${
                      activeContent?.path?.includes(menuTab?.path) ? s.button_active : s.button_default
                    }`}
                    type="button"
                    onClick={() =>
                      handleClick({
                        index,
                        titles: menuTab.title,
                        icon: menuTab.icon,
                        path: menuTab.path
                      })
                    }
                >
                    {menuTab.icon}
                    <p>{menuTab.title}</p>
                </button>
              :
                <button
                    ref={buttonEl}
                    className={`${s.button} ${
                      activeContent?.path?.includes(menuTab?.path) ? s.button_active : s.button_default
                    }`}
                    type="button"
                    onClick={handleClick}
                >
                    {menuTab.icon}
                    <p>{menuTab.title}</p>
                </button>
            }
        </>
  );
});

import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';

abstract class AccountsAPI {
  public static createAccount = (data: any): Promise<AxiosResponse> =>
    RestAPI.post('/api/accounts/create', data);

  public static createCheckAccount = (data: any): Promise<AxiosResponse> =>
    RestAPI.post('/api/accounts/check', data);

  public static getCheckEmployeeAccount = (data: any): Promise<AxiosResponse> => RestAPI.get(`/api/accounts/employee/${data.id}`);

  public static getCheckProviderAccount = (data: any): Promise<AxiosResponse> =>
    RestAPI.get(`/api/accounts/provider/${data.id}`);

  public static deleteAccount = (data: any): Promise<AxiosResponse> =>
    RestAPI.delete(`/api/accounts/${data.id}`, { id: data?.id });
}

export default AccountsAPI;

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { MarkSVG } from 'assets/icons/mark';
import { useTranslation } from 'react-i18next';
import { AuthContainer } from 'containers/auth';
import { ColorsBackground } from 'common/colors';
import { BackLongSVG } from 'assets/icons/backLong';
import FormikContainer from 'components/form/containers';
import { LogoDarkSVG, LogoSVG } from 'assets/icons/logo';
import FormikTextField from 'components/form/fields/inputs';
import s from './index.module.scss';

export interface LocationState {
  tab: number;
}

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("It's required field")
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(4, 'Must be exactly 4 digits')
    .max(4, 'Must be exactly 4 digits')
});

const SignUpSuccessView = (props: any) => {
  const [checkEmail, setCheckEmail] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState<any>(59);
  const [minutes, setMinutes] = useState<any>(4);
  const [isDisabled, setIsDisabled] = useState<any>(true);
  const { auth } = useStores();
  const { getAuthVerificationAsyncHelper } = auth;
  const isLoading = getAuthVerificationAsyncHelper.getLoadingState;
  const isAuthMeLoadingSuccess = auth.getAuthMeAsyncState.getLoadingState;
  const onSubmit = (values: any) => {

    const data = {
      code: values.code
    };

    auth.authVerificationAction(data);

    if (isLoading === Loading.success) {
      navigate('/employees');
    }
  };

  const handleCheckEmail = (e: any) => {
    setCheckEmail(false);
    if ((e.target.value.length === 4 && +e.target.value) || e.target.value === '0000') {
      setCheckEmail(true);
    }
  };

  const resetTimer = () => {
    setSeconds(59);
    setMinutes(4);
    setIsDisabled(true);
  };

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setSeconds(isDisabled ? seconds - 1 : 0);

      if (seconds === 1 && minutes === 0) {
        setIsDisabled(false);
      }

      if (seconds === 0 && isDisabled) {
        setSeconds(59);
      } else {
        clearInterval(timeInterval);
      }

      if (seconds === 0 && minutes !== 0) {
        setMinutes(minutes - 1);
      }
    }, 1000);

    return () => clearInterval(timeInterval);
  }, [seconds, minutes, isDisabled]);

  useEffect(() => {
    if (isLoading === Loading.success) {
      navigate('/employees');
    }
  }, [isLoading]);

  useEffect(() => {
    localStorage.removeItem('authMe');

    return () => localStorage.removeItem('authMe');
  }, [isAuthMeLoadingSuccess]);

  return (
    <AuthContainer>
      <div className={s.wrapper}>
        <motion.div
          variants={{
            initial: { opacity: 0, scale: 0.8 },
            animate: { opacity: 1, scale: 1 },
            exit: { opacity: 0, scale: 0.8 }
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5, type: 'spring', damping: 17 }}
          className={s.wrapper__inner}
        >
          <FormikContainer
            initialValues={{ code: '' }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, isValid, dirty }) => (
              <div className={s.wrapper__inner__container}>
                <div className={s.wrapper__dark_logo}>
                  <LogoDarkSVG />
                </div>

                <div className={s.wrapper__inner__title}>
                  <h1>{t<string>('AUTH.SINGUPSUCCESS.TITLE')}</h1>
                </div>
                <div className={s.wrapper__inner__description}>
                  <p>
                  {t<string>('AUTH.SINGUPSUCCESS.DESCRIPTION')} <br /> {t<string>('AUTH.SINGUPSUCCESS.DESCRIPTIONLASTWORDS')}
                  </p>
                </div>
                <div className={s.form__inputs}>
                  <div
                    className={`${s.input} ${s.input__code} ${errors.code ? s.border_alert : null}`}
                  >
                    <FormikTextField
                      maxLength={4}
                      placeholder={t<string>('AUTH.SINGUPSUCCESS.FORM.CODEPLACEHOLDER')}
                      label={<p className={s.input__label}>{t<string>('AUTH.SINGUPSUCCESS.FORM.CODE')}</p>}
                      name="code"
                      onChange={(e: any) => handleCheckEmail(e)}
                    >
                      <div
                        className={`${s.input__icon} ${s.input__icon__mark} ${
                          checkEmail ? s.email__fill : ''
                        }`}
                      >
                        <MarkSVG />
                      </div>
                    </FormikTextField>
                  </div>

                  <div className={s.buttons}>
                    <motion.button
                       whileTap={
                        (isValid && dirty)
                          ? { scale: 0.99 }
                          : {}
                      }
                      whileHover={
                        (isValid && dirty)
                          ? { scale: 1.02 }
                          : {}
                      }
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      className={s.submit__button}
                      disabled={((!(isValid && dirty)))}
                      type="submit"
                    >
                      <p>{t<string>('AUTH.SINGUPSUCCESS.FORM.SUBMIT')}</p>
                    </motion.button>

                    <motion.button
                      whileTap={{ scale: 0.99 }}
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      onClick={() => navigate(-1)}
                      className={s.back__button}
                      type={'button'}
                    >
                      <BackLongSVG />
                      <p>{t<string>('AUTH.SINGUPSUCCESS.FORM.BACKBUTTON')}</p>
                    </motion.button>

                    <motion.button
                      whileTap={!isDisabled ? { scale: 0.99 } : {}}
                      whileHover={!isDisabled ? { scale: 1.02 } : {}}
                      transition={
                        !isDisabled ? { type: 'spring', stiffness: 400, damping: 17 } : {}
                      }
                      style={{
                        backgroundColor: !isDisabled
                          ? ''
                          : ColorsBackground.backgroundGrayAlmostWhite
                      }}
                      className={s.back__button}
                      type={'button'}
                      onClick={() => resetTimer()}
                      disabled={isDisabled}
                    >
                      <p>{t<string>('AUTH.SINGUPSUCCESS.FORM.SUBMITCODEAGAIN')}</p>
                    </motion.button>
                    <div className={s.resend__code}>
                      <div className={s.resend__timer}>
                        <p>
                          {t<string>('AUTH.SINGUPSUCCESS.FORM.REPEATTEXT')}
                          <span>
                            0{minutes}:{seconds.toString().length !== 1 ? seconds : `0${seconds}`}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </FormikContainer>
        </motion.div>

        <motion.div
          variants={{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 }
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5 }}
          className={s.wrapper__logo}
        >
          <div className={s.wrapper__logo_inner}>
            <LogoSVG />
          </div>
        </motion.div>
        <div className={s.circle} />
      </div>
    </AuthContainer>
  );
};

export const SignUpSuccess = observer(SignUpSuccessView);
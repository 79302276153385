import React from 'react';
import { useModal } from 'hooks/useModal.hook';

export const ModalRoot = () => {
  const { Modal, modalProps, showModal, hideModal } = useModal();

  return Modal ? (
    <Modal
      {...modalProps}
      showModal={showModal}
      hideModal={hideModal}
    />
  ) : null;
};
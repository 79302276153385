import React from 'react';

export const GroupsMarkSVG = () => (
  <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.667 17H20.667C21.2193 17 21.667 16.5523 21.667 16V15C21.667 13.3431 20.3238 12 18.667 12C17.7114 12 16.8601 12.4468 16.3107 13.1429M16.667 17H6.66699M16.667 17V15C16.667 14.3438 16.5406 13.717 16.3107 13.1429M6.66699 17H2.66699C2.11471 17 1.66699 16.5523 1.66699 16V15C1.66699 13.3431 3.01014 12 4.66699 12C5.6226 12 6.47385 12.4468 7.02324 13.1429M6.66699 17V15C6.66699 14.3438 6.79342 13.717 7.02324 13.1429M7.02324 13.1429C7.76049 11.301 9.56181 10 11.667 10C13.7722 10 15.5735 11.301 16.3107 13.1429M14.667 4C14.667 5.65685 13.3238 7 11.667 7C10.0101 7 8.66699 5.65685 8.66699 4C8.66699 2.34315 10.0101 1 11.667 1C13.3238 1 14.667 2.34315 14.667 4ZM20.667 7C20.667 8.10457 19.7716 9 18.667 9C17.5624 9 16.667 8.10457 16.667 7C16.667 5.89543 17.5624 5 18.667 5C19.7716 5 20.667 5.89543 20.667 7ZM6.66699 7C6.66699 8.10457 5.77156 9 4.66699 9C3.56242 9 2.66699 8.10457 2.66699 7C2.66699 5.89543 3.56242 5 4.66699 5C5.77156 5 6.66699 5.89543 6.66699 7Z"
      stroke="#1A1A1A"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { DropSVG } from 'assets/icons/drop';
import { LoupeSVG } from 'assets/icons/loupe';
import { motion, Variants } from 'framer-motion';
import { Pagination } from 'components/pagination';
import { useDebounce } from 'hooks/useDebounce.hook';
import { EditLightSVG } from 'assets/icons/editLight';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikContainer from 'components/form/containers';
import { AnimatedDivPage } from 'components/animatedPage';
import { RouterLink } from 'router/components/routerLink';
import FormikSelect from 'components/form/fields/select/index';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import FormikTextField from 'components/form/fields/inputs/index';
import { useNavigate } from 'react-router';
import { moreFunctionsItems, moreFunctionsListTitles, moreFunctionsSortMobile } from 'common/constants/moreFunctions';
import { IMoreFunctionsItems } from 'common/constants/moreFunctions/types';
import s from './index.module.scss';

export const MoreFunctionsView = () => {
  const navigate = useNavigate();
  const [elementsCount, setElementsCount] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const { isTablet, isTabletAdditional } = useWindowDimensions();
  const [isCurrentGroupId, setIsCurrentGroupId] = useState<any[]>([]);
  const [isOpenGroupMenu, setIsOpenGroupMenu] = useState(false);
  const [isDynamicItemsSorted, setIsDynamicItemsSorted] = useState<any>(false);
  const [moreFunctionsSearch, setMoreFunctionsSearch] = useState('');
  const [moreFunctionsMobileSearch, setMoreFunctionsMobileSearch] = useState('');
  const moreFunctionsSearchDebounce = useDebounce(moreFunctionsSearch.toLowerCase() || moreFunctionsMobileSearch.toLowerCase(), 300);

  const [dynamicMoreFunctionsItems, setDynamicMoreFunctionsItems] = useState<IMoreFunctionsItems[]>(moreFunctionsItems);
  const [currentPaginateItems, setCurrentPaginateItems] = useState<IMoreFunctionsItems[]>([]);

  const animations: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  const handleOpenEmployeeMenu = (e: any, group: any) => {
    setIsOpenGroupMenu(!isOpenGroupMenu);
    setIsCurrentGroupId([...isCurrentGroupId, group.id]);

    if (isCurrentGroupId.includes(group.id)) {
      setIsCurrentGroupId(isCurrentGroupId.filter((employeeId: any) => employeeId !== group.id));
    }
  };

  const goToDetailMoreFunctions = (moreFunc: IMoreFunctionsItems) => {
    navigate(`functions-detail/${moreFunc.path}`, { state: { moreFunc } });
  };

  const handleSortItems = (e: any) => {
    setIsDynamicItemsSorted(!isDynamicItemsSorted);

    setDynamicMoreFunctionsItems((items) => [...items].sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (+e?.value) {
        return +e?.value === 3 ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
      }
      return isDynamicItemsSorted ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
    }));
  };

  const onHandleSearch = (setter: (val: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  useEffect(() => {
    window.onscroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 5 >=
          document.scrollingElement!.scrollHeight &&
        !isTabletAdditional
      ) {
        setElementsCount(elementsCount + 5);
      }
    };
  }, [elementsCount]);

  useEffect(() => {
    setDynamicMoreFunctionsItems(moreFunctionsItems.filter(moreFunc => moreFunc.description.toLowerCase().includes(moreFunctionsSearchDebounce) || moreFunc.title.toLowerCase().includes(moreFunctionsSearchDebounce)));
  }, [moreFunctionsSearchDebounce]);
  
  return (
    <section className={s.wrapper}>
      <AnimatedDivPage transition={{ duration: 0.5 }} animations={animations}>
        <div className={s.wrapper__container}>
          <FormikContainer onSubmit={(): void => {}} initialValues={() => {}}>
            {() => (
              <>
                <div className={s.wrapper__title}>
                  <div className={s.wrapper__title_main}>
                    <h1 className={s.title}>More functions</h1>
                  </div>

                  <div className={s.search__mobile}>
                  <FormikTextField
                    placeholder="Search"
                    name="search"
                    value={moreFunctionsSearch}
                    id={'groupsSearch'}
                    onChange={onHandleSearch(setMoreFunctionsSearch)}
                  >
                    <div className={s.search__icon}>
                      <LoupeSVG />
                    </div>
                  </FormikTextField>
                  </div>

                  <div className={s.services__sort_mobile}>
                    <div className={s.operators__sort}>
                      <FormikSelect
                        valueContainer={{ paddingLeft: '.5rem' }}
                        wrapperSelectStyles={{ width: '11rem', maxWidth: '100%', minWidth: '100%' }}
                        selectWrapperStyles={{ width: '100%' }}
                        options={moreFunctionsSortMobile}
                        name="sort"
                        placeholder="Sort by"
                        indicatorIcon={<DropSelectSVG />}
                        onChange={handleSortItems}
                      />
                    </div>
                  </div>
                </div>

                <div className={s.groups__list}>
                  <div className={s.search}>
                  <FormikTextField
                    placeholder="Search"
                    name="search"
                    value={moreFunctionsMobileSearch}
                    id={'groupsMobileSearch'}
                    onChange={onHandleSearch(setMoreFunctionsMobileSearch)}
                  >
                    <div className={s.search__icon}>
                      <LoupeSVG />
                    </div>
                  </FormikTextField>
                  </div>
                  <div className={s.content}>
                    <div className={s.content__header}>
                      {moreFunctionsListTitles.map((titles: any) => (
                        <div key={titles.id} className={s.content__header_item}>
                          <button
                           style={{ marginLeft: titles.data === 'description' ? '-3rem' : 0 }}
                            disabled={!titles?.icon}
                            onClick={() => handleSortItems(titles)}
                            type="button"
                            className={s.item}
                          >
                            <p>{titles.name}</p>
                            {titles.icon}
                          </button>
                        </div>
                      ))}
                    </div>

                    <div className={s.content__main}>
                      {isTabletAdditional ? (
                        currentPaginateItems.length >= 1 ? (
                          currentPaginateItems?.map((moreFunc, i: number) => (
                            <motion.div
                              initial="hidden"
                              animate="visible"
                              variants={{
                                hidden: { opacity: 0, x: -50 },
                                visible: {
                                  pathLength: 1,
                                  opacity: 1,
                                  transition: {
                                    delay: i * 0.05,
                                    duration: 0.4,
                                    type: 'spring',
                                    damping: 10
                                  },
                                  x: 0
                                }
                              }}
                              transition={{ duration: 0.7 }}
                              key={moreFunc.id}
                              className={s.content__items}
                            >
                              {moreFunctionsListTitles.map((title: any) => (
                                <div key={title.id} className={s.content__item}>

                                    {title.data === 'name' ?
                                    <div className={s.title__wrapper}>
                                      <RouterLink
                                        to={`functions-detail/${moreFunc.path}`}
                                        state={{ moreFunc: toJS(moreFunc) }}
                                      >
                                        <p className={s.content__item_title}>{moreFunc.title || '----'}</p>
                                      </RouterLink>
                                    </div>
                                      : null}
                                </div>
                              ))}
                              <div className={s.content__items_icons}>
                                <button
                                  onClick={() => goToDetailMoreFunctions(moreFunc)}
                                  type="button"
                                  className={s.content__items_icon}
                                >
                                  <EditLightSVG />
                                </button>
                              </div>
                            </motion.div>
                          ))
                        ) :
                            <h2 className={s.item__title_empty}>There are no groups</h2>
                        
                      ) :
                        currentPaginateItems.length >= 1 ? (
                          currentPaginateItems.map((moreFunc, i: number) => (
                            <motion.div
                              initial="hidden"
                              animate="visible"
                              variants={{
                                hidden: { opacity: 0, x: -50 },
                                visible: {
                                  pathLength: 1,
                                  opacity: 1,
                                  borderRadius: isCurrentGroupId.includes(moreFunc.id)
                                    ? '1.25rem'
                                    : '.5rem',
                                  transition: {
                                    delay: i * 0.05,
                                    duration: 0.4,
                                    type: 'spring',
                                    damping: 10
                                  },
                                  x: 0
                                }
                              }}
                              transition={{ duration: 0.7 }}
                              key={moreFunc.id}
                              className={s.content__items_mobile}
                              onClick={(e: any) => handleOpenEmployeeMenu(e, moreFunc)}
                            >
                              <div className={s.content__header}>
                                <div className={s.content__item}>
                                  {isCurrentGroupId.includes(moreFunc.id) ? (
                                    <motion.h5
                                      initial="hidden"
                                      animate={
                                        isCurrentGroupId.includes(moreFunc.id) ? 'open' : 'hidden'
                                      }
                                      variants={{
                                        hidden: { height: '0' },
                                        open: { height: 'fit-content' }
                                      }}
                                      transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                                      className={s.name}
                                    >
                                      Title
                                    </motion.h5>
                                  ) : null}

                                  <div className={s.content__item_info}>
                                    <div className={s.content__item_info__inner}>
                                      <RouterLink
                                        to={`functions-detail/${moreFunc.path}`}
                                        state={{ moreFunc: toJS(moreFunc) }}
                                      >
                                        <p>{moreFunc.title || '----'}</p>
                                      </RouterLink>
                                    </div>
                                  </div>
                                </div>

                                <div className={s.icons}>
                                  {isCurrentGroupId.includes(moreFunc.id) ? (
                                    <>
                                      <RouterLink
                                        to={`functions-detail/${moreFunc.path}`}
                                        state={toJS(moreFunc)}
                                      >
                                        <div className={s.content__items_icon}>
                                          <EditLightSVG />
                                        </div>
                                      </RouterLink>
                                    </>
                                  ) : null}

                                  <div
                                    style={
                                      isCurrentGroupId.includes(moreFunc.id)
                                        ? { transform: 'rotate(180deg)' }
                                        : {}
                                    }
                                    className={s.icon_drop}
                                  >
                                    <DropSVG />
                                  </div>
                                </div>
                              </div>
                            </motion.div>
                          ))
                        ) : (
                          <h2 className={s.item__title_empty}>There are no groups</h2>
                        )
                      }
                    </div>
                  </div>
                </div>

                <div className={s.paginate__wrapper}>
                  <Pagination
                    currentPage={currentPage}
                    setDynamicPage={setCurrentPage}
                    elementsPerPage={elementsCount}
                    totalItems={dynamicMoreFunctionsItems.length}
                    items={dynamicMoreFunctionsItems}
                    setCurrentDynamicItems={setCurrentPaginateItems}
                    paginateMoreFunctions
                    isMoreFunctionsElementsSorted={isDynamicItemsSorted}
                    searchMoreFunctions={moreFunctionsSearchDebounce}
                  />
                </div>
              </>
            )}
          </FormikContainer>
        </div>
      </AnimatedDivPage>
    </section>
  );
};

export const MoreFunctions = observer(MoreFunctionsView);

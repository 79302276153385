/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { toJS } from 'mobx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { GroupsModel } from 'models/groups/types';
import { GroupsController } from 'controllers/groups';
import { EmployeesModel } from 'models/employees/types';
import { EmployeesController } from 'controllers/employees';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  modalRefEl: any;
  setDynamicOperatorsItems: (dynamicItems: any) => void,
  dynamicOperatorsItems: any,
  operator: any;
  employees: EmployeesController;
  groups: GroupsController;
  getEmployeesModel: EmployeesModel;
  getGroupsModel: GroupsModel;
}

const ModalRemoveOperatorFromGroupView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    groups,
    modalRefEl,
    employees,
    setDynamicOperatorsItems,
    dynamicOperatorsItems,
    getEmployeesModel,
    getGroupsModel,
    operator,
  } = props;

  const changeGroupCheck = () => {
    // accounts.deleteAccountsAction({ id: operator.id });

    const data: any = {
      groupId: toJS(getGroupsModel.getGroupDetail).id,
      title: toJS(getGroupsModel.getGroupDetail).title,
      description: toJS(getGroupsModel.getGroupDetail).description,
      parentId: toJS(getGroupsModel.getGroupDetail).parent_id,
      employeesId: toJS(getGroupsModel.getGroupDetail).employees.map(
        (employee: any) => employee.id
      ),
      accounts: toJS(getGroupsModel.getGroupDetail)
        .groupsAccounts.filter((account: any) => account.id !== operator?.id)
        .reduce((acc: any, item: any) => {
          acc.push({
            defaultPrice: item.defaultPrice,
            providerId: item.provider.id,
            priceListId: item.priceList?.id || null,
            defaultScenario: item?.defaultScenario?.id || null,
          });
          return acc;
        }, [])
    };

    groups.updateGroupsAction(data);

    toJS(getEmployeesModel.getEmployeesList)?.filter((employeeFilter: any) => toJS(getGroupsModel.getGroupDetail).employees.map(
      (employee: any) => employee.id
    ).includes(employeeFilter?.id))?.reduce((acc: any, employee: any, index: number) => {
      Promise.all([employees?.updateEmployeeAction({
        employeeId: employee?.id,
        name: employee?.name,
        phones: employee?.phones,
        surname: employee?.surname,
        groupId: getGroupsModel?.getGroupDetail?.id,
        accounts: employee?.accounts?.filter((account: any) => account?.provider?.id !== operator?.provider?.id)?.reduce((replaceAcc: any, replaceAccount: any) => {
          replaceAcc.push({
            defaultPrice: !replaceAccount?.defaultPriceList?.id ? replaceAccount?.defaultPrice : null,
            defaultPriceListId: replaceAccount?.defaultPriceList ? replaceAccount?.defaultPriceList?.id : null,
            defaultScenario: operator?.defaultScenario,
            defaultValueEmployee: operator?.defaultValueEmployee,
            providerId: replaceAccount?.provider?.id,
            employeeId: employee?.id,
            uniqueId: replaceAccount?.uniqueId,
            note: replaceAccount?.note || null
          });
          return replaceAcc;
        }, [])
      }, toJS(getEmployeesModel.getEmployeesList)?.filter((employeeFilter: any) => toJS(getGroupsModel.getGroupDetail).employees.map(
        (employeeItem: any) => employeeItem.id
      ).includes(employeeFilter?.id)).length
      )]);
      return acc;
    }, []);

    setDynamicOperatorsItems({
      ...dynamicOperatorsItems,
      groupsAccounts: dynamicOperatorsItems?.groupsAccounts?.filter(
        (item: any) => item?.id !== operator?.id
      )
    });

    hideModal();

  };

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Delete operator</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              Do you really want to delete this provider: {operator?.provider?.title} ?
            </p>
          </div>

          <div className={s.employee}>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={changeGroupCheck}
              >
                <p>Yes</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>No</p>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalRemoveOperatorFromGroup = observer(ModalRemoveOperatorFromGroupView);

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useStores } from 'store';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { GroupSVG } from 'assets/icons/group';
import { CrossSVG } from 'assets/icons/cross';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import FormikTextField from 'components/form/fields/inputs/index';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  setIsCheck: (checkItems: any) => void;
  setIsCheckGroup: (CheckGroupItems: any) => void;
  isCheck: any[];
}

const ModalEmployeeRemoveConfirmationView = (props: ModalCreateGroupViewProps) => {
  const { hideModal, isCheck, setIsCheck, setIsCheckGroup } = props;
  const { state }: any = useLocation();
  const { auth, groups, employees } = useStores();
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;

  const onSubmit = (values: any) => {

    isCheck.map((employeeId: string) =>
      Promise.all([employees.deleteEmployeesAction({ id: employeeId }, isCheck.length)]).then(() => {
        setIsCheck([]);
        setIsCheckGroup?.([]);
      })
    );

    hideModal();
  };

  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Warning</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
            Are you sure to activate/deactivate ({isCheck?.length}) { isCheck?.length > 1 ? 'employees' : 'employee' }?
            </p>
          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalEmployeeRemoveConfirmation = observer(ModalEmployeeRemoveConfirmationView);

/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useRef } from 'react';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';
import { ColorsAccent } from 'common/colors';
import { CrossSVG } from 'assets/icons/cross';
import { useTranslation } from 'react-i18next';
import { Providers } from 'models/providers/types';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikSelect from 'components/form/fields/select';
import { ProviderMeasure } from 'common/constants/payment';
import { ProvidersController } from 'controllers/providers';
import { SelectPagination } from 'components/selectPagination';
import FormikTextField from 'components/form/fields/inputs/index';
import { CheckAccount, GetAccountData } from 'models/accounts/types';
import { ModalBackdrop } from 'components/modal/modalBackdrop/index';
import { EmployeeList, EmployeesAccounts } from 'models/employees/types';
import { KindOfScenarios } from 'common/constants/additionalOperatorModal';
import FormikTextFieldNumber from 'components/form/fields/inputs/inputNumber/index';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  pageCount?: any;
  endOffset?: any;
  itemOffset?: any;
  dynamicPage?: any;
  chooseFromModal?: any;
  currentOperatorData?: any;
  inputValidateFields?: any;
  isCheckGroupRechange?: any;
  selectValidateFields?: any;
  whichSubmitButtonClicked?: any;
  providers: ProvidersController;
  setEndOffset: (end: any) => void;
  isGetDetailPriceListLoading?: any;
  setDynamicPage: (page: any) => void;
  setItemOffset: (first: any) => void;
  hideModal: (isOpen: boolean) => void;
  handlePageClick: (page: any) => void;
  setFieldValue: (id: string, value: any) => void;
  setChooseFromModal: (fromModalItem: any) => void;
  setInputValidateFields: (inputItem: any) => void;
  setSelectValidateFields: (selectItem: any) => void;
  setIsCheckGroupRechange: (groupItems: any) => void;
  setCurrentOperatorData: (currentOperator: any) => void;
  setInitialReplacedDatasAtEditFields: (initialDatas: any) => void;
}

const ModalAdditionOperatorNewPaymentView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    pageCount,
    endOffset,
    providers,
    itemOffset,
    dynamicPage,
    setEndOffset,
    setFieldValue,
    setItemOffset,
    setDynamicPage,
    chooseFromModal,
    handlePageClick,
    setChooseFromModal,
    inputValidateFields,
    currentOperatorData,
    selectValidateFields,
    isCheckGroupRechange,
    setInputValidateFields,
    setCurrentOperatorData,
    setSelectValidateFields,
    setIsCheckGroupRechange,
    whichSubmitButtonClicked,
    isGetDetailPriceListLoading,
    setInitialReplacedDatasAtEditFields
  } = props;

  const { auth, accounts, priceList, employees } = useStores();
  const { state }: any = useLocation();
  const { getPriceListModel } = priceList;
  const { getProvidersModel } = providers;
  const { getAccountsModel } = accounts;
  const { getAuthModel } = auth;
  const { t } = useTranslation();
  const { getEmployeesModel, getUpdateEmployeeAsyncState } = employees;
  const [choosedAmount, setChoosedAmount] = useState('');
  const [choosedAmountAdditional, setChoosedAmountAdditional] = useState(null);
  const [choosenAmountPackagesAndMoney, setChoosenAmountPackagesAndMoney] = useState<any>('');
  const [selectItem, setSelectItem] = useState<any>({});
  const [enterUniqueId, setEnterUniqueId] = useState('');
  const [enterAmount, setEnterAmount] = useState<any>('');
  const [enterAmountEdit, setEnterAmountEdit] = useState<any>('');
  const [isDefaultAmountFromModal, setIsDefaultAmountFromModal] = useState(true);
  const [isDefaultScenarioFromModal, setIsDefaultScenarioFromModal] = useState(true);
  const [isDefaultUniqueIdCurrentOperator, setIsDefaultUniqueIdCurrentOperator] = useState(true);
  const currentOperatorActiveStatus = isCheckGroupRechange.filter((item: any) => item.id === currentOperatorData?.employee?.id)?.[0]?.accounts.filter((account: any) => account.provider.id === currentOperatorData.provider.id)[0]?.isOperatorActive === undefined ? true : !isCheckGroupRechange.filter((item: any) => item.id === currentOperatorData?.employee?.id)?.[0]?.accounts.filter((account: any) => account.provider.id === currentOperatorData.provider.id)[0]?.isOperatorActive;
  const [scenarios, setScenarios] = useState<any[]>( currentOperatorData?.provider?.scenarios || chooseFromModal?.currentOperator?.scenarios || []);
  // Заносить сюда данные когда появится defaultScenario

  const [defaultScenario, setCurrentScenario] = useState<any>(
    currentOperatorData?.defaultScenario?.id ? currentOperatorData?.defaultScenario : chooseFromModal?.defaultScenario?.id ? chooseFromModal?.defaultScenario : null
    // currentOperatorData?.defaultScenario || chooseFromModal?.defaultScenario ||
    // Тест дефолт значений
    //  { type: 'PriceList', label: 'Bouquet' } ||
    // {}
  );
  // Заносить сюда данные когда появится defaultScenario
  const [productOperatorCheckList, setProductOperatorCheckList] = useState<any[]>(
    // Тест дефолт значений
    defaultScenario?.type === KindOfScenarios.PRICELIST ? [currentOperatorData?.provider?.id?.split('.')?.join('') || chooseFromModal?.currentOperator?.id?.split('.')?.join('')] : []
  );
  
  const containerEl = useRef<any>(null);
  const backdropEl = useRef<any>(null);
  const [descriptionPackagesAndMoney, setDescriptionPackagesAndMoney] = useState<any>('');
  const [choosedPriceList, setChoosedPriceList] = useState<any>('');
  const [choosedPriceListEdit, setChoosedPriceListEdit] = useState<any>('');
  const removeDuplicates = (data: any, key: any) => [
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];

  const checkUniqueIdField = (e: any) => {
    setEnterUniqueId(e?.target?.value);
    setIsDefaultUniqueIdCurrentOperator(false);
  };

  const handleChooseAmount = (e: any, eMeta: any) => {
    setChoosedAmount(e.amount);
    setSelectItem(e);
    setChoosedPriceList(e);
    setChoosedPriceListEdit(e);
    setIsDefaultAmountFromModal(false);
  };

  const handlePriceListSelect = (mainProvider: any) => {
    priceList?.getPriceListDetailAction(mainProvider);
    setChoosedPriceList(mainProvider);
    setChoosedPriceListEdit(mainProvider);
    setDynamicPage(1);
    setItemOffset(0);
    setEndOffset(8);
  };

  const onSubmit = async (e: any) => {
    
    const resetDefaultValue = isDefaultAmountFromModal ? chooseFromModal?.amount : +enterAmount || +choosedAmount || null;
    const resetDefaultValueList = isDefaultAmountFromModal ? chooseFromModal?.currentOperator?.priceListId : choosedPriceList?.id || choosedPriceList?.value || null;
    const resetDefaultValueListChange = isDefaultAmountFromModal && chooseFromModal?.currentOperator?.priceListId 
      ? 
      { id: chooseFromModal?.currentOperator?.priceListId, description: chooseFromModal?.currentOperator?.description, label: chooseFromModal?.currentOperator?.description, price: chooseFromModal?.amount, provider: chooseFromModal?.currentOperator } 
      : 
      { id: choosedPriceList?.id || choosedPriceList?.value, description: choosedPriceList?.currentOperator?.description, label: choosedPriceList?.currentOperator?.description, price: choosedPriceList?.amount, provider: choosedPriceList?.currentOperator } || null;
    const initialDefaultOperator = toJS(getEmployeesModel.getEmployeesList)?.filter((employee: any) => employee?.id === chooseFromModal?.employeeId)?.map((employeeItem: any) => employeeItem?.accounts).flat(Infinity).filter((accountItem: any) => accountItem?.provider?.id === chooseFromModal?.currentOperator?.id)[0];
    
    if (
      (isDefaultAmountFromModal ? chooseFromModal?.amount : +enterAmount || +choosedAmount || null) ?
        (enterAmount || choosedAmount || chooseFromModal?.amount) >= chooseFromModal.currentOperator.minAmount &&
      (enterAmount || choosedAmount || chooseFromModal?.amount) <= chooseFromModal.currentOperator.maxAmount &&
      whichSubmitButtonClicked === '2' : true
    ) {
      const defaultGroupValues = toJS(state?.group)?.groupsAccounts.reduce(
        (acc: any, account: any) => {
          if (chooseFromModal?.providerId === account?.provider?.id) {
            acc.push({
              defaultPrice: account?.defaultPrice,
              priceList: account?.priceList
            });
          }
          return acc;
        },
        []
      )[0];

      await employees?.getEmployeesDetailAction({
        clientId: getAuthModel.getAuthMe.id,
        employeeId: chooseFromModal?.employeeId
      });

      if (
        toJS(getEmployeesModel.getEmployeesDetail)?.accounts.filter(
          (item: any) => item?.provider.id === chooseFromModal?.providerId
        )[0]?.id
      ) {
        await accounts.deleteAccountsAction({
          id: toJS(getEmployeesModel.getEmployeesDetail)?.accounts.filter(
            (item: any) => item?.provider.id === chooseFromModal?.providerId
          )[0]?.id,
          messageDisable: true
        });
      }

      // TODO: Изменение значений независимых от ID, посмотреть на данные + priceList когда выбираем None, должны отправлять defaultPriceList = null или defaultPriceListId = null

      await accounts.createAccountsAction(
      //   {
      //   providerId: chooseFromModal?.providerId,
      //   employeeId: chooseFromModal?.employeeId,
      //   uniqueId: enterUniqueId,
      //   defaultPrice: (!choosedPriceList?.id && !choosedPriceList?.value ? resetDefaultValue : null) || null,
      //   defaultPriceListId: choosedPriceList?.id || choosedPriceList?.value || null,
      //   note: ''
      // }
        {
          providerId: chooseFromModal?.providerId,
          employeeId: chooseFromModal?.employeeId,
          uniqueId: enterUniqueId,
          defaultValueEmployee: initialDefaultOperator?.defaultValueEmployee || false,
          // defaultPrice: (!choosedPriceList?.id && !choosedPriceList?.value && !chooseFromModal?.currentOperator?.priceListId ? resetDefaultValue : null) || null,
          // Сетим дефолтное значение суммы (id меняем, суммы меняются только на месте)
          // defaultPrice: !chooseFromModal?.currentOperator?.priceListId ? chooseFromModal?.amount : null
          defaultPrice: initialDefaultOperator?.defaultPrice || null
          //  ((!choosedPriceList?.id && !choosedPriceList?.value && !chooseFromModal?.currentOperator?.priceListId ? resetDefaultValue : null) || null)
          ,
          
          // defaultPriceListId: resetDefaultValueList || null,
          // Сетим дефолтное значение прайслиста (id меняем, прайслисты меняются только на месте)
          // defaultPriceListId: chooseFromModal?.currentOperator?.priceListId || null,
          defaultPriceListId: initialDefaultOperator?.defaultPriceList?.id || null,
          // TODO: Тест defaultScenario и defaultScenarioId
          // defaultScenarioId: defaultScenario?.id || null,
          // Сетим дефолтное значение сценария (id меняем, сценарии меняются только на месте)
          // defaultScenarioId: chooseFromModal?.defaultScenario?.id || null,
          defaultScenarioId: initialDefaultOperator?.defaultScenario?.id || null,
          note: ''
        }
      );

      // await accounts.getCheckEmployeeAccountsAction({ id: chooseFromModal.employeeId });

      if (chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES) {
        setSelectValidateFields([
          ...selectValidateFields,
          {
            ...selectItem?.currentOperator,
            description: selectItem?.description,
            accountId: toJS(getAccountsModel.getCreateAccounts).id,
            uniqueId: enterUniqueId,
            initialValue: {
              [`choose${chooseFromModal.providerId}${chooseFromModal?.employeeId}`
                ?.split('.')
                .join('')]: {
                amount: selectItem.amount,
                currentOperator: {
                  minAmount: selectItem?.currentOperator?.minAmount,
                  maxAmount: selectItem?.currentOperator?.maxAmount
                }
              }
            }
          }
        ]);
      } else {
        setInputValidateFields([
          ...inputValidateFields,
          {
            ...chooseFromModal,
            value: enterAmount,
            amount: enterAmount,
            singleValue: enterAmount,
            uniqueId: enterUniqueId,
            description: descriptionPackagesAndMoney,
            accountId: toJS(getAccountsModel.getCreateAccounts).id,
            initialValue: {
              [`singleId${chooseFromModal.providerId}${chooseFromModal?.employeeId}`
                ?.split('.')
                .join('')]: {
                amount: enterAmount,
                currentOperator: {
                  minAmount: chooseFromModal.currentOperator.minAmount,
                  maxAmount: chooseFromModal.currentOperator.maxAmount
                }
              }
            }
          }
        ]);
      }

      await setIsCheckGroupRechange(
        isCheckGroupRechange.reduce((acc: any, item: any) => {
          acc.push({
            ...item,
            accounts:
            removeDuplicates(
              (item.id === chooseFromModal.employeeId
                ? [
                  ...item?.accounts,
                  {
                    ...toJS(getAccountsModel.getCreateAccounts),
                    // TODO: Тест defaultScenario
                    defaultScenario,
                    defaultPrice: 
                    // Default true
                    // !(
                    
                    //   toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                    // )
                    //   ? 
                    
                    //   toJS(getAccountsModel.getCreateAccounts)?.defaultPrice
                    //   : null
                    (!choosedPriceList?.id && !choosedPriceList?.value && !chooseFromModal?.currentOperator?.priceListId ? resetDefaultValue : null) || null
                    ,
                    defaultPriceList:
                    // Default true
                    // toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                    //   ? 
                      
                      //   toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                      //   : null
                      resetDefaultValueListChange || null
                    ,
                    defaultPriceListEdit:
                      
                    // Default true
                    // toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                    //   ? 
                        
                      //   toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                      //   : null
                      resetDefaultValueListChange || null
                  }
                ]
                : item?.accounts), (key: any) => key?.provider?.id).reduce((accountAcc: any, accountItem: any) => {
                  
              // Hardcode
              toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                if (provider.id === accountItem?.provider?.id) {
                  accountItem.provider = provider;
                }
              });
  
              accountAcc.push(accountItem);
  
              return accountAcc;
            }, [])
          });
          return acc;
        }, [])
      );
      
      await window.sessionStorage.setItem(
        'isCheckGroup',
        JSON.stringify(
          isCheckGroupRechange.reduce((acc: any, item: any) => {
            acc.push({
              ...item,
              accounts:
              removeDuplicates(
                (item.id === chooseFromModal.employeeId
                  ? [
                    ...item?.accounts,
                    {
                      ...toJS(getAccountsModel.getCreateAccounts),
                      // TODO: Тест defaultScenario
                      defaultScenario,
                      defaultPrice: 
                      // Default true
                      // !(
                   
                      //   toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                      // )
                      //   ? 
                     
                      //   toJS(getAccountsModel.getCreateAccounts)?.defaultPrice
                      //   : null
                      (!choosedPriceList?.id && !choosedPriceList?.value && !chooseFromModal?.currentOperator?.priceListId ? resetDefaultValue : null) || null
                      ,
                      defaultPriceList:
                      // Default true
                      // toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                      //   ? 
                          
                        //   toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                        //   : null
                        resetDefaultValueListChange || null
                      ,
                      defaultPriceListEdit:
                      //  Default true
                      // toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                      //   ? 
                          
                        //   toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
                        //   : null
                        resetDefaultValueListChange || null
                    }
                  ]
                  : item?.accounts), (key: any) => key?.provider?.id).reduce((accountAcc: any, accountItem: any) => {
                    
                // Hardcode
                toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                  if (provider.id === accountItem?.provider?.id) {
                    accountItem.provider = provider;
                  }
                });
    
                accountAcc.push(accountItem);
    
                return accountAcc;
              }, [])
            });
            return acc;
          }, [])
        )
      );

      if (!state?.group) {
        // await window.sessionStorage.setItem(
        //   'providers',
        //   JSON.stringify(
        //     toJS(
        //       isCheckGroupRechange
        //         .reduce((acc: any, item: any) => {
        //           acc.push({
        //             ...item,
        //             accounts:
        //                   (item.id === chooseFromModal.employeeId
        //                     ? [
        //                       ...item?.accounts,
        //                       {
        //                         ...toJS(getAccountsModel.getCreateAccounts),
        //                         // TODO: Тест defaultScenario
        //                         defaultScenario,
        //                         defaultPrice:
        //                         // Default true
        //                         // !(
        //
        //                         //   toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
        //                         // )
        //                         //   ?
        //
        //                         //   toJS(getAccountsModel.getCreateAccounts)?.defaultPrice
        //                         //   : null
        //                         (!choosedPriceList?.id && !choosedPriceList?.value && !chooseFromModal?.currentOperator?.priceListId ? resetDefaultValue : null) || null
        //                         ,
        //                         defaultPriceList:
        //                         //  Default true
        //                         // toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
        //                         //   ?
        //
        //                             //   toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
        //                             //   : null
        //                             resetDefaultValueListChange || null
        //                         ,
        //                         defaultPriceListEdit:
        //                         // Default true
        //                         // toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
        //                         //   ?
        //
        //                             //   toJS(getAccountsModel.getCreateAccounts)?.defaultPriceList
        //                             //   : null
        //                             resetDefaultValueListChange || null
        //                       }
        //                     ]
        //                     : item?.accounts)?.reduce((accountAcc: any, accountItem: any) => {
        //
        //                     // Hardcode
        //                     toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
        //                       if (provider.id === accountItem?.provider?.id) {
        //                         accountItem.provider = provider;
        //                       }
        //                     });
        //
        //                     accountAcc.push(accountItem);
        //
        //                     return accountAcc;
        //                   }, [])
        //           });
        //           return acc;
        //         }, [])
        //         .reduce((acc: any[], accountsItem: any) => {
        //           if (accountsItem?.accounts?.length >= 1) {
        //             acc.push(
        //               ...accountsItem?.accounts?.map((provider: any) => ({
        //                 ...provider?.provider,
        //                 employeeId: accountsItem?.id,
        //                 uniqueId: provider?.uniqueId
        //               }))
        //             );
        //           }
        //           return acc;
        //         }, [])
        //     )
        //   )
        // );
      }

      hideModal(false);
    }
  };

  const onEditSubmit = async () => {
    const initialDefaultOperator = toJS(getEmployeesModel.getEmployeesList)?.filter((employee: any) => employee?.id === currentOperatorData?.employee?.id)?.map((employeeItem: any) => employeeItem?.accounts).flat(Infinity).filter((accountItem: any) => accountItem?.provider?.id === currentOperatorData?.provider?.id)[0];

    if ((isDefaultAmountFromModal ? currentOperatorData?.defaultPrice || currentOperatorData?.defaultPriceList?.price || currentOperatorData?.defaultPriceListEdit?.price : +enterAmountEdit || +choosedAmount || null) ?
      (enterAmountEdit || choosedAmount || currentOperatorData?.defaultPrice || currentOperatorData?.defaultPriceList?.price || currentOperatorData?.defaultPriceListEdit?.price) >= currentOperatorData?.provider?.minAmount &&
    (enterAmountEdit || choosedAmount || currentOperatorData?.defaultPrice || currentOperatorData?.defaultPriceList?.price || currentOperatorData?.defaultPriceListEdit?.price) <= currentOperatorData?.provider?.maxAmount : true) {
      const defaultGroupValues = toJS(state?.group)?.groupsAccounts.reduce(
        (acc: any, account: any) => {
          if (chooseFromModal?.providerId === account?.provider?.id) {
            acc.push({
              defaultPrice: account?.defaultPrice,
              priceList: account?.priceList
            });
          }
          return acc;
        },
        []
      )[0];

      // TODO: Заняться разработкой и тестирыванием для PACKAGES, MONEY, PACKAGES_AND_MONEY(Потестировать)
     
      const data = {
        employeeId: currentOperatorData?.employee?.id,
        groupId: currentOperatorData?.employee?.group?.id,
        name: currentOperatorData?.employee?.name,
        surname: currentOperatorData?.employee?.surname,
        phones: currentOperatorData?.employee?.phones,
        accounts: removeDuplicates(currentOperatorData?.employee?.accounts?.reduce(
          (accountAcc: any, accountItem: any) => {
            if (accountItem?.provider?.id === currentOperatorData?.provider?.id) {

              // Hardcode
              toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                if (provider.id === accountItem?.provider?.id) {
                  accountItem.provider = provider;
                }
              });
              accountAcc.push({
                employeeId: currentOperatorData?.employee?.id,
                providerId: accountItem?.provider?.id,
                // TODO: Тест defaultScenario и defaultScenarioId
                // DEfault true
                // defaultScenario: defaultScenario?.id ? defaultScenario : null,
                // defaultScenario: currentOperatorData?.defaultScenario,
                defaultScenarioId: 
                // currentOperatorData?.defaultScenario?.id || null
                initialDefaultOperator?.defaultScenario?.id || null,

                uniqueId: isDefaultUniqueIdCurrentOperator ? accountItem?.uniqueId : enterUniqueId,
                defaultValueEmployee: initialDefaultOperator?.defaultValueEmployee || false,
                defaultPrice: 
                // Default true
                // (currentOperatorData?.defaultPriceList && currentOperatorData?.defaultPriceListEdit ?
                //   ((!choosedPriceListEdit?.value ||
                // !accountItem?.defaultPriceList?.id ||
                // !accountItem?.defaultPriceListEdit?.id) ?
                //     (isDefaultAmountFromModal
                //       ? +accountItem?.defaultPrice
                //       : +enterAmountEdit || null) : null) : isDefaultAmountFromModal ? +accountItem?.defaultPrice : +enterAmountEdit || null)
                // +accountItem?.defaultPrice
              initialDefaultOperator?.defaultPrice || null,
                defaultPriceListId:
                // Default true
                // ( isDefaultAmountFromModal ? (
                //   accountItem?.defaultPriceList?.id ||
                //   accountItem?.defaultPriceListEdit?.id) : choosedPriceListEdit?.value  )
                
                //   ? isDefaultAmountFromModal ?
                //     accountItem?.defaultPriceList?.id ||
                //     currentOperatorData?.defaultPriceListEdit?.id
                //     : choosedPriceListEdit?.value || null
                    
                //   : null
                // accountItem?.defaultPriceList?.id ||
                //     currentOperatorData?.defaultPriceListEdit?.id || null
                initialDefaultOperator?.defaultPriceList?.id || null,
                note: accountItem?.note
              });
            } else {
              accountAcc.push({
                employeeId: currentOperatorData?.employee?.id,
                providerId: accountItem?.provider?.id,
                uniqueId: accountItem?.uniqueId,
                defaultScenarioId: accountItem?.defaultScenario?.id || null,
                defaultPrice:
                // Default true
                !accountItem?.defaultPriceList?.id && !accountItem?.defaultPriceListEdit?.id
                  ? +accountItem?.defaultPrice
                  : null,
                defaultPriceListId:
                accountItem?.defaultPriceList?.id || accountItem?.defaultPriceListEdit?.id || null,
                note: currentOperatorData?.note
              });
            }

            return accountAcc;
          },
          []
        ), (key: any) => key?.providerId)
      };

      await employees.updateEmployeeAction(data);

      await accounts.getCheckEmployeeAccountsAction({ id: currentOperatorData.employee?.id });

      const currentAccount = toJS(getAccountsModel.getCreateCheckAccounts)?.filter((accountItem: GetAccountData) => accountItem?.provider?.id === currentOperatorData?.provider?.id )[0];
     
      const editEmployeePaymentData = isCheckGroupRechange
        .reduce((acc: any, item: any) => {

          acc.push({
            ...item,
            // Default and true
            // accounts: removeDuplicates(item?.accounts?.map((accountItem: any) => toJS(getEmployeesModel.getEmployeesDetail)?.accounts?.map((newAccountItem) => {
            //   if (accountItem?.provider?.id === newAccountItem?.provider?.id && currentOperatorData?.employee?.id === item?.id) {
            //     accountItem.id = newAccountItem?.id;
            //   }
  
            //   return accountItem;
            // })).flat(Infinity), (key: any) => key?.id)

            // Changed
            accounts:
            //  removeDuplicates(item?.accounts?.map((accountItem: any) => {
            //   if (accountItem?.provider?.id === currentOperatorData?.provider?.id && currentOperatorData?.employee?.id === item?.id) {
            //     // accountItem.id = currentOperatorData?.id;
            //     accountItem.id = currentAccount?.id;
            //   }
  
            //   return accountItem;
            // }).flat(Infinity), (key: any) => key?.id)

            removeDuplicates(item?.accounts?.map((accountItem: any) => toJS(getAccountsModel.getCreateCheckAccounts)?.map((currentAcc: GetAccountData) => {
              if (accountItem?.provider?.id === currentAcc?.provider?.id && item.id === currentAcc?.employee?.id) {
                // accountItem.id = currentOperatorData?.id;
                accountItem.id = currentAcc.id;
              }
  
              return accountItem;
            })).flat(Infinity), (key: any) => key?.id)
          });
          return acc;
        }, [])
        .reduce((acc: any, item: any) => {
          acc.push({
            ...item,
            accounts: item?.accounts?.reduce((accountAcc: any, accountItem: any) => {

              if ( accountItem?.provider?.id === currentOperatorData?.provider?.id && item?.id === currentOperatorData?.employee?.id ) {

                // Hardcode
                toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                  if (provider.id === accountItem?.provider?.id) {
                    accountItem.provider = provider;
                  }
                });

                accountAcc?.push({
                  ...accountItem,
                  // uniqueId: isDefaultUniqueIdCurrentOperator ? accountItem?.uniqueId : enterUniqueId,
                  uniqueId: isDefaultUniqueIdCurrentOperator ? accountItem?.uniqueId : enterUniqueId,
                  // TODO: Тест defaultScenario
                  defaultScenario,
                  employee: { ...item, accounts: null },
                  defaultPrice:
                (currentOperatorData?.defaultPriceList && currentOperatorData?.defaultPriceListEdit ?
                  ((!choosedPriceListEdit?.value ||
                !accountItem?.defaultPriceList?.id ||
                !accountItem?.defaultPriceListEdit?.id) ?
                    (isDefaultAmountFromModal
                      ? +accountItem?.defaultPrice
                      : +enterAmountEdit || null) : null) : isDefaultAmountFromModal ? +accountItem?.defaultPrice : +enterAmountEdit || null),
                  defaultPriceList:
              ( isDefaultAmountFromModal ? (
                accountItem?.defaultPriceList?.id ||
                accountItem?.defaultPriceListEdit?.id) : choosedPriceListEdit?.value  )
                // (choosedPriceListEdit?.id ||
                //   accountItem?.defaultPriceList?.id ||
                //   accountItem?.defaultPriceListEdit?.id)
                //    &&
                // !enterAmountEdit
                ? choosedPriceListEdit?.value || choosedPriceListEdit?.id ? { id: choosedPriceListEdit?.value || choosedPriceListEdit?.id, description: choosedPriceListEdit?.description, label: choosedPriceListEdit?.label, price: choosedPriceListEdit?.amount || choosedPriceListEdit?.price, provider: choosedPriceListEdit?.currentOperator || choosedPriceListEdit?.provider } :
                  accountItem?.defaultPriceList ||
                    currentOperatorData?.defaultPriceListEdit
                : null,
                  defaultPriceListEdit:
                // (choosedPriceListEdit?.id ||
                //   accountItem?.defaultPriceList?.id ||
                //   accountItem?.defaultPriceListEdit?.id) &&
                // !enterAmountEdit
                //   ? choosedPriceListEdit?.value || choosedPriceListEdit?.id ? { id: choosedPriceListEdit?.value || choosedPriceListEdit?.id, description: choosedPriceListEdit?.description, label: choosedPriceListEdit?.label, price: choosedPriceListEdit?.amount || choosedPriceListEdit?.price, provider: choosedPriceListEdit?.currentOperator || choosedPriceListEdit?.provider } :
                //     accountItem?.defaultPriceList ||
                //     currentOperatorData?.defaultPriceListEdit
                //   : null
                ( isDefaultAmountFromModal ? (
                  accountItem?.defaultPriceList?.id ||
                  accountItem?.defaultPriceListEdit?.id &&
                  !enterAmountEdit) : choosedPriceListEdit?.value  )
                  // (choosedPriceListEdit?.id ||
                  //   accountItem?.defaultPriceList?.id ||
                  //   accountItem?.defaultPriceListEdit?.id)
                  //    &&
                  // !enterAmountEdit
                  ? choosedPriceListEdit?.value || choosedPriceListEdit?.id ? { id: choosedPriceListEdit?.value || choosedPriceListEdit?.id, description: choosedPriceListEdit?.description, label: choosedPriceListEdit?.label, price: choosedPriceListEdit?.amount || choosedPriceListEdit?.price, provider: choosedPriceListEdit?.currentOperator || choosedPriceListEdit?.provider } :
                    accountItem?.defaultPriceList ||
                      currentOperatorData?.defaultPriceListEdit
                  : null,

                });
              } else {
                accountAcc?.push({
                  ...accountItem,
                  employee: { ...item, accounts: null },
                });
              }
              return accountAcc;
            }, [])
          });
          return acc;
        }, []);
      
      // Удалить если не нужно будет (потестировать с editEmployeePaymentData)
      // const editGroupPaymentData = isCheckGroupRechange.reduce((acc: any, item: any) => {
      //   acc.push({
      //     ...item,
      //     accounts: removeDuplicates(item?.accounts?.map((accountItem: any) => toJS(getEmployeesModel.getEmployeesDetail)?.accounts?.map((newAccountItem) => {
      //       if (accountItem?.provider?.id === newAccountItem?.provider?.id && currentOperatorData?.employee?.id === item?.id) {
      //         accountItem.id = newAccountItem?.id;
      //       }
  
      //       return accountItem;
      //     })).flat(Infinity), (key: any) => key?.id)
      //   });
      //   return acc;
      // }, [])?.reduce((acc: any, item: any) => {
      //   acc.push({
      //     ...item,
      //     accounts: item?.accounts?.reduce((accountAcc: any, accountItem: any) => {

      //       if ( accountItem?.provider?.id === currentOperatorData?.provider?.id && item?.id === currentOperatorData?.employee?.id ) {

      //         // Hardcode
      //         toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
      //           if (provider.id === accountItem?.provider?.id) {
      //             accountItem.provider = provider;
      //           }
      //         });

      //         accountAcc?.push({
      //           ...accountItem,
      //           uniqueId: isDefaultUniqueIdCurrentOperator ? accountItem?.uniqueId : enterUniqueId,
      //           defaultPrice:
      //         (currentOperatorData?.defaultPriceList || currentOperatorData?.defaultPriceListEdit ?
      //           (!choosedPriceListEdit?.value ||
      //           !accountItem?.defaultPriceList?.id ||
      //           !accountItem?.defaultPriceListEdit?.id) ?
      //             (isDefaultAmountFromModal
      //               ? accountItem?.defaultPrice
      //               : enterAmountEdit || null) : null : enterAmountEdit || null),
      //           defaultPriceList:
      //         ( isDefaultAmountFromModal ? (
      //           accountItem?.defaultPriceList?.id ||
      //           accountItem?.defaultPriceListEdit?.id) : choosedPriceListEdit?.value  )
      //           // (choosedPriceListEdit?.id ||
      //           //   accountItem?.defaultPriceList?.id ||
      //           //   accountItem?.defaultPriceListEdit?.id)
      //           //    &&
      //           // !enterAmountEdit
      //           ? choosedPriceListEdit?.value || choosedPriceListEdit?.id ? { id: choosedPriceListEdit?.value || choosedPriceListEdit?.id, description: choosedPriceListEdit?.description, label: choosedPriceListEdit?.label, price: choosedPriceListEdit?.amount || choosedPriceListEdit?.price, provider: choosedPriceListEdit?.currentOperator || choosedPriceListEdit?.provider } :
      //             accountItem?.defaultPriceList ||
      //               currentOperatorData?.defaultPriceListEdit
      //           : null,
      //           defaultPriceListEdit:
      //           // (choosedPriceListEdit?.id ||
      //           //   accountItem?.defaultPriceList?.id ||
      //           //   accountItem?.defaultPriceListEdit?.id) &&
      //           // !enterAmountEdit
      //           //   ? choosedPriceListEdit?.value || choosedPriceListEdit?.id ? { id: choosedPriceListEdit?.value || choosedPriceListEdit?.id, description: choosedPriceListEdit?.description, label: choosedPriceListEdit?.label, price: choosedPriceListEdit?.amount || choosedPriceListEdit?.price, provider: choosedPriceListEdit?.currentOperator || choosedPriceListEdit?.provider } :
      //           //     accountItem?.defaultPriceList ||
      //           //     currentOperatorData?.defaultPriceListEdit
      //           //   : null
      //           ( isDefaultAmountFromModal ? (
      //             accountItem?.defaultPriceList?.id ||
      //             accountItem?.defaultPriceListEdit?.id &&
      //             !enterAmountEdit) : choosedPriceListEdit?.value  )
      //             // (choosedPriceListEdit?.id ||
      //             //   accountItem?.defaultPriceList?.id ||
      //             //   accountItem?.defaultPriceListEdit?.id)
      //             //    &&
      //             // !enterAmountEdit
      //             ? choosedPriceListEdit?.value || choosedPriceListEdit?.id ? { id: choosedPriceListEdit?.value || choosedPriceListEdit?.id, description: choosedPriceListEdit?.description, label: choosedPriceListEdit?.label, price: choosedPriceListEdit?.amount || choosedPriceListEdit?.price, provider: choosedPriceListEdit?.currentOperator || choosedPriceListEdit?.provider } :
      //               accountItem?.defaultPriceList ||
      //                 currentOperatorData?.defaultPriceListEdit
      //             : null,
      //           employee: { ...item, accounts: null },
      //         });
      //       } else {
      //         accountAcc?.push({
      //           ...accountItem,
      //           employee: { ...item, accounts: null },
      //         });
      //       }
      //       return accountAcc;
      //     }, [])
      //   });
      //   return acc;
      // }, []);

      // if (!state?.group) {
      await setIsCheckGroupRechange(editEmployeePaymentData);
      await window.sessionStorage.setItem('isCheckGroup', JSON.stringify(editEmployeePaymentData));
      // }

      // if (state?.group) {
      //   await setIsCheckGroupRechange(editGroupPaymentData);
      //   await window.sessionStorage.setItem('isCheckGroup', JSON.stringify(editGroupPaymentData));
      // }
    
      hideModal(false);
    }
  };
  
  const handleCheckType = (e: any, eMeta: any) => {
    const replaceObjectDep = `${currentOperatorData?.provider?.id || chooseFromModal?.currentOperator?.id}${currentOperatorData?.employee?.id || chooseFromModal?.employeeId}`;
    setCurrentScenario(e);
    setIsDefaultAmountFromModal(false);

    // Обнуляем amount и prileList когда выбираем сценарий
    setChoosenAmountPackagesAndMoney('');
    setEnterAmountEdit('');
    setEnterAmount('');
    setChoosedPriceListEdit('');
    setChoosedPriceList('');
    setFieldValue(`choose${replaceObjectDep}`, '');
    setFieldValue(`singleId${replaceObjectDep}`, '');
  };

  const handleInputValue = (e: any, employeeInfo: any) => {
    setEnterAmount(e);
    setEnterAmountEdit(e);
    setChoosenAmountPackagesAndMoney(e);
    setDescriptionPackagesAndMoney('');
    setChoosedPriceList('');
    setChoosedPriceListEdit('');
    setIsDefaultAmountFromModal(false);

    if (!e) {
      setEnterAmount('');
      setEnterAmountEdit('');
      setChoosenAmountPackagesAndMoney('');
      // setChoosedPriceList(
      // choosedAmountAdditional ||
      //   currentOperatorData?.defaultPriceList ||
      //   currentOperatorData?.defaultPriceListEdit ||
      //   ''
      // );
      // setChoosedPriceListEdit(
      //   choosedAmountAdditional ||
      //     currentOperatorData?.defaultPriceList ||
      //     currentOperatorData?.defaultPriceListEdit ||
      //     ''
      // );
    }
  };

  const handleChoosePackageAndMoneyAmount = (e: any, employeeInfo: any) => {
    setChoosenAmountPackagesAndMoney(e.amount);
    setEnterAmount(e.amount);
    setEnterAmountEdit('');
    setDescriptionPackagesAndMoney(e?.description);
    setChoosedPriceList(e);
    setChoosedPriceListEdit(e);
    setChoosedAmountAdditional(e);
    setIsDefaultAmountFromModal(false);
    setProductOperatorCheckList([(e?.id || e?.providerId)?.split('.')?.join('')]);

    if (!e?.amount) {
      setEnterAmount('');
      setEnterAmountEdit('');
      setChoosenAmountPackagesAndMoney('');
      // setChoosedPriceList(choosedAmountAdditional || e || '');
      // setChoosedPriceListEdit(choosedAmountAdditional || e || '');
    }
  };

  const handleOperatorActive = () => {
    // TODO: Нужно доделать, логика isActive для каждого оператора
    setIsCheckGroupRechange(isCheckGroupRechange.map((item: any) => {
      if (item.id === currentOperatorData?.employee?.id) {
        item.accounts = item.accounts.map((account: any) => {
          if (account.provider.id === currentOperatorData.provider.id) {
            account.isOperatorActive = !account.isOperatorActive;
          }
          return account;
        });
      }
      return item;
    }));
  };
  // TODO: 18.08.2023 - тест, вернуть useEffect если будут ошибки
  useEffect(
    () =>
      // TODO: Потестировать sessionStorage с данными, в зависимости был также isCheckGroupRechange, потестить без него
      () => {
        setCurrentOperatorData({});
        setChooseFromModal({});
      },
    [currentOperatorData]
  );

  useEffect(() => {
    const isOpenInteractiveList = (e: any) => {
      !containerEl?.current?.contains(e.target) && 
      backdropEl?.current?.contains(e.target)
       &&
        hideModal(false);
    };
      

    document.addEventListener('click', isOpenInteractiveList);
    return () => document.removeEventListener('click', isOpenInteractiveList);
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('isCheckGroup', JSON.stringify(isCheckGroupRechange));
  }, [isCheckGroupRechange]);

  return (
    <div className={s.backdrop} ref={backdropEl}>
      <div className={s.wrapper__container} ref={containerEl}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>{ currentOperatorData?.id ? t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.EDITTITLE') : t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.CREATETITLE') }</h2>
          </div>

          <div className={s.close} onClick={() => hideModal(false)}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.main}>
          <div className={s.operator}>
            <div className={s.operator__create_id}>
              <FormikTextField
                inputStyles={{
                  width: '100%'
                }}
                name={
                  currentOperatorData?.uniqueId
                    ? `uniqueId${currentOperatorData?.provider?.id}${currentOperatorData?.employee?.id}`
                      ?.split('.')
                      .join('')
                    : `uniqueId${chooseFromModal?.providerId}${chooseFromModal?.employeeId}`
                }
                disabled={!currentOperatorActiveStatus}
                placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.IDPLACEHOLDER')}
                type="text"
                // required
                onChange={checkUniqueIdField}
                label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.ID')}</p>}
              />
            </div>

              { scenarios.length ?
                <div className={`${s.operator__create_id} ${s.operator__create_select}`}>
                  <FormikSelect
                  portalTargetDeactivate
                  disabled={!currentOperatorActiveStatus}
                  containerStyles={{
                    width: '100%',
                    maxWidth: '100%',
                    pointerEvents: !currentOperatorActiveStatus ? 'none' : 'auto',
                    opacity: !currentOperatorActiveStatus ? '0.7' : '1',
                  }}
                  valueContainer={{
                    paddingLeft: '.5rem'
                  }}
                  selectWrapperStyles={{
                    width: '100%'
                  }}
                  indicatorContainerStyles={{ height: 'auto', width: '2.5rem', opacity: !currentOperatorActiveStatus ? '0.7' : '1' }}
                  valueStyles={{
                    color: ColorsAccent.accentDark
                  }}
                  id="scenario"
                  name={
                    // `scenario${content?.provider?.id}`?.split('.')?.join('')
                  currentOperatorData?.uniqueId
                    ? `scenario${currentOperatorData?.provider?.id}${currentOperatorData?.employee?.id}`
                      ?.split('.')
                      .join('')
                    : `scenario${chooseFromModal?.providerId}${chooseFromModal?.employeeId}`}
                  options={[{
                    value: '',
                    label: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.SCENARIOPLACEHOLDER'),
                    singleValue: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.SCENARIOPLACEHOLDER'),
                    id: null,
                    type: ''
                  }, 
                  ...scenarios]}
                  placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.SCENARIOPLACEHOLDER')}
                  label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.SCENARIO')}</p>}
                  indicatorIcon={<DropSelectSVG />}
                  onChange={(e: any, eMeta: any) => handleCheckType(e, eMeta)}
                />
              </div>
                :
                null
              }

            <>
              {
                (!scenarios?.length ? chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES : defaultScenario?.type === KindOfScenarios.PRICELIST && chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES)
                // chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES
                  ? (
                <>
                  <div className={`${s.operator__create_id} ${s.operator__create_select}`}>
                    <FormikSelect
                      loadingMessage={isGetDetailPriceListLoading}
                      disabled={!currentOperatorActiveStatus}
                      onMenuOpen={() => handlePriceListSelect({ id: chooseFromModal.providerId })}
                      containerStyles={{
                        width: '100%',
                        maxWidth: '100%'
                      }}
                      valueContainer={{
                        paddingLeft: '.5rem'
                      }}
                      selectWrapperStyles={{
                        width: '100%'
                      }}
                      indicatorContainerStyles={{ height: 'auto', width: '2.5rem' }}
                      // hideInputContainerWrapper={{
                      //   width: 0,
                      //   margin: 0,
                      //   padding: 0,
                      //   maxHeight: 0
                      // }}
                      valueStyles={{
                        color: ColorsAccent.accentDark
                      }}
                      name={!state?.group ? `choose${chooseFromModal.providerId}${chooseFromModal?.employeeId}`
                        ?.split('.')
                        .join('') : ' '}
                      id="operator"
                      options={
                        // getPriceListModel.getPriceListDetail?.slice(itemOffset, endOffset)
                        [{ ...chooseFromModal,
                        
                          currentOperator: {
                            ...chooseFromModal?.currentOperator,
                            description: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER')
                          },
                          amount: null,
                          value: '',
                          singleValue: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER'),
                          label: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER'),
                          description: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER'),
                          uniqueId: enterUniqueId },
                        ...getPriceListModel.getPriceListDetail?.map((price: any) => ({
                          ...chooseFromModal,
                          currentOperator: {
                            ...chooseFromModal?.currentOperator,
                            description: price?.description
                          },
                          amount: price?.price,
                          value: price?.id,
                          singleValue: price?.label,
                          label: price?.label,
                          description: price?.description,
                          uniqueId: enterUniqueId
                        })) ]
                      }
                      placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER')}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCT')}</p>}
                      indicatorIcon={<DropSelectSVG />}
                      onChange={handleChooseAmount}
                    />
                  </div>
                  <div className={s.operator__create_id}>
                    {/* <FormikTextField
                      name="amount"
                      disabled
                      min={chooseFromModal.currentOperator.minAmount}
                      max={chooseFromModal.currentOperator.maxAmount}
                      placeholder="Amount"
                      type="text"
                      value={choosedAmount && `${choosedAmount} UGX`}
                      label={<p className={s.operator__create_label}>Amount</p>}
                    /> */}
                    <FormikTextFieldNumber
                      name="amount"
                      disabled
                      placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNTPLACEHOLDER')}
                      value={ chooseFromModal?.currentOperator?.priceListId || choosedAmount ? (choosedAmount ||
                        currentOperatorData?.defaultPriceList?.price || chooseFromModal?.amount || '') : ''}
                        defaultValue={ chooseFromModal?.currentOperator?.priceListId ?
                          (currentOperatorData?.defaultPriceList?.price || chooseFromModal?.amount || '') : ''}
                      suppressHydrationWarning
                      min={chooseFromModal.currentOperator.minAmount}
                      max={chooseFromModal.currentOperator.maxAmount}
                      // onKeyPress={(event) => {
                      //   if (!/[0-9]/.test(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNT')}</p>}
                      onValueChange={(e: any, name: any) =>
                        handleInputValue(e, {
                          ...chooseFromModal,
                          amount: e,
                          value: e,
                          singleValue: e,
                          label: e,
                          description: `${chooseFromModal?.currentOperator?.title} ${t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.DESCRIPTIONADDITIONALTEXT')}`
                        })
                      }
                    />
                  </div>
                </>
                  ) : null}

              {/* Edit PACKAGES measure field */}
              {(!scenarios?.length ? currentOperatorData?.provider?.measure === ProviderMeasure.PACKAGES : defaultScenario?.type === KindOfScenarios.PRICELIST && currentOperatorData?.provider?.measure === ProviderMeasure.PACKAGES)
              // currentOperatorData?.provider?.measure === ProviderMeasure.PACKAGES
                ? (
                <>
                  <div className={`${s.operator__create_id} ${s.operator__create_select}`}>
                    <FormikSelect
                      loadingMessage={isGetDetailPriceListLoading}
                      onMenuOpen={() =>
                        handlePriceListSelect({ id: currentOperatorData.provider?.id })
                      }
                      disabled={!currentOperatorActiveStatus}
                      containerStyles={{
                        width: '100%',
                        maxWidth: '100%'
                      }}
                      selectWrapperStyles={{
                        width: '100%',
                        pointerEvents: !currentOperatorActiveStatus ? 'none' : 'auto',
                        opacity: !currentOperatorActiveStatus ? '0.7' : '1',
                      }}
                      valueContainer={{
                        paddingLeft: '.5rem'
                      }}
                      indicatorContainerStyles={{ height: 'auto', width: '2.5rem', opacity: !currentOperatorActiveStatus ? '0.7' : '1' }}
                      // hideInputContainerWrapper={{
                      //   width: 0,
                      //   margin: 0,
                      //   padding: 0,
                      //   maxHeight: 0
                      // }}
                      valueStyles={{
                        color: ColorsAccent.accentDark
                      }}
                      name={`choose${currentOperatorData.provider?.id}${currentOperatorData.employee?.id}`
                        ?.split('.')
                        .join('')}
                      id="operator"
                      options={
                        // getPriceListModel.getPriceListDetail?.slice(itemOffset, endOffset)
                        [
                          { ...currentOperatorData.provider,
                            currentOperator: {
                              ...currentOperatorData.provider,
                              description: 'None'
                            },
                            amount: null,
                            value: '',
                            singleValue: 'None',
                            label: 'None',
                            description: 'None',
                            uniqueId: enterUniqueId },
                          ...getPriceListModel.getPriceListDetail?.map((price: any) => ({
                            ...currentOperatorData.provider,
                            currentOperator: {
                              ...currentOperatorData.provider,
                              description: price?.description
                            },
                            amount: price?.price,
                            value: price?.id,
                            singleValue: price?.label,
                            label: price?.label,
                            description: price?.description,
                            uniqueId: enterUniqueId
                          })) ]
                      }
                      placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER')}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCT')}</p>}
                      indicatorIcon={<DropSelectSVG />}
                      onChange={handleChooseAmount}
                    />

                  </div>
                  <div className={s.operator__create_id}>
                    {/* <FormikTextField
                      name="amount"
                      disabled
                      min={currentOperatorData.provider.minAmount}
                      max={currentOperatorData.provider.maxAmount}
                      placeholder="Amount"
                      type="text"
                      value={choosedAmount && `${choosedAmount} UGX`}
                      label={<p className={s.operator__create_label}>Amount</p>}
                    /> */}
                    <FormikTextFieldNumber
                      name="amount"
                      disabled
                      inputStyles={{
                        width: '100%'
                      }}
                      placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNTPLACEHOLDER')}
                      value={choosedAmount ||
                        currentOperatorData?.defaultPriceList?.price || ''}
                        defaultValue={
                          currentOperatorData?.defaultPriceList?.price || ''
                        }
                      suppressHydrationWarning
                      min={currentOperatorData.provider.minAmount}
                      max={currentOperatorData.provider.maxAmount}
                      // onKeyPress={(event) => {
                      //   if (!/[0-9]/.test(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNT')}</p>}
                      onValueChange={(e: any, name: any) =>
                        handleInputValue(e, {
                          ...currentOperatorData?.provider,
                          amount: e,
                          value: e,
                          singleValue: e,
                          label: e,
                          description: `${currentOperatorData?.provider?.title} ${t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.DESCRIPTIONADDITIONALTEXT')}`
                        })
                      }
                    />
                  </div>
                </>
                ) : null}
              {/* TODO: Протестировать сценарии оплат когда появятся данные с бека когда мы их будем везде сетить, сейчас у нас дефолтные значения не летят, убрать потом хардкод в employeesList */}
              {(!scenarios?.length ? chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES_AND_MONEY : defaultScenario?.type === KindOfScenarios.PRICELIST && chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES_AND_MONEY)
              // chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES_AND_MONEY
                ? (
                  <div className={`${s.operator__create_id} ${s.operator__create_select}`}>
                    <FormikSelect
                      loadingMessage={isGetDetailPriceListLoading}
                      disabled={!currentOperatorActiveStatus}
                      onMenuOpen={() => handlePriceListSelect({ id: chooseFromModal.providerId })}
                      containerStyles={{
                        width: '100%',
                        maxWidth: '100%'
                      }}
                      valueContainer={{
                        paddingLeft: '.5rem'
                      }}
                      selectWrapperStyles={{
                        width: '100%'
                      }}
                      indicatorContainerStyles={{ height: 'auto', width: '2.5rem' }}
                      // hideInputContainerWrapper={{
                      // width: 0,
                      // margin: 0,
                      // padding: 0,
                      // maxHeight: 0
                      // }}
                      valueStyles={{
                        color: ColorsAccent.accentDark
                      }}
                      name={`choose${chooseFromModal.providerId}${chooseFromModal?.employeeId}`
                        ?.split('.')
                        .join('')}
                      id="operator"
                      options={
                        // getPriceListModel.getPriceListDetail?.slice(itemOffset, endOffset)
                        [{ ...chooseFromModal,
                        
                          currentOperator: {
                            ...chooseFromModal?.currentOperator,
                            description: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER')
                          },
                          amount: null,
                          value: '',
                          singleValue: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER'),
                          label: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER'),
                          description: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER'),
                          uniqueId: enterUniqueId }, ...getPriceListModel.getPriceListDetail?.map((price: any) => ({
                          ...chooseFromModal,
                          currentOperator: {
                            ...chooseFromModal?.currentOperator,
                            description: price?.description
                          },
                          amount: price?.price,
                          value: price?.id,
                          singleValue: price?.label,
                          label: price?.label,
                          description: price?.description,
                          uniqueId: enterUniqueId
                        }))]
                      }
                      placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER')}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCT')}</p>}
                      indicatorIcon={<DropSelectSVG />}
                      onChange={(e: any, eMeta: any) =>
                        handleChoosePackageAndMoneyAmount(e, {
                          ...chooseFromModal,
                          amount: e.amount,
                          value: e.amount,
                          singleValue: e.amount,
                          label: e.amount,
                          description: `${chooseFromModal?.currentOperator?.title} ${t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.DESCRIPTIONADDITIONALTEXT')}`
                        })
                      }
                    />
                  </div>
                ) : null}
                  
                {(!scenarios?.length ? chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES_AND_MONEY : defaultScenario?.type === KindOfScenarios.AMOUNT && chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES_AND_MONEY)
                  ?
                  (<div className={`${s.operator__create_id} ${s.operator__create_amount}`}>
                    <FormikTextFieldNumber
                      name={`singleId${chooseFromModal.providerId}${chooseFromModal?.employeeId}`
                        ?.split('.')
                        .join('')}
                      placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNTPLACEHOLDER')}
                      value={ isDefaultAmountFromModal ? chooseFromModal?.amount || '' : choosenAmountPackagesAndMoney || ''}
                      defaultValue={chooseFromModal?.amount || ''}
                      disabled={!currentOperatorActiveStatus}
                      suppressHydrationWarning
                      min={chooseFromModal.currentOperator.minAmount}
                      max={chooseFromModal.currentOperator.maxAmount}
                      // onKeyPress={(event) => {
                      //   if (!/[0-9]/.test(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNT')}</p>}
                      onValueChange={(e: any, name: any) =>
                        handleInputValue(e, {
                          ...chooseFromModal,
                          amount: e,
                          value: e,
                          singleValue: e,
                          label: e,
                          description: `${chooseFromModal?.currentOperator?.title} ${t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.DESCRIPTIONADDITIONALTEXT')}`
                        })
                      }
                    />
                  </div>
                  )
                  :
                  null
                }

                { scenarios?.length && chooseFromModal?.currentOperator?.measure === ProviderMeasure.PACKAGES_AND_MONEY && defaultScenario?.type === KindOfScenarios.PRICELIST && productOperatorCheckList.includes(chooseFromModal?.currentOperator?.id?.split('.')?.join('')) ?
                      <div className={`${s.operator__create_id} ${s.operator__create_amount}`}>
                      <FormikTextFieldNumber
                      name={`singleId${chooseFromModal.providerId}${chooseFromModal?.employeeId}`?.split('.')?.join('')}
                      disabled
                      placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNTPLACEHOLDER')}
                      value={ isDefaultAmountFromModal ? chooseFromModal?.amount || '' : choosenAmountPackagesAndMoney || ''}
                      defaultValue={chooseFromModal?.amount || ''}
                      suppressHydrationWarning
                      min={chooseFromModal.currentOperator.minAmount}
                      max={chooseFromModal.currentOperator.maxAmount}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNT')}</p>}
                      // onValueChange={(e: any, name: any) => handleChangeOnlyMoney(e, content)}
                    />
                    </div>
                  :
                  null}

              {/* Edit PACKAGES_AND_MONEY measure field */}
              { (!scenarios?.length ? currentOperatorData?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY : defaultScenario?.type === KindOfScenarios.PRICELIST && currentOperatorData?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY) ? (
                  <div className={`${s.operator__create_id} ${s.operator__create_select}`}>
                    <FormikSelect
                      loadingMessage={isGetDetailPriceListLoading}
                      onMenuOpen={() =>
                        handlePriceListSelect({ id: currentOperatorData.provider?.id })
                      }
                      disabled={!currentOperatorActiveStatus}
                      containerStyles={{
                        width: '100%',
                        maxWidth: '100%',
                        pointerEvents: !currentOperatorActiveStatus ? 'none' : 'auto',
                        opacity: !currentOperatorActiveStatus ? '0.7' : '1'
                      }}
                      valueContainer={{
                        paddingLeft: '.5rem'
                      }}
                      selectWrapperStyles={{
                        width: '100%'
                      }}

                      indicatorContainerStyles={{ height: 'auto', width: '2.5rem', opacity: !currentOperatorActiveStatus ? '0.7' : '1' }}
                      // hideInputContainerWrapper={{
                      // width: 0,
                      // margin: 0,
                      // padding: 0,
                      // maxHeight: 0
                      // }}
                      valueStyles={{
                        color: ColorsAccent.accentDark
                      }}
                      name={`choose${currentOperatorData.provider?.id}${currentOperatorData?.employee?.id}`
                        ?.split('.')
                        .join('')}
                      id="operator"
                      options={
                        // getPriceListModel.getPriceListDetail?.slice(itemOffset, endOffset)
                        [{ ...currentOperatorData?.provider,
                          currentOperator: {
                            ...currentOperatorData?.provider,
                            description: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER')
                          },
                          amount: null,
                          value: '',
                          singleValue: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER'),
                          label: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER'),
                          description: t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER'),
                          uniqueId: enterUniqueId }
                        , ...getPriceListModel.getPriceListDetail?.map((price: any) => ({
                          ...currentOperatorData?.provider,
                          currentOperator: {
                            ...currentOperatorData?.provider,
                            description: price?.description
                          },
                          amount: price?.price,
                          value: price?.id,
                          singleValue: price?.label,
                          label: price?.label,
                          description: price?.description,
                          uniqueId: enterUniqueId
                        })) ]
                      }
                      placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCTPLACEHOLDER')}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.PRODUCT')}</p>}
                      indicatorIcon={<DropSelectSVG />}
                      onChange={(e: any, eMeta: any) =>
                        handleChoosePackageAndMoneyAmount(e, {
                          ...currentOperatorData?.provider,
                          amount: e.amount,
                          value: e.amount,
                          singleValue: e.amount,
                          label: e.amount,
                          description: `${currentOperatorData?.provider?.title} ${t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.DESCRIPTIONADDITIONALTEXT')}`
                        })
                      }
                    />
                  </div>
              ) : null}

                { (!scenarios?.length ? currentOperatorData?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY : defaultScenario?.type === KindOfScenarios.AMOUNT && currentOperatorData?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY) ?
                <div className={`${s.operator__create_id} ${s.operator__create_amount}`}>
                <FormikTextFieldNumber
                  name={`singleId${currentOperatorData.provider?.id}${currentOperatorData?.employee?.id}`
                    ?.split('.')
                    .join('')}
                  placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNTPLACEHOLDER')}
                  value={ isDefaultAmountFromModal ?
                    currentOperatorData?.defaultPriceListEdit?.price || currentOperatorData?.defaultPriceList?.price || currentOperatorData?.defaultPrice || '' : choosenAmountPackagesAndMoney || enterAmountEdit || ''
                  }
                  defaultValue={
                    currentOperatorData?.defaultPriceList?.price ||
                    currentOperatorData?.defaultPriceListEdit?.price || currentOperatorData?.defaultPrice || ''
                  }

                  disabled={!currentOperatorActiveStatus}
                  suppressHydrationWarning
                  min={+currentOperatorData.provider.minAmount}
                  max={+currentOperatorData.provider.maxAmount}
                  // onKeyPress={(event) => {
                  //   if (!/[0-9]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                  label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNT')}</p>}
                  onValueChange={(e: any, name: any) =>
                    handleInputValue(e, {
                      ...currentOperatorData?.provider,
                      amount: e,
                      value: e,
                      singleValue: e,
                      label: e,
                      description: `${currentOperatorData?.provider?.title} ${t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.DESCRIPTIONADDITIONALTEXT')}`
                    })
                  }
                />
              </div>
                  :
                  null
              }

                    { scenarios?.length && currentOperatorData?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY && defaultScenario?.type === KindOfScenarios.PRICELIST && productOperatorCheckList.includes(currentOperatorData?.provider?.id?.split('.')?.join('')) ?
                      <div className={`${s.operator__create_id} ${s.operator__create_amount}`}>
                      <FormikTextFieldNumber
                      name={`defaultPrice${currentOperatorData.provider?.id}${currentOperatorData?.employee?.id}`?.split('.')?.join('')}
                      disabled
                      placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNTPLACEHOLDER')}
                      value={ isDefaultAmountFromModal ?
                        currentOperatorData?.defaultPriceListEdit?.price || currentOperatorData?.defaultPriceList?.price || currentOperatorData?.defaultPrice || '' : choosenAmountPackagesAndMoney || enterAmountEdit || ''
                      }
                      defaultValue={
                        currentOperatorData?.defaultPriceList?.price ||
                        currentOperatorData?.defaultPriceListEdit?.price || currentOperatorData?.defaultPrice || ''
                      }
                      suppressHydrationWarning
                      min={currentOperatorData.provider.minAmount}
                      max={currentOperatorData.provider.maxAmount}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNT')}</p>}
                      // onValueChange={(e: any, name: any) => handleChangeOnlyMoney(e, content)}
                    />
                    </div>
                      :
                      null}
                  

              {(!scenarios?.length ? chooseFromModal?.currentOperator?.measure === ProviderMeasure.MONEY : defaultScenario?.type === KindOfScenarios.AMOUNT && chooseFromModal?.currentOperator?.measure === ProviderMeasure.MONEY)
              // chooseFromModal?.currentOperator?.measure === ProviderMeasure.MONEY
                ? (
                <div className={`${s.operator__create_id} ${s.operator__create_amount}`}>
                  <FormikTextFieldNumber
                    name={`singleId${chooseFromModal.providerId}${chooseFromModal?.employeeId}`
                      ?.split('.')
                      .join('')}
                    placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNTPLACEHOLDER')}
                    value={isDefaultAmountFromModal ? chooseFromModal?.amount || '' : enterAmount || ''}
                    defaultValue={chooseFromModal?.amount || ''}
                    disabled={!currentOperatorActiveStatus}
                    suppressHydrationWarning
                    min={chooseFromModal.currentOperator.minAmount}
                    max={chooseFromModal.currentOperator.maxAmount}
                    // onKeyPress={(event) => {
                    //   if (!/[0-9]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNT')}</p>}
                    onValueChange={(e: any, name: any) =>
                      handleInputValue(e, {
                        ...chooseFromModal,
                        amount: e,
                        value: e,
                        singleValue: e,
                        label: e,
                        description: `${chooseFromModal?.currentOperator?.title} ${t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.DESCRIPTIONADDITIONALTEXT')}`
                      })
                    }
                  />
                </div>
                ) : null}

              {/* Edit MONEY measure field */}
              {(!scenarios?.length ? currentOperatorData?.provider?.measure === ProviderMeasure.MONEY : defaultScenario?.type === KindOfScenarios.AMOUNT && currentOperatorData?.provider?.measure === ProviderMeasure.MONEY)
              // currentOperatorData?.provider?.measure === ProviderMeasure.MONEY
                ? (
                <div className={`${s.operator__create_id} ${s.operator__create_amount}`}>
                  <FormikTextFieldNumber
                    name={`singleId${currentOperatorData.provider?.id}${currentOperatorData?.employee?.id}`
                      ?.split('.')
                      .join('')}
                    placeholder={t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNTPLACEHOLDER')}
                    value={isDefaultAmountFromModal ? currentOperatorData?.defaultPrice || '' : enterAmountEdit || ''}
                    defaultValue={currentOperatorData?.defaultPrice || ''}
                    disabled={!currentOperatorActiveStatus}
                    suppressHydrationWarning
                    min={currentOperatorData.provider.minAmount}
                    max={currentOperatorData.provider.maxAmount}
                    // onKeyPress={(event) => {
                    //   if (!/[0-9]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    label={<p className={s.operator__create_label}>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.AMOUNT')}</p>}
                    onValueChange={(e: any, name: any) =>
                      handleInputValue(e, {
                        ...currentOperatorData?.provider,
                        amount: e,
                        value: e,
                        singleValue: e,
                        label: e,
                        description: `${currentOperatorData?.provider?.title} ${t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.FORM.DESCRIPTIONADDITIONALTEXT')}`
                      })
                    }
                  />
                </div>
                ) : null}
            </>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={currentOperatorData?.uniqueId ? onEditSubmit : onSubmit}
                value="Submit employee add"
                disabled={!currentOperatorActiveStatus}
                formAction="/submit/a"
                type="submit"
              >
                <p>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.BUTTONS.SUBMIT')}</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                className={[s.operator_cancel].join(' ')}
                onClick={() => hideModal(false)}
              >
                <p>{t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.BUTTONS.CANCEL')}</p>
              </motion.button>

              { !chooseFromModal?.currentOperator?.id ?
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                  type="button"
                  onClick={handleOperatorActive}
                  className={[currentOperatorActiveStatus ? s.operator_deactivated : s.operator_activated ].join(' ')}
                >
                  <p>
                    { currentOperatorActiveStatus ?
                      t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.BUTTONS.DEACTIVATE')
                      :
                      t<string>('MAIN.NEWPAYMENT.MODALADDITIONALOPERATORSNEWPAYMENT.BUTTONS.ACTIVATE')
                    }

                  </p>
                </motion.button>
                :
                null
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModalAdditionOperatorNewPayment = observer(ModalAdditionOperatorNewPaymentView);
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { ReactElement } from 'react';
import { ActionMeta, OnChangeValue, Props } from 'react-select';
import { useField, useFormikContext } from 'formik';
import SelectField from './formikSelect';
import { FromikSelectProps } from './types';
import s from './index.module.scss';

const FormikSelect = (props: any & FromikSelectProps): ReactElement => {
  const { label = '', name = '', onChange, onMenuOpen, children, changePhonePriority, isOptionDisabledProvider, titleStyles, ...other } = props;
  const [field] = useField(name);
  const form = useFormikContext();

  const handleChange = (newValue: OnChangeValue<any, false>, actionMeta: ActionMeta<any>) => {
    !changePhonePriority ? form.setFieldValue(name, newValue) : null;

    if (onChange) {
      onChange(newValue, actionMeta);
    }
  };
  
  const handleMenuOpen = (newValue: OnChangeValue<any, false>, actionMeta: ActionMeta<any>) => {
    if (onMenuOpen) {
      onMenuOpen(newValue, actionMeta);
    }
  };
  
  const inputValue = field.value || null;
  const evalProps = {
    ...field,
    ...other,
    value: inputValue,
    id: name,
    isOptionDisabledProvider,

    onChange: handleChange,
    onMenuOpen: handleMenuOpen
  };

  return (
    <>
      {label && (
        <label className={s.label} htmlFor={name}>
          <h4 style={{ ...titleStyles }} className={s.label__title}>{label}</h4>
        </label>
      )}
      <SelectField {...evalProps} >
        {children}
      </SelectField>
    </>
  );
};

export default FormikSelect;

import { ModalEditGroup } from './editGroupModal';
import { ModalSendReport } from './sendReportModal';
import { ModalEditHistory } from './editHistoryModal';
import { ModalEditEmployee } from './editEmployeeModal';
import { ModalMoveToGroup } from './moveToGroupModal/index';
import { ModalCreateGroup } from './createGroupModal/index';
import { ModalImportEmployees } from './importEmployeesModal';
import { ModalImportWindowConfirm } from './importWindowConfirm';
import { ModalEditPhonePriority } from './editPhonePriorityModal';
import { ModalMoveToGroupDetail } from './moveToGroupDetailModal';
import { ModalCreateGroupDetail } from './createGroupDetailModal';
import { ModalCalendarWindowChoose } from './calendarWindowChoose';
import { ModalChangeEmployeeStatus } from './changeEmployeeStatus';
import { ModalAddEmployeesToGroup } from './addEmployeesToGroupModal';
import { ModalCreateOperatorId } from './createOperatorIdModal/index';
import { ModalRemoveOperatorFromGroup } from './removeOperatorFromGroup';
import { ModalPaymentWindowConsent } from './paymentWindowConsent/index';
import { ModalAddEmployeesToPayment } from './addEmployeesToPaymentModal';
import { ModalCreateGroupWarning } from './createGroupWarningWindow/index';
import { ModalMoveToGroupWarning } from './moveToGroupWarningWindow/index';
import { ModalAdditionOperatorFull } from './additionFullOperatorModal/index';
import { ModalRemoveOperatorFromEmployee } from './removeOperatorFromEmployee';
import { ModalGroupRemoveConfirmation } from './groupRemoveConfirmation/index';
import { ModalPhoneRemoveConfirmation } from './phoneRemoveConfirmation/index';
import { ModalRemoveEmployeeFromGroup } from './removeEmployeeFromGroup/index';
import { ModalRemoveAllEmployeeFromGroup } from './removeAllEmployeeFromGroup';
import { ModalAdditionAllEmployeeToGroup } from './additionAllEmployeesToGroup';
import { ModalAdditionEmployeeToGroup } from './additionEmployeesToGroup/index';
import { ModalCreateGroupDetailWarning } from './createGroupDetailWarningWindow';
import { ModalMoveToGroupDetailWarning } from './moveToGroupDetailWarningWindow';
import { ModalAddEmployeesToCreateGroup } from './addEmployeesToCreateGroupModal';
import { ModalEmployeeActivateConfirmation } from './employeeActivateConfirmation';
import { ModalRemoveOperatorFromCreatePage } from './removeOperatorFromCreatePage';
import { ModalEmployeeRemoveConfirmation } from './employeeRemoveConfirmation/index';
import { ModalEmployeeCreateOperatorsGroupChange } from './createEmployeeOperatorsGroupChange/index';

export const Modals = {
  ModalEditGroup,
  ModalSendReport,
  ModalEditHistory,
  ModalCreateGroup,
  ModalMoveToGroup,
  ModalEditEmployee,
  ModalImportEmployees,
  ModalCreateOperatorId,
  ModalCreateGroupDetail,
  ModalMoveToGroupDetail,
  ModalEditPhonePriority,
  ModalMoveToGroupWarning,
  ModalCreateGroupWarning,
  ModalAddEmployeesToGroup,
  ModalImportWindowConfirm,
  ModalPaymentWindowConsent,
  ModalAdditionOperatorFull,
  ModalChangeEmployeeStatus,
  ModalCalendarWindowChoose,
  ModalAddEmployeesToPayment,
  ModalPhoneRemoveConfirmation,
  ModalAdditionEmployeeToGroup,
  ModalRemoveOperatorFromGroup,
  ModalGroupRemoveConfirmation,
  ModalRemoveEmployeeFromGroup,
  ModalMoveToGroupDetailWarning,
  ModalCreateGroupDetailWarning,
  ModalAddEmployeesToCreateGroup,
  ModalAdditionAllEmployeeToGroup,
  ModalRemoveOperatorFromEmployee,
  ModalRemoveAllEmployeeFromGroup,
  ModalEmployeeRemoveConfirmation,
  ModalEmployeeActivateConfirmation,
  ModalRemoveOperatorFromCreatePage,
  ModalEmployeeCreateOperatorsGroupChange,
};

import React, { memo, ReactChild } from 'react';

interface TabComponentProps {
  children: ReactChild | any;
  index: number;
}

const TabView = ({ children, index }: TabComponentProps) => <>{children}</>;

export const Tab = memo(TabView);

interface TabsComponentProps {
  children: ReactChild[];
  activeTab: number;
}

const TabsView = ({ children, activeTab }: TabsComponentProps) => {
  const renderTab = children.filter((el: any) => el.props.index === activeTab);

  return <>{renderTab}</>;
};

export const Tabs = memo(TabsView);

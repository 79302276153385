/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, ChangeEvent, useEffect } from 'react';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { InfoSVG } from 'assets/icons/info';
import { GroupSVG } from 'assets/icons/group';
import { CrossSVG } from 'assets/icons/cross';
import { motion, Variants } from 'framer-motion';
import FormikRadio from 'components/form/fields/radio';
import FormikContainer from 'components/form/containers';
import { GroupUnset } from 'common/constants/groupModals';
import { ColorsAccent, ColorsBorder } from 'common/colors';
import FormikCheckbox from 'components/form/fields/checkbox';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import FormikSelectStatic from 'components/form/fields/selectStatic';
import s from './index.module.scss';

export interface ModalMoveToGroupViewProps {
  hideModal: () => void;
  showModal: (Modal: any, modalProps?: any) => void;
  setIsModalOpen: (open: any) => void;
  setIsCheck: (arr: any) => void;
  setIsCheckGroup: (arr: any) => void;
  createEmplyee?: boolean;
  isCheckGroup?: any[];
  isCheck?: any[];
  isModalOpen?: any;
}
const ModalMoveToGroupView = (props: ModalMoveToGroupViewProps) => {
  const {
    hideModal,
    showModal,
    setIsCheckGroup,
    setIsCheck,
    createEmplyee,
    isCheckGroup,
    isCheck,
    isModalOpen,
    setIsModalOpen
  } = props;
  const { groups, auth, employees } = useStores();
  const [activeRadio, setActiveRadio] = useState<any>();
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const { getGroupsModel } = groups;
  const { getUpdateEmployeeAsyncState } = employees;
  const [choosenGroup, setChoosenGroup] = useState<any>();
  const isUpdateEmployeeLoadingSuccess = getUpdateEmployeeAsyncState.getLoadingState;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSaveValues, setIsSaveValues] = useState(false);
  const removeDuplicates = (data: any, key: any) => [
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];

  const onSubmit = (values: any) => {
    showModal(Modals.ModalMoveToGroupWarning, {
      isCheckGroup,
      isSaveValues,
      choosenGroup,
      setIsCheck,
      setIsCheckGroup
    });
  };

  const handleGroup = (e: ChangeEvent<any>, group: any) => {

    if (group?.id === GroupUnset.NONE) {
      group.id = null;
      setActiveRadio(e.target);
    }
    setChoosenGroup(group);

    if (e.target.id === group.id && !group?.hideGroup) {
      setActiveRadio(e.target);
    }
  };

  const itemVariants: Variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 300, damping: 24 }
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
  };

  useEffect(() => {
    groups.getGroupsAction({ clientId: getAuthMe.id });
  }, []);

  useEffect(() => {
    if (isUpdateEmployeeLoadingSuccess === Loading.success) {
      employees.getEmployeesAction({
        id: getAuthMe?.id
      });
      hideModal();
    }

    return () => {
      getUpdateEmployeeAsyncState.reset();
    };
  }, [isUpdateEmployeeLoadingSuccess]);
  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <div className={s.header__icon}>
              <GroupSVG />
            </div>
            <h2 className={s.title}>Group</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG />
          </div>
        </div>

        <div className={s.description}>
          <p className={s.description__text}>
            If you want to move this contact to another group, then choose a name from the existing
            groups or create a new one group.
          </p>

          { isCheck ? (isCheck.length > 1 ?
            <div className={s.description__info_wrapper}>
              <div className={s.description__info_icon}>
                <InfoSVG />
              </div>
              <p className={s.description__text}>
                When transferring 2 or more employees, to single group in which the employee consists isn't possible
              </p>
            </div>
            :
            null) : null
          }
        </div>

        <div className={s.group}>
          <FormikContainer initialValues={{}} onSubmit={onSubmit}>
            {() => (
              <>
                <div className={s.group__choose}>
                  <FormikSelectStatic
                    setIsOpenModal={setIsOpenModal}
                    isOpenModal={choosenGroup?.hideGroup ? true : isOpenModal}
                    inputWrapperStyles={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      margin: '0 1.3125rem'
                    }}
                    inputStyles={{
                      cursor: 'pointer',
                      border: `1px solid ${ColorsBorder.borderPrimary}`,
                      borderRadius: '0.5rem',
                      height: '2.75rem',
                      width: '100%',
                      textAlign: 'left',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingLeft: '1.125rem'
                    }}
                    name="group"
                    type="text"
                    options={
                      <motion.ul
                        className={s.group__menu}
                        variants={{
                          open: {
                            height: '100%',
                            transition: {
                              type: 'spring',
                              bounce: 0,
                              duration: 0.7,
                              delayChildren: 0.3,
                              staggerChildren: 0.05
                            }
                          },
                          closed: {
                            height: '0',
                            transition: {
                              type: 'spring',
                              bounce: 0,
                              duration: 0.3
                            }
                          }
                        }}
                      >
                        {[
                          ...removeDuplicates(
                            [ { id: GroupUnset.NONE, title: GroupUnset.NONE, parent_id: '', description: '', membersCount: 0, client: { id: '',
                              email: '',
                              name: '',
                              picture: '',
                              phone: '', }, employees: [], groupsAccounts: [] }, ...toJS(getGroupsModel.getGroupList)]
                              ?.map(
                                (groupItem: any) =>
                                  isCheckGroup?.map((employeeItem: any) => {
                                    if (groupItem?.id === (employeeItem?.group?.id || GroupUnset.NONE)) {
                                      groupItem.hideGroup = true;
                                    }
                                    return groupItem;
                                  }),
                                []
                              )
                              ?.flat(Infinity),
                            (key: any) => key?.id
                          )].map((group: any, index: number) => (
                          <FormikRadio
                            onClick={(e: ChangeEvent<any>) => handleGroup(e, toJS(group))}
                            name={'group'}
                            hideGroup={group?.hideGroup}
                            key={group.id}
                            id={group.id}
                            value={group.title}
                            type="radio"
                            label={
                              <motion.li
                                className={`${s.choose__option} ${
                                  group?.hideGroup ? s.choose__option_hide : ''
                                }`}
                                variants={itemVariants}
                              >
                                <div
                                  className={`${s.radio} ${
                                    group.id === activeRadio?.id && !group?.hideGroup ? s.radio_active : ''
                                  }`}
                                />

                                <div
                                  className={`${s.choose__description} ${
                                    group?.hideGroup ? s.choose__description_hide : ''
                                  }`}
                                >
                                  { group?.id !== GroupUnset.NONE ?
                                  <div className={s.choose__icon}>
                                    <GroupSVG />
                                  </div>
                                    :
                                    null }
                                  
                                  <p className={s.choose__text}>{group.title}</p>
                                </div>
                              </motion.li>
                            }
                          />
                        ))}
                      </motion.ul>
                    }
                    placeholder="Choose group"
                  >
                    <motion.div
                      className={s.delimiter}
                      variants={{
                        open: {
                          height: '1px',
                          margin: '1rem 1.25rem',
                          transition: { type: 'spring', stiffness: 300, damping: 24 }
                        },
                        closed: {
                          transition: { type: 'spring', stiffness: 300, damping: 24 }
                        }
                      }}
                    />
                  </FormikSelectStatic>
                </div>

                <div className={s.save__wrapper}>
                  <FormikCheckbox
                    labelStyles={{
                      borderRadius: '4px',
                      backgroundColor: ColorsAccent.accentYellow,
                      marginRight: '0.3rem'
                    }}
                    label={'Move with save values'}
                    title={'Move with save values'}
                    name="save"
                    id="save"
                    onChange={() => setIsSaveValues(!isSaveValues)}
                    isChecked={isSaveValues}
                    groupCheckbox
                  />
                </div>
                <div className={s.buttons}>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                    type="submit"
                  >
                    <p>Move and save</p>
                  </motion.button>
                  {!createEmplyee ? (
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() =>
                        showModal(Modals.ModalCreateGroup, {
                          setIsModalOpen,
                          isModalOpen,
                          isCheckGroup,
                          isCheck
                        })
                      }
                    >
                      <p>Create+</p>
                    </motion.button>
                  ) : null}
                </div>
              </>
            )}
          </FormikContainer>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalMoveToGroup = observer(ModalMoveToGroupView);
import React, { ReactChild, ReactChildren, useReducer } from 'react';
import { ModalContext, modalReducer } from 'hooks/useModal.hook';
import { hideModal, openModal } from 'common/constants/modal/index';
import { ModalRoot } from 'containers/modal/modalRoot';

export interface ModalProviderProps {
  children?: ReactChild | ReactChildren | ReactChild[] | React.ReactNode;
}

export const ModalProvider = (props: ModalProviderProps) => {
  const { children } = props;

  const [state, dispatch] = useReducer(
    modalReducer,
    {
      Modal: null,
      modalProps: {},
      showModal: (Modal: any, modalProps = {}) => {
        dispatch({ type: openModal, Modal, modalProps });
      },
      hideModal: () => {
        dispatch({ type: hideModal });
      },
    }
  );

  return (
    <ModalContext.Provider value={state}>
      {children}
      <ModalRoot />
    </ModalContext.Provider>
  );
};

/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { MarkSVG } from 'assets/icons/mark';
import { useTranslation } from 'react-i18next';
import { AuthContainer } from 'containers/auth';
import { LogoDarkSVG } from 'assets/icons/logo';
import { ColorsBackground } from 'common/colors';
import { BackLongSVG } from 'assets/icons/backLong';
import { useLocation, useNavigate } from 'react-router';
import FormikContainer from 'components/form/containers';
import FormikTextField from 'components/form/fields/inputs';
import { menuTabsChangePassword } from 'common/constants/auth';
import { IActiveContentState } from 'components/tabs/universalTab/types';
import s from './index.module.scss';
import { Loading } from '../../../../store/types';
import { useStores } from '../../../../store';
import { CreateResetPassword } from '../../../../models/auth/types';

export interface LocationState {
  tab: number;
}

const ChangePasswordView = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { auth } = useStores();
  const state = location.state as LocationState;
  const tab = state?.tab ? menuTabsChangePassword(t)[state.tab] : menuTabsChangePassword(t)[0];
  const [CheckCode, setCheckCode] = useState<boolean>(false);
  const [activeContent, setActiveContent] = useState<IActiveContentState>(tab);
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState<any>(59);
  const [minutes, setMinutes] = useState<any>(4);
  const [isDisabled, setIsDisabled] = useState<any>(true);
  const isCreateResetPasswordCodeLoading = auth.getResetPasswordCodeAsyncState.getLoadingState === Loading.success;
  const [resetData, setResetData] = useState<any>();
  const isAuthMeLoadingSuccess = auth.getAuthMeAsyncState.getLoadingState;
  const handleClick = (elements: any, resetForm: any) => {
    setActiveContent({ ...elements });
    resetForm();
  };

  const onSubmit = (values: any) => {
    const data = { code: values.code };
    auth.createResetPasswordCodeAction(data);
  };

  useEffect(() => {
    if (isCreateResetPasswordCodeLoading) {
      navigate('/password-success');
    }

    return () => {
      auth.getResetPasswordCodeAsyncState.reset();
    };
  }, [isCreateResetPasswordCodeLoading]);

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required("It's required field")
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(4, 'Must be exactly 4 digits')
      .max(4, 'Must be exactly 4 digits')
  });

  const handleCheckCode = (e: any) => {
    setCheckCode(false);
    if ((e.target.value.length === 4 && +e.target.value) || e.target.value === '0000') {
      setCheckCode(true);
    }
  };

  // useEffect(() => setActiveContent(tab), [tab]);
  useEffect(() => {
    const timeInterval = setInterval(() => {
      setSeconds(isDisabled ? seconds - 1 : 0);

      if (seconds === 1 && minutes === 0) {
        setIsDisabled(false);
      }

      if (seconds === 0 && isDisabled) {
        setSeconds(59);
      } else {
        clearInterval(timeInterval);
      }

      if (seconds === 0 && minutes !== 0) {
        setMinutes(minutes - 1);
      }
    }, 1000);

    return () => clearInterval(timeInterval);
  }, [seconds, minutes, isDisabled]);

  const resetTimer = () => {
    const resetDataStorage = JSON.parse(window.sessionStorage.getItem('resetData')!);
    auth.createResetPasswordAction(resetDataStorage);
    setSeconds(59);
    setMinutes(4);
    setIsDisabled(true);
  };

  useEffect(() => {
    if (sessionStorage.getItem('resetData')) {
      setResetData(JSON.parse(window.sessionStorage.getItem('resetData')!));
    } else {
      navigate('/password-forgot');
    }

    return () => {
      sessionStorage.removeItem('resetData');
    };
  }, [sessionStorage.getItem('resetData')]);

  useEffect(() => {
    localStorage.removeItem('authMe');

    return () => localStorage.removeItem('authMe');
  }, [isAuthMeLoadingSuccess]);

  return (
    <AuthContainer>
      <div className={s.wrapper}>
        <motion.div
          variants={{
            initial: { opacity: 0, scale: 0.8 },
            animate: { opacity: 1, scale: 1 },
            exit: { opacity: 0, scale: 0.8 }
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5, type: 'spring', damping: 17 }}
          className={s.wrapper__inner}
        >
          <FormikContainer
            initialValues={{ code: '' }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ resetForm, errors, isValid, dirty, values }) => (
              <div className={s.wrapper__inner__container}>
                <div className={s.wrapper__logo}>
                  <LogoDarkSVG />
                </div>
                <div className={s.wrapper__inner__title}>
                  <h1> {t<string>('AUTH.CHANGEPASSWORD.TITLE')}</h1>
                </div>
                <div className={s.wrapper__inner__description}>{t<string>('AUTH.CHANGEPASSWORD.DESCRIPTION')}</div>
                <div className={s.form__inputs}>
                  <div
                    className={`${s.input} ${s.input__code} ${
                      errors.code || errors.touched ? s.border_alert : ''
                    }`}
                  >
                    <FormikTextField
                      maxLength={4}
                      placeholder={t<string>('AUTH.CHANGEPASSWORD.FORM.CODEPLACEHOLDER')}
                      label={<p className={s.input__label}>{t<string>('AUTH.CHANGEPASSWORD.FORM.CODE')}</p>}
                      name="code"
                      onChange={(e: any) => handleCheckCode(e)}
                    >
                      <div
                        className={`${s.input__icon} ${s.input__icon__mark} ${
                          CheckCode ? s.email__fill : ''
                        }`}
                      >
                        <MarkSVG />
                      </div>
                    </FormikTextField>
                  </div>

                  <div className={s.buttons}>
                    <motion.button
                       whileTap={
                        (isValid && dirty)
                          ? { scale: 0.99 }
                          : {}
                      }
                      whileHover={
                        (isValid && dirty)
                          ? { scale: 1.02 }
                          : {}
                      }
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      className={s.submit__button}
                      disabled={values.code.length < 4}
                      type="submit"
                    >
                      <p>{t<string>('AUTH.CHANGEPASSWORD.FORM.SUBMIT')}</p>
                    </motion.button>

                    <motion.button
                      whileTap={{ scale: 0.99 }}
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      onClick={() => navigate(-1)}
                      className={s.back__button}
                      type={'button'}
                    >
                      <BackLongSVG />
                      <p>{t<string>('AUTH.CHANGEPASSWORD.FORM.BACKBUTTON')}</p>
                    </motion.button>

                    <motion.button
                      whileTap={!isDisabled ? { scale: 0.99 } : {}}
                      whileHover={!isDisabled ? { scale: 1.02 } : {}}
                      transition={
                        !isDisabled ? { type: 'spring', stiffness: 400, damping: 17 } : {}
                      }
                      style={{
                        backgroundColor: !isDisabled
                          ? ''
                          : ColorsBackground.backgroundGrayAlmostWhite
                      }}
                      className={s.back__button}
                      type={'button'}
                      onClick={() => resetTimer()}
                      disabled={isDisabled}
                    >
                      <p>{t<string>('AUTH.CHANGEPASSWORD.FORM.SUBMITCODEAGAIN')}</p>
                    </motion.button>
                    <div className={s.resend__code}>
                      <div className={s.resend__timer}>
                        <p>
                        {t<string>('AUTH.CHANGEPASSWORD.FORM.REPEATTEXT')}
                          <span>
                            0{minutes}:{seconds.toString().length !== 1 ? seconds : `0${seconds}`}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </FormikContainer>
        </motion.div>
        <div className={s.circle} />
      </div>
    </AuthContainer>
  );
};

export const ChangePassword = observer(ChangePasswordView);
import React from 'react';

export const WarningSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="20"
    height="20"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    className=""
  >
    <g transform="matrix(1.2300000000000002,0,0,1.2300000000000002,-3.6800000000000033,-3.6800000000000033)">
      <path
        d="M17 10v9a1 1 0 0 1-2 0v-9a1 1 0 0 1 2 0zM17 22.25v.5a1 1 0 0 1-2 0v-.5a1 1 0 0 1 2 0z"
        fill="#000000"
        data-original="#000000"
        className=""
       />
      <path
        d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13 13-5.832 13-13S23.168 3 16 3zm0 24C9.935 27 5 22.065 5 16S9.935 5 16 5s11 4.935 11 11-4.935 11-11 11z"
        fill="#000000"
        data-original="#000000"
        className=""
       />
    </g>
  </svg>
);

import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';

abstract class PriceListAPI {
  public static createPriceList = (data: any): Promise<AxiosResponse> =>
    RestAPI.post('/api/price-lists', data);

  public static getPriceList = (): Promise<AxiosResponse> => RestAPI.get('/api/price-lists');

  public static getPriceListDetail = (data: any): Promise<AxiosResponse> =>
    RestAPI.get(`/api/price-lists/provider/${data.id}`, { quantity: 9999 });

  public static updatePriceListDetail = (data: any): Promise<AxiosResponse> =>
    RestAPI.put(`/api/price-lists/${data.id}`, data);

  public static deletePriceListDetail = (data: any): Promise<AxiosResponse> =>
    RestAPI.delete(`/api/price-lists/${data.id}`, data);
}

export default PriceListAPI;

/* eslint-disable no-param-reassign */
import { makeAutoObservable, toJS } from 'mobx';
import { PriceList, PriceListCreate, PriceListModel, PriceListsDetail, PriceListsMeta } from './types';

export class PriceListEntity implements PriceListModel {
  private priceList: PriceList[] = [];

  private priceListDetail: PriceList[] = [];

  private priceListDetailMeta: PriceListsMeta = {
    total: 0
  };

  setPriceList(priceList: PriceList[]) {
    this.priceList = priceList.map(priceListItem => {
      priceListItem.description = priceListItem.label;
      return priceListItem;
    });
  }

  get getPriceList(): PriceList[] {
    return this.priceList;
  }

  setPriceListDetail(priceListDetail: PriceList[]) {
    this.priceListDetail = priceListDetail.map(priceList => {
      priceList.description = priceList.label;
      return priceList;
    });
  }

  get getPriceListDetail(): PriceList[] {
    return this.priceListDetail;
  }

  setPriceListDetailMeta(meta: PriceListsMeta) {
    this.priceListDetailMeta = meta;
  }

  get getPriceListDetailMeta(): PriceListsMeta {
    return this.priceListDetailMeta;
  }

  resetPriceListDetail() {
    this.priceListDetail = [];
  }

  constructor() {
    makeAutoObservable(this);
  }
}

import RestAPI from 'api/rest';
import { AxiosResponse } from 'axios';

abstract class UsersAPI {
  public static createUpdateUserPassword = (data: any): Promise<AxiosResponse> =>
    RestAPI.post('/api/users/update-password', data);

  public static getUsers = (data: any): Promise<AxiosResponse> =>
    RestAPI.get('/api/users');

  public static getDetailTransactions = (data: any): Promise<AxiosResponse> =>
    RestAPI.delete('/api/users/delete-user', data);
}

export default UsersAPI;

/* eslint-disable jsx-a11y/no-static-element-interactions,no-nested-ternary,no-param-reassign,array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { Field } from 'formik';
import { motion } from 'framer-motion';
import MaskedInput from 'react-text-mask';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { CreateEmployeePhones, EmployeesModel } from 'models/employees/types';
import FormikContainer from 'components/form/containers';
import { EmployeesController } from 'controllers/employees';
import { checkValidateFieldsValiants, KindOfFieldEmployees } from 'common/constants/modal';
import FormikTextField from 'components/form/fields/inputs';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import FormikSelectCountries from 'components/form/fields/selectCountries';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  modalRefEl: any;
  getEmployeesModel: EmployeesModel;
  editKindOfField: string;
  employees?: EmployeesController;
}

const ModalEditEmployeeView = (props: ModalCreateGroupViewProps) => {
  const {
    hideModal,
    employees,
    modalRefEl,
    getEmployeesModel,
    editKindOfField
  } = props;

  const [name, setName] = useState(getEmployeesModel.getEmployeesDetail.name);
  const [surname, setSurname] = useState(getEmployeesModel.getEmployeesDetail.surname);

  const [editedFavoritePhone, setEditedFavoritePhone] = useState<string>('');
  const [createPhoneField, setCreatePhoneField] = useState([{
    phoneName: 'addPhone',
    id: 'addPhone',
    additionalPhone: true,
    favorite: false
  }]);

  const validateSchema = Yup.object().shape((checkValidateFieldsValiants(editKindOfField, createPhoneField)));

  const changeGroupCheck = (values: any) => {

    const phones: any = createPhoneField
      .map((item: any) => ({ phone: item?.phone, favorite: item?.favorite }))
      .flat(1);

    const combinePhones = [
      ...toJS(getEmployeesModel.getEmployeesDetail)?.phones.map((phone: any) => ({
        phone: phone?.phone,
        favorite: phone?.favorite
      })),
      ...phones?.map((item: any) =>
        item?.phone?.length > 10
          ? {
            phone: item?.phone?.slice(0, item?.phone?.length - 1),
            favorite: item?.favorite
          }
          : item
      )
    ].map((phone: CreateEmployeePhones) => {
      if (phone.favorite && editedFavoritePhone) {
        phone.phone = editedFavoritePhone;
      }

      return phone;
    } ).filter(phone => phone?.phone);

    const data: any = {
      employeeId: toJS(getEmployeesModel.getEmployeesDetail)?.id,
      name: values?.name,
      phones:
      combinePhones.length === 1 ? combinePhones.map(phone => {
        phone.favorite = true;
        return phone;
      }) : combinePhones
      ,
      surname: values?.surname,
      groupId: toJS(getEmployeesModel.getEmployeesDetail)?.group?.id,
      accounts: toJS(getEmployeesModel.getEmployeesDetail)?.accounts?.reduce(
        (acc: any, accountItem: any) => {
          acc.push({
            defaultPrice: accountItem.defaultPriceList ? null : accountItem.defaultPrice,
            defaultPriceListId: accountItem.defaultPriceList
              ? accountItem.defaultPriceList?.id
              : null,
            defaultScenario: accountItem?.defaultScenario?.id ? {
              label: accountItem?.defaultScenario?.label,
              id: accountItem?.defaultScenario?.id,
              createAt: accountItem?.defaultScenario?.createAt,
              deletedAt: accountItem?.defaultScenario?.deletedAt,
              type: accountItem?.defaultScenario?.type
            } : null,
            defaultValueEmployee: accountItem?.defaultValueEmployee || false,
            note: null,
            providerId: accountItem.provider?.id,
            employeeId: toJS(getEmployeesModel.getEmployeesDetail).id,
            uniqueId: accountItem.uniqueId
          });

          return acc;
        },
        []
      )
    };

    employees?.updateEmployeeAction(data);

    hideModal();
  };

  const checkPhoneInputField = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.replace(/[^\d]/g, '')[0] !== '0') {
      e.target.value = `(0)${e.target.value}`;
    }

    if (e.target.value.replace(/[^\d]/g, '')[0] === '0' && e.target.value[3] === '_') {
      e.target.value = '';
    }

    setCreatePhoneField(
      createPhoneField?.reduce((acc: any, item: any) => {
        if (item.phoneName === e.target.id) {
          acc.push({
            ...item,
            phone:
              e.target.value.replace(/[^\d]/g, '').split(' ').join('').length > 10
                ? e.target.value
                  .replace(/[^\d]/g, '')
                  .split(' ')
                  .join('')
                  .slice(0, e.target.value.replace(/[^\d]/g, '').split(' ').join('').length - 1)
                : e.target.value.replace(/[^\d]/g, '').split(' ').join('')
          });
        } else {
          acc.push({
            ...item
          });
        }

        return acc;
      }, [])
    );

    createPhoneField.map((item: any) => {
      if (item.phoneName === e.target.id) {
        item.phone = e.target.value.replace(/[^\d]/g, '').split(' ').join('');
      }
    });
  };

  const checkPhoneInputFieldEdit = (e: ChangeEvent<HTMLInputElement>) => {

    if (e.target.value.replace(/[^\d]/g, '')[0] !== '0') {
      e.target.value = `(0)${e.target.value}`;
    }

    if (e.target.value.replace(/[^\d]/g, '')[0] === '0' && e.target.value[3] === '_') {
      e.target.value = '';
    }

    setEditedFavoritePhone(e.target.value.replace(/[^\d]/g, '').split(' ').join('').length > 10
      ? e.target.value
        .replace(/[^\d]/g, '')
        .split(' ')
        .join('')
        .slice(0, e.target.value.replace(/[^\d]/g, '').split(' ').join('').length - 1)
      : e.target.value.replace(/[^\d]/g, '').split(' ').join(''));
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeSurname = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSurname(e.target.value);
  };

  useEffect(() => {
    if (editKindOfField === KindOfFieldEmployees.EMPLOYEEPHONE) {
      setEditedFavoritePhone(toJS(getEmployeesModel.getEmployeesDetail)?.phones.filter((phone: CreateEmployeePhones) => phone?.favorite)?.[0]?.phone);
    }
  }, [editKindOfField]);

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            { editKindOfField === KindOfFieldEmployees.EMPLOYEEFULLNAME ? <h2 className={s.title}>Edit full name</h2> : null }
            { editKindOfField === KindOfFieldEmployees.EMPLOYEEPHONE ? <h2 className={s.title}>Edit phone</h2> : null }
            { editKindOfField === KindOfFieldEmployees.EMPLOYEEPHONEADD ? <h2 className={s.title}>Add new phone</h2> : null }
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <FormikContainer
          initialValues={{ editPhone: getEmployeesModel.getEmployeesDetail.phones.filter(phone => phone?.favorite)?.[0]?.phone, name: getEmployeesModel.getEmployeesDetail.name, surname: getEmployeesModel.getEmployeesDetail.surname }}
          onSubmit={changeGroupCheck}
          // enableReinitialize
          validationSchema={validateSchema}
        >
          {({ errors, setFieldValue }) => (
            <div className={s.main}>
              <div className={s.operator}>
                { editKindOfField === KindOfFieldEmployees.EMPLOYEEFULLNAME ?
                  <>
                    <div className={s.operator__create_id}>
                      <FormikTextField
                        name='name'
                        placeholder="Enter name"
                        value={name}
                        className={[ errors.name ? s.input_error : null ].join(' ')}
                        type="text"
                        onChange={(e) => handleChangeName(e)}
                        label={<p className={s.operator__create_label}>Name</p>}
                        errorMessageDisable
                      />
                    </div>
                    <div className={s.operator__create_id}>
                      <FormikTextField
                        name='surname'
                        placeholder="Enter surname"
                        value={surname}
                        className={[ errors.surname ? s.input_error : null ].join(' ')}
                        type="text"
                        onChange={(e) => handleChangeSurname(e)}
                        label={<p className={s.operator__create_label}>Surname</p>}
                        errorMessageDisable
                      />
                    </div>
                  </>
                  :
                  null }

                { editKindOfField === KindOfFieldEmployees.EMPLOYEEPHONE ?
                  <div className={s.operator__create_id}>
                    <div className={s.employee__input__inner}>
                      <div className={`${s.input__countries} ${s.input__countries_edit}`}>
                        <h4 className={s.phone__title}>Contact phone number</h4>
                        <FormikSelectCountries
                          wrapperChildrenStyles={{ display: 'flex', alignItems: 'center' }}
                          key={'editPhone'}
                          name="editPhone"
                        >
                          <>
                            <div
                              className={` ${s.countries__wrapper__input} ${
                                errors.editPhone ? s.border_alert : ''
                              }`}
                            >
                              <Field name={'editPhone'}>
                                {({ field }: any) => (
                                  <MaskedInput
                                    {...field}
                                    onInput={checkPhoneInputFieldEdit}
                                    mask={[
                                      '(',
                                      /\d/,
                                      ')',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      '-',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      '-',
                                      /\d/,
                                      /\d/,
                                      /\d/
                                    ]}
                                    placeholder={'Enter phone number'}
                                    guide
                                    name={'editPhone'}
                                    id={'editPhone'}
                                  />
                                )}
                              </Field>
                            </div>
                          </>
                        </FormikSelectCountries>

                      </div>
                    </div>
                  </div>
                  :
                  null
                }

                { editKindOfField === KindOfFieldEmployees.EMPLOYEEPHONEADD ?
                  <div className={s.operator__create_id}>
                    <div className={s.employee__input__inner}>
                      <div className={`${s.input__countries} ${s.input__countries_edit}`}>
                        <h4 className={s.phone__title}>Contact phone number</h4>
                        {createPhoneField.map((content: any, index: any) => (
                        <FormikSelectCountries
                          wrapperChildrenStyles={{ display: 'flex', alignItems: 'center' }}
                          key={content.phoneName}
                          name="addPhone"
                        >
                          <>
                            <div
                              className={` ${s.countries__wrapper__input} ${
                                errors[content.phoneName] ? s.border_alert : ''
                              }`}
                            >
                              <Field name={'addPhone'}>
                                {({ field }: any) => (
                                  <MaskedInput
                                    {...field}
                                    onInput={checkPhoneInputField}
                                    mask={[
                                      '(',
                                      /\d/,
                                      ')',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      '-',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      '-',
                                      /\d/,
                                      /\d/,
                                      /\d/
                                    ]}
                                    placeholder={'Enter phone number'}
                                    guide
                                    name={content.phoneName}
                                    id={content.phoneName}
                                  />
                                )}
                              </Field>
                            </div>
                          </>
                        </FormikSelectCountries>
                        ))}

                      </div>
                    </div>
                  </div>
                  :
                  null
                }


                <div className={s.employee}>
                  <div className={s.buttons}>
                    <motion.button
                      type='submit'
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                    >
                      <p>Save</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>Cancel</p>
                    </motion.button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </FormikContainer>
      </div>
    </ModalBackdrop>
  );
};

export const ModalEditEmployee = observer(ModalEditEmployeeView);

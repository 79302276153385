/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { NavigateFunction } from 'react-router';
import FormikContainer from 'components/form/containers';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import s from './index.module.scss';

export interface IModalImportWindowConfirmViewProps {
  hideModal: () => void;
  importFile: File;
  navigate: NavigateFunction;
}

const ModalImportWindowConfirmView = (props: IModalImportWindowConfirmViewProps) => {
  const { hideModal, importFile, navigate } = props;
  const onSubmit = () => {
    if (importFile.type === 'text/csv') {
      navigate('/employees', { state: { importFile } });
      window.sessionStorage.removeItem('isCheckGroup');
      window.sessionStorage.removeItem('isCheck');
      window.sessionStorage.removeItem('isCheckAll');
    } else {
      toast.error('Only allowed to use files with .csv extension');
    }
    hideModal();
  };

  return (
    <ModalBackdrop modalWrapperStyles={{ padding: '0 1rem' }} hideModal={hideModal}>
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Warning</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>
            <p className={s.description__text}>
              Do you confirm the import current .csv file for create a new payment ?
            </p>
          </div>

          <div className={s.employee}>
            <FormikContainer initialValues={{}} onSubmit={onSubmit}>
              {() => (
                <>
                  <div className={s.buttons}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="submit"
                    >
                      <p>Yes</p>
                    </motion.button>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => hideModal()}
                    >
                      <p>No</p>
                    </motion.button>
                  </div>
                </>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalImportWindowConfirm = observer(ModalImportWindowConfirmView);

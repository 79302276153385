import React from 'react';
import { TFunction } from 'react-i18next';
import { ListSVG } from 'assets/icons/list';
import { DropSVG } from 'assets/icons/drop';
import { GroupsSVG } from 'assets/icons/groups';
import { HistorySVG } from 'assets/icons/history';
import { MoreFunctionsSVG } from 'assets/icons/moreFunctions';

export const menuTabsListOfClients = (t: TFunction<'translation', undefined>) => [
  {
    index: 0,
    icon: <ListSVG />,
    title: t('MAIN.HEADER.MENU.EMPLOYEES'),
    tab: 'listOfEmployees',
    path: '/employees'
  },
  {
    index: 1,
    icon: <HistorySVG />,
    title: t('MAIN.HEADER.MENU.HISTORY'),
    tab: 'paymentHistory',
    path: '/history'
  },
  {
    index: 2,
    icon: <GroupsSVG/>,
    title: t('MAIN.HEADER.MENU.GROUPS'),
    tab: 'groups',
    path: '/groups',
    specificIcon: true
  },
  {
    index: 3,
    icon: <MoreFunctionsSVG/>,
    title: t('MAIN.HEADER.MENU.MORE'),
    tab: 'functions',
    path: '/functions',
    defaultIcon: true
  },
];

export const navigatePath = [
  'employees', 'payment', 'history'
];

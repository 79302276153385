import React, { createContext, useContext } from 'react';

import { AsyncUtil } from 'store/asyncUtil';

import { AuthEntity } from 'models/auth';
import { AuthController } from 'controllers/auth';

import { EmployeesEntity } from 'models/employees';
import { EmployeesController } from 'controllers/employees';

import { ProvidersEntity } from 'models/providers';
import { ProvidersController } from 'controllers/providers';

import { GroupsEntity } from 'models/groups';
import { GroupsController } from 'controllers/groups';

import { PriceListEntity } from 'models/priceList';
import { PriceListController } from 'controllers/priceList';

import { PaymentsEntity } from 'models/payments';
import { PaymentsController } from 'controllers/payments';

import { AccountsEntity } from 'models/accounts';
import { AccountsController } from 'controllers/accounts';

import { BalanceEntity } from 'models/balance';
import { BalanceController } from 'controllers/balance';

export const storesContext = createContext({
  auth: new AuthController(AuthEntity, AsyncUtil),
  employees: new EmployeesController(EmployeesEntity, AsyncUtil),
  providers: new ProvidersController(ProvidersEntity, AsyncUtil),
  groups: new GroupsController(GroupsEntity, AsyncUtil),
  priceList: new PriceListController(PriceListEntity, AsyncUtil),
  payments: new PaymentsController(PaymentsEntity, AsyncUtil),
  accounts: new AccountsController(AccountsEntity, AsyncUtil),
  balance: new BalanceController(BalanceEntity, AsyncUtil),
});

export const useStores = () => useContext(storesContext);

/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toJS } from 'mobx';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { AuthMe } from 'models/auth/types';
import { LinkSVG } from 'assets/icons/link';
import { ColorsAccent } from 'common/colors';
import { LoupeSVG } from 'assets/icons/loupe';
import { useTranslation } from 'react-i18next';
import { useModal } from 'hooks/useModal.hook';
import { useDebounce } from 'hooks/useDebounce.hook';
import { GroupsMarkSVG } from 'assets/icons/groupsMark';
import { RouterLink } from 'router/components/routerLink';
import { EmployeesController } from 'controllers/employees';
import FormikCheckbox from 'components/form/fields/checkbox';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import FormikTextField from 'components/form/fields/inputs/index';
import { Providers, ProvidersModel } from 'models/providers/types';
import { chooseAllEmployees } from 'common/constants/employeesList';
import { EmployeeList, EmployeesModel } from 'models/employees/types';
import s from './index.module.scss';

interface PaymentAddEmployeeProps {
  state: any;
  values: any;
  employees: EmployeesController;
  getAuthMe: AuthMe;
  isCheckRechange: any[];
  isCheckGroupRechange: any[];
  getEmployeesModel: EmployeesModel;
  getProvidersModel: ProvidersModel;
  addEmployeeButtonEl: any;
  isDeletedOperators: any;
  inputValidateFields: any,
  selectValidateFields: any,
  isCheckRechangeConcat: any;
  isAppendEmployeesModal: any;
  isCheckGroupRechangeConcat: any;
  removeDuplicates: <T, K>(data: T[], key: (key: T) => K) => T[];
  setIsCheckRecnahge: (checkRechange: any) => void;
  setIsAppendEmployeesModal: (append: any) => void;
  setUniqueProviders: (uniqueProviders: any) => void;
  setIsDeletedOperators: (deletedOperator: any) => void;
  setIsCheckGroupRechange: (checkGroupRechange: any) => void;
  setIsCheckRechangeConcat: (checkRechangeConcat: any) => void;
  setIsCheckGroupRechangeConcat: (checkRechangeGroupConcat: any) => void;
}

const PaymentAddEmployeeView = (props: PaymentAddEmployeeProps) => {
  const {
    state,
    values,
    getAuthMe,
    employees,
    isCheckRechange,
    removeDuplicates,
    getEmployeesModel,
    getProvidersModel,
    setIsCheckRecnahge,
    setUniqueProviders,
    isDeletedOperators,
    inputValidateFields,
    addEmployeeButtonEl,
    selectValidateFields,
    isCheckGroupRechange,
    setIsDeletedOperators,
    isCheckRechangeConcat,
    isAppendEmployeesModal,
    setIsCheckGroupRechange,
    setIsCheckRechangeConcat,
    setIsAppendEmployeesModal,
    setIsCheckGroupRechangeConcat,
    isCheckGroupRechangeConcat,
  } = props;
  const addEmployeeEl = useRef<any>();
  const { isTablet } = useWindowDimensions();
  const [employeeSearch, setEmployeeSearch] = useState('');
  const [isAllElementsChecked, setIsAllElementsChecked] = useState<boolean>(false);
  const employeeSearchDebounce = useDebounce(employeeSearch, 300);
  const { t } = useTranslation();
  const { showModal } = useModal();

  const handleCheck = async (e: ChangeEvent<HTMLInputElement  >, client: any) => {
    const { id, checked } = e.target;

    // client?.accounts?.map(async (account: any) => {
    //   await priceList?.getPriceListDetailAction(account?.provider);
    //   account.provider.priceList = toJS(getPriceListModel.getPriceListDetail.priceLists);
    // });

    if (state?.group) {
      client?.accounts.map((clientItem: any) => state?.group?.groupsAccounts?.map((groupItem: any) => {

        if (clientItem?.provider?.id === groupItem?.provider?.id) {
          clientItem.defaultPrice = !groupItem?.priceList ? groupItem?.defaultPrice : null;
          clientItem.defaultPriceList = groupItem?.priceList ? groupItem?.priceList : null;
        }
      }));
    }

    const concatItemsWithSavedValues = removeDuplicates([...isCheckGroupRechangeConcat, client].flatMap((item) => isCheckGroupRechange.map((innerItem) => {
      if (item.id === innerItem.id) {
        item.accounts = innerItem.accounts;
      }
      return item;
    })), (key: any) => key.id);

    setIsCheckRecnahge(removeDuplicates([...isCheckRechange, id], (key: any) => key));
    setIsCheckRechangeConcat(removeDuplicates([...isCheckRechangeConcat, id], (key: any) => key));
    setIsCheckGroupRechangeConcat(concatItemsWithSavedValues);

    setIsDeletedOperators(isDeletedOperators.filter((item: any) => item !== id));

    if (!checked) {
      if (isCheckRechange.length > 1 && isCheckRechangeConcat.length > 1 && concatItemsWithSavedValues?.length > 1) {
        setIsCheckRecnahge(isCheckRechange?.filter((item: any) => item !== id));
        setIsCheckRechangeConcat(isCheckRechangeConcat.filter((item: any) => item !== id));
        setIsDeletedOperators(
          removeDuplicates([...isDeletedOperators, client.id], (key: any) => key)
        );
        
        setIsCheckGroupRechangeConcat(
          concatItemsWithSavedValues?.filter((item: any) => item.id !== id)
        );
      } else {
        toast.warning('At least one employee should be choosed');
      }
    }
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>, client: EmployeeList) => {
    const { checked } = e.target;

    if (state?.group) {
      client?.accounts.map((clientItem: any) => state?.group?.groupsAccounts?.map((groupItem: any) => {

        if (clientItem?.provider?.id === groupItem?.provider?.id) {
          clientItem.defaultPrice = !groupItem?.priceList ? groupItem?.defaultPrice : null;
          clientItem.defaultPriceList = groupItem?.priceList ? groupItem?.priceList : null;
        }
      }));
    }

    const concatItemsWithSavedValues = removeDuplicates(toJS(getEmployeesModel?.getEmployeesList).flatMap((item) => isCheckGroupRechange.map((innerItem) => {
      if (item.id === innerItem.id) {
        item.accounts = innerItem.accounts;
      }
      return item;
    })), (key: any) => key.id);

    setIsCheckRecnahge(toJS(getEmployeesModel?.getEmployeesList)?.map(item => item.id));
    setIsCheckRechangeConcat(toJS(getEmployeesModel?.getEmployeesList)?.map(item => item.id));
    setIsCheckGroupRechangeConcat(concatItemsWithSavedValues);
    setIsDeletedOperators([]);

    // if (checked) {
    //   setIsCheckGroupRechange(toJS(getEmployeesModel?.getEmployeesList));
    // }

    if (!checked) {
      toast.warning('At least one employee should be choosen');
      setIsCheckRecnahge(toJS(getEmployeesModel?.getEmployeesList)?.map(item => item.id).slice(0, 1));
      setIsCheckRechangeConcat(toJS(getEmployeesModel?.getEmployeesList)?.map(item => item.id).slice(0, 1));
      setIsCheckGroupRechangeConcat(concatItemsWithSavedValues.slice(0, 1));
      setIsDeletedOperators(toJS(getEmployeesModel?.getEmployeesList)?.map(item => item.id).slice(1));
    }
  };

  const onHandleSearch = (setter: (val: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };
  
  const concatNewIsCheckGroupValues = (submitValues: any) => {

    showModal(Modals.ModalAddEmployeesToPayment, {
      submitValues,
      inputValidateFields,
      selectValidateFields,
      state,
      isDeletedOperators,
      setIsCheckGroupRechange,
      isCheckGroupRechangeConcat,
      setIsCheckRecnahge,
      setUniqueProviders,
      removeDuplicates,
      isCheckRechangeConcat,
      isCheckGroupRechange
    });
  };

  useEffect(() => {
    if (!isAppendEmployeesModal) {
      setIsCheckRecnahge(isCheckGroupRechange.map(item => item.id));
      setIsCheckGroupRechangeConcat(isCheckGroupRechange);
    }
  }, [isAppendEmployeesModal]);

  useEffect(() => {
    const isOpenAddEmployeeMenu = (e: any) =>
      addEmployeeEl?.current?.contains(e.target) ||
      addEmployeeButtonEl?.current?.contains(e.target) ||
    setIsAppendEmployeesModal(false);

    document.addEventListener('click', isOpenAddEmployeeMenu);
    return () => document.removeEventListener('click', isOpenAddEmployeeMenu);
  }, []);

  useEffect(() => {
    employees.getEmployeesAction(
      {
        id: getAuthMe.id,
        page: toJS(getEmployeesModel.getEmployeesMeta).page,
        search: employeeSearch,
        // TODO: Если будут ошибка, поменять !== 1 на !== 0
        // quantity: (toJS(getEmployeesModel.getEmployeesMeta).page || employeeSearch) !== 1 ? 1000 : 1000,
      }
    );
  }, [employeeSearchDebounce]);

  // useEffect(() => {
  //   if (isAppendEmployeesModal) {
  //     providers.getProvidersAction();
  //   }
  // }, [isAppendEmployeesModal]);

  useEffect(() => {
    setIsAllElementsChecked(isCheckRechange.length === getEmployeesModel.getEmployeesList.length);
  }, [isCheckRechange, getEmployeesModel.getEmployeesList.length]);

  return (
    <motion.div
      ref={addEmployeeEl}
      initial="hidden"
      animate={isAppendEmployeesModal ? 'open' : 'hidden'}
      variants={{
        hidden: { width: 0, opacity: 0 },
        open: {
          width: !isTablet ? '100%' : '23.8125rem',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)',
          opacity: 1
        }
      }}
      transition={{ duration: 0.3, type: 'spring', damping: 18 }}
      className={s.employee__window}
    >
      <div className={s.employee__window__inner}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>{t<string>('MAIN.NEWPAYMENT.ADDITIONALEMPLOYEESPANEL.TITLE')}</h2>
          </div>
        </div>

        <div className={s.employee__search}>
          <FormikTextField
            placeholder={t<string>('MAIN.NEWPAYMENT.ADDITIONALEMPLOYEESPANEL.SEARCH.PLACEHOLDER')}
            name="addEmployeeSearch"
            value={employeeSearch}
            id={'addEmployeeSearch'}
            onChange={onHandleSearch(setEmployeeSearch)}
          >
            <div className={s.search__icon}>
              <LoupeSVG />
            </div>
          </FormikTextField>
        </div>

        <div className={s.employee__content}>
          { getEmployeesModel?.getEmployeesList?.length ? [chooseAllEmployees, ...toJS(getEmployeesModel?.getEmployeesList)]?.reduce((employeeAcc: any, employee: EmployeeList) => {
            employeeAcc.push({
              ...employee,
              accounts: removeDuplicates(employee?.accounts?.map((accountItem) => toJS(getProvidersModel.getProvidersList).map((provider: Providers) => {
                if (provider.id === accountItem?.provider?.id) {
                  accountItem.provider = provider;
                }
                return accountItem;
              })).flat(1), (key: any) => key.id)
            });

            return employeeAcc;
          }, [])?.map((client: any, i: number) => (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: {
                  pathLength: 1,
                  opacity: 1,
                  transition: {
                    delay: i * 0.05,
                    duration: 0.4,
                    type: 'spring',
                    damping: 10
                  },
                  x: 0
                }
              }}
              transition={{ duration: 0.7 }}
              key={client.id}
              className={s.content__items}
            >
              <div className={[s.content__item, client.isCheckboxAll ? s.content__item_all : null].filter((item) => item).join(' ')}>

                <FormikCheckbox
                  labelStyles={{
                    borderRadius: '8px',
                    backgroundColor: ColorsAccent.accentYellow
                  }}
                  label={`${client.name} ${client.surname}`}
                  id={toJS(client.id)}
                  title={`${client.name} ${client.surname}`}
                  name={`${toJS(client.id)}`}
                  isChecked={client.isCheckboxAll ?
                    isAllElementsChecked :
                    isCheckRechange?.includes(toJS(client.id))}
                  groupCheckbox
                  onChange={(e: ChangeEvent<HTMLInputElement>) => client.isCheckboxAll ? handleCheckAll(e, toJS(client)) : handleCheck(e, toJS(client))}
                />
                 <div className={s.group__icons}>
                    {toJS(client?.group?.title) ? (
                      <div className={s.group__icon}>
                        <GroupsMarkSVG />
                      </div>
                    ) : null}

                   { client.isCheckboxAll ?
                     null
                     :
                    <RouterLink to={`/employees/employees-detail/${client.id}`} state={{ previousPageWithQuery: '/employees' }}>
                      <div className={s.item__icon}>
                        <LinkSVG />
                      </div>
                    </RouterLink>}
                  </div>
              </div>
            </motion.div>
          ))
            :
          <div className={s.empty_message}>
            <p>{t<string>('MAIN.NEWPAYMENT.ADDITIONALEMPLOYEESPANEL.EMPTY')}</p>
          </div>
          }
        </div>

        <div className={s.buttons}>
          <motion.button
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            onClick={() => concatNewIsCheckGroupValues(values)}
            type="button"
          >
            <p>{t<string>('MAIN.NEWPAYMENT.ADDITIONALEMPLOYEESPANEL.BUTTONS.SUBMIT')}</p>
          </motion.button>
          <motion.button
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            type="button"
            onClick={() => setIsAppendEmployeesModal(false)}
          >
            <p>{t<string>('MAIN.NEWPAYMENT.ADDITIONALEMPLOYEESPANEL.BUTTONS.CANCEL')}</p>
          </motion.button>

        </div>
      </div>
    </motion.div>
  );
};

export const PaymentAddEmployee = observer(PaymentAddEmployeeView);
import React, { FC, ReactElement } from 'react';
import s from './index.module.scss';

interface IMoreFunctionsBtn {
  handleFunc: () => void;
  icon: ReactElement;
  title: string;
}

export const MoreFunctionsBtn = (props: IMoreFunctionsBtn) => {
  const { handleFunc, icon, title } = props;

  return (
    <button onClick={(e: any) => {
      e.stopPropagation();
      handleFunc();
    }} type='button' className={s.btn}>
      <div className={s.icon}>
        {icon}
      </div>
      <p>{title}</p>
    </button>
  );
};

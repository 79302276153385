import React from 'react';

export const SendArrowSVG = () => (
  <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.77877 12.6777L13.7285 20.4558L20.0925 1.36396L1.00059 7.72792L8.77877 12.6777ZM8.77877 12.6777L14.4356 7.02082"
      strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

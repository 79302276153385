import React, { CSSProperties } from 'react';
import { motion } from 'framer-motion';
import { ColorsAccent, ColorsGray } from 'common/colors';
import { listKindsOfSchedule } from 'common/constants/schedulePayment';
import { ChoosenSchedulePayment, ScheduleSelectField } from 'common/constants/schedulePayment/types';
import s from './index.module.scss';

interface ISwitchStatus {
  stylesWrapper?: CSSProperties;
  stylesCircle?: CSSProperties;
  switchToggle: boolean;
  setSwitchToggle: (switchToggle: boolean) => void;
  setKindOfSceduled: (selectField: ScheduleSelectField) => void;
  setChoosenSchedulePayment: (choosenSchedulePayment: ChoosenSchedulePayment) => void;
  setFormattedEndsDate: (date: string) => void;
  setFormattedStartsDate: (date: string) => void;
  setFormattedPlannedPaymentDate: (date: string) => void;
  disabled: boolean;
}

export const ScheduleActiveToggle = (props: ISwitchStatus) => {
  const { stylesWrapper, stylesCircle, switchToggle, setSwitchToggle, setFormattedPlannedPaymentDate, setFormattedStartsDate, setFormattedEndsDate, setKindOfSceduled, setChoosenSchedulePayment, ...other } = props;

  const toggleSwitch = () => {
    setSwitchToggle(!switchToggle);

    if (switchToggle) {
      setChoosenSchedulePayment({});
      setFormattedPlannedPaymentDate('Choose date');
      setFormattedStartsDate('Choose date');
      setFormattedEndsDate('Choose date');
    }
  };

  return (
    <motion.button
      type={'button'}
      className={[s.wrapper, switchToggle ? s.active : null].join(' ')}
      initial="initial"
      animate={switchToggle ? 'animate' : 'initial' }
      variants={{
        initial: { backgroundColor: ColorsGray.grayFour },
        animate: { backgroundColor: ColorsAccent.accentYellow }
      }}
      onClick={toggleSwitch}
      style={{ ...stylesWrapper }}
      transition={{
        type: 'spring',
        stiffness: 700,
        damping: 30,
      }}
      {...other}
    >
      <motion.div
        className={s.handle}
        style={{ ...stylesCircle }}
        layout
        transition={{
          type: 'spring',
          stiffness: 700,
          damping: 30,
        }}
      />
    </motion.button>
  );
};

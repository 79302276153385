/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useState } from 'react';
import { useStores } from 'store';
import { Loading } from 'store/types';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { BackSVG } from 'assets/icons/back';
import { LinkSVG } from 'assets/icons/link';
import { useModal } from 'hooks/useModal.hook';
import { motion, Variants } from 'framer-motion';
import { TotalInfoSVG } from 'assets/icons/totalInfo';
import { useLocation, useNavigate } from 'react-router';
import FormikContainer from 'components/form/containers';
import { RouterLink } from 'router/components/routerLink';
import { AnimatedDivPage } from 'components/animatedPage';
import { menuTabsConfirmation } from 'common/constants/main';
import { IActiveContentState } from 'components/tabs/universalTab/types';
import { ChoosenSchedulePayment } from 'common/constants/schedulePayment/types';
import { SchedulePayment } from './components/schedulePayment';
import s from './index.module.scss';

const PaymentConfirmationView: FC = () => {
  let { state }: any = useLocation();
  const navigate = useNavigate();
  const { payments, employees, auth } = useStores();
  const { getAuthModel } = auth;
  const { getConfirmPaymentsAsyncHelper, getPaymentsDetailsAsyncState, getCreatePaymentsAsyncState } = payments;
  const isPaymentsConfirmedLoading = getConfirmPaymentsAsyncHelper.getLoadingState;
  const isPaymentsCreatedLoading = getCreatePaymentsAsyncState.getLoadingState;
  const { showModal } = useModal();
  const tab = state?.tab ? menuTabsConfirmation[state.tab] : menuTabsConfirmation[0];
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
  const [activeContent, setActiveContent] = useState<IActiveContentState>(tab);
  const [choosenScedulePaymentData, setChoosenScedulePaymentData] = useState<ChoosenSchedulePayment & { isChedulePaymentActive: boolean, isShcedulePaymentReadyToPayment: boolean }>();

  const animations: Variants = {
    initial: { opacity: 0, scale: 0.6 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.6 }
  };

  const onSubmit = () => {
    // TODO: Modal window
    // payments.createPaymentsAction(state?.formatPaymentData);
  };

  const showPaymentConsentWindow = () => {
    showModal(Modals.ModalPaymentWindowConsent, { payments, formatPaymentsData: state?.formatPaymentData });
  };

  const handleClick = (elements: any) => {
    setActiveContent({ ...elements });
  };

  const handleSchedulePayment = (choosenScedulePayment: ChoosenSchedulePayment & { isChedulePaymentActive: boolean, isShcedulePaymentReadyToPayment: boolean }) => {
    state.formatPaymentData = { ...state?.formatPaymentData, regularStart: choosenScedulePayment.regularStart || null, regularEnd: choosenScedulePayment.regularEnd || null, interval: choosenScedulePayment.interval || null, pending: choosenScedulePayment.pending || null, isChedulePaymentActive: choosenScedulePayment.isChedulePaymentActive };
    setChoosenScedulePaymentData(choosenScedulePayment);
  };

  useEffect(() => {
    if (!state?.formatResultData?.length) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [activeContent]);

  useEffect(() => {
    if (isPaymentsCreatedLoading === Loading.success) {
      toast.clearWaitingQueue();
      toast.dismiss();
    }
  }, [isPaymentsCreatedLoading]);

  useEffect(() => {

    // employees.getEmployeesAction({ id: getAuthModel.getAuthMe.id || temporaryClientId, quantity: 9999 });

    if (isPaymentsConfirmedLoading === Loading.success) {
      navigate('/history');
      window.sessionStorage.removeItem('isCheckGroup');
      window.sessionStorage.removeItem('isCheck');
      window.sessionStorage.removeItem('isCheckAll');
    }

    return () => {
      getConfirmPaymentsAsyncHelper.reset();
      getCreatePaymentsAsyncState.reset();
      getPaymentsDetailsAsyncState.reset();
      toast.clearWaitingQueue();
      state = [];
    };

  }, [isPaymentsConfirmedLoading]);
  
  return (
    <section className={s.wrapper}>
      <div className={s.wrapper__container}>
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: { opacity: 0, x: 70, y: 70 },
            animate: { opacity: 1, x: 0, y: 0 },
            exit: { opacity: 0, x: 70, y: 70 }
          }}
          transition={{ duration: 0.7, type: 'spring', damping: 22, stiffness: 155 }}
          className={s.back}
        >
          <ReactTooltip textColor="#334049" backgroundColor="#fff" border borderColor="#E5E8ED" />
          <motion.button
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className={s.back_icon}
            onClick={() =>
              navigate(!state?.fromTable ? state?.goBack : '/payment', {
                state: { ...state, forInitialValues: state?.forInitialValues }
              })
            }
          >
            <BackSVG />
          </motion.button>

          <div className={s.back_text}>
            <p>Back</p>
          </div>
        </motion.div>

        <AnimatedDivPage
          transition={{ duration: 0.7, type: 'spring', damping: 15 }}
          animations={animations}
        >
          <div className={s.wrapper__title}>
            <h1 className={s.title}>Payment details</h1>
            <div className={s.wrapper__inner__buttons}>
              {menuTabsConfirmation?.map((menuTab: any, index: number) =>
                index === activeContent.index ? (
                  <div key={menuTab.index} className={s.wrapper__inner__button}>
                    <button className={s.wrapper__inner__button_active} type="submit">
                      <p>{menuTab.title}</p>
                    </button>
                  </div>
                ) : (
                  <div key={menuTab.index} className={s.wrapper__inner__button}>
                    <button
                      className={s.wrapper__inner__button_default}
                      onClick={() => handleClick({ index, titles: menuTab.title })}
                      type="submit"
                    >
                      <p>{menuTab.title}</p>
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
          <div className={s.wrapper__detail}>
            <div className={s.detail}>
              <div className={s.detail__payment}>
                <div className={s.detail__payment__list}>
                  {activeContent.index === 0
                    ? state?.formatResultData?.map((detail: any, index: number) => (
                        <div key={`${detail?.providerId}${detail?.employeeId}`} className={s.item}>
                          <div className={s.item__header}>
                            <div className={s.item__title_wrapper}>
                              <RouterLink to={`/employees/employees-detail/${detail.employeeId}`} state={{ previousPageWithQuery: '/employees' }}>

                                <div className={s.item__link}>
                                  <h2 className={s.item__title}>
                                    {index + 1}. {detail?.name} {' '} {detail?.surname}
                                  </h2>
                                  <div className={s.item__icon}>
                                    <LinkSVG />
                                  </div>
                                </div>
                              </RouterLink>
                            </div>

                            <div className={s.price}>
                              <p>
                                {Number(detail.providers?.reduce(
                                  (amount: number, provider: any) => amount + +provider?.amount,
                                  0
                                ))?.toFixed(2)?.toLocaleString()}{' '}
                                UGX
                              </p>
                            </div>
                          </div>
                          {detail?.providers?.map((provider: any) => (
                            <div
                              key={`${provider.id}${detail?.employeeId}`}
                              className={s.item__description}
                            >
                              <div className={s.description__header}>
                                {provider.icon ? (
                                  <div className={s.image}>
                                    <img
                                      src={provider.icon}
                                      alt="Logo"
                                      data-tip={provider?.title}
                                    />
                                  </div>
                                ) : (
                                  <h4 className={s.title} data-tip={provider?.title}>
                                    {provider?.title}
                                  </h4>
                                )}

                                <div className={s.price}>
                                  <p>{Number(provider.amount)?.toFixed(2)?.toLocaleString()} UGX</p>
                                </div>
                              </div>
                              <p className={s.description__text}>{provider.description}</p>
                            </div>
                          ))}
                        </div>
                    ))
                    : state?.formatResultDataReverse?.map((detail: any) => (
                        <div key={`${detail?.id}${detail?.employeeId}`} className={s.item}>
                          <div className={s.item__header}>
                            {detail.icon ? (
                              <div className={s.image}>
                                <img src={detail.icon} alt="Logo" data-tip={detail?.title} />
                              </div>
                            ) : (
                              <h4 className={s.item__title} data-tip={detail?.title}>
                                {detail?.title}
                              </h4>
                            )}
                            <div className={s.price}>
                              <p>
                                {Number(detail.operators?.reduce(
                                  (amount: number, provider: any) => amount + +provider?.amount,
                                  0
                                ))?.toFixed(2)?.toLocaleString()}{' '}
                                UGX
                              </p>
                            </div>
                          </div>
                          {detail?.operators?.map((operator: any, index: any) => (
                            <div
                              key={`${operator.employeeId}${detail.id}`}
                              className={s.item__description}
                            >
                              <div className={s.description__header}>
                              
                                <div className={s.item__title_wrapper}>
                                  <RouterLink to={`/employees/employees-detail/${operator.employeeId}`} state={{ previousPageWithQuery: '/employees' }}>
                                    <div className={s.item__link}>
                                      <h4 className={`${s.item__title} ${s.title}`} data-tip={operator?.title}>
                                        {index + 1}. {operator?.name} {' '} {operator?.surname}
                                      </h4>
                                      <div className={s.item__icon}>
                                        <LinkSVG />
                                      </div>
                                    </div>
                                  </RouterLink>
                                </div>
                                
                                <div className={s.price}>
                                  <p>{Number(operator?.amount)?.toFixed(2)?.toLocaleString()} UGX</p>
                                </div>
                              </div>
                              <p className={s.description__text}>{operator?.description}</p>
                            </div>
                          ))}
                        </div>
                    ))}
                </div>
              </div>
            </div>
            <div className={s.total__wrapper}>
              <div className={s.total}>
                <div className={s.total__inner}>
                  <div className={s.total__item}>
                    <p>Employees</p>
                    <p>{state?.formatResultData?.length}</p>
                  </div>
                  <div className={s.total__item}>
                    <p>Total amount</p>
                    <p>{Number(state?.totalAmount)?.toFixed(2)?.toLocaleString()} UGX</p>
                  </div>
                  <div className={s.total__item}>
                    <p>Charge</p>
                    <p>{Number(0)?.toFixed(2)?.toLocaleString()} UGX</p>
                  </div>

                  <div className={s.delimiter} />

                  <div className={s.total__item__sum}>
                    <h3>Total</h3>
                    <p>{Number(state?.totalAmount)?.toFixed(2)?.toLocaleString()} UGX</p>
                  </div>
                </div>

                <div className={s.calendar}>
                  <SchedulePayment isRepeatSchedulePayment={state?.isRepeatSchedulePayment} handleSchedulePayment={handleSchedulePayment} />
                </div>

                <div className={s.total__buttons}>
                  <motion.button
                    whileTap={choosenScedulePaymentData?.isShcedulePaymentReadyToPayment ? { scale: 0.95 } : {}}
                    whileHover={choosenScedulePaymentData?.isShcedulePaymentReadyToPayment ? { scale: 1.05 } : {}}
                    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                    type="button"
                    disabled={choosenScedulePaymentData?.isChedulePaymentActive ? !choosenScedulePaymentData?.isShcedulePaymentReadyToPayment : false}
                    onClick={showPaymentConsentWindow}
                  >
                    <p>Make a payment</p>
                  </motion.button>
                </div>
              </div>
            </div>
          </div>
        </AnimatedDivPage>

        <div className={s.total__wrapper_mobile}>
          <div className={s.total}>
            <div className={s.total__inner}>
              <div className={s.total__info_icon}>
                <button
                  onClick={() => setIsMoreInfoOpen(!isMoreInfoOpen)}
                  type="button"
                  className={`${s.total__info_button} ${isMoreInfoOpen ? s.arrow_rotate : ''}`}
                >
                  <TotalInfoSVG />
                </button>
              </div>

              <motion.div
                animate={isMoreInfoOpen ? 'open' : 'hidden'}
                variants={{
                  animate: { height: '100%' },
                  hidden: { height: '0' }
                }}
                transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                className={s.total__main_info}
              >
                <div className={s.total__item}>
                  <p>Employees</p>
                  <p>{state?.formatResultData?.length}</p>
                </div>
                <div className={s.total__item}>
                  <p>Total amount</p>
                  <p>{Number(state?.totalAmount)?.toFixed(2)?.toLocaleString()} UGX</p>
                </div>
                <div className={s.total__item}>
                  <p>Charge</p>
                  <p>{Number(0)?.toFixed(2)?.toLocaleString()} UGX</p>
                </div>
                <div className={s.delimiter} />
              </motion.div>

              <div className={s.total__item__sum}>
                <h3>Total</h3>
                <p>{Number(state?.totalAmount)?.toFixed(2)?.toLocaleString()} UGX</p>
              </div>
            </div>

            <div className={s.calendar__mobile}>
              <SchedulePayment isRepeatSchedulePayment={state?.isRepeatSchedulePayment} handleSchedulePayment={handleSchedulePayment} />
            </div>

            <FormikContainer initialValues={() => {}} onSubmit={onSubmit} enableReinitialize>
              {() => (
                <div className={s.total__buttons}>
                  <motion.button
                    whileTap={choosenScedulePaymentData?.isShcedulePaymentReadyToPayment ? { scale: 0.95 } : {}}
                    whileHover={choosenScedulePaymentData?.isShcedulePaymentReadyToPayment ? { scale: 1.05 } : {}}
                    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                    type="button"
                    disabled={choosenScedulePaymentData?.isChedulePaymentActive ? !choosenScedulePaymentData?.isShcedulePaymentReadyToPayment : false}
                    onClick={showPaymentConsentWindow}
                  >
                    <p>Make a payment</p>
                  </motion.button>
                </div>
              )}
            </FormikContainer>
          </div>
        </div>
      </div>
    </section>
  );
};

export const PaymentConfirmation = observer(PaymentConfirmationView);

/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Tab, Tabs } from 'components/tabs';
import { IActiveContent, ITab } from './types';
import s from './index.module.scss';

export const UniversalTabView = (props: IActiveContent) => {
  const { index, styles, content } = props;

  return (
    <div>
      <Tabs activeTab={index}>
        {content.map((tab: ITab, i) => (
            <Tab key={tab.id} index={tab.id}>
              <motion.p style={{ ...styles }}>
                {tab.text.map((word: string, wordIndex: number) => (
                  <motion.span
                    initial="closed"
                    animate="open"
                    exit="exit"
                    variants={{
                      open: {
                        opacity: 1,
                        transition: {
                          type: 'spring',
                          damping: 17,
                          stiffness: 100,
                          delay: wordIndex * 0.02
                        }
                      },
                      closed: {
                        opacity: 0,
                        transition: {
                          type: 'spring',
                          damping: 17,
                          stiffness: 100,
                          delay: wordIndex * 0.02
                        }
                      },
                      exit: {
                        opacity: 0,
                        transition: {
                          type: 'spring',
                          damping: 17,
                          stiffness: 100,
                          delay: wordIndex * 0.02
                        }
                      }
                    }}
                    key={wordIndex}
                  >
                    {word}
                  </motion.span>
                ))}
              </motion.p>
            </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export const UniversalTab = memo(UniversalTabView);

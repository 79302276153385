/* eslint-disable jsx-a11y/no-static-element-interactions,no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { AuthMe } from 'models/auth/types';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import { GroupsModel } from 'models/groups/types';
import { EmployeeList } from 'models/employees/types';
import { GroupsController } from 'controllers/groups';
import { EmployeesController } from 'controllers/employees';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { toJS } from 'mobx';
import s from './index.module.scss';

export interface ModalCreateGroupViewProps {
  hideModal: () => void;
  employees: EmployeesController;
  isCheckGroup: EmployeeList[];
  setIsCheckGroupList: (groupItems: any) => void;
  setIsAppendEmployeesModal: (isOpen: boolean) => void;
  isAppendEmployeesModal?: any;
  modalRefEl: any;
  removeDuplicates: <T, K>(data: T[], key: (key: T) => K) => T[];
  groups: GroupsController;
  getGroupsModel: GroupsModel;
}

const ModalAddEmployeesToGroupView = (props: ModalCreateGroupViewProps) => {
  const {
    groups,
    hideModal,
    employees,
    modalRefEl,
    isCheckGroup,
    getGroupsModel,
    removeDuplicates,
    setIsCheckGroupList,
    isAppendEmployeesModal,
    setIsAppendEmployeesModal
  } = props;

  const isRemovedEmployees = toJS(getGroupsModel.getGroupDetail.employees).filter(groupEmployee => isCheckGroup.map(employee => employee.id).includes(groupEmployee.id)).length < toJS(getGroupsModel.getGroupDetail.employees).length;
  const removedListEmployees = toJS(getGroupsModel.getGroupDetail.employees).filter(groupEmployee => !isCheckGroup.map(employee => employee.id).includes(groupEmployee.id)).map(employee => `${employee.name} ${employee.surname}`);
  const addedListEmployees = isCheckGroup.filter(groupEmployee => !toJS(getGroupsModel.getGroupDetail.employees).map(employee => employee.id).includes(groupEmployee.id)).map(employee => `${employee.name} ${employee.surname}`);
  const isAddedEmployees = isCheckGroup.filter(groupEmployee => !toJS(getGroupsModel.getGroupDetail.employees).map(employee => employee.id).includes(groupEmployee.id)).length;
  const changeGroupCheck = () => {

    const data: any = {
      groupId: toJS(getGroupsModel.getGroupDetail).id,
      title: toJS(getGroupsModel.getGroupDetail).title,
      description: toJS(getGroupsModel.getGroupDetail).description,
      parentId: toJS(getGroupsModel.getGroupDetail).parent_id,
      employeesId: removeDuplicates(isCheckGroup.map((item: EmployeeList) => item.id), (key: any) => key),
      accounts: toJS(getGroupsModel.getGroupDetail).groupsAccounts.reduce((acc: any, item: any) => {
        acc.push({
          defaultPrice: item.defaultPrice,
          providerId: item.provider.id,
          priceListId: item.priceList?.id || null,
          defaultScenario: item?.defaultScenario?.id || null
        });
        return acc;
      }, [])
    };

    groups.updateGroupsAction(data);

    isCheckGroup?.reduce((acc: any, employee: any) => {
      Promise.all([employees?.updateEmployeeAction({
        employeeId: employee?.id,
        name: employee?.name,
        phones: employee?.phones,
        surname: employee?.surname,
        groupId: getGroupsModel?.getGroupDetail?.id,
        accounts:
            employee?.accounts?.length ?
              [ ...removeDuplicates(employee?.accounts?.map((employeeItem: any) => getGroupsModel?.getGroupDetail?.groupsAccounts?.reduce((accEmployee: any, group: any) => {

                if (employeeItem?.provider?.id === group?.provider?.id) {
                  accEmployee.push({
                    defaultPrice: employeeItem?.provider?.id === group?.provider?.id ? !group?.priceList?.id ? group.defaultPrice : null : !employeeItem?.defaultPriceList?.id ? employeeItem?.defaultPrice : null,
                    defaultPriceListId: employeeItem?.provider?.id === group?.provider?.id ? group?.priceList?.id ? group.priceList?.id : null : employeeItem?.defaultPriceList?.id || null,
                    defaultScenario: employeeItem?.defaultScenario,
                    defaultValueEmployee: employeeItem?.defaultValueEmployee || false,
                    providerId: employeeItem?.provider?.id,
                    employeeId: employee?.id,
                    uniqueId: employeeItem?.uniqueId,
                    note: employeeItem?.note || null
                  });
                }

                return accEmployee;
              }, [])).flat(Infinity), (key: any) => key.providerId),

              ...getGroupsModel?.getGroupDetail?.groupsAccounts?.filter((groupAccount: any) =>
                !removeDuplicates(employee?.accounts?.map((employeeItem: any) => getGroupsModel?.getGroupDetail?.groupsAccounts?.reduce((accEmployee: any, group: any) => {

                  if (employeeItem?.provider?.id === group?.provider?.id) {
                    accEmployee.push({
                      defaultPrice: employeeItem?.provider?.id === group?.provider?.id ? !group?.priceList?.id ? group.defaultPrice : null : !employeeItem?.defaultPriceList?.id ? employeeItem?.defaultPrice : null,
                      defaultPriceListId: employeeItem?.provider?.id === group?.provider?.id ? group?.priceList?.id ? group.priceList?.id : null : employeeItem?.defaultPriceList?.id || null,
                      providerId: employeeItem?.provider?.id,
                      defaultScenario: employeeItem?.defaultScenario,
                      defaultValueEmployee: employeeItem?.defaultValueEmployee || false,
                      employeeId: employee?.id,
                      uniqueId: employeeItem?.uniqueId,
                      note: employeeItem?.note || null
                    });
                  }
                  return accEmployee;
                }, [])).flat(Infinity), (key: any) => key.providerId).map((convertEmployee: any) => convertEmployee?.providerId).includes(groupAccount?.provider?.id)).reduce((groupAcc: any, groupItem: any) => {
                groupAcc?.push({
                  defaultPrice: !groupItem?.priceList?.id ? groupItem.defaultPrice : null,
                  defaultPriceListId: groupItem?.priceList?.id ? groupItem.priceList?.id : null,
                  defaultScenario: groupItem?.defaultScenario,
                  defaultValueEmployee: groupItem?.defaultValueEmployee || false,
                  providerId: groupItem?.provider?.id,
                  employeeId: employee?.id,
                  uniqueId: null,
                  note: null
                });
                return groupAcc;
              }, []),
              ]
              :
              getGroupsModel?.getGroupDetail?.groupsAccounts?.reduce((accGroup: any, group: any) => {
                accGroup.push({
                  defaultPrice: !group?.priceList?.id ? group.defaultPrice : null,
                  defaultPriceListId: group?.priceList?.id || null,
                  defaultScenario: group?.defaultScenario,
                  defaultValueEmployee: group?.defaultValueEmployee,
                  providerId: group?.provider?.id,
                  employeeId: employee?.id,
                  uniqueId: null,
                  note: null
                });
                return accGroup;
              }, []).flat(Infinity)
      }, isCheckGroup.length)]
      );
      return acc;
    }, []);
    setIsCheckGroupList(isCheckGroup);
    setIsAppendEmployeesModal(false);

    hideModal();
  };

  useEffect(() => () => setIsAppendEmployeesModal(false), [isAppendEmployeesModal]);

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Edit employees</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.description}>

            { isRemovedEmployees ?
              <>
                <p className={[s.description__text, s.description__text_scroll].join(' ')}>
                  Do you want to remove employees ({removedListEmployees.map(employee => employee).join(', ')}) from this group: {getGroupsModel.getGroupDetail.title} ?
                </p><br/>
              </>
              :
              null
            }

            { isAddedEmployees ?
              <>
                <p className={[s.description__text, s.description__text_scroll].join(' ')}>
                  Do you want to add employees ({addedListEmployees.map(employee => employee).join(', ')}) to this group: {getGroupsModel.getGroupDetail.title } ?
                </p><br/>
              </>
              :
              null
            }
            {
              isCheckGroup.filter((employee) => employee.group).length
                ?
              <>
                <p className={s.description__text}>
                  Some employees already have a group, do you want to change this group: {getGroupsModel.getGroupDetail.title} ?
                </p><br/>
              </>
                :
                null
            }
            <p className={s.description__text}>
              When adding a user to a group, all values that are currently selected will be reassigned to the group's
              default value, do you really want to change them ?
            </p>
          </div>

          <div className={s.employee}>
            <div className={s.buttons}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                onClick={changeGroupCheck}
              >
                <p>Yes</p>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                type="button"
                onClick={() => hideModal()}
              >
                <p>No</p>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export const ModalAddEmployeesToGroup = observer(ModalAddEmployeesToGroupView);
import React, { ReactChild, ReactChildren } from 'react';
import { motion, Transition, Variants } from 'framer-motion';

interface IAnimatedPageProps {
  children: ReactChild | ReactChildren | ReactChild[];
  animations: Variants;
  transition?: Transition;
  className?: any;
  styles?: any;
  ref?: any;
}

export const AnimatedDivPage = ({ children, animations, transition, styles, ref, className }: IAnimatedPageProps) => (
    <motion.div
      className={className}
      style={styles}
      ref={ref}
      transition={transition}
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.div>
);

/* eslint-disable jsx-a11y/no-static-element-interactions,no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CrossSVG } from 'assets/icons/cross';
import FormikContainer from 'components/form/containers';
import { PaymentsController } from 'controllers/payments';
import FormikTextField from 'components/form/fields/inputs';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { GetPaymentsHistoryDetails } from 'models/payments/types';
import s from './index.module.scss';

export interface ModalCreateHistoryviewProps {
  hideModal: () => void;
  modalRefEl: any;
  payments: PaymentsController;
  currentPayment: GetPaymentsHistoryDetails;
}

const ModalEditHistoryView = (props: ModalCreateHistoryviewProps) => {
  const {
    payments,
    hideModal,
    modalRefEl,
    currentPayment,
  } = props;

  const acceptEditPaymentDescription = (values: { paymentsDescription: string }) => {
    const { paymentsDescription } = values;
    payments.updatePaymentsHistoryDetailsAction({
      multiTxId: currentPayment.id,
      notes: paymentsDescription
    });

    hideModal();
  };

  return (
    <ModalBackdrop
      modalRefEl={modalRefEl}
      modalWrapperStyles={{ padding: '0 1rem' }}
      hideModal={hideModal}
    >
      <div className={s.wrapper__container}>
        <div className={s.header}>
          <div className={s.header__title}>
            <h2 className={s.title}>Edit description</h2>
          </div>

          <div className={s.close} onClick={() => hideModal()}>
            <CrossSVG/>
          </div>
        </div>

        <FormikContainer
          initialValues={{ paymentsDescription: currentPayment.notes }}
          onSubmit={acceptEditPaymentDescription}
        >
          {({ errors, setFieldValue }) => (
            <div className={s.main}>
              <div className={s.operator}>

                  <div className={s.operator__create_id}>
                    <FormikTextField
                      type="text"
                      value={''}
                      placeholder="Enter description"
                      name="paymentsDescription"
                      label={<p className={s.operator__create_label}>Payment description</p>}
                    />
                  </div>

                <div className={s.buttons}>
                  <motion.button
                    type='submit'
                    whileTap={{ scale: 0.95 }}
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                  >
                    <p>Save</p>
                  </motion.button>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                    type="button"
                    onClick={() => hideModal()}
                  >
                    <p>Cancel</p>
                  </motion.button>
                </div>
              </div>
            </div>
          )}
        </FormikContainer>
      </div>
    </ModalBackdrop>
  );
};

export const ModalEditHistory = observer(ModalEditHistoryView);

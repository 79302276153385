import React from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import s from './index.module.scss';

interface ITransactionsCountProps {
  allTransactions: number;
  wrapperStyles?: any;
  trackSuccessStyles?: any;
  trackFailureStyles?: any;
  trackInProgressStyles?: any;
  successfulTransactions?: any;
  failureTransactions?: any;
  inProgressTransactions?: any;
}

export const TransactionsCount = (props: ITransactionsCountProps) => {
  const {
    allTransactions,
    successfulTransactions,
    wrapperStyles,
    trackSuccessStyles,
    trackFailureStyles,
    trackInProgressStyles,
    failureTransactions,
    inProgressTransactions
  } = props;

  const eightyLikeOneHundredPercentage = 100 / allTransactions;

  return (
    <div className={s.wrapper} style={wrapperStyles}>
      {inProgressTransactions ? (
        <>
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            key="progress"
            variants={{
              hidden: {
                opacity: 0,
                width: `calc(${
                  eightyLikeOneHundredPercentage * (successfulTransactions + failureTransactions)
                }%)`
              },
              visible: {
                pathLength: 1,
                opacity: 1,
                transition: {
                  delay: 0.05,
                  duration: 3,
                  type: 'spring',
                  damping: 30
                },
                width: `calc(${
                  eightyLikeOneHundredPercentage * (successfulTransactions + failureTransactions)
                }%)`
              },
              exit: {
                opacity: 0,
                transition: {
                  delay: 2,
                  duration: 2,
                  type: 'spring',
                  damping: 30
                }
              }
            }}
            style={{
              ...trackInProgressStyles
            }}
            className={s.track__progress}
          />

          <div className={s.wrapper__inner}>
            <p>
              {successfulTransactions + failureTransactions}/{allTransactions}
            </p>
          </div>
        </>
      ) : (
        <>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={{
              hidden: {
                opacity: 0,
                width: '0',
                transition: {
                  delay: 0.05,
                  duration: 15,
                  type: 'spring',
                  damping: 20
                }
              },
              visible: {
                pathLength: 1,
                opacity: 1,
                width: `calc(${eightyLikeOneHundredPercentage * successfulTransactions}%)`,
                transition: {
                  delay: 0.05,
                  duration: 5,
                  type: 'spring',
                  damping: 30
                }
              }
            }}
            style={{
              ...trackSuccessStyles
            }}
            className={s.track__progress}
          />
          <motion.div
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, width: '0' },
              visible: {
                pathLength: 1,
                opacity: 1,
                width: `calc(${eightyLikeOneHundredPercentage * failureTransactions}%)`,
                transition: {
                  delay: 0.05,
                  duration: 15,
                  type: 'spring',
                  damping: 30
                }
              }
            }}
            style={{
              ...trackFailureStyles
            }}
            className={s.track__progress}
          />
          <div className={s.wrapper__inner}>
            <p>
              {successfulTransactions}/{allTransactions}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { useStores } from 'store';
import { Loading } from 'store/types';
import ReactTooltip from 'react-tooltip';
import { Modals } from 'components/modal';
import { observer } from 'mobx-react-lite';
import { BackSVG } from 'assets/icons/back';
import { LinkSVG } from 'assets/icons/link';
import { LoupeSVG } from 'assets/icons/loupe';
import { CrossSVG } from 'assets/icons/cross';
import { useModal } from 'hooks/useModal.hook';
import { motion, Variants } from 'framer-motion';
import { useDebounce } from 'hooks/useDebounce.hook';
import { useNavigate } from 'react-router';
import { EmployeeList } from 'models/employees/types';
import { GroupsMarkSVG } from 'assets/icons/groupsMark';
import { DropSelectSVG } from 'assets/icons/dropselect';
import FormikContainer from 'components/form/containers';
import { RouterLink } from 'router/components/routerLink';
import { AnimatedDivPage } from 'components/animatedPage';
import { DeleteLightSVG } from 'assets/icons/deleteLight';
import { ColorsAccent, ColorsBorder } from 'common/colors';
import { ProviderMeasure } from 'common/constants/payment';
import FormikTextField from 'components/form/fields/inputs';
import FormikCheckbox from 'components/form/fields/checkbox';
import FormikSelect from 'components/form/fields/select/index';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import { chooseAllEmployees } from 'common/constants/employeesList';
import { KindOfPage } from 'common/constants/additionalOperatorModal';
import { IOperatorsTableTitles } from 'common/constants/employeesDetail/types';
import FormikTextFieldNumber from 'components/form/fields/inputs/inputNumber/index';
import { operatorsTableGroupTitles, selectGroupSortByTitles } from 'common/constants/groups';
import s from './index.module.scss';

const CreateGroupView: FC = () => {
  const navigate = useNavigate();
  const removeDuplicates = (data: any, key: any) => [
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];

  const { employees, providers, auth, accounts, priceList, groups } = useStores();
  const { getAuthModel } = auth;
  const { getAuthMe } = getAuthModel;
  const { getPriceListModel, getPriceListDetailAsyncState } = priceList;
  const { getProvidersModel, getProvidersAsyncState } = providers;
  const { getCreateGroupsAsyncState, getGroupsModel } = groups;
  const { getEmployeesModel, getEmployeesAsyncState } = employees;
  const { getCreateAccountsAsyncState, getDeleteAccountsAsyncState } = accounts;
  const { isTablet } = useWindowDimensions();
  const { showModal } = useModal();
  const [isAppendEmployeesModal, setIsAppendEmployeesModal] = useState<boolean | any>(false);
  const [choosenItems, setChoosenItems] = useState<any>([]);
  const [isCheck, setIsCheck] = useState<any>([]);
  const [isCheckGroup, setIsCheckGroup] = useState<any>([]);
  const [isCheckGroupList, setIsCheckGroupList] = useState<EmployeeList[]>([]);
  const [createOperatorsGroup, setCreateOperatorsGroup] = useState<any>([]);
  const [isOperatorsItemsSorted, setIsOperatorsItemsSorted] = useState<any>(false);
  const isAccountAddLoading = getCreateAccountsAsyncState.getLoadingState;
  const isDeletedLoading = getDeleteAccountsAsyncState.getLoadingState;
  const addEmployeeEl = useRef<any>();
  const addEmployeeButtonEl = useRef<any>();
  const modalRefEl = useRef<any>(null);
  const backdropEl = useRef<any>(null);
  const [createOperators, setCreateOperators] = useState<any>([]);
  const [productOperatorCheckList, setProductOperatorCheckList] = useState<any[]>([]);
  const [isFirstChoosenOperatorsCheck, setFirstChoosenOperatorsCheck] = useState<any[]>([]);
  const [isButtonClicked, setIsButtonClicked] = useState<any>(false);
  const isCreatedGroupSuccess = getCreateGroupsAsyncState.getLoadingState;
  const [employeeSearch, setEmployeeSearch] = useState('');
  const employeeSearchDebounce = useDebounce(employeeSearch, 300);
  const isGetDetailPriceListSuccess = getPriceListDetailAsyncState.getLoadingState;
  const [groupName, setGroupName] = useState<any>('');
  const [groupDescription, setGroupDescription] = useState<any>('');
  const [isAllElementsChecked, setIsAllElementsChecked] = useState<boolean>(false);
  const isGetEmployeesLoading = getEmployeesAsyncState.getLoadingState;

  const groupInitialValues = createOperatorsGroup
    ?.reduce((acc: any, item: any) => {
      acc.push(
        item?.defaultPriceList
          ? {
            defaultPriceInitial: {
              [`${item.defaultPriceId}`]: {
                amount: +item?.amount,
                min: item?.minAmount,
                max: item?.maxAmount
              }
            },
            providerInitial: {
              [`${item.providerInitialId}`]: {
                ...item,
                label: item?.provider?.title,
                id: item.provider.id,
                value: item.provider?.id,
                singleValue: item?.provider?.title
              }
            },
            productInitial: {
              [`${item.productId}`]: item?.defaultPriceList
                ? {
                  ...item,
                  label: item?.defaultPriceList?.label,
                  id: item?.defaultPriceList?.id,
                  value: item.amount,
                  singleValue: item?.defaultPriceList?.label
                }
                : null
            }
          }
          : {
            defaultPriceInitial: {
              [`${item?.defaultPriceId}`]: {
                amount: +item?.amount,
                min: item?.minAmount,
                max: item?.maxAmount
              }
            },
            providerInitial: {
              [`${item?.providerInitialId}`]: {
                ...item,
                label: item?.provider?.title,
                id: item?.provider?.id,
                value: item?.provider?.id,
                singleValue: item?.provider?.title
              }
            }
          }
      );
      return acc;
    }, [])
    .reduce(
      (acc: any, item: any) =>
        Object.assign(acc, item.defaultPriceInitial, item.providerInitial, item.productInitial),
      {}
    );

  const validateSchema = Yup.object().shape({
    groupName: Yup.string().required(),
    ...createOperators?.map((operator: any) => {
      if (!operator?.amount || operator?.amount < operator.minAmount || operator?.amount > operator.maxAmount) {
        return {
          [`${operator.defaultPrice}`]: Yup.object().shape({
            amount: Yup.number().required().min(
              operator.minAmount,
              `Must be greater than or equal to ${operator.minAmount}`
            )
              .max(
                operator.maxAmount,
                `Must be less than or equal to ${operator.maxAmount}`
              )
          })
        };
      }
    }
    ).reduce((acc: any, item: any) => Object.assign(acc, item), {})
  });
  const onSubmit = (values: any, { resetForm }: any) => {
    const resultData: any = [];
    setIsButtonClicked(true);

    Object.entries(values).map(([key, valuesData]: [string, any]): any => {
      resultData.push(
        ...createOperatorsGroup.reduce((acc: any, operatorItem: any) => {
          choosenItems.map((choosenItem: any) => {
            if (operatorItem.providerId === choosenItem.id) {
              acc.push({
                defaultPrice: operatorItem.defaultPriceListId ? null : operatorItem.amount,
                priceListId: operatorItem.defaultPriceListId ? operatorItem.defaultPriceListId : null,
                providerId: operatorItem.providerId,
                defaultScenario: operatorItem?.defaultScenario?.id || null
              });
            }
          });
          return acc;
        }, [])
      );
    });

    const data: any = {
      clientId: getAuthMe.id,
      title: values.groupName,
      description: values.groupDescription,
      parentId: null,
      employeesId: isCheckGroup.map((item: EmployeeList) => item.id),
      accounts: removeDuplicates(resultData, (key: any) => key.providerId)
    };

    groups.createGroupsAction(data);

    if (isCreatedGroupSuccess === Loading.success) {
      resetForm();
    }
  };
  const animations: Variants = {
    initial: { opacity: 0, scale: 0.6, y: -10 },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.6, y: -10 }
  };

  const addNewMember = () => {
    setIsAppendEmployeesModal(!isAppendEmployeesModal);
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>, employee: any) => {
    const { id, checked } = e.target;

    if (checked) {
      setIsCheck([...isCheck, id]);
      setIsCheckGroup([...isCheckGroup, employee]);
    }

    if (!checked) {
      setIsCheck(isCheck?.filter((item: any) => item !== id));
      setIsCheckGroup(isCheckGroup?.filter((item: any) => item.id !== id));
    }
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>, employee: EmployeeList) => {
    const { checked, id } = e.target;

    if (checked) {
      setIsCheck([...getEmployeesModel?.getEmployeesList?.map((li) => li.id), id]);
      setIsCheckGroup(getEmployeesModel?.getEmployeesList);
    }

    if (!checked) {
      setIsCheck([]);
      setIsCheckGroup([]);
    }
  };

  const handleSelectSort = (e: any, eMeta: any) => {
    setIsOperatorsItemsSorted(!isOperatorsItemsSorted);
  };

  const removeOperator = (operator: any) => {
    showModal(Modals.ModalRemoveOperatorFromCreatePage, {
      setCreateOperators,
      setCreateOperatorsGroup,
      setProductOperatorCheckList,
      setFirstChoosenOperatorsCheck,
      setChoosenItems,
      operator,
      createOperators,
      createOperatorsGroup,
      productOperatorCheckList,
      isFirstChoosenOperatorsCheck,
      choosenItems
    });
  };

  const selectUnique = () =>
    toJS(getProvidersModel?.getProvidersList).filter(
      (allProviders: any) =>
        !createOperatorsGroup?.map((provider: any) => provider?.provider?.id).includes(allProviders.id)
    ).sort((a: any, b: any) => {
      if (a?.title < b?.title) {
        return -1;
      }
      if (a?.title > b?.title) {
        return 1;
      }

      return 0;
    });

  const removeCurrentEmployee = (e: any, employeeItem: any) => {
    e.stopPropagation();
    showModal(Modals.ModalRemoveEmployeeFromGroup, {
      isCheck,
      isCheckGroup,
      isCheckGroupList,
      setIsCheck,
      setIsCheckGroup,
      setIsCheckGroupList,
      employee: employeeItem,
      id: employeeItem.id,
      modalRefEl,
      setIsAppendEmployeesModal,
      isAppendEmployeesModal
    });
  };

  const onHandleSearch = (setter: (val: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  const handleOperatorsEdit = (content?: any) => {
    showModal(Modals.ModalAdditionOperatorFull, {
      uniqueProviders: selectUnique(),
      setCreateOperatorsGroup,
      createOperatorsGroup,
      backdropEl,
      currentOperatorData: content,
      setChoosenItems,
      choosenItems,
      currentKindOfPage: KindOfPage.CREATE_GROUP_PAGE
    });
  };

  const handleChangeOnlyMoneyGroup = (e: any, content: any) => {
    setCreateOperatorsGroup(
      createOperatorsGroup.map((item: any) => {
        if (content?.operatorName === item?.operatorName) {
          item.amount = +e;
          item.defaultPriceListId = null;
          item.isDefaultAmountValue = false;
        }
        return item;
      })
    );
  };

  const handleChangeGroup = (e: any, eMeta: any, content: any) => {
    createOperatorsGroup?.map(async (account: any) => {
      if (content?.operatorName === account?.operatorName) {
        await priceList?.getPriceListDetailAction({ id: e?.id });
        account.priceList = toJS(getPriceListModel.getPriceListDetail);
        account.minAmount = +e.minAmount;
        account.maxAmount = +e.maxAmount;
        account.providerId = e.id;
        account.provider = e;
        account.id = e?.id;
        account.isDefaultOperatorValue = false;
        account.isDefaultIdValue = false;
        account.isDefaultProductValue = false;
        account.isDefaultAmountValue = false;
      }
    });

    setChoosenItems(removeDuplicates([...choosenItems, e], (key: any) => key.selectedFieldId));
    setFirstChoosenOperatorsCheck([...isFirstChoosenOperatorsCheck, content.id]);
  };

  const handleChangeProductGroup = (e: any, eMeta: any, content: any) => {
    setCreateOperatorsGroup(
      createOperatorsGroup?.map((account: any) => {
        if (content?.operatorName === account?.operatorName) {
          account.amount = +e?.price;
          account.defaultPriceListId = e?.id;
          account.defaultPriceList = {
            description: e?.description,
            label: e?.label,
            id: e?.id,
            price: e?.price,
            provider: e?.provider
          };
          account.isDefaultProductValue = false;
          account.isDefaultAmountValue = false;
        }
        return account;
      })
    );

    setProductOperatorCheckList([...productOperatorCheckList, content.id]);
  };

  const handleChangePackagesAndMoneyGroup = (e: any, content: any) => {
    setCreateOperatorsGroup(
      createOperatorsGroup.map((item: any) => {
        if (content?.operatorName === item?.operatorName) {
          item.amount = +e;
          item.defaultPriceListId = null;
          item.isDefaultAmountValue = false;
        }
        return item;
      })
    );
  };

  const concatNewIsCheckGroupValues = () => {
    showModal(Modals.ModalAddEmployeesToCreateGroup, {
      isCheckGroup,
      setIsCheckGroupList,
      isCheckGroupList,
      modalRefEl,
      setIsAppendEmployeesModal,
      isAppendEmployeesModal
    });
    // setIsCheckGroupList(isCheckGroup);
    // setIsAppendEmployeesModal(false);
  };

  useEffect((): any => {
    // providers.getProvidersAction();

    if (isCreatedGroupSuccess === Loading.success) {
      navigate('/groups');
    }

    return () => getCreateGroupsAsyncState.reset();
  }, [
    isAccountAddLoading,
    isDeletedLoading,
    isCreatedGroupSuccess,
    selectUnique().length,
    getPriceListModel.getPriceListDetail,
    getPriceListModel.getPriceListDetail.length
  ]);

  useEffect(() => {
    // TODO: Протестировать закрытие бокового окна после выбора операторов в таблице, сейчас закрывается окно, но после клику по селекту выбора оператора в модалке оператора (CreateGroup, DetailGroup)
    const isOpenAddEmployeeMenu = (e: any) =>
      addEmployeeEl?.current?.contains(e.target) ||
      addEmployeeButtonEl?.current?.contains(e.target) ||
      modalRefEl?.current?.contains(e.target) ||
      setIsAppendEmployeesModal(false);


    document.addEventListener('click', isOpenAddEmployeeMenu);
    return () => document.removeEventListener('click', isOpenAddEmployeeMenu);
  }, [isAppendEmployeesModal]);

  useEffect(() => {
    if (isButtonClicked) {
      setTimeout(() => {
        setIsButtonClicked(false);
      }, 500);
    }
  }, [isButtonClicked]);

  useEffect(() => {
    employees.getEmployeesAction(
      {
        id: getAuthMe.id,
        page: toJS(getEmployeesModel.getEmployeesMeta).page,
        search: employeeSearch
        // quantity: (toJS(getEmployeesModel.getEmployeesMeta).page || employeeSearch) !== 1 ? 0 : 1000,
      }
    );
  }, [employeeSearchDebounce, employeeSearch]);
  useEffect(() => {
    if (isCreatedGroupSuccess === Loading.success) {
      isCheckGroup?.reduce((acc: any, employee: any, index: number) => {
        Promise.all([employees?.updateEmployeeAction({
          employeeId: employee?.id,
          name: employee?.name,
          phones: employee?.phones,
          surname: employee?.surname,
          groupId: getGroupsModel?.getGroupDetail?.id,
          accounts: createOperatorsGroup?.reduce(
            (accountAcc: any, accountItem: any) => {

              accountAcc.push(
                {
                  defaultPrice: !accountItem.defaultPriceList ? accountItem.amount : null,
                  defaultPriceListId: accountItem.defaultPriceList
                    ? accountItem.defaultPriceList?.id
                    : null,
                  note: null,
                  providerId: accountItem.provider?.id,
                  employeeId: employee?.id,
                  uniqueId: null,
                  defaultScenario: accountItem?.defaultScenario?.id ? accountItem?.defaultScenario : null,
                  defaultValueEmployee: false,
                  // TODO: Тест defaultScenario и defaultScenarioId
                  // defaultScenarioId: accountItem?.defaultScenario?.id
                });

              return accountAcc;
            },
            []
          )
        }, isCheckGroup.length)]
        );
        return acc;
      }, []);
    }
  }, [isCreatedGroupSuccess]);

  useEffect(() => {
    providers.getProvidersAction();
  }, []);

  useEffect(() => {

    if (isAppendEmployeesModal && !isTablet) {
      document.body.style.overflow = 'hidden';
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }

  }, [isAppendEmployeesModal]);

  useEffect(() => {
    if (isGetEmployeesLoading === Loading.success) {
      setIsAllElementsChecked(getEmployeesModel.getEmployeesList.length === isCheckGroup.length);
    }
  }, [isGetEmployeesLoading, isCheckGroup]);

  useEffect(() => {

    if (!isAppendEmployeesModal) {
      setIsCheckGroup(isCheckGroupList);
      setIsCheck(isCheckGroupList.map(item => item.id));
    }

  }, [isAppendEmployeesModal]);

  return (
    <section className={s.wrapper}>
      <div className={s.wrapper__container}>
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: { opacity: 0, x: 70, y: 70 },
            animate: { opacity: 1, x: 0, y: 0 },
            exit: { opacity: 0, x: 70, y: 70 }
          }}
          transition={{ duration: 0.7, type: 'spring', damping: 22, stiffness: 155 }}
          className={s.back}
        >
          <ReactTooltip textColor="#334049" backgroundColor="#fff" border borderColor="#E5E8ED"/>
          <motion.button
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className={s.back_icon}
            onClick={() => navigate('/groups')}
          >
            <BackSVG/>
          </motion.button>

          <div className={s.back_text}>
            <p>Back to groups</p>
          </div>
        </motion.div>

        <AnimatedDivPage
          transition={{ duration: 0.7, type: 'spring', damping: 15 }}
          animations={animations}
        >
          <div className={s.wrapper__title}>
            <h1 className={s.title}>Create group</h1>
          </div>
          <FormikContainer
            initialValues={{
              groupName,
              groupDescription,
              ...groupInitialValues
            }}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validateSchema}
          >
            {({ values, setValues, errors }) => (
              <>
                <div className={s.detail}>
                  <div className={s.detail__title_header}>
                    <div className={s.group}>
                      <div className={`${s.input} ${errors?.groupName ? s.border_alert : ''}`}>
                        <FormikTextField
                          type="text"
                          placeholder="Name"
                          label={<p className={s.input__label}>Group name</p>}
                          name='groupName'
                          onChange={(e: any) => setGroupName(e.target.value)}
                          errorMessageDisable
                        />
                      </div>

                      <div className={s.input}>
                        <FormikTextField
                          type="text"
                          placeholder="Description"
                          label={<p className={s.input__label}>Description</p>}
                          name='groupDescription'
                          onChange={(e: any) => setGroupDescription(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={s.employee__group}>
                    <h4 className={s.employee__group_title}>Employees</h4>
                    <div className={s.employee__group__list}>
                      { isCheckGroupList.length ? isCheckGroupList?.map((employee: any) => (
                        <RouterLink key={employee.id} to={`/employees/employees-detail/${employee.id}`} state={{ previousPageWithQuery: '/employees' }}>
                          <div className={s.group__item}>
                            <div className={s.link}>
                              <p>
                                {employee.name} {employee.surname}
                              </p>

                              <div className={s.item__icon}>
                                <LinkSVG/>
                              </div>
                            </div>

                            <button
                              onClick={(e: ChangeEvent<any>) => removeCurrentEmployee(e, employee)}
                              type="button"
                              className={s.employee__remove_icon}
                            >
                              <CrossSVG/>
                            </button>
                          </div>
                        </RouterLink>
                      ))
                        :
                      <p>There are no employees</p>}
                    </div>
                  </div>

                  <div className={s.members__button}>
                    <motion.button
                      ref={addEmployeeButtonEl}
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => addNewMember()}
                    >
                      <p>Add employee+</p>
                    </motion.button>
                  </div>

                  <div className={s.operators}>
                    <div className={s.operators__header}>
                      <div className={s.operators__wrapper__title}>
                        <h4 className={s.operators__title}>Operators</h4>
                      </div>

                      <div className={s.operators__sort}>
                        <FormikSelect
                          valueContainer={{ paddingLeft: '.5rem' }}
                          wrapperSelectStyles={{
                            width: '11rem',
                            maxWidth: '100%',
                            minWidth: '100%'
                          }}
                          options={selectGroupSortByTitles}
                          name="sort"
                          placeholder="Sort by"
                          indicatorIcon={<DropSelectSVG/>}
                          onChange={handleSelectSort}
                        />
                      </div>
                    </div>

                    <div className={s.operators__list}>
                      <div className={s.operators__list__header}>
                        {operatorsTableGroupTitles.map((titles: IOperatorsTableTitles) => (
                          <div key={titles.id} className={s.header__item}>
                            <button
                              type="button"
                              className={s.item}
                            >
                              <p>{titles.name}</p>
                              {titles.icon}
                            </button>
                          </div>
                        ))}
                      </div>

                      <motion.div className={s.operators__main}>
                        {createOperatorsGroup.length
                          ? createOperatorsGroup.map((content: any, index: any) => (
                            <motion.div
                              initial="initial"
                              animate="animate"
                              exit="exit"
                              variants={{
                                initial: { opacity: 0, y: -10 },
                                animate: { opacity: 1, y: 0 },
                                exit: { opacity: 0, y: 10 }
                              }}
                              transition={{
                                duration: 0.5,
                                type: 'spring',
                                stiffness: 150,
                                damping: 18,
                                bounce: 0
                              }}
                              key={content?.provider?.id}
                              className={`${s.operators__items} ${s.operators__items__create}`}
                            >

                              <div className={s.operators__item}>
                                <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                  <FormikSelect
                                    // onMenuOpen={() =>
                                    // handleProviderSelect
                                    // handleOperatorsEdit(content)
                                    // }
                                    isDisabled
                                    valueStyles={{
                                      color: ColorsAccent.accentDark,
                                      opacity: 1
                                    }}
                                    indicatorContainerStyles={{
                                      height: 'auto',
                                      width: '2.0625rem',
                                      cursor: 'pointer'
                                    }}
                                    // wrapperSelectStyles={{ width: '100%' }}
                                    controlStyles={{
                                      minHeight: '2.0625rem',
                                      maxHeight: '2.0625rem',
                                      border: `1px solid ${ColorsBorder.borderPrimary}`,
                                    }}
                                    // hideInputContainerWrapper={{
                                    //   width: 0,
                                    //   margin: 0,
                                    //   padding: 0,
                                    //   maxHeight: 0
                                    // }}
                                    valueContainer={{
                                      height: '2.0625rem',
                                      maxHeight: '2.0625rem',
                                      paddingTop: '0',
                                      paddingBottom: '.25rem',
                                      fontStyle: content?.isDefaultOperatorValue ? 'italic' : 'normal'
                                    }}
                                    containerStyles={{
                                      width: '8.375rem',
                                      minWidth: !isTablet ? '100%' : 'auto',
                                      height: 'auto',
                                      fontSize: '1rem',
                                      border: 'none'
                                    }}
                                    name={`provider${content?.provider?.id}`?.split('.')?.join('')}
                                    options={
                                      // selectUnique()?.slice(itemOffset, endOffset)
                                      selectUnique()?.map((item: any) => ({
                                        ...item,
                                        value: item?.title,
                                        label: item?.title,
                                        singleValue: item?.title,
                                        id: item?.id,
                                        isDisabled: item?.availability === 'Disabled',
                                        genre: ['pop'],
                                        selectedFieldId: `provider${item?.id}`,
                                        uniqueId: `uniqueId${item?.id}`,
                                        defaultPrice: `defaultPrice${item?.id}`,
                                        note: `note${item?.id}`
                                      }))}
                                    // selectPagination={
                                    //   <>
                                    //     <div className={s.paginate__count__mobile}>
                                    //         <p>{dynamicPage}</p> <p className={s.count__divider}>of</p> <p>{pageCount || 1}</p>
                                    //     </div>
                                    //     <div className={s.select__pagination}>
                                    //       <SelectPagination
                                    //         dynamicPage={dynamicPage}
                                    //         handlePageClick={handlePageClickProviders}
                                    //         pageCount={pageCount}
                                    //       />
                                    //     </div>
                                    //   </>
                                    // }
                                    // isSearchable
                                    placeholder={'Operator'}
                                    indicatorIcon={<DropSelectSVG/>}
                                    onChange={(e: any, eMeta: any) =>
                                      handleChangeGroup(e, eMeta, { ...content, id: content?.id })
                                    }
                                  />
                                </div>
                              </div>

                              {content?.provider?.measure === ProviderMeasure.PACKAGES ||
                              content?.provider?.measure ===
                              ProviderMeasure.PACKAGES_AND_MONEY ? (

                                <div className={s.operators__item}
                                  // onClick={() => handleOperatorsEdit(content)}
                                >
                                  <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                    <FormikSelect
                                      // onMenuOpen={() =>
                                      // handlePriceListSelect({ ...content, provider: { id: content?.providerId } })
                                      //   handleOperatorsEdit(content)
                                      // }
                                      indicatorContainerStyles={{
                                        height: 'auto',
                                        width: '2.0625rem'
                                      }}
                                      isDisabled
                                      valueStyles={{
                                        color: ColorsAccent.accentDark,
                                        opacity: 1
                                      }}
                                      // wrapperSelectStyles={{ width: '100%' }}
                                      controlStyles={{
                                        minHeight: '2.0625rem',
                                        maxHeight: '2.0625rem',
                                        border: `1px solid ${ColorsBorder.borderPrimary}`
                                      }}
                                      // hideInputContainerWrapper={{
                                      //   width: 0,
                                      //   margin: 0,
                                      //   padding: 0,
                                      //   maxHeight: 0
                                      // }}
                                      valueContainer={{
                                        height: '2.0625rem',
                                        maxHeight: '2.0625rem',
                                        paddingTop: '0',
                                        paddingBottom: '.25rem',
                                        fontStyle: content?.isDefaultProductValue ? 'italic' : 'normal',
                                      }}
                                      containerStyles={{
                                        width: '8.375rem',
                                        minWidth: !isTablet ? '100%' : 'auto',
                                        height: 'auto',
                                        fontSize: '1rem',
                                        border: 'none'
                                      }}
                                      name={`product${content?.provider?.id}`?.split('.')?.join('')}
                                      loadingMessage={
                                        isGetDetailPriceListSuccess === Loading.success
                                      }
                                      options={
                                        // toJS(getPriceListModel.getPriceListDetail?.slice(itemOffset, endOffset))
                                        toJS(getPriceListModel.getPriceListDetail)?.map((item: any) => ({
                                          ...item,
                                          value: item?.description,
                                          label: item?.description,
                                          singleValue: item?.description,
                                          uniqueId: `uniqueId${content?.provider?.id}`?.split('.')?.join(''),
                                          amount: item?.price,
                                          id: item?.id,
                                          isDisabled: item?.availability === 'Disabled',
                                          genre: ['pop']
                                        }))}
                                      // selectPagination={
                                      //   <>
                                      //     <div className={s.paginate__count__mobile}>
                                      //         <p>{dynamicPage}</p> <p className={s.count__divider}>of</p> <p>{pageCount || 1}</p>
                                      //     </div>
                                      //     <div className={s.select__pagination}>
                                      //       <SelectPagination
                                      //         dynamicPage={dynamicPage}
                                      //         handlePageClick={handlePageClick}
                                      //         pageCount={pageCount}
                                      //       />
                                      //     </div>
                                      //   </>
                                      // }
                                      // isSearchable
                                      placeholder={'Product'}
                                      indicatorIcon={<DropSelectSVG/>}
                                      onChange={(e: any, eMeta: any) =>
                                        handleChangeProductGroup(e, eMeta, {
                                          ...content,
                                          id: content?.provider?.id
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                ) : (
                                <div className={s.operators__placeholder}/>
                                )}

                              {content?.provider?.measure === ProviderMeasure.PACKAGES ? (
                                <div className={s.operators__item}>
                                  <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                    <FormikTextFieldNumber
                                      readOnly={
                                        content?.provider?.measure === ProviderMeasure.PACKAGES
                                      }
                                      inputStyles={{
                                        width: '8.375rem',
                                        height: '2.0625rem',
                                        minWidth: !isTablet ? '100%' : 'auto',
                                        fontStyle: content?.isDefaultAmountValue ? 'italic' : 'normal'
                                      }}
                                      placeholder="Amount"
                                      value={+content?.amount || ''}
                                      name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                                      suppressHydrationWarning
                                      min={+content?.minAmount}
                                      max={+content?.maxAmount}
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : null}

                              {content?.provider?.measure === ProviderMeasure.PACKAGES_AND_MONEY ? (
                                <div className={s.operators__item}>
                                  <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                    <FormikTextFieldNumber
                                      readOnly
                                      inputStyles={{
                                        width: '8.375rem',
                                        height: '2.0625rem',
                                        minWidth: !isTablet ? '100%' : 'auto',
                                        fontStyle: content?.isDefaultAmountValue ? 'italic' : 'normal',
                                        border: content?.amount < content?.minAmount || content?.amount > content?.maxAmount || !content?.amount ? '1px solid red' : `1px solid ${ColorsBorder.borderPrimary}`
                                      }}
                                      placeholder="Amount"
                                      value={+content?.amount || ''}
                                      name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                                      suppressHydrationWarning
                                      min={+content?.minAmount}
                                      max={+content?.maxAmount}
                                      onValueChange={(e: any, name: any) =>
                                        handleChangePackagesAndMoneyGroup(e, content)
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : null}

                              {content?.provider?.measure === ProviderMeasure.MONEY ? (
                                <div className={s.operators__item}>
                                  <div className={s.item__wrapper} onClick={() => handleOperatorsEdit(content)}>
                                    <FormikTextFieldNumber
                                      inputStyles={{
                                        width: '8.375rem',
                                        height: '2.0625rem',
                                        minWidth: !isTablet ? '100%' : 'auto',
                                        fontStyle: content?.isDefaultAmountValue ? 'italic' : 'normal',
                                        border: content?.amount < content?.minAmount || content?.amount > content?.maxAmount || !content?.amount ? '1px solid red' : `1px solid ${ColorsBorder.borderPrimary}`
                                      }}
                                      readOnly
                                      placeholder="Amount"
                                      value={+content?.amount || ''}
                                      name={`defaultPrice${content?.provider?.id}`?.split('.')?.join('')}
                                      suppressHydrationWarning
                                      min={+content?.minAmount}
                                      max={+content?.maxAmount}
                                      onValueChange={(e: any, name: any) =>
                                        handleChangeOnlyMoneyGroup(e, content)
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : null}
                              <div className={s.operators__items_icon}>
                                <button
                                  onClick={() => removeOperator(content)}
                                  type="button"
                                  className={s.remove__button}
                                >
                                  <DeleteLightSVG/>
                                </button>
                              </div>
                            </motion.div>
                          ))
                          :
                          <div className={s.operators__empty}>
                            <p>There are no operators</p>
                          </div>}
                      </motion.div>
                    </div>

                    <div className={s.create}>
                      <motion.button
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ scale: 1.05 }}
                        // onClick={() => addNewOperator()}
                        onClick={() => handleOperatorsEdit()}
                        className={s.create__button}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        type="button"
                      >
                        <p>Add+</p>
                      </motion.button>
                    </div>
                  </div>
                </div>

                <div className={s.operators__buttons}>
                  <div className={s.operators__button}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      disabled={isButtonClicked}
                      type="submit"
                    >
                      <p>Create</p>
                    </motion.button>
                  </div>
                  <div className={s.operators__button}>
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                      type="button"
                      onClick={() => navigate('/groups')}
                    >
                      <p>Cancel</p>
                    </motion.button>
                  </div>
                </div>
                <motion.div
                  ref={addEmployeeEl}
                  initial="hidden"
                  animate={isAppendEmployeesModal ? 'open' : 'hidden'}
                  variants={{
                    hidden: { width: 0, opacity: 0 },
                    open: {
                      width: !isTablet ? '100%' : '23.8125rem',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)',
                      opacity: 1
                    }
                  }}
                  transition={{ duration: 0.3, type: 'spring', damping: 18 }}
                  className={s.employee__window}
                >
                  <div className={s.employee__window__inner}>
                    <div className={s.header}>
                      <div className={s.header__title}>
                        <h2 className={s.title}>Add employee</h2>
                      </div>
                    </div>

                    <div className={s.employee__search}>
                      <FormikTextField
                        placeholder="Search"
                        name="addEmployeeSearch"
                        value={employeeSearch}
                        id={'addEmployeeSearch'}
                        onChange={onHandleSearch(setEmployeeSearch)}
                      >
                        <div className={s.search__icon}>
                          <LoupeSVG/>
                        </div>
                      </FormikTextField>
                    </div>

                    <div className={s.employee__content}>
                      {getEmployeesModel?.getEmployeesList?.length ? [chooseAllEmployees, ...getEmployeesModel?.getEmployeesList]?.map((client: EmployeeList, i: number) => (
                          <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={{
                              hidden: { opacity: 0, x: -50 },
                              visible: {
                                pathLength: 1,
                                opacity: 1,
                                transition: {
                                  delay: i * 0.05,
                                  duration: 0.4,
                                  type: 'spring',
                                  damping: 10
                                },
                                x: 0
                              }
                            }}
                            transition={{ duration: 0.7 }}
                            key={client.id}
                            className={s.content__items}
                          >
                            <div className={[s.content__item, client.isCheckboxAll ? s.content__item_all : null].filter((item) => item).join(' ')}>
                              <FormikCheckbox
                                labelStyles={{
                                  borderRadius: '8px',
                                  backgroundColor: ColorsAccent.accentYellow
                                }}
                                label={`${client.name} ${client.surname}`}
                                id={toJS(client.id)}
                                title={`${client.name} ${client.surname}`}
                                name={`${toJS(client.id)}`}
                                isChecked={ client.isCheckboxAll ?
                                  isAllElementsChecked :

                                  isCheck?.includes(toJS(client.id))
                                }
                                groupCheckbox
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  client.isCheckboxAll ? handleCheckAll(e, toJS(client)) : handleCheck(e, toJS(client))
                                }
                              />

                              <div className={s.group__icons}>
                                {toJS(client?.group?.title) ? (
                                  <div className={s.group__icon}>
                                    <GroupsMarkSVG/>
                                  </div>
                                ) : null}

                                { client.isCheckboxAll ?
                                  null
                                  :
                                <RouterLink to={`/employees/employees-detail/${client.id}`} state={{ previousPageWithQuery: '/employees' }}>
                                  <div className={s.item__icon}>
                                    <LinkSVG/>
                                  </div>
                                </RouterLink>}
                              </div>

                            </div>
                          </motion.div>
                      ))
                        :
                        <div className={s.empty_message}>
                          <p>There are no employees</p>
                        </div>
                      }
                    </div>

                    <div className={s.buttons}>
                      <motion.button
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        onClick={concatNewIsCheckGroupValues}
                        type="button"
                      >
                        <p>Add/Remove</p>
                      </motion.button>
                      <motion.button
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        type="button"
                        onClick={() => setIsAppendEmployeesModal(false)}
                      >
                        <p>Cancel</p>
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              </>
            )}
          </FormikContainer>
        </AnimatedDivPage>
      </div>
    </section>
  );
};

export const CreateGroup = observer(CreateGroupView);
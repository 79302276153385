/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import SERVICE_API from 'api/payway';
import { tokenFieldName } from 'common/constants/auth';
import { remapFieldsError } from 'common/replaceErrorMessage';
import { makeAutoObservable } from 'mobx';
import {
  SignUp,
  SignIn,
  AuthModel,
  SignInGoogle,
  SignInMobile,
  AuthVerification,
  AuthMe, IGoogleSignUp, CreateResetPassword, ICreateResetPasswordCode, ICreateNewPassword, ICreateNewPasswordResponse
} from 'models/auth/types';
import { toast } from 'react-toastify';
import { IAsyncUtil, IConstructorAsyncUtils } from 'store/types';
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { authPaths } from 'router/paths/appPaths';

interface AuthRepository {
  new (): AuthModel;
}

export class AuthController {
  private authModel: AuthModel;

  private signUpAsyncHelper: IAsyncUtil;

  private authMeAsyncHelper: IAsyncUtil;

  private signInAsyncHelper: IAsyncUtil;

  private signInMobileAsyncHelper: IAsyncUtil;

  private signInGoogleAsyncHelper: IAsyncUtil;

  private logoutAsyncHelper: IAsyncUtil;

  private authVerificationAsyncHelper: IAsyncUtil;

  private signUpGoogleAsyncHelper: IAsyncUtil;

  private resetPasswordAsyncHelper: IAsyncUtil;

  private resetPasswordCodeAsyncHelper: IAsyncUtil;

  private newPasswordAsyncHelper: IAsyncUtil;

  get getAuthModel(): AuthModel {
    return this.authModel;
  }

  get getAuthMeAsyncState(): IAsyncUtil {
    return this.authMeAsyncHelper;
  }

  get getSignUpAsyncState(): IAsyncUtil {
    return this.signUpAsyncHelper;
  }

  get getSignInAsyncState(): IAsyncUtil {
    return this.signInAsyncHelper;
  }

  get getSignInMobileAsyncState(): IAsyncUtil {
    return this.signInMobileAsyncHelper;
  }

  get getSignInGoogleAsyncState(): IAsyncUtil {
    return this.signInGoogleAsyncHelper;
  }

  get getLogoutAsyncState(): IAsyncUtil {
    return this.signInAsyncHelper;
  }

  get getAuthVerificationAsyncHelper(): IAsyncUtil {
    return this.authVerificationAsyncHelper;
  }

  get getResetPasswordAsyncState(): IAsyncUtil {
    return this.resetPasswordAsyncHelper;
  }

  get getResetPasswordCodeAsyncState(): IAsyncUtil {
    return this.resetPasswordCodeAsyncHelper;
  }

  get getNewPasswordAsyncState(): IAsyncUtil {
    return this.newPasswordAsyncHelper;
  }

  authMeAction = async (): Promise<void> => {
    this.authMeAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.AuthAPI.getAuthMe();
      if (!authPaths.includes(window.location.pathname)) {
        localStorage.setItem('authMe', JSON.stringify({ id: data?.id, email: data?.email, balance: data?.balance }));
      } else {
        localStorage.removeItem('authMe');
      }
      this.authMeSuccess(data);
      await this.authMeAsyncHelper.successLoadingAsync();
      // this.authMeAsyncHelper.reset();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error || 'Authorization error');
      this.authMeAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  signUpAction = async (auth: SignUp): Promise<void> => {
    this.signUpAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.AuthAPI.createSignUp(auth);
      this.signUpSuccess(data);
      await this.signUpAsyncHelper.successLoadingAsync(false, data?.message || data);
      this.getSignUpAsyncState.reset();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.signUpAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  signInAction = async (auth: SignIn): Promise<void> => {
    this.getSignInAsyncState.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.AuthAPI.createSignIn(auth);
      this.signInSuccess(data);
      await this.getSignInAsyncState.successLoadingAsync(false, data?.message || data);
      this.getSignInAsyncState.reset();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getSignInAsyncState.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  signInMobileAction = async (auth: SignInMobile): Promise<void> => {
    this.getSignInMobileAsyncState.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.AuthAPI.createSignInMobile(auth);
      this.signInMobileSuccess(data);
      await this.getSignInMobileAsyncState.successLoadingAsync(false, data?.message || data);
      this.getSignInMobileAsyncState.reset();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getSignInMobileAsyncState.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  signInGoogleAction = async (auth: SignInGoogle): Promise<void> => {
    this.signInGoogleAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.AuthAPI.createSignInGoogle(auth);
      this.signInGoogleSuccess(data);
      await this.signInGoogleAsyncHelper.successLoadingAsync(false, data?.message || data);
      this.signInGoogleAsyncHelper.reset();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.signInGoogleAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  createResetPasswordAction = async (dataItem: any): Promise<void> => {
    this.getResetPasswordAsyncState.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.AuthAPI.createResetPassword(dataItem);
      await this.getResetPasswordAsyncState.successLoadingAsync(false, `Code sent to ${dataItem.email ? `email: ${dataItem.email}` : `phone: +256${dataItem.phone}`}`);
      await sessionStorage.setItem('resetData', JSON.stringify(dataItem));
      this.getResetPasswordAsyncState.reset();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getResetPasswordAsyncState.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  createResetPasswordCodeAction = async (dataItem: ICreateResetPasswordCode): Promise<void> => {
    this.getResetPasswordCodeAsyncState.startLoadingAsync();
    try {
      const { data }: AxiosResponse<ICreateNewPassword> = await SERVICE_API.AuthAPI.createResetPasswordCode(dataItem);
      await sessionStorage.setItem('key', data?.key);
      this.resetPasswordCode(data);
      await this.getResetPasswordCodeAsyncState.successLoadingAsync(false, 'OTP is correct');
      this.getResetPasswordCodeAsyncState.reset();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getResetPasswordCodeAsyncState.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  createNewPasswordAction = async (dataItem: ICreateNewPasswordResponse): Promise<void> => {
    this.getNewPasswordAsyncState.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.AuthAPI.createNewPassword(dataItem);
      await this.getNewPasswordAsyncState.successLoadingAsync(false, 'Password successfully changed, please log in with the new credentials.');
      this.getNewPasswordAsyncState.reset();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.getNewPasswordAsyncState.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  logoutAction = async (): Promise<void> => {
    this.logoutAsyncHelper.startLoadingAsync();
    try {
      await SERVICE_API.AuthAPI.getLogout();
      // localStorage.removeItem(tokenFieldName);
      sessionStorage.removeItem('isCheckGroup');
      sessionStorage.removeItem('isCheck');
      sessionStorage.removeItem('isCheckAll');
      sessionStorage.removeItem('previousNav');
      sessionStorage.removeItem('temporarySessionData');
      sessionStorage.removeItem('temporaryMultiTxId');
      sessionStorage.removeItem('temporaryBalance');
      sessionStorage.removeItem('providers');

      localStorage.removeItem('authMe');
      Cookies.remove('refreshToken');
      Cookies.remove('twk_idm_key');
      this.logoutAsyncHelper.successLoadingAsync(false, 'You are logged out');
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.logoutAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  authVerificationAction = async (code: AuthVerification): Promise<void> => {
    this.authVerificationAsyncHelper.startLoadingAsync();
    // this.authMeAction();
    try {
      const { data } = await SERVICE_API.AuthAPI.createVerifiction(code);
      sessionStorage.removeItem('isCheckGroup');
      sessionStorage.removeItem('isCheck');
      sessionStorage.removeItem('isCheckAll');
      sessionStorage.removeItem('previousNav');
      sessionStorage.removeItem('temporarySessionData');
      sessionStorage.removeItem('temporaryMultiTxId');
      sessionStorage.removeItem('temporaryBalance');
      sessionStorage.removeItem('providers');
      await this.verificationSuccess(data);
      // localStorage.setItem(tokenFieldName, 'ready');
      await this.authVerificationAsyncHelper.successLoadingAsync(false, 'OTP is correct');
      await this.authVerificationAsyncHelper.reset();
      await this.authMeAction();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.authVerificationAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  signUpGoogleAction = async (): Promise<void> => {
    this.signUpGoogleAsyncHelper.startLoadingAsync();
    try {
      const { data } = await SERVICE_API.AuthAPI.getSignUpGoogle();
      await this.googleSignUpSuccess(data);
      await this.signUpGoogleAsyncHelper.successLoadingAsync();
    } catch (error) {
      const { errorMessage, fieldErrors } = this.ormErrors(error);
      this.signUpGoogleAsyncHelper.errorLoadingAsync(false, remapFieldsError(fieldErrors)['0'] || errorMessage);
    }
  };

  private ormErrors = (error: any) => {
    const errorMessage = error?.response?.data?.error || error?.message || error;
    const fieldErrors = error?.response?.data?.message;
    return { errorMessage, fieldErrors };
  };

  private authMeSuccess = (data: AuthMe): void => {
    this.authModel.setAuthMe(data);
  };

  private signUpSuccess = (data: any): void => {
    this.authModel.setAuthSignUp(data);
  };

  private signInSuccess = (data: any): void => {
    this.authModel.setAuthSignIn(data);
  };

  private signInMobileSuccess = (data: any): void => {
    this.authModel.setAuthSignInMobile(data);
  };

  private signInGoogleSuccess = (data: any): void => {
    this.authModel.setAuthSignInGoogle(data);
  };

  private resetPasswordCode = (data: ICreateNewPassword): void => {
    this.authModel.setAuthResetPasswordCode(data);
  };

  private verificationSuccess = (code: any): void => {
    this.authModel.setAuthVerification(code);
  };

  private googleSignUpSuccess = (data: IGoogleSignUp): void => {
    this.authModel.setAuthSignUpGoogle(data);
  };

  constructor(AuthEntity: AuthRepository, AsyncHelper: IConstructorAsyncUtils) {
    this.authModel = new AuthEntity();
    this.authMeAsyncHelper = new AsyncHelper();
    this.signUpAsyncHelper = new AsyncHelper();
    this.signInAsyncHelper = new AsyncHelper();
    this.logoutAsyncHelper = new AsyncHelper();
    this.signUpGoogleAsyncHelper = new AsyncHelper();
    this.signInMobileAsyncHelper = new AsyncHelper();
    this.signInGoogleAsyncHelper = new AsyncHelper();
    this.authVerificationAsyncHelper = new AsyncHelper();
    this.newPasswordAsyncHelper = new AsyncHelper();
    this.resetPasswordAsyncHelper = new AsyncHelper();
    this.resetPasswordCodeAsyncHelper = new AsyncHelper();

    makeAutoObservable(this);
  }
}
import React from 'react';
import { makeAutoObservable } from 'mobx';
import { AccountsModel, GetAccountData } from './types';

export class AccountsEntity implements AccountsModel {
  private accountsCreateCheck: GetAccountData[] = [];

  private accountsCheck: GetAccountData = {
    id: '',
    uniqueId: '',
    defaultPrice: 0,
    defaultPriceList: {
      description: '',
      id: '',
      label: '',
      price: 0,
      provider: {
        id: '',
        title: '',
        minAmount: 0,
        maxAmount: 0,
        measure: '',
        validatePatterns: [],
        inputPatterns: [],
        availability: '',
        isPhoneNumber: false
      },
    },
    defaultScenario: {
      createAt: '',
      deletedAt: '',
      id: '',
      label: '',
      type: '',
    },
    defaultValueEmployee: false,
    employee: {
      id: '',
      title: '',
      parent_id: '',
      membersCount: 0,
      employees: [],
      groupsAccounts: [],
    },
    note: '',
    provider: {
      id: '',
      title: '',
      minAmount: 0,
      maxAmount: 0,
      measure: '',
      validatePatterns: [],
      inputPatterns: [],
      availability: '',
      isPhoneNumber: false
    }
  };

  setCreateCheckAccounts(accountsCreateCheck: GetAccountData[]) {
    this.accountsCreateCheck = accountsCreateCheck;
  }

  setCreateAccounts(accountsCheck: GetAccountData) {
    this.accountsCheck = accountsCheck;
  }

  get getCreateCheckAccounts(): GetAccountData[] {
    return this.accountsCreateCheck;
  }

  get getCreateAccounts(): GetAccountData {
    return this.accountsCheck;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
